import { Component } from '@angular/core';
import { TransboundaryWaterModule } from '../../transboundary-water.module';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { FilterType } from 'src/app/core/models/templates/static-template/static-template.model';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.css']
})
export class ProfitComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  label: any = 'Financial Gain';
  private formatInfo!: any;
  public dataMap: any = {};

  constructor(private transboundaryWaterService: TransboundaryWaterServiceService,
    private homeStore: HomeStore, 
    private spinner: NgxSpinnerService,) {
      if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
      else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
      else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
      else this.isTri = true;
    }

  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    }  
    if (this.isUSA) {
      
      this.dataMap = {
        center: MapCenter.USA,
        scale: ['fefbce', 'FFF766', 'ec0909'],
        json: MapJson.USA,
        zoom: MapZoom.USA,
        dateFormat: 'YYYY',
        borderColor: '#000000'
      };

      this.formatInfo = {
        region: "en-US",
        format: {  year: 'numeric'},
        addDays: 0
      };
      this.getDataUs();
    } 
    if (this.isMx) {
      this.getDataMx();
    }
    if(this.isTri){
      this.getDataTri();
    }

  }
 
  getDataUs() {
    this.transboundaryWaterService.getAgriculturalProfitUs()
    .subscribe(
      {
        next: (res) => {
          this.data = {
            data: res,
              title: 'Agricultural Profit',
              description: 'info',
              mapTitle: 'Agricultural Profit',
              mapInfo: this.dataMap,
              ECDF: {
                title: 'ECDF Agricultural Profit ',
                yLabel: 'Cumulative Relative Frequency', 
                xLabel: 'Agricultural Profit',
              },
              histogram: {
                title: `Histogram Agricultural Profit `,
                yLabel: 'Frequency', 
                xLabel: 'Agricultural Profit',
              },
              timeUnit: 'year',
              formatInfo: this.formatInfo,
              labelPopup: this.label, 
          }
          this.spinner.hide();
        },
        error: (e) => { 
          console.error(e); 
          this.spinner.hide(); 
        }
      }
    );
  }

  getDataCN() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataTri(){
    this.spinner.hide();
  }

}
