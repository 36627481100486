import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';

@Component({
  selector: 'app-y-axis-filter',
  templateUrl: './y-axis-filter.component.html',
  styleUrls: ['./y-axis-filter.component.css'],
})
export class YAxisFilterComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() data!: any;
  @Input() defaultSelection!: any;
  @Input() selectedItem!: number;
  @Input() FilterInTrendModel!: boolean; //This input appear only in Time Series Template the second tab, bcz for endpoints in dataiku i need the name not the id
  @Output() yAxisValue = new EventEmitter<string>();
  @Output() yAxisValueName = new EventEmitter<string>();
  @Output() yAxisId = new EventEmitter<number>();
  yAxisFilter = new FormControl<number>(0);
dataFilter:any;
  constructor(private dataService:DataMultipleVariableService) {

this.dataService.dataFilter$.subscribe((data) => {
  this.dataFilter=data;
})
    this.yAxisFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.yAxis.filter((y: any) => y.id == x)[0];
      if (data_ !== undefined) {
        if (this.FilterInTrendModel == true) {
          this.yAxisValue.emit(data_.shortName.toString());
        } 
        else {
          this.yAxisValue.emit(data_.id.toString());
          
          this.yAxisValueName.emit(data_.shortName.toString());
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['data'] && this.data) {
      this.yAxisFilter.setValue(this.dataFilter.YAxisValue);
    }
  }


  ngOnInit() {
        // setTimeout(() => {
    //     if (this.defaultSelection && this.defaultSelection.YAxisValue !== null) {
    //        this.yAxisFilter.setValue(this.defaultSelection.YAxisValue);
    //     } 
    // });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   if (this.defaultSelection && this.defaultSelection.YAxisValue !== null) {
    //     this.yAxisFilter.setValue(this.defaultSelection.YAxisValue);
    //   } 
    // });
  }

}
