import {
  Component, OnInit,
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormControl} from "@angular/forms";
import * as echarts from 'echarts';
import {US_STATES} from "./us_states";

const settings = [
  {
    name: 'crime',
    label: 'Crime',
    canBeOutput: false,
  },
  {
    name: 'water_habits',
    label: 'Water habits',
    canBeOutput: false,
  },
  {
    name: 'food_demand',
    label: 'Food demand',
    canBeOutput: false,
  },
  {
    name: 'export',
    label: 'Export',
    canBeOutput: false,
  },
  {
    name: 'water_access',
    label: 'Water access',
    canBeOutput: false,
  },
  {
    name: 'geopolitics',
    label: 'Geopolitics',
    canBeOutput: false,
  },
  {
    name: 'alfalfa_hay_production',
    label: 'Alfalfa/Hay production',
    canBeOutput: true,
  },
  {
    name: 'employment',
    label: 'Employment',
    canBeOutput: true,
  }
]

const query = {
  crime: 'low',
  water_habits: 'low',
  food_demand: 'medium',
  export: 'low',
  water_access: 'high',
  geopolitics: 'low',
  alfalfa_hay_production: 'low',
  __output__: 'employment',
}

const result = {
  predictedValue: 0.8409,
}

const cachedResults = {
  query: query,
  result: result,
}

function getNoiseHelper() {
  class Grad {
    x: number;
    y: number;
    z: number;

    constructor(x: number, y: number, z: number) {
      this.x = x;
      this.y = y;
      this.z = z;
    }

    dot2(x: number, y: number) {
      return this.x * x + this.y * y;
    }

    dot3(x: number, y: number, z: number) {
      return this.x * x + this.y * y + this.z * z;
    }
  }

  const grad3 = [
    new Grad(1, 1, 0),
    new Grad(-1, 1, 0),
    new Grad(1, -1, 0),
    new Grad(-1, -1, 0),
    new Grad(1, 0, 1),
    new Grad(-1, 0, 1),
    new Grad(1, 0, -1),
    new Grad(-1, 0, -1),
    new Grad(0, 1, 1),
    new Grad(0, -1, 1),
    new Grad(0, 1, -1),
    new Grad(0, -1, -1)
  ];
  const p = [
    151, 160, 137, 91, 90, 15, 131, 13, 201, 95, 96, 53, 194, 233, 7, 225, 140,
    36, 103, 30, 69, 142, 8, 99, 37, 240, 21, 10, 23, 190, 6, 148, 247, 120,
    234, 75, 0, 26, 197, 62, 94, 252, 219, 203, 117, 35, 11, 32, 57, 177, 33,
    88, 237, 149, 56, 87, 174, 20, 125, 136, 171, 168, 68, 175, 74, 165, 71,
    134, 139, 48, 27, 166, 77, 146, 158, 231, 83, 111, 229, 122, 60, 211, 133,
    230, 220, 105, 92, 41, 55, 46, 245, 40, 244, 102, 143, 54, 65, 25, 63, 161,
    1, 216, 80, 73, 209, 76, 132, 187, 208, 89, 18, 169, 200, 196, 135, 130,
    116, 188, 159, 86, 164, 100, 109, 198, 173, 186, 3, 64, 52, 217, 226, 250,
    124, 123, 5, 202, 38, 147, 118, 126, 255, 82, 85, 212, 207, 206, 59, 227,
    47, 16, 58, 17, 182, 189, 28, 42, 223, 183, 170, 213, 119, 248, 152, 2, 44,
    154, 163, 70, 221, 153, 101, 155, 167, 43, 172, 9, 129, 22, 39, 253, 19, 98,
    108, 110, 79, 113, 224, 232, 178, 185, 112, 104, 218, 246, 97, 228, 251, 34,
    242, 193, 238, 210, 144, 12, 191, 179, 162, 241, 81, 51, 145, 235, 249, 14,
    239, 107, 49, 192, 214, 31, 181, 199, 106, 157, 184, 84, 204, 176, 115, 121,
    50, 45, 127, 4, 150, 254, 138, 236, 205, 93, 222, 114, 67, 29, 24, 72, 243,
    141, 128, 195, 78, 66, 215, 61, 156, 180
  ];
  // To remove the need for index wrapping, double the permutation table length
  let perm = new Array(512);
  let gradP = new Array(512);
  // This isn't a very good seeding function, but it works ok. It supports 2^16
  // different seed values. Write something better if you need more seeds.
  function seed(seed: number) {
    if (seed > 0 && seed < 1) {
      // Scale the seed out
      seed *= 65536;
    }
    seed = Math.floor(seed);
    if (seed < 256) {
      seed |= seed << 8;
    }
    for (let i = 0; i < 256; i++) {
      let v;
      if (i & 1) {
        v = p[i] ^ (seed & 255);
      } else {
        v = p[i] ^ ((seed >> 8) & 255);
      }
      perm[i] = perm[i + 256] = v;
      gradP[i] = gradP[i + 256] = grad3[v % 12];
    }
  }

  seed(0);

  // ##### Perlin noise stuff
  function fade(t: number) {
    return t * t * t * (t * (t * 6 - 15) + 10);
  }

  function lerp(a: number, b: number, t: number) {
    return (1 - t) * a + t * b;
  }

  // 2D Perlin Noise
  function perlin2(x: number, y: number) {
    // Find unit grid cell containing point
    let X = Math.floor(x),
      Y = Math.floor(y);
    // Get relative xy coordinates of point within that cell
    x = x - X;
    y = y - Y;
    // Wrap the integer cells at 255 (smaller integer period can be introduced here)
    X = X & 255;
    Y = Y & 255;
    // Calculate noise contributions from each of the four corners
    let n00 = gradP[X + perm[Y]].dot2(x, y);
    let n01 = gradP[X + perm[Y + 1]].dot2(x, y - 1);
    let n10 = gradP[X + 1 + perm[Y]].dot2(x - 1, y);
    let n11 = gradP[X + 1 + perm[Y + 1]].dot2(x - 1, y - 1);
    // Compute the fade curve value for x
    let u = fade(x);
    // Interpolate the four results
    return lerp(lerp(n00, n10, u), lerp(n01, n11, u), fade(y));
  }

  return {
    seed,
    perlin2
  };
}

const SMALL_CHART_OPTIONS = {
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['Rainfall', 'Evaporation']
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {show: true, readOnly: false},
      magicType: {show: true, type: ['line', 'bar']},
      restore: {show: true},
      saveAsImage: {show: true}
    }
  },
  calculable: true,
  xAxis: [
    {
      type: 'category',
      // prettier-ignore
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: 'Rainfall',
      type: 'bar',
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ],
      markPoint: {
        data: [
          {type: 'max', name: 'Max'},
          {type: 'min', name: 'Min'}
        ]
      },
      markLine: {
        data: [{type: 'average', name: 'Avg'}]
      }
    },
    {
      name: 'Evaporation',
      type: 'bar',
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
      ],
      markPoint: {
        data: [
          {name: 'Max', value: 182.2, xAxis: 7, yAxis: 183},
          {name: 'Min', value: 2.3, xAxis: 11, yAxis: 3}
        ]
      },
      markLine: {
        data: [{type: 'average', name: 'Avg'}]
      }
    }
  ]
}

@Component({
  selector: 'app-story-v2',
  templateUrl: './story-v2.component.html',
  styleUrls: ['./story-v2.component.css'],
})
export class StoryV2Component implements OnInit {
  housingDemand: any;
  woodProduction: any;
  revenue: any;

  section1 = {
    title: 'Housing demand',
    description: `When there is an increased demand for housing, either due to
            population growth, urbanization, or economic factors, the
            construction industry experiences a surge. Wood is a fundamental
            material in construction, used for framing, flooring, and various
            other purposes.`
  }
  section2 = {
    title: 'Wood Production',
    description: `High demand for wood without a corresponding increase in the
            efficiency and capacity of the wood production process can lead
            to shortages. Logging and processing facilities may struggle to
            keep up with the demand, affecting the overall volume and
            quality of wood available for construction.`
  }

  revenueHousingControl = new FormControl()
  revenueWoodControl = new FormControl()

  mapOptions = {
    tooltip: {
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2
    },
    visualMap: {
      left: 'right',
      min: 500000,
      max: 38000000,
      inRange: {
        color: [
          '#fee090',
          '#fdae61',
          '#f46d43',
          '#d73027',
          '#a50026'
        ]
      },
      text: ['High', 'Low'],
      calculable: true
    },
    toolbox: {
      show: true,
      //orient: 'vertical',
      left: 'left',
      top: 'top',
      feature: {
        dataView: {readOnly: false},
        restore: {},
        saveAsImage: {}
      }
    },
    series: [
      {
        name: 'USA PopEstimates',
        type: 'map',
        roam: true,
        map: 'USA',
        zoom: 2.5,
        emphasis: {
          label: {
            show: true
          }
        },
        data: [
          {name: 'Alabama', value: 4822023},
          {name: 'Alaska', value: 731449},
          {name: 'Arizona', value: 6553255},
          {name: 'Arkansas', value: 2949131},
          {name: 'California', value: 38041430},
          {name: 'Colorado', value: 5187582},
          {name: 'Connecticut', value: 3590347},
          {name: 'Delaware', value: 917092},
          {name: 'District of Columbia', value: 632323},
          {name: 'Florida', value: 19317568},
          {name: 'Georgia', value: 9919945},
          {name: 'Hawaii', value: 1392313},
          {name: 'Idaho', value: 1595728},
          {name: 'Illinois', value: 12875255},
          {name: 'Indiana', value: 6537334},
          {name: 'Iowa', value: 3074186},
          {name: 'Kansas', value: 2885905},
          {name: 'Kentucky', value: 4380415},
          {name: 'Louisiana', value: 4601893},
          {name: 'Maine', value: 1329192},
          {name: 'Maryland', value: 5884563},
          {name: 'Massachusetts', value: 6646144},
          {name: 'Michigan', value: 9883360},
          {name: 'Minnesota', value: 5379139},
          {name: 'Mississippi', value: 2984926},
          {name: 'Missouri', value: 6021988},
          {name: 'Montana', value: 1005141},
          {name: 'Nebraska', value: 1855525},
          {name: 'Nevada', value: 2758931},
          {name: 'New Hampshire', value: 1320718},
          {name: 'New Jersey', value: 8864590},
          {name: 'New Mexico', value: 2085538},
          {name: 'New York', value: 19570261},
          {name: 'North Carolina', value: 9752073},
          {name: 'North Dakota', value: 699628},
          {name: 'Ohio', value: 11544225},
          {name: 'Oklahoma', value: 3814820},
          {name: 'Oregon', value: 3899353},
          {name: 'Pennsylvania', value: 12763536},
          {name: 'Rhode Island', value: 1050292},
          {name: 'South Carolina', value: 4723723},
          {name: 'South Dakota', value: 833354},
          {name: 'Tennessee', value: 6456243},
          {name: 'Texas', value: 26059203},
          {name: 'Utah', value: 2855287},
          {name: 'Vermont', value: 626011},
          {name: 'Virginia', value: 8185867},
          {name: 'Washington', value: 6897012},
          {name: 'West Virginia', value: 1855413},
          {name: 'Wisconsin', value: 5726398},
          {name: 'Wyoming', value: 576412},
          {name: 'Puerto Rico', value: 3667084}
        ]
      }
    ]
  }

  smallCharts = [
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
    {
      label: 'Test',
      options: SMALL_CHART_OPTIONS,
    },
  ]

  barChartOptions = {
    xAxis: {
      type: 'category',
      data: ['1', '2', '3', '4', '5', '6']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [
          this.gradientGenerator(120, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(200, '#3e77ce', '#94c4ea'),
          this.gradientGenerator(150, '#f27146', '#f27146'),
          this.gradientGenerator(80, '#e85857', '#ed7b79'),
          this.gradientGenerator(70, '#ffd15b', '#ffd15b'),
          this.gradientGenerator(110, '#f7a03c', '#f9c883'),
        ],
        type: 'bar'
      }
    ]
  };
  lineChartOptions = {
    legend: {},
    tooltip: {
      trigger: 'axis',
      showContent: false
    },
    dataset: {
      source: [
        ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
        ['1', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
        ['2', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
        ['3', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
        ['4', 25.2, 37.1, 41.2, 18, 33.9, 49.1]
      ]
    },
    xAxis: {type: 'category'},
    yAxis: {gridIndex: 0},
    series: [
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: {focus: 'series'}
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: {focus: 'series'}
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: {focus: 'series'}
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: {focus: 'series'}
      },
    ]
  };
  heatmapChartOptions = (() => {
    let noise = getNoiseHelper();
    let xData: number[] = [];
    let yData: number[] = [];

    function generateData(theta: number, min: number, max: number) {
      let data: number[][] = [];
      for (let i = 0; i <= 200; i++) {
        for (let j = 0; j <= 100; j++) {
          // let x = (max - min) * i / 200 + min;
          // let y = (max - min) * j / 100 + min;
          data.push([i, j, noise.perlin2(i / 40, j / 20) + 0.5]);
          // data.push([i, j, normalDist(theta, x) * normalDist(theta, y)]);
        }
        xData.push(i);
      }
      for (let j = 0; j < 100; j++) {
        yData.push(j);
      }
      return data;
    }

    let data = generateData(2, -5, 5);
    return {
      tooltip: {},
      xAxis: {
        type: 'category',
        data: xData
      },
      yAxis: {
        type: 'category',
        data: yData
      },
      visualMap: {
        left: 'right',
        top: 'center',
        min: 0,
        max: 1,
        calculable: true,
        realtime: false,
        inRange: {
          color: [
            '#313695',
            '#4575b4',
            '#74add1',
            '#abd9e9',
            '#e0f3f8',
            '#ffffbf',
            '#fee090',
            '#fdae61',
            '#f46d43',
            '#d73027',
            '#a50026'
          ]
        }
      },
      series: [
        {
          name: 'Gaussian',
          type: 'heatmap',
          data: data,
          emphasis: {
            itemStyle: {
              borderColor: '#333',
              borderWidth: 1
            }
          },
          progressive: 1000,
          animation: false
        }
      ]
    }
  })()
  bellBarChartOptions = {
    xAxis: {
      type: 'category',
      data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [
          this.gradientGenerator(40, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(50, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(60, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(70, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(80, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(90, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(100, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(90, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(80, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(70, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(60, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(50, '#39c8f9', '#a7e4f4'),
          this.gradientGenerator(40, '#39c8f9', '#a7e4f4'),
        ],
        type: 'bar'
      }
    ]
  };

  areaChartOptions = {
    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    title: {
      text: 'Gradient Stacked Area Chart'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: ['Line 1', 'Line 2', 'Line 3', 'Line 4', 'Line 5']
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Line 2',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(0, 221, 255)'
            },
            {
              offset: 1,
              color: 'rgb(77, 119, 255)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: [120, 282, 111, 234, 220, 340, 310]
      },
    ]
  }

  tab = 0;
  tab2 = 0;

  variables: any[] = settings;

  inputs: any[] = [
    {
      name: 'Crime',
      variable: 0.60012,
      width: 90,
    },
    {
      name: 'Water habits',
      variable: 0.21,
      width: 70,
    },
    {
      name: 'Food demand',
      variable: 0.002,
      width: 60,
      highlighted: true,
    },
    {
      name: 'Export',
      variable: 0.01,
      width: 40,
    },
    {
      name: 'Water access',
      variable: 0.01,
      width: 20,
      highlighted: true,
    },
    {
      name: 'Geopolitics',
      variable: 0.4,
      width: 10,
    },
    {
      name: 'Alfalfa/Hay production',
      output: true,
      width: 10,
    },
    {
      name: 'Employment',
      output: true,
      width: 10,
    }
  ]

  constructor(
    private route: ActivatedRoute
  ) {
  }

  gradientGenerator(value: number, startColor: string, endColor: string) {
    return {
      value: value,
      itemStyle: {
        color: {
          type: 'lineal',
          y: 0.5,
          colorStops: [
            {
              offset: 0,
              color: startColor
            },
            {
              offset: 1,
              color: endColor
            }
          ]
        }
      }
    }
  }

  // variables necesarias
  // red / network / supply chain
  // region / EU / MEX / CAN / TRI

  // nodos / x nodos / Housing demand / Wood


  ngOnInit() {
    echarts.registerMap('USA', US_STATES as any, {
      Alaska: {
        left: -131,
        top: 25,
        width: 15
      },
      Hawaii: {
        left: -110,
        top: 28,
        width: 5
      },
      'Puerto Rico': {
        left: -76,
        top: 26,
        width: 2
      }
    });
  }

  run() {
    this.predictedValue = 12.98;
  }

  predictedValue: any = null;
  accuracy: any = 0.9790;
}
