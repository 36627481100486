<div class="app">
  <div class="router-outlet">
    <router-outlet></router-outlet>
  </div>

  <footer class="prevent-select">
    <img src="{{ baseAssets }}/ATM-logo.svg" alt="ATM-LOGO" class="atm-logo" />
    <div class="footer-links">
      <span *ngFor="let link of footerLinks">
        <a [href]="link.href" target="_blank">{{link.description}}</a>
        <span class="separator">{{'//'}}</span>
      </span>
    </div>
    <img src="{{ baseAssets }}/TAMUS-White-Logo.png" alt="TAMU-LOGO" class="tamu-logo">
  </footer>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-running-dots" [fullScreen]="true">
  <p style="color: white"> Consulting Information.... </p>
</ngx-spinner>
