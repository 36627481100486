import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-bar-chart-horizontal',
  templateUrl: './bar-chart-horizontal.component.html',
  styleUrls: ['./bar-chart-horizontal.component.css']
})
export class BarChartHorizontalComponent implements AfterViewInit, OnDestroy{
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() data!: any;
  @Input() title : string = 'Title';
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  constructor() {
  }
  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngAfterViewInit(): void {
    this.initHorizontalBar();
  }
  initHorizontalBar(): void {
    this.root = am5.Root.new(this.chartEl.nativeElement);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      layout: this.root.verticalLayout,
    }));
    //Add Legend config
    let legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.p50,
      x: am5.p50,
    }));

    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.title,
      fontSize: 14,
      fontWeight: "400",
      x: am5.p50,
      centerX: am5.p50,
      oversizedBehavior: "wrap",
    }));

    //Add YAxis config
    let yAxis = this.chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: 'site',

      renderer: am5xy.AxisRendererY.new(this.root, {
        inversed: true,
         cellStartLocation: 0,
         cellEndLocation: 1,
         minGridDistance: 10
      })
    }));
    yAxis.data.setAll(this.data.data!);

    let xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererX.new(this.root, {
        strokeOpacity: 0.1
      }),
      min: 0
    }));
    this.data.series.forEach((x: any) => {
      this.createSeries(this.chart, xAxis, yAxis, this.root, x.propertyName, x.longName, this.data.data!, x.color!);
    })


    legend.data.setAll(this.chart.series.values);

    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      behavior: "zoomY"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      maxWidth: 90,
      fontSize: 11,


    });

    var scrollbarY = am5.Scrollbar.new(this.root, {
      orientation: "vertical",
      marginLeft: 20,

    });

    this.chart.set("scrollbarY", scrollbarY);
    this.chart.rightAxesContainer.children.push(scrollbarY);
    this.chart.zoomOutButton.set("forceHidden", true);
    this.chart.appear(1000, 100);



  }
  createSeries(chart: am5xy.XYChart, xAxis: any, yAxis: any, root: am5.Root, field: string, name: string, data: any,  color:string) {
    let tooltip = am5.Tooltip.new(root, {
      pointerOrientation: "left",
      labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"  + (this.data.percentage == true ? "%" : "")
    });
    tooltip.label.setAll({
      fontSize: 14
    });
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      stacked: this.data.stacked == true,
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: field,
      categoryYField: 'site'
    }));

    series.columns.template.setAll({
      //height: 30,
      strokeOpacity: 0,
      width: am5.p100,
      fill: color !== "" ? am5.color(color) : undefined,
      stroke: color !== "" ? am5.color(color) : undefined,
    });

    series.bullets.push(() => {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{valueX}" + (this.data.percentage == true ? "%" : ""),
          fill: am5.color('#fff'),
          populateText: true
        })
      });
    });

    series.set("tooltip", tooltip);
    series.data.setAll(data);
    series.events.once('datavalidated', () => {
      if (this.data.data.length > 5)
        yAxis.zoomToCategories(this.data!.data.at(0)!.site!, this.data!.data.at(4)!.site!) //Scroll to top five
      else
        yAxis.zoomToCategories(this.data!.data.at(0)!.site!, this.data!.data.at(-1)!.site!) //Scroll to last item
    });
    series.appear();

    return series;
  }
}
