import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalFiltersComponent } from '../modal-filters/modal-filters.component';
import {
  faPlus,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-no-data-modal',
  templateUrl: './no-data-modal.component.html',
  styleUrls: ['./no-data-modal.component.css']
})
export class NoDataModalComponent {
  //Icons
  public faExclamationTriangle = faExclamationTriangle;

  message = ''; 
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalFiltersComponent>,
    private cdr: ChangeDetectorRef
  ) {
    this.message = data.message
  }
}
