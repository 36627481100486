import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
declare const L: any;
import 'leaflet';
import 'leaflet-timedimension';
import '../../../../../assets/js/leaflet.timedimension.point';
import '../../../../../assets/js/leaflet.timedimension.choropleth.control';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-shape-map',
  templateUrl: './dynamic-shape-map.component.html',
  styleUrls: ['./dynamic-shape-map.component.css'],
})
export class DynamicShapeMapComponent implements OnChanges {
  @ViewChild('map', { read: ElementRef })
  private mapEl!: ElementRef<HTMLElement>;
  @Input() data!: any;
  @Input() newData!: any;
  @Input() newFirstDate!: any;
  @Input() forceUpdate!: boolean;
  @Input() dates!: any;
  @Input() formatInfo!: any;
  @Input() mapInfo!: any;
  @Input() AllowValueHover!: boolean;
  @Input() typeMap!: any;

  @Output() getNewData = new EventEmitter<boolean>();

  private map: any;

  private lastDate!: Date;

  private geojson!: any;
  private timeSeries!: any;
  private player!: any;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.newData){
      if (
        changes['newData'].currentValue !== undefined &&
        changes['newData'].currentValue !== null
      ) {
        if (this.newData.features.length == 0) return;
        if (this.map !== undefined) {
          this.map.remove();
        }
        this.initMap();
        this.onReload();
        this.AddNewGeoJson();
      }
    }
  }

  ngOnDestroy(): void {}
  ngOnInit(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.lastDate = new Date(this.dates.at(-1));
    this.initMap();
    this.onReload();
  }
  stopPlayer() {
    this.player.stop();
  }

  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map('mapShape', {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 12,
        minZoom: 1,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: () => {
          return '<img class="icon-svg" src="' + environment.BaseAssets + '/house-solid.svg" alt="home-icon">';
        },
        zoomHomeTitle: 'Zoom home',
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + '-in',
          container,
          this._zoomIn
        );
        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + '-home',
          container,
          this._zoomHome
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + '-out',
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (
        html: any,
        title: any,
        className: any,
        container: any,
        fn: any
      ) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }

  onReload() {
    this.initStatesLayer();
  }
  private initStatesLayer() {
    var timeDimension = new L.TimeDimension({
      period: 'PT1H',
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player(
      {
        transitionTime: 2000,
        loop: false,
        startOver: false,
      },
      timeDimension
    );
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      speedSlider: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 1,
      timeSliderDragUpdate: false,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays,
    };
    var timeDimensionControl = new L.Control.TimeDimensionChoropleth(
      timeDimensionControlOptions
    );

    if (this.typeMap != 'Static') this.map.addControl(timeDimensionControl);

    this.player.on('animationfinished', (e: any) => {
      if (this.lastDate.getTime() === this.map.timeDimension.getCurrentTime())
        return;
      this.getNewData.emit(true);
    });
  }
  private AddNewGeoJson() {
    this.timeSeries = L.geoJSON(this.newData, {
      style: this.style,
      onEachFeature: this.AllowValueHover ? this.onEachFeature : null
    });

    this.geojson = L.timeDimension.layer.point(this.timeSeries, {
      updateTimeDimensionMode: 'replace',
      updateTimeDimension: true,
      waitForReady: true,
    });
    this.geojson.addTo(this.map);
    if (this.newFirstDate !== undefined){
      const day = this.newFirstDate.getDate().toString().padStart(2, '0');
      const month = (this.newFirstDate.getMonth() + 1).toString().padStart(2, '0');
      const year = this.newFirstDate.getFullYear();
      this.newFirstDate = `${day}/${month}/${year}`;
      this.map.timeDimension.setCurrentTime(this.newFirstDate.getCurrentTime());
    }
  }

  private style = (feature: any) => {
    let color = '';
    let fillOpacity = 1;
    let width = '100%';
    if (feature!.properties!.id !== undefined) {
      color = this.data.shapes.at(
        parseInt(feature!.properties!.id.substr(1))
      ).shapeColor;
      fillOpacity =
        this.map.timeDimension.getCurrentTime() ===
        new Date(feature!.properties!.time).getTime()
          ? 1
          : 0;
      width =
        this.map.timeDimension.getCurrentTime() ===
        new Date(feature!.properties!.time).getTime()
          ? '100%'
          : '0%';
    }
    return {
      weight: 0.5,
      opacity: fillOpacity,
      color: color,
      dashArray: '',
      fillOpacity: fillOpacity,
      fillColor: color,
      with: width,
    };
  };

  private highLightFeature = (e: any) => {
    var layer = e.target;

    layer.setStyle({
      weight: 3.5,
      color: '#99CCFF',
      dashArray: '',
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  private resetFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 5,
      color: this.mapInfo.color,
      fillOpacity: 1,
    });
    layer.bringToFront();
  };

  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
    });
    layer
      .bindTooltip(
        `<p style="font-weight: 700; text-align: center;"> Value:</p>${feature.properties.value}`
      )
      .openTooltip();
  };
}
