<div class="main">
  <section>
    <header>
      <a class="prevent-select" href="https://r13-cbts-sgl.engr.tamu.edu/" target="_blank">
        <img src="{{ baseAssets }}/TAMU-HEADER.png" alt="tamu-header-logo">
      </a>
    </header>
    <h1 [@fadeIn]="{value: 1, params: {delay: 0}}">INFORMATION ABOUT THE PROJECT</h1>
    <div class="text-description">
      <p [@fadeIn]="{value: 1, params: {delay: 50}}">
        Welcome to the SGL-CBTS supply chain risk analysis platform, a tool that allows you to monitor and improve
        specific supply chain performance across North America (United States, Mexico, and Canada).
      </p>
      <p [@fadeIn]="{value: 1, params: {delay: 150}}">
        It’s supported by the computational infrastructure (CBTS-Data Lake System), and the operational infrastructure
        (CBTS-Data Management Work Flow), to compute the risk analytics needed to improve operational decision-making
        on U.S. supply chains across North America.
      </p>
      <p [@fadeIn]="{value: 1, params: {delay: 250}}">
        With this dashboard, you can:
      </p>
      <ul [@fadeIn]="{value: 1, params: {delay: 350}}">
        <li>Analyze relevant and important information from trusted and official data sources from the three
          countries, and explore first and second-order statistics, from the main variables related to each supply
          chain.</li>
        <li>Play around with risk scenarios, such as demand fluctuations, supplier disruptions, and transportation
          delays, and see how they affect the supply chain KPIs and metrics.</li>
      </ul>
    </div>
  </section>
  <section [@listAnimation]="modelList.length" class="model-list">
    <div class="model" *ngFor="let model of modelList;">
      <div style="width: 100%; height: 60%; display: flex; justify-content: center;">
        <img class="w-100 h-100" style="color: gray; object-fit: cover;" src="{{IMAGES_TEMP[model.url]}}" />
      </div>
      <div class="truncate">
        <span title="{{model.name}}" class="break-word">{{model.name}}</span>
      </div>
      <a class="btn mb-2" routerLink="{{'/'+country+'/'+model.url}}">Go to Explore</a>
    </div>
  </section>
</div>
