import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Optional, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, delay, firstValueFrom } from 'rxjs';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DataSourceComponent implements OnDestroy, OnChanges, AfterViewInit {
  private subscriber: Subscription;
  @Input() idScenario!: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  variableTypeFilter = new FormControl<string>('');
  variablesOptionFilter = new FormControl<string>('');

  data: any;
  ecdf: any;
  public valuesData: any;
  public valuesEcdf: any;

  dataDataSource: any;
  ecdfDataSource: any;
  chartData: any[] = [];  // Array para almacenar datos de cada gráfico

  filtersRaw: any;
  typesVar: any;
  typeSelection: any;
  optionsByType: any[] = [];
  optionsByTypeSelection: any;


  private onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private generalservice: GeneralService,
    private spinner: NgxSpinnerService,
    public homeStore: HomeStore,
    private cdr: ChangeDetectorRef,
    private timeVisualizationService: TimeVisualizationTemplateService,
    @Optional() private dialog: MatDialog,
    private httpClient: HttpClient
  ) {
    this.subscriber = homeStore.appContextObservable.subscribe(async (ctx) => {
      this.optionsByType = [];
      this.optionsByTypeSelection = null;
      this.typesVar = null;
      this.typeSelection = null;

      let scenariosList = await homeStore.getIdScenario();

      if (scenariosList.length != 0) {
        this.idScenario = scenariosList.values[0].idScenario;
      }

      const idsce = { 
        idScenario: this.idScenario
      };

      this.filtersRaw = await firstValueFrom(this.generalservice.getFiltersDataSourceRS(idsce));

      if (this.filtersRaw && this.filtersRaw.length > 0 && this.filtersRaw[0].values && this.filtersRaw[0].values.length > 0)
      {
        this.typesVar = this.filtersRaw[0];
        this.typeSelection = this.filtersRaw[0].values[0].value;
       
        var cont = 0;
        this.optionsByType = [];
        this.filtersRaw[1].values.forEach((element: { idScenario: any; value: any; })=> {
          if(element.idScenario ==  this.typeSelection){
            let option = {
              id: cont++,
              value: element.value
            }
            this.optionsByType.push(option);
          }
        });

        this.optionsByTypeSelection = this.optionsByType[0].value;

        const datosmodel = { 
          idScenario:this.idScenario,
          selection: this.typeSelection,
          optionVar: this.optionsByTypeSelection,
        };

        this.dataDataSource = await firstValueFrom(
          this.generalservice.getDataSource(datosmodel)
        );

        if(this.dataDataSource != null) this.getValuesCharts();
      }

    });
    
  }


  onOptionChangeSecond (event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    
    this.optionsByTypeSelection = selectedOption;

    const datosmodel = { 
      idScenario:this.idScenario,
      selection: this.typeSelection,
      optionVar: this.optionsByTypeSelection, 
    };

    this.subscriber = this.homeStore.appContextObservable.subscribe(async (ctx) => {
    this.dataDataSource = await firstValueFrom(
      this.generalservice.getDataSource(datosmodel)
    );

    if(this.dataDataSource != null) this.getValuesCharts();

  });
  }

  
  onOptionChangeFirst (event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
  
    this.typeSelection = selectedOption;
   

    this.subscriber = this.homeStore.appContextObservable.subscribe(async (ctx) => {
        

  var cont = 0;
  this.optionsByType = [];
  this.filtersRaw[1].values.forEach((element: { idScenario: any; value: any; })=> {
    if(element.idScenario ==  this.typeSelection){
      let option = {
        id: cont++,
        value: element.value
      }
      this.optionsByType.push(option);
    }
  });

  this.optionsByTypeSelection = this.optionsByType[0].value;
  const datosmodel = { 
    idScenario:this.idScenario,
    selection: this.typeSelection,
    optionVar: this.optionsByTypeSelection, 
  };
    this.dataDataSource = await firstValueFrom(
      this.generalservice.getDataSource(datosmodel)
    );

    if(this.dataDataSource != null) this.getValuesCharts();

  });



  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    
  }
  ngOnInit() {
  }

  getValuesCharts(){
     // Reiniciar el array para gráficos
     this.chartData = [];

     // Iterar sobre cada registro y preparar los datos para los gráficos
     this.dataDataSource.forEach((record: any) => {
       let generalData = record;
       let valuesData = record.data;
       let valuesEcdf = record.ecdf.data;
 
       let lineData = {
         title: generalData.axisTitle + " (" + valuesData.unit + ")", 
         yLabel: generalData.axisTitle, 
         xLabel: "Time",
         data: this.timeVisualizationService.getXY(
           valuesData,
           true
         ),
         style: {
           color: "#0000ff"
         }
       };
 
       let ecdfData = {
         title: generalData.axisTitle + " Empirical Cumulative Density Function", 
         yLabel: "ECDF", 
         xLabel: generalData.axisTitle,
         data: valuesEcdf,
         style: {
           colorSerieData: "#0000ff",
           colorSerieNormalFit: "#ffa7ff",    
         }
       };
 
       this.chartData.push({
         lineData: lineData,
         ecdfData: ecdfData
       });
     });
    
  }


  emitFirstValue($event: any) {
    this.firstValue.emit($event + '1');
    this.spinner.show();
  }
}
