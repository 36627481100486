<!--<div class="two-filter-container">
  <mat-form-field>
      <mat-label>{{this.data.firstFilter.name}}</mat-label>
      <mat-select [formControl]="firstFilter">
        <mat-option *ngFor="let item of this.data.firstFilter.values" [value]="item.id">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field> 
</div>-->
<div class="row">
<div class="col-2">
  <label for="firstFilter" class="form-label subtitle">{{this.data.firstFilter.name}}</label>
    <select class="form-select"  [formControl]="firstFilter">
      <option *ngFor="let item of this.data.firstFilter.values" [value]="item.id">
        {{item.value}}
      </option>
    </select>
</div>
</div>

