import { Component } from '@angular/core';
import { concatMap, of } from 'rxjs';
import { ReferenceModel } from 'src/app/core/models/menu.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  public referenceData!: ReferenceModel[];
  constructor(private homeService: HomeService, private homeStore: HomeStore) {
    this.homeStore.appContextObservable
      .pipe(
        concatMap((ctx) => {
          if (!ctx.section?.url) return of([]);
          const model = { bayesianId: ctx.section?.url };
          return this.homeService.getBayesianReferences(model);
        })
      )
      .subscribe((res) => {
        this.referenceData = res;
      });
  }
}
