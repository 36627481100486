import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class Covid19Service {

  constructor(private apiService: ApiServiceService) { }

  getCovid19National(model:any): Observable<any> {

    return this.apiService.post(`${ApiRouting.covid19}/${ApiRouting.national}`, model);
  }
  
  getHealthWorkforceCn(model: any): Observable<any> {
    return this.apiService.post(`${ApiRouting.stateOfRisk}/${ApiRouting.healthWorkforce}_cn`, model);
  }
  getWeeklyDeathCountsCn(model: any): Observable<any> {
    return this.apiService.post(`${ApiRouting.stateOfRisk}/${ApiRouting.weeklyDeathCounts}_cn`, model);
  }
  getProvisionalEstimatedDeathsCn(model: any): Observable<any> {
    return this.apiService.post(`${ApiRouting.stateOfRisk}/${ApiRouting.provisionalEstimatedDeaths}_cn`, model);
  }

}
