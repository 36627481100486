import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';
import { CaseModel } from '../../models/main-page/case-model';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  public caseModel?:CaseModel;
  constructor(private apiService: ApiServiceService) { }

  getMenu(model: any): Observable<any> {
    return this.apiService.post(`MainMenu/menu`, model);
  }
}