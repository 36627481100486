<mat-card class="map-container">
    <mat-card-header>
        <h2>
          {{this.data.title | titlecase}}
        </h2>
      </mat-card-header>
        <div #chart class="bar"></div>

</mat-card>

