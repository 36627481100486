<div class="row">
  <div class="col-2">
    <label for="selectoryearFilter" class="form-label subtitle">Select year(s)</label>
    <select class="form-select" [formControl]="selectoryearFilter" multiple>
      <option *ngFor="let item of listYears" [value]="item">
        {{ item }}
      </option>
    </select>
  </div>
  <div class="col-2">
    <button class="btn btn-primary" (click)="submitSelection()" style="margin-top: 25px;">Submit year(s)</button>
  </div>
</div>