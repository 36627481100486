import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, CanActivateChild } from '@angular/router';
import { environment } from 'src/environments/environment.development';
import { AuthService } from '../services/authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.validate(next, state)
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.validate(next, state);
  }

  /**
   * 
   * @param next 
   * @param state 
   * @returns true: Permiter acceder al módulo, false: Redirecciona al login.
   */
  private validate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    // Verifica si el ambiente NO es de aseguramiento de calidad.
    // Únicamente para el ambiente QA aplica el módulo de autenticación, lo que significa que en los demás ambientes no se valida la autenticación.
    if (!environment.qa) return true;

    // Si existe usuario autenticado permite cargar el módulo.
    if (this.authService.isUserAuthenticated) {
      return true;
    }

    // Si el usuario no está autenticado envía al login.
    this.router.navigate(['/login', btoa(state.url)], { skipLocationChange: true });

    return false;
  }
}