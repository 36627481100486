import {Component, Input, OnInit} from '@angular/core';
import {CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList} from "@angular/cdk/drag-drop";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {findResult, generateResult, MOCK_RESULTS} from "./ResultsMock";

const omit = (obj: any, props: string[]) => {
  obj = { ...obj }
  props.forEach(prop => delete obj[prop])
  return obj
}

@Component({
  selector: 'app-data-driven-model-calculator',
  templateUrl: './data-driven-model-calculator.component.html',
  styleUrls: ['./data-driven-model-calculator.component.css']
})
export class DataDrivenModelCalculatorComponent implements OnInit {
  @Input() variables: any[] = []

  inputsForm: FormGroup | undefined;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.inputsForm = this.fb.group(
      this.variables.reduce((controls, variable) => {
        return {
          ...controls,
          [variable.name]: this.fb.control('', {
            validators: [Validators.required]
          }),
        }
      }, {
        __output__: this.fb.control(null, {
          validators: [Validators.required]
        })
      })
    )

    console.log(MOCK_RESULTS)

    console.log(this.inputsForm)
  }

  variablesLevels = new Set()

  output: any = null;

  result: any = null;

  onDrop(event: CdkDragDrop<any[]>) {
    const variable = event.item.data

    if (!variable) return

    this.setOutput(variable)
  }

  removeOutput(event: MouseEvent) {
    event.stopPropagation()

    this.inputsForm?.controls['__output__']?.setValue(undefined)
    this.inputsForm?.controls[this.output.name].setValidators([Validators.required]);
    this.inputsForm?.controls[this.output.name].updateValueAndValidity();
    this.inputsForm?.updateValueAndValidity()

    this.output = undefined
  }

  setOutput(variable: any) {
    this.inputsForm?.controls['__output__']?.setValue(variable.name)
    this.inputsForm?.controls[variable.name].setValue(undefined);
    this.inputsForm?.controls[variable.name].setValidators([]);
    this.inputsForm?.controls[variable.name].updateValueAndValidity();
    this.inputsForm?.updateValueAndValidity()
    this.output = variable;
  }

  onEntered(event: CdkDragEnter) {
    console.log(event)
  }

  enterPredicate(drag: CdkDrag, drop: CdkDropList): boolean {
    // console.log(drag)
    // console.log(drop)
    return false;
  }

  run() {
    if (this.inputsForm?.valid) {
      const query = omit(this.inputsForm?.value, [this.inputsForm?.value['__output__']])
      console.log(query)
      console.log(generateResult(query))
      this.result = generateResult(query)
    }
  }
}
