import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

export const  mvmLinksWoodUSA: GraphLinkModel[] = [
  {
    "source": "Commercial\nConstruction",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Commercial\nConstruction",
    "target": "Unemployment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nPolicies",
    "target": "Forestry\nSector",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nPolicies",
    "target": "Trucks\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nPolicies",
    "target": "Trucks\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Forest\nFires",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Roadways\nInfrastructure\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Forestry\nSector",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Roadways\nInfrastructure\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roadways\nInfrastructure\nRM-S",
    "target": "Trucks\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Residential\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nWeather",
    "target": "Commercial\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nDiseases",
    "target": "Forest\nFires",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nDiseases",
    "target": "Forestry\nSector",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nFires",
    "target": "Roadways\nInfrastructure\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roadways\nInfrastructure\nS-M",
    "target": "Trucks\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nFires",
    "target": "Forestry\nSector",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nFires",
    "target": "Roadways\nInfrastructure\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nFires",
    "target": "Raw\nTimber\nSupplier",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nInsects",
    "target": "Forest\nFires",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forest\nInsects",
    "target": "Forestry\nSector",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nPractices",
    "target": "Forest\nDiseases",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nPractices",
    "target": "Forest\nInsects",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nPractices",
    "target": "Forest\nFires",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nSector",
    "target": "Trucks\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nSector",
    "target": "Raw\nTimber\nSupplier",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Forestry\nSector",
    "target": "Carbon\nDioxide\nEmissions",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Interest\nRates",
    "target": "Residential\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Interest\nRates",
    "target": "Commercial\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nTrade\nPolicies",
    "target": "Sawmill\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nTimber\nSupplier",
    "target": "Trucks\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nTimber\nSupplier",
    "target": "Sawmill\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nTimber\nSupplier",
    "target": "Carbon\nDioxide\nEmissions",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Residential\nConstruction",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Residential\nConstruction",
    "target": "Housing\nStarts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Residential\nConstruction",
    "target": "Unemployment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Residential\nConstruction",
    "target": "Housing\nPrices",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roadways\nInfrastructure\nRM\nS",
    "target": "Trucks\nRM-S",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roadways\nInfrastructure\nS\nM",
    "target": "Trucks\nS-M",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sawmill\nProduction",
    "target": "Residential\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sawmill\nProduction",
    "target": "Commercial\nConstruction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sawmill\nProduction",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\nRM\nS",
    "target": "Raw\nTimber\nSupplier",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\nS\nM",
    "target": "Sawmill\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  }
];
