<div style="display: flex; justify-content: flex-start;gap: 2rem;">
  <button class="btn btn-home" *ngIf="this.dataRes.hasStudyButton" (click)="openStudyDialog()">Select variable</button>
</div>
<br />

<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab label="Metadata & Visualization">
    <ng-template matTabContent>
        <div>
          <div style="padding: 1rem; background-color: var(--white);">
            <h2>{{this.dataRes.data.metadata.name}}</h2>
              <p>
                  {{this.dataRes.data.metadata.description}}
              </p>
              <div style="padding-left: 3rem;">
                <p><span style="font-weight: 700;">Risk Component Classification:</span> {{this.dataRes.data.metadata.classification == "" ? "N/A" : this.dataRes.data.metadata.classification}}</p>
                <p><span style="font-weight: 700;">Type of Evidence:</span> {{this.dataRes.data.metadata.evidenceType}}</p>
                <p><span style="font-weight: 700;">Temporal Resolution:</span> {{this.dataRes.data.metadata.temporalResolution}}</p>
                <p><span style="font-weight: 700;">Time Period:</span> {{this.dataRes.data.metadata.timePeriod}}</p>
                <p><span style="font-weight: 700;">Data Source:</span> {{this.dataRes.data.metadata.source}}</p>

              </div>
          </div>
          <br>
          <mat-card>
            <div *ngIf="this.dataRes.hasFilter">
              <div *ngIf="this.dataRes.filterType === this.filterType.oneFilter">

                  <div *ngIf="this.dataRes.filterInfoSecond">
                    <app-one-single-filter [data]="this.dataRes.filterInfoSecond"
                                          (firstValue)="emitSecondValue($event)"></app-one-single-filter>
                  </div>

                  <div *ngIf="!this.dataRes.filterInfoSecond">
                    <app-one-single-filter [data]="this.dataRes.filterInfo"
                                          (firstValue)="emitSecondValue($event)"></app-one-single-filter>
                  </div>
              </div>
              <div *ngIf="this.dataRes.filterType === this.filterType.twoFilters">
                <app-two-single-filters [data]="this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"
                                        (secondValue)="emitSecondValue($event)"></app-two-single-filters>
              </div>
            </div>
            <div class="row">

              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="principal-content" *ngIf="this.XYCategoryChart != undefined">
                  <app-line-category [data]="this.XYCategoryChart"
                      [barId]="'line-category-data'" style="--minWidth: 0px; --minHeight: 300px;"></app-line-category>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="this.ecdfChart != undefined">
                    <app-ecdf-chart [data]="this.ecdfChart.data"
                                      [hasMinMax]="false"
                                      [datasetPosition]="'top'"
                                      [datasetAlign]="'center'"
                                      [xLabel]="this.ecdfChart.xLabel"
                                      [yLabel]="this.ecdfChart.yLabel"
                                      [title]="this.ecdfChart.title"></app-ecdf-chart>
                  </div>

                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="this.histogram != undefined">
                    <app-d3-histogram
                      [data]="this.histogram.data"
                      [plusMax]="this.dataRes.plusMax"
                      [title]="this.histogram.title"
                      [xLabel]="this.histogram.xLabel"
                      [yLabel]="this.histogram.yLabel"
                      [Id]="'histogram-county'"></app-d3-histogram>
                  </div>

                </div>

              </div>

            </div>
          </mat-card>


        </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Trend Model & Residual Analysis">
    <app-trend-model #trendModel (response)="getTrendModel($event)" [algorithm]="this.algorithm"></app-trend-model>
    <mat-card>
      <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

          <div class="principal-content" *ngIf="this.XYChartTrend != undefined">
            <app-line-category [data]="this.XYChartTrend"
                [barId]="'line-trend'" ></app-line-category>
          </div>

          <div class="principal-content" *ngIf="this.XYChartRemain != undefined">
            <app-line-category [data]="this.XYChartRemain"
                [barId]="'line-Remain'" ></app-line-category>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"  *ngIf="this.ecdfChartTrend != undefined">
              <app-ecdf-chart   [Id]="'myTrendChart'"
                                [data]="this.ecdfChartTrend.data"
                                [hasMinMax]="true"
                                [datasetPosition]="'top'"
                                [datasetAlign]="'center'"
                                [xLabel]="this.ecdfChartTrend.xLabel"
                                [yLabel]="this.ecdfChartTrend.yLabel"
                                [title]="this.ecdfChartTrend.title"></app-ecdf-chart>
            </div>

          </div>
        </div>

      </div>
    </mat-card>
</mat-tab>
<!-- <mat-tab label="Autocorrelation Analysis">
  <app-autocorrelation-model #autocorrelationModel (response)="getAutocorrelationModel($event)" [max]="this.totalRows" [nlags]="this.nlags"></app-autocorrelation-model>
  <mat-card>
    <div class="row">

      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        <div class="principal-content" *ngIf="this.XYChartAutocorrelation != undefined">
          <app-line-category [data]="this.XYChartAutocorrelation"
              [barId]="'line-trendAutocorrelation'" ></app-line-category>
        </div>

      </div>
    </div>

  </mat-card>
</mat-tab>
<mat-tab label="Seasonal & Trend Decomposition">
  <app-seasonal-model #seasonalModel (response)="getSeasonalModel($event)"></app-seasonal-model>
  <mat-card>
    <div class="row">

      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        <div class="principal-content" *ngIf="this.XYChartTrendSeasonal != undefined">
          <app-line-category [data]="this.XYChartTrendSeasonal"
              [barId]="'line-trendSeasonal'" ></app-line-category>
        </div>

        <div class="principal-content" *ngIf="this.XYChartSeasonal != undefined">
          <app-line-category [data]="this.XYChartSeasonal"
                [barId]="'line-seasonal'" ></app-line-category>
        </div>

        <div class="principal-content" *ngIf="this.XYChartResidualSeasonal != undefined">
          <app-line-category [data]="this.XYChartResidualSeasonal"
                [barId]="'line-residual'" ></app-line-category>
        </div>
      </div>

    </div>
  </mat-card>
</mat-tab>
<mat-tab label="Second-Order Statistics" #tab>
  <ng-template matTabContent>
    <div class="row" *ngIf="this.dataRes.data != undefined">
      <app-second-order  [typeTemplate]="'Time Series'" [data]="this.dataRes" [tab]="tab"></app-second-order>
    </div>
  </ng-template>
</mat-tab>
<mat-tab label="Bayesian Network Model with Synchronized Data">
  <ng-template matTabContent>
    <app-graph [isHomeView]="false"></app-graph>
  </ng-template>
</mat-tab> -->


</mat-tab-group>




