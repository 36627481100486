import { environment } from "../../../../environments/environment.development";

const BASE_URL = environment.BaseUrl + environment.Api;
export const ApiRouting = {
  ApiUrl: BASE_URL,
  auth: 'auth',
  login: 'login',
  //home
  home: 'home',
  references: 'references',
  //gas
  gas: 'gas',
  consumption: 'consumption',
  //Natuural
  natural: 'natural',
  surfaceAirTemperature:  'surfaceAirTemperature',
  precipitation:  'precipitation',
  system:  'system',
  anthropogenic: 'anthropogenic',
  manufacturing: 'manufacturing',
  stateOfRisk: 'stateofrisk',
  customer: 'customer',
  //manufacturing
  broadband: 'broadband',
  gross_production: 'paper_gross_production',
  income: 'paper_income',
  manufacturing_equipment: 'manufacturing_equipment',

  //covid19
  covid19: 'covid19',
  national: 'national',
  //Transboundary water
  transboundary: 'TransboundaryWater/',
  groundWater: 'groundwater',
  landUse: 'landUse',
  populationGrowth: 'population_growth',
  waterEfficiency_cn: 'water_use_province_cn',
  coastalFlooding: 'coastal_flooting',
  drought: 'drought',
  earthquake: 'earthquake',
  hurricane: 'hurricane',
  tornado: 'tornado',
  wildfire: 'wildfire',
  geopoliticalRisk: 'geopolitical_risk_index',
  barleyWater: 'barley_water', 
  energy_demand: 'energy_demand',
  croapAlfalfa: 'croap_alfalfa_state',
  croapState: 'croap_state',
  barley: 'barley',
  canola: 'canola',
  aquacultureProduction: 'aquaculture_production',
  agriculturalTrade: 'agricultural_trade',
  reservoirsWaterUse: 'reservoir_water_use',
  riversWaterUse: 'river_water_use',
  agriculturalProfit: 'financial_gain_agricultural_profit',
  agriculturalRevenue: 'financial_gain_agricultural_revenue',
  waterTreatment: 'water_treatment',

  //Sawmills production
  labor: 'labor',
  housingPrices: 'housing_pricing',
  producerPrices: 'producer_price_index',
  newHomeSales : 'new_home_sales', //housing demand
  newResidentialConstruction : 'new_residential_construction', //housing demand
  valueResidentialConstruction : 'value_of_residential_construction',
  numberOfEmployees: 'number_of_employees',
  woodProductWorkforce: 'wood_product_workforce',
  totalGrossProduction: 'total_gross_production',
  lumberPrices: 'lumber_prices',
  gdp: 'gdp',
  totalIncome: 'total_income',
  other_wood_products: 'other_wood_product_',
  revenue: 'revenue',
  safe_drinkig_water: 'safe_drinking_water',
  unemployment: 'unemployment',
  workforceStatistics: 'workforce_statistics',
  forestryProduction: 'forestry_production_trade',
  exportQuantityValue: 'export_quantity_value',
  //Automobile engine
  automobile: 'automobile',
  operationalCost: 'operational_cost_',
  carbon:  'carbon_',
  steel:  'steel_',
  aluminium: 'aluminium',
  healthInsurance: 'health_insurance',
  aftermarketSuppliers: 'aftermark_suppliers',
  productRecalls: 'product_recalls',
  //Shared
  dates: 'dates',
  data: 'data',
  trinational: 'tri',

  //Inorganic chemical
  chemicalManufacturing: 'chemical_manufacturing',
  windTurbine: 'wind_turbine',
  workerFatalities: 'worker_fatalities',
  chemicalIndustryInvestment: 'chemical_industry_investment',

  //corn farming
  ethanol: 'ethanol',
  alcohol: 'alcohol',
  fossil_fuel_dependence: 'fossil_fuel_dependence',

  //Semiconductor
  productionRate: 'production_rate',
  silicon: 'silicon',

  //system
  product_recall: 'product_recall',
  mineralResources: 'mineral_resources',
  titanium: 'titanium',
  net_access: 'net_access',
  aviabilityInternetService: 'aviability_internet_service',

  //public health
  peopleOneDoseVaccination: 'people_onedose_vaccination',
  fullyVaccinatedPeople: 'fully_people_vaccinated',
  healthInsuranceCoverage: 'health_insurance_coverage',
  healthWorkforce: 'health_workforce',
  weeklyDeathCounts: 'weekly_death_counts',
  provisionalEstimatedDeaths: 'provisional_estimated_deaths',
  emergencyDepartmentVisits: 'emergency_department_visits',
  medicalConsultations: 'medical_consultation',
  smarthphoneIndustry: 'smartphone_industry',
  covid19deaths: 'covid19_deaths',
  sexDistribution: 'sex_distribution',
  ageDistribution: 'age_distribution',
  sexCanadianPopulation: 'sex_canadian_population',
  ageCanadianPopulation: 'age_canadian_population',
  digitalHealthRevenue: 'digital_health_revenue',
  
  generalVar: 'general_variables',
  generalTemplates: 'general_template',
};
