import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-rail-infrastructure',
  templateUrl: './rail-infrastructure.component.html',
  styleUrls: ['./rail-infrastructure.component.css']
})
export class RailInfrastructureComponent {
  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();

  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }

  ngOnInit(): void {
    this.spinner.show();
    
    if (this.isUSA) {
      this.getDataUs();
    } else{
      this.spinner.hide();
    }

    if(this.filter!=undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
     this.select = result ? result.itemId : null;
      this.filterInfo=this.filter;
      this.hasStudyButton=true;
    
    }

  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

  getDataUs() {
    const dataMap = {
      center: MapCenter.USA,
      scale: ['#CC9966', '#996633', '#999999'].reverse(), // Tonos de café/gris
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#666666', // Borde gris
      color: '#663300', // Color de las áreas café
      fillColor: '#CCCCCC' // Relleno gris claro
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };
    
    this.naturalService.getRailsInfrastructure()
    .subscribe({
      next: (res) => {
        this.data = {
          data: res,
          formatInfo: formatInfo,
          metadata: res.metadata, 
          mapInfo: dataMap,
          typeMap: "Static",
          filterInfo:this.filterInfo,
          selectedInfo:this.select,
          hasStudyButton:this.hasStudyButton,
        };
        this.spinner.hide();
      },
      error: (e) => { console.error(e); this.spinner.hide(); }
    });  
    
  }

}

