import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-precipitation-net-water',
  templateUrl: './precipitation-net-water.component.html',
  styleUrls: ['./precipitation-net-water.component.css']
})
export class PrecipitationNetWaterComponent {

  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  public selection: any = { 
    filter_one: 'Ferry'
  };

  public estadocomponente:boolean =false;
  public currentId?: number;
  public numVariable?: number;
  public hasChangeVariableBtn = false;
  public filterInfo!: any;

  dataMap = {
    center: MapCenter.MEXICO,
    scale: ['#3399FF', '#66CCFF', '#99CCFF'].reverse(), // Tonos de azul
    json: MapJson.MEXICO,
    zoom: MapZoom.MEXICO,
    borderColor: '#0000FF', // Borde azul
    color: '#3366CC', // Color de las áreas azul
    fillColor: '#99CCFF' // Relleno azul claro
  };
  formatInfo = {
    region: "en-US",
    format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
    addDays: 1
  };

  filterInfoValues!: any;

  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService,
    private generalservice: GeneralService,
    private dataService: DataMultipleVariableService,) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }

  ngOnInit(): void {
    this.spinner.show();

    setTimeout(async () => {

      if (this.isCN) {
        this.getDataCN();
      } else if (this.isUSA) {
        this.getDataUs();
      } else if (this.isMx) {
        this.getDataMx();
      }else{
        this.getDataTri();
      }

    }, 300); 

  }

  getDataCN() {
    this.spinner.hide();
  } 

  getDataTri() {
    this.spinner.hide();
  }

  getDataUs() {
    this.spinner.hide();
  }

  getDataMx() {
    this.naturalService.getPrecipitationTransboundaryWater()
    .subscribe({
      next: (res) => {
        this.data = {
          data: res,
          formatInfo: this.formatInfo,
          metadata: res.metadata, 
          mapInfo: this.dataMap,
          filterInfo: {},
          selectedInfo: 0,
          hasStudyButton: false,
        };

        this.spinner.hide();
      },
      error: (e) => { console.error(e); this.spinner.hide(); }
    });  
    
  }

  async handleFilterUpdate($event: any) {
    this.selection ={ 
      filter_one: $event.toString()
    }; 

    this.getDataMx();
  }

  async fetchData(res: any) {
    
    if (res.variablesSameNode) {
      this.filterInfo = {
        firstFilter: res.variablesSameNode,
      };
      // Buscar el objeto en el array que tiene el 'itemId' correspondiente al 'numeroDeseado'
      const objetoEncontrado = this.filterInfo.firstFilter.values.find(
        (item: { itemId: any }) => item.itemId === res.numVariable
      );
      this.dataService.setData(objetoEncontrado.id);
      this.hasChangeVariableBtn = true;
    }

    this.currentId = res.numVariable;

  }

  async getValue($event: any) {
    this.spinner.show();
    this.data = undefined;
    this.currentId=$event;
    const datosmodel = { numVariable: $event };
    
    const filters = await firstValueFrom(
      this.generalservice.getFilters(datosmodel)
    );
    this.numVariable=filters.numVariable;
      //await this.fetchData(filters);
  }
}

