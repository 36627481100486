<div class="header">
  <div class="container">
    <h2>Risk Scenarios</h2>
    <p>(Machine Learning)</p>
  </div>
</div>

<div class="layout">
  <div class="navbar">
    <ul>
      <a href="#section-data" (click)="scrollTo($event, data)">
        <li>Data source</li>
      </a>
      <a href="#section-analysis" (click)="scrollTo($event, analysis)">
        <li>Exploratory Analysis</li>
      </a>
      <a href="#section-risk" (click)="scrollTo($event, risk)">
        <li>Risk scenario</li>
      </a>
    </ul>
  </div>
  <div class="main-content" appScrollSpy="[id^='section']">
    <!-- <h3>Sawmills and Wood Production</h3> -->
    <div #data id="section-data" class="section">
      <h2>Data Source Analysis</h2>
      <app-data-source></app-data-source>
    </div>
    <div #analysis id="section-analysis" class="section">
      <h2>Exploratory Analysis</h2>
      <app-exploratory-analysis></app-exploratory-analysis>
    </div>
    <div #risk id="section-risk" class="section">
      <h2>Risk Scenario</h2>
      <app-risk-scenario></app-risk-scenario>
    </div>
  </div>
</div>
