import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-time-control',
  templateUrl: './timeControl.component.html',
  styleUrls: ['./timeControl.component.css']
})
export class TimeControlComponent implements OnChanges, AfterViewInit {
  @ViewChild('range') range!: ElementRef;
  @Input() timeInterval: any;
  @Input() currentTime: any;
  @Input() period: any;
  @Output() timeUpdate: EventEmitter<any>;
  public current: any;
  public now: any;
  public steps: any;
  public status: any;
  private setInterval: any;

  constructor() {
    this.now = '';
    this.steps = [];
    this.status = 'play';
    this.timeUpdate = new EventEmitter<any>();
  }

  ngAfterViewInit(): void {
    this.setDateTime();
  }

  ngOnChanges(): void {
    try {
      let time = this.timeInterval.split('/');
      this.steps = this.generatePeriods(time[0], time[1], this.period);
      this.now = this.steps[0].end;
      this.timeUpdate.emit(this.steps[0]);
    } catch (e) {
      console.error(e);
    }
  }

  setDateTime() {
    const inputRange = this.range?.nativeElement;
    inputRange.min = 1;
    inputRange.max = this.steps.length;
    inputRange.value = 1;

    inputRange.addEventListener('input', (e: any) => {
      const selectedValue = parseInt(e.target.value);
      const selectedPeriod = this.steps[selectedValue - 1];
      this.now = selectedPeriod.end;
      this.timeUpdate.emit(selectedPeriod);
    });
  }

  generatePeriods(start: any, end: any, period: any) {
    const periods = [];
    let current = new Date(start);

    periods.push({
      period: period,
      start: current.toISOString(),
      end: current.toISOString()
    });

    while (current < new Date(end)) {
      const finish = new Date(current);
      let increment;

      // Determine the increment based on the period format
      if (period.startsWith('P')) {
        const amount = parseInt(period.slice(1));
        if (period.endsWith('H')) {
          increment = {hours: amount};
        } else if (period.endsWith('D')) {
          increment = {days: amount};
        } else if (period.endsWith('M')) {
          increment = {months: amount};
        } else {
          throw new Error('Invalid period format');
        }
      } else {
        throw new Error('Invalid period format');
      }

      finish.setHours(finish.getHours() + (increment.hours || 0));
      finish.setDate(finish.getDate() + (increment.days || 0));
      finish.setMonth(finish.getMonth() + (increment.months || 0));

      periods.push({
        period: period,
        start: current.toISOString(),
        end: finish.toISOString()
      });

      current = finish;
    }

    return periods;
  }

  play() {
    this.status = this.status == 'pause' ? 'play' : 'pause';
    if (this.status == 'pause') {
      this.setInterval = setInterval(() => {
        this.forward();
        console.log('play time control');
      }, 1000);
    } else if (this.setInterval) {
      clearInterval(this.setInterval);
    }
  }

  backward() {
    const inputRange = this.range?.nativeElement;
    if (inputRange.value > inputRange.min) {
      inputRange.value = parseInt(inputRange.value) - 1;
      inputRange.dispatchEvent(new Event('input'));
    }
  }

  forward() {
    const inputRange = this.range?.nativeElement;
    if (inputRange.value < inputRange.max) {
      inputRange.value = parseInt(inputRange.value) + 1;
      inputRange.dispatchEvent(new Event('input'));
    }
  }
}
