<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-7 mx-auto">
        <h1 class="display-3 bold text-center">
          Bayesian Risk Assesment and Management of U.S. Transboundary Water Supply Chain in North America
        </h1>
      </div>
    </div>
  </div>
</section>
<!-- <section class="section hero-gradient"
         style="background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.pexels.com%2Fphotos%2F1396122%2Fpexels-photo-1396122.jpeg%3Fcs%3Dsrgb%26dl%3Darchitecture-bungalow-daylight-1396122.jpg%26fm%3Djpg&f=1&nofb=1&ipt=560fe30488b9276c48638e6a38dd74cf8caa85a94fef6f7553d8b0cc66354cb8&ipo=images')">
  <div class="hero-bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-5">
        <h2 class="display-2 hero-title">
          The Colorado River<br>
          The Lifeblood of the Southwest
        </h2>
      </div>
    </div>
  </div>
</section> -->
<div class="ui-tabs">
  <a class="ui-tab" [class.ui-tab--active]="tab === 0" (click)="tab = 0">Introduction</a>
  <a class="ui-tab" [class.ui-tab--active]="tab === 1" (click)="tab = 1">Risk simulator</a>
  <a class="ui-tab" [class.ui-tab--active]="tab === 2" (click)="tab = 2">Help</a>
</div>
<!-- <ng-container *ngIf="tab === 0">
  <section class="section">
    <div class="container">
      <p class="xl">
        Housing demand growth reflects the changes in preferences and behaviors of households in response
        to the pandemic, such as the desire for more space, the ability to work remotely, and the need for
        affordability. Understanding these changes can help policymakers and market participants to design
        and implement policies and strategies that meet the needs and expectations of homebuyers and
        renters.
      </p>
      <p class="xl">
        Higher housing demand can lead to higher prices, lower inventory, and increased competition, which
        can create affordability and accessibility challenges for some segments of the population.
      </p>
    </div>
  </section>
  <app-graph-section [title]="section1.title" [description]="section1.description">
    <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="barChartOptions"></div>
  </app-graph-section>
  <app-graph-section [title]="section2.title" [description]="section2.description" [inverted]="true">
    <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="barChartOptions"></div>
  </app-graph-section>
  <section id="revenue" class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>Revenue</h3>
          <p>
            Revenue of the Wood/Timber Industry: The revenue of the wood/timber industry is directly tied to the volume of
            wood sold and the
            prevailing market prices. If production struggles to meet demand, there may be missed opportunities for sales.
            Conversely, if prices rise
            significantly, it might increase revenue per unit sold, but this could be offset by decreased overall sales
            volume if some potential buyers
            are priced out of the market.
          </p>
          <p>
            Source: bla.com
          </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="w-100 grow" style="height: 400px; background-color: #cce0e6;" EchartDirective [echartOptions]="mapOptions"></div>
  </section>
  <section class="section" style="background-color: #e6e5dc">
    <div class="container">
      <div class="row">
        <div class="col-3 px-4">
          <div class="text-center">
            <img width="80" src="/assets/images/case-model/wood_production.svg" alt="">
          </div>
          <h4>Housing Demand</h4>
          <p class="xl text-center">(Millions of Units)</p>
          <label class="selection selection--low">
            <mat-checkbox color="primary" [formControl]="revenueHousingControl"></mat-checkbox>
            Low</label>
          <label class="selection selection--medium">
            <mat-checkbox color="primary" [formControl]="revenueHousingControl"></mat-checkbox>
            Medium</label>
          <label class="selection selection--high">
            <mat-checkbox color="primary" [formControl]="revenueHousingControl"></mat-checkbox>
            High</label>
        </div>
        <div class="col-3 px-5">
          <div class="text-center">
            <img width="80" src="/assets/images/case-model/wood_production.svg" alt="">
          </div>
          <h4>Wood Production</h4>
          <p class="xl text-center">(Tons)</p>
          <label class="selection selection--low">
            <mat-checkbox color="primary" [formControl]="revenueWoodControl"></mat-checkbox>
            Low</label>
          <label class="selection selection--medium">
            <mat-checkbox color="primary" [formControl]="revenueWoodControl"></mat-checkbox>
            Medium</label>
          <label class="selection selection--high">
            <mat-checkbox color="primary" [formControl]="revenueWoodControl"></mat-checkbox>
            High</label>
        </div>
        <div class="col-6">
          <div class="graph-card">
            <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="areaChartOptions"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container> -->
<ng-container *ngIf="tab === 0">
  <div>
    <app-the-colorado-river></app-the-colorado-river>
  </div>
</ng-container>
<ng-container *ngIf="tab === 1">
  <section class="section">
    <div class="row mb-5rem">
      <div class="col-6 mx-auto">
        <div class="ui-tabs ui-tabs--pills">
          <div class="ui-tab" [class.ui-tab--active]="tab2 === 0" (click)="tab2 = 0">Expert Driven Model</div>
          <div class="ui-tab" [class.ui-tab--active]="tab2 === 1" (click)="tab2 = 1">Data Driven Model</div>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="tab2 === 0">
    <section id="help" class="section pt-0">
      <div class="container">
        <div class="row align-items-center mb-5rem">
          <div class="col-12 col-xl-7 mx-auto">
            <ng-container *ngTemplateOutlet="nodes"></ng-container>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-2">
            <div class="text-center">
              <img width="80" src="/assets/images/case-model/wood_production.svg" alt="">
            </div>
            <h4>Housing Demand</h4>
            <p class="xl text-center">(Millions of Units)</p>
            <label class="selection selection--low">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Low</label>
            <label class="selection selection--medium">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Medium</label>
            <label class="selection selection--high">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              High</label>
          </div>
          <div class="col-5">
            <div class="graph-card">
              <div class="info-grid">
                <div style="grid-column-start: 1; grid-column-end: 2; grid-row-start: 2">
                  <div class="text-center">
                    <img width="59" src="/assets/images/case-model/wood_production.svg" alt="">
                  </div>
                  <div class="text-center">
                    <h5>Housing demand</h5>
                    <div>(Millions of Units)</div>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="grid-column-start: 3; grid-column-end: 6">
                  <div class="text-center">
                    <img width="59" src="/assets/images/case-model/wood_production.svg" alt="">
                  </div>
                  <div class="ms-3 text-center">
                    <h5>Wood Production</h5>
                    <div>(Tons)</div>
                  </div>
                </div>
                <div class="options-grid" style="grid-column-start: 2; grid-column-end: 6; grid-row-start: 2;">
                  <div style="font-weight: 700; font-size: 2.5rem; text-align: center;">P(CIT)</div>
                  <label class="selection selection--sm selection--low">
                    Low</label>
                  <label class="selection selection--sm selection--medium">
                    Medium</label>
                  <label class="selection selection--sm selection--high">
                    High</label>

                  <label class="selection selection--sm selection--low">
                    Low</label>
                  <div class="input">
                    0.1
                  </div>
                  <div class="input">
                    0.5
                  </div>
                  <div class="input">
                    0.4
                  </div>

                  <label class="selection selection--sm selection--medium">
                    Medium</label>
                  <div class="input"></div>
                  <div class="input"></div>
                  <div class="input"></div>

                  <label class="selection selection--sm selection--high">
                    High</label>
                  <div class="input"></div>
                  <div class="input"></div>
                  <div class="input"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="graph-card">
              <div class="options-grid" style="grid-column-start: 2; grid-column-end: 6; grid-row-start: 2;">
                <div style="font-weight: 700; font-size: 1.5rem; text-align: center;">Impact</div>
                <label class="selection selection--sm selection--low">
                  Low</label>
                <label class="selection selection--sm selection--medium">
                  Medium</label>
                <label class="selection selection--sm selection--high">
                  High</label>

                <label class="selection selection--sm selection--low">
                  Low</label>
                <div class="input">
                  0.1
                </div>
                <div class="input">
                  0.5
                </div>
                <div class="input">
                  0.4
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="graph-card">
              <div class="d-flex align-items-center mb-3" style="grid-column-start: 3; grid-column-end: 6">
                <div class="text-center">
                  <img width="59" src="/assets/images/case-model/wood_production.svg" alt="">
                </div>
                <div class="ms-3 text-center">
                  <h5>Revenue</h5>
                  <div>(Billion Dlls)</div>
                </div>
              </div>
              <div class="input">
                3.6 (Billion Dlls)
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <h3>Non informative</h3>
        </div>
        <div class="row align-items-center">
          <div class="col-7 pe-4">
            <ng-container *ngTemplateOutlet="nodes"></ng-container>
          </div>
          <div class="col-5 ps-4">
            <div class="graph-card">
              <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="bellBarChartOptions"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <h3>Prior Monte Carlo</h3>
        </div>
        <div class="row align-items-center mb-5">
          <div class="col-7 pe-4">
            <ng-container *ngTemplateOutlet="nodes"></ng-container>
          </div>
          <div class="col-5 ps-4">
            <div class="graph-card">
              <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="bellBarChartOptions"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="graph-card">
              <div class="row">
                <div class="col-7 row">
                  <div class="col-4">
                    <div class="w-100 grow" style="height: 300px;" EchartDirective [echartOptions]="heatmapChartOptions"></div>
                  </div>
                  <div class="col-4">
                    <div class="w-100 grow" style="height: 300px;" EchartDirective [echartOptions]="heatmapChartOptions"></div>
                  </div>
                  <div class="col-4">
                    <div class="w-100 grow" style="height: 300px;" EchartDirective [echartOptions]="heatmapChartOptions"></div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="w-100 grow" style="height: 300px;" EchartDirective [echartOptions]="lineChartOptions"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container mb-5">
        <div class="row">
          <h3>Posterior Monte Carlo</h3>
        </div>
        <div class="row align-items-center mb-5">
          <div class="col-7 mx-auto">
            <ng-container *ngTemplateOutlet="nodes"></ng-container>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-3 px-4">
            <div class="text-center">
              <img width="80" src="/assets/images/case-model/wood_production.svg" alt="">
            </div>
            <h4>Housing Demand</h4>
            <p class="xl text-center">(Millions of Units)</p>
            <label class="selection selection--low">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Low</label>
            <label class="selection selection--medium">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Medium</label>
            <label class="selection selection--high">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              High</label>
          </div>
          <div class="col-3 px-5">
            <div class="text-center">
              <img width="80" src="/assets/images/case-model/wood_production.svg" alt="">
            </div>
            <h4>Wood Production</h4>
            <p class="xl text-center">(Tons)</p>
            <label class="selection selection--low">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Low</label>
            <label class="selection selection--medium">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              Medium</label>
            <label class="selection selection--high">
              <mat-checkbox color="primary" [checked]="true"></mat-checkbox>
              High</label>
          </div>
          <div class="col-6">
            <div class="graph-card" style="height: 400px;">
              <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="bellBarChartOptions"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container mb-5">
        <div class="text-center">
          <h3>Probabilistic Calibration - Posterior Parameter</h3>
          <h4>Informed Case</h4>
        </div>
      </div>
      <div class="container container-md">
        <div class="row">
          <ng-container *ngFor="let item of smallCharts">
            <div class="col-3">
              <div class="" style="height: 300px" EchartDirective [echartOptions]="item.options">

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="tab2 === 1">
    <app-data-driven-model-calculator [variables]="variables" />
  </ng-container>
</ng-container>

<ng-template #nodes>
  <div class="nodes">
    <div class="node-wrapper">
      <div class="node node--blue">
              <span>
                <span>Housing Demand</span>
              </span>
      </div>
    </div>
    <div class="node-wrapper">
      <div class="node node--red">
              <span>
                <span>Wood Production</span>
              </span>
      </div>
    </div>
    <div class="node-wrapper">
      <div class="node node--yellow">
              <span>
                <span>Revenue</span>
              </span>
      </div>
    </div>
  </div>
</ng-template>
