import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ApiServiceService } from '../../../services/apiService/api-service.service';
import * as L from 'leaflet';
import { rewind } from './poligon-fixer';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-article-single',
  templateUrl: './article-single.component.html',
  styleUrls: ['./article-single.component.scss'],
})
export class ArticleSingleComponent
  implements OnInit, OnDestroy, AfterViewInit, OnDestroy
{
  @ViewChild('content', {read: ElementRef})
  private contentEl!: ElementRef<HTMLDivElement>;
  @ViewChild('map', { read: ElementRef })
  private mapEl!: ElementRef<HTMLDivElement>;
  private _destroyed = new Subject();
  article: any;
  hasRegions: boolean = false;
  map!: L.Map;
  geoJSON: L.GeoJSON[] = [];
  allBounds!: L.LatLngBounds;
  params: any;
  private _redraw = this.redraw.bind(this);
  faRefresh = faRefresh;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiServiceService
  ) {}
  ngAfterViewInit(): void {
    this.redraw();
    window.addEventListener('nlp-tab', this._redraw);
  }

  redraw() {
    if (!this.mapEl) return;
    this.map?.remove();
    this.map = L.map(this.mapEl.nativeElement).setView([51.505, -0.09], 13);
    const tiles = L.tileLayer(
      'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.map);
    const { id } = this.params;
    if (id) {
      this.api.get(`event/event-details/${id}`).subscribe((response) => {
        this.article = response;
        const style = (feature: any) => {
          return {
            weight: 1,
            opacity: 1,
            color: 'rgba(35,35,35,1.0)',
            dashArray: '',
            fillOpacity: 1,
            fillColor: 'red',
          };
        };
        const regions = this.article?.regions?.filter(
          (data: any) => !!data.geoJson
        );
        this.hasRegions = !!regions.length;
        if (this.map)
          if (this.hasRegions) {
            this.map.whenReady(() => {
              this.allBounds = L.latLngBounds([]); // Initialize an empty LatLngBounds object
              for (const region of regions) {
                const rewinded = rewind(region.geoJson, false);
                const geoJson = L.geoJSON(rewinded, {
                  style: style,
                });
                this.geoJSON.push(geoJson);
                this.allBounds.extend(geoJson.getBounds());
                geoJson.addTo(this.map);
              }
              this.map.fitBounds(this.allBounds); // Fit the map to the combined bounds
            });
          }
      });
    } else {
      this.article = undefined;
    }
  }

  ngOnInit() {
    const isVisible = (domElement: HTMLElement) => {
      return new Promise((resolve) => {
        const o = new IntersectionObserver(([entry]) => {
          resolve(entry.intersectionRatio === 1);
          o.disconnect();
        });
        o.observe(domElement);
      });
    };
    this.route.queryParams
      .pipe(takeUntil(this._destroyed))
      .subscribe(async (params) => {
        this.params = params;
        this.redraw();
        if (this.mapEl?.nativeElement) {
          await isVisible(this.mapEl.nativeElement);
          this.redraw();
        }
      });
  }

  ngOnDestroy() {
    this._destroyed.next(undefined);
    window.removeEventListener('nlp-tab', this._redraw);
  }

  async download() {
    let quotes = this.contentEl.nativeElement

    await html2canvas(quotes).then((canvas) => {
      let imgData = canvas.toDataURL("image/jpeg",1);
      let pdf = new jsPDF("p", "mm", "a4");
      let pageWidth = pdf.internal.pageSize.getWidth() - 32;
      let pageHeight = pdf.internal.pageSize.getHeight() - 32;
      let imageWidth = canvas.width;
      let imageHeight = canvas.height;

      let ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
      pdf.addImage(imgData, 'JPEG', 16, 16, imageWidth * ratio, imageHeight * ratio);
      pdf.save(`${this.article.eventHeadline}.pdf`);
    });
  }
}
