import { Injectable } from '@angular/core';
import { PopulationUsCountyModel } from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DefaultBoxPlotGraphModel, DefaultGraphModel } from 'src/app/core/models/chartjs.model';

@Injectable({
  providedIn: 'root'
})
export class TimeVisualizationTemplateService {

  constructor() { }
  
  getBoxplot(data: any, title: string, property="value"): DefaultBoxPlotGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(data)); // {Date: x, Value: y}
    const boxplot: DefaultBoxPlotGraphModel = {
      labels: [''],
      datasets: [{
        label: title,
        outLierColor: '#999999',
        meanBackgroundColor: '#461e7d',
        data: [],
        padding: 0,
        itemRadiius: 0,
        backgroundColor: 'rgba(255, 26, 104, 0.2)',
        borderColor: 'rgba(255, 26, 104, 1)',
        borderWidth: 1,
        type: 'boxplot'
      }]
    };
    let values: number[] = dataCopy.data.map((item: any, i: number) => {
      return item[property]
    });
    boxplot.datasets[0].data.push(values);
    return boxplot;
  }
  
  getHistogram(dataMapCopy: any, title: string, property="value"):any {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy));
    const histogram_: any[] = [];

    const values: number[] = [];
    dataCopy.data.forEach((value: any) => {
      values.push(value[property]);
    });
    
    
    const labels = [...new Set(values)].sort( (a, b) =>{
      return a - b;
    }).filter(x => x !== 0);
    const labelsString = labels.map((value: number) => { return value.toString() });

    labelsString.forEach((label: string) => {
      histogram_.push(... values.filter(x => x === parseFloat(label)).map(x => { return {value: x}}));
    });
    return histogram_;
  }

  getXY(data: any, isDate: boolean, property="value"): any {
    let dataCopy = JSON.parse(JSON.stringify(data)); // {Date: x, Value: y}


    const data_: any[] = dataCopy.data.map((item: any, i: number) => {
      const value = item[property];
      if (typeof item.date === 'string') {
        const lengthOfString = item.date.length;
      
        if(!isDate || lengthOfString == 4){
          const date = new Date(item.date, 0);
          const dateParse = date.toLocaleDateString();
          return {date: date.getTime(), value: value, dateParse: dateParse};
        }
      }


      const date = new Date(`${item.date}T00:00:00`);
      
const day = date.getDate().toString().padStart(2, '0');
const month = (date.getMonth() + 1).toString().padStart(2, '0');
const year = date.getFullYear();
const dateParse = `${day}/${month}/${year}`;

      return {date: date.getTime(), value: value, dateParse: dateParse};
      
    });
    return data_;
  }
  getECDFDataiku(data: any, property="value"): DefaultGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(data)) ;

    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: `ECDF`,
        data: [],
        backgroundColor: 'rgba(255, 26, 104, 0.8)',
        borderColor: 'rgba(255, 26, 104, 1)',
        stepped: true,
      }]
    };

    
    ECDF.labels = dataCopy.map((value:any ) => { return value.date.toString() });

    ECDF.datasets[0].data = dataCopy.map((value:any ) => { return value.value });
    return ECDF;
  }

  getECDFGeneral(data: any, property="value"): DefaultGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(data)) ;

    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: `ECDF`,
        data: [],
        backgroundColor: 'rgba(255, 26, 104, 0.8)',
        borderColor: 'rgba(255, 26, 104, 1)',
        stepped: true,
      }]
    };

    let values: number[] = []
    dataCopy.data.forEach((item: any) => {
      values.push(item[property]);
    });

    values = values.sort((a, b) => {
      return a - b;
    });
    const one_vec = values.map((value) => { return 1; });
    const eq_prob = one_vec.map((value) => {
      return value / values.length;
    })
    const labels = values.sort((a, b) => {
      return a - b;
    });
    ECDF.labels = labels.map((value: number) => { return value.toString() });

    const data_: number[] = [];
    let init_prob = 0;
    eq_prob.forEach((value: number, i: number) => {
      init_prob += value;
      data_.push(init_prob);
    });
    data_.pop();
    data_.push(1);
    ECDF.datasets[0].data = data_;
    return ECDF;
  }

  getECDFRiskScenario(data: any): DefaultGraphModel {
    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: ``,
        data: data,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',        
        stepped: true,
      }]
    };
    return ECDF;
  }
  
  getXYCategory(data: any): any{
    let dataCopy = JSON.parse(JSON.stringify(data.data)); // {DateCompose: x, Value: y}
    
    let datos: { category: string; value: number; }[] = dataCopy.map((element: { date: any; value: any; }) => {
      return { category: element.date, value: element.value };
    });

    return datos;
  }
  getXYCategoryDataiku(data: any): any{
    let dataCopy = JSON.parse(JSON.stringify(data)); // {DateCompose: x, Value: y}
    
    let datos: { category: string; value: number; }[] = dataCopy.map((element: { date: any; value: any; valuesSecond?: any }) => {
      return { category: element.date, value: element.value, valueSecond: element.valuesSecond };
    });

    return datos;
  }
}

