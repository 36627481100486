import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-filters',
  templateUrl: './modal-filters.component.html',
  styleUrls: ['./modal-filters.component.css']
})

export class ModalFiltersComponent {
  selectedOptionClassification: any;
  selectedOptionVariableType: any;
  itemsClassification: any[]; 
  itemsVariableType: any[]; 
  categories: any [];
  disabled_categories: any = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalFiltersComponent>,
    private cdr: ChangeDetectorRef
  ) {
    this.itemsClassification = data.itemsClassification; 
    this.itemsVariableType = data.itemsVariableType; 
    this.selectedOptionClassification = data.selectedOptionClassification;
    this.selectedOptionVariableType = data.selectedOptionVariableType;
    this.categories = data.categories;
  }

  onChangeSelection() {
    this.cdr.detectChanges();
    setTimeout(() => {
      //console.log("clas: "+this.selectedOptionClassification);
      //console.log("varType: "+this.selectedOptionVariableType);
      if(this.selectedOptionClassification != "All" || this.selectedOptionVariableType != "All"){
        this.disabled_categories = true;
      } else {
        this.disabled_categories = false;
      }
      this.cdr.detectChanges();
    },300);
  }

  closeDialog(): void {
      this.dialogRef.close({
        selectedOptionClassification: this.selectedOptionClassification,
        selectedOptionVariableType: this.selectedOptionVariableType,
        categories: this.categories
      });
    
  }

  cancel(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.cdr.detectChanges();

      this.selectedOptionClassification = "All";
      this.selectedOptionVariableType = "All";
      this.categories.forEach(category => {
      category.links.forEach((link: { checked: boolean; }) => {
          link.checked = true;
        });
     

   this.dialogRef.close({
        selectedOptionClassification: this.selectedOptionClassification,
        selectedOptionVariableType: this.selectedOptionVariableType,
        categories: this.categories
      });
      this.cdr.detectChanges();
    },300);
    
    this.closeDialog();
  }
}
