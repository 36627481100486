import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FilterType,
  TimeVisualizationTemplateModel,
} from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TrendModelComponent } from '../../components/forms/trend-model/trend-model.component';
import { HttpClient } from '@angular/common/http';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutocorrelationModelComponent } from '../../components/forms/autocorrelation-model/autocorrelation-model.component';
import { SeasonalModelComponent } from '../../components/forms/seasonal-model/seasonal-model.component';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';

@Component({
  selector: 'app-time-visualization-template',
  templateUrl: './time-visualization-template.component.html',
  styleUrls: ['./time-visualization-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TimeVisualizationTemplateComponent
  implements OnDestroy, OnChanges
{
  @Input() dataRes!: TimeVisualizationTemplateModel;
  @Input() idsFilters!: any;
  @Input() isMVM!: any;
  @Input() filtro!:string[];
  @Input() event: string = 'Average Total Cost\nPer 15k Miles';
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  @Output() selectedItem = new EventEmitter<number>();
  @Output() yAxisValue = new EventEmitter<string>();
  @Output() selectedYearValue = new EventEmitter<string[]>();

  showBorder = false;
  public totalRows: number = 100;
  public nlags: number = 100;
  public algorithm: string = '1';
  public filters: string[] = [];
  public yaxisfilter: string[] = [];
  // public filter: string = '';
  public showAlertEcdf = false;
  public showAlertHistogram = false;
  showModal: boolean | undefined;
  showModalDataiku: boolean | undefined;
  /* Variables for metadata and visualization */
  public valueYear!: any;
  public histogram!: any;
  public XYChart!: any;
  public ecdfChart!: any;
  public minDistance: number = 1;
  public yLabel: string[] | string = '';
  public filterType = FilterType;
  public propertyName: string = 'value';
  public showTrendModelGraphs: boolean = true;
  /*End of variables for metadata and visualization */
  /* Variables for Trend model */
  @ViewChild('trendModel', { static: false })
  trendComponent!: TrendModelComponent;
  public XYChartTrend!: any;
  public XYChartRemain!: any;
  public ecdfChartTrend!: any;
  public data!: any;
  private onDestroy$: Subject<boolean> = new Subject();
  /* End Variables for Trend model */
  /* Variables for Autocorrelation model */
  @ViewChild('autocorrelationModel', { static: false })
  autocorrelationComponent!: AutocorrelationModelComponent;
  public XYChartAutocorrelation!: any;
  /* End Variables for Autocorrelation  model */
  /* Variables for seasonal and trend model */
  @ViewChild('seasonalModel', { static: false })
  seasonalComponent!: SeasonalModelComponent;
  public XYChartTrendSeasonal!: any;
  public XYChartSeasonal!: any;
  public XYChartResidualSeasonal!: any;
  /* End Variables for seasonal  model */
  constructor(
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private timeVisualizationService: TimeVisualizationTemplateService,
    private dataService: DataMultipleVariableService,
    private chartjsService: ChartjsServiceService,
    @Optional() private dialog: MatDialog,
    private httpClient: HttpClient
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dataRes']){
      this.totalRows = this.dataRes.dataikuModel?.totalRows ?? 100;
      this.nlags = this.dataRes.dataikuModel?.nlags_ ?? 100;
      this.algorithm = this.dataRes.dataikuModel?.algorithm_ ?? '1';
      this.filters = this.filtro ?? [];
      this.yaxisfilter = this.dataRes.dataikuModel?.y_axis_filter ?? [];
      // this.filter = this.dataRes.dataikuModel?.filter_ ?? '';
      this.reloadView();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.dataService.reloadView$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.reloadView();
    });
    this.dataService.validationArray$.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
      this.ValidationArrayWithData(data);
      this.reloadView();
    });

  }

  emitSelectedYearValue($event: any) {
    this.selectedYearValue.emit($event);
    this.spinner.show();
    this.reloadView();
  }

  emitFirstValue($event: any) {
    this.firstValue.emit($event + '1');
    this.spinner.show();
    this.reloadView();
  }

  emitSecondValue($event: any) {
    this.secondValue.emit($event + '2');
    this.spinner.show();
    if (
      $event === 'Fixed cost' ||
      $event === 'Healthcare provider' ||
      $event === 'Mexico Rate' ||
      $event === 'Imports' ||
      $event === 'Medium and Heavy' ||
      $event === 'Natural Gas' ||
      $event === 'Manufactured home (mobile home) manufacturing'
    ) {
      this.event = $event;
      this.propertyName = 'valueSecond';
    } else if (
      $event === 'Variable cost' ||
      $event === 'USA Rate' ||
      $event === 'Nuclear' ||
      $event === 'Prefabricated wood building manufacturing'
    ) {
      this.event = $event;
      this.propertyName = 'valueThird';
    } else if ($event === 'Business associate' || $event === 'Hydro') {
      this.event = $event;
      this.propertyName = 'valueFourth';
    } else if (
      $event === 'Healthcare clearinghouse' ||
      $event === 'Wind, solar, etc.'
    ) {
      this.event = $event;
      this.propertyName = 'valueFifth';
    } else if ($event === 'Biofuels and waste') {
      this.event = $event;
      this.propertyName = 'valueSixth';
    } else if ($event === 'Oil') {
      this.event = $event;
      this.propertyName = 'valueSeventh';
    } else if ($event === 'Average total cost per 15k miles') {
      this.event = 'Average Total Cost\nPer 15k Miles';
      this.propertyName = 'value';
    } else if ($event === 'Expand health care') {
      this.event = $event;
      this.propertyName = 'valuesSecond';
    } else if ($event === 'Disability') {
      this.event = $event;
      this.propertyName = 'values';
    } else {
      this.event = $event;
      this.propertyName = 'value';
    }
    this.reloadView();
  }

  emitThirdValue($event: any) {
    this.thirdValue.emit($event + '3');
    this.spinner.show();
    if ($event === 'Females' || $event === 'Males') {
      this.event = 'Weekly Death Counts';
      this.propertyName = 'value';
    } else {
      this.event = $event;
      this.propertyName = 'value';
    }
    this.reloadView();
  }
 
  emitFourthValue($event: any) {
    this.spinner.show();
    this.fourthValue.emit($event + '4');
    this.reloadView();
  }

  emitFifthValue($event: any) {
    this.spinner.show();
    this.fifthValue.emit($event + '5');
    this.reloadView();
  }

  emitSixthValue($event: any) {
    this.spinner.show();
    this.sixthValue.emit($event + '6');
    this.reloadView();
  }

  emitYAxisValue($event: any) {
        this.spinner.show();
    this.yAxisValue.emit($event);
    this.reloadView();
  }

  reloadView() {
    
    this.histogram = {
      data: this.timeVisualizationService.getHistogram(
        this.dataRes.data,
        this.dataRes.histogram.title,
        this.propertyName
      ),
      title: this.dataRes.histogram.title,
      yLabel: this.dataRes.histogram.yLabel,
      xLabel: this.dataRes.histogram.xLabel,
    };
    if (this.dataRes.timeUnit == 'quarter') {
      this.XYChart = {
        title: this.dataRes.xyChart.title,
        data: this.timeVisualizationService.getXYCategory(this.dataRes.data),
        xLabel: this.dataRes.xyChart.xLabel,
        yLabel: this.dataRes.xyChart.yLabel,
      };
    } else {
      this.XYChart = {
        title: this.dataRes.xyChart.title,
        data: this.timeVisualizationService.getXY(
          this.dataRes.data,
          this.dataRes.isDate,
          this.propertyName
        ),
        xLabel: this.dataRes.xyChart.xLabel,
        yLabel: this.dataRes.xyChart.yLabel,
      };
    }
    
    this.ecdfChart = {
      title: 'ECDF', // - '+ this.dataRes.xyChart.title,
      data: this.chartjsService.getDataMapped(
        this.timeVisualizationService.getECDFGeneral(
          this.dataRes.data,
          this.propertyName
        ),
        true,
        false,
        true
      ),
      yLabel: 'Cumulative Relative Frequency',
      xLabel: this.dataRes.xyChart.title,
    };
    this.showAlertHistogram = this.histogram.data.length > 1 ? false : true;
    this.showAlertEcdf = this.ecdfChart.data.datasets[0].data.length > 1 ? false : true;
    this.showBorder = this.dataRes.hasFilter == true ? true : this.dataRes.data.listYears != null ? true : false;
    this.cdr.detectChanges(); // Force detetection of changes
    
  }

  openStudyDialog() {
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        filterInfo: this.dataRes.filterVariableInfo,
        selectedInfo: this.dataRes.selectedInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }
  public tabEventAction(tabChangeEvent: string): void {
    if (tabChangeEvent == 'Trend') {
      this.trendComponent.onSubmit();
    }
    if (tabChangeEvent == 'Auto') this.autocorrelationComponent.onSubmit();
    if (tabChangeEvent == 'Seasonal') this.seasonalComponent.onSubmit();
  }

  public getTrendModel(event: any) {
    this.spinner.show();
    event.filters_ = event.filters_;
    event.y_axis_filter = event.y_axis_filter;
    let model = {
      table: this.dataRes.dataikuModel?.table,
      column_to_work: this.dataRes.dataikuModel?.column_to_work,
      frequency: this.dataRes.dataikuModel?.frequency,
      date_: this.dataRes.dataikuModel?.date_,
      filters_:
        JSON.stringify(event.filters_) === JSON.stringify([''])
          ? null
          : event.filters_,
      y_axis_filter: 
      JSON.stringify(event.y_axis_filter) === JSON.stringify([''])
        ? null
        : event.y_axis_filter,
      algorithm_: parseInt(event.algorithm_),
      window_size_0_1: parseFloat(event.window_size_0_1),
      window_size: parseFloat(event.window_size),
      degree_: parseFloat(event.degree_),
      smoothing_factor: parseFloat(event.smoothing_factor),
      project_: this.dataRes.dataikuModel?.project_,
    };
    if (model.filters_ === null) {
      model.filters_ = [];
    }
    if (model.y_axis_filter === null || model.y_axis_filter === undefined) {
      model.y_axis_filter = [];
    }
    this.httpClient
      .post(
        'http://10.55.164.42:11200/public-cors/api/v1/service_time_series_variables/trend_model_and_residual_analysis/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          
          if(res.response=="your data is not ok" || res.response=="Your parameters does not work. Try changing them."){
this.showModalDataiku=true;




this.showTrendModelGraphs = false;
          }
          else{
this.showModalDataiku=false; 

const response = JSON.parse(res.response);

this.XYChartTrend = {
  title: '',
  data: this.timeVisualizationService.getXYCategoryDataiku(
    response.trend_graph
  ),
  xLabel: '',
  yLabel: '',
};

this.XYChartRemain = {
  title: '',
  data: this.timeVisualizationService.getXYCategoryDataiku(
    response.remain_graph
  ),
  xLabel: '',
  yLabel: '',
};

this.ecdfChartTrend = {
  title: '',
  data: this.chartjsService.getDataMapped(
    this.timeVisualizationService.getECDFDataiku(response.ecdf_graph),
    false,
    false,
    true
  ),
  yLabel: '',
  xLabel: '',
};

this.showTrendModelGraphs = true;

          }

         
          
        },
        error: (e) => {
          console.error(e);

          this.showTrendModelGraphs = false;

          this.XYChartTrend = {
            title: '',
            data: [],
            xLabel: '',
            yLabel: '',
          };

          this.XYChartRemain = {
            title: '',
            data: [],
            xLabel: '',
            yLabel: '',
          };

          this.ecdfChartTrend = {
            title: '',
            data: [],
            yLabel: '',
            xLabel: '',
          };
        },
      });
  }

  public getAutocorrelationModel(event: any) {
    this.spinner.show();
    event.nlags_ = parseInt(event.nlags_);
    let model = event;
    model.table = this.dataRes.dataikuModel?.table;
    model.column_to_work = this.dataRes.dataikuModel?.column_to_work;
    model.frequency = this.dataRes.dataikuModel?.frequency;
    model.date_ = this.dataRes.dataikuModel?.date_;
    model.filters_ = []; //event.filters_;

    this.httpClient
      .post(
        'http://10.55.164.42:11200/public-cors/api/v1/service_time_series_variables/autocorrelation_analysis/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res.response);
          this.XYChartAutocorrelation = {
            title: 'Autocorrelation Function (ACF) Plot',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.graph1
            ),
            xLabel: 'Lag(Month)',
            yLabel: 'Autocorrelation',
          };
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  public getSeasonalModel(event: any) {
    this.spinner.show();
    event.stl_trend_smoother = parseInt(event.stl_trend_smoother);
    event.stl_seasonal_smoother = parseInt(event.stl_seasonal_smoother);
    event.stl_period = parseInt(event.stl_period);

    let model = event;
    model.table = this.dataRes.dataikuModel?.table;
    model.column_to_work = this.dataRes.dataikuModel?.column_to_work;
    model.frequency = this.dataRes.dataikuModel?.frequency;
    model.date_ = this.dataRes.dataikuModel?.date_;
    model.filters_ = event.filters_;
    this.httpClient
      .post(
        'http://10.55.164.42:11200/public-cors/api/v1/service_time_series_variables/seasonal_and_trend_decomposition/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res.response);

          //trend_graph
          this.XYChartTrendSeasonal = {
            title: 'Trend Time-Series',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.trend_graph
            ),
            xLabel: '',
            yLabel: '',
          };
          //seasonal_graph
          this.XYChartSeasonal = {
            title: 'Seasonal Time-Series',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.seasonal_graph
            ),
            xLabel: '',
            yLabel: '',
          };
          //residual_graph
          this.XYChartResidualSeasonal = {
            title: 'Residial Time-Series ',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.residual_graph
            ),
            xLabel: '',
            yLabel: '',
          };
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  public closeModal() {
    this.showModal = false;
    this.showModalDataiku=false;
  }

  ValidationArray() {
    if (
      this.dataRes.data.data &&
      Array.isArray(this.dataRes.data.data) &&
      this.dataRes.data.data.length > 0
    ) {
      this.showModal = false;
    } else {
      this.showModal = true;
    }
  }

  public ValidationArrayWithData(data: any): void {
    if (
      data.data.data &&
      Array.isArray(data.data.data) &&
      data.data.data.length > 0
    ) {
      this.showModal = false;
    } else {
      this.showModal = true;
    }
    this.dataRes = data;
  }
}
