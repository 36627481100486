import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
declare const L: any;
import 'leaflet';
import 'leaflet-timedimension';
import '../../../../../assets/js/leaflet.timedimension.point';
import '../../../../../assets/js/leaflet.timedimension.choropleth.control';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-point-map',
  templateUrl: './dynamic-point-map.component.html',
  styleUrls: ['./dynamic-point-map.component.css'],
})
export class DynamicPointMapComponent implements OnChanges, OnDestroy {
  @ViewChild('map', { read: ElementRef })
  private mapEl!: ElementRef<HTMLElement>;
  @Input() typeMap!: any;
  @Input() AllowValueHover!: boolean;
  @Input() data!: any;
  @Input() mapInfo!: any;
  @Input() formatInfo!: any;
  @Input() property!: string;
  private map: any;
  private states: any;
  private geojson!: any;
  private timeSeries!: any;
  private player!: any;
  private radious: number = 5;

  constructor(private http: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.initMap();
    this.onReload();
  }

  ngOnDestroy(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
  }

  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map('mapPoint', {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });

    this.states = JSON.parse(JSON.stringify(this.data.geojson));

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 12,
        minZoom: 1,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: () => {
          return '<img class="icon-svg" src="' + environment.BaseAssets + '/house-solid.svg" alt="home-icon">';
        },
        zoomHomeTitle: 'Zoom home',
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + '-in',
          container,
          this._zoomIn
        );
        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + '-home',
          container,
          this._zoomHome
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + '-out',
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (
        html: any,
        title: any,
        className: any,
        container: any,
        fn: any
      ) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }

  onReload() {
    this.initStatesLayer();
  }

  private initStatesLayer() {
    var timeDimension = new L.TimeDimension({
      period: 'PT1H',
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player(
      {
        transitionTime: 2000,
        loop: false,
        startOver: false,
      },
      timeDimension
    );
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 10,
      timeSliderDragUpdate: false,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays,
    };
    var timeDimensionControl = new L.Control.TimeDimensionChoropleth(
      timeDimensionControlOptions
    );

    if (this.typeMap == 'Static') {
      this.radious = 5;
    } else {
      this.map.addControl(timeDimensionControl);
    }

    const geoPointMarketOption = {
      radius: this.radious,
      fillColor: this.mapInfo.fillColor,
      color: this.mapInfo.color,
      weight: 5,
      opacity: 1,
      fillOpacity: 0.8,
    };

    this.timeSeries = L.geoJSON(this.states, {
      pointToLayer: (feature: any, latLng: any) => {
        return L.circleMarker(latLng, geoPointMarketOption);
      },
      onEachFeature: this.AllowValueHover ? this.onEachFeature : null
    });

    this.geojson = L.timeDimension.layer.point(this.timeSeries, {
      updateTimeDimensionMode: 'replace',
      waitForReady: true,
    },   this.typeMap);

    this.geojson.addTo(this.map);
  }

  private highLightFeature = (e: any) => {
    var layer = e.target;

    layer.setStyle({
      weight: 3.5,
      color: '#99CCFF',
      dashArray: '',
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  private resetFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 5,
      color: this.mapInfo.color,
      fillOpacity: 1,
    });
    layer.bringToFront();
  };

  private onEachFeature = (feature: any, layer: any) => {

    var label = 'Value';
    if(feature.properties.category){
      label = feature.properties.category;
    }

    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
    });
    layer
      .bindTooltip(
        `<p style="font-weight: 300; text-align: center;">${label}: ${feature.properties.value}</p>`
      )
      .openTooltip();
  };

}
