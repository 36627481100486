<div class="toolbar" [hidden]="!article">
  <button class="btn btn-download" (click)="download()">
    <img
      style="width: 16px; height: 16px;"
      src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0zIDE1QzMgMTcuODI4NCAzIDE5LjI0MjYgMy44Nzg2OCAyMC4xMjEzQzQuNzU3MzYgMjEgNi4xNzE1NyAyMSA5IDIxSDE1QzE3LjgyODQgMjEgMTkuMjQyNiAyMSAyMC4xMjEzIDIwLjEyMTNDMjEgMTkuMjQyNiAyMSAxNy44Mjg0IDIxIDE1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjxwYXRoIGQ9Ik0xMiAzVjE2TTEyIDE2TDE2IDExLjYyNU0xMiAxNkw4IDExLjYyNSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4='/>
    Download PDF
  </button>
</div>
<div class="layout" [hidden]="!article">
  <aside>
    <div class="content-table-title">Content</div>

    <ul class="content-table">
      <li class="active">Full article</li>
      <!-- <li>Rising Demand from the Pharmaceutical § Healthcare Industries</li>
      <li>Conflict and high inflation</li>
      <li>Impacted countries</li> -->
    </ul>
  </aside>
  <main>
    <div #content>
      <h1 class="title">{{ article?.eventHeadline }}</h1>
      <div class="date">
        Published: {{ article?.eventDate | date : "d MMM y" }}
      </div>
      <section [innerHTML]="article?.storyHTML"></section>
    </div>
    <section class="map-section" [hidden]="!hasRegions">
      <div id="map" #map></div>
      <button id="refresh" class="btn rounded-circle" (click)="redraw()">
        <fa-icon [icon]="faRefresh"></fa-icon>
      </button>
    </section>
  </main>
</div>
