import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-autocorrelation-model',
  templateUrl: './autocorrelation-model.component.html',
  styleUrls: ['./autocorrelation-model.component.css']
})
export class AutocorrelationModelComponent implements OnChanges {
  @Output() response = new EventEmitter<any>();
  @Input() max!: number;
  @Input() nlags!: number;

  public form!: FormGroup;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
     nlags_: ['100', Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['nlags']){
      this.form.get('nlags_')?.setValue(this.nlags || 100);
    }
  }

  public onSubmit():void{
    if(this.form.invalid) return;
    this.response.emit(this.form.value);
  }
}
