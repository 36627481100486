import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil, finalize } from 'rxjs';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';

@Component({
  selector: 'app-pie-bar-template',
  templateUrl: './pie-bar-template.component.html',
  styleUrls: ['./pie-bar-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PieBarTemplateComponent {
    @Input() dataRes!: any;
    @Input() idsFilters!: any;
    @Input() filtro!:string[];
    @Input() propertyName: string = 'Value';
    @Output() selectedItem = new EventEmitter<number>();

    public histogram!: any;
    public pieChart!: any;
    public ecdfChart!: any;

    public showAlertEcdf = false;
    public showAlertHistogram = false;


    constructor(
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
      private timeVisualizationService: TimeVisualizationTemplateService,
      private dataService: DataMultipleVariableService,
      private chartjsService: ChartjsServiceService,
      @Optional() private dialog: MatDialog,
      private httpClient: HttpClient
    ) {}
  
    ngOnInit(): void {
      this.reloadView();
    }
  
    reloadView() {
      this.pieChart = {
        title: this.dataRes.pieChart.title,
        data: this.dataRes.data.data,
        xLabel: this.dataRes.pieChart.xLabel,
        yLabel: this.dataRes.pieChart.yLabel,
      };

      this.ecdfChart = {
        title: 'ECDF',
        data: this.chartjsService.getDataMapped(
          this.timeVisualizationService.getECDFGeneral(
            this.dataRes.data,
            "value"
          ),
          true,
          false,
          true
        ),
        yLabel: 'Cumulative Relative Frequency',
        xLabel: this.dataRes.pieChart.title,
      };

      this.histogram = {
        data: this.timeVisualizationService.getHistogram(
          this.dataRes.data,
          this.dataRes.histogram.title,
          "value"
        ),
        title: this.dataRes.histogram.title,
        yLabel: this.dataRes.histogram.yLabel,
        xLabel: this.dataRes.histogram.xLabel,
      };

      this.showAlertHistogram = this.histogram.data.length > 1 ? false : true;
      this.showAlertEcdf = this.ecdfChart.data.datasets[0].data.length > 1 ? false : true;
    
      this.cdr.detectChanges(); // Force detetection of changes
    }
  
    openStudyDialog() {
      const dialogRef = this.dialog.open(SelectStudyComponent, {
        height: '380px',
        width: '90%',
        minWidth: '300px',
        maxWidth: '600px',
        data: {
          filterInfo: this.dataRes.filterVariableInfo,
          selectedInfo: this.dataRes.selectedInfo,
        },
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          this.dataRes.selectedInfo = result;
          this.selectedItem.emit(result);
        }
      });
    }
}
  
