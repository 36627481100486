import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private apiService: ApiServiceService, private router : Router, private l: Location) { }

  getBayesianReferences(model: any): Observable<any> {

    return this.apiService.post(`${ApiRouting.home}/${ApiRouting.references}`, model);
  }

  goBack() {
    this.l.back();
  }
}
