import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  LegendComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import { GraphChart, GraphSeriesOption } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { HomeStore } from '../../../../core/stores/home-store';
import {
  faPlus,
  faMinus,
  faRotateRight,
  faArrowRight,
  faArrowLeft,
  faLineChart,
  faExclamationTriangle,
  faSitemap,
  faCheckSquare,
  faEarthAmericas,
  faHourglassHalf,
  faLifeRing,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import {
  GraphItemModel,
  GraphItemStyleModel,
  GraphLinkModel,
} from 'src/app/core/models/graph.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalFiltersComponent } from '../modal-filters/modal-filters.component';
import { NoDataModalComponent } from '../no-data-modal/no-data-modal.component';

echarts.use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GraphChart,
  CanvasRenderer,
]);

type EChartsOption = echarts.ComposeOption<
  TitleComponentOption | TooltipComponentOption | GraphSeriesOption
>;

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})
export class GraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLElement>;
  @Input() isHomeView: boolean = true;
  @Input() isMVM: boolean = false;
  @Input() id: string = 'main';
  @Input() graphItems!: GraphItemModel[];
  @Input() graphLinks: GraphLinkModel[] = [];
  public showAfterInit = false;
  public myControl = new FormControl<string | GraphItemModel>('');
  public text!: any;
  public items!: GraphItemModel[];
  public itemsTemp!: GraphItemModel[];
  public itemsTempTimeSeries!: GraphItemModel[];
  public itemsTempPoint!: GraphItemModel[];
  public itemsTempSpatial!: GraphItemModel[];
  public itemsTempSpatialTime!: GraphItemModel[];
  public copyItems!: GraphItemModel[];
  public filteredOptions!: Observable<GraphItemModel[]>;

public copy:any[]=[];
  public categoryLinks: any[] = [];
  private itemLinks!: GraphLinkModel[];
  private myChart!: echarts.ECharts;
  private option!: EChartsOption;
  private zoom: number = 2.5;
  private initialZoom: number = 25;
  private maxZoom: number = 4;
  private minZoom: number = 1;
  private nodeWidth: number = 10;
  private Nodecenter: number[] = [25, 13];
  private initialNodecenter: number[] = [25, 13];
  private zoomEvent: number = 0;
  private slideEvent: number = 0;
  //Icons
  public faPlus = faPlus;
  public faMinus = faMinus;
  public faRotateRight = faRotateRight;
  public faArrowRight = faArrowRight;
  public faArrowLeft = faArrowLeft;

  public faLineChart = faLineChart;
  public faExclamationTriangle = faExclamationTriangle;
  public faSitemap = faSitemap;
  public faCheckSquare = faCheckSquare;
  public faEarthAmericas = faEarthAmericas;
  public faHourglassHalf = faHourglassHalf;
  public faLifeRing = faLifeRing;
  public faFilter = faFilter;

  public selectedOptionClassification: string = 'All';
  public itemsClassification = [
    { name: 'All', icon: this.faCheckSquare },
    { name: 'Threat', icon: this.faExclamationTriangle },
    { name: 'System', icon: this.faSitemap },
    { name: 'Impact', icon: this.faLineChart },
  ];

  public selectedOptionVariableType: string = 'All';
  public itemsVariableType = [
    { name: 'All', icon: this.faCheckSquare },
    { name: 'Point Process', icon: this.faLifeRing },
    { name: 'Time Series', icon: this.faLineChart },
    { name: 'Spatial', icon: this.faEarthAmericas },
    { name: 'Spatial Temporal', icon: this.faHourglassHalf },
  ];

  public categories_: any[] = [];

  private originalOptionClassification: string = 'All';
  private originalOptionVariableType: string = 'All';
  banderaVarType: boolean = false;
  copyItemsFilterByVarType: any = null;
  public dataIsEmpty: boolean = false;

  constructor(
    private homeStore: HomeStore,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public dialogNoData: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    this.homeStore.appContextObservable.subscribe((res) => this.handleUpdate());
  }
  ngAfterViewInit(): void {
    this.handleUpdate();
  }
  ngOnDestroy(): void {
    this.myChart!.clear();
    this.myChart!.dispose();
  }
  ngOnInit(): void {
    this.handleUpdate();
  }
  handleUpdate() {
    this.myControl.setValue('');
    this.categoryLinks = this.homeStore.getGraphCategoryLinks();
    if (!this.categoryLinks) return;
    this.categoryLinks.forEach((category) => {
      category.links.forEach((link: { checked: boolean }) => {
        link.checked = true;
      });
    });

    let itemsSINFiltrado: GraphItemModel[] = [];
    if (!this.isMVM) {
      ///console.log('else is MVM 1', this.isMVM)
      this.itemLinks = this.homeStore.getGraphLinks();
      itemsSINFiltrado = this.homeStore.getGraphItems();
    } else {
      //.log('else is MVM', this.isMVM)
      this.itemLinks = this.homeStore.getMVMGraphLinks();//this.graphLinks;
      itemsSINFiltrado = this.homeStore.getMVMGraphItems();//this.graphItems;
    }
    this.items =
      itemsSINFiltrado?.filter((item) => item.name !== 'invisible') || [];
    //console.log(this.isMVM, 'es MVM')
    if (this.isHomeView) {
      this.items = this.items.map((item: GraphItemModel) => {
        if( ['Impact', 'State of Risk'].includes(item.classification != undefined ? item.classification : '' ) ){
          item.symbol = 'path://M238.8050193363579,152.48640927958579 L274.5907432011123,124.48640494986219 L370.0193327017493,124.48640494986219 L405.80504535844415,152.48640927958579 L370.0193327017493,180.48641360931023 L274.5907432011123,180.48641360931023 L238.8050193363579,152.48640927958579 z'
        }
        if (item.isActive) {
          item.symbolSize = 40;
          item.itemStyle = {
            borderType: 'solid',
            borderWidth: 2,
            borderColor: '#fff',
            shadowColor: 'rgba(0, 0, 0, 1)',
            shadowBlur: 2,
          };
        }
        else {
          item.symbolSize = 20;
          item.itemStyle = {
            borderType: 'solid',
            borderWidth: 1,
            borderColor: '#000000', //'#fff',
          };
        }
        return item;
      });
    }
    if (!this.items.filter((x) => x.name === 'Select all').length) {
      this.items.unshift({
        fullname: 'Select all',
        name: 'Select all',
        x: 80,
        y: 12.1,
        itemStyle: { color: '#fff', borderWidth: 0 },
        label: { color: '#fff' },
        isActive: true,
      });
    }
    this.copyItems = JSON.parse(JSON.stringify(this.items));
    if (!this.chartEl?.nativeElement) return;
    this.myChart = echarts.init(this.chartEl.nativeElement);
    window.dispatchEvent(new Event('resize'));
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.items.slice();
      })
    );

    if (!this.isHomeView && !this.isMVM) {
      this.getSelectedNodeByOrginal(this.homeStore.getSelectedNodeOriginal());
    }
    
    this.selectedOptionClassification='All';
    this.selectedOptionVariableType='All';
    
    this.spinner.hide();
  }
  displayFn(user: GraphItemModel): string {
    return user && user.fullname ? user.fullname : '';
  }
  private _filter(name: string): GraphItemModel[] {
    const filterValue = name.toLowerCase();

    return this.items.filter((option) =>
      option!.name.toLowerCase().includes(filterValue)
    );
  }
  getSelectedNodeByOrginal(node: string) {
    this.spinner.show();
    const copy = JSON.parse(JSON.stringify(this.items));
    const item = copy.filter((x: any) => x.name === node);
    this.copyItems = item;

    if (this.itemLinks != undefined && this.itemLinks.length > 0) {
      const links = this.itemLinks
        .filter((x) => x.source === node)
        .map((x) => {
          return x.target;
        });
      const itemLinks = copy.filter((x: any) => links.includes(x.name));
      this.copyItems.push(...itemLinks);
    }
    this.Nodecenter = [item.at(0)?.x, item.at(0)?.y];
    this.zoom = 0.5;
    this.slideEvent = 2;
    this.myChart.setOption({
      series: [
        {
          data: this.copyItems.map((item: GraphItemModel) => {
            if (item.isActive) {
              item.symbolSize = 10;
              item.itemStyle = {
                borderWidth: 1,
                shadowColor: 'rgba(0, 0, 0, 1)',
                shadowBlur: 0,
              };
              return item;
            }
            return item;
          }),
          center: this.Nodecenter,
          zoom: this.zoom,
        },
      ],
    });
    this.spinner.hide();
  }
  getSelectedNode(event: any) {
    this.spinner.show();
    const value = this.myControl.value as GraphItemModel;
    if (value.name === 'Select all') {
      this.copyItems = JSON.parse(JSON.stringify(this.items));
      this.myChart.setOption({
        series: [
          {
            data: this.copyItems,
          },
        ],
      });
      window.dispatchEvent(new Event('resize'));
      this.myControl.setValue('');
      event.option.deselect();
      this.getSelectedClassification({ value: 'All' }); // Disparar el evento "All" para el segundo div
      this.spinner.hide();
      return;
    }
    const copy = JSON.parse(JSON.stringify(this.items));
    const item = copy.filter((x: any) => x.name === value.name);
    this.copyItems = item;

    if (this.itemLinks != undefined && this.itemLinks.length > 0) {
      const links = this.itemLinks
        .filter((x) => x.source === value.name)
        .map((x) => {
          return x.target;
        });
      const itemLinks = copy.filter((x: any) => links.includes(x.name));
      this.copyItems.push(...itemLinks);
    }
    this.Nodecenter = [item.at(0)?.x, item.at(0)?.y];
    this.zoom = 1.5;
    this.slideEvent = 2;
    this.myChart.setOption({
      series: [
        {
          data: this.copyItems,
          center: this.Nodecenter,
          zoom: this.zoom,
        },
      ],
    });
    this.spinner.hide();
  }
  getSelectedSubClassification(event: any) {
    this.spinner.show();

    const activeCategories: any = [];

    this.categoryLinks.forEach((categoryGroup) => {
      categoryGroup.links.forEach((link: { checked: any; name: any }) => {
        if (link.checked) {
          activeCategories.push(link.name);
        }
      });
    });

    const activeCategoriesIndices = activeCategories.map(
      (categoryName: any) => {
        return this.categories_.findIndex(
          (category) => category.name === categoryName
        );
      }
    );

    const copy = JSON.parse(JSON.stringify(this.items));

    const filteredCopy = copy.filter((item: any) =>
      activeCategoriesIndices.includes(item.category)
    );
    this.copyItems = filteredCopy;

    this.Nodecenter = [filteredCopy.at(1)?.x, filteredCopy.at(1)?.y];
    this.zoom = 1.5;
    this.slideEvent = 2;
    this.myChart.setOption({
      series: [
        {
          data: this.copyItems,
          center: this.Nodecenter,
          zoom: this.zoom,
        },
      ],
    });
    this.spinner.hide();
  }
  getSelectedClassification(event: any) {
    this.spinner.show();
    if(this.selectedOptionVariableType==='All'){
this.copyItemsFilterByVarType=null;
    }
    const value = event.value || this.selectedOptionClassification;

    if (value === 'All') {
      // this.copyItems = JSON.parse(JSON.stringify(this.items));
      // this.myChart.setOption({
      //   series: [
      //     {
      //       data: this.copyItems,
      //     },
      //   ],
      // });
      this.copyItems=this.copy;
      window.dispatchEvent(new Event('resize'));
      this.spinner.hide();
      return;
    }

    // this.copy = JSON.parse(JSON.stringify(this.items));
    
    // if(this.copyItemsFilterByVarType != null){
    //   copy = JSON.parse(JSON.stringify(this.copyItemsFilterByVarType));
    // } 

    const item = this.copy.filter((x: any) => x.classification === value);
    this.copyItems = item;

    if (this.itemLinks != undefined && this.itemLinks.length > 0) {
      const links = this.itemLinks
        .filter((x) => x.source === value.name)
        .map((x) => {
          return x.target;
        });
      const itemLinks = this.copy.filter((x: any) => links.includes(x.name));
      this.copyItems.push(...itemLinks);
    }
    this.Nodecenter = [item.at(0)?.x, item.at(0)?.y];
    this.zoom = 1.5;
    this.slideEvent = 2;
    this.myChart.setOption({
      series: [
        {
          data: this.copyItems,
          center: this.Nodecenter,
          zoom: this.zoom,
        },
      ],
    });
    this.spinner.hide();
  }
  async getSelectedVariableType(event: any) {
    this.spinner.show();
    const value = event.value || this.selectedOptionVariableType;

    if (value === 'All') {
      this.copyItems = JSON.parse(JSON.stringify(this.items));
      this.copy=this.items;
      this.myChart.setOption({
        series: [
          {
            data: this.copyItems,
          },
        ],
      });
      window.dispatchEvent(new Event('resize'));
      this.spinner.hide();
      return;
    }

    await this.homeStore.setGraphItemsByVarType(value);
    this.itemsTemp = this.homeStore.getGraphItemsByVarType();

    if(this.itemsTemp.length < 1){
      this.dataIsEmpty = true;
    } else {
      this.dataIsEmpty = false;
    }

    this.copy = JSON.parse(JSON.stringify(this.itemsTemp));
    this.copy.forEach((tempItem: { fullname: string; classification: string | undefined; itemStyle: GraphItemStyleModel | undefined; symbolSize: number | number[] | undefined; }) => {
      const matchingItem = this.items.find(item =>
        item.fullname === tempItem.fullname && item.classification === tempItem.classification
      );

      if (matchingItem) {
        tempItem.itemStyle = matchingItem.itemStyle;
        tempItem.symbolSize = matchingItem.symbolSize;
      }
    });
    
    if(this.banderaVarType){
      this.copyItemsFilterByVarType = JSON.parse(JSON.stringify(this.itemsTemp));

    } else {
      this.copyItemsFilterByVarType = null;
    }

    const item = this.copy;
    this.copyItems = item;

    if (this.itemLinks != undefined && this.itemLinks.length > 0) {
      const links = this.itemLinks
        .filter((x) => x.source === value.name)
        .map((x) => {
          return x.target;
        });
      const itemLinks = this.copy.filter((x: any) => links.includes(x.name));
      this.copyItems.push(...itemLinks);
    }
    this.Nodecenter = [item.at(0)?.x, item.at(0)?.y];
    this.zoom = 1.5;
    this.slideEvent = 2;
    this.myChart.setOption({
      series: [
        {
          data: this.copyItems,
          center: this.Nodecenter,
          zoom: this.zoom,
        },
      ],
    });

    this.spinner.hide();
  }
  initChart(zoom?: number, center?: number[]) {
    if (!this.myChart) return;
    this.categories_ = this.homeStore.getGraphCategories();
    const categories = this.homeStore.getGraphCategories();
    const onPointDragging = (dataIndex: any, event: any) => {
      let pos = this.myChart.convertFromPixel({ seriesIndex: 0 }, [
        event.offsetX,
        event.offsetY,
      ]);
      this.copyItems[dataIndex].x = pos[0];
      this.copyItems[dataIndex].y = pos[1];
      this.myChart.setOption({
        series: {
          nodes: this.copyItems,
        },
      });
      this.myChart.setOption({
        graphic: echarts.util.map(this.copyItems, (item, dataIndex) => {
          return {
            position: this.myChart.convertToPixel({ seriesIndex: 0 }, [
              item.x,
              item.y,
            ]),
          };
        }),
      });
    };
    this.option = {
      graphic: echarts.util.map(this.copyItems, (item, dataIndex) => {
        return {
          type: 'circle',
          position: this.myChart.convertToPixel({ seriesIndex: 0 }, [
            item.x,
            item.y,
          ]),
          shape: {
            r: item.symbolSize,
          },
          invisible: true,
          draggable: true,
          ondrag: echarts.util.curry(onPointDragging, dataIndex),
          z: 100,
        };
      }),
      series: [
        {
          type: 'graph',
          layout: 'none',
          zoom: zoom !== undefined ? zoom : this.zoom,
          scaleLimit: {
            min: this.minZoom,
            max: this.maxZoom,
          },
          symbolSize: this.nodeWidth,
          center: center !== undefined ? center : this.Nodecenter,
          roam: true,
          itemStyle: {
            borderColor: '#000',
          },
          color: '#500000',
          label: {
            show: false,
            color: '#000',
            fontWeight: 'bold',
            fontSize: 12 ,
            position: 'top',
            formatter: '{b}',
            width: 40,
            height: 40,
            overflow: 'truncate'
          },
          symbol: 'circle',
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          data: this.copyItems,
          // links: [],
          links: this.itemLinks.map((l) => {
            const source = this.copyItems.find((n) => n.name == l.source);
            // if (this.isMVM) { console.log(source?.category, source, categories, l)}
            if (!source || source.category == undefined) return l;
            const color = categories[source.category]?.itemStyle?.color;
            //console.log('source', source,l)
            if (color.toString() === '#ffffff') { l.lineStyle = {color: '#5C5858'} as any; return l}
            if (!color) return l;
            l.lineStyle = {
              color,
            } as any;
            return l;
          }),
          categories,
          lineStyle: {
            opacity: 0.9,
            width: 1,
            curveness: 0,
          },
          draggable: true,
          labelLayout: {
            hideOverlap: true,
          },
          emphasis: {
            focus: 'adjacency',
            label: {
              show: true,
              width: 100,
            },
          },
        },
      ],
    };
    this.myChart.on('click', (params: any) => {
      if (this.validateClick(params.name)) {
        if(!this.isMVM){
          this.homeStore.setSelectedNodeOriginal(params.name);
          const node: string = params.name
            .trim()
            .split('\n')
            .join('-')
            .replaceAll(' ', '-')
            .replaceAll(/\//g, "%2F");
          this.redirectTo(node);
        } else {
          this.homeStore.setSelectedNodeOriginal(params.name);
          var node: string = params.name
            .trim()
            .split('\n')
            .join('-')
            .replaceAll(' ', '-')
            .replaceAll(/\//g, "%2F");

            node = node + '-mvm';

          this.redirectTo(node);
        }
      }
    });
    this.myChart.setOption(this.option, true);
  }
  validateClick(str: string): boolean {
    if (str.includes('>') || str.includes('<')) return false;
    else return true;
  }
  redirectTo(node: string): void {
    this.homeStore.setSelectedNode(node);
    const newUrl = this.homeStore.getSelectedNode();
    this.router
      .navigate([newUrl], { relativeTo: this.route })
      .then()
      .catch((e) => {
        console.error(e);
        console.error('Cannot redirect');
        this.homeStore.setSelectedNode('');
      });
  }
  @HostListener('window:resize', ['$event'])
  onResze(event: any) {
    this.myChart?.clear();
    const width = event.target.innerWidth;
    if (width > 1020) {
      this.zoom = this.setZoom(0);
      this.initialZoom = this.setZoom(0);
      this.maxZoom = 5;
      this.minZoom = 0.25;
      this.nodeWidth = 10;
      this.Nodecenter = this.setNodeCenter(0, 5);
      this.initialNodecenter = this.setNodeCenter(0, 5);
      this.zoomEvent = 0.5;
      this.slideEvent = 20;
    }
    if (width <= 1020 && width > 820) {
      this.zoom = this.setZoom(2); //+2
      this.initialZoom = this.setZoom(2); //+2
      this.maxZoom = 10;
      this.minZoom = 0.25;
      this.nodeWidth = 8;
      this.Nodecenter = this.setNodeCenter(15, 4); //5 10
      this.initialNodecenter = this.setNodeCenter(15, 4);
      this.zoomEvent = 2;
      this.slideEvent = 18;
    }
    if (width <= 820 && width > 720) {
      this.zoom = this.setZoom(3);
      this.initialZoom = this.setZoom(3);
      this.maxZoom = 12;
      this.minZoom = 0.25;
      this.nodeWidth = 6;
      this.Nodecenter = this.setNodeCenter(17, 4);
      this.initialNodecenter = this.setNodeCenter(17, 4);
      this.zoomEvent = 2;
      this.slideEvent = 18;
    }
    if (width <= 720 && width > 585) {
      this.zoom = this.setZoom(4);
      this.initialZoom = this.setZoom(4);
      this.maxZoom = 16;
      this.minZoom = 0.25;
      this.nodeWidth = 5;
      this.Nodecenter = this.setNodeCenter(18, 3); // 2 9
      this.initialNodecenter = this.setNodeCenter(18, 3);
      this.zoomEvent = 2;
      this.slideEvent = 16;
    } else if (width <= 585) {
      this.zoom = this.setZoom(6);
      this.initialZoom = this.setZoom(6);
      this.maxZoom = 12;
      this.minZoom = 0.25;
      this.nodeWidth = 3;
      this.Nodecenter = this.setNodeCenter(20, 0); //0 10
      this.initialNodecenter = this.setNodeCenter(20, 0);
      this.zoomEvent = 3;
      this.slideEvent = 14;
    }
    this.initChart(this.initialZoom, this.initialNodecenter);
    setTimeout(() => {
      this.myChart?.resize();
    }, 10);
  }
  zoomIn() {
    if (this.zoom >= this.maxZoom) return;
    this.zoom += this.zoomEvent;
    this.myChart.setOption({
      series: [
        {
          zoom: this.zoom,
        },
      ],
    });
  }
  zoomOut() {
    if (this.zoom <= this.minZoom) return;
    this.zoom -= this.zoomEvent;
    this.myChart.setOption({
      series: [
        {
          zoom: this.zoom,
        },
      ],
    });
  }
  reload() {
    this.spinner.show();
    const value = this.myControl.value as GraphItemModel;
    const valueString = value + '';
    if (value.name === '' || value === undefined || valueString === '') {
      window.dispatchEvent(new Event('resize'));
    } else {
      this.Nodecenter = [this.copyItems[0].x, this.copyItems[0].y];

      this.myChart.setOption(
        {
          series: [
            {
              center: this.Nodecenter,
            },
          ],
        },
        false,
        true
      );
    }
    this.spinner.hide();
  }
  goLeft() {
    if (this.Nodecenter[0] - this.slideEvent <= -4) return;
    this.Nodecenter[0] -= this.slideEvent;
    this.myChart.setOption({
      series: [
        {
          center: this.Nodecenter,
        },
      ],
    });
  }
  goRight() {
    if (this.Nodecenter[0] + this.slideEvent >= 100) return;
    this.Nodecenter[0] += this.slideEvent;
    this.myChart.setOption({
      series: [
        {
          center: this.Nodecenter,
        },
      ],
    });
  }
  setNodeCenter(minus: number, minus2: number): number[] {
    var center = this.homeStore.Nodecenter;
    if (this.isMVM) {
      center = this.homeStore.nodeCenterMvm;
    }
    const items = JSON.parse(JSON.stringify(center));
    items[0] -= minus;
    items[1] -= minus2;
    return items;
  }
  setZoom(plus: number) {
    const zoomCopy = JSON.parse(JSON.stringify(this.homeStore.zoom));
    return zoomCopy + plus;
  }

  openModal(): void {
    this.originalOptionClassification = this.selectedOptionClassification;
    this.originalOptionVariableType = this.selectedOptionVariableType;
    const categoriesOriginal = JSON.parse(JSON.stringify(this.categoryLinks));

    const dialogRef = this.dialog.open(ModalFiltersComponent, {
      width: '6000px',
      height: 'auto',
      data: {
        itemsClassification: this.itemsClassification,
        itemsVariableType: this.itemsVariableType,
        categories: this.categoryLinks,
        selectedOptionClassification: this.selectedOptionClassification,
        selectedOptionVariableType: this.selectedOptionVariableType,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.categoryLinks = result.categories;
        await this.getSelectedSubClassification(this.categoryLinks);
    
        // if (
        //   result.selectedOptionVariableType != this.originalOptionVariableType 
        // ) {
        //   this.banderaVarType = true;
          this.selectedOptionVariableType = result.selectedOptionVariableType;
          await this.getSelectedVariableType(this.selectedOptionVariableType);

          if (this.dataIsEmpty) {
            const noDataDialogRef = this.dialog.open(NoDataModalComponent, {
              width: '400px',
              data: {
                message: "There is not data avaiable for the type of variable: "+this.selectedOptionVariableType+"."
              }
            });
          }
          
          setTimeout(() => {
            this.selectedOptionClassification = result.selectedOptionClassification;
            this.getSelectedClassification(this.selectedOptionClassification);
          }, 300);
          
        // }
        
        this.cdr.detectChanges();
      }
    });
  }
}
