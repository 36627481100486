import { Injectable } from '@angular/core';
import { ApiServiceService } from '../apiService/api-service.service';
import { Observable } from 'rxjs';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class InorganicChemicalService {

  constructor(private apiService: ApiServiceService) { }

  getWindTurbineUS(): Observable<any> { //Power Source
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.windTurbine}_us`);
  }

  getMineralResourcesUS(): Observable<any> { 
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.mineralResources}_us`);
  }

  getWorkerFatalitieUS(): Observable<any> { 
    return this.apiService.get(`${ApiRouting.stateOfRisk}/${ApiRouting.workerFatalities}_us`);
  }

  getChemicalIndustryInvestmentMx(): Observable<any> { 
    return this.apiService.get(`${ApiRouting.anthropogenic}/${ApiRouting.chemicalIndustryInvestment}_mx`);
  }

}