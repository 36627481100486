import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { InorganicChemicalRoutingModule } from './inorganic-chemical.routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    InorganicChemicalRoutingModule,
    SharedModule,
    MatExpansionModule
  ]
})
export class InorganicChemicalModule { }
