import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
declare const L: any;
import 'leaflet';
import "leaflet-timedimension";
import "../../../../../assets/js/leaflet.timedimension.heatmap";
import "../../../../../assets/js/leaflet.timedimension.heatmap.control";
import "../../../../../assets/js/leaflet-heatmap.control";
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-heatmap',
  templateUrl: './dynamic-heatmap.component.html',
  styleUrls: ['./dynamic-heatmap.component.css']
})
export class DynamicHeatmapComponent implements OnChanges{
  @ViewChild('map', {read: ElementRef}) private mapEl!: ElementRef<HTMLElement>;
  @Input() data!: any;
  @Input() newData!: any;
  @Input() forceUpdate!: boolean;
  @Input() dates!: any;
  @Input() formatInfo!: any;

  @Output() setIndex = new EventEmitter<string>();
  @Output() forward = new EventEmitter<boolean>();
  @Output() backward = new EventEmitter<boolean>();

  private map: any;



  private geojson!: any;
  private player !: any;
  private heatmapControl !: any;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) { }


  ngOnChanges(changes: SimpleChanges): void {
      if(changes['newData'].currentValue !== undefined &&
      changes['newData'].currentValue !== null ){
        if(this.newData.length == 0) return;
        if (this.map !== undefined) {
          this.map.remove();
        }
        this.initMap();
        this.onReload();
        this.AddNewGeoJson();
      }
  }

  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.initMap();
    this.onReload();
  }
  stopPlayer(){
    this.player.stop();
  }

  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map("mapHeat", {
      center: [54.8, -96],
      zoom: 2.5,
      zoomControl: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 12,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: () => {
          return '<img class="icon-svg" src="' + environment.BaseAssets + '/house-solid.svg" alt="home-icon">';
        },
        zoomHomeTitle: 'Zoom home'
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
        this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView([54.8, -96], 2.5);
      },

      _createButton: function (html: any, title: any, className: any, container: any, fn: any) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      }
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  onReload() {
    this.initStatesLayer();
  }

  private initStatesLayer() {
    var timeDimension = new L.TimeDimension({
      period: "PT1H",
      times: this.dates
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player({
      transitionTime: 2000,
      loop: false,
      startOver: false
    }, timeDimension);
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      speedSlider: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 1,
      timeSliderDragUpdate: false,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays,
      timeSlider: false,
      playButton: false
    };
    var timeDimensionControl = new L.Control.TimeDimensionHeatmap(timeDimensionControlOptions);

    this.map.addControl(timeDimensionControl);
    this.heatmapControl= L.control.heatmapControl({position: "bottomright"});
    this.map.addControl(this.heatmapControl);

    var forwardButton = document.querySelector('a.leaflet-control-timecontrol.timecontrol-forward');
    forwardButton?.addEventListener('click', () => {
        this.forward.emit(true);
    });
    var backwardButton = document.querySelector('a.leaflet-control-timecontrol.timecontrol-backward');
    backwardButton?.addEventListener('click', () => {
        this.backward.emit(true);
    });

    this.player.on('animationfinished', (e: any) => {
        this.setIndex.emit(this.map.timeDimension.getCurrentTime());
    });
  }
  private AddNewGeoJson(){
    //this.timeSeries = L.geoJSON(this.newData, { style: this.style });
    this.geojson = L.timeDimension.layer.Heatmap(this.newData, {
      heatmapOptions: {
          radius: 15,
          maxOpacity: .8,
          scaleRadius: false,
          useLocalExtrema: false,
          defaultWeight: 1,
          heatmapControl: this.heatmapControl
          }
      });
    this.geojson.addTo(this.map);

  }


}
