import { GraphLinkModel } from "../models/graph.model";

export const mvmLinksAutomobileUSA: GraphLinkModel[] = [
  {
    "source": "Assembly",
    "target": "Rails\nInfrastructure\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Trucks\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Car\nand\nAutomobile\nManufacturing",
    "target": "Sales",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Duty\nand\nCustoms\nRegulations",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Rails\nInfrastructure\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Trucks\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Infrastructure\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Ports\nInfrastructure\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Infrastructure\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Rails\nInfrastructure\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitical\nTension",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitical\nTension",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Trucks\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Vessels\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Infrastructure\nCapacity",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Infrastructure\nCapacity",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Infrastructure\nCapacity",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Laws\nand\nRegulations",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Rails\nInfrastructure\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Trucks\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturers",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\nInfrastructure\n(S-M)",
    "target": "Vessels\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\nInfrastructure\n(M-C)",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\nInfrastructure\n(M-C)",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\nInfrastructure\n(M-C)",
    "target": "Trucks\n(M-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\nInfrastructure\n(S-M)",
    "target": "Trucks\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Trucks\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Vessels\n(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "SUV\nand\nLight\nTruck\nManufacturing",
    "target": "Sales",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n(M-C)",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n(M-C)",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n(S-M)",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n(S-M)",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n(S-M)",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessels\n(S-M)",
    "target": "Original\nEquipment\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessels\n(S-M)",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessels\n(S-M)",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  }
]
