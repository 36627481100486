
  <div #chart class="chartdiv"></div>

  <div #chart2 class="chartdiv"></div>

    
  <!-- Segundo Selector -->
  <select id="eventSelect2" class="selectclass" (change)="onEventSelect($event, data2, true)">
    <option value="">Select an event</option>
    <option *ngFor="let item of data2" [value]="item.cantidad">{{item.country}}</option>
  </select>
  <div class="selectTextoSelect">
    Number of news articles for <span>{{ selectedEvent2 }}</span>: <span>{{ articleCount2 }}</span>
  </div>