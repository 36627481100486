import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {GraphSectionComponent} from './graph-section/graph-section.component';
import {StoryV2Component} from "./story-v2/story-v2.component";
import {TheColoradoRiverComponent} from './the-colorado-river/the-colorado-river.component';
import {DataDrivenModelCalculatorComponent} from "./data-drive-model-calculator/data-driven-model-calculator.component";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    StoryV2Component,
    GraphSectionComponent,
    TheColoradoRiverComponent,
    DataDrivenModelCalculatorComponent
  ],
  exports: [
    StoryV2Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
  ]
})
export class StoryModule {
}
