<mat-accordion class="example-headers-align"  >
    <mat-expansion-panel [expanded]="step === 0">

      <mat-expansion-panel-header>
        <mat-panel-title>
          General
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
      <app-graph></app-graph>
      </ng-template>
  
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
          References
        </mat-expansion-panel-header>
        <div *ngIf="this.referenceData !== undefined">
            <custom-app-tree [data]="this.referenceData"></custom-app-tree>
        </div>
    </mat-expansion-panel>
  
  </mat-accordion>

