<section #sectionEl class="flex flex-column">
  <div class="bg-white d-flex flex-column justify-content-center" style="padding: 10rem; height: 200vh">
    <h3 #titleEl *ngIf="title">{{ title }}</h3>
    <p #descriptionEl *ngIf="description">{{ description }}</p>
  </div>
  <div #chartColEl class="overflow-hidden d-flex" style="height: 100vh">
    <div #chartWrapperEl class="d-flex flex-column justify-content-center h-100 w-100" style="padding: 10rem">
      <ng-content></ng-content>
    </div>
  </div>
</section>
