

<div *ngIf="this.isCN">
  <div *ngIf="this.canData !== undefined">
    <app-dynamic-shapefiles-template [data]="this.canData" [title]="''" 
    [AllowValueHover]="true"
    [typeVar]="'Temporal w Filters'"
    [haveFilters]="1" 
    [filters]="this.filterInfoValues"
    (firstValue)="handleFilterUpdate($event)"></app-dynamic-shapefiles-template>
  </div>  
</div>

    <div *ngIf="!this.isUSA && !this.isCN">
      <app-not-found></app-not-found>
    </div>
    
