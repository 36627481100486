import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-six-single-filter',
  templateUrl: './six-single-filter.component.html',
  styleUrls: ['./six-single-filter.component.css'],
})
export class SixSingleFilterComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() data!: any;
  @Input() defaultSelection!: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  firstFilter = new FormControl<string>('');
  secondFilter = new FormControl<string>('');
  thirdFilter = new FormControl<string>('');
  fourthFilter = new FormControl<string>('');
  fifthFilter = new FormControl<string>('');
  sixthFilter = new FormControl<string>('');

  constructor() {
    this.firstFilter.valueChanges.subscribe((x) => {
      //x equals to id
      const data_ = this.data.firstFilter.values.at(x);
      if (data_ !== undefined) this.firstValue.emit(data_.value);
    });
    this.secondFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.secondFilter.values.at(x);
      if (data_ !== undefined) this.secondValue.emit(data_.value);
    });
    this.thirdFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.thirdFilter.values.at(x);
      if (data_ !== undefined) this.thirdValue.emit(data_.value);
    });
    this.fourthFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.fourthFilter.values.at(x);
      if (data_ !== undefined) this.fourthValue.emit(data_.value);
    });
    this.fifthFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.fifthFilter.values.at(x);
      if (data_ !== undefined) this.fifthValue.emit(data_.value);
    });
    this.sixthFilter.valueChanges.subscribe((x) => {
      const data_ = this.data.sixthFilter.values.at(x);
      if (data_ !== undefined) this.sixthValue.emit(data_.value);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.firstFilter.patchValue(this.data.firstFilter.values.at(0).id);
      this.secondFilter.patchValue(this.data.secondFilter.values.at(0).id);
      this.thirdFilter.patchValue(this.data.thirdFilter.values.at(0).id);
      this.fourthFilter.patchValue(this.data.fourthFilter.values.at(0).id);
      this.fifthFilter.patchValue(this.data.fifthFilter.values.at(0).id);
      this.sixthFilter.patchValue(this.data.sixthFilter.values.at(0).id);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.firstFilter.setValue(this.defaultSelection.Filter_One);
      this.secondFilter.setValue(this.defaultSelection.Filter_Two);
      this.thirdFilter.setValue(this.defaultSelection.Filter_Three);    
      this.fourthFilter.setValue(this.defaultSelection.Filter_Four);    
      this.fifthFilter.setValue(this.defaultSelection.Filter_Five);    
      this.sixthFilter.setValue(this.defaultSelection.Filter_Six);    
    });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   if (this.defaultSelection && this.defaultSelection.Filter_One !== null) {
    //     this.firstFilter.setValue(this.defaultSelection.Filter_One);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Two !== null) {
    //     this.secondFilter.setValue(this.defaultSelection.Filter_Two);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Three !== null) {
    //     this.thirdFilter.setValue(this.defaultSelection.Filter_Three);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Four !== null) {
    //     this.fourthFilter.setValue(this.defaultSelection.Filter_Four);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Five !== null) {
    //     this.fifthFilter.setValue(this.defaultSelection.Filter_Five);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Six !== null) {
    //     this.sixthFilter.setValue(this.defaultSelection.Filter_Six);
    //   }
    // });
  }

}
