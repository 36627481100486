import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-maritime-ports-infrastructure',
  templateUrl: './maritime-ports-infrastructure.component.html',
  styleUrls: ['./maritime-ports-infrastructure.component.css']
})
export class MaritimePortsInfrastructureComponent {
  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }

  }
  getDataCN() {
    this.spinner.hide();
  }
  getDataMx() {
    const  dataMap = {
      center: MapCenter.MEXICO,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.TRI,
      zoom: MapZoom.MEXICO,
      borderColor: '#000000',
      color: '#7c7c7c',
      fillColor: '#FFFFFF'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };

    this.naturalService.getMaritimePortsInfrastructureMEX().subscribe(
      {
        next: (res:any) => {
          this.data = {
              data: res,
              formatInfo: formatInfo,
              metadata: res.metadata, 
              mapInfo: dataMap,
              filterInfo: {},
              selectedInfo: 0,
              hasStudyButton: false,
              typeMap: 'Static',
            };
            this.spinner.hide();
        },
        error: (error:any) => {this.spinner.hide(); console.error(error);}
      });
      
  }
  getDataTri() {
    this.spinner.hide();
  }

  getDataUs() {
    const  dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.TRI,
      zoom: MapZoom.USA,
      borderColor: '#000000',
      color: '#7c7c7c',
      fillColor: '#FFFFFF'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };

    this.naturalService.getMaritimePortsInfrastructureUSA().subscribe(
      {
        next: (res:any) => {
          this.data = {
              data: res,
              formatInfo: formatInfo,
              metadata: res.metadata, 
              mapInfo: dataMap,
              filterInfo: {},
              selectedInfo: 0,
              hasStudyButton: false,
              typeMap: 'Static',
            };
            this.spinner.hide();
        },
        error: (error:any) => {this.spinner.hide(); console.error(error);}
      });

  }

}

