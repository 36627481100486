import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { MapCenter, MapJson, MapZoom, ShapeMapDataLink } from 'src/app/core/enums/map.enum';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-natural-forest',
  templateUrl: './natural-forest.component.html',
  styleUrls: ['./natural-forest.component.css']
})
export class NaturalForestComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }

  public f1 = 'Webb';
  public f2 = 'maxStorage';

  public selection: any = { 
    filter_one: this.f1,
    filter_two: this.f2,
  };

  filterInfoValues!: any;

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(async () => {
      const filters = await firstValueFrom(
        this.naturalService.getFiltersNaturalForest()
      );
  
      this.filterInfoValues = {
        firstFilter: filters.filter_OneData,
        secondFilter: filters.filter_TwoData
      };

      if (this.isCN) {
        this.getDataCN();
      } else if (this.isUSA) {
        this.getDataUs();
      } else if (this.isMx) {
        this.getDataMx();
      }else{
        this.getDataTri();
      }

    }, 300); 

  }

  getDataCN() {
    this.spinner.hide();
  }

  getDataTri() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataUs(){
    const shapeTypes = [
      {id: 0, name: 'Abnormally Dry', color: 'rgba(255,255,31, 1)', shapeColor: 'rgba(255,255,31, 0.45)'},
      {id: 1, name: 'Moderate Drought', color: 'rgba(239,197,123, 1)', shapeColor: 'rgba(239,197,123, 0.45)'},
      {id: 2, name: 'Severe Drought', color: 'rgba(246,149,0, 1)', shapeColor: 'rgba(246,149,0, 0.45)'},
      {id: 3, name: 'Extreme Drought', color: 'rgba(254,1,0, 1)', shapeColor: 'rgba(254,1,0, 0.45)'},
      {id: 4, name: 'Exceptional Drought', color: 'rgba(129,1,1, 1)', shapeColor: 'rgba(129,1,1, 0.45)'},
      {id: 5, name: 'Impact lines', color: 'rgba(97,114,112, 1)', shapeColor: 'rgba(97,114,112, 0.45)'},
    ];
      this.naturalService.getNaturalForestDates()
        .subscribe(
          {
            next: (res:any) => {
              console.log(res);
              const  dataMap = {
                center: MapCenter.USA,
                zoom: MapZoom.GENERAL,
              };
              const formatInfo = {
                region: "en-US",
                format: {  year: 'numeric', month: '2-digit' },
                addDays: 1
              };
              this.data = {
                data: shapeTypes,
                metadata: res.metadata, 
                dates: res.dates,
                shapes: shapeTypes,
                mapInfo: dataMap,
                step: 12, // step year by year
                shapeUrl: ShapeMapDataLink.Natural_forest_us,
                formatInfo: formatInfo,
                typeMap: 'Static', 
                haveFilters: 2,
              };  
            },
            error: (e:any) => { console.error(e); this.spinner.hide(); }
          }
        );
    
    this.spinner.hide();
  }


  async handleFilterUpdate($event: any) {
    this.f1 = $event.toString();

    this.selection = { 
      filter_one: $event.toString(),
      filter_two: this.f2,
    }; 

    this.getDataUs();
  }

  async handleFilterSecondUpdate($event: any) {
    this.f2 = $event.toString();

    this.selection = { 
      filter_one: this.f1,
      filter_two: $event.toString(),
    }; 

    this.getDataUs();
  }
}

