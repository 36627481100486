import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-simulation-params',
  templateUrl: './simulation-params.component.html',
  styleUrls: ['./simulation-params.component.css']
})
export class SimulationParamsComponent {
  constructor(public dialogRef: MatDialogRef<SimulationParamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    console.log(data)
  }
}
