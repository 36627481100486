import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WildfireComponent } from 'src/app/shared/pages/wildfire/wildfire.component';
import { HurricaneComponent } from 'src/app/shared/pages/hurricane/hurricane.component';
import { FloodingComponent } from 'src/app/shared/pages/flooding/flooding.component';
import { DroughtComponent } from 'src/app/shared/pages/drought/drought.component';
import { EarthquakeComponent } from 'src/app/shared/pages/earthquake/earthquake.component';
import { NaturalGasConsumptionComponent } from 'src/app/shared/pages/natural-gas-consumption/natural-gas-consumption.component';
import { WindTurbineComponent } from '../../shared/pages/wind-turbine/wind-turbine.component';
import { MineralResourcesComponent } from '../../shared/pages/mineral-resources/mineral-resources.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Hurricane',
    component: HurricaneComponent
  },
  {
    path: 'Wildfire',
    component: WildfireComponent
  },
  {
    path: 'Flooding',
    component: FloodingComponent
  },
  {
    path: 'Drought',
    component: DroughtComponent
  },
  {
    path: 'Earthquake',
    component: EarthquakeComponent
  },
  {
    path: 'Natural-Gas',
    component: NaturalGasConsumptionComponent
  },
  {
    path: 'Natural-resources',
    component: WindTurbineComponent
  }, 
  {
    path: 'Quarrying/-Mining',
    component: MineralResourcesComponent
  },
    
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class InorganicChemicalRoutingModule { }
