import { AfterViewInit, Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { latLng } from 'leaflet';
import * as XLSX from 'xlsx';
import { DataContext, DataPart } from 'src/app/core/models/amchart.model';
import { getTime } from '@amcharts/amcharts5/.internal/core/util/Time';
@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() barId!: string;
  @Input() timeUnit!: am5.time.TimeUnit;
  @Input() data!: any;
  @Input() metadata!:any;
  @Input() customHeight!: string;
  @Input() showDownloadButton:boolean = true;
  @ViewChild('elRef', {read: ElementRef}) private elRef!: ElementRef<HTMLElement>;
  MAX_ROWS_PER_EXPORT = 50000; 
  dataParts: DataPart[] = [];
  datad: { date: string | undefined; value: number | undefined }[] = [];
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  constructor() { }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.root)
      this.initChart();

      if (changes['data']) {
        if (this.chart) {
          this.chart.dispose();
        }
        if (this.root) {
          this.root.dispose();
        }
        this.initChart();
      }
  }
  ngAfterViewInit(): void {
    this.initChart();

    const series = this.chart?.series?.getIndex(0);

if (series) {
    this.datad = series.dataItems.map(item => {
      const context = item.dataContext as DataContext;
      return {
        date: context.dateParse?.toString(),
        value: item.get("valueY")
      };
    });
  } else {
    console.error("La serie de la gráfica no está definida o no tiene datos.");
  }
    this.splitDataIntoParts();
  }
  splitDataIntoParts() {
    const totalData = this.datad.length;
    const totalParts = Math.ceil(totalData / this.MAX_ROWS_PER_EXPORT);

    for (let i = 0; i < totalParts; i++) {
      const start = i * this.MAX_ROWS_PER_EXPORT;
      const end = Math.min(start + this.MAX_ROWS_PER_EXPORT, totalData);
      this.dataParts.push({
        label: i + 1, 
        start: start + 1,  
        end: end, 
        selected: false  
      });
    }
  }
  initChart() {
    if(this.chart != null && this.chart != undefined  && this.root != null && this.root != undefined){
        this.chart!.dispose();
        this.root!.dispose();
    }

    this.root = am5.Root.new(this.elRef.nativeElement);
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX:true,
    }));

    let color = this.data.style?.color || "#67B7DC";

    var leftContainer = am5.Label.new(this.root, {
        text: this.data.yLabel,
        textAlign: 'center',
        fontSize: 16,
        maxHeight: 300,
        maxWidth: 70,
        fontWeight: 'bold',
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        rotation: 270,
        paddingBottom: 25,
        oversizedBehavior: 'wrap'
    });

    this.chart.leftAxesContainer.children.push(leftContainer);
    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
        text: this.data.title,
        textAlign: "center",
        fontSize: 16,
        fontWeight: "700",
        x: am5.p50,
        centerX: am5.p50,
        oversizedBehavior: "wrap",
    }));

    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
        behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    let adjustedTimeUnit = this.timeUnit === 'week' ? 'day' : this.timeUnit;

    let xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
        maxDeviation: 0.2,
        baseInterval: {
            timeUnit: adjustedTimeUnit,
            count: 1
        },
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {})
    }));

    xAxis.children.push(am5.Label.new(this.root, {
        text: this.data.xLabel,
        textAlign: 'center',
        x: am5.p50,
        fontSize: 14,
        fontWeight: 'bold'
    }));

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {})
    }));

    let series = this.chart.series.push(am5xy.SmoothedXLineSeries.new(this.root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color(color),
        tooltip: am5.Tooltip.new(this.root, {
            labelText: "{valueY}"
        }),
    }));
    if (this.data.style) {
        series.strokes.template.setAll({
            strokeWidth: 2,
            stroke: am5.color(color)
        });

        series.bullets.push(() => {
            var graphics = am5.Circle.new(this.root, {
                radius: 4,
                interactive: true,
                cursorOverStyle: "ns-resize",
                stroke: am5.color(color),
                fill: am5.color(color),
            });

            return am5.Bullet.new(this.root, {
                sprite: graphics,
            });
        });
    } else {
        series.fills.template.setAll({
            fillOpacity: 0.5,
            visible: true
        });

        series.bullets.push(() => {
            var graphics = am5.Circle.new(this.root, {
                radius: 4,
                interactive: true,
                cursorOverStyle: "ns-resize",
                stroke: series.get("stroke"),
                fill: am5.color(color),
            });

            return am5.Bullet.new(this.root, {
                sprite: graphics,
            });
        });
    }

    let scrollbar = this.chart.set("scrollbarX", am5xy.XYChartScrollbar.new(this.root, {
        orientation: "horizontal",
        height: 20
    }));

    this.chart.set("scrollbarX", scrollbar);
    this.chart.bottomAxesContainer.children.push(scrollbar);

    let sbDateAxis = scrollbar.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
        baseInterval: {
            timeUnit: "day",
            count: 1
        },
        renderer: am5xy.AxisRendererX.new(this.root, {})
    }));

    let sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
            renderer: am5xy.AxisRendererY.new(this.root, {})
        })
    );

    let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(this.root, {
        valueYField: "value",
        valueXField: "date",
        xAxis: sbDateAxis,
        yAxis: sbValueAxis
    }));

    sbSeries.data.setAll(this.data.data);
    this.chart.zoomOutButton.set("forceHidden", true);

    series.data.setAll(this.data.data);

    series.appear(1000);
    this.chart.appear(1000, 100);
}

  

exportAll() {
  const totalData = this.datad;
  const totalRows = totalData.length;
  if (totalRows > this.MAX_ROWS_PER_EXPORT) {
    const totalParts = Math.ceil(totalRows / this.MAX_ROWS_PER_EXPORT);
    for (let i = 0; i < totalParts; i++) {
      const start = i * this.MAX_ROWS_PER_EXPORT;
      const end = Math.min(start + this.MAX_ROWS_PER_EXPORT, totalRows);
      const dataChunk = totalData.slice(start, end); 
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataChunk);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `Part ${i + 1}`);
      XLSX.writeFile(wb, `Data_line_graph${this.data.title}_${this.metadata.dashboard}_${this.metadata.net}_${this.metadata.node}_Part_${i + 1}.xlsx`);
    }
  } else {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(totalData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `All_data`);
    XLSX.writeFile(wb, `Complete_data_line_graph${this.data.title}_${this.metadata.dashboard}_${this.metadata.net}_${this.metadata.node}_${Date()}.xlsx`);
  }
}

}

