import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
declare const L: any;
import 'leaflet';
import * as XLSX from 'xlsx';
import "leaflet-timedimension";
import { HttpClient } from '@angular/common/http';
import * as chroma from "chroma-js";
import { MapJson } from 'src/app/core/enums/map.enum';

@Component({
  selector: 'app-static-counties-map',
  templateUrl: './static-counties-map.component.html',
  styleUrls: ['./static-counties-map.component.css']
})
export class StaticCountiesMapComponent implements OnInit, OnDestroy {
  @ViewChild('map', {read: ElementRef}) private mapEl!: ElementRef<HTMLElement>;
  @Input() labelPopup: string = "";
  @Input() data!: any;
  @Input() bounds!: number;
  @Input() mapInfo!: any;
  @Input() property: string = "values";
  @Output() countySelected = new EventEmitter<any>();
  private map: any;
  private states: any;
  private counties:any;
  maxValue: any;
  private geojson!: any;
  private info !: any;
  promiseGeoJson!: Promise<any>;
  public values: any[] = [];

  constructor(private http: HttpClient) { }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.maxValue = this.data.maxCounty;
    this.initMap();
    this.getGeoJsonData();
    Promise.allSettled([this.promiseGeoJson]).then(value => {
      this.onReload();

    });
  }
  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map("mapCounty", {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 12,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: '<img class="icon-svg" src= "../../assets/images/house-solid.svg" alt="home-icon">',
        zoomHomeTitle: 'Zoom home'
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
        this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (html: any, title: any, className: any, container: any, fn: any) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      }
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }

  getGeoJsonData() {
  // Cargar el archivo de condados
  this.promiseGeoJson = new Promise((resolve, reject) => {
    this.http.get(`/assets/geojson/${this.mapInfo.json}_counties.json`).subscribe((counties: any) => {
      this.states=counties;
      this.counties = counties.features.reduce((acc: any, feature: any) => {
        acc[feature.id] = feature.properties.NAME;  // Mapea el id del condado al nombre
        return acc;
      }, {});
      resolve(this.counties);
    }, error => {
      reject(error);
    });
  });
}
  onReload() {
    this.createNewItems();
    this.initStatesLayer();
  }
  private createNewItems() {
    this.data.data.forEach((state: any) => {
      let itemIndex = -1
      if(this.mapInfo.json === MapJson.USA){
        itemIndex = this.states.features.findIndex((item: any) => item.id == state.name);
      }else if(this.mapInfo.json === MapJson.MEXICO){
        itemIndex = this.states.features.findIndex((item: any) => item.properties.state_code+""+item.properties.mun_code == state.name);
      }
      if (itemIndex == -1) return;
      const copyItem = JSON.parse(JSON.stringify(this.states.features.at(itemIndex))); //deep copy for the item
      this.states.features.splice(itemIndex, 1); //remove original item.
      copyItem.properties.times = [];
      copyItem.properties.values = [];
      copyItem.properties.times.push(... this.data.dates);
      copyItem.properties.valuesString = state[this.property].map((val: number) => { return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})});
      copyItem.properties.values.push(...state[this.property]);
      copyItem.properties.id = copyItem.id;
      this.states.features.push(copyItem);

    });
    for (var i = 0; i < this.states.features.length; i++) {
      if (this.states.features[i].properties.values === undefined) {
        this.states.features.splice(i, 1); //remove original item.
        i--;
      }
    }
    let min = Math.min( ... this.data.data.map((item: any) => { return Math.min(... item[this.property].filter((x: any) => x !== 0))}) );
    let max = Math.max( ... this.data.data.map((item: any) => { return Math.max(... item[this.property].filter((x: any) => x !== 0))}) );

    min = Math.round(min);
    max = Math.round(max);

    while(max % 10 !== 0){
      max++;
    }

    while(min % 10 !== 0){
      min--;
    }

    const length = this.data.data.at(0)[this.property].length;
    for(var i = 0; i < length; i++){
      const values_ = [...new Set(this.data.data.map((item: any) => { return item[this.property].at(i) }))].sort((a:any, b: any) => {
        return a - b;
      });
      values_.unshift(min);
      if(values_.at(-1) !== max){
        values_.pop();
        values_.push(max);
      }
      this.values.push(values_);
    }
  }
  private initStatesLayer() {

    this.geojson = L.geoJSON(this.states, { style: this.style, onEachFeature: this.onEachFeature });


    this.geojson.addTo(this.map);


    this.info = L.control({ position: 'topleft' });

    this.info.onAdd = function (map: any) {
      this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    this.info.update = function (props: any, map: any, mouseout: boolean) {
      let text = '';
      if (props === undefined) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return
      };
      if (mouseout) {
        text += 'Hover over a county';
        this._div.innerHTML = text;
        return;
      }
      if (props.values === undefined) {
        text += `<app-not-found></app-not-found>`;
      } else {
        text += '<b>' + props.NAME + '</b><br />';
        text += '<b>' + props.valuesString[0] + '</b><br />';
      }
      this._div.innerHTML = text;
    };

    var legend = L.control({position: 'bottomright'});

    legend.onAdd =  (map:any) =>{

        var div = L.DomUtil.create('div', 'info legend')
        const values_ = this.values[0].filter((x: any) => x !== 0).reverse();
        // loop through our density intervals and generate a label with a colored square for each interval
        for (var i = 0; i < values_.length; i++) {
            if(i == 0 || i == (values_.length-1)){
              div.innerHTML +=
              '<span style="height: 1.5px;"><i style="background:' + this.getColor(values_[i]) + ';height: 1.5px;"></i> ' +
              values_[i] +'</span>';
            }else{
              div.innerHTML +=
              '<span style="height: 1.5px;"><i style="background:' + this.getColor(values_[i]) + ';height: 1.5px;"></i> '+'</span>';
            }

        }

        return div;
    };

    legend.addTo(this.map);

    this.info.addTo(this.map);
    this.map.fitBounds(this.bounds);
  }

  private style = (feature: any) => {
    let color = 'rgba(255,255,255,1.0)';
    if (feature!.properties!.values !== undefined) {
      color = this.getColor(feature!.properties!.values[0]);
    }
    return {
      weight: 1,
      opacity: 1,
      color: this.mapInfo.borderColor,
      dashArray: '',
      fillOpacity: 1,
      fillColor: color
    };
  }
  private getColor = (d: any) => {
    var mapScale = chroma.scale(this.mapInfo.scale).domain([this.maxValue, 0]);
    return mapScale(d).toString();
  }
  private zoomToFeature = (e: any) => {
    L.DomEvent.stopPropagation;
  }
  private highLightFeature = (e: any) => {
    var layer = e.target;

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
    this.info.update(e.target.feature.properties, this.map, false);
    layer.bringToFront();
  }
  private resetFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 1,
      color: this.mapInfo.borderColor,
      fillOpacity: 1,
    });
    this.info.update(e.target.feature.properties, this.map, true);
    layer.bringToFront();
  }
  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
      click: this.zoomToFeature
    });
    const total = feature.properties.valuesString === undefined ? 'N/A' : feature.properties.valuesString[0];
    layer.bindTooltip(`<p style="font-weight: 700; text-align: center;">${feature.properties.NAME}</p> ${this.labelPopup}:  ${total}`).openTooltip();
    layer.on('click', (e: any) => {
      this.getCounty(feature);
    });

  }
  public getCounty(feature: any) {
    if (feature.properties.values === undefined) return;
    const model = { name: feature.properties.NAME, id: feature.properties.id };
    this.countySelected.emit(model);
  }

  exportToExcel() {
    const MAX_ROWS_PER_EXPORT = 50000; 
    const countyData = this.data.data.map((county: { name: string | number; state: string | number; values: any[]; }) => ({
      County: this.counties[county.name] || county.name, 
      Value: county.values[0]  
    }));
    const splitDataIntoChunks = (data: any[], chunkSize: number) => {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      return chunks;
    };
    const countyChunks = splitDataIntoChunks(countyData, MAX_ROWS_PER_EXPORT);
    countyChunks.forEach((chunk, index) => {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const wsCounties: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk);
      XLSX.utils.book_append_sheet(wb, wsCounties, 'Counties');
  
      const fileName = countyChunks.length === 1
      ? `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_${Date()}.xlsx`
      : `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_Part${index + 1}_${Date()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    });
  }
}
