import { Injectable } from '@angular/core';
import { PopulationUsCountyModel, PopulationUsStateModel } from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DefaultBoxPlotGraphModel, DefaultGraphModel } from 'src/app/core/models/chartjs.model';
import { HousingPriceFuncModel, HousingPriceModel } from 'src/app/core/models/sawmillsProduction/housingPrice/housingPriceModel';

@Injectable({
  providedIn: 'root'
})
export class DynamicTemplateService {

  constructor() { }

  getBoxplotByState(dataMapCopy: any, title: string, format:any, property="values"): DefaultBoxPlotGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsStateModel;
    const boxplot: DefaultBoxPlotGraphModel = {
      labels: this.getFormatDate(dataCopy.dates, format),
      datasets: [{
        label: title,
        outLierColor: '#999999',
        meanBackgroundColor: '#461e7d',
        data: [],
        padding: 0,
        itemRadiius: 0,
        backgroundColor: 'rgba(255, 26, 104, 0.2)',
        borderColor: 'rgba(255, 26, 104, 1)',
        borderWidth: 1,
        type: 'boxplot'
      }, {
        label: 'Mean',
        data: [],
        backgroundColor: '#461e7d',
        borderColor: '#461e7d',
        borderWidth: 2,
        type: 'line'
      }]
    };
    const Means: number[] = [];
    dataCopy.dates.forEach((date: string, i: number) => {
      let values: number[] = []
      
      dataCopy.data_States.forEach((value: any) => {
        values.push(value[property][i]);
      });
      values = values.filter(x => x !== 0);
      const sum = values.reduce((partialSum, a) => partialSum + a, 0);
      Means.push((sum / values.length));
      boxplot.datasets[0].data.push(values);
    });
    boxplot.datasets[1].data.push(...Means);
    return boxplot;
  }


  getHistogramByState(dataMapCopy: any, valueYear:any, title: string, property="values"):any {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsStateModel;
    const histogram_: any[] = []

    const index = dataCopy.dates.findIndex(x => x === valueYear.toString());
    
    const values: number[] = []
    dataCopy.data_States.forEach((value: any) => {
      values.push(Math.trunc(value[property][index]));
    });

    const labels = [...new Set(values)].sort( (a, b) =>{
      return a - b;
    }).filter(x => x !== 0);
    const labelsString = labels.map((value: number) => { return value.toString() });

    labelsString.forEach((label: string) => {
      histogram_.push(... values.filter(x => x === parseFloat(label)).map(x => { return {value: x}}));
    });
    return histogram_;
  }
  getFormatFromDate(date: string, format: any): string{
    // const date_ = new Date(date);
    // date_.setDate(date_.getDate() + format.addDays);
    // return date_.toLocaleDateString(format.region, format.format);
    return date;
  }
  getFormatDate(dates: string[], format: any): string[]{
    return dates.map((value: string) => {
        const date = new Date(value);
        date.setDate(date.getDate() + format.addDays);
        return date.toLocaleDateString(format.region, format.format);
    })
  }
  getBoxplot(dataMapCopy: any, title: string, format:any, property="values"): DefaultBoxPlotGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const boxplot: DefaultBoxPlotGraphModel = {
      labels: this.getFormatDate(dataCopy.dates, format),
      datasets: [{
        label: title,
        outLierColor: '#999999',
        meanBackgroundColor: '#461e7d',
        data: [],
        padding: 0,
        itemRadiius: 0,
        backgroundColor: 'rgba(255, 26, 104, 0.2)',
        borderColor: 'rgba(255, 26, 104, 1)',
        borderWidth: 1,
        type: 'boxplot'
      }, {
        label: 'Mean',
        data: [],
        backgroundColor: '#461e7d',
        borderColor: '#461e7d',
        borderWidth: 2,
        type: 'line'
      }]
    };
    const Means: number[] = [];
    dataCopy.dates.forEach((date: string, i: number) => {
      let values: number[] = []
      
      dataCopy.data.forEach((value: any) => {
        values.push(value[property][i]);
      });
      values = values.filter(x => x !== 0);
      const sum = values.reduce((partialSum, a) => partialSum + a, 0);
      Means.push((sum / values.length));
      boxplot.datasets[0].data.push(values);
    });
    boxplot.datasets[1].data.push(...Means);
    return boxplot;
  }


  getHistogram(dataMapCopy: any, valueYear:any, title: string, property="values"):any {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const histogram_: any[] = [];
    const index = dataCopy.dates.findIndex(x => x === valueYear.toString());
    const values: number[] = []
    dataCopy.data.forEach((value: any) => {
      values.push(Math.trunc(value[property][index]));
    });
    const labels = [...new Set(values)].sort( (a, b) =>{
      return a - b;
    }).filter(x => x !== 0);
    const labelsString = labels.map((value: number) => { return value.toString() });
    labelsString.forEach((label: string) => {
      histogram_.push(... values.filter(x => x === parseFloat(label)).map(x => { return {value: x}}));
    });
    return histogram_;
  }


  getECDFGeneral(dataMapCopy: any, valueYear: any, format: any, property = "values"): DefaultGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: `ECDF (${this.getFormatFromDate(valueYear, format)})`,
        data: [],
        backgroundColor: 'rgba(255, 26, 104, 0.8)',
        borderColor: 'rgba(255, 26, 104, 1)',
        stepped: true,
      }]
    };
    const index = dataCopy.dates.findIndex(x => x === valueYear.toString());
    let values: number[] = [];
    if (index === -1) {
      console.error("Year not found");
      return ECDF; 
    }
    dataCopy.data.forEach((value: any) => {
      const val = value[property][index];
      if (val !== undefined && val !== null && !isNaN(val) && val !== 0) {
        values.push(val);
      }
    });
    values = values.sort((a, b) => a - b);
    const one_vec = values.map(() => 1);
    const eq_prob = one_vec.map(() => 1 / values.length);
    const labels = values.map(value => value.toString());
    ECDF.labels = labels;
    const data_: number[] = [];
    let init_prob = 0;
    eq_prob.forEach((prob: number) => {
      init_prob += prob;
      data_.push(init_prob);
    });
    data_.pop();
    data_.push(1);
    ECDF.datasets[0].data = data_;
    return ECDF;
  }
  getECDFByState(dataMapCopy: any, valueYear:any, format: any, property="values"): DefaultGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsStateModel;
    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: `ECDF (${this.getFormatFromDate(valueYear, format)})`,
        data: [],
        backgroundColor: 'rgba(255, 26, 104, 0.8)',
        borderColor: 'rgba(255, 26, 104, 1)',
        stepped: true,
      }]
    };
    const index = dataCopy.dates.findIndex(x => x === valueYear.toString());
    let values: number[] = []
    dataCopy.data_States.forEach((value: any) => {
      if (value[property][index] === 0) return;
      values.push(value[property][index]);
    });
    values = values.sort((a, b) => {
      return a - b;
    });
    const one_vec = values.map((value) => { return 1; });
    const eq_prob = one_vec.map((value) => {
      return value / values.length;
    })
    const labels = values.filter((value) => value !== undefined).sort((a, b) => a - b);

    ECDF.labels = labels.map((value: number) => { return value.toString() });
    const data_: number[] = [];
    let init_prob = 0;
    eq_prob.forEach((value: number, i: number) => {
      init_prob += value;
      data_.push(init_prob);
    });
    data_.pop();
    data_.push(1);
    ECDF.datasets[0].data = data_;
    return ECDF;
  }
  getECDFByCounty(dataMapCopy: any, selectedCounty: any, title: string, selectedState: any, property = "values"): DefaultGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const ECDF: DefaultGraphModel = {
      datasets: [{
        label: title,
        data: [],
        backgroundColor: 'rgba(255, 26, 104, 0.8)',
        borderColor: 'rgba(255, 26, 104, 1)',
        stepped: true,
      }]
    };
    const dataCopyFiltered = dataCopy.data.filter((x: any) => x.name === selectedCounty.id && x.state === selectedState.id);
    if (dataCopyFiltered.length === 0 || !dataCopyFiltered[0] || !dataCopyFiltered[0][property]) {
      console.error("There's no data for that selection");
      return ECDF;
    }
    let values: number[] = [];
    dataCopy.dates.forEach((date: string, i: number) => {
      const value = dataCopyFiltered[0][property][i];
      if (value !== undefined && value !== null && !isNaN(value) && value !== 0) {
        values.push(value);
      }
    });
    values = values.sort((a, b) => a - b);
    const one_vec = values.map(() => 1);
    const eq_prob = one_vec.map(() => 1 / values.length);
    ECDF.labels = values.map(value => value.toString());
    const data_: number[] = [];
    let init_prob = 0;
    eq_prob.forEach((prob: number) => {
      init_prob += prob;
      data_.push(init_prob);
    });
    data_.pop();
    data_.push(1);
    ECDF.datasets[0].data = data_;
    return ECDF;
  }
  

  getHistogramByCounty(dataMapCopy: any, selectedCounty: any, title: string, selectedState: any): any {
    let dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const histogram_: any[] = []
    const dataCopyFiltered = dataCopy.data.filter((x: any) => x.name === selectedCounty.id && x.state === selectedState.id);
    const values: number[] = [];
    dataCopy.dates.forEach((date: string, i: number) => {
      dataCopyFiltered.forEach((value: any) => {
        values.push(Math.trunc(value.values[i]));
      });
    });
    
    const labels = [...new Set(values)].sort((a, b) => {
      return a - b;
    }).filter(x => x !== 0);
    const labelsString = labels.map((value: number) => { return value.toString() });

    labelsString.forEach((label: string) => {
      histogram_.push(... values.filter(x => x === parseFloat(label)).map(x => { return {value: x}}));
    });
    return histogram_;
  }


  getXYByCounty(dataMapCopy: any, selectedCounty: any, title: string, selectedState: any): any {
    let dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    let dataCopyFiltered = dataCopy.data.filter((x: any) => x.name === selectedCounty.id && x.state === selectedState.id);
    const values: number[] = [];
    dataCopy.dates.forEach((date: string, i: number) => {
      dataCopyFiltered.forEach((value: any) => {
        values.push(Math.trunc(value.values[i]));
      });
    });
    
    const labels = [...new Set(dataCopy.dates)];

    const data_: any[] = [];
    labels.forEach((label: string, i: number) => {
      const value = values.at(i);
      const date = new Date(label);
      const dateParse = label;
      data_.push({date: date.getTime(), value: value, dateParse: dateParse});
    });

    return data_;
  }

}
