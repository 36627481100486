import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-reservoirs',
  templateUrl: './reservoirs.component.html',
  styleUrls: ['./reservoirs.component.css']
})
export class ReservoirsComponent {
    public data: any;
    public isUSA: boolean = false;
    public isCN: boolean = false;
    public isMx: boolean = false;
    public isTri: boolean = false;
    private filterInfo!: any;
    public select!:any;
    public hasStudyButton!:any;
    @Input() filter: any=undefined;
    @Input() numvariable!:any;
    @Output() selectedItem = new EventEmitter<number>();
    public f1 = '';
  
    public selection: any = { 
      filter_one: this.f1,
      // filter_two: this.f2,
    };
    filterInfoValues!: any;
    
  
    dataMap = {
      center: MapCenter.USA,
      scale: ['#CC9966', '#996633', '#999999'].reverse(), // Tonos de café/gris
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#666666', // Borde gris
      color: '#663300', // Color de las áreas café
      fillColor: '#CCCCCC' // Relleno gris claro
    };
    formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 2
    };
  
    constructor(private naturalService: NaturalService, 
      private homeStore: HomeStore, private spinner: NgxSpinnerService) {
      if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
      else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
      else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
      else this.isTri = true;
    }
  
    ngOnInit(): void {
      this.spinner.show();
  
      setTimeout(async () => {
        const filters = await firstValueFrom(
          this.naturalService.getFiltersReservoirs()
        );
    
        this.filterInfoValues = {
          firstFilter: filters.filter_OneData
        };
  
        if (this.isCN) {
          this.getDataCN();
        } else if (this.isUSA) {
          this.getDataUs(this.selection);
        } else if (this.isMx) {
          this.getDataMx();
        }else{
          this.getDataTri();
        }
        if(this.filter!=undefined){
          let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
         this.select = result ? result.itemId : null;
          this.filterInfo=this.filter;
          this.hasStudyButton=true;
        
        }
  
      }, 300); 
  
    }
  
    handleSelectItem(data: any) {
      this.selectedItem.emit(data);
    }
  
    getDataCN() {
      this.spinner.hide();
    } 
  
    getDataTri() {
      this.spinner.hide();
    }
  
    getDataMx() {
      this.spinner.hide();
    }
  
    getDataUs(selection: any) {
      this.naturalService.getReservoirs(selection)
      .subscribe({
        next: (res) => {
          this.data = {
            data: res,
            formatInfo: this.formatInfo,
            metadata: res.metadata, 
            mapInfo: this.dataMap,
            filterInfo:this.filterInfo,
            selectedInfo:this.select,
            hasStudyButton:this.hasStudyButton,
          };
  
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      });  
      
    }
  
    async handleFilterUpdate($event: any) {
      this.f1 = $event.toString();
  
      this.selection = { 
        filter_one: $event.toString(),
        //filter_two: this.f2,
      }; 
  
      this.getDataUs(this.selection);
    }
  
    // async handleFilterSecondUpdate($event: any) {
    //   this.f2 = $event.toString();
  
    //   this.selection = { 
    //     filter_one: this.f1,
    //     filter_two: $event.toString(),
    //   }; 
  
    //   this.getDataUs(this.selection);
    // }
  
  }
