<div class="row">
  <div class="col-sm-12">
    <mat-card>
      <mat-card-content *ngIf="linearRegressionSurfaceMap">
        <app-plotly [id]="'f02ca22f-2d0e-4d64-9b7f-a2f9ee5164d1'"
                    [config]="linearRegressionSurfaceMap">
        </app-plotly>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-12">
    <mat-card >
      <mat-card-content>
        <app-plotly [id]="'f02ca22f-2d0e-4d64-9b7f-a2f9ee5164d2'"
                    [config]="linearRegressionResidualMap">
        </app-plotly>
        <!--app-time-control [timeInterval]="'2015-09-01/2015-09-03'"
                          [currentTime]="'2015-09-01'"
                          [period]="'P1D'"
                          (timeUpdate)="timeUpdate($event)">
        </app-time-control-->
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-12">
    <mat-card>
      <mat-card-content>
        <app-plotly [id]="'f02ca22f-2d0e-4d64-9b7f-a2f9ee5164d3'"
                    [config]="polynomialSmoothingSurfaceMap">
        </app-plotly>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-12">
    <mat-card>
      <mat-card-content >
        <mat-form-field>
          <mat-label>Degrees</mat-label>
          <!--mat-select [formControl]="degrees" *ngIf="final_dict!=null && final_dict.polynomial_smoothing!=null">
            <mat-option *ngFor="let value of final_dict.polynomial_smoothing[4]; let i = index"
                        [value]="i">{{i + 1}}
            </mat-option>
          </mat-select-->
          <mat-select [formControl]="degrees" (selectionChange)="select($event.value)" >
            <mat-option *ngFor="let value of degreesList"
                        [value]="value">{{value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-plotly [id]="'f02ca22f-2d0e-4d64-9b7f-a2f9ee5164d4'"
                    [config]="polynomialSmoothingResidualMap">
        </app-plotly>
      </mat-card-content>
    </mat-card>
  </div>


    <!-- Modal open if the 'showModal' variable is true -->
    <div *ngIf="showModal" class="modal" id="creative" #modal tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content creative-modal">
          <div class="modal-body">
            <p class="modal-text">There's no data for this variable.</p>
            <button type="button" id="closeButton" (click)="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
</div>
