import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, delay, firstValueFrom } from 'rxjs';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-risk-scenarios-template',
  templateUrl: './risk-scenarios-template.component.html',
  styleUrls: ['./risk-scenarios-template.component.css']
})
export class RiskScenariosTemplateComponent {

  private subscriber: Subscription;

  constructor(
    private generalservice: GeneralService,
    public homeStore: HomeStore,
    private spinner: NgxSpinnerService
  ) {
    this.subscriber = homeStore.appContextObservable.subscribe(async (ctx) => {
    
    });

  }

  scrollTo(event: MouseEvent, el: HTMLElement) {
    event.preventDefault()
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
