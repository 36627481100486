export const environment = {
  production: false,
  //BaseUrl: window.location.origin, //testing server
  //BaseUrl: 'https://localhost:44368', //development server for 
  //BaseUrl: 'http://localhost:57678', //docker
  BaseUrl: 'https://riskdatalake.engr.tamu.edu', //docker prod
  Api: '/api/',
  BaseAssets: 'https://tamubucketr18.s3.amazonaws.com/riskplatformv1.0/0_images',
  qa: true
};
