<mat-card>
    <mat-card-content>
      <div class="two-filter-container">
          <mat-form-field>
              <mat-label>Select year</mat-label>
              <mat-select [formControl]="firstFilter">
                <mat-option *ngFor="let item of this.info1" [value]="item.id">{{item.value}}</mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>Select hurricane(s)</mat-label>
              <mat-select [formControl]="secondFilter" multiple (closed)="pushModel()">
                <mat-option  (click)="toggleAll()" [value]="0">Select all</mat-option>
                <mat-option *ngFor="let item of this.info2" [value]="item.id">{{item.value}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>