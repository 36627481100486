import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class ManufacturingService {

  constructor(private apiService: ApiServiceService) { }

  getBroadbandMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.manufacturing}/${ApiRouting.broadband}_mx`);
  }
  getPaperGrossProductionMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.manufacturing}/${ApiRouting.gross_production}_mx`);
  }

  getAviabilityInternetMx(model: any): Observable<any> {
    return this.apiService.post(`${ApiRouting.system}/${ApiRouting.aviabilityInternetService}_mx`, model);
  }
}
