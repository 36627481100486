import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { environment } from 'src/environments/environment';
declare const L: any; 
import 'leaflet';

@Component({
  selector: 'app-hurricane-trinational',
  templateUrl: './hurricane-trinational.component.html',
  styleUrls: ['./hurricane-trinational.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HurricaneTrinationalComponent implements OnInit,OnDestroy, AfterViewInit{
  @Input() data!: any;

  private map: any;
  private geojson: any;
  private layer!: any;

  public returnToOriginalLayer: boolean = false;
  constructor(private naturalService: NaturalService, private spinner: NgxSpinnerService){}
  ngAfterViewInit(): void {
    this.initMap();
  }
  ngOnInit(): void {
    //this.initMap();
  }
  ngOnDestroy(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
  }
  getDataFromResponse(model:any):void{
    this.spinner.show();
    this.naturalService.getHurricaneTrinationalData(model)
    .subscribe(
      {
        next: (res) => {
          this.geojson = res;
          this.geojson.features.forEach((feature: any) => {
            feature.properties.color = this.stringToColour(feature.properties.name);
          });
          this.setDateToMap();
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
  }
  stringToColour(str: string) {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
    }
    return colour
  }
  private initMap(): void {
    this.map = L.map('mapHurricaneTrinational', {
      center: this.data.mapInfo.center,
      zoom: this.data.mapInfo.zoom,
      zoomControl: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 12,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: () => {
          return '<img class="icon-svg" src="' + environment.BaseAssets + '/house-solid.svg" alt="home-icon">';
        },
        zoomHomeTitle: 'Zoom home'
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
        this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.data.mapInfo.center, this.data.mapInfo.zoom);
      },

      _createButton: function (html: any, title: any, className: any, container: any, fn: any) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      }
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  public setDateToMap(){
    this.removeLayer();
    this.addLayer(this.geojson); 
    this.returnToOriginalLayer = false;
  }
  private addLayer(geojson: any, useIntensity: boolean = false){
    this.layer =  L.geoJSON(geojson, {
      style: (feature: any) => {
        let weight = feature.properties.intensity/10;
        if(Number.isNaN(weight)) weight = 3.3;
        return {
          radius: useIntensity === true ? weight : 2.8,
          fillColor: feature.properties.color,
          color: feature.properties.color,
          weight: useIntensity === true ? weight : 2.8,
          opacity: 1,
          fillOpacity: 0.8
        }
      },
      pointToLayer: (feature:any, latLng:any) =>{
        let weight = feature.properties.intensity/10;
        if(Number.isNaN(weight)) weight = 3.3;
        const geoPointMarketOption = {
          radius: useIntensity === true ? weight : 3.3,
          fillColor: feature.properties.color,
          color: feature.properties.color,
          weight: useIntensity === true ? weight : 3.3,
          opacity: 1,
          fillOpacity: 0.8
        };
        return L.circleMarker(latLng, geoPointMarketOption);
      },
      onEachFeature: this.onEachFeature
    }).addTo(this.map); 
  }
  private removeLayer(){
    if(this.layer !== undefined) this.map.removeLayer(this.layer);
  }

  private clickFeature = (e: any) => {
    const name = e.target.feature.properties.name;
    const geojsonCopy = JSON.parse(JSON.stringify(this.geojson));
    const geojsonFiltered = { type: "FeatureCollection", features: []};
    geojsonFiltered.features = geojsonCopy.features.filter((feature: any) => feature.properties.name === name);
    this.removeLayer();
    this.addLayer(geojsonFiltered, true);
    this.returnToOriginalLayer = true;
  }
  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      click: this.clickFeature
    });
    let text = `<p style="font-weight: 700; text-align: center;">${feature.properties.name}</p>`;
    if(feature.properties.intensity !== undefined){
      text += `<p>Intensity:  ${feature.properties.intensity}</p>`;
    }
    layer.bindTooltip(text).openTooltip();
  }

}
