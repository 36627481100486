import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { Covid19Component } from 'src/app/shared/pages/covid19/covid19.component';
import { WildfireComponent } from 'src/app/shared/pages/wildfire/wildfire.component';
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },{
    path: 'Fire',
    component: WildfireComponent
  },
  {
    path: 'COVID-19',
    component: Covid19Component
  },{
    path: 'Cyberattacks',
    component: GeneralNodeComponent//CyberattacksComponent
  },
  {
    path: 'Electricity',
    component: GeneralNodeComponent
  },
  {
    path: 'Wastewater-Infrastructure',
    component: GeneralNodeComponent
  },
  {
    path: 'Public-Transportation',
    component: GeneralNodeComponent
  },
  
];
@NgModule({
    declarations: [],
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
export class MedicalDeviceRoutingModule { }
