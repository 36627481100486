import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private apiService: ApiServiceService) { }

  getTypeVariable(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_type_var`, model);
  }
  getDataTimeSeries(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_timeseries`, model);
  }
  getDataSpatial(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial`, model);
  }
  getDataSpatialTemporal(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial_temporal`, model);
  }
  getDataSpatialTemporalDate(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial_temporal_dates`, model);
  }
  getDataSpatialTemporalToTimeSeriesMVM(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial_temporal_to_time_series_MVM`, model);
  }
  getDataTimeSeriesMVM(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_timeseries_MVM`, model);
  }
  getDataSpatialMVM(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial_MVM`, model);
  }
  getDataSpatialTemporalMVM(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_spatial_temporal_MVM`, model);
  }
  getDataBarChart(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_data_barchart`, model);
  }
  getDefaultTemplate(model: any): Observable<any> {
    return this.apiService.post(`restructuring/default_template`, model);
  }
  getFilters(model: any): Observable<any> {
    return this.apiService.post(`restructuring/get_filters`, model);
  }
  getGraphItems(model: any): Observable<any> { 
    return this.apiService.post(`restructuring/get_graph_items`, model);
  }
  getGraphItemsByFilterVariableType(model: any): Observable<any> { 
    return this.apiService.post(`restructuring/get_graph_items_by_variable_type`, model);
  }
  getDataForStory(model: any): Observable<any> { 
    return this.apiService.post(`restructuring/get_data_story`, model);
  }
  getGraphItemsMVM(model: any): Observable<any>{
    return this.apiService.postMVM(`restructuring/get_graph_items_mvm`, model);
  }

// ********************* RISK SCENARIOS TAB ********************* //
  getScenarios(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_scenarios`, model);
  }
  getFiltersDataSourceRS(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_filters_data_source`, model);
  }
  getDataSource(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_data_source`, model);
  }
  getDataHeatmapByScenario(IdScenario: any): Observable<any>{
    return this.apiService.get(`RiskScenarios/get_heatmap_by_Scenario?IdScenario=${IdScenario}`);
  }
  getPcaBiplotByScenario(IdScenario: any): Observable<any> {
    return this.apiService.get(`RiskScenarios/get_PcaBiplot_by_Scenario?IdScenario=${IdScenario}`);
  }
  getFiltersRiskScenarioRS(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_filters_risk_scenario`, model);
  }
  getRiskScenarioPlot(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_risk_scenario_plot`, model);
  }
  getFiltersRiskScenarioEcdf(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_risk_scenario_filter`, model);
  }
  getRiskScenarioEcdfCommun(model: any): Observable<any>{
    return this.apiService.post(`RiskScenarios/get_risk_scenario_ecdf_commun`, model);
  }
  GetProbabilisticCalibrationLinearModelCommun(IdScenario: any,Select:any): Observable<any>{
    return this.apiService.get(`RiskScenarios/get_risk_scenario_probabilisticCalibrationLinearModelCommun?Selection=${Select}&IdScenario=${IdScenario}`);
  }
  GetLinearModelRegression(IdScenario: any,Select:any): Observable<any>{
    return this.apiService.get(`RiskScenarios/get_risk_scenario_linearmodelregression?Selection=${Select}&IdScenario=${IdScenario}`);
  }
}
