import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphComponent } from './components/echarts/graph/graph.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarChartHorizontalComponent } from './components/amchart/bar-chart-horizontal/bar-chart-horizontal.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { BarChartVerticalComponent } from './components/amchart/bar-chart-vertical/bar-chart-vertical.component';
import { LineChartComponent } from './components/chartjs/line-chart/line-chart.component';
import { DynamicMapComponent } from './components/leaflet/dynamic-counties-map/dynamic-counties-map.component';
import { DynamicStatesMapComponent } from './components/leaflet/dynamic-states-map/dynamic-states-map.component';
import { BoxplotComponent } from './components/chartjs/boxplot/boxplot.component';
import { HistogramComponent } from './components/chartjs/histogram/histogram.component';
import { StaticStatesMapComponent } from './components/leaflet/static-states-map/static-states-map.component';
import { StaticCountiesMapComponent } from './components/leaflet/static-counties-map/static-counties-map.component';
import { StaticTemplateComponent } from './templates/static-template/static-template.component';
import { DroughtComponent } from './pages/drought/drought.component';
import { DynamicShapeMapComponent } from './components/leaflet/dynamic-shape-map/dynamic-shape-map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReturnHomeComponent } from './components/return-home/return-home.component';
import { DynamicTemplateComponent } from './templates/dynamic-template/dynamic-template.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EarthquakeComponent } from './pages/earthquake/earthquake.component';
import { FloodingComponent } from './pages/flooding/flooding.component';
import { HurricaneComponent } from './pages/hurricane/hurricane.component';
import { WildfireComponent } from './pages/wildfire/wildfire.component';
import { Covid19Component } from './pages/covid19/covid19.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { AccumulatedComponent } from './components/amchart/accumulated/accumulated.component';
import { StackedComponent } from './components/amchart/stacked/stacked.component';
import { GaugeComponent } from './components/amchart/gauge/gauge.component';
import { LineComponent } from './components/amchart/line/line.component';
import { NaturalGasConsumptionComponent } from './pages/natural-gas-consumption/natural-gas-consumption.component';
import {
  TimeVisualizationTemplateComponent
} from './templates/time-visualization-template/time-visualization-template.component';
import { StepComponent } from './components/amchart/step/step.component';
import { TwoSingleFiltersComponent } from './components/filters/two-single-filters/two-single-filters.component';
import { ECDFComponent } from './components/chartjs/ecdf/ecdf.component';
import { HistogramD3Component } from './components/d3/histogram/histogram.component';
import { OneSingleFilterComponent } from './components/filters/one-single-filter/one-single-filter.component';
import {
  DynamicShapefilesTemplateComponent
} from './templates/dynamic-shapefiles-template/dynamic-shapefiles-template.component';
import { ImageTemplateComponent } from './templates/image-template/image-template.component';
import { SelectStudyComponent } from './components/dialogs/select-study/select-study.component';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DynamicHeatmapTemplateComponent
} from './templates/dynamic-heatmap-template/dynamic-heatmap-template.component';
import { DynamicHeatmapComponent } from './components/leaflet/dynamic-heatmap/dynamic-heatmap.component';
import { DynamicPointMapComponent } from './components/leaflet/dynamic-point-map/dynamic-point-map.component';
import { DynamicPointTemplateComponent } from './templates/dynamic-point-template/dynamic-point-template.component';
import { WindTurbineComponent } from './pages/wind-turbine/wind-turbine.component';
import { LineCategoryComponent } from './components/amchart/line-category/line-category.component';
import {
  TimeCategoryVisualizationTemplateComponent
} from './templates/time-category-visualization-template/time-category-visualization-template.component';
import { DynamicGeotiffMapComponent } from './components/leaflet/dynamic-geotiff-map/dynamic-geotiff-map.component';
import {
  DynamicGeotiffTemplateComponent
} from './templates/dynamic-geotiff-template/dynamic-geotiff-template.component';
import { SurfaceAirTemperatureComponent } from './pages/surface-air-temperature/surface-air-temperature.component';
import { HurricaneTrinationalComponent } from './pages/hurricane-trinational/hurricane-trinational.component';
import {
  OneSingleOneMultiFiltersComponent
} from './components/filters/one-single-one-multi-filters/one-single-one-multi-filters.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PrecipitationComponent } from './pages/precipitation/precipitation.component';
import { MineralResourcesComponent } from './pages/mineral-resources/mineral-resources.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TrendModelComponent } from './components/forms/trend-model/trend-model.component';
import {
  AutocorrelationModelComponent
} from './components/forms/autocorrelation-model/autocorrelation-model.component';
import { SeasonalModelComponent } from './components/forms/seasonal-model/seasonal-model.component';
import { CategoryBarTemplateComponent } from './templates/category-bar-template/category-bar-template.component';
import { BroadbandComponent } from './pages/broadband/broadband.component';
import { ThreeSingleFiltersComponent } from './components/filters/three-single-filters/three-single-filters.component';
import { TreeComponent } from './components/material/tree/tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GeneralNodeComponent } from './pages/general-node/general-node.component';
import { FourSingleFilterComponent } from './components/filters/four-single-filter/four-single-filter.component';
import { FiveSingleFilterComponent } from './components/filters/five-single-filter/five-single-filter.component';
import { SimulationParamsComponent } from './components/simulation-params/simulation-params.component';
import { MatSliderModule } from '@angular/material/slider';
import { StoryComponent } from './components/story/story.component';
import { EchartDirective } from './components/echart.directive';
import { PlotlyComponent } from './components/plotly/plotly.component';
import {
  ResidualAnalysisComponent
} from './components/plotly/residualAnalysis/residualAnalysis.component';
import { SecondOrderComponent } from './components/forms/second-order/second-order.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TimeControlComponent } from './components/timeControl/timeControl.component';
import { YAxisFilterComponent } from './components/filters/y-axis-filter/y-axis-filter.component';
import { SixSingleFilterComponent } from './components/filters/six-single-filter/six-single-filter.component';
import { ChartJsDirective } from './components/chartjs.directive';
import { SolarComponent } from './pages/solar/solar.component';
import { Co2emissionsComponent } from './pages/co2emissions/co2emissions.component';
import { ModalFiltersComponent } from './components/echarts/modal-filters/modal-filters.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { SoilGridsComponent } from './pages/soil-grids/soil-grids.component';
import { ForestCharacteristicComponent } from './pages/forest-characteristic/forest-characteristic.component';
import { BridgesInfrastructureComponent } from './pages/bridges-infrastructure/bridges-infrastructure.component';
import { TrucksComponent } from './pages/trucks/trucks.component';
import { RoadwaysInfrastructureComponent } from './pages/roadways-infrastructure/roadways-infrastructure.component';
import { InlandWaterwaysInfrastructureComponent } from './pages/inland-waterways-infrastructure/inland-waterways-infrastructure.component';
import { NoDataModalComponent } from './components/echarts/no-data-modal/no-data-modal.component';
import { MaritimePortsInfrastructureComponent } from './pages/maritime-ports-infrastructure/maritime-ports-infrastructure.component';
import { WaterSupplyInfrastructureComponent } from './pages/water-supply-infrastructure/water-supply-infrastructure.component';
import { RiversComponent } from './pages/rivers/rivers.component';
import { PrecipitationNetWaterComponent } from './pages/precipitation-net-water/precipitation-net-water.component';
import { NaturalForestComponent } from './pages/natural-forest/natural-forest.component';
import { DynamicContainerComponent } from './pages/dynamic-container/dynamic-container.component';
import { RailInfrastructureComponent } from './pages/rail-infrastructure/rail-infrastructure.component';
import { PieBarTemplateComponent } from './templates/pie-bar-template/pie-bar-template.component';
import { PieChartComponent } from './components/amchart/pie-chart/pie-chart.component';
import { GraphBarLineComponent } from './components/amchart/graph-bar-line/graph-bar-line.component';
import { ReservoirsComponent } from './pages/reservoirs/reservoirs.component';
import { MvmDynamicTemplateComponent } from './templates/mvm-dynamic-template/mvm-dynamic-template.component';
import { MvmStaticTemplateComponent } from './templates/mvm-static-template/mvm-static-template.component';
import { MvmTimeVisualizationTemplateComponent } from './templates/mvm-time-visualization-template/mvm-time-visualization-template.component';
import { ScrollSpyDirective } from './templates/risk-scenarios-template/scroll-spy.directive';
import { LineScatterComponent } from './components/plotly/line-scatter/line-scatter.component';
import { SelectorYearsComponent } from './components/filters/selector-years/selector-years.component';



@NgModule({
    declarations: [
        GraphComponent,
        BarChartHorizontalComponent,
        BarChartVerticalComponent,
        LineChartComponent,
        DynamicMapComponent,
        DynamicStatesMapComponent,
        BoxplotComponent,
        HistogramComponent,
        StaticStatesMapComponent,
        StaticCountiesMapComponent,
        StaticTemplateComponent,
        DroughtComponent,
        DynamicShapeMapComponent,
        ReturnHomeComponent,
        DynamicTemplateComponent,
        EarthquakeComponent,
        FloodingComponent,
        HurricaneComponent,
        WildfireComponent,
        Covid19Component,
        AccumulatedComponent,
        StackedComponent,
        GaugeComponent,
        LineComponent,
        NaturalGasConsumptionComponent,
        TimeVisualizationTemplateComponent,
        StepComponent,
        TwoSingleFiltersComponent,
        ECDFComponent,
        HistogramD3Component,
        OneSingleFilterComponent,
        DynamicShapefilesTemplateComponent,
        ImageTemplateComponent,
        SelectStudyComponent,
        DynamicHeatmapTemplateComponent,
        DynamicHeatmapComponent,
        DynamicPointMapComponent,
        DynamicPointTemplateComponent,
        WindTurbineComponent,
        LineCategoryComponent,
        TimeCategoryVisualizationTemplateComponent,
        DynamicGeotiffMapComponent,
        DynamicGeotiffTemplateComponent,
        SurfaceAirTemperatureComponent,
        HurricaneTrinationalComponent,
        OneSingleOneMultiFiltersComponent,
        PrecipitationComponent,
        NotFoundComponent,
        MineralResourcesComponent,
        TrendModelComponent,
        AutocorrelationModelComponent,
        SeasonalModelComponent,
        SecondOrderComponent,
        CategoryBarTemplateComponent,
        BroadbandComponent,
        ThreeSingleFiltersComponent,
        TreeComponent,
        GeneralNodeComponent,
        FourSingleFilterComponent,
        FiveSingleFilterComponent,
        SimulationParamsComponent,
        StoryComponent,
        EchartDirective,
        ChartJsDirective,
        PlotlyComponent,
        ResidualAnalysisComponent,
        TimeControlComponent,
        YAxisFilterComponent,
        SixSingleFilterComponent,
        SolarComponent,
        Co2emissionsComponent,
        ModalFiltersComponent,
        SoilGridsComponent,
        ForestCharacteristicComponent,
        BridgesInfrastructureComponent,
        TrucksComponent,
        RoadwaysInfrastructureComponent,
        InlandWaterwaysInfrastructureComponent,
        NoDataModalComponent,
        MaritimePortsInfrastructureComponent,
        WaterSupplyInfrastructureComponent,
        RiversComponent,
        PrecipitationNetWaterComponent,
        NaturalForestComponent,
        DynamicContainerComponent,
        RailInfrastructureComponent,
        PieBarTemplateComponent,
        GraphBarLineComponent,
        PieChartComponent,
        ReservoirsComponent,
        MvmDynamicTemplateComponent,
        MvmStaticTemplateComponent,
        MvmTimeVisualizationTemplateComponent,
        ScrollSpyDirective,
        LineScatterComponent,
        SelectorYearsComponent
    ],
  imports: [
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    FontAwesomeModule,
    MatCardModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatDividerModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    MatDatepickerModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatSliderModule,
    MatSlideToggleModule,
  ],
  exports: [
    GraphComponent,
    BarChartHorizontalComponent,
    BarChartVerticalComponent,
    LineChartComponent,
    DynamicMapComponent,
    DynamicStatesMapComponent,
    BoxplotComponent,
    HistogramComponent,
    StaticStatesMapComponent,
    StaticCountiesMapComponent,
    StaticTemplateComponent,
    DroughtComponent,
    DynamicShapeMapComponent,
    ReturnHomeComponent,
    DynamicTemplateComponent,
    EarthquakeComponent,
    FloodingComponent,
    HurricaneComponent,
    WildfireComponent,
    NaturalGasConsumptionComponent,
    Covid19Component,
    TimeVisualizationTemplateComponent,
    TimeCategoryVisualizationTemplateComponent,
    NotFoundComponent,
    TreeComponent,
    EchartDirective,
    ChartJsDirective,
    LineComponent,
    LineCategoryComponent,
    ModalFiltersComponent,
    PieBarTemplateComponent,
    GraphBarLineComponent,
    PieChartComponent,
    LineScatterComponent,
    ECDFComponent,
  ]

})
export class SharedModule {
}
