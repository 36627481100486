import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DefaultBoxPlotGraphModel, DefaultGraphModel } from '../../../../core/models/chartjs.model';
import { PopulationGrowthModelResponse, PopulationUsCountyModel, PopulationUsStateModel } from '../../../../core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { ChartjsServiceService } from '../../../../core/services/chartjsService/chartjs-service.service';
import { HomeStore } from '../../../../core/stores/home-store';
import { SawmillsProductionService } from 'src/app/core/services/sawmillsProductionService/sawmills-production.service';
import { HousingPriceFuncModel, HousingPriceModel } from 'src/app/core/models/sawmillsProduction/housingPrice/housingPriceModel';
import { DynamicTemplateService } from 'src/app/core/services/shared/dynamic-template/dynamic-template.service';
import { DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';

@Component({
  selector: 'app-housing-price',
  templateUrl: './housing-price.component.html',
  styleUrls: ['./housing-price.component.css']
})
export class HousingPriceComponent {
  public data: any;

  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  constructor(private sawmillsProductionServiice: SawmillsProductionService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }

  }
  getDataCN() {
    this.spinner.hide();
  }
  //US Region
  getDataUs() {
    const dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'],//[ 'edb2ed', 'd10fd1', '590059', '360436'],
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#000000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric' },
      addDays: 1
    }
    this.sawmillsProductionServiice.getHousingPricesUS()
      .subscribe(
        {
          next: (res) => {
            this.data ={
              data: res,
              title: 'Housing Prices',
              description: 'The FHFA House Price Index (FHFA HPI®) is a comprehensive​ collection of public, freely available house price indexes that measure changes in single-family home values based on data from all 50 states and over 400 American cities that extend back to the mid-1970s.  The FHFA HPI incorporates tens of millions of home sales and offers insights about house price fluctuations at the national, census division, state, metro area, county, ZIP code, and census tract levels.  FHFA uses a fully transparent methodology based upon a weighted, repeat-sales statistical technique to analyze house price transaction data.',
              mapTitle: 'Housing Price Index by State',
              mapInfo: dataMap,
              ECDF: {
                title: `ECDF Housing Price Index from `,
                yLabel: 'Cumulative Relative Frequency', xLabel: 'HPI',
                titleCounty: 'Housing Price Index -',
              },
              histogram: {
                title: `Histogram Housing Price Index from `,
                yLabel: 'Frequency', xLabel: 'HPI',
                titleCounty: 'Housing Price Index -',
              },
              xyChart: {
                title: 'Housing Price Index -' ,
                yLabel: 'Housing price Index (%)', xLabel: 'Year' 
              },
              timeUnit: 'year',
              formatInfo: formatInfo,
              labelPopup: 'Average price changes' //HPI
            } as DynamicTemplateModel;

          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }

  //end us  region



  //MX Region
  getDataMx() {
    this.spinner.hide();
  }
  getDataTri(){
    this.spinner.hide();
  }
}
