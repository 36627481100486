import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartjsServiceService } from '../../../core/services/chartjsService/chartjs-service.service';
import { HomeStore } from '../../../core/stores/home-store';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { StaticTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { MapCenter, MapJson, MapZoom, ShapeMapDataLink } from 'src/app/core/enums/map.enum';

@Component({
  selector: 'app-wildfire',
  templateUrl: './wildfire.component.html',
  styleUrls: ['./wildfire.component.css']
})
export class WildfireComponent {
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;

  constructor(private transboundaryWaterService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    }else{
      this.spinner.hide();
    }

    if(this.filter != undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
      this.select = result ? result.itemId : null;
      this.filterInfo = this.filter;
      this.hasStudyButton = true;
    }

  }

  getDataCN() {
    const  dataMap = {
      center: MapCenter.CANADA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.CANADA,
      zoom: MapZoom.CANADA,
      borderColor: '#000000',
      color: '#FF0000',
      fillColor: '#FF0000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric' },
      addDays: 1
    };
    const shapeTypes = [
      {id: 0, name: 'L', color: 'rgba(255,255,31, 1)', shapeColor: 'rgba(255,255,31, 0.45)'},
      {id: 1, name: 'H', color: 'rgba(254,1,0, 1)', shapeColor: 'rgba(254,1,0, 0.45)'},

    ];
    this.transboundaryWaterService.getFireCnDates().subscribe(
      {
        next: (res:any) => {
          this.data = {
              data: res,
              metadata: res.metadata, 
              dates: res.dates,
              shapes: shapeTypes,
              mapInfo: dataMap,
              step: 4,
              shapeUrl: ShapeMapDataLink.Wildfire_cn,
              formatInfo: formatInfo
            };
            this.spinner.hide();
        },
        error: (error:any) => {this.spinner.hide(); console.error(error);}
      });
  }

  getDataUs() {
    const  dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#000000',
      color: '#FF0000',
      fillColor: '#FF0000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric' },
      addDays: 1
    };

      const shapeTypes = [
        {id: 0, name: 'Prescribed Fire', color: 'rgba(255,255,31, 1)', shapeColor: 'rgba(255,255,31, 0.45)'},
        {id: 1, name: 'Unknown', color: 'rgba(239,197,123, 1)', shapeColor: 'rgba(239,197,123, 0.45)'},
        {id: 2, name: 'Wildland Fire Use', color: 'rgba(246,149,0, 1)', shapeColor: 'rgba(246,149,0, 0.45)'},
        {id: 3, name: 'Wildfire', color: 'rgba(254,1,0, 1)', shapeColor: 'rgba(254,1,0, 0.45)'},

      ];
      this.transboundaryWaterService.getFireUsDates().subscribe(
        {
          next: (res:any) => {
            this.data = {
                data: res,
                metadata: res.metadata, 
                dates: res.dates,
                shapes: shapeTypes,
                mapInfo: dataMap,
                step: 2, // step year by year
                shapeUrl: ShapeMapDataLink.Wildfire_us,
                formatInfo: formatInfo,
                filterInfo: this.filterInfo,
                hasStudyButton: true,
              };
              this.spinner.hide();
          },
          error: (error:any) => {this.spinner.hide(); console.error(error);}
        });

    
  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

}
