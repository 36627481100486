<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template (selectedItem)="handleSelectItem($event)" [data]="this.data" [AllowValueHover]="true" [haveFilters]="2" [filters]="this.filterInfoValues"
        (firstValue)="handleFilterUpdate($event)"
        (secondValue)="handleFilterSecondUpdate($event)"></app-dynamic-point-template>
        <!-- (thridValue)="handleFilterThridUpdate($event)"></app-dynamic-point-template> -->
   </div>   
</div>
  
  <div *ngIf=" !this.isUSA">
    <app-not-found></app-not-found>
  </div>
  
