export interface StaticTemplateModel{
    numVariable?:number,
    data: any;
    boxplotTitle?: string;
    histogramTitle?: string;
    ECDF?: any;
    histogram?: any;
    mapTitle:  string;
    mapInfo?: any;
    title: string;
    description: string;
    filterInfo?: any;
    isCountyLevel?: boolean;
    hasStudyButton?: boolean;
    selectedInfo?: number;
    isStateOnly?:  boolean;
    labelPopup?: string;
    hasFilter?: boolean;
    filterType?: FilterType;
    filterInfoSecond?: any;
    emitFirstValue?: boolean;
    filterVariableInfo?: any;
    scale?: boolean;
}
export interface DynamicTemplateModel{
    title: string;
    description: string;
    mapTitle: string;
    mapInfo: any;
    formatInfo: any;
    xyChart: XYModel;
    ECDF: any;
    histogram: any;
    data: any;
    timeUnit: any;
    hasFilter: boolean;
    filterType: FilterType;
    filterInfo: any;
    filterVariableInfo?: any;
    hasStudyButton?: boolean;
    selectedInfo?: number;
    labelPopup?: string;
    isStateOnly?:  boolean;
    isCountyLevel?: boolean;
    filterInfoSecond?: any;
    emitFirstValue?: boolean;
    scale?: boolean;
    
}
export enum FilterType{
    oneFilter = "ONE",
    twoFilters  = "TWO",
    threeFilters = "THREE",
    fourFilters = "FOUR",
    fiveFilters = "FIVE",
    sixFilters = "SIX",
}
export interface TimeVisualizationTemplateModel{
    title: string;
    description: string;
    xyChart: XYModel;
    formatInfo: any;
    histogram: any;
    ecdfTitle: string;
    data: any;
    isDate: boolean;
    timeUnit: any,
    hasFilter: boolean;
    filterType: FilterType;
    filterInfo: any;
    filterVariableInfo?: any;
    plusMax: number;
    hasStudyButton?: boolean;
    selectedInfo?: number;
    dataikuModel?: DataikuModel;
    filterInfoSecond?: any;
    emitFirstValue?: boolean;
    listYears?: any;
}

interface DataikuModel{
    table: string;
    column_to_work: string;
    frequency?: string;
    date_?: string[];
    filters_?: string[];
    filter_?: string;
    
    y_axis_filter?: string[];

    totalRows?: number;
    nlags_?: number;
    algorithm_?: string;

    window_size_0_1?: number;
    window_size?: number;
    degree?: number;
    smoothing_factor?: number;
    stl_trend_smoother?: number;
    stl_seasonal_smoother?: number;
    stl_period?: number;

    project_?: string;
}
interface XYModel{
    title: string;
    xLabel: string;
    yLabel: string;
}