import { Link } from "../models/link.model";

export const footerLinks: Link[] = [
  { description: 'Compact with Texans', href: 'http://agrilife.org/required-links/compact/' },
  { description: 'Privacy and Security', href: 'http://itaccessibility.tamu.edu/' },
  { description: 'Accessibility Policy', href: 'http://itaccessibility.tamu.edu/' },
  { description: 'State Link Policy', href: 'http://publishingext.dir.texas.gov/portal/internal/resources/DocumentLibrary/State%20Website%20Linking%20and%20Privacy%20Policy.pdf' },
  { description: 'Statewide Search', href: 'http://www.tsl.state.tx.us/trail' },
  { description: 'Veterans Benefits', href: 'http://veterans.tamu.edu/' },
  { description: 'Military Families', href: 'https://fch.tamu.edu/programs/military-programs/' },
  { description: 'Risk, Fraud & Misconduct Hotline', href: 'https://secure.ethicspoint.com/domain/en/report_custom.asp?clientid=19681' },
  { description: 'Texas Homeland Security', href: 'https://gov.texas.gov/organization/hsgd' },
  { description: 'Texas Veterans Portal', href: 'http://veterans.portal.texas.gov/' },
  { description: 'Equal Opportunity', href: 'http://agrilifeas.tamu.edu/hr/diversity/equal-opportunity-educational-programs/' },
  { description: 'Open Records/Public Information', href: 'http://agrilife.org/required-links/orpi/'}
];

export const footerCollabLinks: Link[] = [
  { description: 'State of Texas Texas Homeland Security' },
  { description: 'Open Records' },
  { description: 'Risk, Fraud. & Misconduct Hotline' },
  { description: 'Accommodations' },
  { description: 'Statewide Search' },
  { description: 'Texas CREWS' },
  { description: 'Environmental health, Safety, & Security' },
  { description: 'Accessibility' },
  { description: 'Site Policies' },

];
