
<div style="display: flex; justify-content: flex-start;gap: 2rem;">
  <button class="btn btn-home" *ngIf="this.data.hasStudyButton" (click)="openStudyDialog()">Select variable</button>
</div>
<br />

<div class="card">
  <div class="card-body">
    <div class="row gap-1">
      <div class="location">{{this.data.metadata.name}}</div>
      <p>
          {{this.data.metadata.description}}
      </p>
    </div>
    
    <div class="subtitle2 pt-2">Metadata</div>
    <div class="row">
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span> {{this.data.metadata.classification == "" ? "N/A" : this.data.metadata.classification}}</li>
          <li class="list-group-item"><span class="subtitle">Type of Evidence:</span> {{this.data.metadata.evidenceType}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Spatial Resolution:</span> {{this.data.metadata.spatialResolution}}</li>
          <li class="list-group-item"><span class="subtitle">Temporal Resolution:</span> {{this.data.metadata.temporalResolution}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Time Period:</span> {{this.data.metadata.timePeriod}}</li>
          <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.data.metadata.source}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

 <!-- TABS-->
 <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" style="border-radius: 30px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
      <span class="textMedium">Metadata & Visualization</span>
    </button>
  </li>
</ul>

<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

  <div class="row card half-border">
    <div *ngIf="this.haveFilters == 1">
      <app-two-single-filters [data]="this.filters" (firstValue)="emitFirstValue($event)"></app-two-single-filters>
    </div>
    <div *ngIf="this.haveFilters == 2">
      <app-two-single-filters [data]="this.filters" (firstValue)="emitFirstValue($event)" (secondValue)="emitSecondValue($event)"></app-two-single-filters>
    </div>

    <mat-card style="box-shadow: none;">
      <mat-card-header>
        <h2>{{title}}</h2>
      </mat-card-header>
      <mat-card-content>
        <app-dynamic-shape-map
        [data]="this.data"
        [newFirstDate]="newFirstDate"
        [formatInfo]="this.data.formatInfo"
        [dates]="this.data.dates.join()"
        [newData]="this.newData"
        [mapInfo]="this.data.mapInfo"
        (getNewData)="this.getData()"></app-dynamic-shape-map>
      </mat-card-content>
      <mat-card-footer style="display:flex; justify-content: space-between;flex-wrap: wrap;">
        <div style="display:flex; justify-content: space-between; gap: 0.5rem;" *ngFor="let shape of this.data.shapes">
            <div style="height: 1rem;
            width: 1rem; align-self: center;" [style.background-color]="shape.color"></div>
            <div>{{shape.name}}</div>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>

</div>







