<!-- <div *ngIf="this.isHomeView && !this.isMVM">
  <div *ngIf="this.categoryLinks.length">
    <div style="display: grid; grid-template-columns: repeat(3, 1fr);">
      <div class="list-container" *ngFor="let categoryLink of this.categoryLinks">
          <h3 class="center-text">{{categoryLink.name}}</h3>
          <br>
          <div class="list-content">
            <div class="list-item_container"  *ngFor="let link of categoryLink.links">
              <div class="list-item_content" [style.background-color]="link.itemStyle.color"></div>
              <div >{{link.name}}</div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="filter-containter">
    <div>
      <form class="example-form">
          <input type="text" placeholder="Start typing..." style="padding: 0.6rem;"
                [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="getSelectedNode($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
      </form>
    </div>
    <div>
      <form class="example-form">
        <mat-form-field>
          <mat-label>Risk component classification</mat-label>
          <mat-select [(value)]="selectedOptionClassification" (selectionChange)="getSelectedClassification($event)">
            <mat-option *ngFor="let option of itemsClassification" [value]="option.name">
              <fa-icon [icon]="option.icon"></fa-icon>  {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div>
      <form class="example-form">
        <mat-form-field>
          <mat-label>Variable Type  </mat-label>
          <mat-select [(value)]="selectedOptionVariableType" (selectionChange)="getSelectedVariableType($event)">
            <mat-option *ngFor="let option of itemsVariableType" [value]="option.name">
              <fa-icon [icon]="option.icon"></fa-icon>  {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="zoom">
      <button (click)="zoomIn()" class="btn btn-zoom"> <fa-icon [icon]="faPlus"></fa-icon></button>
      <button (click)="zoomOut()" class="btn btn-zoom"> <fa-icon [icon]="faMinus"></fa-icon></button>
      <button (click)="goLeft()" class="btn btn-zoom"> <fa-icon [icon]="faArrowLeft"></fa-icon></button>
      <button (click)="reload()" class="btn btn-zoom"> <fa-icon [icon]="faRotateRight"></fa-icon></button>
      <button (click)="goRight()" class="btn btn-zoom"> <fa-icon [icon]="faArrowRight"></fa-icon></button>
    </div>
  </div>
</div> -->

<div *ngIf="this.isHomeView && !this.isMVM">
  <div class="row">
    <div class="col-md-8">
      <form class="example-form">
        <input type="text" placeholder="Search..." style="padding: 0.6rem;" [formControl]="myControl" [matAutocomplete]="auto" class="custom-autocomplete">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="getSelectedNode($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
      </form>
    </div>

    <div class="col-md-2">
      <button class="filter-btn" (click)="openModal()"> <fa-icon [icon]="faFilter"></fa-icon>Filters
      </button>
    </div>

    <div class="col-md-2">
      <div style="border-color: black; border-radius: 30px;">
        <div> Data availability only for white border nodes</div>
      </div>
      
    </div>

  </div>
</div>

<div  #chart class="bayesian-network" ></div>


