import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DefaultBoxPlotGraphModel, DefaultGraphModel } from '../../../../core/models/chartjs.model';
import { PopulationGrowthModelResponse, PopulationUsCountyModel, PopulationUsStateModel } from '../../../../core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { ChartjsServiceService } from '../../../../core/services/chartjsService/chartjs-service.service';
import { TransboundaryWaterServiceService } from '../../../../core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from '../../../../core/stores/home-store';
import { HousingPriceFuncModel, HousingPriceModel } from 'src/app/core/models/sawmillsProduction/housingPrice/housingPriceModel';
import { DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';

@Component({
  selector: 'app-population-growth',
  templateUrl: './population-growth.component.html',
  styleUrls: ['./population-growth.component.css']
})
export class PopulationGrowthComponent {
  public data: any;
  
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  public dataMap: any = {};

  constructor(private transboundaryService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, 
    private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    } else {
      this.spinner.hide();
    }

  }
  getDataCN() {
    this.transboundaryService.getPopulationGrowthCN()
      .subscribe(
        {
          next: (res) => {
            this.data = res as PopulationGrowthModelResponse;
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }
  //US Region
  getDataUs() {
    this.dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'],
      json: MapJson.USA,
      zoom: MapZoom.USA,
      dateFormat: 'YYYY',
      borderColor: '#000000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric'},
      addDays: 0
    };
    this.transboundaryService.getPopulationGrowthUS()
    .subscribe(
      {
        next: (res) => {
          this.data = {
              data: res,
                title: 'Population Growth US',
                description: 'info',
                mapTitle: 'Population Growth US',
                mapInfo: this.dataMap,
                ECDF: {title: 'ECDF Population Growth ',
                         yLabel: 'Cumulative Relative Frequency', xLabel: 'Population Growth US',
                         titleCounty: 'Population Growth',
                        },
                  histogram: {
                    title: `Histogram Population Growth `,
                    yLabel: 'Frequency', xLabel: 'Population Growth US',
                    titleCounty: 'Population Growth',
                  },
                  xyChart: {
                      title: 'Population Growth' ,
                      yLabel: 'Population Growth US', xLabel: 'Year' 
                    },
                timeUnit: 'year',
                formatInfo: formatInfo,
                labelPopup: 'Value', //Npopchg
              } as DynamicTemplateModel;
          
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
  }


  //end us  region



  //MX Region
  getDataMx() {
    this.spinner.hide();
  }
}
