import {
  GRAPH_CATEGORIES,
  GRAPH_CATEGORIES_FILTERED,
  GRAPH_LINKS_FILTERED_1,
  GRAPH_LINKS_FILTERED_2,
  GRAPH_NODES_FILTERED_1,
  GRAPH_NODES_FILTERED_2,
  HOUSING_DEMAND_VAR110,
  HOUSING_DEMAND_VAR111,
  REVENUE_VAR138,
  WOOD_PRODUCTION_VAR129,
} from './data-test';

export const STORY_EXAMPLE = {
  name: '',
  initialSection: 'overview',
  menu: [
    { label: 'Overview', sectionId: 'overview' },
    { label: 'Housing Demand', sectionId: 'housing-demand' },
    {
      label: 'Wood Production',
      sectionId: 'impact-on-wood-production',
    },
    {
      label: 'Revenue',
      sectionId: 'revenue-of-the-wood-timber-industry',
    },
  ],
  sections: {
    overview: {
      title: 'Overview',
      children: [
        'title',
        'housing-demand',
        'wood-production',
        'revenue',
        'risk-analytics',
      ],
    },
    'housing-demand': {
      title: 'Housing Demand',
      children: ['hd-container-detailed'],
    },
    'impact-on-wood-production': {
      title: 'Wood Production',
      children: ['wp-container'],
    },
    'revenue-of-the-wood-timber-industry': {
      title: 'Revenue',
      children: ['rev-container'],
    },
  },
  components: {
    title: {
      component: 'brief',
      style: `width: 100%; justify-items: center; text-align: center;`,
      title: `<h1 class="mt-4 mb-3 ms-4 me-4" style="text-align: left; ">Housing demand growth post COVID-19 pandemic in the US</h1>`,
      text: `<p class="p-4" style="text-align: justify;" >
      Housing demand growth reflects the changes in preferences and behaviors of households in response to the pandemic, such as the desire for more space, the ability to work remotely, and the need for affordability. Understanding these changes can help policymakers and market participants to design and implement policies and strategies that meet the needs and expectations of homebuyers and renters.
      </p>
      <p class="p-4 pt-0" style="text-align: justify;" >
      Higher housing demand can lead to higher prices, lower inventory, and increased competition, which can create affordability and accessibility challenges for some segments of the population.
      </p>`,
      image:
        'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.pexels.com%2Fphotos%2F1396122%2Fpexels-photo-1396122.jpeg%3Fcs%3Dsrgb%26dl%3Darchitecture-bungalow-daylight-1396122.jpg%26fm%3Djpg&f=1&nofb=1&ipt=560fe30488b9276c48638e6a38dd74cf8caa85a94fef6f7553d8b0cc66354cb8&ipo=images',
      imageAlt: '',
    },
    'housing-demand': {
      component: 'section',
      title: `<h2 class="ms-4 mt-4 mb-3">Housing Demand</h2>`,
      text: `<p class="p-4 pt-0" style="text-align: justify;" >
      When there is an increased demand for housing, either due to population growth, urbanization, or economic factors, the construction industry experiences a surge. Wood is a fundamental material in construction, used for framing, flooring, and various other purposes.
      </p>`,
      variables: [HOUSING_DEMAND_VAR110, HOUSING_DEMAND_VAR111],
    },
    'wood-production': {
      component: 'section',
      title: `<h2 class="ms-4 mt-4 mb-3">Wood Production</h2>`,
      text: `<p class="p-4 pt-0" style="text-align: justify;" >
      High demand for wood without a corresponding increase in the efficiency and capacity of the wood production process can lead to shortages. Logging and processing facilities may struggle to keep up with the demand, affecting the overall volume and quality of wood available for construction.
      </p>`,
      variables: [WOOD_PRODUCTION_VAR129],
    },
    revenue: {
      component: 'section',
      title: `<h2 class="ms-4 mt-4 mb-3">Revenue</h2>`,
      text: `<p class="p-4 pt-0" style="text-align: justify;" >
      The revenue of the wood/timber industry is directly tied to the volume of wood sold and the prevailing market prices. If production struggles to meet demand, there may be missed opportunities for sales. Conversely, if prices rise significantly, it might increase revenue per unit sold, but this could be offset by decreased overall sales volume if some potential buyers are priced out of the market.
      </p>`,
      variables: [REVENUE_VAR138],
    },
    'hd-container-detailed': {
      component: 'rows',
      children: ['hd-text', 'hd-chart-1', 'hd-chart-2'],
    },
    'hd-text': {
      component: 'rich-text',
      style: `width: 100%;`,
      text: `<h2 class="ms-4 mt-4 mb-3">Housing Demand</h2>
      <p class="p-4 pt-0" style="text-align: justify;" >
      When there is an increased demand for housing, either due to population growth, urbanization, or economic factors, the construction industry experiences a surge. Wood is a fundamental material in construction, used for framing, flooring, and various other purposes.
      </p>`,
    },
    'hd-chart-1': {
      component: 'line',
      variables: [HOUSING_DEMAND_VAR110],
      style: `flex-grow: 1;`,
    },
    'hd-chart-2': {
      component: 'line',
      variables: [HOUSING_DEMAND_VAR111],
      style: `flex-grow: 1;`,
    },
    'wp-container': {
      component: 'rows',
      children: ['wp-text', 'wp-chart'],
      classes: '',
    },
    'wp-chart': {
      component: 'line-category',
      data: {
        data: WOOD_PRODUCTION_VAR129,
        xyChart: {
          title: '',
          data: WOOD_PRODUCTION_VAR129,
        },
        histogram: {
          title: '',
        },
      },
      style: `flex-grow: 1;`,
    },
    'wp-text': {
      component: 'rich-text',
      style: `width: 100%;`,
      text: `<h2 class="ms-4 mt-4 mb-3">Wood Production</h2>
      <p class="p-4 pt-0" style="text-align: justify;" >
      High demand for wood without a corresponding increase in the efficiency and capacity of the wood production process can lead to shortages. Logging and processing facilities may struggle to keep up with the demand, affecting the overall volume and quality of wood available for construction.
      </p>`,
    },
    'rev-container': {
      component: 'rows',
      children: ['rev-text', 'rev-chart'],
    },
    'rev-text': {
      component: 'rich-text',
      style: `width: 100%;`,
      text: `<h2 class="ms-4 mt-4 mb-3">Revenue</h2>
      <p class="p-4 pt-0" style="text-align: justify;" >
      The revenue of the wood/timber industry is directly tied to the volume of wood sold and the prevailing market prices. If production struggles to meet demand, there may be missed opportunities for sales. Conversely, if prices rise significantly, it might increase revenue per unit sold, but this could be offset by decreased overall sales volume if some potential buyers are priced out of the market.
      </p>`,
    },
    'rev-chart': {
      component: 'line-category',
      data: {
        data: REVENUE_VAR138,
        xyChart: {
          title: '',
          data: REVENUE_VAR138,
        },
        histogram: {
          title: '',
        },
      },
      style: `flex-grow: 1;`,
    },
    'risk-analytics': {
      component: 'simulator',
      variables: [
        {
          name: 'Housing\nDemand',
          type: '',
          options: [
            { label: 'Low', value: 'low' },
            { label: 'Medium', value: 'medium' },
            { label: 'High', value: 'high' },
          ],
        },
        {
          name: 'Wood production',
          type: '',
          options: [
            { label: 'Low', value: 'low' },
            { label: 'Medium', value: 'medium' },
            { label: 'High', value: 'high' },
          ],
        },
      ],
      data:{
        legend: {
          data: GRAPH_CATEGORIES.map((a: any) => {
            return a.name;
          }),
          type: 'scroll',
          itemStyle: { borderWidth: 1, borderColor: '#000' },
        },
        series: [
          {
            type: 'graph',
            layout: 'none',
            zoom: 1.5,
            symbolSize: 10,
            center: [7, -8],
            roam: true,
            itemStyle: {
              borderColor: '#000',
            },
            color: '#500000',
            label: {
              show: true,
              color: '#000',
              fontWeight: 'bold',
              fontSize: 10.5,
              position: 'top',
              formatter: '{b}',
            },
            symbol: 'circle',
            edgeSymbol: ['rect', 'arrow'],
            edgeSymbolSize: [1, 8],
            data: GRAPH_NODES_FILTERED_1,
            links: GRAPH_LINKS_FILTERED_1,
            categories: GRAPH_CATEGORIES,
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
          },
        ],
      },
      clickHandler: (
        { evt, chartInstance }: any,
        variables: any[],
        openSelectVariableValueModal: (
          componentId: string,
          variableName: string,
          variables: any[]
        ) => void
      ) => {
        if (!chartInstance.__initialized) {
          chartInstance.__initialized = true;
          chartInstance.setOption(
            {
              xAxis: {
                type: 'category',
                data: ['0', '0.2', '0.4', '0.6', '0.8', '1'],
              },
              yAxis: {
                type: 'value',
              },
              grid: {
                top: '150px',
                bottom: '20px',
                left: '50px',
                right: '10px',
              },
              lineStyle: {
                cap: 'round'
              },
              symbolOffset: [10, 0],
              series: [
                {
                  type: 'graph',
                  layout: 'none',
                  roam: false,
                  zoom: 1,
                  symbolSize: 60,
                  edgeSymbolSize: [6, 15],
                  center: [0, 0.8],
                  label: {
                    show: true,
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: 10.5,
                    position: 'top',
                    formatter: '{b}',
                  },
                  symbol: 'circle',
                  edgeSymbol: ['rect', 'arrow'],
                  data: GRAPH_NODES_FILTERED_2,
                  links: GRAPH_LINKS_FILTERED_2,
                  categories: GRAPH_CATEGORIES_FILTERED,
                  lineStyle: {
                    opacity: 0.9,
                    width: 4,
                    curveness: 0,
                  },
                },
                {
                  data: [120, 200, 150, 80, 70, 110],
                  type: 'bar',
                  showBackground: true,
                  categories: GRAPH_CATEGORIES,
                  backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)',
                  },
                },
              ],
            },
            true
          );
        } else {
          openSelectVariableValueModal('', evt.data.fullname, variables);
        }
      },
    },
  },
};
