import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeStore } from '../../../core/stores/home-store';
import { MapCenter, MapJson, MapZoom, ShapeMapDataLink } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
  selector: 'app-flooding',
  templateUrl: './flooding.component.html',
  styleUrls: ['./flooding.component.css']
})
export class FloodingComponent {
  public canData!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  public f1 = 'AREA_HA';
  public numPage = 1
  public page: any = { 
    pageNumber: this.numPage
  };

  public selection: any = { 
    filter_one: this.f1
  };

  filterInfoValues!: any;

  constructor(
    private naturalService: NaturalService,
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      setTimeout(async () => {
        const filters = await firstValueFrom(
          this.naturalService.getFiltersFlooding()
        );

        this.filterInfoValues = {
          firstFilter: filters.filter_OneData
        };

        if(filters.filter_OneData){
          this.getDataCN(this.selection);
        }
  
      }, 300); 
    }else{
      this.spinner.hide();
    }

  }

  getDataCN(selection: any) {
    const shapeTypes = [
      {id: 'PERIM_M', name: 'PERIM_M', color: 'rgba(239,197,123, 1)', shapeColor: 'rgba(255,255,123, 0.45)'},
      {id: 'AREA_HA', name: 'AREA_HA', color: 'rgba(239,197,125, 1)', shapeColor: 'rgba(239,197,125, 0.45)'},
    ];
      this.naturalService.getFloodingDates(this.page)
        .subscribe(
          {
            next: (res:any) => {
              //console.log(res);
              const  dataMap = {
                center: MapCenter.CANADA,
                zoom: MapZoom.CANADA,
              };
              const formatInfo = {
                region: "en-US",
                format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
                addDays: 0
              };
              this.canData = {
                data: shapeTypes,
                metadata: res.metadata, 
                dates: res.dates,
                shapes: shapeTypes,
                mapInfo: dataMap,
                step: 12, // step year by year
                shapeUrl: ShapeMapDataLink.Flooding_cn,
                formatInfo: formatInfo,
                // typeMap: 'Static', 
                haveFilters: 1,
              };  

              this.numPage++;
            },
            error: (e:any) => { console.error(e); this.spinner.hide(); }
          }
        );
    this.spinner.hide();
  }

  async handleFilterUpdate($event: any) {
    this.f1 = $event.toString();

    this.selection = { 
      filter_one: $event.toString()
    }; 

    this.getDataCN(this.selection);
  }

}
