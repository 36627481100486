import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
declare const L: any;
import * as XLSX from 'xlsx';
import 'leaflet';
import "leaflet-timedimension";
import "../../../../../assets/js/leaflet.timedimension.choropleth";
import "../../../../../assets/js/leaflet.SpatialTemporal.choropleth.control";
import { HttpClient } from '@angular/common/http';
import * as chroma from "chroma-js";
import { MapJson } from 'src/app/core/enums/map.enum';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dynamic-counties-map',
  templateUrl: './dynamic-counties-map.component.html',
  styleUrls: ['./dynamic-counties-map.component.css']
})
export class DynamicMapComponent implements OnInit,OnChanges, OnDestroy {
  @ViewChild('map', {read: ElementRef}) private mapEl!: ElementRef<HTMLElement>;
  @Input() labelPopup: string = "";
  @Input() data!: any;
  @Input() variable!:number;
  @Input() title: string = "";
  @Input() mapInfo!: any;
  @Input() bounds!: number;
  @Input() formatInfo!: any;
  @Input() property!: string;
  @Input() index!:any;
  @Input() consultedIndex: any; // Recibe el valor de consultedIndex
  @Output() countySelected = new EventEmitter<any>();
  @Output() currentTime = new EventEmitter<number>();
  @Output() dataUpdated = new EventEmitter<any>();
  @Output() indexUpdate = new EventEmitter<any>();
  private consultedIndexes: Set<number> = new Set();
  private datosConsultados: Set<number> = new Set();
  private consultasEnProgreso: Set<number> = new Set();

  private map: any;
  private states: any;
  maxValue: any;
  private geojson!: any;
  private timeSeries!: any;
  private player !: any;
  private legend !: any;
  private info !: any;
  promiseGeoJson!: Promise<any>;
  private values: any[] = [];
  geojsonLayer: any;
  highlightFeature: any;
  resetHighlight: any;
  private counties: any;
  constructor(private http: HttpClient,private generalservice: GeneralService,private spinner: NgxSpinnerService) {
    this.consultedIndexes = new Set([0]);
    this.datosConsultados=new Set([0]);

   }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map !== undefined) {
      this.player.stop();
      this.map.remove();
    }
    this.maxValue = this.data.maxCounty;
    this.initMap();
    this.map.on('timeupdate', this.ngOnInit.bind(this));
    this.getGeoJsonData();
    Promise.allSettled([this.promiseGeoJson]).then(value => {
      this.onReload();
    });

    this.map.on('timeupdate', (event: any) => {
      debugger
      if(this.consultedIndex!=undefined){
        this.consultedIndexes=this.consultedIndex;
        this.datosConsultados=this.consultedIndex;
      }
      if(this.data.metadata.typeVariable!='Spatial Temporal MVM'){
      const index = event.index;
      if (this.consultasEnProgreso.has(index)) {
        return;
      }
      if(this.index!=undefined){
        if(this.index.size>this.consultedIndexes.size){
  this.consultedIndexes=this.index;
  this.datosConsultados=this.index;
        }
      }

      if (this.consultedIndexes.has(index) && this.datosConsultados.has(index)) {
if(event.action=='next'){
  this.map.fire('timeupdateProcessedNext',{
    estado: 'procesado',
  });
}
else if(event.action=='back'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedBack');
}
else if(event.action=='play'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedPlay');
}
else if(event.action=='slice'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedSlice');
}
        return; 
      }

    this.consultasEnProgreso.add(index);
this.spinner.show();
      this.consultedIndexes.add(index);
      let mdl = {
        numVariable: this.variable,
        StartDate: event.date
      };
      this.generalservice.getDataSpatialTemporalDate(mdl).subscribe(z => {
        this.datosConsultados.add(index);
        this.HandleDate(z,event.index);
        this.index=this.consultedIndexes;
  this.dataUpdated.emit(this.data);
  this.indexUpdate.emit(this.index);
        this.updateMap();
        this.spinner.hide();
        if(event.action=='next'){
this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedNext',{
            estado: 'procesado',
          });
        }
        else if(event.action=='back'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedBack');
        }
        else if(event.action=='play'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedPlay');
        }
        else if(event.action=='slice'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedSlice');
        }
      });
    }
    else{
      if(event.action=='next'){
        this.map.fire('timeupdateProcessedNext',{
          estado: 'procesado',
        });
      }
      else if(event.action=='back'){
        this.consultasEnProgreso.clear();
        this.map.fire('timeupdateProcessedBack');
      }
      else if(event.action=='play'){
        this.consultasEnProgreso.clear();
        this.map.fire('timeupdateProcessedPlay');
      }
      else if(event.action=='slice'){
        this.consultasEnProgreso.clear();
        this.map.fire('timeupdateProcessedSlice');
      }
              return; 
            }
          
    });

    
  
  }

  HandleDate(z:any, index:any):void{

    const dataStatesMap = new Map();
    this.data.data_States.forEach((item: { name: any; values: any; }) => {
      dataStatesMap.set(item.name, [...item.values]); 
    });
    z.data_States.forEach((item: { name: any; values: any; }) => {
      if (dataStatesMap.has(item.name)) {
        const existingValues = dataStatesMap.get(item.name);
        const newValues = this.fillZerosUntilIndex(existingValues, index);
        newValues[index] = item.values[0]; 
        dataStatesMap.set(item.name, newValues);
      } else {
        const newValues = Array(index).fill(0); 
        newValues[index] = item.values[0];
        dataStatesMap.set(item.name, newValues);
      }
    });
    this.data.data_States.forEach((item: { name: any; values: any; }) => {
      if (!z.data_States.some((newItem: { name: any; }) => newItem.name === item.name)) {
        const existingValues = dataStatesMap.get(item.name);
        const newValues = this.fillZerosUntilIndex(existingValues, index);
        dataStatesMap.set(item.name, newValues);
      }
    });
    this.data.data_States = Array.from(dataStatesMap, ([name, values]) => ({ name, values }))
      .sort((a, b) => parseInt(a.name) - parseInt(b.name));
const dataMap = new Map<string, { state: string, values: number[] }>();
this.data.data.forEach((item: { name: string; state: string; values: number[] }) => {
dataMap.set(item.name, { state: item.state, values: [...item.values] });
});
z.data.forEach((item: { name: string; state: string; values: number[] }) => {
if (dataMap.has(item.name)) {
const existingItem = dataMap.get(item.name);
const existingValues = existingItem?.values || [];
const newValues = this.fillZerosUntilIndex(existingValues, index);
newValues[index] += item.values[0];
dataMap.set(item.name, { state: item.state, values: newValues });
} else {
const newValues = Array(index).fill(0);
newValues[index] = item.values[0];
dataMap.set(item.name, { state: item.state, values: newValues });
}
});
this.data.data.forEach((item: { name: string; state: string; values: number[] }) => {
if (!z.data.some((newItem: { name: string }) => newItem.name === item.name)) {
const existingItem = dataMap.get(item.name);
const existingValues = existingItem?.values || [];
const newValues = this.fillZerosUntilIndex(existingValues, index);
dataMap.set(item.name, { state: item.state, values: newValues });
}
});
this.data.data = Array.from(dataMap, ([name, { state, values }]) => ({ name, state, values }))
.sort((a, b) => parseInt(a.name) - parseInt(b.name));

  }

  getIndex(event: any){
    this.consultedIndexes=event;
  }

  ngOnDestroy(): void {
    if (this.map !== undefined) {
      this.removeMapListeners();
      this.map.remove();
   
    }
  }
  removeMapListeners(): void {
    if (this.map) {
      this.map.off('timeupdate'); 
    }
  }



  ngOnInit(): void {
    this.map.on('timeupdate', this.ngOnInit.bind(this));
  }

  private updateMap(): void {
    this.createNewItems();
    if (this.geojson) {
      this.map.removeLayer(this.geojson);
    }
    this.timeSeries = L.geoJSON(this.states, {
      style: this.style,
      onEachFeature: this.onEachFeature,
    });
    this.geojson = L.timeDimension.layer.choropleth(this.timeSeries, {
      updateTimeDimensionMode: 'replace',
      waitForReady: true,
    });
    this.geojson.addTo(this.map);
  }
  fillZerosUntilIndex(values: any[], index: number): any[] {
    const newArray = [...values];
    while (newArray.length <= index) {
      newArray.push(0);
    }
    return newArray;
  }
  
  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map('map', {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 12,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: '<img class="icon-svg" src= "../../assets/images/house-solid.svg" alt="home-icon">',
        zoomHomeTitle: 'Zoom home'
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
        this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (html: any, title: any, className: any, container: any, fn: any) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      }
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  getGeoJsonData() {
    this.promiseGeoJson = new Promise((resolve, reject) => {
      this.http.get(`/assets/geojson/${this.mapInfo.json}_counties.json`).subscribe((counties: any) => {
        this.states=counties;
        this.counties = counties.features.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature.properties.NAME;  // Mapea el id del condado al nombre
          return acc;
        }, {});
        resolve(this.states);
      }, error => {
        reject(error);
      });
    });
  }
  onReload() {
    this.createNewItems();
    this.initStatesLayer();
  }
  private createNewItems() {
    this.data.data.forEach((state: any) => {
      let itemIndex = -1
      if(this.mapInfo.json === MapJson.USA){
        itemIndex = this.states.features.findIndex((item: any) => item.id == state.name);
      }else if(this.mapInfo.json === MapJson.MEXICO){
        itemIndex = this.states.features.findIndex((item: any) => item.properties.state_code+""+item.properties.mun_code == state.name);
      }
      if (itemIndex == -1) return;
      const copyItem = JSON.parse(JSON.stringify(this.states.features.at(itemIndex))); //deep copy for the item
      this.states.features.splice(itemIndex, 1); //remove original item.
      copyItem.properties.times = [];
      copyItem.properties.values = [];
      copyItem.properties.times.push(... this.data.dates);
      copyItem.properties.valuesString = state[this.property].map((val: number) => { return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})});
      copyItem.properties.values.push(...state[this.property]);
      copyItem.properties.id = copyItem.id;
      this.states.features.push(copyItem);

    });
    for (var i = 0; i < this.states.features.length; i++) {
      if (this.states.features[i].properties.values === undefined) {
        this.states.features.splice(i, 1); //remove original item.
        i--;
      }
    }
    let min = Math.min( ... this.data.data.map((item: any) => { return Math.min(... item[this.property].filter((x: any) => x !== 0))}) );
    let max = Math.max( ... this.data.data.map((item: any) => { return Math.max(... item[this.property].filter((x: any) => x !== 0))}) );

    min = Math.round(min);
    max = Math.round(max);

    while(max % 10 !== 0){
      max++;
    }

    while(min % 10 !== 0){
      min--;
    }

    const length = this.data.data.at(0)[this.property].length;
    for(var i = 0; i < length; i++){
      const values_ = [...new Set(this.data.data.map((item: any) => { return item[this.property].at(i) }))].sort((a:any, b: any) => {
        return a - b;
      });
      values_.unshift(min);
      if(values_.at(-1) !== max){
        values_.pop();
        values_.push(max);
      }
      this.values.push(values_);
    }
  }
  private sentCountyInfo(feature: any){
    let model = {};
    if(this.mapInfo.json === MapJson.USA){
      model =  { name: feature.properties.NAME, id: feature.properties.id };
    }else if(this.mapInfo.json === MapJson.MEXICO){
      model = { name: feature.properties.NAME, id: this.zeroPad(feature.properties.mun_code, 3) }
    }
    this.countySelected.emit(model);
  }
  private initStatesLayer() {
    const firstFeature = this.states.features[0];
    this.sentCountyInfo(firstFeature);
    var timeDimension = new L.TimeDimension({
      period: "PT1H",
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player({
      transitionTime: 2500,
      loop: false,
      startOver: false
    }, timeDimension);
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 10,
      timeSliderDragUpdate: true,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays
    };
    var timeDimensionControl = new L.Control.SpatialTemporalChoropleth(timeDimensionControlOptions);

    this.map.addControl(timeDimensionControl);
    this.timeSeries = L.geoJSON(this.states, { style: this.style, onEachFeature: this.onEachFeature });

    this.geojson = L.timeDimension.layer.choropleth(this.timeSeries, { updateTimeDimensionMode: 'replace',
                                                                        waitForReady: true,
                                                                        });



    this.geojson.addTo(this.map);


    this.info = L.control({ position: 'topleft' });

    this.info.onAdd = function (map: any) {
      this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    this.info.update = function (props: any, map: any, mouseout: boolean) {
      let text = '';
      if (props === undefined) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return
      };
      if (mouseout) {
        text += 'Hover over a county';
        this._div.innerHTML = text;
        return;
      }
      if (props.values === undefined) {
        text += `<app-not-found></app-not-found>`;
      } else {
        text += '<b>' + props.NAME + '</b><br />';
        text += '<b>' + props.valuesString[map.timeDimension.getCurrentTimeIndex()] + '</b><br />';
      }
      this._div.innerHTML = text;
    };

    this.info.addTo(this.map);
    this.legend = L.control({position: 'bottomright'});

    this.legend.onAdd =  function(map:any) {

      this._div = L.DomUtil.create('div', 'info legend'); // create a div with a class "info"
      return this._div;

    };
    this.legend.update =  (values: any) =>{
      const values_ = values.filter((x: any) => x !== 0).reverse();
      this.legend._div.innerHTML = "";
      // loop through our density intervals and generate a label with a colored square for each interval
      for (var i = 0; i < values_.length; i++) {
          if(i == 0 || i == (values_.length-1)){
            this.legend._div.innerHTML +=
            '<span style="height: 0.8px;"><i style="background:' + this.getColor(values_[i]) + ';height: 0.8px;"></i> ' +
            values_[i] +'</span>';
          }else{
            this.legend._div.innerHTML +=
            '<span style="height: 0.8px;"><i style="background:' + this.getColor(values_[i]) + ';height: 0.8px;"></i> '+'</span>';
          }

      }
    };
    this.legend.addTo(this.map);

    this.legend.update(this.values[this.map.timeDimension.getCurrentTimeIndex()]);


    // this.map.fitBounds(this.bounds);

    this.map.timeDimension.on("timeload", (e: any) => {
      // this.legend.update(this.values[this.map.timeDimension.getCurrentTimeIndex()]);
      debugger
      this.currentTime.emit(e.time);
    });
  }

  private style = (feature: any) => {
    let color = 'rgba(255,255,255,1.0)';
    if (feature!.properties!.values !== undefined) {
      const value = feature!.properties!.values[this.map.timeDimension.getCurrentTimeIndex()];
      if(value === undefined) return;
      if(value !== 0){
        color = this.getColor(value);
      }
      else{
        return {
          weight: 1,
          opacity: 0,
          color: 'rgba(35,35,35,1.0)',
          dashArray: '',
          fillOpacity: 0,
          fillColor: color,
          width: '0%'
        };
      }
    }
    return {
      weight: 1,
      opacity: 1,
      color: 'rgba(35,35,35,1.0)',
      dashArray: '',
      fillOpacity: 1,
      fillColor: color,
      width: '100%'
    };
  }
  private getColor = (d: any) => {
    var mapScale = chroma.scale(this.mapInfo.scale)
            .classes(this.values[this.map.timeDimension.getCurrentTimeIndex()]);
    return mapScale(d).toString();
  }
  private zoomToFeature = (e: any) => {
    L.DomEvent.stopPropagation;
  }
  private highLightFeature = (e: any) => {
    var layer = e.target;
    if(layer.options.opacity !== 0){
      layer.setStyle({
        weight: 1.5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7
      });
      this.info.update(e.target.feature.properties, this.map, false);
      layer.bringToFront();
    }

  }
  private resetFeature = (e: any) => {
    var layer = e.target;
    if(layer.options.opacity !== 0){
      layer.setStyle({
        weight: 1,
        color: 'rgba(35,35,35,1.0)',
        fillOpacity: 1,
      });
      this.info.update(e.target.feature.properties, this.map, true);
      layer.bringToFront();
    }


  }
  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
      click: this.zoomToFeature
    });
    if(feature.properties.values[this.map.timeDimension.getCurrentTimeIndex()] !== 0){
      const total = feature.properties.valuesString === undefined ? 'N/A' : feature.properties.valuesString[this.map.timeDimension.getCurrentTimeIndex()];
      layer.bindTooltip(`<p style="font-weight: 700; text-align: center;">${feature.properties.NAME}</p> ${this.labelPopup}:  ${total}`).openTooltip();
      layer.on('click', (e: any) => {
        this.stopPlayer(feature);
      });
    }


  }
  public stopPlayer(feature: any) {
    if (feature.properties.values === undefined) return;
    this.player.stop();
    let model = {};
    if(this.mapInfo.json === MapJson.USA){
      model =  { name: feature.properties.NAME, id: feature.properties.id };
    }else if(this.mapInfo.json === MapJson.MEXICO){
      model = { name: feature.properties.NAME, id: this.zeroPad(feature.properties.mun_code, 3) }
    }
    this.countySelected.emit(model);
  }
  public zeroPad(num:string, places:number) { return String(num).padStart(places, '0')}

  exportToExcel = async () => {
    const MAX_ROWS_PER_EXPORT = 50000;
    debugger;
    if(this.index!=undefined){
      if(this.index.size>this.consultedIndexes.size){
this.consultedIndexes=this.index;
this.datosConsultados=this.index;
      }
    }
    if (this.consultedIndexes.size != this.data.dates.length) {
      this.spinner.show();
      const promises = [];
  
      for (let index = 0; index < this.data.dates.length; index++) {
        if (!this.consultedIndexes.has(index)) {
          let mdl = {
            numVariable: this.variable,
            StartDate: this.data.dates[index]
          };
          this.consultedIndexes.add(index);
          const promise = this.generalservice.getDataSpatialTemporalDate(mdl).toPromise().then(z => {
            this.datosConsultados.add(index);
            this.HandleDate(z, index);
          });
  
          promises.push(promise); 
        }
      }
      await Promise.all(promises);
      this.index=this.consultedIndexes;
      this.dataUpdated.emit(this.data);
      this.indexUpdate.emit(this.index);
      this.spinner.hide();
    }
    let countyData = this.data.data.flatMap((county: { name: string | number; state: string | number; values: any[]; }) => {
      return this.data.dates.map((date: string, index: number) => ({
        Year: date,  
        County: this.counties[county.name] || county.name,
        Value: county.values[index]  
      }));
    });
    countyData = countyData.sort((a: { Year: string; }, b: { Year: string; }) => a.Year.localeCompare(b.Year));
    const splitDataIntoChunks = (data: any[], chunkSize: number) => {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      return chunks;
    };
    const countyChunks = splitDataIntoChunks(countyData, MAX_ROWS_PER_EXPORT);
    countyChunks.forEach((countyChunk, index) => {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const wsCounties: XLSX.WorkSheet = XLSX.utils.json_to_sheet(countyChunk);
      XLSX.utils.book_append_sheet(wb, wsCounties, 'Counties');
      const fileName = countyChunks.length === 1
        ? `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_${Date()}.xlsx`
        : `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_Part${index + 1}_${Date()}.xlsx`;
  
      XLSX.writeFile(wb, fileName);
    });
  
    this.consultasEnProgreso.clear();
  };
  
  
}
