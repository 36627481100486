import { Component, OnInit } from '@angular/core';
import { GraphItemModel, GraphLinkModel } from 'src/app/core/models/graph.model';
import { ReferenceModel } from 'src/app/core/models/menu.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  constructor(private homeService: HomeService, private homeStore: HomeStore){

  }
  public step: number = 0;
  public threatsX = -20;
  public threatsY = -19;
  public mvmData: GraphItemModel[] = [
  { fullname: 'Researchers Incentives', name: 'Researchers\nIncentives', x: (this.threatsX + 16), y: (this.threatsY + 0), isActive: false },
  { fullname: 'Data Dredge/P-hack', name: 'Data\nDredge/P-hack', x: (this.threatsX + 17), y: (this.threatsY + 5), category: 0, isActive: false },
  { fullname: 'Bias Information Search', name: 'Bias\nInformation\nSearch', x: (this.threatsX + 21), y: (this.threatsY + 8), category: 0, isActive: false },
  { fullname: 'Citation Misdirection', name: 'Citation\nMisdirection', x: (this.threatsX + 20), y: (this.threatsY + 2), category: 0, isActive: false },
  { fullname: 'Rapid Growth of Publication Outlets', name: 'Rapid Growth\nof Publication\nOutlets', x: (this.threatsX + 18), y: (this.threatsY + 12), category: 0, isActive: false },
  { fullname: 'Selective Outcome Reporting', name: 'Selective\nOutcome\nReporting', x: 19, y: -11, category: 3, isActive: false },
  { fullname: 'Quality of Research', name: 'Quality of \nResearch', x: 22, y: -8, category: 3, isActive: false },
  { fullname: 'Peer Review Quality', name: 'Peer Review\nQuality', x: 31, y: -19, category: 4, isActive: false },
  { fullname: 'Quality of Published Research', name: 'Quality of\nPublished\nResearch', x: 35, y: -16, category: 4, isActive: false },
  { fullname: 'Quantity of Published Research', name: 'Quantity of\nPublished\nResearch', x: 33, y: -12, category: 4, isActive: false },
  { fullname: 'Hype Findings', name: 'Hype\nFindings', x: 36, y: -9, category: 6, isActive: false },
  { fullname: 'Published Research', name: 'Published\nResearch', x: 36, y: -1, category: 6, isActive: false },
  { fullname: 'False Facts', name: 'False Facts', symbol: 'rect', x: 60, y: -20, category: 7, isActive: false },
  { fullname: 'Replication Crisis', name: 'Replication\nCrisis', symbol: 'rect', x: 55, y: -20, category: 7, isActive: false },
  ]
  public mvmLinks: GraphLinkModel[] =[
    { source: 'Researchers\nIncentives' ,target: 'Selective\nOutcome\nReporting', lineStyle: { curveness: 0.1 } },
    { source: 'Researchers\nIncentives' ,target: 'Data\nDredge/P-hack', lineStyle: { curveness: 0.1 } },
    { source: 'Data\nDredge/P-hack' ,target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Bias\nInformation\nSearch' ,target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Citation\nMisdirection' ,target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Quality of \nResearch' ,target: 'Selective\nOutcome\nReporting', lineStyle: { curveness: 0.1 } },
    { source: 'Rapid Growth\nof Publication\nOutlets' ,target: 'Peer Review\nQuality', lineStyle: { curveness: 0.1 } },
    { source: 'Rapid Growth\nof Publication\nOutlets' ,target: 'Quantity of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Rapid Growth\nof Publication\nOutlets' ,target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Selective\nOutcome\nReporting' ,target: 'Quantity of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Selective\nOutcome\nReporting' ,target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Selective\nOutcome\nReporting' ,target: 'Peer Review\nQuality', lineStyle: { curveness: 0.1 } },
    { source: 'Peer Review\nQuality' ,target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Peer Review\nQuality' ,target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Quality of\nPublished\nResearch' ,target: 'Published\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Quantity of\nPublished\nResearch' ,target: 'Published\nResearch', lineStyle: { curveness: 0.1 } },
    { source: 'Published\nResearch' ,target: 'Hype\nFindings', lineStyle: { curveness: 0.1 } },
    { source: 'Hype\nFindings' ,target: 'False Facts', lineStyle: { curveness: 0.1 } },
    { source: 'Published\nResearch' ,target: 'False Facts', lineStyle: { curveness: 0.1 } },
    { source: 'Published\nResearch' ,target: 'Replication\nCrisis', lineStyle: { curveness: 0.1 } },

  ];
  public referenceData!: ReferenceModel[]

  ngOnInit(): void {
    const model = {bayesianId : this.homeStore.getSelectedSecondMenuOption()};
    this.homeService.getBayesianReferences(model).subscribe(
      {
        next: (res) => {
          this.referenceData = res;
        },
        error: (e) => { console.error(e); }
      });
  }
}
