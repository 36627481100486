<header>
  <div class="logo">
    <a class="prevent-select" href="https://r13-cbts-sgl.engr.tamu.edu/" target="_blank">
      <img src="{{ baseAssets }}/TAMU-HEADER.png" alt="tamu-header-logo">
    </a>
  </div>
  <div class="toolbar prevent-select" *ngIf="homeStore.appContextObservable | async as context">
    <button class="btn rounded-circle menu" (click)="showMenu = !showMenu">
      <fa-icon [icon]="showMenu ? faClose : faBars"></fa-icon>
    </button>
    <div class="location">
      <img src="{{baseAssets + context.section?.urlImage}}" alt="{{context.section?.name}}">
      <a routerLink="{{'/'+context.country+'/'+context.section?.url}}" (click)="showMenu = false">{{context.section?.name}}</a>
    </div>
    <div class="countries">
      <a class="btn" routerLink="{{'/'+item.id+'/'+context.section?.url+(context.detail?('/'+context.detail):'')}}"
        [ngClass]="{selected: context.country==item.id }" *ngFor="let item of context.countries" title="{{item.name}}" >
        <img src="{{COUNTRIES_TEMP[item.name]}}" alt="{{item.name}}"  [ngStyle]="item.name === 'Trinational' ? {'width': '9vw'} : {}">
        
      </a>
    </div>
    <button class="btn rounded-circle user-menu pl-2" (click)="goBack()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>
    
    <div *ngIf="isUserAuthenticated"class="header-user">
        <button class="header-user__button" mat-icon-button [matMenuTriggerFor]="menuUser">
            <fa-icon [icon]="faUser"></fa-icon>
        </button>
        <mat-menu #menuUser>
            <button mat-menu-item (click)="handleLogoutClick()"> Log out </button>
        </mat-menu>
    </div>
    <!-- <button class="btn rounded-circle user-menu" (click)="showUserMenu = !showUserMenu">
      <fa-icon [icon]="faUser"></fa-icon>
    </button> -->
  </div>
</header>
<div class="router-outlet">
  <router-outlet></router-outlet>
</div>
<div class="backdrop prevent-select" *ngIf="homeStore.appContextObservable | async as context"
  [ngClass]="{open: showMenu}">
  <div class="panel">
    <span class="menu-title">Supply Chain Menu</span>
    <div class="line"></div>
    <ul>
      <li *ngFor="let item of context.sections">
        <a routerLink="{{'/'+context.country+'/'+item.url}}" [ngClass]="{selected: context.section?.url==item.url}"
          (click)="showMenu = false">
          <img src="{{baseAssets + item.urlImageWhite}}" alt="{{item.name}}">
          <span>{{item.name}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
