<button class="dropbtn" (click)="exportToExcel()">Download map data</button>

<mat-card class="map-container" style="box-shadow: none;">
  <mat-card-header>
    <h3>
      {{title}}
    </h3>
  </mat-card-header>
  <mat-card-content id="mapState">
  </mat-card-content>
</mat-card>
