<ng-container *ngIf="data; else notFound">
  <ng-container [ngSwitch]="typeVariable">
    <ng-container *ngSwitchCase="'Time Series'">
      <app-time-visualization-template [isMVM]="isMVM" [dataRes]="data" [filtro]="filtro" [idsFilters]="idsFilters" [event]="'title'" (selectedItem)="getValue($event)"
        (firstValue)="handleFilterUpdate($event)" (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)"
        (fourthValue)="handleFilterUpdate($event)" (fifthValue)="handleFilterUpdate($event)" (sixthValue)="handleFilterUpdate($event)"
        (yAxisValue)="handleFilterUpdate($event)" (selectedYearValue)="handleFilterUpdate($event)"></app-time-visualization-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Spatial'">
      <app-static-template  [dataRes]="data" [variable]="idVariable" [idsFilters]="idsFilters" (selectedItem)="getValue($event)"
        (firstValue)="handleFilterUpdate($event)" (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)"
        (fourthValue)="handleFilterUpdate($event)" (fifthValue)="handleFilterUpdate($event)" (sixthValue)="handleFilterUpdate($event)" (yAxisValue)="handleFilterUpdate($event)"></app-static-template>    
    </ng-container>
    <ng-container *ngSwitchCase="'Spatial Temporal'">
      <app-dynamic-template *ngIf="!isMVM" [dataRes]="data" [variable]="idVariable" [idsFilters]="idsFilters" (selectedItem)="getValue($event)" (firstValue)="handleFilterUpdate($event)"
        (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)" (fourthValue)="handleFilterUpdate($event)"
        (fifthValue)="handleFilterUpdate($event)"  (sixthValue)="handleFilterUpdate($event)" (yAxisValue)="handleFilterUpdate($event)"></app-dynamic-template>
      
      <app-mvm-time-visualization-template *ngIf="isMVM" [dataRes]="data" [filtro]="filtro" [idsFilters]="idsFilters" [event]="'title'" (selectedItem)="getValue($event)"
        (firstValue)="handleFilterUpdate($event)" (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)"
        (fourthValue)="handleFilterUpdate($event)" (fifthValue)="handleFilterUpdate($event)" (sixthValue)="handleFilterUpdate($event)"
        (yAxisValue)="handleFilterUpdate($event)"></app-mvm-time-visualization-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Bar Chart'">
      <app-pie-bar-template 
              [dataRes]="data" 
              [idsFilters]="idsFilters" 
              (selectedItem)="getValue($event)"
                ></app-pie-bar-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Time Series MVM'">
      <app-mvm-time-visualization-template [dataRes]="data" [filtro]="filtro" [idsFilters]="idsFilters" [event]="'title'" (selectedItem)="getValue($event)"
        (firstValue)="handleFilterUpdate($event)" (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)"
        (fourthValue)="handleFilterUpdate($event)" (fifthValue)="handleFilterUpdate($event)" (sixthValue)="handleFilterUpdate($event)"
        (yAxisValue)="handleFilterUpdate($event)" (selectedYearValue)="handleFilterUpdate($event)"></app-mvm-time-visualization-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Spatial MVM'">
      <app-mvm-static-template [dataRes]="data" [variable]="idVariable" [idsFilters]="idsFilters" (selectedItem)="getValue($event)"
        (firstValue)="handleFilterUpdate($event)" (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)"
        (fourthValue)="handleFilterUpdate($event)" (fifthValue)="handleFilterUpdate($event)" (sixthValue)="handleFilterUpdate($event)" (yAxisValue)="handleFilterUpdate($event)"></app-mvm-static-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Spatial Temporal MVM'">
      <app-mvm-dynamic-template [dataRes]="data" [variable]="idVariable" [idsFilters]="idsFilters" (selectedItem)="getValue($event)" (firstValue)="handleFilterUpdate($event)"
        (secondValue)="handleFilterUpdate($event)" (thirdValue)="handleFilterUpdate($event)" (fourthValue)="handleFilterUpdate($event)"
        (fifthValue)="handleFilterUpdate($event)"  (sixthValue)="handleFilterUpdate($event)" (yAxisValue)="handleFilterUpdate($event)"></app-mvm-dynamic-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="this.estadocomponente">
  <app-dynamic-container [numvariable]="numVariable" [filter]="this.filterInfo" (selectedItem)="getValue($event)"></app-dynamic-container>
  </ng-container>
 
<ng-template #notFound>
  <app-not-found *ngIf="!this.estadocomponente"></app-not-found>
</ng-template>

