<div class="card half-border">
    <div [formGroup]="form">
        <div class="row">
          <div class="col-md-4">
            <span class="textMedium">Decomposition to Trend & Residual</span>
          </div>
          <div class="col-md-8">
            <span class="textMedium">Trend Time-Series</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col">
                <label for="algorithm_" class="form-label subtitle">Select an algorithm</label>
                <select class="form-select"  formControlName="algorithm_">
                  <option value="1" selected>Linear Regression</option>
                  <option value="2">LOESS (Locally Weighted Running Line Smoother)</option>
                  <option value="3">Moving Averages</option>
                  <option value="4">Simple Exponential Smoothing</option>
                  <option value="5">Spline Smoothing (Piecewise Polynomial)</option>
                  <option value="6">Polynomial Smoothing</option>
                </select>
              </div>
              
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col">
                <label for="window_size_0_1" class="form-label subtitle">
                  Window size from 0-1 <br>
                  (LOESS, Exponential)
                </label>
                <input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="window_size_0_1" ngDefaultControl matInput>
              </div>
              <div class="col">
                <label for="window_size" class="form-label subtitle">
                  Window size <br>
                  (Moving Averages)
                </label>
                <input class="form-control" type="number" min="0" formControlName="window_size" ngDefaultControl matInput>
              </div>
              <div class="col">
                <label for="degree_" class="form-label subtitle">
                  Degree (Spline Smoothing <br>
                  / Polynomial Smoothing)
                </label>
                <input class="form-control" type="number" min="0" formControlName="degree_" ngDefaultControl matInput>
              </div>
              <div class="col">
                <label for="smoothing_factor" class="form-label subtitle">
                  Smoothing Factor <br>
                  (Spline Smoothing)</label>
                <input class="form-control" type="number" min="0" formControlName="smoothing_factor" ngDefaultControl matInput>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.dataRes.hasFilter">
          <div class="row" >
            <div class="col-md-8">
              <span class="textMedium">Section of filters</span>
            </div>
          </div>
          <div class="row">        
            <div *ngIf="this.dataRes.filterType === this.filterType.oneFilter">
                
                <div *ngIf="this.dataRes.filterInfoSecond; else elseBlockFirstFilter">
                  <app-two-single-filters [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)"> </app-two-single-filters>
                </div>
                <ng-template #elseBlockFirstFilter>
                  <app-two-single-filters [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)"> </app-two-single-filters>
                </ng-template>
              
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.twoFilters">
                <app-two-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                        (firstValue)="emitFirstValue($event)"
                        (secondValue)="emitSecondValue($event)">
                </app-two-single-filters>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.threeFilters">
                  <app-three-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"
                                        (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)"></app-three-single-filters>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.fourFilters">
                  <app-four-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)" (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)" (fourthValue)="emitFourthValue($event)"></app-four-single-filter>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.fiveFilters">
                  <app-five-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)" (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)" (fourthValue)="emitFourthValue($event)"
                                        (fifthValue)="emitFifthValue($event)"></app-five-single-filter>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.sixFilters">
                  <app-six-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                          (firstValue)="emitFirstValue($event)"
                                          (secondValue)="emitSecondValue($event)" (thirdValue)="emitThirdValue($event)"
                                          (fourthValue)="emitFourthValue($event)" (fifthValue)="emitFifthValue($event)"
                                          (sixthValue)="emitSixthValue($event)"></app-six-single-filter>
              </div>

              <!--YAxis-->
              <div *ngIf="this.dataRes.filterInfoSecond!=null && this.dataRes.filterInfoSecond.yAxis!=null">
                  <app-y-axis-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                    (yAxisValue)="emitYAxisValue($event)"
                    [FilterInTrendModel]="true"></app-y-axis-filter>
              </div>

          
          </div>
        </div>
        <button type="submit" min="0" (click)="onSubmit()" class="btn btn-home">Search</button>
    </div>
</div>

