import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Optional, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Subject, firstValueFrom, BehaviorSubject } from 'rxjs';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { LineScatterComponent } from 'src/app/shared/components/plotly/line-scatter/line-scatter.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-risk-scenario',
  templateUrl: './risk-scenario.component.html',
  styleUrls: ['./risk-scenario.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RiskScenarioComponent implements OnDestroy, OnChanges, AfterViewInit {
  private subscriber: Subscription;
  selectedYear= '0';
  public idScenario = '0';
  public selection = '0';
  public hiddenGoodnesOfFit = true;
  public valueLineScatter: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  variableTypeFilter = new FormControl<string>('');
  variablesOptionFilter = new FormControl<string>('');
  baseAssets = environment.BaseAssets;

  @ViewChild(LineScatterComponent) child: LineScatterComponent | undefined;

  public valuesDataRSPlot: any;
  dataRiskScenarioPlot: any;

  filtersRaw: any;
  typesVar: any;
  filterYear: number[] = [];
  showRiskScenarioECDF = false;
  dataRSECDF = {
    title_unit: '',
    dataCoordinates: []
  };
  ecdfChart: any;
  imagePath: string | null = null;
  private typeSelectionSubject = new BehaviorSubject<string>('');
  private modelSelectionSubject = new BehaviorSubject<string>('AutoML');


  private onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private timeVisualizationService: TimeVisualizationTemplateService,
    private cdr: ChangeDetectorRef,
    private generalservice: GeneralService,
    private spinner: NgxSpinnerService,
    public homeStore: HomeStore) {
      this.typeSelectionSubject.subscribe(() => this.updateImagePath());
      this.modelSelectionSubject.subscribe(() => this.updateImagePath());
    
    this.subscriber = homeStore.appContextObservable.subscribe(async (ctx) => {
      
      let scenariosList = await homeStore.getIdScenario();

      if (scenariosList.length != 0) {
        this.idScenario = scenariosList.values[0].idScenario;
      }

      const idsce = { 
        idScenario: this.idScenario
      };

      this.filtersRaw = await firstValueFrom(this.generalservice.getFiltersRiskScenarioRS(idsce));

      if (this.filtersRaw)
      {
        this.typesVar = this.filtersRaw.values;
        this.typeSelection = this.typesVar[0].value;
       
        const datosmodel = { 
          idScenario:this.idScenario,
          selection: this.typeSelection +'¬'+ this.modelSelection
        };

        this.selection = this.typeSelection +'¬'+ this.modelSelection;

        this.dataRiskScenarioPlot = await firstValueFrom(
          this.generalservice.getRiskScenarioPlot(datosmodel)
        );

        if(this.dataRiskScenarioPlot != null) await this.getValuesTwoSeriesScatterChart();

        if(this.modelSelection == "Linear Model %2B Probabilistic Calibration"){
          const rsec = { 
            idScenario:this.idScenario,
            selection: this.typeSelection
          };

          this.filterYear = await firstValueFrom(
            this.generalservice.getFiltersRiskScenarioEcdf(rsec)
          );

          if (this.filterYear && this.filterYear.length > 0) {
            this.showRiskScenarioECDF = true;
            const rsec = { 
              idScenario: this.idScenario,
              selection: this.typeSelection,
              year: this.filterYear[0] 
            };
          
            this.dataRSECDF = await firstValueFrom(
              this.generalservice.getRiskScenarioEcdfCommun(rsec)
            );

            this.ecdfChart = {
              data: this.timeVisualizationService.getECDFRiskScenario(
                      this.dataRSECDF.dataCoordinates
                    ),
              yLabel: "ECDF",
              xLabel: this.dataRSECDF.title_unit,
            };
          } 
        } 
        else {
          this.showRiskScenarioECDF = false;
        }
      }
    });
    
  }

  set typeSelection(value: string) {
    this.typeSelectionSubject.next(value);
  }

  get typeSelection() {
    return this.typeSelectionSubject.value;
  }

  set modelSelection(value: string) {
    this.modelSelectionSubject.next(value); 
  }

  get modelSelection() {
    return this.modelSelectionSubject.value;
  }

  updateImagePath() {
    if (
      this.modelSelection === 'Linear Model + Probabilistic Calibration' ||
      this.modelSelection === 'Linear Model %2B Probabilistic Calibration'
    ) {
      this.imagePath = this.getImagePath();
    } else {
      this.imagePath = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onYearChange(event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    
    var year = selectedOption;
    const datosmodel = { 
      idScenario:this.idScenario,
      selection: this.typeSelection,
      year: year
    };

    this.generalservice.getRiskScenarioEcdfCommun(datosmodel).subscribe((response) => {
      this.dataRSECDF = {
        title_unit: response.title_unit || '',
        dataCoordinates: response.dataCoordinates || []
      };

      this.ecdfChart = {
        data: this.timeVisualizationService.getECDFRiskScenario(
                this.dataRSECDF.dataCoordinates
              ),
        yLabel: "ECDF",
        xLabel: this.dataRSECDF.title_unit,
      };

    });

    this.cdr.detectChanges();

  }

  onOptionChangeFirst (event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    this.selection=selectedOption;
    this.typeSelection=selectedOption;
    const datosmodel = { 
      idScenario:this.idScenario,
      selection: this.typeSelection +'¬'+ this.modelSelection
    };

    this.subscriber = this.homeStore.appContextObservable.subscribe(async (ctx) => {

    this.dataRiskScenarioPlot = await firstValueFrom(
      this.generalservice.getRiskScenarioPlot(datosmodel)
    );

    if(this.dataRiskScenarioPlot != null) await this.getValuesTwoSeriesScatterChart();

    if(this.modelSelection == "Linear Model %2B Probabilistic Calibration"){
      const rsec = { 
        idScenario:this.idScenario,
        selection: this.typeSelection
      };
      this.filterYear = await firstValueFrom(
        this.generalservice.getFiltersRiskScenarioEcdf(rsec)
      );

      if (this.filterYear && this.filterYear.length > 0) {
        this.showRiskScenarioECDF = true;
        const rsec = { 
          idScenario: this.idScenario,
          selection: this.typeSelection,
          year: this.filterYear[0] 
        };
      
        this.dataRSECDF = await firstValueFrom(
          this.generalservice.getRiskScenarioEcdfCommun(rsec)
        );

        this.ecdfChart = {
          data: this.timeVisualizationService.getECDFRiskScenario(
                  this.dataRSECDF.dataCoordinates
                ),
          yLabel: "ECDF",
          xLabel: this.dataRSECDF.title_unit,
        };
      } 
    }
    else {
      this.showRiskScenarioECDF = false;
    }

  });
  this.child?.updateGraph(datosmodel.idScenario, datosmodel.selection);
  }

  onModelChange(event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    
    this.hiddenGoodnesOfFit = false;
    this.modelSelection = selectedOption;

    const datosmodel = { 
      idScenario:this.idScenario,
      selection: this.typeSelection + '¬' + this.modelSelection
    };

    this.subscriber = this.homeStore.appContextObservable.subscribe(async (ctx) => {

      this.dataRiskScenarioPlot = await firstValueFrom(
        this.generalservice.getRiskScenarioPlot(datosmodel)
      );
  
      if(this.dataRiskScenarioPlot != null) await this.getValuesTwoSeriesScatterChart();

      if(this.modelSelection == "Linear Model %2B Probabilistic Calibration"){
        const rsec = { 
          idScenario:this.idScenario,
          selection: this.typeSelection
        };
        this.filterYear = await firstValueFrom(
          this.generalservice.getFiltersRiskScenarioEcdf(rsec)
        );

        if (this.filterYear && this.filterYear.length > 0) {
          this.showRiskScenarioECDF = true;
          const rsec = { 
            idScenario: this.idScenario,
            selection: this.typeSelection,
            year: this.filterYear[0] 
          };
        
          this.dataRSECDF = await firstValueFrom(
            this.generalservice.getRiskScenarioEcdfCommun(rsec)
          );

          this.ecdfChart = {
            data: this.timeVisualizationService.getECDFRiskScenario(
                    this.dataRSECDF.dataCoordinates
                  ),
            yLabel: "ECDF",
            xLabel: this.dataRSECDF.title_unit,
          };
        } 
      }
      else {
        this.showRiskScenarioECDF = false;
      }
    });

    this.child?.updateGraph(datosmodel.idScenario, datosmodel.selection);
    this.hiddenGoodnesOfFit = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    
  }
  ngOnInit() {
  }

  getValuesTwoSeriesScatterChart(){

    this.valuesDataRSPlot = {
      title: this.dataRiskScenarioPlot.title,
      yLabel: this.dataRiskScenarioPlot.yAxisTitle,
      xLabel: "Time",
      data: this.dataRiskScenarioPlot.data,
      style: {
        colorSerieObservation: "#ff0000",
        colorSeriePrediction: "#0000ff",    
        colorPointExpected: "#000000",    
      }
    };
    
  }

  getImagePath(): string {
    let folder = '';

    switch (this.homeStore.getSelectedSecondMenuOption()) {
       case 'automobile-engine':
          folder = 'AUTOMOTIVE_IMAGES';
          break;
       case 'medical-device':
          folder = 'MEDICAL_IMAGES';
          break;
       case 'power':
          folder = 'POWER_IMAGES';
          break;
       case 'transboundary-water':
          folder = 'WATER_IMAGES';
          break;
       default:
          folder = 'DEFAULT_IMAGES'; 
          break;
    }

    const imageName = `${this.typeSelection.replace(/ /g, '%20')}_prob.png`;
   
    return `${this.baseAssets}/Risk_Scenario/${folder}/${imageName}`;
 }

  emitFirstValue($event: any) {
    this.firstValue.emit($event + '1');
    this.spinner.show();
  }
}
