import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Directive({
  selector: '[ChartJsDirective]',
})
export class ChartJsDirective {
  @Input() options: any;
  myChart!: any;
  @Output() onClick = new EventEmitter<any>();
  el: ElementRef;
  constructor(el: ElementRef) {
    this.el = el;
    Chart.register(...registerables);
  }
  @HostListener('window:resize')
  onResize() {
    if (this.myChart) this.myChart.resize();
  }
  ngAfterViewInit() {
    this.myChart = new Chart(
      this.el.nativeElement,
      this.options || {
        type: 'line',
        data: [],
        options: {},
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.updateChart();
    }
  

    private updateChart(): void {
      if (this.myChart) {
        this.myChart.destroy();

        this.myChart = new Chart(
          this.el.nativeElement,
          this.options || {
            type: 'line',
            data: [],
            options: {},
          }
        );
      }
    }
  
  onChange(evt: any) {
    this.myChart.options = this.options;
    this.myChart.update();
  }
  ngOnDestroy(): void {
    this.myChart.destroy();
  }
}
