import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, firstValueFrom } from 'rxjs';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-exploratory-analysis',
  templateUrl: './exploratory-analysis.component.html',
  styleUrls: ['./exploratory-analysis.component.css']
})
export class ExploratoryAnalysisComponent {
  public idScenario = '0';
  private subscriber: Subscription;

  constructor(
    private generalservice: GeneralService,
    private spinner: NgxSpinnerService,
    public homeStore: HomeStore,
  ) {
    this.subscriber = homeStore.appContextObservable.subscribe(async (ctx) => {

      let scenariosList = await homeStore.getIdScenario();

      if (scenariosList.length != 0) {
        this.idScenario = scenariosList.values[0].idScenario;
      }
    });
    
  }

}
