<div class="card">
  <div class="card-body">
    <div class="row gap-1">
      <div class="location">{{this.data.metadata.name}}</div>
      <p>
          {{this.data.metadata.description}}
      </p>
    </div>
    
    <div class="subtitle2 pt-2">Metadata</div>
    <div class="row">
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span> {{this.data.metadata.classification == "" ? "N/A" : this.data.metadata.classification}}</li>
          <li class="list-group-item"><span class="subtitle">Type of Evidence:</span> {{this.data.metadata.evidenceType}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Spatial Resolution:</span> {{this.data.metadata.spatialResolution}}</li>
          <li class="list-group-item"><span class="subtitle">Temporal Resolution:</span> {{this.data.metadata.temporalResolution}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul>
          <li class="list-group-item"><span class="subtitle">Time Period:</span> {{this.data.metadata.timePeriod}}</li>
          <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.data.metadata.source}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

 <!-- TABS-->
 <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
      <span class="textMedium">Metadata & Visualization</span>
    </button>
  </li>
</ul>

<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card half-border">       
        <h4 style="font-weight: 700;">Section of filters:</h4>
        <div *ngIf="this.data.filterInfo !== undefined">
          <app-one-single-one-multi-filters [data]="this.data.filterInfo" 
                                        (response)="getDataFromResponse($event)"> </app-one-single-one-multi-filters>
        </div>
      </div>
    </div>
  </div>

  <div class="row card"> 
    <mat-card style="box-shadow: none;">
      <mat-card-content>
        <div id="mapHurricaneTrinational">
          <button class="btn btn-home"
          *ngIf="this.returnToOriginalLayer"
          id="refreshButton"
          (click)="setDateToMap()">Refresh layers</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>




