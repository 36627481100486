<!--
// {
//   "eventId": "44fceff9-58b9-5c1a-99ef-d4bf94940494",
//   "eventSummary": "Sihuan Pharmaceutical Holdings Group completed acquisition of Jilin Huisheng Biopharmaceutical Co. Ltd. Investors subscribed for an additional registered capital of RMB38,666,667 of Huishen Biopharaceutical. The lead investor, Baixing Bairong, is a special investment fund co-sponsored by Shanghai Angju Asset Management Co., Ltd. and other investors.Baixing Bairong will continue to help Huisheng Biopharmaceutical grow into a                biopharm pharmaceutical leader. The company's products will benefit patients around the world. We are fully optimistic about the company's future value.",
//   "eventHeadline": "Sihuan Pharmaceutical（0460.HK）: Huisheng Biopharmaceutical Successfully Completed the A + Round of Financing",
//   "eventDate": "2023-01-04",
//   "contentId": "84b3e78f-7730-51c8-8ea9-5bd3e1c7c475",
//   "contentLabel": "Pharmaceutical Company Financial Updates",
//   "topics": [
//     {
//       "topicId": "f6ffee63-825d-58e3-b508-281f7a34971b",
//       "topicName": "GROW-STRONG"
//     }
//   ]
// }
-->

<div class="wrapper" [class.last]="last">
  <div class="tags">
      <span *ngIf="article.contentLabel" class="tag">
        {{ article.contentLabel }}
      </span>
    <ng-container *ngFor="let topic of article.topics">
      <span *ngIf="topic.topicName" class="tag">
        {{ topic.topicName }}
      </span>
    </ng-container>
  </div>
  <h4 (click)="select()" class="title">{{ article.eventHeadline }}</h4>
  <div class="summary">
    {{ article.eventSummary }}
  </div>
</div>
