import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-shapefiles-template',
  templateUrl: './dynamic-shapefiles-template.component.html',
  styleUrls: ['./dynamic-shapefiles-template.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicShapefilesTemplateComponent
  implements OnDestroy, OnChanges
{
  @Input() data!: any;
  @Input() typeVar!: any;
  @Input() title: string = '';
  @Output() selectedItem = new EventEmitter<number>();
  @Input() AllowValueHover!: boolean;
  @Input() haveFilters!: any;
  @Input() filters!: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();

  public newData: any;
  public newFirstDate!: Date;
  model: any;

  private dates: string[] = [];
  private size: number = 5;
  private index: number = 0;
  valueFilter: any = '';

  onDestroy$: Subject<boolean> = new Subject();
  constructor(
    private apiService: ApiServiceService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.valueFilter !== '') {
      this.size = this.data.step;
      this.dates = JSON.parse(JSON.stringify(this.data.dates));
      this.getData();
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
  getData() {
    this.spinner.show();
    if (this.index >= this.dates.length) {
      this.spinner.hide();
      return;
    }
    const copyDates: string[] = JSON.parse(JSON.stringify(this.dates));
    const selectedDates = copyDates.splice(this.index, this.size);
    if (!selectedDates.length) {
      this.spinner.hide();
      return;
    }
    this.index += this.size;
    
    if(this.typeVar == 'Temporal w Filters'){
      this.model = {
        FirstDate: selectedDates.at(0),
        LastDate: selectedDates.at(-1),
        filter_one: this.valueFilter
      };
    } else {
      this.model = {
        FirstDate: selectedDates.at(0),
        LastDate: selectedDates.at(-1),
      };
    }
    this.apiService
      .post(this.data.shapeUrl, this.model)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (res: any) => {
          const parts = this.model.FirstDate!.split("-");
          this.newFirstDate = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
          this.newData = res;
          this.spinner.hide();
        },
        error: (e: any) => {
          console.error(e);
          this.spinner.hide();
        },
      });
  }

  openStudyDialog() {
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        filterInfo: this.data.filterInfo,
        selectedInfo: this.data.selectedInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        this.data.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }

  emitFirstValue($event: any) {
    this.valueFilter = $event;
    this.firstValue.emit($event);
    this.reloadView();
    this.spinner.show();
  }

  emitSecondValue($event: any) {
    this.secondValue.emit($event);
    this.reloadView();
    this.spinner.show();
  }

  reloadView() {
    this.cdr.detectChanges(); // Force detetection of changes
    this.spinner.hide();

  }
}
