export const HOUSING_DEMAND_VAR110 = {
  metadata: {
    name: 'New Residential Construction Report',
    description:
      'The Census Bureau produces the following key business statistics: number of establishments; number of employees; payroll; first quarter payroll; and total sales, shipments, receipts, and business done.',
    classification: 'Anthropogenic Threat',
    evidenceType: 'Observation',
    temporalResolution: 'monthly',
    timePeriod: '1960-2023',
    source: 'US Census Bureau',
    citation:
      'New Residential Construction. (2023, June 27). U.S. Census Bureau. https://www.census.gov/econ/currentdata/?programCode=RESCONST&startYear=1959&endYear=2023&categories[]=APERMITS&dataType=TOTAL&geoLevel=US&adjusted=1 Adjusted=0&errorData=0',
    node: 'Housing Demand',
    net: 'Wood',
    dashboard: 'USA',
    nameShort: 'New Residential Construction',
    timeUnit: 'month',
    isDate: true,
    yAxis: 'Thousands of Units',
    typeVariable: 'Time Series',
    multipleFilters: 0,
    multipleVariables: true,
  },
  data: [
    {
      date: '1960-01-01',
      value: 1092.0,
    },
    {
      date: '1960-02-01',
      value: 1088.0,
    },
    {
      date: '1960-03-01',
      value: 955.0,
    },
    {
      date: '1960-04-01',
      value: 1016.0,
    },
    {
      date: '1960-05-01',
      value: 1052.0,
    },
    {
      date: '1960-06-01',
      value: 958.0,
    },
    {
      date: '1960-07-01',
      value: 999.0,
    },
    {
      date: '1960-08-01',
      value: 994.0,
    },
    {
      date: '1960-09-01',
      value: 984.0,
    },
    {
      date: '1960-10-01',
      value: 972.0,
    },
    {
      date: '1960-11-01',
      value: 979.0,
    },
    {
      date: '1960-12-01',
      value: 951.0,
    },
    {
      date: '1961-01-01',
      value: 969.0,
    },
    {
      date: '1961-02-01',
      value: 961.0,
    },
    {
      date: '1961-03-01',
      value: 1000.0,
    },
    {
      date: '1961-04-01',
      value: 1002.0,
    },
    {
      date: '1961-05-01',
      value: 1027.0,
    },
    {
      date: '1961-06-01',
      value: 1070.0,
    },
    {
      date: '1961-07-01',
      value: 1083.0,
    },
    {
      date: '1961-08-01',
      value: 1159.0,
    },
    {
      date: '1961-09-01',
      value: 1098.0,
    },
    {
      date: '1961-10-01',
      value: 1123.0,
    },
    {
      date: '1961-11-01',
      value: 1152.0,
    },
    {
      date: '1961-12-01',
      value: 1161.0,
    },
    {
      date: '1962-01-01',
      value: 1122.0,
    },
    {
      date: '1962-02-01',
      value: 1194.0,
    },
    {
      date: '1962-03-01',
      value: 1134.0,
    },
    {
      date: '1962-04-01',
      value: 1235.0,
    },
    {
      date: '1962-05-01',
      value: 1142.0,
    },
    {
      date: '1962-06-01',
      value: 1154.0,
    },
    {
      date: '1962-07-01',
      value: 1189.0,
    },
    {
      date: '1962-08-01',
      value: 1200.0,
    },
    {
      date: '1962-09-01',
      value: 1223.0,
    },
    {
      date: '1962-10-01',
      value: 1181.0,
    },
    {
      date: '1962-11-01',
      value: 1236.0,
    },
    {
      date: '1962-12-01',
      value: 1236.0,
    },
    {
      date: '1963-01-01',
      value: 1248.0,
    },
    {
      date: '1963-02-01',
      value: 1212.0,
    },
    {
      date: '1963-03-01',
      value: 1258.0,
    },
    {
      date: '1963-04-01',
      value: 1288.0,
    },
    {
      date: '1963-05-01',
      value: 1350.0,
    },
    {
      date: '1963-06-01',
      value: 1345.0,
    },
    {
      date: '1963-07-01',
      value: 1321.0,
    },
    {
      date: '1963-08-01',
      value: 1310.0,
    },
    {
      date: '1963-09-01',
      value: 1413.0,
    },
    {
      date: '1963-10-01',
      value: 1414.0,
    },
    {
      date: '1963-11-01',
      value: 1357.0,
    },
    {
      date: '1963-12-01',
      value: 1423.0,
    },
    {
      date: '1964-01-01',
      value: 1296.0,
    },
    {
      date: '1964-02-01',
      value: 1442.0,
    },
    {
      date: '1964-03-01',
      value: 1313.0,
    },
    {
      date: '1964-04-01',
      value: 1264.0,
    },
    {
      date: '1964-05-01',
      value: 1299.0,
    },
    {
      date: '1964-06-01',
      value: 1280.0,
    },
    {
      date: '1964-07-01',
      value: 1304.0,
    },
    {
      date: '1964-08-01',
      value: 1306.0,
    },
    {
      date: '1964-09-01',
      value: 1265.0,
    },
    {
      date: '1964-10-01',
      value: 1230.0,
    },
    {
      date: '1964-11-01',
      value: 1254.0,
    },
    {
      date: '1964-12-01',
      value: 1164.0,
    },
    {
      date: '1965-01-01',
      value: 1264.0,
    },
    {
      date: '1965-02-01',
      value: 1185.0,
    },
    {
      date: '1965-03-01',
      value: 1211.0,
    },
    {
      date: '1965-04-01',
      value: 1162.0,
    },
    {
      date: '1965-05-01',
      value: 1207.0,
    },
    {
      date: '1965-06-01',
      value: 1241.0,
    },
    {
      date: '1965-07-01',
      value: 1237.0,
    },
    {
      date: '1965-08-01',
      value: 1249.0,
    },
    {
      date: '1965-09-01',
      value: 1227.0,
    },
    {
      date: '1965-10-01',
      value: 1279.0,
    },
    {
      date: '1965-11-01',
      value: 1306.0,
    },
    {
      date: '1965-12-01',
      value: 1315.0,
    },
    {
      date: '1966-01-01',
      value: 1325.0,
    },
    {
      date: '1966-02-01',
      value: 1159.0,
    },
    {
      date: '1966-03-01',
      value: 1234.0,
    },
    {
      date: '1966-04-01',
      value: 1145.0,
    },
    {
      date: '1966-05-01',
      value: 1078.0,
    },
    {
      date: '1966-06-01',
      value: 956.0,
    },
    {
      date: '1966-07-01',
      value: 932.0,
    },
    {
      date: '1966-08-01',
      value: 877.0,
    },
    {
      date: '1966-09-01',
      value: 774.0,
    },
    {
      date: '1966-10-01',
      value: 739.0,
    },
    {
      date: '1966-11-01',
      value: 736.0,
    },
    {
      date: '1966-12-01',
      value: 743.0,
    },
    {
      date: '1967-01-01',
      value: 995.0,
    },
    {
      date: '1967-02-01',
      value: 907.0,
    },
    {
      date: '1967-03-01',
      value: 955.0,
    },
    {
      date: '1967-04-01',
      value: 1035.0,
    },
    {
      date: '1967-05-01',
      value: 1076.0,
    },
    {
      date: '1967-06-01',
      value: 1169.0,
    },
    {
      date: '1967-07-01',
      value: 1177.0,
    },
    {
      date: '1967-08-01',
      value: 1229.0,
    },
    {
      date: '1967-09-01',
      value: 1279.0,
    },
    {
      date: '1967-10-01',
      value: 1280.0,
    },
    {
      date: '1967-11-01',
      value: 1297.0,
    },
    {
      date: '1967-12-01',
      value: 1315.0,
    },
    {
      date: '1968-01-01',
      value: 1179.0,
    },
    {
      date: '1968-02-01',
      value: 1342.0,
    },
    {
      date: '1968-03-01',
      value: 1370.0,
    },
    {
      date: '1968-04-01',
      value: 1286.0,
    },
    {
      date: '1968-05-01',
      value: 1297.0,
    },
    {
      date: '1968-06-01',
      value: 1300.0,
    },
    {
      date: '1968-07-01',
      value: 1344.0,
    },
    {
      date: '1968-08-01',
      value: 1357.0,
    },
    {
      date: '1968-09-01',
      value: 1464.0,
    },
    {
      date: '1968-10-01',
      value: 1421.0,
    },
    {
      date: '1968-11-01',
      value: 1436.0,
    },
    {
      date: '1968-12-01',
      value: 1389.0,
    },
    {
      date: '1969-01-01',
      value: 1459.0,
    },
    {
      date: '1969-02-01',
      value: 1495.0,
    },
    {
      date: '1969-03-01',
      value: 1438.0,
    },
    {
      date: '1969-04-01',
      value: 1441.0,
    },
    {
      date: '1969-05-01',
      value: 1328.0,
    },
    {
      date: '1969-06-01',
      value: 1349.0,
    },
    {
      date: '1969-07-01',
      value: 1278.0,
    },
    {
      date: '1969-08-01',
      value: 1317.0,
    },
    {
      date: '1969-09-01',
      value: 1263.0,
    },
    {
      date: '1969-10-01',
      value: 1216.0,
    },
    {
      date: '1969-11-01',
      value: 1191.0,
    },
    {
      date: '1969-12-01',
      value: 1155.0,
    },
    {
      date: '1970-01-01',
      value: 1062.0,
    },
    {
      date: '1970-02-01',
      value: 1118.0,
    },
    {
      date: '1970-03-01',
      value: 1132.0,
    },
    {
      date: '1970-04-01',
      value: 1224.0,
    },
    {
      date: '1970-05-01',
      value: 1328.0,
    },
    {
      date: '1970-06-01',
      value: 1322.0,
    },
    {
      date: '1970-07-01',
      value: 1324.0,
    },
    {
      date: '1970-08-01',
      value: 1394.0,
    },
    {
      date: '1970-09-01',
      value: 1426.0,
    },
    {
      date: '1970-10-01',
      value: 1564.0,
    },
    {
      date: '1970-11-01',
      value: 1502.0,
    },
    {
      date: '1970-12-01',
      value: 1767.0,
    },
    {
      date: '1971-01-01',
      value: 1643.0,
    },
    {
      date: '1971-02-01',
      value: 1588.0,
    },
    {
      date: '1971-03-01',
      value: 1759.0,
    },
    {
      date: '1971-04-01',
      value: 1745.0,
    },
    {
      date: '1971-05-01',
      value: 1972.0,
    },
    {
      date: '1971-06-01',
      value: 1903.0,
    },
    {
      date: '1971-07-01',
      value: 2069.0,
    },
    {
      date: '1971-08-01',
      value: 2004.0,
    },
    {
      date: '1971-09-01',
      value: 1996.0,
    },
    {
      date: '1971-10-01',
      value: 2026.0,
    },
    {
      date: '1971-11-01',
      value: 2079.0,
    },
    {
      date: '1971-12-01',
      value: 2133.0,
    },
    {
      date: '1972-01-01',
      value: 2238.0,
    },
    {
      date: '1972-02-01',
      value: 2169.0,
    },
    {
      date: '1972-03-01',
      value: 2105.0,
    },
    {
      date: '1972-04-01',
      value: 2139.0,
    },
    {
      date: '1972-05-01',
      value: 2067.0,
    },
    {
      date: '1972-06-01',
      value: 2183.0,
    },
    {
      date: '1972-07-01',
      value: 2195.0,
    },
    {
      date: '1972-08-01',
      value: 2263.0,
    },
    {
      date: '1972-09-01',
      value: 2393.0,
    },
    {
      date: '1972-10-01',
      value: 2354.0,
    },
    {
      date: '1972-11-01',
      value: 2234.0,
    },
    {
      date: '1972-12-01',
      value: 2419.0,
    },
    {
      date: '1973-01-01',
      value: 2271.0,
    },
    {
      date: '1973-02-01',
      value: 2226.0,
    },
    {
      date: '1973-03-01',
      value: 2062.0,
    },
    {
      date: '1973-04-01',
      value: 1908.0,
    },
    {
      date: '1973-05-01',
      value: 1931.0,
    },
    {
      date: '1973-06-01',
      value: 2051.0,
    },
    {
      date: '1973-07-01',
      value: 1819.0,
    },
    {
      date: '1973-08-01',
      value: 1809.0,
    },
    {
      date: '1973-09-01',
      value: 1704.0,
    },
    {
      date: '1973-10-01',
      value: 1411.0,
    },
    {
      date: '1973-11-01',
      value: 1402.0,
    },
    {
      date: '1973-12-01',
      value: 1288.0,
    },
    {
      date: '1974-01-01',
      value: 1331.0,
    },
    {
      date: '1974-02-01',
      value: 1360.0,
    },
    {
      date: '1974-03-01',
      value: 1440.0,
    },
    {
      date: '1974-04-01',
      value: 1254.0,
    },
    {
      date: '1974-05-01',
      value: 1138.0,
    },
    {
      date: '1974-06-01',
      value: 1086.0,
    },
    {
      date: '1974-07-01',
      value: 1002.0,
    },
    {
      date: '1974-08-01',
      value: 917.0,
    },
    {
      date: '1974-09-01',
      value: 840.0,
    },
    {
      date: '1974-10-01',
      value: 824.0,
    },
    {
      date: '1974-11-01',
      value: 783.0,
    },
    {
      date: '1974-12-01',
      value: 869.0,
    },
    {
      date: '1975-01-01',
      value: 726.0,
    },
    {
      date: '1975-02-01',
      value: 729.0,
    },
    {
      date: '1975-03-01',
      value: 709.0,
    },
    {
      date: '1975-04-01',
      value: 866.0,
    },
    {
      date: '1975-05-01',
      value: 914.0,
    },
    {
      date: '1975-06-01',
      value: 946.0,
    },
    {
      date: '1975-07-01',
      value: 1020.0,
    },
    {
      date: '1975-08-01',
      value: 994.0,
    },
    {
      date: '1975-09-01',
      value: 1064.0,
    },
    {
      date: '1975-10-01',
      value: 1096.0,
    },
    {
      date: '1975-11-01',
      value: 1110.0,
    },
    {
      date: '1975-12-01',
      value: 1091.0,
    },
    {
      date: '1976-01-01',
      value: 1195.0,
    },
    {
      date: '1976-02-01',
      value: 1190.0,
    },
    {
      date: '1976-03-01',
      value: 1164.0,
    },
    {
      date: '1976-04-01',
      value: 1132.0,
    },
    {
      date: '1976-05-01',
      value: 1194.0,
    },
    {
      date: '1976-06-01',
      value: 1188.0,
    },
    {
      date: '1976-07-01',
      value: 1245.0,
    },
    {
      date: '1976-08-01',
      value: 1309.0,
    },
    {
      date: '1976-09-01',
      value: 1481.0,
    },
    {
      date: '1976-10-01',
      value: 1425.0,
    },
    {
      date: '1976-11-01',
      value: 1531.0,
    },
    {
      date: '1976-12-01',
      value: 1511.0,
    },
    {
      date: '1977-01-01',
      value: 1466.0,
    },
    {
      date: '1977-02-01',
      value: 1560.0,
    },
    {
      date: '1977-03-01',
      value: 1660.0,
    },
    {
      date: '1977-04-01',
      value: 1660.0,
    },
    {
      date: '1977-05-01',
      value: 1668.0,
    },
    {
      date: '1977-06-01',
      value: 1752.0,
    },
    {
      date: '1977-07-01',
      value: 1687.0,
    },
    {
      date: '1977-08-01',
      value: 1780.0,
    },
    {
      date: '1977-09-01',
      value: 1674.0,
    },
    {
      date: '1977-10-01',
      value: 1758.0,
    },
    {
      date: '1977-11-01',
      value: 1771.0,
    },
    {
      date: '1977-12-01',
      value: 1754.0,
    },
    {
      date: '1978-01-01',
      value: 1740.0,
    },
    {
      date: '1978-02-01',
      value: 1736.0,
    },
    {
      date: '1978-03-01',
      value: 1799.0,
    },
    {
      date: '1978-04-01',
      value: 1948.0,
    },
    {
      date: '1978-05-01',
      value: 1766.0,
    },
    {
      date: '1978-06-01',
      value: 1983.0,
    },
    {
      date: '1978-07-01',
      value: 1786.0,
    },
    {
      date: '1978-08-01',
      value: 1691.0,
    },
    {
      date: '1978-09-01',
      value: 1751.0,
    },
    {
      date: '1978-10-01',
      value: 1781.0,
    },
    {
      date: '1978-11-01',
      value: 1795.0,
    },
    {
      date: '1978-12-01',
      value: 1818.0,
    },
    {
      date: '1979-01-01',
      value: 1461.0,
    },
    {
      date: '1979-02-01',
      value: 1492.0,
    },
    {
      date: '1979-03-01',
      value: 1720.0,
    },
    {
      date: '1979-04-01',
      value: 1597.0,
    },
    {
      date: '1979-05-01',
      value: 1684.0,
    },
    {
      date: '1979-06-01',
      value: 1640.0,
    },
    {
      date: '1979-07-01',
      value: 1534.0,
    },
    {
      date: '1979-08-01',
      value: 1591.0,
    },
    {
      date: '1979-09-01',
      value: 1638.0,
    },
    {
      date: '1979-10-01',
      value: 1481.0,
    },
    {
      date: '1979-11-01',
      value: 1276.0,
    },
    {
      date: '1979-12-01',
      value: 1254.0,
    },
    {
      date: '1980-01-01',
      value: 1280.0,
    },
    {
      date: '1980-02-01',
      value: 1199.0,
    },
    {
      date: '1980-03-01',
      value: 988.0,
    },
    {
      date: '1980-04-01',
      value: 808.0,
    },
    {
      date: '1980-05-01',
      value: 861.0,
    },
    {
      date: '1980-06-01',
      value: 1118.0,
    },
    {
      date: '1980-07-01',
      value: 1259.0,
    },
    {
      date: '1980-08-01',
      value: 1367.0,
    },
    {
      date: '1980-09-01',
      value: 1484.0,
    },
    {
      date: '1980-10-01',
      value: 1366.0,
    },
    {
      date: '1980-11-01',
      value: 1383.0,
    },
    {
      date: '1980-12-01',
      value: 1249.0,
    },
    {
      date: '1981-01-01',
      value: 1221.0,
    },
    {
      date: '1981-02-01',
      value: 1199.0,
    },
    {
      date: '1981-03-01',
      value: 1183.0,
    },
    {
      date: '1981-04-01',
      value: 1190.0,
    },
    {
      date: '1981-05-01',
      value: 1173.0,
    },
    {
      date: '1981-06-01',
      value: 976.0,
    },
    {
      date: '1981-07-01',
      value: 935.0,
    },
    {
      date: '1981-08-01',
      value: 889.0,
    },
    {
      date: '1981-09-01',
      value: 847.0,
    },
    {
      date: '1981-10-01',
      value: 731.0,
    },
    {
      date: '1981-11-01',
      value: 748.0,
    },
    {
      date: '1981-12-01',
      value: 796.0,
    },
    {
      date: '1982-01-01',
      value: 794.0,
    },
    {
      date: '1982-02-01',
      value: 808.0,
    },
    {
      date: '1982-03-01',
      value: 891.0,
    },
    {
      date: '1982-04-01',
      value: 888.0,
    },
    {
      date: '1982-05-01',
      value: 953.0,
    },
    {
      date: '1982-06-01',
      value: 913.0,
    },
    {
      date: '1982-07-01',
      value: 1044.0,
    },
    {
      date: '1982-08-01',
      value: 926.0,
    },
    {
      date: '1982-09-01',
      value: 1042.0,
    },
    {
      date: '1982-10-01',
      value: 1149.0,
    },
    {
      date: '1982-11-01',
      value: 1229.0,
    },
    {
      date: '1982-12-01',
      value: 1351.0,
    },
    {
      date: '1983-01-01',
      value: 1426.0,
    },
    {
      date: '1983-02-01',
      value: 1471.0,
    },
    {
      date: '1983-03-01',
      value: 1475.0,
    },
    {
      date: '1983-04-01',
      value: 1566.0,
    },
    {
      date: '1983-05-01',
      value: 1669.0,
    },
    {
      date: '1983-06-01',
      value: 1769.0,
    },
    {
      date: '1983-07-01',
      value: 1795.0,
    },
    {
      date: '1983-08-01',
      value: 1713.0,
    },
    {
      date: '1983-09-01',
      value: 1585.0,
    },
    {
      date: '1983-10-01',
      value: 1716.0,
    },
    {
      date: '1983-11-01',
      value: 1668.0,
    },
    {
      date: '1983-12-01',
      value: 1627.0,
    },
    {
      date: '1984-01-01',
      value: 1816.0,
    },
    {
      date: '1984-02-01',
      value: 1987.0,
    },
    {
      date: '1984-03-01',
      value: 1725.0,
    },
    {
      date: '1984-04-01',
      value: 1776.0,
    },
    {
      date: '1984-05-01',
      value: 1741.0,
    },
    {
      date: '1984-06-01',
      value: 1814.0,
    },
    {
      date: '1984-07-01',
      value: 1605.0,
    },
    {
      date: '1984-08-01',
      value: 1530.0,
    },
    {
      date: '1984-09-01',
      value: 1523.0,
    },
    {
      date: '1984-10-01',
      value: 1490.0,
    },
    {
      date: '1984-11-01',
      value: 1643.0,
    },
    {
      date: '1984-12-01',
      value: 1626.0,
    },
    {
      date: '1985-01-01',
      value: 1660.0,
    },
    {
      date: '1985-02-01',
      value: 1662.0,
    },
    {
      date: '1985-03-01',
      value: 1727.0,
    },
    {
      date: '1985-04-01',
      value: 1664.0,
    },
    {
      date: '1985-05-01',
      value: 1709.0,
    },
    {
      date: '1985-06-01',
      value: 1716.0,
    },
    {
      date: '1985-07-01',
      value: 1697.0,
    },
    {
      date: '1985-08-01',
      value: 1808.0,
    },
    {
      date: '1985-09-01',
      value: 1916.0,
    },
    {
      date: '1985-10-01',
      value: 1743.0,
    },
    {
      date: '1985-11-01',
      value: 1692.0,
    },
    {
      date: '1985-12-01',
      value: 1794.0,
    },
    {
      date: '1986-01-01',
      value: 1847.0,
    },
    {
      date: '1986-02-01',
      value: 1767.0,
    },
    {
      date: '1986-03-01',
      value: 1780.0,
    },
    {
      date: '1986-04-01',
      value: 1858.0,
    },
    {
      date: '1986-05-01',
      value: 1797.0,
    },
    {
      date: '1986-06-01',
      value: 1790.0,
    },
    {
      date: '1986-07-01',
      value: 1780.0,
    },
    {
      date: '1986-08-01',
      value: 1726.0,
    },
    {
      date: '1986-09-01',
      value: 1686.0,
    },
    {
      date: '1986-10-01',
      value: 1675.0,
    },
    {
      date: '1986-11-01',
      value: 1644.0,
    },
    {
      date: '1986-12-01',
      value: 1903.0,
    },
    {
      date: '1987-01-01',
      value: 1690.0,
    },
    {
      date: '1987-02-01',
      value: 1689.0,
    },
    {
      date: '1987-03-01',
      value: 1704.0,
    },
    {
      date: '1987-04-01',
      value: 1601.0,
    },
    {
      date: '1987-05-01',
      value: 1500.0,
    },
    {
      date: '1987-06-01',
      value: 1522.0,
    },
    {
      date: '1987-07-01',
      value: 1516.0,
    },
    {
      date: '1987-08-01',
      value: 1511.0,
    },
    {
      date: '1987-09-01',
      value: 1514.0,
    },
    {
      date: '1987-10-01',
      value: 1447.0,
    },
    {
      date: '1987-11-01',
      value: 1457.0,
    },
    {
      date: '1987-12-01',
      value: 1345.0,
    },
    {
      date: '1988-01-01',
      value: 1244.0,
    },
    {
      date: '1988-02-01',
      value: 1438.0,
    },
    {
      date: '1988-03-01',
      value: 1525.0,
    },
    {
      date: '1988-04-01',
      value: 1429.0,
    },
    {
      date: '1988-05-01',
      value: 1444.0,
    },
    {
      date: '1988-06-01',
      value: 1485.0,
    },
    {
      date: '1988-07-01',
      value: 1439.0,
    },
    {
      date: '1988-08-01',
      value: 1460.0,
    },
    {
      date: '1988-09-01',
      value: 1436.0,
    },
    {
      date: '1988-10-01',
      value: 1516.0,
    },
    {
      date: '1988-11-01',
      value: 1508.0,
    },
    {
      date: '1988-12-01',
      value: 1501.0,
    },
    {
      date: '1989-01-01',
      value: 1466.0,
    },
    {
      date: '1989-02-01',
      value: 1383.0,
    },
    {
      date: '1989-03-01',
      value: 1214.0,
    },
    {
      date: '1989-04-01',
      value: 1376.0,
    },
    {
      date: '1989-05-01',
      value: 1381.0,
    },
    {
      date: '1989-06-01',
      value: 1322.0,
    },
    {
      date: '1989-07-01',
      value: 1283.0,
    },
    {
      date: '1989-08-01',
      value: 1334.0,
    },
    {
      date: '1989-09-01',
      value: 1314.0,
    },
    {
      date: '1989-10-01',
      value: 1365.0,
    },
    {
      date: '1989-11-01',
      value: 1344.0,
    },
    {
      date: '1989-12-01',
      value: 1422.0,
    },
    {
      date: '1990-01-01',
      value: 1748.0,
    },
    {
      date: '1990-02-01',
      value: 1329.0,
    },
    {
      date: '1990-03-01',
      value: 1246.0,
    },
    {
      date: '1990-04-01',
      value: 1136.0,
    },
    {
      date: '1990-05-01',
      value: 1067.0,
    },
    {
      date: '1990-06-01',
      value: 1108.0,
    },
    {
      date: '1990-07-01',
      value: 1078.0,
    },
    {
      date: '1990-08-01',
      value: 1069.0,
    },
    {
      date: '1990-09-01',
      value: 976.0,
    },
    {
      date: '1990-10-01',
      value: 925.0,
    },
    {
      date: '1990-11-01',
      value: 941.0,
    },
    {
      date: '1990-12-01',
      value: 861.0,
    },
    {
      date: '1991-01-01',
      value: 786.0,
    },
    {
      date: '1991-02-01',
      value: 853.0,
    },
    {
      date: '1991-03-01',
      value: 911.0,
    },
    {
      date: '1991-04-01',
      value: 916.0,
    },
    {
      date: '1991-05-01',
      value: 991.0,
    },
    {
      date: '1991-06-01',
      value: 964.0,
    },
    {
      date: '1991-07-01',
      value: 973.0,
    },
    {
      date: '1991-08-01',
      value: 944.0,
    },
    {
      date: '1991-09-01',
      value: 974.0,
    },
    {
      date: '1991-10-01',
      value: 991.0,
    },
    {
      date: '1991-11-01',
      value: 984.0,
    },
    {
      date: '1991-12-01',
      value: 1061.0,
    },
    {
      date: '1992-01-01',
      value: 1077.0,
    },
    {
      date: '1992-02-01',
      value: 1146.0,
    },
    {
      date: '1992-03-01',
      value: 1082.0,
    },
    {
      date: '1992-04-01',
      value: 1054.0,
    },
    {
      date: '1992-05-01',
      value: 1056.0,
    },
    {
      date: '1992-06-01',
      value: 1057.0,
    },
    {
      date: '1992-07-01',
      value: 1089.0,
    },
    {
      date: '1992-08-01',
      value: 1075.0,
    },
    {
      date: '1992-09-01',
      value: 1114.0,
    },
    {
      date: '1992-10-01',
      value: 1132.0,
    },
    {
      date: '1992-11-01',
      value: 1118.0,
    },
    {
      date: '1992-12-01',
      value: 1176.0,
    },
    {
      date: '1993-01-01',
      value: 1177.0,
    },
    {
      date: '1993-02-01',
      value: 1148.0,
    },
    {
      date: '1993-03-01',
      value: 1056.0,
    },
    {
      date: '1993-04-01',
      value: 1104.0,
    },
    {
      date: '1993-05-01',
      value: 1112.0,
    },
    {
      date: '1993-06-01',
      value: 1130.0,
    },
    {
      date: '1993-07-01',
      value: 1174.0,
    },
    {
      date: '1993-08-01',
      value: 1230.0,
    },
    {
      date: '1993-09-01',
      value: 1251.0,
    },
    {
      date: '1993-10-01',
      value: 1287.0,
    },
    {
      date: '1993-11-01',
      value: 1357.0,
    },
    {
      date: '1993-12-01',
      value: 1461.0,
    },
    {
      date: '1994-01-01',
      value: 1390.0,
    },
    {
      date: '1994-02-01',
      value: 1269.0,
    },
    {
      date: '1994-03-01',
      value: 1342.0,
    },
    {
      date: '1994-04-01',
      value: 1392.0,
    },
    {
      date: '1994-05-01',
      value: 1396.0,
    },
    {
      date: '1994-06-01',
      value: 1357.0,
    },
    {
      date: '1994-07-01',
      value: 1335.0,
    },
    {
      date: '1994-08-01',
      value: 1377.0,
    },
    {
      date: '1994-09-01',
      value: 1412.0,
    },
    {
      date: '1994-10-01',
      value: 1397.0,
    },
    {
      date: '1994-11-01',
      value: 1340.0,
    },
    {
      date: '1994-12-01',
      value: 1396.0,
    },
    {
      date: '1995-01-01',
      value: 1282.0,
    },
    {
      date: '1995-02-01',
      value: 1254.0,
    },
    {
      date: '1995-03-01',
      value: 1226.0,
    },
    {
      date: '1995-04-01',
      value: 1259.0,
    },
    {
      date: '1995-05-01',
      value: 1271.0,
    },
    {
      date: '1995-06-01',
      value: 1305.0,
    },
    {
      date: '1995-07-01',
      value: 1354.0,
    },
    {
      date: '1995-08-01',
      value: 1386.0,
    },
    {
      date: '1995-09-01',
      value: 1421.0,
    },
    {
      date: '1995-10-01',
      value: 1400.0,
    },
    {
      date: '1995-11-01',
      value: 1430.0,
    },
    {
      date: '1995-12-01',
      value: 1442.0,
    },
    {
      date: '1996-01-01',
      value: 1387.0,
    },
    {
      date: '1996-02-01',
      value: 1420.0,
    },
    {
      date: '1996-03-01',
      value: 1437.0,
    },
    {
      date: '1996-04-01',
      value: 1463.0,
    },
    {
      date: '1996-05-01',
      value: 1457.0,
    },
    {
      date: '1996-06-01',
      value: 1429.0,
    },
    {
      date: '1996-07-01',
      value: 1450.0,
    },
    {
      date: '1996-08-01',
      value: 1413.0,
    },
    {
      date: '1996-09-01',
      value: 1392.0,
    },
    {
      date: '1996-10-01',
      value: 1358.0,
    },
    {
      date: '1996-11-01',
      value: 1412.0,
    },
    {
      date: '1996-12-01',
      value: 1411.0,
    },
    {
      date: '1997-01-01',
      value: 1382.0,
    },
    {
      date: '1997-02-01',
      value: 1445.0,
    },
    {
      date: '1997-03-01',
      value: 1436.0,
    },
    {
      date: '1997-04-01',
      value: 1421.0,
    },
    {
      date: '1997-05-01',
      value: 1414.0,
    },
    {
      date: '1997-06-01',
      value: 1402.0,
    },
    {
      date: '1997-07-01',
      value: 1440.0,
    },
    {
      date: '1997-08-01',
      value: 1449.0,
    },
    {
      date: '1997-09-01',
      value: 1494.0,
    },
    {
      date: '1997-10-01',
      value: 1499.0,
    },
    {
      date: '1997-11-01',
      value: 1469.0,
    },
    {
      date: '1997-12-01',
      value: 1456.0,
    },
    {
      date: '1998-01-01',
      value: 1555.0,
    },
    {
      date: '1998-02-01',
      value: 1647.0,
    },
    {
      date: '1998-03-01',
      value: 1605.0,
    },
    {
      date: '1998-04-01',
      value: 1547.0,
    },
    {
      date: '1998-05-01',
      value: 1554.0,
    },
    {
      date: '1998-06-01',
      value: 1551.0,
    },
    {
      date: '1998-07-01',
      value: 1610.0,
    },
    {
      date: '1998-08-01',
      value: 1654.0,
    },
    {
      date: '1998-09-01',
      value: 1577.0,
    },
    {
      date: '1998-10-01',
      value: 1719.0,
    },
    {
      date: '1998-11-01',
      value: 1672.0,
    },
    {
      date: '1998-12-01',
      value: 1742.0,
    },
    {
      date: '1999-01-01',
      value: 1732.0,
    },
    {
      date: '1999-02-01',
      value: 1720.0,
    },
    {
      date: '1999-03-01',
      value: 1665.0,
    },
    {
      date: '1999-04-01',
      value: 1600.0,
    },
    {
      date: '1999-05-01',
      value: 1640.0,
    },
    {
      date: '1999-06-01',
      value: 1702.0,
    },
    {
      date: '1999-07-01',
      value: 1682.0,
    },
    {
      date: '1999-08-01',
      value: 1671.0,
    },
    {
      date: '1999-09-01',
      value: 1551.0,
    },
    {
      date: '1999-10-01',
      value: 1649.0,
    },
    {
      date: '1999-11-01',
      value: 1672.0,
    },
    {
      date: '1999-12-01',
      value: 1683.0,
    },
    {
      date: '2000-01-01',
      value: 1727.0,
    },
    {
      date: '2000-02-01',
      value: 1692.0,
    },
    {
      date: '2000-03-01',
      value: 1651.0,
    },
    {
      date: '2000-04-01',
      value: 1597.0,
    },
    {
      date: '2000-05-01',
      value: 1543.0,
    },
    {
      date: '2000-06-01',
      value: 1572.0,
    },
    {
      date: '2000-07-01',
      value: 1542.0,
    },
    {
      date: '2000-08-01',
      value: 1552.0,
    },
    {
      date: '2000-09-01',
      value: 1570.0,
    },
    {
      date: '2000-10-01',
      value: 1577.0,
    },
    {
      date: '2000-11-01',
      value: 1614.0,
    },
    {
      date: '2000-12-01',
      value: 1543.0,
    },
    {
      date: '2001-01-01',
      value: 1699.0,
    },
    {
      date: '2001-02-01',
      value: 1656.0,
    },
    {
      date: '2001-03-01',
      value: 1659.0,
    },
    {
      date: '2001-04-01',
      value: 1666.0,
    },
    {
      date: '2001-05-01',
      value: 1665.0,
    },
    {
      date: '2001-06-01',
      value: 1626.0,
    },
    {
      date: '2001-07-01',
      value: 1598.0,
    },
    {
      date: '2001-08-01',
      value: 1615.0,
    },
    {
      date: '2001-09-01',
      value: 1565.0,
    },
    {
      date: '2001-10-01',
      value: 1566.0,
    },
    {
      date: '2001-11-01',
      value: 1651.0,
    },
    {
      date: '2001-12-01',
      value: 1680.0,
    },
    {
      date: '2002-01-01',
      value: 1665.0,
    },
    {
      date: '2002-02-01',
      value: 1787.0,
    },
    {
      date: '2002-03-01',
      value: 1691.0,
    },
    {
      date: '2002-04-01',
      value: 1669.0,
    },
    {
      date: '2002-05-01',
      value: 1716.0,
    },
    {
      date: '2002-06-01',
      value: 1758.0,
    },
    {
      date: '2002-07-01',
      value: 1738.0,
    },
    {
      date: '2002-08-01',
      value: 1695.0,
    },
    {
      date: '2002-09-01',
      value: 1803.0,
    },
    {
      date: '2002-10-01',
      value: 1799.0,
    },
    {
      date: '2002-11-01',
      value: 1771.0,
    },
    {
      date: '2002-12-01',
      value: 1896.0,
    },
    {
      date: '2003-01-01',
      value: 1808.0,
    },
    {
      date: '2003-02-01',
      value: 1854.0,
    },
    {
      date: '2003-03-01',
      value: 1757.0,
    },
    {
      date: '2003-04-01',
      value: 1803.0,
    },
    {
      date: '2003-05-01',
      value: 1835.0,
    },
    {
      date: '2003-06-01',
      value: 1875.0,
    },
    {
      date: '2003-07-01',
      value: 1885.0,
    },
    {
      date: '2003-08-01',
      value: 1966.0,
    },
    {
      date: '2003-09-01',
      value: 1961.0,
    },
    {
      date: '2003-10-01',
      value: 2012.0,
    },
    {
      date: '2003-11-01',
      value: 1918.0,
    },
    {
      date: '2003-12-01',
      value: 1987.0,
    },
    {
      date: '2004-01-01',
      value: 1952.0,
    },
    {
      date: '2004-02-01',
      value: 1966.0,
    },
    {
      date: '2004-03-01',
      value: 2066.0,
    },
    {
      date: '2004-04-01',
      value: 2070.0,
    },
    {
      date: '2004-05-01',
      value: 2150.0,
    },
    {
      date: '2004-06-01',
      value: 2020.0,
    },
    {
      date: '2004-07-01',
      value: 2112.0,
    },
    {
      date: '2004-08-01',
      value: 2056.0,
    },
    {
      date: '2004-09-01',
      value: 2041.0,
    },
    {
      date: '2004-10-01',
      value: 2097.0,
    },
    {
      date: '2004-11-01',
      value: 2079.0,
    },
    {
      date: '2004-12-01',
      value: 2082.0,
    },
    {
      date: '2005-01-01',
      value: 2139.0,
    },
    {
      date: '2005-02-01',
      value: 2114.0,
    },
    {
      date: '2005-03-01',
      value: 2062.0,
    },
    {
      date: '2005-04-01',
      value: 2150.0,
    },
    {
      date: '2005-05-01',
      value: 2085.0,
    },
    {
      date: '2005-06-01',
      value: 2178.0,
    },
    {
      date: '2005-07-01',
      value: 2203.0,
    },
    {
      date: '2005-08-01',
      value: 2219.0,
    },
    {
      date: '2005-09-01',
      value: 2263.0,
    },
    {
      date: '2005-10-01',
      value: 2170.0,
    },
    {
      date: '2005-11-01',
      value: 2218.0,
    },
    {
      date: '2005-12-01',
      value: 2120.0,
    },
    {
      date: '2006-01-01',
      value: 2212.0,
    },
    {
      date: '2006-02-01',
      value: 2141.0,
    },
    {
      date: '2006-03-01',
      value: 2118.0,
    },
    {
      date: '2006-04-01',
      value: 1998.0,
    },
    {
      date: '2006-05-01',
      value: 1905.0,
    },
    {
      date: '2006-06-01',
      value: 1867.0,
    },
    {
      date: '2006-07-01',
      value: 1763.0,
    },
    {
      date: '2006-08-01',
      value: 1722.0,
    },
    {
      date: '2006-09-01',
      value: 1655.0,
    },
    {
      date: '2006-10-01',
      value: 1570.0,
    },
    {
      date: '2006-11-01',
      value: 1535.0,
    },
    {
      date: '2006-12-01',
      value: 1638.0,
    },
    {
      date: '2007-01-01',
      value: 1626.0,
    },
    {
      date: '2007-02-01',
      value: 1598.0,
    },
    {
      date: '2007-03-01',
      value: 1596.0,
    },
    {
      date: '2007-04-01',
      value: 1470.0,
    },
    {
      date: '2007-05-01',
      value: 1493.0,
    },
    {
      date: '2007-06-01',
      value: 1407.0,
    },
    {
      date: '2007-07-01',
      value: 1361.0,
    },
    {
      date: '2007-08-01',
      value: 1321.0,
    },
    {
      date: '2007-09-01',
      value: 1261.0,
    },
    {
      date: '2007-10-01',
      value: 1192.0,
    },
    {
      date: '2007-11-01',
      value: 1224.0,
    },
    {
      date: '2007-12-01',
      value: 1149.0,
    },
    {
      date: '2008-01-01',
      value: 1094.0,
    },
    {
      date: '2008-02-01',
      value: 1014.0,
    },
    {
      date: '2008-03-01',
      value: 967.0,
    },
    {
      date: '2008-04-01',
      value: 1008.0,
    },
    {
      date: '2008-05-01',
      value: 995.0,
    },
    {
      date: '2008-06-01',
      value: 1180.0,
    },
    {
      date: '2008-07-01',
      value: 921.0,
    },
    {
      date: '2008-08-01',
      value: 858.0,
    },
    {
      date: '2008-09-01',
      value: 797.0,
    },
    {
      date: '2008-10-01',
      value: 736.0,
    },
    {
      date: '2008-11-01',
      value: 626.0,
    },
    {
      date: '2008-12-01',
      value: 554.0,
    },
    {
      date: '2009-01-01',
      value: 545.0,
    },
    {
      date: '2009-02-01',
      value: 558.0,
    },
    {
      date: '2009-03-01',
      value: 513.0,
    },
    {
      date: '2009-04-01',
      value: 521.0,
    },
    {
      date: '2009-05-01',
      value: 556.0,
    },
    {
      date: '2009-06-01',
      value: 601.0,
    },
    {
      date: '2009-07-01',
      value: 595.0,
    },
    {
      date: '2009-08-01',
      value: 616.0,
    },
    {
      date: '2009-09-01',
      value: 609.0,
    },
    {
      date: '2009-10-01',
      value: 583.0,
    },
    {
      date: '2009-11-01',
      value: 623.0,
    },
    {
      date: '2009-12-01',
      value: 664.0,
    },
    {
      date: '2010-01-01',
      value: 636.0,
    },
    {
      date: '2010-02-01',
      value: 650.0,
    },
    {
      date: '2010-03-01',
      value: 687.0,
    },
    {
      date: '2010-04-01',
      value: 637.0,
    },
    {
      date: '2010-05-01',
      value: 575.0,
    },
    {
      date: '2010-06-01',
      value: 587.0,
    },
    {
      date: '2010-07-01',
      value: 579.0,
    },
    {
      date: '2010-08-01',
      value: 580.0,
    },
    {
      date: '2010-09-01',
      value: 563.0,
    },
    {
      date: '2010-10-01',
      value: 558.0,
    },
    {
      date: '2010-11-01',
      value: 560.0,
    },
    {
      date: '2010-12-01',
      value: 632.0,
    },
    {
      date: '2011-01-01',
      value: 576.0,
    },
    {
      date: '2011-02-01',
      value: 542.0,
    },
    {
      date: '2011-03-01',
      value: 583.0,
    },
    {
      date: '2011-04-01',
      value: 581.0,
    },
    {
      date: '2011-05-01',
      value: 618.0,
    },
    {
      date: '2011-06-01',
      value: 636.0,
    },
    {
      date: '2011-07-01',
      value: 621.0,
    },
    {
      date: '2011-08-01',
      value: 647.0,
    },
    {
      date: '2011-09-01',
      value: 610.0,
    },
    {
      date: '2011-10-01',
      value: 671.0,
    },
    {
      date: '2011-11-01',
      value: 706.0,
    },
    {
      date: '2011-12-01',
      value: 697.0,
    },
    {
      date: '2012-01-01',
      value: 712.0,
    },
    {
      date: '2012-02-01',
      value: 738.0,
    },
    {
      date: '2012-03-01',
      value: 806.0,
    },
    {
      date: '2012-04-01',
      value: 732.0,
    },
    {
      date: '2012-05-01',
      value: 796.0,
    },
    {
      date: '2012-06-01',
      value: 794.0,
    },
    {
      date: '2012-07-01',
      value: 849.0,
    },
    {
      date: '2012-08-01',
      value: 840.0,
    },
    {
      date: '2012-09-01',
      value: 930.0,
    },
    {
      date: '2012-10-01',
      value: 887.0,
    },
    {
      date: '2012-11-01',
      value: 917.0,
    },
    {
      date: '2012-12-01',
      value: 941.0,
    },
    {
      date: '2013-01-01',
      value: 940.0,
    },
    {
      date: '2013-02-01',
      value: 980.0,
    },
    {
      date: '2013-03-01',
      value: 936.0,
    },
    {
      date: '2013-04-01',
      value: 1012.0,
    },
    {
      date: '2013-05-01',
      value: 1003.0,
    },
    {
      date: '2013-06-01',
      value: 942.0,
    },
    {
      date: '2013-07-01',
      value: 997.0,
    },
    {
      date: '2013-08-01',
      value: 964.0,
    },
    {
      date: '2013-09-01',
      value: 1004.0,
    },
    {
      date: '2013-10-01',
      value: 1044.0,
    },
    {
      date: '2013-11-01',
      value: 1029.0,
    },
    {
      date: '2013-12-01',
      value: 1005.0,
    },
    {
      date: '2014-01-01',
      value: 976.0,
    },
    {
      date: '2014-02-01',
      value: 1039.0,
    },
    {
      date: '2014-03-01',
      value: 1067.0,
    },
    {
      date: '2014-04-01',
      value: 1090.0,
    },
    {
      date: '2014-05-01',
      value: 1018.0,
    },
    {
      date: '2014-06-01',
      value: 1010.0,
    },
    {
      date: '2014-07-01',
      value: 1076.0,
    },
    {
      date: '2014-08-01',
      value: 1047.0,
    },
    {
      date: '2014-09-01',
      value: 1077.0,
    },
    {
      date: '2014-10-01',
      value: 1094.0,
    },
    {
      date: '2014-11-01',
      value: 1059.0,
    },
    {
      date: '2014-12-01',
      value: 1072.0,
    },
    {
      date: '2015-01-01',
      value: 1049.0,
    },
    {
      date: '2015-02-01',
      value: 1127.0,
    },
    {
      date: '2015-03-01',
      value: 1072.0,
    },
    {
      date: '2015-04-01',
      value: 1166.0,
    },
    {
      date: '2015-05-01',
      value: 1272.0,
    },
    {
      date: '2015-06-01',
      value: 1379.0,
    },
    {
      date: '2015-07-01',
      value: 1140.0,
    },
    {
      date: '2015-08-01',
      value: 1165.0,
    },
    {
      date: '2015-09-01',
      value: 1144.0,
    },
    {
      date: '2015-10-01',
      value: 1160.0,
    },
    {
      date: '2015-11-01',
      value: 1244.0,
    },
    {
      date: '2015-12-01',
      value: 1211.0,
    },
    {
      date: '2016-01-01',
      value: 1171.0,
    },
    {
      date: '2016-02-01',
      value: 1172.0,
    },
    {
      date: '2016-03-01',
      value: 1118.0,
    },
    {
      date: '2016-04-01',
      value: 1160.0,
    },
    {
      date: '2016-05-01',
      value: 1205.0,
    },
    {
      date: '2016-06-01',
      value: 1208.0,
    },
    {
      date: '2016-07-01',
      value: 1198.0,
    },
    {
      date: '2016-08-01',
      value: 1201.0,
    },
    {
      date: '2016-09-01',
      value: 1303.0,
    },
    {
      date: '2016-10-01',
      value: 1254.0,
    },
    {
      date: '2016-11-01',
      value: 1230.0,
    },
    {
      date: '2016-12-01',
      value: 1248.0,
    },
    {
      date: '2017-01-01',
      value: 1296.0,
    },
    {
      date: '2017-02-01',
      value: 1237.0,
    },
    {
      date: '2017-03-01',
      value: 1273.0,
    },
    {
      date: '2017-04-01',
      value: 1239.0,
    },
    {
      date: '2017-05-01',
      value: 1205.0,
    },
    {
      date: '2017-06-01',
      value: 1353.0,
    },
    {
      date: '2017-07-01',
      value: 1254.0,
    },
    {
      date: '2017-08-01',
      value: 1336.0,
    },
    {
      date: '2017-09-01',
      value: 1277.0,
    },
    {
      date: '2017-10-01',
      value: 1360.0,
    },
    {
      date: '2017-11-01',
      value: 1295.0,
    },
    {
      date: '2017-12-01',
      value: 1312.0,
    },
    {
      date: '2018-01-01',
      value: 1311.0,
    },
    {
      date: '2018-02-01',
      value: 1331.0,
    },
    {
      date: '2018-03-01',
      value: 1381.0,
    },
    {
      date: '2018-04-01',
      value: 1365.0,
    },
    {
      date: '2018-05-01',
      value: 1331.0,
    },
    {
      date: '2018-06-01',
      value: 1338.0,
    },
    {
      date: '2018-07-01',
      value: 1333.0,
    },
    {
      date: '2018-08-01',
      value: 1293.0,
    },
    {
      date: '2018-09-01',
      value: 1320.0,
    },
    {
      date: '2018-10-01',
      value: 1280.0,
    },
    {
      date: '2018-11-01',
      value: 1334.0,
    },
    {
      date: '2018-12-01',
      value: 1320.0,
    },
    {
      date: '2019-01-01',
      value: 1254.0,
    },
    {
      date: '2019-02-01',
      value: 1302.0,
    },
    {
      date: '2019-03-01',
      value: 1291.0,
    },
    {
      date: '2019-04-01',
      value: 1298.0,
    },
    {
      date: '2019-05-01',
      value: 1338.0,
    },
    {
      date: '2019-06-01',
      value: 1301.0,
    },
    {
      date: '2019-07-01',
      value: 1347.0,
    },
    {
      date: '2019-08-01',
      value: 1528.0,
    },
    {
      date: '2019-09-01',
      value: 1491.0,
    },
    {
      date: '2019-10-01',
      value: 1538.0,
    },
    {
      date: '2019-11-01',
      value: 1522.0,
    },
    {
      date: '2019-12-01',
      value: 1461.0,
    },
    {
      date: '2020-01-01',
      value: 1500.0,
    },
    {
      date: '2020-02-01',
      value: 1440.0,
    },
    {
      date: '2020-03-01',
      value: 1348.0,
    },
    {
      date: '2020-04-01',
      value: 1050.0,
    },
    {
      date: '2020-05-01',
      value: 1253.0,
    },
    {
      date: '2020-06-01',
      value: 1329.0,
    },
    {
      date: '2020-07-01',
      value: 1526.0,
    },
    {
      date: '2020-08-01',
      value: 1564.0,
    },
    {
      date: '2020-09-01',
      value: 1643.0,
    },
    {
      date: '2020-10-01',
      value: 1622.0,
    },
    {
      date: '2020-11-01',
      value: 1713.0,
    },
    {
      date: '2020-12-01',
      value: 1760.0,
    },
    {
      date: '2021-01-01',
      value: 1866.0,
    },
    {
      date: '2021-02-01',
      value: 1700.0,
    },
    {
      date: '2021-03-01',
      value: 1731.0,
    },
    {
      date: '2021-04-01',
      value: 1714.0,
    },
    {
      date: '2021-05-01',
      value: 1680.0,
    },
    {
      date: '2021-06-01',
      value: 1644.0,
    },
    {
      date: '2021-07-01',
      value: 1618.0,
    },
    {
      date: '2021-08-01',
      value: 1837.0,
    },
    {
      date: '2021-09-01',
      value: 1636.0,
    },
    {
      date: '2021-10-01',
      value: 1727.0,
    },
    {
      date: '2021-11-01',
      value: 1775.0,
    },
    {
      date: '2021-12-01',
      value: 1948.0,
    },
    {
      date: '2022-01-01',
      value: 1898.0,
    },
    {
      date: '2022-02-01',
      value: 1817.0,
    },
    {
      date: '2022-03-01',
      value: 1877.0,
    },
    {
      date: '2022-04-01',
      value: 1795.0,
    },
    {
      date: '2022-05-01',
      value: 1708.0,
    },
    {
      date: '2022-06-01',
      value: 1701.0,
    },
    {
      date: '2022-07-01',
      value: 1658.0,
    },
    {
      date: '2022-08-01',
      value: 1586.0,
    },
    {
      date: '2022-09-01',
      value: 1588.0,
    },
    {
      date: '2022-10-01',
      value: 1555.0,
    },
    {
      date: '2022-11-01',
      value: 1402.0,
    },
    {
      date: '2022-12-01',
      value: 1409.0,
    },
    {
      date: '2023-01-01',
      value: 1354.0,
    },
    {
      date: '2023-02-01',
      value: 1482.0,
    },
    {
      date: '2023-03-01',
      value: 1437.0,
    },
    {
      date: '2023-04-01',
      value: 1417.0,
    },
    {
      date: '2023-05-01',
      value: 1496.0,
    },
    {
      date: '2023-06-01',
      value: 1441.0,
    },
    {
      date: '2023-07-01',
      value: 1443.0,
    },
    {
      date: '2023-08-01',
      value: 1541.0,
    },
    {
      date: '2023-09-01',
      value: 1473.0,
    },
  ],
  dataikuD: {
    table: '110_HD_US_CENSUS_postgres',
    columnToWork: 'value',
    frecuency: 'monthly',
    date: 'label_time',
    nlags: 80,
    algorithm: '1',
    window_size_0_1: 0.5,
    window_size: 5.0,
    degree: 3.0,
    smoothing_factor: 3.0,
    stl_trend_smoother: 11.0,
    stl_seasonal_smoother: 7.0,
    stl_period: 7.0,
  },
  totalRows: 764,
};

export const HOUSING_DEMAND_VAR111 = {
  metadata: {
    name: 'New Home Sales',
    description:
      'The Census Bureau produces the following key business statistics: number of establishments; number of employees; payroll; first quarter payroll; and total sales, shipments, receipts, and business done.',
    classification: 'Anthropogenic Threat',
    evidenceType: 'Observation',
    temporalResolution: 'monthly',
    timePeriod: '1963-2023',
    source: 'US Census Bureau',
    citation:
      'New Home Sales. (2023, June 27). U.S. Census Bureau. https://www.census.gov/econ/currentdata/?programCode=RESSALES&startYear=1963&endYear=2023&categories[]=ASOLD&dataType=TOTAL&geoLevel=US&adjusted=1¬Adjusted=0&errorData=0',
    node: 'Housing Demand',
    net: 'Wood',
    dashboard: 'USA',
    nameShort: 'New Home Sales',
    timeUnit: 'month',
    isDate: true,
    yAxis: 'Thousands of Units',
    typeVariable: 'Time Series',
    multipleFilters: 0,
    multipleVariables: true,
  },
  data: [
    {
      date: '1963-01-01',
      value: 591.0,
    },
    {
      date: '1963-02-01',
      value: 464.0,
    },
    {
      date: '1963-03-01',
      value: 461.0,
    },
    {
      date: '1963-04-01',
      value: 605.0,
    },
    {
      date: '1963-05-01',
      value: 586.0,
    },
    {
      date: '1963-06-01',
      value: 526.0,
    },
    {
      date: '1963-07-01',
      value: 665.0,
    },
    {
      date: '1963-08-01',
      value: 570.0,
    },
    {
      date: '1963-09-01',
      value: 590.0,
    },
    {
      date: '1963-10-01',
      value: 567.0,
    },
    {
      date: '1963-11-01',
      value: 579.0,
    },
    {
      date: '1963-12-01',
      value: 514.0,
    },
    {
      date: '1964-01-01',
      value: 549.0,
    },
    {
      date: '1964-02-01',
      value: 609.0,
    },
    {
      date: '1964-03-01',
      value: 562.0,
    },
    {
      date: '1964-04-01',
      value: 559.0,
    },
    {
      date: '1964-05-01',
      value: 523.0,
    },
    {
      date: '1964-06-01',
      value: 580.0,
    },
    {
      date: '1964-07-01',
      value: 575.0,
    },
    {
      date: '1964-08-01',
      value: 582.0,
    },
    {
      date: '1964-09-01',
      value: 590.0,
    },
    {
      date: '1964-10-01',
      value: 583.0,
    },
    {
      date: '1964-11-01',
      value: 548.0,
    },
    {
      date: '1964-12-01',
      value: 540.0,
    },
    {
      date: '1965-01-01',
      value: 533.0,
    },
    {
      date: '1965-02-01',
      value: 559.0,
    },
    {
      date: '1965-03-01',
      value: 556.0,
    },
    {
      date: '1965-04-01',
      value: 555.0,
    },
    {
      date: '1965-05-01',
      value: 544.0,
    },
    {
      date: '1965-06-01',
      value: 614.0,
    },
    {
      date: '1965-07-01',
      value: 554.0,
    },
    {
      date: '1965-08-01',
      value: 615.0,
    },
    {
      date: '1965-09-01',
      value: 587.0,
    },
    {
      date: '1965-10-01',
      value: 555.0,
    },
    {
      date: '1965-11-01',
      value: 616.0,
    },
    {
      date: '1965-12-01',
      value: 609.0,
    },
    {
      date: '1966-01-01',
      value: 599.0,
    },
    {
      date: '1966-02-01',
      value: 541.0,
    },
    {
      date: '1966-03-01',
      value: 557.0,
    },
    {
      date: '1966-04-01',
      value: 545.0,
    },
    {
      date: '1966-05-01',
      value: 499.0,
    },
    {
      date: '1966-06-01',
      value: 434.0,
    },
    {
      date: '1966-07-01',
      value: 435.0,
    },
    {
      date: '1966-08-01',
      value: 377.0,
    },
    {
      date: '1966-09-01',
      value: 358.0,
    },
    {
      date: '1966-10-01',
      value: 387.0,
    },
    {
      date: '1966-11-01',
      value: 382.0,
    },
    {
      date: '1966-12-01',
      value: 369.0,
    },
    {
      date: '1967-01-01',
      value: 416.0,
    },
    {
      date: '1967-02-01',
      value: 408.0,
    },
    {
      date: '1967-03-01',
      value: 439.0,
    },
    {
      date: '1967-04-01',
      value: 479.0,
    },
    {
      date: '1967-05-01',
      value: 503.0,
    },
    {
      date: '1967-06-01',
      value: 499.0,
    },
    {
      date: '1967-07-01',
      value: 515.0,
    },
    {
      date: '1967-08-01',
      value: 504.0,
    },
    {
      date: '1967-09-01',
      value: 531.0,
    },
    {
      date: '1967-10-01',
      value: 566.0,
    },
    {
      date: '1967-11-01',
      value: 500.0,
    },
    {
      date: '1967-12-01',
      value: 502.0,
    },
    {
      date: '1968-01-01',
      value: 494.0,
    },
    {
      date: '1968-02-01',
      value: 543.0,
    },
    {
      date: '1968-03-01',
      value: 490.0,
    },
    {
      date: '1968-04-01',
      value: 501.0,
    },
    {
      date: '1968-05-01',
      value: 441.0,
    },
    {
      date: '1968-06-01',
      value: 441.0,
    },
    {
      date: '1968-07-01',
      value: 493.0,
    },
    {
      date: '1968-08-01',
      value: 507.0,
    },
    {
      date: '1968-09-01',
      value: 501.0,
    },
    {
      date: '1968-10-01',
      value: 502.0,
    },
    {
      date: '1968-11-01',
      value: 469.0,
    },
    {
      date: '1968-12-01',
      value: 511.0,
    },
    {
      date: '1969-01-01',
      value: 480.0,
    },
    {
      date: '1969-02-01',
      value: 524.0,
    },
    {
      date: '1969-03-01',
      value: 474.0,
    },
    {
      date: '1969-04-01',
      value: 450.0,
    },
    {
      date: '1969-05-01',
      value: 447.0,
    },
    {
      date: '1969-06-01',
      value: 461.0,
    },
    {
      date: '1969-07-01',
      value: 436.0,
    },
    {
      date: '1969-08-01',
      value: 422.0,
    },
    {
      date: '1969-09-01',
      value: 396.0,
    },
    {
      date: '1969-10-01',
      value: 401.0,
    },
    {
      date: '1969-11-01',
      value: 441.0,
    },
    {
      date: '1969-12-01',
      value: 452.0,
    },
    {
      date: '1970-01-01',
      value: 461.0,
    },
    {
      date: '1970-02-01',
      value: 373.0,
    },
    {
      date: '1970-03-01',
      value: 389.0,
    },
    {
      date: '1970-04-01',
      value: 445.0,
    },
    {
      date: '1970-05-01',
      value: 466.0,
    },
    {
      date: '1970-06-01',
      value: 485.0,
    },
    {
      date: '1970-07-01',
      value: 481.0,
    },
    {
      date: '1970-08-01',
      value: 515.0,
    },
    {
      date: '1970-09-01',
      value: 564.0,
    },
    {
      date: '1970-10-01',
      value: 545.0,
    },
    {
      date: '1970-11-01',
      value: 570.0,
    },
    {
      date: '1970-12-01',
      value: 582.0,
    },
    {
      date: '1971-01-01',
      value: 618.0,
    },
    {
      date: '1971-02-01',
      value: 618.0,
    },
    {
      date: '1971-03-01',
      value: 681.0,
    },
    {
      date: '1971-04-01',
      value: 662.0,
    },
    {
      date: '1971-05-01',
      value: 618.0,
    },
    {
      date: '1971-06-01',
      value: 646.0,
    },
    {
      date: '1971-07-01',
      value: 706.0,
    },
    {
      date: '1971-08-01',
      value: 659.0,
    },
    {
      date: '1971-09-01',
      value: 625.0,
    },
    {
      date: '1971-10-01',
      value: 647.0,
    },
    {
      date: '1971-11-01',
      value: 710.0,
    },
    {
      date: '1971-12-01',
      value: 689.0,
    },
    {
      date: '1972-01-01',
      value: 698.0,
    },
    {
      date: '1972-02-01',
      value: 711.0,
    },
    {
      date: '1972-03-01',
      value: 640.0,
    },
    {
      date: '1972-04-01',
      value: 684.0,
    },
    {
      date: '1972-05-01',
      value: 677.0,
    },
    {
      date: '1972-06-01',
      value: 687.0,
    },
    {
      date: '1972-07-01',
      value: 681.0,
    },
    {
      date: '1972-08-01',
      value: 773.0,
    },
    {
      date: '1972-09-01',
      value: 767.0,
    },
    {
      date: '1972-10-01',
      value: 843.0,
    },
    {
      date: '1972-11-01',
      value: 735.0,
    },
    {
      date: '1972-12-01',
      value: 772.0,
    },
    {
      date: '1973-01-01',
      value: 781.0,
    },
    {
      date: '1973-02-01',
      value: 737.0,
    },
    {
      date: '1973-03-01',
      value: 725.0,
    },
    {
      date: '1973-04-01',
      value: 661.0,
    },
    {
      date: '1973-05-01',
      value: 660.0,
    },
    {
      date: '1973-06-01',
      value: 650.0,
    },
    {
      date: '1973-07-01',
      value: 601.0,
    },
    {
      date: '1973-08-01',
      value: 566.0,
    },
    {
      date: '1973-09-01',
      value: 561.0,
    },
    {
      date: '1973-10-01',
      value: 565.0,
    },
    {
      date: '1973-11-01',
      value: 547.0,
    },
    {
      date: '1973-12-01',
      value: 519.0,
    },
    {
      date: '1974-01-01',
      value: 523.0,
    },
    {
      date: '1974-02-01',
      value: 539.0,
    },
    {
      date: '1974-03-01',
      value: 572.0,
    },
    {
      date: '1974-04-01',
      value: 544.0,
    },
    {
      date: '1974-05-01',
      value: 590.0,
    },
    {
      date: '1974-06-01',
      value: 534.0,
    },
    {
      date: '1974-07-01',
      value: 534.0,
    },
    {
      date: '1974-08-01',
      value: 492.0,
    },
    {
      date: '1974-09-01',
      value: 511.0,
    },
    {
      date: '1974-10-01',
      value: 448.0,
    },
    {
      date: '1974-11-01',
      value: 450.0,
    },
    {
      date: '1974-12-01',
      value: 417.0,
    },
    {
      date: '1975-01-01',
      value: 416.0,
    },
    {
      date: '1975-02-01',
      value: 422.0,
    },
    {
      date: '1975-03-01',
      value: 477.0,
    },
    {
      date: '1975-04-01',
      value: 543.0,
    },
    {
      date: '1975-05-01',
      value: 579.0,
    },
    {
      date: '1975-06-01',
      value: 557.0,
    },
    {
      date: '1975-07-01',
      value: 569.0,
    },
    {
      date: '1975-08-01',
      value: 566.0,
    },
    {
      date: '1975-09-01',
      value: 556.0,
    },
    {
      date: '1975-10-01',
      value: 609.0,
    },
    {
      date: '1975-11-01',
      value: 680.0,
    },
    {
      date: '1975-12-01',
      value: 669.0,
    },
    {
      date: '1976-01-01',
      value: 603.0,
    },
    {
      date: '1976-02-01',
      value: 644.0,
    },
    {
      date: '1976-03-01',
      value: 591.0,
    },
    {
      date: '1976-04-01',
      value: 611.0,
    },
    {
      date: '1976-05-01',
      value: 570.0,
    },
    {
      date: '1976-06-01',
      value: 591.0,
    },
    {
      date: '1976-07-01',
      value: 664.0,
    },
    {
      date: '1976-08-01',
      value: 648.0,
    },
    {
      date: '1976-09-01',
      value: 696.0,
    },
    {
      date: '1976-10-01',
      value: 708.0,
    },
    {
      date: '1976-11-01',
      value: 735.0,
    },
    {
      date: '1976-12-01',
      value: 767.0,
    },
    {
      date: '1977-01-01',
      value: 825.0,
    },
    {
      date: '1977-02-01',
      value: 839.0,
    },
    {
      date: '1977-03-01',
      value: 872.0,
    },
    {
      date: '1977-04-01',
      value: 799.0,
    },
    {
      date: '1977-05-01',
      value: 807.0,
    },
    {
      date: '1977-06-01',
      value: 805.0,
    },
    {
      date: '1977-07-01',
      value: 755.0,
    },
    {
      date: '1977-08-01',
      value: 808.0,
    },
    {
      date: '1977-09-01',
      value: 842.0,
    },
    {
      date: '1977-10-01',
      value: 819.0,
    },
    {
      date: '1977-11-01',
      value: 829.0,
    },
    {
      date: '1977-12-01',
      value: 835.0,
    },
    {
      date: '1978-01-01',
      value: 795.0,
    },
    {
      date: '1978-02-01',
      value: 791.0,
    },
    {
      date: '1978-03-01',
      value: 814.0,
    },
    {
      date: '1978-04-01',
      value: 864.0,
    },
    {
      date: '1978-05-01',
      value: 857.0,
    },
    {
      date: '1978-06-01',
      value: 834.0,
    },
    {
      date: '1978-07-01',
      value: 789.0,
    },
    {
      date: '1978-08-01',
      value: 756.0,
    },
    {
      date: '1978-09-01',
      value: 812.0,
    },
    {
      date: '1978-10-01',
      value: 872.0,
    },
    {
      date: '1978-11-01',
      value: 798.0,
    },
    {
      date: '1978-12-01',
      value: 805.0,
    },
    {
      date: '1979-01-01',
      value: 754.0,
    },
    {
      date: '1979-02-01',
      value: 723.0,
    },
    {
      date: '1979-03-01',
      value: 793.0,
    },
    {
      date: '1979-04-01',
      value: 748.0,
    },
    {
      date: '1979-05-01',
      value: 727.0,
    },
    {
      date: '1979-06-01',
      value: 700.0,
    },
    {
      date: '1979-07-01',
      value: 715.0,
    },
    {
      date: '1979-08-01',
      value: 729.0,
    },
    {
      date: '1979-09-01',
      value: 727.0,
    },
    {
      date: '1979-10-01',
      value: 670.0,
    },
    {
      date: '1979-11-01',
      value: 597.0,
    },
    {
      date: '1979-12-01',
      value: 559.0,
    },
    {
      date: '1980-01-01',
      value: 592.0,
    },
    {
      date: '1980-02-01',
      value: 541.0,
    },
    {
      date: '1980-03-01',
      value: 474.0,
    },
    {
      date: '1980-04-01',
      value: 370.0,
    },
    {
      date: '1980-05-01',
      value: 469.0,
    },
    {
      date: '1980-06-01',
      value: 552.0,
    },
    {
      date: '1980-07-01',
      value: 636.0,
    },
    {
      date: '1980-08-01',
      value: 659.0,
    },
    {
      date: '1980-09-01',
      value: 596.0,
    },
    {
      date: '1980-10-01',
      value: 561.0,
    },
    {
      date: '1980-11-01',
      value: 562.0,
    },
    {
      date: '1980-12-01',
      value: 532.0,
    },
    {
      date: '1981-01-01',
      value: 511.0,
    },
    {
      date: '1981-02-01',
      value: 510.0,
    },
    {
      date: '1981-03-01',
      value: 514.0,
    },
    {
      date: '1981-04-01',
      value: 470.0,
    },
    {
      date: '1981-05-01',
      value: 467.0,
    },
    {
      date: '1981-06-01',
      value: 415.0,
    },
    {
      date: '1981-07-01',
      value: 431.0,
    },
    {
      date: '1981-08-01',
      value: 378.0,
    },
    {
      date: '1981-09-01',
      value: 338.0,
    },
    {
      date: '1981-10-01',
      value: 356.0,
    },
    {
      date: '1981-11-01',
      value: 382.0,
    },
    {
      date: '1981-12-01',
      value: 457.0,
    },
    {
      date: '1982-01-01',
      value: 368.0,
    },
    {
      date: '1982-02-01',
      value: 365.0,
    },
    {
      date: '1982-03-01',
      value: 374.0,
    },
    {
      date: '1982-04-01',
      value: 339.0,
    },
    {
      date: '1982-05-01',
      value: 384.0,
    },
    {
      date: '1982-06-01',
      value: 370.0,
    },
    {
      date: '1982-07-01',
      value: 375.0,
    },
    {
      date: '1982-08-01',
      value: 407.0,
    },
    {
      date: '1982-09-01',
      value: 481.0,
    },
    {
      date: '1982-10-01',
      value: 480.0,
    },
    {
      date: '1982-11-01',
      value: 554.0,
    },
    {
      date: '1982-12-01',
      value: 521.0,
    },
    {
      date: '1983-01-01',
      value: 582.0,
    },
    {
      date: '1983-02-01',
      value: 562.0,
    },
    {
      date: '1983-03-01',
      value: 596.0,
    },
    {
      date: '1983-04-01',
      value: 638.0,
    },
    {
      date: '1983-05-01',
      value: 664.0,
    },
    {
      date: '1983-06-01',
      value: 651.0,
    },
    {
      date: '1983-07-01',
      value: 606.0,
    },
    {
      date: '1983-08-01',
      value: 572.0,
    },
    {
      date: '1983-09-01',
      value: 608.0,
    },
    {
      date: '1983-10-01',
      value: 632.0,
    },
    {
      date: '1983-11-01',
      value: 644.0,
    },
    {
      date: '1983-12-01',
      value: 773.0,
    },
    {
      date: '1984-01-01',
      value: 691.0,
    },
    {
      date: '1984-02-01',
      value: 696.0,
    },
    {
      date: '1984-03-01',
      value: 641.0,
    },
    {
      date: '1984-04-01',
      value: 639.0,
    },
    {
      date: '1984-05-01',
      value: 615.0,
    },
    {
      date: '1984-06-01',
      value: 630.0,
    },
    {
      date: '1984-07-01',
      value: 619.0,
    },
    {
      date: '1984-08-01',
      value: 567.0,
    },
    {
      date: '1984-09-01',
      value: 662.0,
    },
    {
      date: '1984-10-01',
      value: 687.0,
    },
    {
      date: '1984-11-01',
      value: 597.0,
    },
    {
      date: '1984-12-01',
      value: 597.0,
    },
    {
      date: '1985-01-01',
      value: 645.0,
    },
    {
      date: '1985-02-01',
      value: 682.0,
    },
    {
      date: '1985-03-01',
      value: 671.0,
    },
    {
      date: '1985-04-01',
      value: 620.0,
    },
    {
      date: '1985-05-01',
      value: 678.0,
    },
    {
      date: '1985-06-01',
      value: 722.0,
    },
    {
      date: '1985-07-01',
      value: 766.0,
    },
    {
      date: '1985-08-01',
      value: 726.0,
    },
    {
      date: '1985-09-01',
      value: 678.0,
    },
    {
      date: '1985-10-01',
      value: 655.0,
    },
    {
      date: '1985-11-01',
      value: 733.0,
    },
    {
      date: '1985-12-01',
      value: 721.0,
    },
    {
      date: '1986-01-01',
      value: 733.0,
    },
    {
      date: '1986-02-01',
      value: 728.0,
    },
    {
      date: '1986-03-01',
      value: 880.0,
    },
    {
      date: '1986-04-01',
      value: 857.0,
    },
    {
      date: '1986-05-01',
      value: 789.0,
    },
    {
      date: '1986-06-01',
      value: 728.0,
    },
    {
      date: '1986-07-01',
      value: 698.0,
    },
    {
      date: '1986-08-01',
      value: 621.0,
    },
    {
      date: '1986-09-01',
      value: 763.0,
    },
    {
      date: '1986-10-01',
      value: 669.0,
    },
    {
      date: '1986-11-01',
      value: 707.0,
    },
    {
      date: '1986-12-01',
      value: 784.0,
    },
    {
      date: '1987-01-01',
      value: 709.0,
    },
    {
      date: '1987-02-01',
      value: 732.0,
    },
    {
      date: '1987-03-01',
      value: 713.0,
    },
    {
      date: '1987-04-01',
      value: 735.0,
    },
    {
      date: '1987-05-01',
      value: 651.0,
    },
    {
      date: '1987-06-01',
      value: 637.0,
    },
    {
      date: '1987-07-01',
      value: 658.0,
    },
    {
      date: '1987-08-01',
      value: 657.0,
    },
    {
      date: '1987-09-01',
      value: 666.0,
    },
    {
      date: '1987-10-01',
      value: 650.0,
    },
    {
      date: '1987-11-01',
      value: 625.0,
    },
    {
      date: '1987-12-01',
      value: 595.0,
    },
    {
      date: '1988-01-01',
      value: 585.0,
    },
    {
      date: '1988-02-01',
      value: 663.0,
    },
    {
      date: '1988-03-01',
      value: 669.0,
    },
    {
      date: '1988-04-01',
      value: 699.0,
    },
    {
      date: '1988-05-01',
      value: 684.0,
    },
    {
      date: '1988-06-01',
      value: 717.0,
    },
    {
      date: '1988-07-01',
      value: 679.0,
    },
    {
      date: '1988-08-01',
      value: 688.0,
    },
    {
      date: '1988-09-01',
      value: 703.0,
    },
    {
      date: '1988-10-01',
      value: 718.0,
    },
    {
      date: '1988-11-01',
      value: 628.0,
    },
    {
      date: '1988-12-01',
      value: 658.0,
    },
    {
      date: '1989-01-01',
      value: 719.0,
    },
    {
      date: '1989-02-01',
      value: 622.0,
    },
    {
      date: '1989-03-01',
      value: 567.0,
    },
    {
      date: '1989-04-01',
      value: 608.0,
    },
    {
      date: '1989-05-01',
      value: 656.0,
    },
    {
      date: '1989-06-01',
      value: 642.0,
    },
    {
      date: '1989-07-01',
      value: 731.0,
    },
    {
      date: '1989-08-01',
      value: 697.0,
    },
    {
      date: '1989-09-01',
      value: 639.0,
    },
    {
      date: '1989-10-01',
      value: 645.0,
    },
    {
      date: '1989-11-01',
      value: 684.0,
    },
    {
      date: '1989-12-01',
      value: 630.0,
    },
    {
      date: '1990-01-01',
      value: 620.0,
    },
    {
      date: '1990-02-01',
      value: 591.0,
    },
    {
      date: '1990-03-01',
      value: 574.0,
    },
    {
      date: '1990-04-01',
      value: 542.0,
    },
    {
      date: '1990-05-01',
      value: 534.0,
    },
    {
      date: '1990-06-01',
      value: 545.0,
    },
    {
      date: '1990-07-01',
      value: 542.0,
    },
    {
      date: '1990-08-01',
      value: 528.0,
    },
    {
      date: '1990-09-01',
      value: 496.0,
    },
    {
      date: '1990-10-01',
      value: 465.0,
    },
    {
      date: '1990-11-01',
      value: 493.0,
    },
    {
      date: '1990-12-01',
      value: 464.0,
    },
    {
      date: '1991-01-01',
      value: 401.0,
    },
    {
      date: '1991-02-01',
      value: 482.0,
    },
    {
      date: '1991-03-01',
      value: 507.0,
    },
    {
      date: '1991-04-01',
      value: 508.0,
    },
    {
      date: '1991-05-01',
      value: 517.0,
    },
    {
      date: '1991-06-01',
      value: 516.0,
    },
    {
      date: '1991-07-01',
      value: 511.0,
    },
    {
      date: '1991-08-01',
      value: 526.0,
    },
    {
      date: '1991-09-01',
      value: 487.0,
    },
    {
      date: '1991-10-01',
      value: 524.0,
    },
    {
      date: '1991-11-01',
      value: 575.0,
    },
    {
      date: '1991-12-01',
      value: 558.0,
    },
    {
      date: '1992-01-01',
      value: 676.0,
    },
    {
      date: '1992-02-01',
      value: 639.0,
    },
    {
      date: '1992-03-01',
      value: 553.0,
    },
    {
      date: '1992-04-01',
      value: 546.0,
    },
    {
      date: '1992-05-01',
      value: 554.0,
    },
    {
      date: '1992-06-01',
      value: 596.0,
    },
    {
      date: '1992-07-01',
      value: 627.0,
    },
    {
      date: '1992-08-01',
      value: 636.0,
    },
    {
      date: '1992-09-01',
      value: 650.0,
    },
    {
      date: '1992-10-01',
      value: 621.0,
    },
    {
      date: '1992-11-01',
      value: 614.0,
    },
    {
      date: '1992-12-01',
      value: 650.0,
    },
    {
      date: '1993-01-01',
      value: 596.0,
    },
    {
      date: '1993-02-01',
      value: 604.0,
    },
    {
      date: '1993-03-01',
      value: 602.0,
    },
    {
      date: '1993-04-01',
      value: 701.0,
    },
    {
      date: '1993-05-01',
      value: 626.0,
    },
    {
      date: '1993-06-01',
      value: 653.0,
    },
    {
      date: '1993-07-01',
      value: 655.0,
    },
    {
      date: '1993-08-01',
      value: 645.0,
    },
    {
      date: '1993-09-01',
      value: 726.0,
    },
    {
      date: '1993-10-01',
      value: 704.0,
    },
    {
      date: '1993-11-01',
      value: 769.0,
    },
    {
      date: '1993-12-01',
      value: 812.0,
    },
    {
      date: '1994-01-01',
      value: 619.0,
    },
    {
      date: '1994-02-01',
      value: 686.0,
    },
    {
      date: '1994-03-01',
      value: 747.0,
    },
    {
      date: '1994-04-01',
      value: 692.0,
    },
    {
      date: '1994-05-01',
      value: 691.0,
    },
    {
      date: '1994-06-01',
      value: 621.0,
    },
    {
      date: '1994-07-01',
      value: 628.0,
    },
    {
      date: '1994-08-01',
      value: 656.0,
    },
    {
      date: '1994-09-01',
      value: 677.0,
    },
    {
      date: '1994-10-01',
      value: 715.0,
    },
    {
      date: '1994-11-01',
      value: 646.0,
    },
    {
      date: '1994-12-01',
      value: 629.0,
    },
    {
      date: '1995-01-01',
      value: 626.0,
    },
    {
      date: '1995-02-01',
      value: 559.0,
    },
    {
      date: '1995-03-01',
      value: 616.0,
    },
    {
      date: '1995-04-01',
      value: 621.0,
    },
    {
      date: '1995-05-01',
      value: 674.0,
    },
    {
      date: '1995-06-01',
      value: 725.0,
    },
    {
      date: '1995-07-01',
      value: 765.0,
    },
    {
      date: '1995-08-01',
      value: 701.0,
    },
    {
      date: '1995-09-01',
      value: 678.0,
    },
    {
      date: '1995-10-01',
      value: 696.0,
    },
    {
      date: '1995-11-01',
      value: 664.0,
    },
    {
      date: '1995-12-01',
      value: 709.0,
    },
    {
      date: '1996-01-01',
      value: 714.0,
    },
    {
      date: '1996-02-01',
      value: 769.0,
    },
    {
      date: '1996-03-01',
      value: 721.0,
    },
    {
      date: '1996-04-01',
      value: 736.0,
    },
    {
      date: '1996-05-01',
      value: 746.0,
    },
    {
      date: '1996-06-01',
      value: 721.0,
    },
    {
      date: '1996-07-01',
      value: 770.0,
    },
    {
      date: '1996-08-01',
      value: 826.0,
    },
    {
      date: '1996-09-01',
      value: 770.0,
    },
    {
      date: '1996-10-01',
      value: 720.0,
    },
    {
      date: '1996-11-01',
      value: 771.0,
    },
    {
      date: '1996-12-01',
      value: 805.0,
    },
    {
      date: '1997-01-01',
      value: 830.0,
    },
    {
      date: '1997-02-01',
      value: 801.0,
    },
    {
      date: '1997-03-01',
      value: 831.0,
    },
    {
      date: '1997-04-01',
      value: 744.0,
    },
    {
      date: '1997-05-01',
      value: 760.0,
    },
    {
      date: '1997-06-01',
      value: 793.0,
    },
    {
      date: '1997-07-01',
      value: 805.0,
    },
    {
      date: '1997-08-01',
      value: 815.0,
    },
    {
      date: '1997-09-01',
      value: 840.0,
    },
    {
      date: '1997-10-01',
      value: 800.0,
    },
    {
      date: '1997-11-01',
      value: 864.0,
    },
    {
      date: '1997-12-01',
      value: 793.0,
    },
    {
      date: '1998-01-01',
      value: 872.0,
    },
    {
      date: '1998-02-01',
      value: 866.0,
    },
    {
      date: '1998-03-01',
      value: 836.0,
    },
    {
      date: '1998-04-01',
      value: 866.0,
    },
    {
      date: '1998-05-01',
      value: 887.0,
    },
    {
      date: '1998-06-01',
      value: 923.0,
    },
    {
      date: '1998-07-01',
      value: 876.0,
    },
    {
      date: '1998-08-01',
      value: 846.0,
    },
    {
      date: '1998-09-01',
      value: 864.0,
    },
    {
      date: '1998-10-01',
      value: 893.0,
    },
    {
      date: '1998-11-01',
      value: 995.0,
    },
    {
      date: '1998-12-01',
      value: 949.0,
    },
    {
      date: '1999-01-01',
      value: 875.0,
    },
    {
      date: '1999-02-01',
      value: 848.0,
    },
    {
      date: '1999-03-01',
      value: 863.0,
    },
    {
      date: '1999-04-01',
      value: 918.0,
    },
    {
      date: '1999-05-01',
      value: 888.0,
    },
    {
      date: '1999-06-01',
      value: 923.0,
    },
    {
      date: '1999-07-01',
      value: 900.0,
    },
    {
      date: '1999-08-01',
      value: 893.0,
    },
    {
      date: '1999-09-01',
      value: 826.0,
    },
    {
      date: '1999-10-01',
      value: 872.0,
    },
    {
      date: '1999-11-01',
      value: 863.0,
    },
    {
      date: '1999-12-01',
      value: 873.0,
    },
    {
      date: '2000-01-01',
      value: 873.0,
    },
    {
      date: '2000-02-01',
      value: 856.0,
    },
    {
      date: '2000-03-01',
      value: 900.0,
    },
    {
      date: '2000-04-01',
      value: 841.0,
    },
    {
      date: '2000-05-01',
      value: 857.0,
    },
    {
      date: '2000-06-01',
      value: 793.0,
    },
    {
      date: '2000-07-01',
      value: 887.0,
    },
    {
      date: '2000-08-01',
      value: 848.0,
    },
    {
      date: '2000-09-01',
      value: 912.0,
    },
    {
      date: '2000-10-01',
      value: 933.0,
    },
    {
      date: '2000-11-01',
      value: 880.0,
    },
    {
      date: '2000-12-01',
      value: 983.0,
    },
    {
      date: '2001-01-01',
      value: 936.0,
    },
    {
      date: '2001-02-01',
      value: 963.0,
    },
    {
      date: '2001-03-01',
      value: 939.0,
    },
    {
      date: '2001-04-01',
      value: 909.0,
    },
    {
      date: '2001-05-01',
      value: 885.0,
    },
    {
      date: '2001-06-01',
      value: 882.0,
    },
    {
      date: '2001-07-01',
      value: 880.0,
    },
    {
      date: '2001-08-01',
      value: 866.0,
    },
    {
      date: '2001-09-01',
      value: 853.0,
    },
    {
      date: '2001-10-01',
      value: 871.0,
    },
    {
      date: '2001-11-01',
      value: 924.0,
    },
    {
      date: '2001-12-01',
      value: 979.0,
    },
    {
      date: '2002-01-01',
      value: 880.0,
    },
    {
      date: '2002-02-01',
      value: 948.0,
    },
    {
      date: '2002-03-01',
      value: 923.0,
    },
    {
      date: '2002-04-01',
      value: 936.0,
    },
    {
      date: '2002-05-01',
      value: 978.0,
    },
    {
      date: '2002-06-01',
      value: 957.0,
    },
    {
      date: '2002-07-01',
      value: 956.0,
    },
    {
      date: '2002-08-01',
      value: 1014.0,
    },
    {
      date: '2002-09-01',
      value: 1044.0,
    },
    {
      date: '2002-10-01',
      value: 1006.0,
    },
    {
      date: '2002-11-01',
      value: 1024.0,
    },
    {
      date: '2002-12-01',
      value: 1048.0,
    },
    {
      date: '2003-01-01',
      value: 999.0,
    },
    {
      date: '2003-02-01',
      value: 936.0,
    },
    {
      date: '2003-03-01',
      value: 999.0,
    },
    {
      date: '2003-04-01',
      value: 1012.0,
    },
    {
      date: '2003-05-01',
      value: 1078.0,
    },
    {
      date: '2003-06-01',
      value: 1193.0,
    },
    {
      date: '2003-07-01',
      value: 1168.0,
    },
    {
      date: '2003-08-01',
      value: 1206.0,
    },
    {
      date: '2003-09-01',
      value: 1131.0,
    },
    {
      date: '2003-10-01',
      value: 1144.0,
    },
    {
      date: '2003-11-01',
      value: 1093.0,
    },
    {
      date: '2003-12-01',
      value: 1129.0,
    },
    {
      date: '2004-01-01',
      value: 1165.0,
    },
    {
      date: '2004-02-01',
      value: 1159.0,
    },
    {
      date: '2004-03-01',
      value: 1276.0,
    },
    {
      date: '2004-04-01',
      value: 1186.0,
    },
    {
      date: '2004-05-01',
      value: 1241.0,
    },
    {
      date: '2004-06-01',
      value: 1180.0,
    },
    {
      date: '2004-07-01',
      value: 1088.0,
    },
    {
      date: '2004-08-01',
      value: 1175.0,
    },
    {
      date: '2004-09-01',
      value: 1214.0,
    },
    {
      date: '2004-10-01',
      value: 1305.0,
    },
    {
      date: '2004-11-01',
      value: 1179.0,
    },
    {
      date: '2004-12-01',
      value: 1242.0,
    },
    {
      date: '2005-01-01',
      value: 1203.0,
    },
    {
      date: '2005-02-01',
      value: 1319.0,
    },
    {
      date: '2005-03-01',
      value: 1328.0,
    },
    {
      date: '2005-04-01',
      value: 1260.0,
    },
    {
      date: '2005-05-01',
      value: 1286.0,
    },
    {
      date: '2005-06-01',
      value: 1274.0,
    },
    {
      date: '2005-07-01',
      value: 1389.0,
    },
    {
      date: '2005-08-01',
      value: 1255.0,
    },
    {
      date: '2005-09-01',
      value: 1244.0,
    },
    {
      date: '2005-10-01',
      value: 1336.0,
    },
    {
      date: '2005-11-01',
      value: 1214.0,
    },
    {
      date: '2005-12-01',
      value: 1239.0,
    },
    {
      date: '2006-01-01',
      value: 1174.0,
    },
    {
      date: '2006-02-01',
      value: 1061.0,
    },
    {
      date: '2006-03-01',
      value: 1116.0,
    },
    {
      date: '2006-04-01',
      value: 1123.0,
    },
    {
      date: '2006-05-01',
      value: 1086.0,
    },
    {
      date: '2006-06-01',
      value: 1074.0,
    },
    {
      date: '2006-07-01',
      value: 965.0,
    },
    {
      date: '2006-08-01',
      value: 1035.0,
    },
    {
      date: '2006-09-01',
      value: 1016.0,
    },
    {
      date: '2006-10-01',
      value: 941.0,
    },
    {
      date: '2006-11-01',
      value: 1003.0,
    },
    {
      date: '2006-12-01',
      value: 998.0,
    },
    {
      date: '2007-01-01',
      value: 891.0,
    },
    {
      date: '2007-02-01',
      value: 828.0,
    },
    {
      date: '2007-03-01',
      value: 833.0,
    },
    {
      date: '2007-04-01',
      value: 887.0,
    },
    {
      date: '2007-05-01',
      value: 842.0,
    },
    {
      date: '2007-06-01',
      value: 793.0,
    },
    {
      date: '2007-07-01',
      value: 778.0,
    },
    {
      date: '2007-08-01',
      value: 699.0,
    },
    {
      date: '2007-09-01',
      value: 686.0,
    },
    {
      date: '2007-10-01',
      value: 727.0,
    },
    {
      date: '2007-11-01',
      value: 641.0,
    },
    {
      date: '2007-12-01',
      value: 619.0,
    },
    {
      date: '2008-01-01',
      value: 627.0,
    },
    {
      date: '2008-02-01',
      value: 593.0,
    },
    {
      date: '2008-03-01',
      value: 535.0,
    },
    {
      date: '2008-04-01',
      value: 536.0,
    },
    {
      date: '2008-05-01',
      value: 504.0,
    },
    {
      date: '2008-06-01',
      value: 487.0,
    },
    {
      date: '2008-07-01',
      value: 477.0,
    },
    {
      date: '2008-08-01',
      value: 435.0,
    },
    {
      date: '2008-09-01',
      value: 433.0,
    },
    {
      date: '2008-10-01',
      value: 393.0,
    },
    {
      date: '2008-11-01',
      value: 389.0,
    },
    {
      date: '2008-12-01',
      value: 377.0,
    },
    {
      date: '2009-01-01',
      value: 336.0,
    },
    {
      date: '2009-02-01',
      value: 372.0,
    },
    {
      date: '2009-03-01',
      value: 339.0,
    },
    {
      date: '2009-04-01',
      value: 337.0,
    },
    {
      date: '2009-05-01',
      value: 376.0,
    },
    {
      date: '2009-06-01',
      value: 393.0,
    },
    {
      date: '2009-07-01',
      value: 411.0,
    },
    {
      date: '2009-08-01',
      value: 418.0,
    },
    {
      date: '2009-09-01',
      value: 386.0,
    },
    {
      date: '2009-10-01',
      value: 396.0,
    },
    {
      date: '2009-11-01',
      value: 375.0,
    },
    {
      date: '2009-12-01',
      value: 352.0,
    },
    {
      date: '2010-01-01',
      value: 345.0,
    },
    {
      date: '2010-02-01',
      value: 336.0,
    },
    {
      date: '2010-03-01',
      value: 381.0,
    },
    {
      date: '2010-04-01',
      value: 422.0,
    },
    {
      date: '2010-05-01',
      value: 280.0,
    },
    {
      date: '2010-06-01',
      value: 305.0,
    },
    {
      date: '2010-07-01',
      value: 283.0,
    },
    {
      date: '2010-08-01',
      value: 282.0,
    },
    {
      date: '2010-09-01',
      value: 317.0,
    },
    {
      date: '2010-10-01',
      value: 291.0,
    },
    {
      date: '2010-11-01',
      value: 287.0,
    },
    {
      date: '2010-12-01',
      value: 326.0,
    },
    {
      date: '2011-01-01',
      value: 307.0,
    },
    {
      date: '2011-02-01',
      value: 270.0,
    },
    {
      date: '2011-03-01',
      value: 300.0,
    },
    {
      date: '2011-04-01',
      value: 310.0,
    },
    {
      date: '2011-05-01',
      value: 305.0,
    },
    {
      date: '2011-06-01',
      value: 301.0,
    },
    {
      date: '2011-07-01',
      value: 296.0,
    },
    {
      date: '2011-08-01',
      value: 299.0,
    },
    {
      date: '2011-09-01',
      value: 304.0,
    },
    {
      date: '2011-10-01',
      value: 316.0,
    },
    {
      date: '2011-11-01',
      value: 328.0,
    },
    {
      date: '2011-12-01',
      value: 341.0,
    },
    {
      date: '2012-01-01',
      value: 335.0,
    },
    {
      date: '2012-02-01',
      value: 366.0,
    },
    {
      date: '2012-03-01',
      value: 354.0,
    },
    {
      date: '2012-04-01',
      value: 354.0,
    },
    {
      date: '2012-05-01',
      value: 370.0,
    },
    {
      date: '2012-06-01',
      value: 360.0,
    },
    {
      date: '2012-07-01',
      value: 369.0,
    },
    {
      date: '2012-08-01',
      value: 375.0,
    },
    {
      date: '2012-09-01',
      value: 385.0,
    },
    {
      date: '2012-10-01',
      value: 358.0,
    },
    {
      date: '2012-11-01',
      value: 392.0,
    },
    {
      date: '2012-12-01',
      value: 399.0,
    },
    {
      date: '2013-01-01',
      value: 446.0,
    },
    {
      date: '2013-02-01',
      value: 447.0,
    },
    {
      date: '2013-03-01',
      value: 444.0,
    },
    {
      date: '2013-04-01',
      value: 441.0,
    },
    {
      date: '2013-05-01',
      value: 428.0,
    },
    {
      date: '2013-06-01',
      value: 470.0,
    },
    {
      date: '2013-07-01',
      value: 375.0,
    },
    {
      date: '2013-08-01',
      value: 381.0,
    },
    {
      date: '2013-09-01',
      value: 403.0,
    },
    {
      date: '2013-10-01',
      value: 444.0,
    },
    {
      date: '2013-11-01',
      value: 446.0,
    },
    {
      date: '2013-12-01',
      value: 433.0,
    },
    {
      date: '2014-01-01',
      value: 443.0,
    },
    {
      date: '2014-02-01',
      value: 420.0,
    },
    {
      date: '2014-03-01',
      value: 405.0,
    },
    {
      date: '2014-04-01',
      value: 403.0,
    },
    {
      date: '2014-05-01',
      value: 451.0,
    },
    {
      date: '2014-06-01',
      value: 418.0,
    },
    {
      date: '2014-07-01',
      value: 402.0,
    },
    {
      date: '2014-08-01',
      value: 456.0,
    },
    {
      date: '2014-09-01',
      value: 470.0,
    },
    {
      date: '2014-10-01',
      value: 476.0,
    },
    {
      date: '2014-11-01',
      value: 442.0,
    },
    {
      date: '2014-12-01',
      value: 497.0,
    },
    {
      date: '2015-01-01',
      value: 515.0,
    },
    {
      date: '2015-02-01',
      value: 540.0,
    },
    {
      date: '2015-03-01',
      value: 480.0,
    },
    {
      date: '2015-04-01',
      value: 502.0,
    },
    {
      date: '2015-05-01',
      value: 502.0,
    },
    {
      date: '2015-06-01',
      value: 480.0,
    },
    {
      date: '2015-07-01',
      value: 506.0,
    },
    {
      date: '2015-08-01',
      value: 518.0,
    },
    {
      date: '2015-09-01',
      value: 456.0,
    },
    {
      date: '2015-10-01',
      value: 482.0,
    },
    {
      date: '2015-11-01',
      value: 504.0,
    },
    {
      date: '2015-12-01',
      value: 546.0,
    },
    {
      date: '2016-01-01',
      value: 505.0,
    },
    {
      date: '2016-02-01',
      value: 517.0,
    },
    {
      date: '2016-03-01',
      value: 532.0,
    },
    {
      date: '2016-04-01',
      value: 576.0,
    },
    {
      date: '2016-05-01',
      value: 571.0,
    },
    {
      date: '2016-06-01',
      value: 557.0,
    },
    {
      date: '2016-07-01',
      value: 628.0,
    },
    {
      date: '2016-08-01',
      value: 575.0,
    },
    {
      date: '2016-09-01',
      value: 558.0,
    },
    {
      date: '2016-10-01',
      value: 575.0,
    },
    {
      date: '2016-11-01',
      value: 571.0,
    },
    {
      date: '2016-12-01',
      value: 561.0,
    },
    {
      date: '2017-01-01',
      value: 573.0,
    },
    {
      date: '2017-02-01',
      value: 587.0,
    },
    {
      date: '2017-03-01',
      value: 632.0,
    },
    {
      date: '2017-04-01',
      value: 598.0,
    },
    {
      date: '2017-05-01',
      value: 635.0,
    },
    {
      date: '2017-06-01',
      value: 619.0,
    },
    {
      date: '2017-07-01',
      value: 572.0,
    },
    {
      date: '2017-08-01',
      value: 556.0,
    },
    {
      date: '2017-09-01',
      value: 637.0,
    },
    {
      date: '2017-10-01',
      value: 626.0,
    },
    {
      date: '2017-11-01',
      value: 711.0,
    },
    {
      date: '2017-12-01',
      value: 630.0,
    },
    {
      date: '2018-01-01',
      value: 590.0,
    },
    {
      date: '2018-02-01',
      value: 618.0,
    },
    {
      date: '2018-03-01',
      value: 679.0,
    },
    {
      date: '2018-04-01',
      value: 642.0,
    },
    {
      date: '2018-05-01',
      value: 662.0,
    },
    {
      date: '2018-06-01',
      value: 636.0,
    },
    {
      date: '2018-07-01',
      value: 628.0,
    },
    {
      date: '2018-08-01',
      value: 593.0,
    },
    {
      date: '2018-09-01',
      value: 595.0,
    },
    {
      date: '2018-10-01',
      value: 554.0,
    },
    {
      date: '2018-11-01',
      value: 625.0,
    },
    {
      date: '2018-12-01',
      value: 546.0,
    },
    {
      date: '2019-01-01',
      value: 591.0,
    },
    {
      date: '2019-02-01',
      value: 638.0,
    },
    {
      date: '2019-03-01',
      value: 705.0,
    },
    {
      date: '2019-04-01',
      value: 695.0,
    },
    {
      date: '2019-05-01',
      value: 602.0,
    },
    {
      date: '2019-06-01',
      value: 763.0,
    },
    {
      date: '2019-07-01',
      value: 669.0,
    },
    {
      date: '2019-08-01',
      value: 720.0,
    },
    {
      date: '2019-09-01',
      value: 715.0,
    },
    {
      date: '2019-10-01',
      value: 721.0,
    },
    {
      date: '2019-11-01',
      value: 710.0,
    },
    {
      date: '2019-12-01',
      value: 693.0,
    },
    {
      date: '2020-01-01',
      value: 685.0,
    },
    {
      date: '2020-02-01',
      value: 699.0,
    },
    {
      date: '2020-03-01',
      value: 609.0,
    },
    {
      date: '2020-04-01',
      value: 569.0,
    },
    {
      date: '2020-05-01',
      value: 696.0,
    },
    {
      date: '2020-06-01',
      value: 936.0,
    },
    {
      date: '2020-07-01',
      value: 1019.0,
    },
    {
      date: '2020-08-01',
      value: 1029.0,
    },
    {
      date: '2020-09-01',
      value: 988.0,
    },
    {
      date: '2020-10-01',
      value: 1027.0,
    },
    {
      date: '2020-11-01',
      value: 863.0,
    },
    {
      date: '2020-12-01',
      value: 873.0,
    },
    {
      date: '2021-01-01',
      value: 901.0,
    },
    {
      date: '2021-02-01',
      value: 765.0,
    },
    {
      date: '2021-03-01',
      value: 850.0,
    },
    {
      date: '2021-04-01',
      value: 810.0,
    },
    {
      date: '2021-05-01',
      value: 715.0,
    },
    {
      date: '2021-06-01',
      value: 716.0,
    },
    {
      date: '2021-07-01',
      value: 760.0,
    },
    {
      date: '2021-08-01',
      value: 690.0,
    },
    {
      date: '2021-09-01',
      value: 742.0,
    },
    {
      date: '2021-10-01',
      value: 680.0,
    },
    {
      date: '2021-11-01',
      value: 772.0,
    },
    {
      date: '2021-12-01',
      value: 830.0,
    },
    {
      date: '2022-01-01',
      value: 810.0,
    },
    {
      date: '2022-02-01',
      value: 773.0,
    },
    {
      date: '2022-03-01',
      value: 707.0,
    },
    {
      date: '2022-04-01',
      value: 611.0,
    },
    {
      date: '2022-05-01',
      value: 636.0,
    },
    {
      date: '2022-06-01',
      value: 563.0,
    },
    {
      date: '2022-07-01',
      value: 543.0,
    },
    {
      date: '2022-08-01',
      value: 638.0,
    },
    {
      date: '2022-09-01',
      value: 567.0,
    },
    {
      date: '2022-10-01',
      value: 577.0,
    },
    {
      date: '2022-11-01',
      value: 582.0,
    },
    {
      date: '2022-12-01',
      value: 636.0,
    },
    {
      date: '2023-01-01',
      value: 649.0,
    },
    {
      date: '2023-02-01',
      value: 625.0,
    },
    {
      date: '2023-03-01',
      value: 640.0,
    },
    {
      date: '2023-04-01',
      value: 679.0,
    },
    {
      date: '2023-05-01',
      value: 710.0,
    },
    {
      date: '2023-06-01',
      value: 684.0,
    },
    {
      date: '2023-07-01',
      value: 739.0,
    },
    {
      date: '2023-08-01',
      value: 675.0,
    },
  ],
  dataikuD: {
    table: '111_HD_US_CENSUS_postgres',
    columnToWork: 'value',
    frecuency: 'monthly',
    date: 'label_time',
    nlags: 100,
    algorithm: '1',
    window_size_0_1: 0.5,
    window_size: 5.0,
    degree: 3.0,
    smoothing_factor: 3.0,
    stl_trend_smoother: 11.0,
    stl_seasonal_smoother: 7.0,
    stl_period: 7.0,
  },
  totalRows: 727,
};

export const REVENUE_VAR138 = {
  metadata: {
    name: 'Wood Products: U.S. Total   Not Seasonally Adjusted Net Sales,Receipts,and Operating Revenues [Millions of Dollars]',
    description:
      'Wood Products: U.S. Total   Not Seasonally Adjusted Net Sales,Receipts,and Operating Revenues [Millions of Dollars]',
    classification: 'Economic State of Risk',
    evidenceType: 'Observation, Model Prediction',
    temporalResolution: 'monthly',
    timePeriod: '2000-2023',
    source: 'US Census Bureau',
    citation:
      'Economic Indicator Database (2023, June 6). U.S. Census Bureau. https://www.census.gov/econ_timeseries/?programCode=QFR&startYear=2000&endYear=2023&categories[]=321&dataType=101&geoLevel=US&adjusted=0 Adjusted=1&errorData=0',
    node: 'Revenue',
    net: 'Wood',
    dashboard: 'USA',
    nameShort: 'Net Sales, Receipts, and Operating Revenues',
    timeUnit: 'quarter',
    isDate: true,
    yAxis: 'Millions of Dollars',
    typeVariable: 'Time Series',
    multipleFilters: 0,
    multipleVariables: false,
  },
  data: [
    {
      date: '2000 Oct-Dec',
      value: 14441.0,
    },
    {
      date: '2001 Apr-Jun',
      value: 15442.0,
    },
    {
      date: '2001 Jan-Mar',
      value: 13665.0,
    },
    {
      date: '2001 Jul-Sep',
      value: 16531.0,
    },
    {
      date: '2001 Oct-Dec',
      value: 14824.0,
    },
    {
      date: '2002 Apr-Jun',
      value: 16473.0,
    },
    {
      date: '2002 Jan-Mar',
      value: 14709.0,
    },
    {
      date: '2002 Jul-Sep',
      value: 16293.0,
    },
    {
      date: '2002 Oct-Dec',
      value: 15009.0,
    },
    {
      date: '2003 Apr-Jun',
      value: 15456.0,
    },
    {
      date: '2003 Jan-Mar',
      value: 14014.0,
    },
    {
      date: '2003 Jul-Sep',
      value: 16621.0,
    },
    {
      date: '2003 Oct-Dec',
      value: 16209.0,
    },
    {
      date: '2004 Apr-Jun',
      value: 19412.0,
    },
    {
      date: '2004 Jan-Mar',
      value: 17121.0,
    },
    {
      date: '2004 Jul-Sep',
      value: 19852.0,
    },
    {
      date: '2004 Oct-Dec',
      value: 18119.0,
    },
    {
      date: '2005 Apr-Jun',
      value: 21015.0,
    },
    {
      date: '2005 Jan-Mar',
      value: 17922.0,
    },
    {
      date: '2005 Jul-Sep',
      value: 20557.0,
    },
    {
      date: '2005 Oct-Dec',
      value: 19833.0,
    },
    {
      date: '2006 Apr-Jun',
      value: 21013.0,
    },
    {
      date: '2006 Jan-Mar',
      value: 18667.0,
    },
    {
      date: '2006 Jul-Sep',
      value: 20126.0,
    },
    {
      date: '2006 Oct-Dec',
      value: 17800.0,
    },
    {
      date: '2007 Apr-Jun',
      value: 19391.0,
    },
    {
      date: '2007 Jan-Mar',
      value: 17283.0,
    },
    {
      date: '2007 Jul-Sep',
      value: 19364.0,
    },
    {
      date: '2007 Oct-Dec',
      value: 17137.0,
    },
    {
      date: '2008 Apr-Jun',
      value: 17447.0,
    },
    {
      date: '2008 Jan-Mar',
      value: 15560.0,
    },
    {
      date: '2008 Jul-Sep',
      value: 19205.0,
    },
    {
      date: '2008 Oct-Dec',
      value: 15711.0,
    },
    {
      date: '2009 Apr-Jun',
      value: 14658.0,
    },
    {
      date: '2009 Jan-Mar',
      value: 12996.0,
    },
    {
      date: '2009 Jul-Sep',
      value: 15821.0,
    },
    {
      date: '2009 Oct-Dec',
      value: 14172.0,
    },
    {
      date: '2010 Apr-Jun',
      value: 17389.0,
    },
    {
      date: '2010 Jan-Mar',
      value: 14048.0,
    },
    {
      date: '2010 Jul-Sep',
      value: 16583.0,
    },
    {
      date: '2010 Oct-Dec',
      value: 15435.0,
    },
    {
      date: '2011 Apr-Jun',
      value: 15980.0,
    },
    {
      date: '2011 Jan-Mar',
      value: 14714.0,
    },
    {
      date: '2011 Jul-Sep',
      value: 16476.0,
    },
    {
      date: '2011 Oct-Dec',
      value: 15267.0,
    },
    {
      date: '2012 Apr-Jun',
      value: 18309.0,
    },
    {
      date: '2012 Jan-Mar',
      value: 15898.0,
    },
    {
      date: '2012 Jul-Sep',
      value: 18313.0,
    },
    {
      date: '2012 Oct-Dec',
      value: 18063.0,
    },
    {
      date: '2013 Apr-Jun',
      value: 20847.0,
    },
    {
      date: '2013 Jan-Mar',
      value: 19127.0,
    },
    {
      date: '2013 Jul-Sep',
      value: 21433.0,
    },
    {
      date: '2013 Oct-Dec',
      value: 21420.0,
    },
    {
      date: '2014 Apr-Jun',
      value: 20616.0,
    },
    {
      date: '2014 Jan-Mar',
      value: 21428.0,
    },
    {
      date: '2014 Jul-Sep',
      value: 21497.0,
    },
    {
      date: '2014 Oct-Dec',
      value: 20925.0,
    },
    {
      date: '2015 Apr-Jun',
      value: 20322.0,
    },
    {
      date: '2015 Jan-Mar',
      value: 21301.0,
    },
    {
      date: '2015 Jul-Sep',
      value: 20754.0,
    },
    {
      date: '2015 Oct-Dec',
      value: 19629.0,
    },
    {
      date: '2016 Apr-Jun',
      value: 21509.0,
    },
    {
      date: '2016 Jan-Mar',
      value: 18719.0,
    },
    {
      date: '2016 Jul-Sep',
      value: 22910.0,
    },
    {
      date: '2016 Oct-Dec',
      value: 22839.0,
    },
    {
      date: '2017 Apr-Jun',
      value: 23664.0,
    },
    {
      date: '2017 Jan-Mar',
      value: 20298.0,
    },
    {
      date: '2017 Jul-Sep',
      value: 24921.0,
    },
    {
      date: '2017 Oct-Dec',
      value: 26966.0,
    },
    {
      date: '2018 Apr-Jun',
      value: 27444.0,
    },
    {
      date: '2018 Jan-Mar',
      value: 23447.0,
    },
    {
      date: '2018 Jul-Sep',
      value: 27667.0,
    },
    {
      date: '2018 Oct-Dec',
      value: 23703.0,
    },
    {
      date: '2019 Apr-Jun',
      value: 23671.0,
    },
    {
      date: '2019 Jan-Mar',
      value: 18646.0,
    },
    {
      date: '2019 Jul-Sep',
      value: 25213.0,
    },
    {
      date: '2019 Oct-Dec',
      value: 24425.0,
    },
    {
      date: '2020 Apr-Jun',
      value: 22585.0,
    },
    {
      date: '2020 Jan-Mar',
      value: 20697.0,
    },
    {
      date: '2020 Jul-Sep',
      value: 28517.0,
    },
    {
      date: '2020 Oct-Dec',
      value: 30427.0,
    },
    {
      date: '2021 Apr-Jun',
      value: 40918.0,
    },
    {
      date: '2021 Jan-Mar',
      value: 29158.0,
    },
    {
      date: '2021 Jul-Sep',
      value: 33859.0,
    },
    {
      date: '2021 Oct-Dec',
      value: 32128.0,
    },
    {
      date: '2022 Apr-Jun',
      value: 41059.0,
    },
    {
      date: '2022 Jan-Mar',
      value: 37635.0,
    },
    {
      date: '2022 Jul-Sep',
      value: 36199.0,
    },
    {
      date: '2022 Oct-Dec',
      value: 30646.0,
    },
    {
      date: '2023 Jan-Mar',
      value: 28134.0,
    },
  ],
  dataikuD: {
    table: '138_REV_US_CENSUS_postgres',
    columnToWork: 'value',
    frecuency: 'quarter',
    date: 'label_time',
    nlags: 89,
    algorithm: '5',
    window_size_0_1: 0.5,
    window_size: 5.0,
    degree: 3.0,
    smoothing_factor: 3.0,
    stl_trend_smoother: 11.0,
    stl_seasonal_smoother: 7.0,
    stl_period: 7.0,
  },
  totalRows: 89,
};

export const WOOD_PRODUCTION_VAR129 = {
  metadata: {
    name: 'Wood Product Manufacturing - Production',
    description: 'Wood Product Manufacturing - Production',
    classification: 'Manufacturing',
    evidenceType: 'Observation',
    temporalResolution: 'monthly',
    timePeriod: '1993-2023',
    source: 'Data Mexico',
    citation:
      'Producto Interno Bruto Trimestral por Actividad Economica. (N.A). INEGI. https://www.inegi.org.mx/temas/pib/default.html#Tabulados',
    node: 'Wood Product Manufacturing',
    net: 'Wood',
    dashboard: 'MEX',
    nameShort: 'GDP',
    timeUnit: 'quarter',
    isDate: true,
    yAxis: 'M.X. Million',
    typeVariable: 'Time Series',
    multipleFilters: 0,
    multipleVariables: false,
  },
  data: [
    {
      date: '1993 Apr-Jun',
      value: 280716.0,
    },
    {
      date: '1993 Jan-Mar',
      value: 279539.0,
    },
    {
      date: '1993 Jul-Sep',
      value: 279116.0,
    },
    {
      date: '1993 Oct-Dic',
      value: 293936.0,
    },
    {
      date: '1994 Apr-Jun',
      value: 310089.0,
    },
    {
      date: '1994 Jan-Mar',
      value: 292342.0,
    },
    {
      date: '1994 Jul-Sep',
      value: 310215.0,
    },
    {
      date: '1994 Oct-Dic',
      value: 326127.0,
    },
    {
      date: '1995 Apr-Jun',
      value: 424692.0,
    },
    {
      date: '1995 Jan-Mar',
      value: 390879.0,
    },
    {
      date: '1995 Jul-Sep',
      value: 444757.0,
    },
    {
      date: '1995 Oct-Dic',
      value: 510372.0,
    },
    {
      date: '1996 Apr-Jun',
      value: 608345.0,
    },
    {
      date: '1996 Jan-Mar',
      value: 586670.0,
    },
    {
      date: '1996 Jul-Sep',
      value: 626376.0,
    },
    {
      date: '1996 Oct-Dic',
      value: 702325.0,
    },
    {
      date: '1997 Apr-Jun',
      value: 795695.0,
    },
    {
      date: '1997 Jan-Mar',
      value: 740086.0,
    },
    {
      date: '1997 Jul-Sep',
      value: 805608.0,
    },
    {
      date: '1997 Oct-Dic',
      value: 860983.0,
    },
    {
      date: '1998 Apr-Jun',
      value: 929648.0,
    },
    {
      date: '1998 Jan-Mar',
      value: 937975.0,
    },
    {
      date: '1998 Jul-Sep',
      value: 974755.0,
    },
    {
      date: '1998 Oct-Dic',
      value: 1027330.0,
    },
    {
      date: '1999 Apr-Jun',
      value: 1100317.0,
    },
    {
      date: '1999 Jan-Mar',
      value: 1069904.0,
    },
    {
      date: '1999 Jul-Sep',
      value: 1135390.0,
    },
    {
      date: '1999 Oct-Dic',
      value: 1121077.0,
    },
    {
      date: '2000 Apr-Jun',
      value: 1255634.0,
    },
    {
      date: '2000 Jan-Mar',
      value: 1236294.0,
    },
    {
      date: '2000 Jul-Sep',
      value: 1323720.0,
    },
    {
      date: '2000 Oct-Dic',
      value: 1268296.0,
    },
    {
      date: '2001 Apr-Jun',
      value: 1275378.0,
    },
    {
      date: '2001 Jan-Mar',
      value: 1312321.0,
    },
    {
      date: '2001 Jul-Sep',
      value: 1278554.0,
    },
    {
      date: '2001 Oct-Dic',
      value: 1238371.0,
    },
    {
      date: '2002 Apr-Jun',
      value: 1304079.0,
    },
    {
      date: '2002 Jan-Mar',
      value: 1235077.0,
    },
    {
      date: '2002 Jul-Sep',
      value: 1308856.0,
    },
    {
      date: '2002 Oct-Dic',
      value: 1301497.0,
    },
    {
      date: '2003 Apr-Jun',
      value: 1301893.0,
    },
    {
      date: '2003 Jan-Mar',
      value: 1281671.0,
    },
    {
      date: '2003 Jul-Sep',
      value: 1306065.0,
    },
    {
      date: '2003 Oct-Dic',
      value: 1338088.0,
    },
    {
      date: '2004 Apr-Jun',
      value: 1476095.0,
    },
    {
      date: '2004 Jan-Mar',
      value: 1389021.0,
    },
    {
      date: '2004 Jul-Sep',
      value: 1510891.0,
    },
    {
      date: '2004 Oct-Dic',
      value: 1506853.0,
    },
    {
      date: '2005 Apr-Jun',
      value: 1509479.0,
    },
    {
      date: '2005 Jan-Mar',
      value: 1441019.0,
    },
    {
      date: '2005 Jul-Sep',
      value: 1494187.0,
    },
    {
      date: '2005 Oct-Dic',
      value: 1570832.0,
    },
    {
      date: '2006 Apr-Jun',
      value: 1788780.0,
    },
    {
      date: '2006 Jan-Mar',
      value: 1644420.0,
    },
    {
      date: '2006 Jul-Sep',
      value: 1836827.0,
    },
    {
      date: '2006 Oct-Dic',
      value: 1814412.0,
    },
    {
      date: '2007 Apr-Jun',
      value: 1853390.0,
    },
    {
      date: '2007 Jan-Mar',
      value: 1800631.0,
    },
    {
      date: '2007 Jul-Sep',
      value: 1848726.0,
    },
    {
      date: '2007 Oct-Dic',
      value: 1823443.0,
    },
    {
      date: '2008 Apr-Jun',
      value: 2007029.0,
    },
    {
      date: '2008 Jan-Mar',
      value: 1858689.0,
    },
    {
      date: '2008 Jul-Sep',
      value: 1997988.0,
    },
    {
      date: '2008 Oct-Dic',
      value: 1964775.0,
    },
    {
      date: '2009 Apr-Jun',
      value: 1750628.0,
    },
    {
      date: '2009 Jan-Mar',
      value: 1770600.0,
    },
    {
      date: '2009 Jul-Sep',
      value: 1847617.0,
    },
    {
      date: '2009 Oct-Dic',
      value: 1975619.0,
    },
    {
      date: '2010 Apr-Jun',
      value: 2098066.0,
    },
    {
      date: '2010 Jan-Mar',
      value: 1990818.0,
    },
    {
      date: '2010 Jul-Sep',
      value: 2108292.0,
    },
    {
      date: '2010 Oct-Dic',
      value: 2122953.0,
    },
    {
      date: '2011 Apr-Jun',
      value: 2194275.0,
    },
    {
      date: '2011 Jan-Mar',
      value: 2139225.0,
    },
    {
      date: '2011 Jul-Sep',
      value: 2261946.0,
    },
    {
      date: '2011 Oct-Dic',
      value: 2409970.0,
    },
    {
      date: '2012 Apr-Jun',
      value: 2613815.0,
    },
    {
      date: '2012 Jan-Mar',
      value: 2529042.0,
    },
    {
      date: '2012 Jul-Sep',
      value: 2603715.0,
    },
    {
      date: '2012 Oct-Dic',
      value: 2596166.0,
    },
    {
      date: '2013 Apr-Jun',
      value: 2612466.0,
    },
    {
      date: '2013 Jan-Mar',
      value: 2539760.0,
    },
    {
      date: '2013 Jul-Sep',
      value: 2579653.0,
    },
    {
      date: '2013 Oct-Dic',
      value: 2576022.0,
    },
    {
      date: '2014 Apr-Jun',
      value: 2761837.0,
    },
    {
      date: '2014 Jan-Mar',
      value: 2652944.0,
    },
    {
      date: '2014 Jul-Sep',
      value: 2805361.0,
    },
    {
      date: '2014 Oct-Dic',
      value: 2910857.0,
    },
    {
      date: '2015 Apr-Jun',
      value: 3180893.0,
    },
    {
      date: '2015 Jan-Mar',
      value: 3038842.0,
    },
    {
      date: '2015 Jul-Sep',
      value: 3259031.0,
    },
    {
      date: '2015 Oct-Dic',
      value: 3270339.0,
    },
    {
      date: '2016 Apr-Jun',
      value: 3444329.0,
    },
    {
      date: '2016 Jan-Mar',
      value: 3298090.0,
    },
    {
      date: '2016 Jul-Sep',
      value: 3492922.0,
    },
    {
      date: '2016 Oct-Dic',
      value: 3597709.0,
    },
    {
      date: '2017 Apr-Jun',
      value: 3835792.0,
    },
    {
      date: '2017 Jan-Mar',
      value: 3845545.0,
    },
    {
      date: '2017 Jul-Sep',
      value: 3764254.0,
    },
    {
      date: '2017 Oct-Dic',
      value: 3856779.0,
    },
    {
      date: '2018 Apr-Jun',
      value: 4241319.0,
    },
    {
      date: '2018 Jan-Mar',
      value: 3968736.0,
    },
    {
      date: '2018 Jul-Sep',
      value: 4162948.0,
    },
    {
      date: '2018 Oct-Dic',
      value: 4134872.0,
    },
    {
      date: '2019 Apr-Jun',
      value: 4261557.0,
    },
    {
      date: '2019 Jan-Mar',
      value: 4152661.0,
    },
    {
      date: '2019 Jul-Sep',
      value: 4318357.0,
    },
    {
      date: '2019 Oct-Dic',
      value: 4139248.0,
    },
    {
      date: '2020 Apr-Jun',
      value: 3223995.0,
    },
    {
      date: '2020 Jan-Mar',
      value: 4281313.0,
    },
    {
      date: '2020 Jul-Sep',
      value: 4343312.0,
    },
    {
      date: '2020 Oct-Dic',
      value: 4433408.0,
    },
    {
      date: '2021 Apr-Jun',
      value: 4632369.0,
    },
    {
      date: '2021 Jan-Mar',
      value: 4537900.0,
    },
    {
      date: '2021 Jul-Sep',
      value: 4692577.0,
    },
    {
      date: '2021 Oct-Dic',
      value: 4871355.0,
    },
    {
      date: '2022 Apr-Jun',
      value: 5350973.0,
    },
    {
      date: '2022 Jan-Mar',
      value: 5166447.0,
    },
    {
      date: '2022 Jul-Sep',
      value: 5533310.0,
    },
    {
      date: '2022 Oct-Dic',
      value: 5425028.0,
    },
    {
      date: '2023 Jan-Mar',
      value: 5475600.0,
    },
  ],
  dataikuD: {
    table: '129_WPM_MEX_DATAMEX_postgres',
    columnToWork: 'value',
    frecuency: 'quarter',
    date: 'label_time',
    nlags: 120,
    algorithm: '3',
    window_size_0_1: 0.5,
    window_size: 5.0,
    degree: 3.0,
    smoothing_factor: 3.0,
    stl_trend_smoother: 11.0,
    stl_seasonal_smoother: 7.0,
    stl_period: 7.0,
  },
  totalRows: 120,
};

export const TEST_DATA_1 = {
  data_States: [
    {
      name: '01',
      values: [1.8],
    },
    {
      name: '11',
      values: [0.67],
    },
    {
      name: '20',
      values: [1.66],
    },
    {
      name: '21',
      values: [2.64],
    },
    {
      name: '22',
      values: [13.33],
    },
    {
      name: '40',
      values: [2.65],
    },
    {
      name: '50',
      values: [4.74],
    },
    {
      name: '51',
      values: [2.89],
    },
    {
      name: '60',
      values: [14.58],
    },
    {
      name: '61',
      values: [4.46],
    },
    {
      name: '80',
      values: [1.94],
    },
    {
      name: '81',
      values: [0.79],
    },
    {
      name: '90',
      values: [3.05],
    },
    {
      name: '12',
      values: [0.43],
    },
    {
      name: '13',
      values: [0.91],
    },
    {
      name: '15',
      values: [2.14],
    },
    {
      name: '16',
      values: [6.6],
    },
    {
      name: '17',
      values: [0.58],
    },
    {
      name: '18',
      values: [0.81],
    },
    {
      name: '19',
      values: [0.75],
    },
    {
      name: '23',
      values: [2.72],
    },
    {
      name: '24',
      values: [2.96],
    },
    {
      name: '25',
      values: [1.16],
    },
    {
      name: '26',
      values: [0.13],
    },
    {
      name: '27',
      values: [0.14],
    },
    {
      name: '28',
      values: [6.73],
    },
    {
      name: '29',
      values: [0.4],
    },
    {
      name: '30',
      values: [0.35],
    },
    {
      name: '31',
      values: [0.88],
    },
    {
      name: '32',
      values: [3.88],
    },
    {
      name: '33',
      values: [1.29],
    },
    {
      name: '34',
      values: [3.26],
    },
    {
      name: '35',
      values: [2.07],
    },
    {
      name: '36',
      values: [0.65],
    },
    {
      name: '37',
      values: [0.58],
    },
    {
      name: '38',
      values: [0.0],
    },
    {
      name: '39',
      values: [0.74],
    },
    {
      name: '41',
      values: [4.1],
    },
    {
      name: '42',
      values: [1.48],
    },
    {
      name: '44',
      values: [6.72],
    },
    {
      name: '45',
      values: [0.09],
    },
    {
      name: '46',
      values: [0.6],
    },
    {
      name: '47',
      values: [2.34],
    },
    {
      name: '48',
      values: [0.48],
    },
    {
      name: '49',
      values: [5.54],
    },
    {
      name: '53',
      values: [5.63],
    },
    {
      name: '54',
      values: [1.16],
    },
    {
      name: '55',
      values: [0.5],
    },
    {
      name: '56',
      values: [1.23],
    },
    {
      name: '72',
      values: [0.0],
    },
    {
      name: '78',
      values: [0.0],
    },
  ],
  maxState: 14,
  dates: ['1/1/2015'],
};

export const TEST_DATA_2 = [
  {
    date: '1985-01-01',
    value: 2.57,
  },
  {
    date: '1985-02-01',
    value: 2.86,
  },
  {
    date: '1985-03-01',
    value: 2.95,
  },
  {
    date: '1985-04-01',
    value: 2.0,
  },
  {
    date: '1985-05-01',
    value: 2.39,
  },
  {
    date: '1985-06-01',
    value: 3.81,
  },
  {
    date: '1985-07-01',
    value: 3.11,
  },
  {
    date: '1985-08-01',
    value: 1.83,
  },
  {
    date: '1985-09-01',
    value: 2.49,
  },
  {
    date: '1985-10-01',
    value: 2.82,
  },
  {
    date: '1985-11-01',
    value: 2.43,
  },
  {
    date: '1985-12-01',
    value: 2.28,
  },
  {
    date: '1986-01-01',
    value: 3.38,
  },
  {
    date: '1986-02-01',
    value: 2.3,
  },
  {
    date: '1986-03-01',
    value: 2.43,
  },
  {
    date: '1986-04-01',
    value: 4.08,
  },
  {
    date: '1986-05-01',
    value: 2.92,
  },
  {
    date: '1986-06-01',
    value: 2.33,
  },
  {
    date: '1986-07-01',
    value: 2.26,
  },
  {
    date: '1986-08-01',
    value: 2.52,
  },
  {
    date: '1986-09-01',
    value: 2.99,
  },
  {
    date: '1986-10-01',
    value: 2.52,
  },
  {
    date: '1986-11-01',
    value: 2.8,
  },
  {
    date: '1986-12-01',
    value: 2.24,
  },
  {
    date: '1987-01-01',
    value: 2.42,
  },
  {
    date: '1987-02-01',
    value: 2.57,
  },
  {
    date: '1987-03-01',
    value: 2.28,
  },
  {
    date: '1987-04-01',
    value: 2.87,
  },
  {
    date: '1987-05-01',
    value: 2.52,
  },
  {
    date: '1987-06-01',
    value: 2.37,
  },
  {
    date: '1987-07-01',
    value: 2.53,
  },
  {
    date: '1987-08-01',
    value: 2.76,
  },
  {
    date: '1987-09-01',
    value: 3.14,
  },
  {
    date: '1987-10-01',
    value: 2.59,
  },
  {
    date: '1987-11-01',
    value: 2.52,
  },
  {
    date: '1987-12-01',
    value: 2.95,
  },
  {
    date: '1988-01-01',
    value: 2.06,
  },
  {
    date: '1988-02-01',
    value: 2.15,
  },
  {
    date: '1988-03-01',
    value: 2.81,
  },
  {
    date: '1988-04-01',
    value: 3.09,
  },
  {
    date: '1988-05-01',
    value: 2.65,
  },
  {
    date: '1988-06-01',
    value: 1.95,
  },
  {
    date: '1988-07-01',
    value: 2.11,
  },
  {
    date: '1988-08-01',
    value: 2.13,
  },
  {
    date: '1988-09-01',
    value: 1.93,
  },
  {
    date: '1988-10-01',
    value: 1.86,
  },
  {
    date: '1988-11-01',
    value: 1.73,
  },
  {
    date: '1988-12-01',
    value: 2.37,
  },
  {
    date: '1989-01-01',
    value: 2.16,
  },
  {
    date: '1989-02-01',
    value: 2.21,
  },
  {
    date: '1989-03-01',
    value: 1.95,
  },
  {
    date: '1989-04-01',
    value: 1.96,
  },
  {
    date: '1989-05-01',
    value: 2.53,
  },
  {
    date: '1989-06-01',
    value: 2.16,
  },
  {
    date: '1989-07-01',
    value: 1.95,
  },
  {
    date: '1989-08-01',
    value: 2.73,
  },
  {
    date: '1989-09-01',
    value: 2.08,
  },
  {
    date: '1989-10-01',
    value: 1.66,
  },
  {
    date: '1989-11-01',
    value: 2.36,
  },
  {
    date: '1989-12-01',
    value: 2.79,
  },
  {
    date: '1990-01-01',
    value: 1.92,
  },
  {
    date: '1990-02-01',
    value: 1.92,
  },
  {
    date: '1990-03-01',
    value: 1.6,
  },
  {
    date: '1990-04-01',
    value: 1.96,
  },
  {
    date: '1990-05-01',
    value: 2.11,
  },
  {
    date: '1990-06-01',
    value: 1.99,
  },
  {
    date: '1990-07-01',
    value: 2.0,
  },
  {
    date: '1990-08-01',
    value: 6.41,
  },
  {
    date: '1990-09-01',
    value: 4.95,
  },
  {
    date: '1990-10-01',
    value: 3.6,
  },
  {
    date: '1990-11-01',
    value: 4.11,
  },
  {
    date: '1990-12-01',
    value: 3.98,
  },
  {
    date: '1991-01-01',
    value: 9.6,
  },
  {
    date: '1991-02-01',
    value: 8.5,
  },
  {
    date: '1991-03-01',
    value: 3.82,
  },
  {
    date: '1991-04-01',
    value: 2.41,
  },
  {
    date: '1991-05-01',
    value: 2.11,
  },
  {
    date: '1991-06-01',
    value: 2.14,
  },
  {
    date: '1991-07-01',
    value: 2.66,
  },
  {
    date: '1991-08-01',
    value: 2.53,
  },
  {
    date: '1991-09-01',
    value: 2.71,
  },
  {
    date: '1991-10-01',
    value: 2.33,
  },
  {
    date: '1991-11-01',
    value: 2.3,
  },
  {
    date: '1991-12-01',
    value: 2.53,
  },
  {
    date: '1992-01-01',
    value: 2.36,
  },
  {
    date: '1992-02-01',
    value: 2.11,
  },
  {
    date: '1992-03-01',
    value: 1.98,
  },
  {
    date: '1992-04-01',
    value: 1.99,
  },
  {
    date: '1992-05-01',
    value: 1.83,
  },
  {
    date: '1992-06-01',
    value: 2.21,
  },
  {
    date: '1992-07-01',
    value: 1.74,
  },
  {
    date: '1992-08-01',
    value: 1.82,
  },
  {
    date: '1992-09-01',
    value: 1.7,
  },
  {
    date: '1992-10-01',
    value: 1.48,
  },
  {
    date: '1992-11-01',
    value: 2.07,
  },
  {
    date: '1992-12-01',
    value: 1.99,
  },
  {
    date: '1993-01-01',
    value: 2.84,
  },
  {
    date: '1993-02-01',
    value: 1.95,
  },
  {
    date: '1993-03-01',
    value: 2.16,
  },
  {
    date: '1993-04-01',
    value: 2.44,
  },
  {
    date: '1993-05-01',
    value: 2.51,
  },
  {
    date: '1993-06-01',
    value: 2.27,
  },
  {
    date: '1993-07-01',
    value: 2.62,
  },
  {
    date: '1993-08-01',
    value: 2.12,
  },
  {
    date: '1993-09-01',
    value: 1.9,
  },
  {
    date: '1993-10-01',
    value: 2.32,
  },
  {
    date: '1993-11-01',
    value: 1.78,
  },
  {
    date: '1993-12-01',
    value: 1.72,
  },
  {
    date: '1994-01-01',
    value: 1.84,
  },
  {
    date: '1994-02-01',
    value: 2.65,
  },
  {
    date: '1994-03-01',
    value: 2.0,
  },
  {
    date: '1994-04-01',
    value: 2.18,
  },
  {
    date: '1994-05-01',
    value: 2.36,
  },
  {
    date: '1994-06-01',
    value: 2.76,
  },
  {
    date: '1994-07-01',
    value: 2.28,
  },
  {
    date: '1994-08-01',
    value: 2.13,
  },
  {
    date: '1994-09-01',
    value: 2.32,
  },
  {
    date: '1994-10-01',
    value: 2.16,
  },
  {
    date: '1994-11-01',
    value: 1.92,
  },
  {
    date: '1994-12-01',
    value: 2.1,
  },
  {
    date: '1995-01-01',
    value: 1.67,
  },
  {
    date: '1995-02-01',
    value: 1.59,
  },
  {
    date: '1995-03-01',
    value: 1.74,
  },
  {
    date: '1995-04-01',
    value: 2.2,
  },
  {
    date: '1995-05-01',
    value: 1.99,
  },
  {
    date: '1995-06-01',
    value: 2.13,
  },
  {
    date: '1995-07-01',
    value: 2.28,
  },
  {
    date: '1995-08-01',
    value: 2.38,
  },
  {
    date: '1995-09-01',
    value: 1.45,
  },
  {
    date: '1995-10-01',
    value: 1.38,
  },
  {
    date: '1995-11-01',
    value: 1.55,
  },
  {
    date: '1995-12-01',
    value: 1.41,
  },
  {
    date: '1996-01-01',
    value: 1.47,
  },
  {
    date: '1996-02-01',
    value: 1.65,
  },
  {
    date: '1996-03-01',
    value: 1.72,
  },
  {
    date: '1996-04-01',
    value: 1.78,
  },
  {
    date: '1996-05-01',
    value: 1.32,
  },
  {
    date: '1996-06-01',
    value: 1.31,
  },
  {
    date: '1996-07-01',
    value: 1.6,
  },
  {
    date: '1996-08-01',
    value: 1.6,
  },
  {
    date: '1996-09-01',
    value: 2.02,
  },
  {
    date: '1996-10-01',
    value: 1.07,
  },
  {
    date: '1996-11-01',
    value: 1.09,
  },
  {
    date: '1996-12-01',
    value: 1.51,
  },
  {
    date: '1997-01-01',
    value: 0.97,
  },
  {
    date: '1997-02-01',
    value: 1.05,
  },
  {
    date: '1997-03-01',
    value: 1.15,
  },
  {
    date: '1997-04-01',
    value: 1.3,
  },
  {
    date: '1997-05-01',
    value: 1.1,
  },
  {
    date: '1997-06-01',
    value: 0.75,
  },
  {
    date: '1997-07-01',
    value: 0.91,
  },
  {
    date: '1997-08-01',
    value: 1.05,
  },
  {
    date: '1997-09-01',
    value: 0.86,
  },
  {
    date: '1997-10-01',
    value: 1.07,
  },
  {
    date: '1997-11-01',
    value: 1.49,
  },
  {
    date: '1997-12-01',
    value: 0.95,
  },
  {
    date: '1998-01-01',
    value: 1.11,
  },
  {
    date: '1998-02-01',
    value: 1.84,
  },
  {
    date: '1998-03-01',
    value: 1.25,
  },
  {
    date: '1998-04-01',
    value: 1.07,
  },
  {
    date: '1998-05-01',
    value: 1.96,
  },
  {
    date: '1998-06-01',
    value: 1.58,
  },
  {
    date: '1998-07-01',
    value: 1.15,
  },
  {
    date: '1998-08-01',
    value: 2.23,
  },
  {
    date: '1998-09-01',
    value: 1.42,
  },
  {
    date: '1998-10-01',
    value: 1.23,
  },
  {
    date: '1998-11-01',
    value: 1.62,
  },
  {
    date: '1998-12-01',
    value: 1.78,
  },
  {
    date: '1999-01-01',
    value: 1.32,
  },
  {
    date: '1999-02-01',
    value: 1.25,
  },
  {
    date: '1999-03-01',
    value: 1.93,
  },
  {
    date: '1999-04-01',
    value: 2.36,
  },
  {
    date: '1999-05-01',
    value: 1.85,
  },
  {
    date: '1999-06-01',
    value: 1.63,
  },
  {
    date: '1999-07-01',
    value: 1.24,
  },
  {
    date: '1999-08-01',
    value: 1.27,
  },
  {
    date: '1999-09-01',
    value: 1.4,
  },
  {
    date: '1999-10-01',
    value: 1.48,
  },
  {
    date: '1999-11-01',
    value: 1.19,
  },
  {
    date: '1999-12-01',
    value: 1.83,
  },
  {
    date: '2000-01-01',
    value: 1.2,
  },
  {
    date: '2000-02-01',
    value: 1.23,
  },
  {
    date: '2000-03-01',
    value: 1.01,
  },
  {
    date: '2000-04-01',
    value: 1.03,
  },
  {
    date: '2000-05-01',
    value: 1.39,
  },
  {
    date: '2000-06-01',
    value: 1.27,
  },
  {
    date: '2000-07-01',
    value: 0.96,
  },
  {
    date: '2000-08-01',
    value: 0.82,
  },
  {
    date: '2000-09-01',
    value: 1.1,
  },
  {
    date: '2000-10-01',
    value: 1.71,
  },
  {
    date: '2000-11-01',
    value: 0.95,
  },
  {
    date: '2000-12-01',
    value: 0.98,
  },
  {
    date: '2001-01-01',
    value: 1.02,
  },
  {
    date: '2001-02-01',
    value: 1.17,
  },
  {
    date: '2001-03-01',
    value: 1.19,
  },
  {
    date: '2001-04-01',
    value: 1.14,
  },
  {
    date: '2001-05-01',
    value: 1.28,
  },
  {
    date: '2001-06-01',
    value: 1.51,
  },
  {
    date: '2001-07-01',
    value: 1.32,
  },
  {
    date: '2001-08-01',
    value: 1.35,
  },
  {
    date: '2001-09-01',
    value: 13.23,
  },
  {
    date: '2001-10-01',
    value: 13.1,
  },
  {
    date: '2001-11-01',
    value: 7.89,
  },
  {
    date: '2001-12-01',
    value: 6.05,
  },
  {
    date: '2002-01-01',
    value: 4.23,
  },
  {
    date: '2002-02-01',
    value: 3.62,
  },
  {
    date: '2002-03-01',
    value: 3.86,
  },
  {
    date: '2002-04-01',
    value: 3.84,
  },
  {
    date: '2002-05-01',
    value: 4.09,
  },
  {
    date: '2002-06-01',
    value: 3.67,
  },
  {
    date: '2002-07-01',
    value: 2.95,
  },
  {
    date: '2002-08-01',
    value: 3.13,
  },
  {
    date: '2002-09-01',
    value: 4.39,
  },
  {
    date: '2002-10-01',
    value: 4.16,
  },
  {
    date: '2002-11-01',
    value: 3.7,
  },
  {
    date: '2002-12-01',
    value: 4.33,
  },
  {
    date: '2003-01-01',
    value: 4.97,
  },
  {
    date: '2003-02-01',
    value: 5.82,
  },
  {
    date: '2003-03-01',
    value: 9.0,
  },
  {
    date: '2003-04-01',
    value: 6.59,
  },
  {
    date: '2003-05-01',
    value: 4.0,
  },
  {
    date: '2003-06-01',
    value: 3.55,
  },
  {
    date: '2003-07-01',
    value: 3.41,
  },
  {
    date: '2003-08-01',
    value: 3.36,
  },
  {
    date: '2003-09-01',
    value: 2.82,
  },
  {
    date: '2003-10-01',
    value: 2.64,
  },
  {
    date: '2003-11-01',
    value: 2.87,
  },
  {
    date: '2003-12-01',
    value: 3.16,
  },
  {
    date: '2004-01-01',
    value: 2.69,
  },
  {
    date: '2004-02-01',
    value: 2.82,
  },
  {
    date: '2004-03-01',
    value: 3.17,
  },
  {
    date: '2004-04-01',
    value: 3.55,
  },
  {
    date: '2004-05-01',
    value: 2.88,
  },
  {
    date: '2004-06-01',
    value: 3.36,
  },
  {
    date: '2004-07-01',
    value: 3.18,
  },
  {
    date: '2004-08-01',
    value: 3.45,
  },
  {
    date: '2004-09-01',
    value: 3.51,
  },
  {
    date: '2004-10-01',
    value: 2.94,
  },
  {
    date: '2004-11-01',
    value: 2.97,
  },
  {
    date: '2004-12-01',
    value: 2.58,
  },
  {
    date: '2005-01-01',
    value: 2.46,
  },
  {
    date: '2005-02-01',
    value: 2.62,
  },
  {
    date: '2005-03-01',
    value: 2.12,
  },
  {
    date: '2005-04-01',
    value: 1.94,
  },
  {
    date: '2005-05-01',
    value: 2.28,
  },
  {
    date: '2005-06-01',
    value: 1.93,
  },
  {
    date: '2005-07-01',
    value: 3.49,
  },
  {
    date: '2005-08-01',
    value: 2.49,
  },
  {
    date: '2005-09-01',
    value: 2.36,
  },
  {
    date: '2005-10-01',
    value: 2.01,
  },
  {
    date: '2005-11-01',
    value: 1.77,
  },
  {
    date: '2005-12-01',
    value: 2.13,
  },
  {
    date: '2006-01-01',
    value: 2.19,
  },
  {
    date: '2006-02-01',
    value: 2.23,
  },
  {
    date: '2006-03-01',
    value: 2.19,
  },
  {
    date: '2006-04-01',
    value: 2.04,
  },
  {
    date: '2006-05-01',
    value: 2.0,
  },
  {
    date: '2006-06-01',
    value: 2.34,
  },
  {
    date: '2006-07-01',
    value: 2.98,
  },
  {
    date: '2006-08-01',
    value: 3.03,
  },
  {
    date: '2006-09-01',
    value: 2.48,
  },
  {
    date: '2006-10-01',
    value: 2.59,
  },
  {
    date: '2006-11-01',
    value: 1.96,
  },
  {
    date: '2006-12-01',
    value: 2.06,
  },
  {
    date: '2007-01-01',
    value: 2.37,
  },
  {
    date: '2007-02-01',
    value: 2.3,
  },
  {
    date: '2007-03-01',
    value: 1.95,
  },
  {
    date: '2007-04-01',
    value: 1.77,
  },
  {
    date: '2007-05-01',
    value: 1.99,
  },
  {
    date: '2007-06-01',
    value: 2.18,
  },
  {
    date: '2007-07-01',
    value: 2.39,
  },
  {
    date: '2007-08-01',
    value: 1.94,
  },
  {
    date: '2007-09-01',
    value: 2.18,
  },
  {
    date: '2007-10-01',
    value: 2.35,
  },
  {
    date: '2007-11-01',
    value: 1.95,
  },
  {
    date: '2007-12-01',
    value: 2.15,
  },
  {
    date: '2008-01-01',
    value: 1.84,
  },
  {
    date: '2008-02-01',
    value: 1.77,
  },
  {
    date: '2008-03-01',
    value: 1.63,
  },
  {
    date: '2008-04-01',
    value: 1.63,
  },
  {
    date: '2008-05-01',
    value: 1.62,
  },
  {
    date: '2008-06-01',
    value: 1.86,
  },
  {
    date: '2008-07-01',
    value: 2.04,
  },
  {
    date: '2008-08-01',
    value: 1.96,
  },
  {
    date: '2008-09-01',
    value: 2.03,
  },
  {
    date: '2008-10-01',
    value: 1.38,
  },
  {
    date: '2008-11-01',
    value: 1.76,
  },
  {
    date: '2008-12-01',
    value: 1.92,
  },
  {
    date: '2009-01-01',
    value: 1.77,
  },
  {
    date: '2009-02-01',
    value: 1.58,
  },
  {
    date: '2009-03-01',
    value: 1.64,
  },
  {
    date: '2009-04-01',
    value: 2.0,
  },
  {
    date: '2009-05-01',
    value: 2.08,
  },
  {
    date: '2009-06-01',
    value: 1.76,
  },
  {
    date: '2009-07-01',
    value: 1.93,
  },
  {
    date: '2009-08-01',
    value: 1.69,
  },
  {
    date: '2009-09-01',
    value: 2.2,
  },
  {
    date: '2009-10-01',
    value: 2.11,
  },
  {
    date: '2009-11-01',
    value: 1.74,
  },
  {
    date: '2009-12-01',
    value: 2.21,
  },
  {
    date: '2010-01-01',
    value: 2.06,
  },
  {
    date: '2010-02-01',
    value: 1.81,
  },
  {
    date: '2010-03-01',
    value: 1.66,
  },
  {
    date: '2010-04-01',
    value: 1.97,
  },
  {
    date: '2010-05-01',
    value: 2.03,
  },
  {
    date: '2010-06-01',
    value: 2.12,
  },
  {
    date: '2010-07-01',
    value: 1.62,
  },
  {
    date: '2010-08-01',
    value: 1.73,
  },
  {
    date: '2010-09-01',
    value: 1.55,
  },
  {
    date: '2010-10-01',
    value: 1.43,
  },
  {
    date: '2010-11-01',
    value: 2.03,
  },
  {
    date: '2010-12-01',
    value: 2.11,
  },
  {
    date: '2011-01-01',
    value: 1.53,
  },
  {
    date: '2011-02-01',
    value: 1.8,
  },
  {
    date: '2011-03-01',
    value: 2.94,
  },
  {
    date: '2011-04-01',
    value: 1.92,
  },
  {
    date: '2011-05-01',
    value: 2.3,
  },
  {
    date: '2011-06-01',
    value: 1.8,
  },
  {
    date: '2011-07-01',
    value: 1.57,
  },
  {
    date: '2011-08-01',
    value: 1.7,
  },
  {
    date: '2011-09-01',
    value: 1.64,
  },
  {
    date: '2011-10-01',
    value: 1.53,
  },
  {
    date: '2011-11-01',
    value: 1.74,
  },
  {
    date: '2011-12-01',
    value: 1.83,
  },
  {
    date: '2012-01-01',
    value: 1.78,
  },
  {
    date: '2012-02-01',
    value: 1.91,
  },
  {
    date: '2012-03-01',
    value: 1.91,
  },
  {
    date: '2012-04-01',
    value: 1.97,
  },
  {
    date: '2012-05-01',
    value: 1.63,
  },
  {
    date: '2012-06-01',
    value: 1.49,
  },
  {
    date: '2012-07-01',
    value: 1.7,
  },
  {
    date: '2012-08-01',
    value: 1.74,
  },
  {
    date: '2012-09-01',
    value: 1.57,
  },
  {
    date: '2012-10-01',
    value: 1.56,
  },
  {
    date: '2012-11-01',
    value: 1.57,
  },
  {
    date: '2012-12-01',
    value: 1.56,
  },
  {
    date: '2013-01-01',
    value: 1.76,
  },
  {
    date: '2013-02-01',
    value: 1.84,
  },
  {
    date: '2013-03-01',
    value: 1.64,
  },
  {
    date: '2013-04-01',
    value: 2.22,
  },
  {
    date: '2013-05-01',
    value: 1.77,
  },
  {
    date: '2013-06-01',
    value: 1.86,
  },
  {
    date: '2013-07-01',
    value: 1.43,
  },
  {
    date: '2013-08-01',
    value: 2.06,
  },
  {
    date: '2013-09-01',
    value: 2.36,
  },
  {
    date: '2013-10-01',
    value: 1.57,
  },
  {
    date: '2013-11-01',
    value: 1.76,
  },
  {
    date: '2013-12-01',
    value: 1.63,
  },
  {
    date: '2014-01-01',
    value: 1.7,
  },
  {
    date: '2014-02-01',
    value: 1.46,
  },
  {
    date: '2014-03-01',
    value: 2.7,
  },
  {
    date: '2014-04-01',
    value: 2.11,
  },
  {
    date: '2014-05-01',
    value: 1.8,
  },
  {
    date: '2014-06-01',
    value: 2.13,
  },
  {
    date: '2014-07-01',
    value: 2.92,
  },
  {
    date: '2014-08-01',
    value: 2.8,
  },
  {
    date: '2014-09-01',
    value: 2.91,
  },
  {
    date: '2014-10-01',
    value: 1.77,
  },
  {
    date: '2014-11-01',
    value: 1.76,
  },
  {
    date: '2014-12-01',
    value: 1.78,
  },
  {
    date: '2015-01-01',
    value: 2.1,
  },
  {
    date: '2015-02-01',
    value: 2.42,
  },
  {
    date: '2015-03-01',
    value: 2.5,
  },
  {
    date: '2015-04-01',
    value: 2.44,
  },
  {
    date: '2015-05-01',
    value: 1.67,
  },
  {
    date: '2015-06-01',
    value: 1.91,
  },
  {
    date: '2015-07-01',
    value: 2.45,
  },
  {
    date: '2015-08-01',
    value: 1.86,
  },
  {
    date: '2015-09-01',
    value: 2.29,
  },
  {
    date: '2015-10-01',
    value: 2.04,
  },
  {
    date: '2015-11-01',
    value: 3.17,
  },
  {
    date: '2015-12-01',
    value: 3.35,
  },
  {
    date: '2016-01-01',
    value: 2.51,
  },
  {
    date: '2016-02-01',
    value: 2.25,
  },
  {
    date: '2016-03-01',
    value: 2.45,
  },
  {
    date: '2016-04-01',
    value: 2.01,
  },
  {
    date: '2016-05-01',
    value: 1.92,
  },
  {
    date: '2016-06-01',
    value: 1.77,
  },
  {
    date: '2016-07-01',
    value: 2.46,
  },
  {
    date: '2016-08-01',
    value: 2.15,
  },
  {
    date: '2016-09-01',
    value: 2.61,
  },
  {
    date: '2016-10-01',
    value: 2.0,
  },
  {
    date: '2016-11-01',
    value: 1.92,
  },
  {
    date: '2016-12-01',
    value: 2.3,
  },
  {
    date: '2017-01-01',
    value: 2.57,
  },
  {
    date: '2017-02-01',
    value: 3.02,
  },
  {
    date: '2017-03-01',
    value: 2.14,
  },
  {
    date: '2017-04-01',
    value: 3.08,
  },
  {
    date: '2017-05-01',
    value: 2.52,
  },
  {
    date: '2017-06-01',
    value: 2.86,
  },
  {
    date: '2017-07-01',
    value: 2.62,
  },
  {
    date: '2017-08-01',
    value: 3.56,
  },
  {
    date: '2017-09-01',
    value: 2.88,
  },
  {
    date: '2017-10-01',
    value: 2.1,
  },
  {
    date: '2017-11-01',
    value: 2.18,
  },
  {
    date: '2017-12-01',
    value: 2.0,
  },
  {
    date: '2018-01-01',
    value: 2.2,
  },
  {
    date: '2018-02-01',
    value: 1.59,
  },
  {
    date: '2018-03-01',
    value: 2.75,
  },
  {
    date: '2018-04-01',
    value: 3.36,
  },
  {
    date: '2018-05-01',
    value: 3.32,
  },
  {
    date: '2018-06-01',
    value: 2.62,
  },
  {
    date: '2018-07-01',
    value: 2.69,
  },
  {
    date: '2018-08-01',
    value: 2.81,
  },
  {
    date: '2018-09-01',
    value: 1.82,
  },
  {
    date: '2018-10-01',
    value: 2.39,
  },
  {
    date: '2018-11-01',
    value: 1.95,
  },
  {
    date: '2018-12-01',
    value: 2.23,
  },
  {
    date: '2019-01-01',
    value: 2.25,
  },
  {
    date: '2019-02-01',
    value: 2.43,
  },
  {
    date: '2019-03-01',
    value: 1.88,
  },
  {
    date: '2019-04-01',
    value: 1.94,
  },
  {
    date: '2019-05-01',
    value: 2.78,
  },
  {
    date: '2019-06-01',
    value: 2.87,
  },
  {
    date: '2019-07-01',
    value: 2.38,
  },
  {
    date: '2019-08-01',
    value: 2.75,
  },
  {
    date: '2019-09-01',
    value: 2.33,
  },
  {
    date: '2019-10-01',
    value: 2.47,
  },
  {
    date: '2019-11-01',
    value: 1.74,
  },
  {
    date: '2019-12-01',
    value: 1.72,
  },
  {
    date: '2020-01-01',
    value: 3.62,
  },
  {
    date: '2020-02-01',
    value: 1.52,
  },
  {
    date: '2020-03-01',
    value: 1.71,
  },
  {
    date: '2020-04-01',
    value: 1.53,
  },
  {
    date: '2020-05-01',
    value: 1.71,
  },
  {
    date: '2020-06-01',
    value: 1.65,
  },
  {
    date: '2020-07-01',
    value: 1.54,
  },
  {
    date: '2020-08-01',
    value: 1.46,
  },
  {
    date: '2020-09-01',
    value: 1.7,
  },
  {
    date: '2020-10-01',
    value: 1.66,
  },
  {
    date: '2020-11-01',
    value: 1.31,
  },
  {
    date: '2020-12-01',
    value: 1.44,
  },
  {
    date: '2021-01-01',
    value: 1.93,
  },
  {
    date: '2021-02-01',
    value: 1.58,
  },
  {
    date: '2021-03-01',
    value: 1.78,
  },
  {
    date: '2021-04-01',
    value: 2.03,
  },
  {
    date: '2021-05-01',
    value: 2.03,
  },
  {
    date: '2021-06-01',
    value: 1.71,
  },
  {
    date: '2021-07-01',
    value: 1.3,
  },
  {
    date: '2021-08-01',
    value: 2.16,
  },
  {
    date: '2021-09-01',
    value: 1.93,
  },
  {
    date: '2021-10-01',
    value: 1.68,
  },
  {
    date: '2021-11-01',
    value: 1.92,
  },
  {
    date: '2021-12-01',
    value: 2.56,
  },
  {
    date: '2022-01-01',
    value: 3.36,
  },
  {
    date: '2022-02-01',
    value: 4.91,
  },
  {
    date: '2022-03-01',
    value: 7.01,
  },
  {
    date: '2022-04-01',
    value: 4.02,
  },
  {
    date: '2022-05-01',
    value: 3.18,
  },
  {
    date: '2022-06-01',
    value: 2.97,
  },
  {
    date: '2022-07-01',
    value: 2.61,
  },
  {
    date: '2022-08-01',
    value: 3.18,
  },
  {
    date: '2022-09-01',
    value: 2.97,
  },
  {
    date: '2022-10-01',
    value: 3.48,
  },
  {
    date: '2022-11-01',
    value: 2.75,
  },
  {
    date: '2022-12-01',
    value: 2.6,
  },
  {
    date: '2023-01-01',
    value: 2.42,
  },
  {
    date: '2023-02-01',
    value: 3.15,
  },
  {
    date: '2023-03-01',
    value: 2.72,
  },
];

export const ECHART_DATA = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        alignWithLabel: true,
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
    },
  ],
  series: [
    {
      name: 'Direct',
      type: 'bar',
      barWidth: '60%',
      data: [10, 52, 200, 334, 390, 330, 220],
    },
  ],
};

export const GRAPH_LINKS = [
  {
    source: 'Aviation\nInfrastructure',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Aviation\nInfrastructure',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Barges',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Bioenergy\nIndustry',
    target: 'Supply\nDependecy',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Bridges\nInfrastructure',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Broadband\nInfrastructure',
    target: 'Information\nTechnology',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Byproducts',
    target: 'Ground and Surface\nWater Contamination',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Construction\nIndustry',
    target: 'Housing\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Construction\nIndustry',
    target: 'Supply\nDependecy',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Construction\nIndustry',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Container\nManufacturing\nIndustry',
    target: 'Supply\nDependecy',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Container\nManufacturing\nIndustry',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'COVID-19\nPandemic',
    target: 'Public\nHealth',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'COVID-19\nPandemic',
    target: 'International\nTrade\nPolicies',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'COVID-19\nPandemic',
    target: 'Raw\nTimber\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'COVID-19\nPandemic',
    target: 'Housing\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Deforestation',
    target: 'Landslides',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Deforestation',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Deforestation',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Dependence\non Foreign\nSuppliers',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Dependence\non Foreign\nSuppliers',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Dependence\non Foreign\nSuppliers',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Dependence\non Foreign\nSuppliers',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drinking\nWater\nInfrastructure',
    target: 'Public\nHealth',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Invasive\nspecies',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Forest\nfires',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Forest Characteristics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Landslides',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Drought',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Flooding',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Landslides',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Labor\nShortage\n(Harvest)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Warehouse',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Earthquake',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Environmental\nRegulations',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Environmental\nRegulations',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Levees',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Warehouse',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Stormwater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nCharacteristics',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nCharacteristics',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nCharacteristics',
    target: 'Forest\nDegradation',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Deforestation',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Warehouse',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nfires',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nQuality',
    target: 'Forest\nDegradation',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Other\nRaw\nMaterial',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Forest\nDegradation',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nPlantations',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nPlantations',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Wastewater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Drinking\nWater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Public\nTransportation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Water',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Stormwater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Inland\nWaterways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Maritime\n(Ports)\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Roadways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Bridges\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Wood\nByproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Hazardous\nByproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Byproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Funding',
    target: 'Stormwater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Dependence\non Foreign\nSuppliers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Geopolitics/War',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hazardous\nByproducts',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hazardous\nByproducts',
    target: 'Ground and Surface\nWater Contamination',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Housing\nDemand',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Housing\nDemand',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Flooding',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Landslides',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Labor\nShortage (RL)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Warehouse',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Hurricane',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Illegal\nLogging',
    target: 'Forest\nfires',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Illegal\nLogging',
    target: 'Deforestation',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'In-Plant\nLogistics',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Industrial\nWastewater\nInfrastructure',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Industrial\nWastewater\nInfrastructure',
    target: 'Ground and Surface\nWater Contamination',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'In-Plant\nLogistics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Manufacturing\nEquipment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Warehouse',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Inland\nWaterways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Maritime\n(Ports)\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Roadways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Bridges\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Information\nTechnology',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Inland\nWaterways\nInfrastructure',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Inland\nWaterways\nInfrastructure',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'International\nTrade\nPolicies',
    target: 'Dependence\non Foreign\nSuppliers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'International\nTrade\nPolicies',
    target: 'Transportation/\nLogistics\nOperator. S-M',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Invasive\nspecies',
    target: 'Forest\nFires',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Invasive\nspecies',
    target: 'Forest\nCharacteristics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (M)',
    target: 'Labor',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (RM)',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (RM)',
    target: 'Other\nRaw\nMaterial',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (S)',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (W)',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Landslides',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Levees',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Levees',
    target: 'Natural\nForest',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Manufacturing\nEquipment',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Maritime\n(Ports)\nInfrastructure',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Maritime\n(Ports)\nInfrastructure',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Mill\nClosures',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Mill\nClosures',
    target: 'Unemployment',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Millwork\nManufacturing',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Natural\nForest',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Natural\nForest',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nRaw\nMaterial',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nRaw\nMaterial',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nRaw\nMaterial',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nRaw\nMaterial',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nWood\nProducts\nIndustry',
    target: 'Supply\nDependecy',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Other\nWood\nProducts\nIndustry',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Over\nExploitation\nof\nResources',
    target: 'Forest\nHarvesting\nQuality',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Over\nExploitation\nof\nResources',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    target: 'Supply\nDependecy',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Stormwater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Wood\nByproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Hazardous\nByproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Physical\nInfrastructure',
    target: 'Industrial\nWastewater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Plywood\nManufacturing',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Electricity',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nHealth',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nHealth',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nHealth',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nHealth',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nHealth',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nTransportation\nInfrastructure',
    target: 'Labor\nShortage (M)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nTransportation\nInfrastructure',
    target: 'Labor\nShortage (RM)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nTransportation\nInfrastructure',
    target: 'Labor\nShortage (L)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nTransportation\nInfrastructure',
    target: 'Labor\nShortage (S)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Public\nTransportation\nInfrastructure',
    target: 'Labor\nShortage (W)',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Railroads\nInfrastructure',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nDemand',
    target: 'Dependence\non Foreign\nSuppliers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nDemand',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nDemand',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nSupply',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nSupply',
    target: 'Byproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nSupply',
    target: 'Hazardous\nByproducts',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Roadways\nInfrastructure',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Soil\nPhysical and\nMechanical\nProperties',
    target: 'Forest\nCharacteristics',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Stormwater\nInfrastructure',
    target: 'Ground and Surface\nWater Contamination',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Surface Air\nTemperature',
    target: 'Invasive\nspecies',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Surface Air\nTemperature',
    target: 'Forest\nFires',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Technology\nChange',
    target: 'Broadband\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Technology\nChange',
    target: 'Information\nTechnology',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Public\nTransportation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Inland\nWaterways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Maritime\n(Ports)\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Roadways\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Bridges\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Trucks',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Value of\nResidential\nConstruction',
    target: 'Housing\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Value of\nResidential\nConstruction',
    target: 'Wood\nDemand',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Veneering',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Mill\nClosures',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Millwork\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Veneering',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Container\nManufacturing\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Other\nWood\nProducts\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Vessels',
    target: 'Wholesalers/\nRetailers',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Warehouse',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wastewater\nInfrastructure',
    target: 'Drinking\nWater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wastewater\nInfrastructure',
    target: 'Public\nHealth',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Water',
    target: 'Physical\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Water',
    target: 'Plywood\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wholesalers/\nRetailers',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nByproducts',
    target: 'Construction\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nByproducts',
    target: 'Bioenergy\nIndustry',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nByproducts',
    target: 'Ground and Surface\nWater Contamination',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nDemand',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nDemand',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Revenue',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Exports',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Industrial\nWastewater\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Revenue',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Trucks',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Vessels',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Barges',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Aviation\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Railroads\nInfrastructure',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Materials\nTheft',
    lineStyle: {
      curveness: 0.1,
    },
  },
];

export const GRAPH_NODES = [
  {
    fullname: 'Geopolitics/War',
    name: 'Geopolitics/War',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -9.3,
    y: -13,
    isActive: false,
  },
  {
    fullname: 'International Trade Policies',
    name: 'International\nTrade\nPolicies',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -13,
    y: -12.5,
    isActive: false,
  },
  {
    fullname: 'Housing Demand',
    name: 'Housing\nDemand',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -6.2,
    y: -12.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Value of Residential Construction',
    name: 'Value of\nResidential\nConstruction',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -8.3,
    y: -9.3,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Dependence on Foreign Suppliers',
    name: 'Dependence\non Foreign\nSuppliers',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -13,
    y: -9.5,
    isActive: false,
  },
  {
    fullname: 'Wood Demand',
    name: 'Wood\nDemand',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -4.2,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Environmental Regulations',
    name: 'Environmental\nRegulations',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -15,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Illegal Logging',
    name: 'Illegal\nLogging',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -12,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Technology Change',
    name: 'Technology\nChange',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -9,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Raw Timber Demand',
    name: 'Raw\nTimber\nDemand',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -6,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Over Explotation of Resources',
    name: 'Over\nExplotation of\nResources',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -15,
    y: -4.6,
    isActive: false,
  },
  {
    fullname: 'Deforestation',
    name: 'Deforestation',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -12,
    y: -4.6,
    isActive: false,
  },
  {
    fullname: 'Funding',
    name: 'Funding',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -9.5,
    y: -5,
    isActive: false,
  },
  {
    fullname: 'COVID-19 Pandemic',
    name: 'COVID-19\nPandemic',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -7,
    y: -5,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (RM)',
    name: 'Labor\nShortage (RM)',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -3.3,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (S)',
    name: 'Labor\nShortage (S)',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -3.3,
    y: -5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Labor Shortage (M)',
    name: 'Labor\nShortage (M)',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -4,
    y: -3,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (L)',
    name: 'Labor\nShortage (L)',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -4,
    y: -1,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (W)',
    name: 'Labor\nShortage (W)',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -4,
    y: 1,
    isActive: false,
  },
  {
    fullname: 'Public Health',
    name: 'Public\nHealth',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -8.2,
    y: -3,
    isActive: false,
  },
  {
    fullname: 'Power Outage',
    name: 'Power\nOutage',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -13.9,
    y: -2,
    isActive: false,
  },
  {
    fullname: 'Transportation Disruption',
    name: 'Transportation\nDisruption',
    symbol: 'circle',
    classification: 'Threat',
    category: 0,
    x: -10,
    y: -1,
    isActive: false,
  },
  {
    fullname: 'Earthquake',
    name: 'Earthquake',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -15,
    y: 1.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Landslides',
    name: 'Landslides',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -12,
    y: 2.5,
    isActive: false,
  },
  {
    fullname: 'Drought',
    name: 'Drought',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -14.4,
    y: 4,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Extreme Weather (Cold)',
    name: 'Extreme\nWeather\n(Cold)',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -12,
    y: 5.5,
    isActive: false,
  },
  {
    fullname: 'Flooding',
    name: 'Flooding',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -9,
    y: 2.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Soil Physical and Mechanical Properties',
    name: 'Soil\nPhysical and\nMechanical\nProperties',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -6.4,
    y: 2.5,
    isActive: false,
  },
  {
    fullname: 'Hurricane',
    name: 'Hurricane',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -9,
    y: 5.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Forest fires',
    name: 'Forest\nFires',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -4,
    y: 3.2,
    isActive: false,
  },
  {
    fullname: 'Invasive species',
    name: 'Invasive\nspecies',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -5.6,
    y: 5.7,
    isActive: false,
  },
  {
    fullname: 'Surface Air Temperature',
    name: 'Surface Air\nTemperature',
    symbol: 'circle',
    classification: 'Threat',
    category: 1,
    x: -3,
    y: 5.7,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Forest Plantations',
    name: 'Forest\nPlantations',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 0.5,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Other Raw Material',
    name: 'Other\nRaw\nMaterial',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 3.4,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Forest Harvesting Rate',
    name: 'Forest\nHarvesting\nRate',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 5.8,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Forest Characteristics',
    name: 'Forest\nCharacteristics',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 3.4,
    y: -6.5,
    isActive: false,
  },
  {
    fullname: 'Natural Forest',
    name: 'Natural\nForest',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 0.5,
    y: -4.5,
    isActive: false,
  },
  {
    fullname: 'Forest Harvesting Quality',
    name: 'Forest\nHarvesting\nQuality',
    symbol: 'circle',
    classification: 'System',
    category: 2,
    x: 5.8,
    y: -4.5,
    isActive: false,
  },
  {
    fullname: 'Raw Timber Supply',
    name: 'Raw\nTimber\nSupply',
    symbol: 'circle',
    classification: 'System',
    category: 3,
    x: 8,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Raw Timber Shortage',
    name: 'Raw\nTimber\nShortage',
    symbol: 'circle',
    classification: 'System',
    category: 3,
    x: 11,
    y: -9,
    isActive: false,
  },
  {
    fullname: 'Hazardous Byproducts',
    name: 'Hazardous\nByproducts',
    symbol: 'circle',
    classification: 'System',
    category: 3,
    x: 8,
    y: -6,
    isActive: false,
  },
  {
    fullname: 'Byproducts',
    name: 'Byproducts',
    symbol: 'circle',
    classification: 'System',
    category: 3,
    x: 11,
    y: -6,
    isActive: false,
  },
  {
    fullname: 'Aviation Infrastructure',
    name: 'Aviation\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 2.6,
    y: 10,
    isActive: false,
  },
  {
    fullname: 'Inland Waterways Infrastructure',
    name: 'Inland\nWaterways\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 6,
    y: 10,
    isActive: false,
  },
  {
    fullname: 'Roadways Infraestructure',
    name: 'Roadways\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 9,
    y: 10,
    isActive: false,
  },
  {
    fullname: 'Railroads Infrastructure',
    name: 'Railroads\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 12.5,
    y: 10,
    isActive: false,
  },
  {
    fullname: 'Maritime (Ports)',
    name: 'Maritime\n(Ports)\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 15.8,
    y: 10,
    isActive: false,
  },
  {
    fullname: 'Levees',
    name: 'Levees',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 2,
    y: 5.2,
    isActive: false,
  },
  {
    fullname: 'Wastewater Infrastructure',
    name: 'Wastewater\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: -8.6,
    y: 9.5,
    isActive: false,
  },
  {
    fullname: 'Drinking Water Infrastructure',
    name: 'Drinking\nWater\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: -6,
    y: 8.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Public Transportation Infrastructure',
    name: 'Public\nTransportation\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: -3.4,
    y: 9.5,
    isActive: false,
  },
  {
    fullname: 'Broadband Infrastructure',
    name: 'Broadband\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 0,
    y: 4.4,
    isActive: false,
  },
  {
    fullname: 'Electricity',
    name: 'Electricity',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 0,
    y: 7.6,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Fuel',
    name: 'Fuel',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 4,
    y: 7.6,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Bridges Infrastructure',
    name: 'Bridges\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 7,
    y: 7.6,
    isActive: false,
  },
  {
    fullname: 'Industrial Wastewater Infrastructure',
    name: 'Industrial\nWastewater\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 4,
    x: 10.5,
    y: 7.6,
    isActive: false,
  },
  {
    fullname: 'Information Technology',
    name: 'Information\nTechnology',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 19,
    y: -13,
    isActive: false,
  },
  {
    fullname: 'Manufacturing Equipment',
    name: 'Manufacturing\nEquipment',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 14.5,
    y: -10,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Warehouse',
    name: 'Warehouse',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 17,
    y: -10,
    isActive: false,
  },
  {
    fullname: 'In-Plant Logistics',
    name: 'In-Plant\nLogistics',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 21,
    y: -10,
    isActive: false,
  },
  {
    fullname: 'Labor',
    name: 'Labor',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 14,
    y: -7,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Physical Infrastructure',
    name: 'Physical\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 19,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Stormwater Infrastructure',
    name: 'Stormwater\nInfrastructure',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 23,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Mill Closures',
    name: 'Mill\nClosures',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 12.6,
    y: -4,
    isActive: false,
  },
  {
    fullname: 'Plywood Manufacturing',
    name: 'Plywood\nManufacturing',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 17,
    y: -4,
    isActive: false,
  },
  {
    fullname: 'Millwork Manufacturing',
    name: 'Millwork\nManufacturing',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 21.6,
    y: -4,
    isActive: false,
  },
  {
    fullname: 'Veneering',
    name: 'Veneering',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 25.5,
    y: -4,
    isActive: false,
  },
  {
    fullname: 'Wood Product Manufacturing',
    name: 'Wood\nProduct\nManufacturing',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 19,
    y: -1,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Wood Shortage',
    name: 'Wood\nShortage',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 26.5,
    y: -1,
    isActive: false,
  },
  {
    fullname: 'Water',
    name: 'Water',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 23,
    y: -2.5,
    isActive: false,
  },
  {
    fullname: 'Wood Byproducts',
    name: 'Wood\nByproducts',
    symbol: 'circle',
    classification: 'System',
    category: 5,
    x: 23,
    y: 1,
    isActive: false,
  },
  {
    fullname: 'Wholesalers/Retailers',
    name: 'Wholesalers/\nRetailers',
    symbol: 'circle',
    classification: 'System',
    category: 6,
    x: 30,
    y: 5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Container Manufacturing Industry',
    name: 'Container\nManufacturing\nIndustry',
    symbol: 'circle',
    classification: 'System',
    category: 7,
    x: 30,
    y: -10,
    isActive: false,
  },
  {
    fullname: 'Other Wood Products Industry',
    name: 'Other\nWood\nProducts\nIndustry',
    symbol: 'circle',
    classification: 'System',
    category: 7,
    x: 30,
    y: -6.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Bioenergy Industry',
    name: 'Bioenergy\nIndustry',
    symbol: 'circle',
    classification: 'System',
    category: 7,
    x: 30,
    y: -4,
    isActive: false,
  },
  {
    fullname: 'Construction Industry',
    name: 'Construction\nIndustry',
    symbol: 'circle',
    classification: 'System',
    category: 7,
    x: 30,
    y: -1.5,
    isActive: false,
  },
  {
    fullname: 'Paper, Pulp, and Paperboard Industry',
    name: 'Paper,\nPulp, and\nPaperboard\nIndustry',
    symbol: 'circle',
    classification: 'System',
    category: 7,
    x: 30,
    y: 2,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Exports',
    name: 'Exports',
    symbol: 'rect',
    classification: 'Impact',
    category: 8,
    x: 35,
    y: -7,
    isActive: false,
  },
  {
    fullname: 'Lumber Prices',
    name: 'Lumber\nPrices',
    symbol: 'rect',
    classification: 'Impact',
    category: 8,
    x: 38,
    y: -7,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Revenue',
    name: 'Revenue',
    symbol: 'rect',
    classification: 'Impact',
    category: 8,
    x: 41,
    y: -7,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Housing Prices',
    name: 'Housing\nPrices',
    symbol: 'rect',
    classification: 'Impact',
    category: 8,
    x: 44,
    y: -7,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Materials Theft',
    name: 'Materials\nTheft',
    symbol: 'rect',
    classification: 'Impact',
    category: 8,
    x: 42,
    y: -10,
    isActive: false,
  },
  {
    fullname: 'Supply Dependecy',
    name: 'Supply\nDependecy',
    symbol: 'rect',
    classification: 'Impact',
    category: 9,
    x: 35,
    y: -2.5,
    isActive: false,
  },
  {
    fullname: 'Unemployment',
    name: 'Unemployment',
    symbol: 'rect',
    classification: 'Impact',
    category: 9,
    x: 40,
    y: -2.5,
    isActive: true,
    symbolSize: 14,
    itemStyle: {
      borderType: 'solid',
      borderWidth: 2,
      borderColor: '#fff',
      shadowColor: 'rgba(0, 0, 0, 0.8)',
      shadowBlur: 6,
    },
  },
  {
    fullname: 'Ground and Surface Water Contamination',
    name: 'Ground and Surface\nWater Contamination',
    symbol: 'rect',
    classification: 'Impact',
    category: 10,
    x: 35,
    y: 2,
    isActive: false,
  },
  {
    fullname: 'Chemical Pollution',
    name: 'Chemical\nPollution',
    symbol: 'rect',
    classification: 'Impact',
    category: 10,
    x: 40,
    y: 2,
    isActive: false,
  },
  {
    fullname: 'Barges',
    name: 'Barges',
    symbol: 'circle',
    classification: 'System',
    category: 11,
    x: 7,
    y: 0,
    isActive: false,
  },
  {
    fullname: 'Vessels',
    name: 'Vessels',
    symbol: 'circle',
    classification: 'System',
    category: 11,
    x: 4,
    y: 0,
    isActive: false,
  },
  {
    fullname: 'Trucks',
    name: 'Trucks',
    symbol: 'circle',
    classification: 'System',
    category: 11,
    x: 5.3,
    y: 2.5,
    isActive: false,
  },
];

export const GRAPH_NODES_FILTERED_1 = [
  {
    fullname: 'Select all',
    name: 'Select all',
    x: 80,
    y: 12.1,
    itemStyle: {
      color: '#fff',
      borderWidth: 0,
    },
    label: {
      color: '#fff',
    },
    isActive: true,
  },
  {
    fullname: 'Housing Demand',
    name: 'Housing\nDemand',
    x: -6.2,
    y: -12.5,
    category: 0,
    isActive: true,
  },
  {
    fullname: 'Wood Demand',
    name: 'Wood\nDemand',
    x: -4.2,
    y: -9,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'International Trade Policies',
    name: 'International\nTrade\nPolicies',
    x: -13,
    y: -12.5,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (L)',
    name: 'Labor\nShortage (L)',
    x: -4,
    y: -1,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'Labor Shortage (M)',
    name: 'Labor\nShortage (M)',
    x: -4,
    y: -3,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'Power Outage',
    name: 'Power\nOutage',
    x: -13.9,
    y: -2,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'Transportation Disruption',
    name: 'Transportation\nDisruption',
    x: -10,
    y: -1,
    category: 0,
    isActive: false,
  },
  {
    fullname: 'Flooding',
    name: 'Flooding',
    x: -9,
    y: 2.5,
    category: 1,
    isActive: true,
  },
  {
    fullname: 'Forest fires',
    name: 'Forest\nFires',
    x: -4,
    y: 3.2,
    category: 1,
    isActive: false,
  },
  {
    fullname: 'Extreme Weather (Cold)',
    name: 'Extreme\nWeather\n(Cold)',
    x: -12,
    y: 5.5,
    category: 1,
    isActive: false,
  },
  {
    fullname: 'Invasive species',
    name: 'Invasive\nspecies',
    x: -5.6,
    y: 5.7,
    category: 1,
    isActive: false,
  },
  {
    fullname: 'Forest Plantations',
    name: 'Forest\nPlantations',
    x: 0.5,
    y: -9,
    category: 2,
    isActive: false,
  },
  {
    fullname: 'Forest Harvesting Rate',
    name: 'Forest\nHarvesting\nRate',
    x: 5.8,
    y: -9,
    category: 2,
    isActive: false,
  },
  {
    fullname: 'Raw Timber Supply',
    name: 'Raw\nTimber\nSupply',
    x: 10,
    y: -9,
    category: 3,
    isActive: false,
  },
  {
    fullname: 'Raw Timber Shortage',
    name: 'Raw\nTimber\nShortage',
    x: 13,
    y: -9,
    category: 3,
    isActive: false,
  },
  {
    fullname: 'Transportation/Logistics Operator. S-M',
    name: 'Transportation/\nLogistics\nOperator. S-M',
    x: 0,
    y: 4,
    category: 4,
    isActive: false,
  },
  {
    fullname: 'Transportation/Logistics Operator. M-C',
    name: 'Transportation/\nLogistics\nOperator. M-C',
    x: 15.8,
    y: 4,
    category: 4,
    isActive: false,
  },
  {
    fullname: 'Electricity',
    name: 'Electricity',
    x: 0,
    y: 7.6,
    category: 4,
    isActive: false,
  },
  {
    fullname: 'Fuel',
    name: 'Fuel',
    x: 4,
    y: 7.6,
    category: 4,
    isActive: false,
  },
  {
    fullname: 'Wood Product Manufacturing',
    name: 'Wood\nProduct\nManufacturing',
    x: 19,
    y: -1,
    category: 5,
    isActive: true,
  },
  {
    fullname: 'Wood Shortage',
    name: 'Wood\nShortage',
    x: 26.5,
    y: -1,
    category: 5,
    isActive: false,
  },
  {
    fullname: 'Lumber Prices',
    name: 'Lumber\nPrices',
    symbol: 'rect',
    x: 30,
    y: -7,
    category: 8,
    isActive: true,
  },
  {
    fullname: 'Revenue',
    name: 'Revenue',
    symbol: 'rect',
    x: 30,
    y: -10,
    category: 8,
    isActive: true,
  },
];

export const GRAPH_LINKS_FILTERED_1 = [
  {
    source: 'Electricity',
    target: 'Transportation/\nLogistics\nOperator. S-M',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Electricity',
    target: 'Transportation/\nLogistics\nOperator. M-C',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Extreme\nWeather\n(Cold)',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Transportation\nDisruption',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Flooding',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nFires',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nFires',
    target: 'Power\nOutage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nHarvesting\nRate',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Forest\nPlantations',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Transportation/\nLogistics\nOperator. S-M',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Fuel',
    target: 'Transportation/\nLogistics\nOperator. M-C',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Housing\nDemand',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Housing\nDemand',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'International\nTrade\nPolicies',
    target: 'Forest\nHarvesting\nRate',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'International\nTrade\nPolicies',
    target: 'Raw\nTimber\nSupply',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Invasive\nspecies',
    target: 'Forest\nFires',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Invasive\nspecies',
    target: 'Forest\nPlantations',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Transportation/\nLogistics\nOperator. S-M',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (L)',
    target: 'Transportation/\nLogistics\nOperator. M-C',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Labor\nShortage (M)',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation/\nLogistics\nOperator. S-M',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation/\nLogistics\nOperator. M-C',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Electricity',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Fuel',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Power\nOutage',
    target: 'Electricity',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nShortage',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Raw\nTimber\nSupply',
    target: 'Raw\nTimber\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Transportation/\nLogistics\nOperator. S-M',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Transportation\nDisruption',
    target: 'Transportation/\nLogistics\nOperator. M-C',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nDemand',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nDemand',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Wood\nShortage',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Revenue',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nShortage',
    target: 'Lumber\nPrices',
    lineStyle: {
      curveness: 0.1,
    },
  },
];

export const GRAPH_NODES_FILTERED_2 = [
  {
    fullname: 'Housing Demand',
    name: 'Housing\nDemand',
    x: -1,
    y: 0,
    category: 0,
    isActive: true,
  },
  {
    fullname: 'Wood Product Manufacturing',
    name: 'Wood\nProduct\nManufacturing',
    x: 0,
    y: 0,
    category: 1,
    isActive: true,
  },
  {
    fullname: 'Revenue',
    name: 'Revenue',
    symbol: 'rect',
    x: 1,
    y: 0,
    category: 2,
    isActive: true,
  },
];

export const GRAPH_LINKS_FILTERED_2 = [
  {
    source: 'Housing\nDemand',
    target: 'Wood\nProduct\nManufacturing',
    lineStyle: {
      curveness: 0.1,
    },
  },
  {
    source: 'Wood\nProduct\nManufacturing',
    target: 'Revenue',
    lineStyle: {
      curveness: 0.1,
    },
  },
];

export const GRAPH_CATEGORIES = [
  {
    name: 'Anthropogenic',
    itemStyle: {
      color: '#006483',
    },
  },
  {
    name: 'Natural',
    itemStyle: {
      color: '#438bca',
    },
  },
  {
    name: 'Raw Material/Sources',
    itemStyle: {
      color: '#c17a2f',
    },
  },
  {
    name: 'Supplier',
    itemStyle: {
      color: '#2b0171',
    },
  },
  {
    name: 'Transportation/Logistics',
    itemStyle: {
      color: '#500101',
    },
  },
  {
    name: 'Manufacturer/Production',
    itemStyle: {
      color: '#707070',
    },
  },
  {
    name: 'Wholesaler/Retailer',
    itemStyle: {
      color: '#5a6237',
    },
  },
  {
    name: 'Customers',
    itemStyle: {
      color: '#b0b1b1',
    },
  },
  {
    name: 'Social',
    itemStyle: {
      color: '#ad9d68',
    },
  },
  {
    name: 'Economic',
    itemStyle: {
      color: '#ad9d68',
    },
  },
  {
    name: 'Environmental',
    itemStyle: {
      color: '#ad9d68',
    },
  },
  {
    name: '',
    itemStyle: {
      color: '#e376a7',
    },
  },
];

export const GRAPH_CATEGORIES_FILTERED = [
  {
    name: 'Anthropogenic',
    itemStyle: {
      color: '#ffffff',
      borderColor: '#4444ff',
      borderWidth: 8
    },

  },
  {
    name: 'Natural',
    itemStyle: {
      color: '#ffffff',
      borderColor: '#2277ff',
      borderWidth: 8
    },
  },
  {
    name: 'Raw Material/Sources',
    itemStyle: {
      color: '#ffffff',
      borderColor: '#771188',
      borderWidth: 8
    },
  },

];
