import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeStore } from './core/stores/home-store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MainPageComponent } from './core/layouts/main-page/main-page.component';
import { SecondPageComponent } from './core/layouts/second-page/second-page.component';
import { SharedModule } from './shared/shared.module';
import { DynamicViewComponent } from './core/layouts/dynamic-view/dynamic-view.component';
import { HomeComponent } from './core/layouts/home/home.component';
import { AutomobileEngineModule } from './modules/automobile-engine/automobile-engine.module';
import { CornFarmingModule } from './modules/corn-farming/corn-farming.module';
import { InorganicChemicalModule } from './modules/inorganic-chemical/inorganic-chemical.module';
import { MedicalDeviceModule } from './modules/medical-device/medical-device.module';
import { PowerModule } from './modules/power/power.module';
import { PublicHealthInfoModule } from './modules/public-health-info/public-health-info.module';
import { SawmillsProductionModule } from './modules/sawmills-production/sawmills-production.module';
import { SemiconductorModule } from './modules/semiconductor/semiconductor.module';
import { TransboundaryWaterModule } from './modules/transboundary-water/transboundary-water.module';
import {StoryModule} from "./modules/story/story.module";
import { BarChartComponent } from './shared/components/amchart/bar-chart/bar-chart.component';
import { ArticleItemComponent } from './core/layouts/home/article-item/article-item.component';
import { ArticleSingleComponent } from './core/layouts/home/article-single/article-single.component';
import { ArticlesFiltersComponent } from './core/layouts/home/articles-filters/articles-filters.component';
import { ArticlesListComponent } from './core/layouts/home/articles-list/articles-list.component';
import { ArticlesVisualizerComponent } from './core/layouts/home/articles-visualizer/articles-visualizer.component';;
import { DataSourceComponent } from './shared/templates/risk-scenarios-template/data-source/data-source.component';
import { LineTwoSeriesScatterComponent } from './shared/components/amchart/line-two-series-scatter/line-two-series-scatter.component';
import { ExploratoryAnalysisComponent } from './shared/templates/risk-scenarios-template/exploratory-analysis/exploratory-analysis.component';
import { RiskScenarioComponent } from './shared/templates/risk-scenarios-template/risk-scenario/risk-scenario.component';
import { RiskScenariosTemplateComponent } from './shared/templates/risk-scenarios-template/risk-scenarios-template.component';
import { HeatmapComponent } from './shared/components/plotly/heatmap/heatmap.component';
import { BiplotComponent } from './shared/components/plotly/biplot/biplot.component';
import { ProbabilityGraphComponent } from './shared/components/d3/histogram/probability-graph/probability-graph.component';
import { RegressionGraphComponent } from './shared/components/d3/histogram/regression-graph/regression-graph.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    MainPageComponent,
    SecondPageComponent,
    HomeComponent,
    DynamicViewComponent,
    BarChartComponent,
    ArticleItemComponent,
    ArticleSingleComponent,
    ArticlesFiltersComponent,
    ArticlesListComponent,
    ArticlesVisualizerComponent,
    HeatmapComponent,
    RiskScenariosTemplateComponent,
    LineTwoSeriesScatterComponent,
    DataSourceComponent,
    ExploratoryAnalysisComponent,
    RiskScenarioComponent,
    BiplotComponent,
    ProbabilityGraphComponent,
    RegressionGraphComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatDialogModule,
    SharedModule,
    // todo: better place the components and do lazy loading
    CornFarmingModule,
    TransboundaryWaterModule,
    SawmillsProductionModule,
    InorganicChemicalModule,
    MedicalDeviceModule,
    PublicHealthInfoModule,
    AutomobileEngineModule,
    SemiconductorModule,
    StoryModule,
    PowerModule,
  ],

  providers: [
    HomeStore,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
