import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import * as echarts from 'echarts';

@Directive({
  selector: '[EchartDirective]',
})
export class EchartDirective {
  @Input() echartOptions: any;
  myChart!: echarts.ECharts;
  @Output() onClick = new EventEmitter<any>();
  el: ElementRef;
  constructor(el: ElementRef) {
    this.el = el;
  }
  @HostListener('window:resize')
  onResize() {
    if (this.myChart) this.myChart.resize();
  }
  ngAfterViewInit() {
    this.myChart = echarts.init(this.el.nativeElement);
    //console.log(this.myChart, this.echartOptions);
    if (!this.echartOptions) return;
    this.myChart.setOption(this.echartOptions);
    this.myChart.on('click', (params: any) => {
      this.onClick.emit({ evt: params, chartInstance: this.myChart });
    });
  }
}
