import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom, GeotiffDataLink } from 'src/app/core/enums/map.enum';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forest-characteristic',
  templateUrl: './forest-characteristic.component.html',
  styleUrls: ['./forest-characteristic.component.css']
})
export class ForestCharacteristicComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri()
    }
    if(this.filter!=undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
     this.select = result ? result.itemId : null;
      this.filterInfo=this.filter;
      this.hasStudyButton=true;
    
    }
  }
  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

  getDataCN() {
    this.spinner.hide();
  }

  getDataUs() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataTri() {
    const  dataMap = {
      center: MapCenter.USA,
      json: MapJson.TRI,
      zoom: MapZoom.TRINATIONAL
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };
    this.naturalService.getForestCharacteristicsDateGbl()
      .subscribe(
        {
          next: (res) => {
            this.data = {
              data: res.data.map((value:any) => {
                return {url: `${environment.BaseUrl}${environment.Api}${GeotiffDataLink.ForestCharacteristic_gbl}${value.fileId}`, date: value.date }
              }),
              filterInfo:this.filterInfo,
            selectedInfo:this.select,
            hasStudyButton:this.hasStudyButton,
              metadata: res.metadata, 
              dates: res.data.map((value:any) => {
                return value.date
              }),
              mapInfo: dataMap,
              formatInfo: formatInfo
            };
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }
}
