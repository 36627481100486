import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as XLSX from 'xlsx';
declare const L: any;
import 'leaflet';
import 'leaflet-timedimension';
import '../../../../../assets/js/leaflet.timedimension.choropleth';
import '../../../../../assets/js/leaflet.SpatialTemporal.choropleth.control';
import * as chroma from 'chroma-js';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay } from 'rxjs';

@Component({
  selector: 'app-dynamic-states-map',
  templateUrl: './dynamic-states-map.component.html',
  styleUrls: ['./dynamic-states-map.component.css'],
})
export class DynamicStatesMapComponent implements  OnChanges, OnDestroy {
  @ViewChild('map', { read: ElementRef })
  private mapEl!: ElementRef<HTMLElement>;
  @Input() labelPopup: string = '';
  @Input() data!: any;
  @Input() variable!:number;
  @Input() title: string = '';
  @Input() mapInfo!: any;
  @Input() formatInfo!: any;
  @Input() property!: string;
  @Input() index!:any;
  @Input() consultedIndex: any;
  @Output() stateSelected = new EventEmitter<any>();
  @Output() currentTime = new EventEmitter<number>();
  @Output() dataUpdated = new EventEmitter<any>();
  @Output() countyIndex = new EventEmitter<any>();
  private consultedIndexes: Set<number> = new Set(); 
private datosConsultados: Set<number> = new Set();
private consultasEnProgreso: Set<number> = new Set();
private counties:any;
private statesEx:any;
  private map: any;
  private states: any;
  private maxValue: any;
  private geojson!: any;
  private timeSeries!: any;
  private player!: any;
  private info!: any;
  private legend!: any;
  promiseGeoJson!: Promise<any>;
  private values: any[] = [];
  constructor(private http: HttpClient,private generalservice: GeneralService,private spinner: NgxSpinnerService) {
    this.consultedIndexes = new Set([0]);
    this.datosConsultados=new Set([0]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.map !== undefined) {
      this.player.stop();
      this.map.remove();
    }
    this.maxValue = this.data.maxState;
    this.initMap();
    this.getGeoJsonData();
    Promise.allSettled([this.promiseGeoJson]).then((value) => {
      this.onReload();
      if (this.map !== undefined) {
      }
    });
    this.map.on('timeupdate', (event: any) => {
      if(this.consultedIndex!=undefined){
        this.consultedIndexes=this.consultedIndex;
        this.datosConsultados=this.consultedIndex;
      }

      if(this.data.metadata.typeVariable!='Spatial Temporal MVM'){
        
      const index = event.index;
      if (this.consultasEnProgreso.has(index)) {
        return;
      }
      if(this.index!=undefined){
      if(this.index.size>this.consultedIndexes.size){
this.consultedIndexes=this.index;
this.datosConsultados=this.index;
      }
    }
      if (this.consultedIndexes.has(index) && this.datosConsultados.has(index)) {
if(event.action=='next'){

  this.map.fire('timeupdateProcessedNext',{
    estado: 'procesado',
  });
}
else if(event.action=='back'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedBack');
}
else if(event.action=='play'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedPlay');
}
else if(event.action=='slice'){
  this.consultasEnProgreso.clear();
  this.map.fire('timeupdateProcessedSlice');
}
        return;
      }
    this.consultasEnProgreso.add(index);
this.spinner.show();
      this.consultedIndexes.add(index);
      let mdl = {
        numVariable: this.variable,
        StartDate: event.date
      };
      this.generalservice.getDataSpatialTemporalDate(mdl).subscribe(z => {
        this.datosConsultados.add(index);
        this.HandleDate(z,event.index);
this.index=this.consultedIndexes;
        this.dataUpdated.emit(this.data);
        this.countyIndex.emit(this.consultedIndexes);
        this.spinner.hide();
        if(event.action=='next'){
this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedNext',{
            estado: 'procesado',
          });
        }
        else if(event.action=='back'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedBack');
        }
        else if(event.action=='play'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedPlay');
        }
        else if(event.action=='slice'){
          this.consultasEnProgreso.clear();
          this.map.fire('timeupdateProcessedSlice');
        }
      });
    }
    else{
      if(event.action=='next'){
        this.consultasEnProgreso.clear();
                  this.map.fire('timeupdateProcessedNext',{
                    estado: 'procesado',
                  });
                }
                else if(event.action=='back'){
                  this.consultasEnProgreso.clear();
                  this.map.fire('timeupdateProcessedBack');
                }
                else if(event.action=='play'){
                  this.consultasEnProgreso.clear();
                  this.map.fire('timeupdateProcessedPlay');
                }
                else if(event.action=='slice'){
                  this.consultasEnProgreso.clear();
                  this.map.fire('timeupdateProcessedSlice');
                }
    }
    });
  
  }
  ngOnDestroy(): void {
    if (this.map !== undefined) {
      this.removeMapListeners();
      this.map.remove(); 
    }
  }


  HandleDate(z:any, index:any):void{
    const dataStatesMap = new Map();
        this.data.data_States.forEach((item: { name: any; values: any; }) => {
          dataStatesMap.set(item.name, [...item.values]);
        });
        z.data_States.forEach((item: { name: any; values: any; }) => {
          if (dataStatesMap.has(item.name)) {
            const existingValues = dataStatesMap.get(item.name);
            const newValues = this.fillZerosUntilIndex(existingValues, index);
            newValues[index] = item.values[0];
            dataStatesMap.set(item.name, newValues);
          } else {
            const newValues = Array(index).fill(0); 
            newValues[index] = item.values[0];
            dataStatesMap.set(item.name, newValues);
          }
        });
        this.data.data_States.forEach((item: { name: any; values: any; }) => {
          if (!z.data_States.some((newItem: { name: any; }) => newItem.name === item.name)) {
            const existingValues = dataStatesMap.get(item.name);
            const newValues = this.fillZerosUntilIndex(existingValues, index);
            dataStatesMap.set(item.name, newValues);
          }
        });
        this.data.data_States = Array.from(dataStatesMap, ([name, values]) => ({ name, values }))
          .sort((a, b) => parseInt(a.name) - parseInt(b.name));
        this.updateMap();
        if(this.data.hasCountyLevel==true){
          const dataMap = new Map<string, { state: string, values: number[] }>();
this.data.data.forEach((item: { name: string; state: string; values: number[] }) => {
  dataMap.set(item.name, { state: item.state, values: [...item.values] });
});
z.data.forEach((item: { name: string; state: string; values: number[] }) => {
  if (dataMap.has(item.name)) {
    const existingItem = dataMap.get(item.name);
    const existingValues = existingItem?.values || [];
    const newValues = this.fillZerosUntilIndex(existingValues, index);
    newValues[index] += item.values[0];
    dataMap.set(item.name, { state: item.state, values: newValues });
  } else {
    const newValues = Array(index).fill(0);
    newValues[index] = item.values[0];
    dataMap.set(item.name, { state: item.state, values: newValues });
  }
});
this.data.data.forEach((item: { name: string; state: string; values: number[] }) => {
  if (!z.data.some((newItem: { name: string }) => newItem.name === item.name)) {
    const existingItem = dataMap.get(item.name);
    const existingValues = existingItem?.values || [];
    const newValues = this.fillZerosUntilIndex(existingValues, index);
    dataMap.set(item.name, { state: item.state, values: newValues });
  }
});
this.data.data = Array.from(dataMap, ([name, { state, values }]) => ({ name, state, values }))
  .sort((a, b) => parseInt(a.name) - parseInt(b.name));
        }
      

  }


  removeMapListeners(): void {
    if (this.map) {
      this.map.off('timeupdate'); 
    }
  }
  private updateMap(): void {
    this.createNewItems();
    if (this.geojson) {
      this.map.removeLayer(this.geojson);
    }
    this.timeSeries = L.geoJSON(this.states, {
      style: this.style,
      onEachFeature: this.onEachFeature,
    });
    this.geojson = L.timeDimension.layer.choropleth(this.timeSeries, {
      updateTimeDimensionMode: 'replace',
      waitForReady: true,
    });
    this.geojson.addTo(this.map);
  }

  // ngOnDestroy(): void {
  //   if (this.map !== undefined) {
  //     if (this.geojson) {
  //       this.map.removeLayer(this.geojson);
  //     }
  //     if (this.timeSeries) {
  //       this.timeSeries.clearLayers();
  //     }
  //     this.map.off(); // Elimina todos los eventos asociados al mapa
  //     this.map.remove();
  //   }
  // }

  fillZerosUntilIndex(values: any[], index: number): any[] {
    const newArray = [...values];
    while (newArray.length <= index) {
      newArray.push(0);
    }
    return newArray;
  }



  private initMap(): void {
    //this.map = L.map(this.mapEl.nativeElement, {
    this.map = L.map('mapState', {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 12,
        minZoom: 1,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText:
          '<img class="icon-svg" src= "../../assets/images/house-solid.svg" alt="home-icon">',
        zoomHomeTitle: 'Zoom home',
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + '-in',
          container,
          this._zoomIn
        );
        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + '-home',
          container,
          this._zoomHome
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + '-out',
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (
        html: any,
        title: any,
        className: any,
        container: any,
        fn: any
      ) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  getGeoJsonData() {
    this.promiseGeoJson = new Promise((resolve, reject) => {
      this.http.get(`/assets/geojson/${this.mapInfo.json}_states.json`).subscribe((states: any) => {
        this.states=states;
        this.statesEx = states.features.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature.properties.name;  
          return acc;
        }, {});
        resolve(this.states);
      }, error => {
        reject(error);
      });
    });
    this.promiseGeoJson = new Promise((resolve, reject) => {
      this.http.get(`/assets/geojson/${this.mapInfo.json}_counties.json`).subscribe((counties: any) => {
        this.counties = counties.features.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature.properties.NAME;  
          return acc;
        }, {});
        resolve(this.counties);
      }, error => {
        reject(error);
      });
    });
  }
  onReload() {
    this.createNewItems();
    this.initStatesLayer();
  }
  private createNewItems() {
    this.data.data_States.forEach((state: any) => {
      const itemIndex = this.states.features.findIndex(
        (item: any) => item.id === state.name
      );
      if (itemIndex == -1) return;
      const copyItem = JSON.parse(
        JSON.stringify(this.states.features.at(itemIndex))
      );
      this.states.features.splice(itemIndex, 1);
      copyItem.properties.times = [];
      copyItem.properties.values = [];
      copyItem.properties.times.push(...this.data.dates);
      copyItem.properties.valuesString = state[this.property].map(
        (val: number) => {
          return val.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      );
      copyItem.properties.values.push(...state[this.property]);
      copyItem.properties.id = copyItem.id;
      this.states.features.push(copyItem);
    });
    for (var i = 0; i < this.states.features.length; i++) {
      if (this.states.features[i].properties.values === undefined) {
        this.states.features.splice(i, 1); 
        i--;
      }
    }
    let min = Math.min(
      ...this.data.data_States.map((item: any) => {
        return Math.min(...item[this.property].filter((x: any) => x !== 0));
      })
    );
    let max = Math.max(
      ...this.data.data_States.map((item: any) => {
        return Math.max(...item[this.property].filter((x: any) => x !== 0));
      })
    );

    min = Math.round(min);

    max = Math.round(max);

    while (max % 10 !== 0) {
      max++;
    }

    while (min % 10 !== 0) {
      min--;
    }

    const length = this.data.data_States.at(0)[this.property].length;
    this.values = [];
    for (var i = 0; i < length; i++) {
      let values_ = [
        ...new Set(
          this.data.data_States.map((item: any) => {
            return item[this.property].at(i);
          })
        ),
      ].sort((a: any, b: any) => {
        return a - b;
      });

      if(this.data.valuesGeneral){
        values_ = [];
        values_.push(...this.data.valuesGeneral);
        values_.push(0);
        values_.sort((a: any, b: any) => {
          return a - b;
        });
      }
      
      values_.unshift(min);
      if (values_.at(-1) !== max) {
        values_.pop();
        values_.push(max);
      }

      this.values.push(values_);
    }
  }
  private initStatesLayer() {

    if (this.geojson) {
      this.map.removeLayer(this.geojson);
   
  }
    var timeDimension = new L.TimeDimension({
      period: 'PT1H',
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player(
      {
        transitionTime: 5000,
        loop: false,
        startOver: false,
      },
      timeDimension
    );
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      speedSlider: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 1,
      timeSliderDragUpdate: false,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays,
    };
    var timeDimensionControl = new L.Control.SpatialTemporalChoropleth(
      timeDimensionControlOptions
    );
    this.map.addControl(timeDimensionControl);
    this.timeSeries = L.geoJSON(this.states, {
      style: this.style,
      onEachFeature: this.onEachFeature,
    });
    this.geojson = L.timeDimension.layer.choropleth(this.timeSeries, {
      updateTimeDimensionMode: 'replace',
      waitForReady: true,
    });
    this.geojson.addTo(this.map);

    this.info = L.control({ position: 'topleft' });

    this.info.onAdd = function (map: any) {
      this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    this.info.update = function (props: any, map: any, mouseout: boolean) {
      let text = '';
      if (props === undefined) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return;
      }
      if (mouseout) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return;
      }
      if (props.valuesString === undefined) {
        text += `<app-not-found></app-not-found>`;
      } else {
        text += '<b>' + props.name + '</b><br />';
        text +=
          '<b>' +
          props.valuesString[map.timeDimension.getCurrentTimeIndex()] +
          '</b><br />';
      }
      this._div.innerHTML = text;
    };

    if (!this.map.hasLayer(this.info)) {
      this.info.addTo(this.map);
    }

    var legend= L.control({ position: 'bottomright' });
this.legend=legend;
    this.legend.onAdd = function (map: any) {
      this._div = L.DomUtil.create('div', 'info legend'); // create a div with a class "info"
      return this._div;
    };
    this.legend.update = (values: any) => {
     const values_ = values.filter((x: any) => x >= 0).sort((a: number, b: number) => a-b).reverse();
      this.legend._div.innerHTML = '';
      // loop through our density intervals and generate a label with a colored square for each interval
      for (var i = 0; i < values_.length; i++) {
        if (i == 0 || i == values_.length - 1) {
          this.legend._div.innerHTML +=
            '<span style="height: 5px;"><i style="background:' +
            this.getColor(values_[i]) +
            ';height: 5px;"></i> ' +
            values_[i] +
            '</span>';
        } else {
          this.legend._div.innerHTML +=
            '<span style="height: 5px;"><i style="background:' +
            this.getColor(values_[i]) +
            ';height: 5px;"></i> ' +
            '</span>';
        }
      }
      if (values_.length == 2) {
        const spans = this.legend._div.querySelectorAll('span');
        spans[0].style.marginBottom = '5px';
      }
    };

    
    if (!this.map.hasLayer(legend)) {
      legend.addTo(this.map);
  
    this.legend.update(
      this.values[this.map.timeDimension.getCurrentTimeIndex()]
    );

    this.map.timeDimension.on('timeload', (e: any) => {
      this.legend.update(
        this.values[this.map.timeDimension.getCurrentTimeIndex()]
      );
      this.currentTime.emit(e.time);
      //Aqui quiero emitir un evento que se reciba en
    });
    
  }
  }

  private style = (feature: any) => {
   let color = '#fefbce';
    if (feature!.properties!.values !== undefined) {
      const value =
        feature!.properties!.values[
          this.map.timeDimension.getCurrentTimeIndex()
        ];
     if (value === undefined)   return {
        weight: 1,
        opacity: 1,
        color: 'rgba(35,35,35,1.0)',
        dashArray: '',
        fillOpacity: 1,
        fillColor: 'rgba(0, 0, 0, 0)',
        width: '100%',
      };
      if (value !== 0) {
        color = this.getColor(value);
      } else {
        return {
          weight: 1,
          opacity: 1,
          color: 'rgba(35,35,35,1.0)',
          dashArray: '',
          fillOpacity: 1,
          fillColor: color,
          width: '100%',
        };
      }
    }
    return {
      weight: 1,
      opacity: 1,
      color: 'rgba(35,35,35,1.0)',
      dashArray: '',
      fillOpacity: 1,
      fillColor: color,
      width: '100%',
    };
  };
  private getColor = (d: any) => {
    var mapScale = chroma
      .scale(this.mapInfo.scale)
      .classes(this.values[this.map.timeDimension.getCurrentTimeIndex()]);
    return mapScale(d).toString();
  };
  private zoomToFeature = (e: any) => {
    if (e.target.feature.properties.values === undefined) return;
    this.map.fitBounds(e.target.getBounds());
  };
  private highLightFeature = (e: any) => {
    var layer = e.target;

    layer.setStyle({
      weight: 1.5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7,
    });
    this.info.update(e.target.feature.properties, this.map, false);
    layer.bringToFront();
  };
  private resetFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 1,
      color: 'rgba(35,35,35,1.0)',
      fillOpacity: 1,
    });
    this.info.update(e.target.feature.properties, this.map, true);
    layer.bringToFront();
  };
  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
      click: this.zoomToFeature,
    });
    const total =
      feature.properties.valuesString === undefined
        ? 'N/A'
        : feature.properties.valuesString[
            this.map.timeDimension.getCurrentTimeIndex()
          ];
    layer
      .bindTooltip(
        `<p style="font-weight: 700; text-align: center;">${feature.properties.name}</p> ${this.labelPopup}:  ${total}`
      )
      .openTooltip();
    layer.on('click', (e: any) => {
    this.stopPlayer(feature, e);
    });
  };
  public stopPlayer= async (feature: any, e: any)=>{
    if (feature.properties.values === undefined) return;
    this.player.stop();
    e.target.getBounds();
    const model = {
      name: feature.properties.name,
      id: feature.properties.id,
      bounds: e.target.getBounds(),
    };
    if(this.data.metadata.typeVariable!='Spatial Temporal MVM'){
    await this.ConsulEnd();
    }
    this.countyIndex.emit(this.consultedIndexes)
    this.stateSelected.emit(model);
  }

  ConsulEnd=async () =>{
    if(this.index!=undefined){
      if(this.index.size>this.consultedIndexes.size){
this.consultedIndexes=this.index;
this.datosConsultados=this.index;
      }
    }
  
    if (this.consultedIndexes.size != this.data.dates.length) {
      this.spinner.show();
      const promises = [];
      for (let index = 0; index < this.data.dates.length; index++) {
        if (!this.consultedIndexes.has(index)) {
          let mdl = {
            numVariable: this.variable,
            StartDate: this.data.dates[index]
          };
          this.consultedIndexes.add(index);
          const promise = this.generalservice.getDataSpatialTemporalDate(mdl).toPromise().then(z => {
            this.datosConsultados.add(index);
            this.HandleDate(z, index);
          });
          promises.push(promise); 
        }
      }
      await Promise.all(promises);
      this.index=this.consultedIndexes;
      this.dataUpdated.emit(this.data);
      this.countyIndex.emit(this.consultedIndexes);
      this.spinner.hide();
    }
  }

  exportToExcel = async () => {
    const MAX_ROWS_PER_EXPORT = 50000;
    debugger;  
    if (this.data.metadata.typeVariable != 'Spatial Temporal MVM') { 
        await this.ConsulEnd();
    }

    let stateData = [];
    let countyData = [];

    if (this.data.data_States && Array.isArray(this.data.data_States)) {
        stateData = this.data.data_States.flatMap((state: { name: string | number; values: any[]; }) => {
            return this.data.dates.map((date: string, index: number) => ({
                Year: date, 
                State: this.statesEx[state.name] || state.name, 
                Value: state.values[index] 
            }));
        });
        stateData = stateData.sort((a: { Year: string; }, b: { Year: any; }) => a.Year.localeCompare(b.Year));
    }
    if (this.data.data && Array.isArray(this.data.data)) {
        countyData = this.data.data.flatMap((county: { name: string | number; state: string | number; values: any[]; }) => {
            return this.data.dates.map((date: string, index: number) => ({
                Year: date,
                County: this.counties[county.name] || county.name, 
                State: this.statesEx[county.state] || county.state,
                Value: county.values[index]  
            }));
        });
        countyData = countyData.sort((a: { Year: string; }, b: { Year: any; }) => a.Year.localeCompare(b.Year));
    }

    const splitDataIntoChunks = (data: any[], chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < data.length; i += chunkSize) {
            chunks.push(data.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const stateChunks = stateData.length > 0 ? splitDataIntoChunks(stateData, MAX_ROWS_PER_EXPORT) : [];
    const countyChunks = countyData.length > 0 ? splitDataIntoChunks(countyData, MAX_ROWS_PER_EXPORT) : [];

    if (stateChunks.length === 0 && countyChunks.length === 0) {
        console.warn("No data available for export");
        return;
    }

    stateChunks.forEach((stateChunk, index) => {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        if (stateChunk && stateChunk.length > 0) {
            const wsStates: XLSX.WorkSheet = XLSX.utils.json_to_sheet(stateChunk);
            XLSX.utils.book_append_sheet(wb, wsStates, 'Estates');
        }
        if (countyChunks[index] && countyChunks[index].length > 0) {
            const wsCounties: XLSX.WorkSheet = XLSX.utils.json_to_sheet(countyChunks[index]);
            XLSX.utils.book_append_sheet(wb, wsCounties, 'Counties');
        }
        const fileName = stateChunks.length === 1
            ? `Map_data_${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_${Date()}.xlsx`
            : `Map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_Part${index + 1}_${Date()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    });
    this.consultasEnProgreso.clear();
};

}