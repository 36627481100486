import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-bar-chart-vertical',
  templateUrl: './bar-chart-vertical.component.html',
  styleUrls: ['./bar-chart-vertical.component.css']
})
export class BarChartVerticalComponent implements AfterViewInit,OnChanges, OnDestroy {
  // @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() data!: any;
  @Input() title: string = '';
  @Input() yLabel: string = '';
  @Input() xLabel: string = '';

  private root!: am5.Root;
  private chart!: am5xy.XYChart;

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initVeticalBar();
  }
  ngAfterViewInit(): void {
    this.initVeticalBar();
  }
  initVeticalBar(): void {
    if(this.chart != null && this.chart != undefined  && this.root != null && this.root != undefined){
      this.chart!.dispose();
      this.root!.dispose();
    }
    //this.root = am5.Root.new(this.chartEl.nativeElement);
    this.root = am5.Root.new("chart");
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      layout: this.root.verticalLayout,
    }));
    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.yLabel,
      fontSize: 16,
      fontWeight: 'bold',
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 25
    }));
    //Add Legend config
    let legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.title,
      textAlign: "center",
      fontSize: 18,
      fontWeight: "700",
      x: am5.p50,
      centerX: am5.p50,
      oversizedBehavior: "wrap",
    }));

    //Add YAxis and xAxis config
    let xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: 'site',

      renderer: am5xy.AxisRendererX.new(this.root, {
        inversed: undefined,
        minGridDistance: 40,
      })
    }));
    xAxis.children.push(am5.Label.new(this.root, {
      text: this.xLabel,
      textAlign: 'center',
      x: am5.p50,
      fontSize: 16,
      fontWeight: 'bold'
    }));
    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      maxWidth: 100,
      rotation: -20
    });
    xAxis.data.setAll(this.data.data!);

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1
      }),
      min: 0
    }));
    this.data.series.forEach((x: any) => {
      this.createSeries(this.chart, xAxis, yAxis, this.root, x.propertyName, x.longName, this.data.data!, x.color);
    })


    legend.data.setAll(this.chart.series.values);

    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      behavior: "zoomY"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      maxWidth: 90,
      fontSize: 11,


    });

    var scrollbarX = am5.Scrollbar.new(this.root, {
      orientation: "horizontal",
      marginLeft: 20,

    });

    this.chart.set("scrollbarX", scrollbarX);
    this.chart.bottomAxesContainer.children.push(scrollbarX);
    this.chart.zoomOutButton.set("forceHidden", true);
    this.chart.appear(1000, 100);



  }
  createSeries(chart: am5xy.XYChart, xAxis: any, yAxis: any, root: am5.Root, field: string, name: string, data: any,  color:string) {
    let tooltip = am5.Tooltip.new(root, {
      pointerOrientation: "left",
      labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
    });
    tooltip.label.setAll({
      fontSize: 14
    });
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: field.charAt(0).toLocaleLowerCase() + field.slice(1),
      categoryXField: 'site'
    }));
    series.columns.template.setAll({
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0,
      fill: color !== "" ? am5.color(color) : undefined,
      stroke: color !== "" ? am5.color(color) : undefined,
    });

    series.bullets.push(() => {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{valueX}",
          fill: am5.color('#fff'),
          populateText: true
        })
      });
    });

    series.set("tooltip", tooltip);
    series.data.setAll(data);
    series.events.once('datavalidated', () => {
      if (this.data.data.length > 5)
        xAxis.zoomToCategories(this.data!.data.at(0)!.site!, this.data!.data.at(4)!.site!) //Scroll to top five
      else
        xAxis.zoomToCategories(this.data!.data.at(0)!.site!, this.data!.data.at(-1)!.site!) //Scroll to last item
    });
    series.appear();

    return series;
  }
}
