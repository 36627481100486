import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';

@Component({
  selector: 'app-one-single-filter',
  templateUrl: './one-single-filter.component.html',
  styleUrls: ['./one-single-filter.component.css'],
})
export class OneSingleFilterComponent implements OnChanges {
  @Input() data!: any;
  @Input() selectedItem!: number;
  @Output() firstValue = new EventEmitter<string>();
  @Output() firstId = new EventEmitter<number>();
  firstFilter = new FormControl<number>(0);
  receivedData: any;

  constructor(private dataService: DataMultipleVariableService) {
    this.dataService.data$.subscribe((data) => {
      this.receivedData = data;
    });
    this.firstFilter.valueChanges.subscribe((x) => {
      //x equals to id
      const data_ = this.data.firstFilter.values.at(x);
      if (data_ !== undefined) {
        this.firstValue.emit(data_.value);
        this.firstId.emit(data_.itemId);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.firstFilter.patchValue(
        this.data.firstFilter.values.at(this.receivedData).id
      );
      if (this.selectedItem !== undefined) {
        const id = this.data.firstFilter.values.find(
          (item: any) => item.itemId === this.selectedItem
        ).id;
        this.firstFilter.patchValue(id);
      }
    }
  }
}
