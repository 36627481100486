import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { CornFarmingRoutingModule } from './corn-farming-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    CornFarmingRoutingModule,
    SharedModule, 
    MatExpansionModule
  ]
})
export class CornFarmingModule { }
