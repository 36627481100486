import { Component, OnInit } from '@angular/core';
import { GraphItemModel, GraphLinkModel } from 'src/app/core/models/graph.model';
import { ReferenceModel } from 'src/app/core/models/menu.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  constructor(private homeService: HomeService, private homeStore: HomeStore){

  }
  public step: number = 0;
  public mvmData: GraphItemModel[] = [
    { fullname: 'Housing Demand', name: 'Housing\nDemand', x: -6.2, y: -12.5, category: 0, isActive: true },
    { fullname: 'Wood Demand', name: 'Wood\nDemand', x: -4.2, y: -9, category: 0, isActive: false },
    { fullname: 'International Trade Policies', name: 'International\nTrade\nPolicies', x: -13, y: -12.5, category: 0, isActive: false },
    { fullname: 'Labor Shortage (L)', name: 'Labor\nShortage (L)', x: -4, y: -1, category: 0, isActive: false },
    { fullname: 'Labor Shortage (M)', name: 'Labor\nShortage (M)', x: -4, y: -3, category: 0, isActive: false },
    { fullname: 'Power Outage', name: 'Power\nOutage', x: -13.9, y: -2, category: 0, isActive: false },
    { fullname: 'Transportation Disruption', name: 'Transportation\nDisruption', x: -10, y: -1, category: 0, isActive: false },

    { fullname: 'Flooding', name: 'Flooding', x: -9, y: 2.5, category: 1, isActive: true },
    { fullname: 'Forest fires', name: 'Forest\nFires', x: -4, y: 3.2, category: 1, isActive: false },
    { fullname: 'Extreme Weather (Cold)', name: 'Extreme\nWeather\n(Cold)', x: -12, y: 5.5, category: 1, isActive: false },
    { fullname: 'Invasive species', name: 'Invasive\nspecies', x: -5.6, y: 5.7, category: 1, isActive: false },

    { fullname: 'Forest Plantations', name: 'Forest\nPlantations', x: 0.5, y: -9, category: 2, isActive: false },
    { fullname: 'Forest Harvesting Rate', name: 'Forest\nHarvesting\nRate', x: 5.8, y: -9, category: 2, isActive: false },

    { fullname: 'Raw Timber Supply', name: 'Raw\nTimber\nSupply', x: 10, y: -9, category: 3, isActive: false },
    { fullname: 'Raw Timber Shortage', name: 'Raw\nTimber\nShortage', x: 13, y: -9, category: 3, isActive: false },

    { fullname: 'Transportation/Logistics Operator. S-M', name: 'Transportation/\nLogistics\nOperator. S-M', x: 0, y: 4, category: 4, isActive: false },
    { fullname: 'Transportation/Logistics Operator. M-C', name: 'Transportation/\nLogistics\nOperator. M-C', x: 15.8, y: 4, category: 4, isActive: false },
    { fullname: 'Electricity', name: 'Electricity', x:0, y: 7.6, category: 4, isActive: false },
    { fullname: 'Fuel', name: 'Fuel', x:4, y: 7.6, category: 4, isActive: false },

    { fullname: 'Wood Product Manufacturing', name: 'Wood\nProduct\nManufacturing', x: 19, y: -1, category: 5, isActive: true },
    { fullname: 'Wood Shortage', name: 'Wood\nShortage', x: 26.5, y: -1, category: 5, isActive: false },

    { fullname: 'Lumber Prices', name: 'Lumber\nPrices', symbol: 'rect',  x:30, y: -7, category: 8, isActive: true },
    { fullname: 'Revenue', name: 'Revenue', symbol: 'rect',  x: 30, y: -10, category: 8, isActive: true },
  ];
  public mvmLinks: GraphLinkModel[] =[
    { source: 'Electricity' , target: 'Transportation/\nLogistics\nOperator. S-M',   lineStyle: { curveness: 0.1 } },
    { source: 'Electricity' , target: 'Transportation/\nLogistics\nOperator. M-C',   lineStyle: { curveness: 0.1 } },
    { source: 'Extreme\nWeather\n(Cold)' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
    { source: 'Extreme\nWeather\n(Cold)' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Extreme\nWeather\n(Cold)' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
    { source: 'Extreme\nWeather\n(Cold)' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Flooding' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
    { source: 'Flooding' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Flooding' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
    { source: 'Flooding' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Forest\nFires' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
    { source: 'Forest\nFires' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
    { source: 'Forest\nHarvesting\nRate' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
    { source: 'Forest\nPlantations' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
    { source: 'Fuel' , target: 'Transportation/\nLogistics\nOperator. S-M',   lineStyle: { curveness: 0.1 } },
    { source: 'Fuel' , target: 'Transportation/\nLogistics\nOperator. M-C',   lineStyle: { curveness: 0.1 } },
    { source: 'Housing\nDemand' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
    { source: 'Housing\nDemand' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'International\nTrade\nPolicies' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
    { source: 'International\nTrade\nPolicies' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
    { source: 'Invasive\nspecies' , target: 'Forest\nFires',   lineStyle: { curveness: 0.1 } },
    { source: 'Invasive\nspecies' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
    { source: 'Labor\nShortage (L)' , target: 'Transportation/\nLogistics\nOperator. S-M',   lineStyle: { curveness: 0.1 } },
    { source: 'Labor\nShortage (L)' , target: 'Transportation/\nLogistics\nOperator. M-C',   lineStyle: { curveness: 0.1 } },
    { source: 'Labor\nShortage (M)' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
    { source: 'Transportation/\nLogistics\nOperator. S-M' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'Transportation/\nLogistics\nOperator. M-C' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'Power\nOutage' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
    { source: 'Power\nOutage' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Power\nOutage' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
    { source: 'Power\nOutage' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
    { source: 'Raw\nTimber\nShortage' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
    { source: 'Raw\nTimber\nSupply' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'Transportation\nDisruption' , target: 'Transportation/\nLogistics\nOperator. S-M',   lineStyle: { curveness: 0.1 } },
    { source: 'Transportation\nDisruption' , target: 'Transportation/\nLogistics\nOperator. M-C',   lineStyle: { curveness: 0.1 } },
    { source: 'Wood\nDemand' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
    { source: 'Wood\nDemand' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'Wood\nProduct\nManufacturing' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
    { source: 'Wood\nShortage' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
    { source: 'Wood\nShortage' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
  ];

  public referenceData!: ReferenceModel[]

  ngOnInit(): void {
    const model = {bayesianId : this.homeStore.getSelectedSecondMenuOption()};
    this.homeService.getBayesianReferences(model).subscribe(
      {
        next: (res) => {
          this.referenceData = res;
        },
        error: (e) => { console.error(e); }
      });
  }
}
