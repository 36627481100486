import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopulationGrowthModelResponse, PopulationUsCountyModel, PopulationUsStateModel } from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DynamicTemplateModel, FilterType } from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { DynamicTemplateService } from 'src/app/core/services/shared/dynamic-template/dynamic-template.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mvm-dynamic-template',
  templateUrl: './mvm-dynamic-template.component.html',
  styleUrls: ['./mvm-dynamic-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})

export class MvmDynamicTemplateComponent implements OnInit, OnChanges{
  @Input() dataRes!: DynamicTemplateModel;
  @Input() idsFilters!: any;
  @Input() variable!: number;
  @Input() event!: string;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  @Output() selectedItem = new EventEmitter<number>();
  @Output() yAxisValue = new EventEmitter<string>();

  public data!: PopulationGrowthModelResponse | undefined;
  public dataMap!: PopulationUsCountyModel | undefined;
  public dataMapCopy!: PopulationUsCountyModel | undefined;
  public dataStateMap!: PopulationUsStateModel | undefined;
  public histogram!: any;
  public XYChart!: any | undefined;
  public ecdfChart!: any;
  public minDistance: number = 1;

  public countyBounds!: any;
  public minYear!: any;
  public maxYear!: any;
  public valueYear!: any;
  private selectedState!: any;
  private selectedCounty!: any;
  selectedTab: string = 'metadata';
  public goToCounty: boolean = false;

  public hasNationalData: boolean = false;

  public filterType = FilterType;
  public propertyName: string = "values";
  constructor(private dynamicTemplateService: DynamicTemplateService,
    private spinner: NgxSpinnerService,
    private chartjsService: ChartjsServiceService,
    private dialog: MatDialog) {

  }
  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatDates();
    if(this.dataRes.data.hasCountyLevel === true || this.dataRes.data.hasCountyLevel === undefined){

      this.dataMap = this.dataRes.data as PopulationUsCountyModel;
      this.dataMapCopy = JSON.parse(JSON.stringify(this.dataMap)) as PopulationUsCountyModel;
      this.dataStateMap = this.dataRes.data as PopulationUsStateModel;

      this.minYear =this.dataMapCopy.dates.at(0);
      this.valueYear = this.dataMapCopy.dates.at(0);
      this.maxYear = this.dataMapCopy.dates.at(-1);

      this.histogram = {
        data: this.dynamicTemplateService.getHistogram(this.dataMapCopy, this.valueYear, this.dataRes.histogram.title, this.propertyName),
        title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
        yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
      }

      this.ecdfChart  = {
        title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
        data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFGeneral(this.dataMapCopy, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
        yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
      };
    }else{
      this.dataStateMap = this.dataRes.data as PopulationUsStateModel;

      this.minYear =this.dataStateMap.dates.at(0);
      this.valueYear = this.dataStateMap.dates.at(0);
      this.maxYear = this.dataStateMap.dates.at(-1);

      this.histogram = {
        data: this.dynamicTemplateService.getHistogramByState(this.dataStateMap, this.valueYear, this.dataRes.histogram.title, this.propertyName),
        title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
        yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
      };

      this.ecdfChart  = {
        title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
        data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFByState(this.dataStateMap, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
        yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
      };
    }

    //console.log(this.ecdfChart);
    this.spinner.hide();
  }
  formatDates(){
    this.dataRes.data.dates.forEach((date: string, i: number) => {
    const date_ = new Date(date);
    date_.setDate(date_.getDate() + this.dataRes.formatInfo.addDays);
    this.dataRes.data.dates[i] = date_.toLocaleDateString(this.dataRes.formatInfo.region, this.dataRes.formatInfo.format);
    });
  }

  ngOnInit(): void {

  }

  emitFirstValue($event:any){
    this.firstValue.emit($event + '1');
    if(this.dataRes.emitFirstValue)
      this.reloadView();
  }
  emitSecondValue($event:any){
    this.secondValue.emit($event +'2');
    if($event === "Gigajoules" || $event === "Fatalities" || $event === "Number of deaths Last 7" || $event === "Values"
    || $event === "Cosechada - Sum" || $event === "Average Farm Price Tonne" || $event === "Per 100,000 pop"){
      this.event = $event;
      this.propertyName = "valuesSecond";
    } else if($event === "Number of accidents" || $event === "Rate of deaths" || $event === "Siniestrada - Sum" || $event === "Average Yield Bushel"  || $event === "Female (%)"){
      this.event = $event;
      this.propertyName = "valueThird";
    } else if( $event === "Rate of deaths Last 7" || $event === "Volumen Producción - Sum" || $event === "Average Yield Kilogram" || $event === "Age <30 years (%)"){
      this.event = $event;
      this.propertyName = "valueFourth";
    } else if( $event === "Number of deaths Last 14" || $event === "Rendimiento - AVG" || $event === "Harvested Area Acres" || $event === "Age 30–59 years (%)"){
      this.event = $event;
      this.propertyName = "valueFifth";
    } else if( $event === "Rate of deaths Last 14" || $event === "Precio - AVG" || $event === "Harvested Area Hectares" || $event === "Age 60+ years (%)"){
      this.event = $event;
      this.propertyName = "valueSixth";
    } else if( $event === "Average deaths Last 7" || $event === "Valor Producción - Sum" || $event === "Production Bushels"){
      this.event = $event;
      this.propertyName = "valueSeventh";
    } else if( $event === "Average deaths Last 14" || $event === "Production Tonne"){
      this.event = $event;
      this.propertyName = "valueEighth";
    } else if( $event === "Seeded Area Acres"){
      this.event = $event;
      this.propertyName = "valueNinth";
    } else if( $event === "Seeded Area Hectares"){
      this.event = $event;
      this.propertyName = "valueTenth";
    } else if( $event === "Total Farm"){
      this.event = $event;
      this.propertyName = "valueEleventh";
    }
    else{
      this.event = $event;
      this.propertyName = "values";
    }
    this.reloadView();
  }
  emitThirdValue($event:any){
    this.thirdValue.emit($event + '3');
      this.reloadView();
  }
  emitFourthValue($event:any){
    this.fourthValue.emit($event + '4');
      this.reloadView();
  }
  emitFifthValue($event:any){
    this.fifthValue.emit($event + '5');
      this.reloadView();
  }
  emitSixthValue($event:any){
    this.sixthValue.emit($event + '5');
      this.reloadView();
  }
  emitYAxisValue($event:any){
    this.yAxisValue.emit($event);
    this.reloadView();
  }

  reloadView(){
    this.spinner.show();
    setTimeout(() => {
      if(this.dataRes.data.hasCountyLevel === true || this.dataRes.data.hasCountyLevel === undefined){

        this.minYear =this.dataMapCopy!.dates.at(0);
        this.valueYear = this.dataMapCopy!.dates.at(0);
        this.maxYear = this.dataMapCopy!.dates.at(-1);

        this.histogram = {
          data: this.dynamicTemplateService.getHistogram(this.dataMapCopy, this.valueYear, this.dataRes.histogram.title, this.propertyName),
          title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
        };

        this.ecdfChart  = {
          title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFGeneral(this.dataMapCopy, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
          yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
        };
      }else{

        this.minYear =this.dataStateMap!.dates.at(0);
        this.valueYear = this.dataStateMap!.dates.at(0);
        this.maxYear = this.dataStateMap!.dates.at(-1);

        this.histogram = {
          data: this.dynamicTemplateService.getHistogramByState(this.dataStateMap, this.valueYear, this.dataRes.histogram.title, this.propertyName),
          title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
        };

        this.ecdfChart  = {
          title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFByState(this.dataStateMap, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
          yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
        };
      }
      this.spinner.hide();
    }, 300);
  }
  getSelectedState(state: any) {
    if(this.dataRes.data.hasCountyLevel === false) return;
    this.spinner.show();

    this.countyBounds = state.bounds;
    this.selectedState = { id: state.id, name: state.name };

    const dataCopy = JSON.parse(JSON.stringify(this.dataMapCopy)) as PopulationUsCountyModel;
    const filteredData = dataCopy.data.filter((county: any) => county.state === state.id);
    const max = Math.max(...[].concat(...filteredData.map((item: any) => { return item.values })));

    this.dataMap!.data = filteredData;
    this.dataMap!.maxCounty = max;
    this.dataMap!.selectedState = this.selectedState;

    this.goToCounty = true;
    this.spinner.hide();
  }

  getSelectedCounty(county: any) {
    this.spinner.show();
    this.selectedCounty = { id: county.id, name: county.name };
    setTimeout(() => {

      this.ecdfChart  = {
        title: `${this.dataRes.ECDF.titleCounty} ${this.selectedCounty.name} county ${this.selectedCounty.id}~from ${this.dynamicTemplateService.getFormatFromDate(this.minYear.toString(), this.dataRes.formatInfo)} to ${this.dynamicTemplateService.getFormatFromDate(this.maxYear.toString(), this.dataRes.formatInfo)}`.split('~'),
        data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFByCounty(this.dataMapCopy, this.selectedCounty, this.dataRes.ECDF.title, this.selectedState, this.propertyName), true, false, true),
        yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
      };

      this.histogram = {
        data: this.dynamicTemplateService.getHistogramByCounty(this.dataMapCopy, this.selectedCounty, this.dataRes.histogram.title, this.selectedState),
        title: `${this.dataRes.histogram.titleCounty} ${this.selectedCounty.name} county ${this.selectedCounty.id}\nfrom ${this.dynamicTemplateService.getFormatFromDate(this.minYear.toString(), this.dataRes.formatInfo)} to ${this.dynamicTemplateService.getFormatFromDate(this.maxYear.toString(), this.dataRes.formatInfo)}`,
        yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
      };

      this.XYChart = {
        title: `${this.dataRes.xyChart.title} ${this.selectedCounty.name} county ${this.selectedCounty.id}\nfrom ${this.dynamicTemplateService.getFormatFromDate(this.minYear.toString(), this.dataRes.formatInfo)} to ${this.dynamicTemplateService.getFormatFromDate(this.maxYear.toString(), this.dataRes.formatInfo)}`,
        data: this.dynamicTemplateService.getXYByCounty(this.dataMapCopy, this.selectedCounty, this.dataRes.xyChart.title, this.selectedState),
        xLabel: this.dataRes.xyChart.xLabel, yLabel: this.dataRes.xyChart.yLabel
      };
      this.goToCounty = true;
      this.spinner.hide();
    }, 300)
  }
  getCurrentTime(year: number) {
    const date_ = new Date(year);

    if (this.valueYear === date_.toLocaleDateString()) return;

    date_.setDate(date_.getDate() + this.dataRes.formatInfo.addDays);
    const newDate = date_.toLocaleDateString(this.dataRes.formatInfo.region, this.dataRes.formatInfo.format);


    this.valueYear = year;

if(typeof year === 'number'){
  var date = new Date(year);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const years = date.getFullYear();
    const newDate = `${day}/${month}/${years}`;
    this.valueYear = newDate;
}
    if (this.valueYear < this.minYear) {
      this.valueYear = JSON.parse(JSON.stringify(this.minYear));
    }
    this.getDataFromSelectedYear(true);
  }


  getDataFromSelectedYear(isState: boolean) {
      if(this.dataRes.data.hasCountyLevel === true || this.dataRes.data.hasCountyLevel === undefined){

        this.histogram = {
          data: this.dynamicTemplateService.getHistogram(this.dataMapCopy, this.valueYear, this.dataRes.histogram.title, this.propertyName),
          title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
        };

        this.ecdfChart  = {
          title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFGeneral(this.dataMapCopy, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
          yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
        };

      }else{

        this.histogram = {
          data: this.dynamicTemplateService.getHistogramByState(this.dataStateMap, this.valueYear, this.dataRes.histogram.title, this.propertyName),
          title: this.dataRes.histogram.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          yLabel: this.dataRes.histogram.yLabel, xLabel: this.dataRes.histogram.xLabel
        };

        this.ecdfChart  = {
          title: this.dataRes.ECDF.title + this.dynamicTemplateService.getFormatFromDate(this.valueYear.toString(), this.dataRes.formatInfo),
          data: this.chartjsService.getDataMapped(this.dynamicTemplateService.getECDFByState(this.dataStateMap, this.valueYear, this.dataRes.formatInfo, this.propertyName), true, false, true),
          yLabel: this.dataRes.ECDF.yLabel, xLabel: this.dataRes.ECDF.xLabel
        };
      }
  }

  returnToState() {
    this.spinner.show();
    this.goToCounty = !this.goToCounty
    this.reloadView();
    this.spinner.hide();
  }

  openStudyDialog(){
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {filterInfo: this.dataRes.filterVariableInfo, selectedInfo: this.dataRes.selectedInfo},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }

    });
    }


}
  