<div style="display: flex; justify-content: flex-start;gap: 2rem;">
    <button class="btn btn-home" *ngIf="this.data.hasStudyButton" (click)="openStudyDialog()">Select variable</button>
</div>

<mat-tab-group>

  <mat-tab label="Metadata & Visualization">
    <ng-template matTabContent>
        <div>
          <div style="padding: 1rem; background-color: var(--white);">
            <h2>{{this.data.data.metadata.name}}</h2>
            <p>
                {{this.data.data.metadata.name}}
            </p>
            <div style="padding-left: 3rem;">
              <p><span style="font-weight: 700;">Risk Component Classification:</span> {{this.data.data.metadata.classification == "" ? "N/A" : this.data.data.metadata.classification}}</p>
              <p><span style="font-weight: 700;">Type of Evidence:</span> {{this.data.data.metadata.evidenceType}}</p>
              <p><span style="font-weight: 700;">Spatial Resolution:</span> {{this.data.data.metadata.spatialResolution}}</p>
              <p><span style="font-weight: 700;">Temporal Resolution:</span> {{this.data.data.metadata.temporalResolution}}</p>
              <p><span style="font-weight: 700;">Time Period:</span> {{this.data.data.metadata.timePeriod}}</p>
              <p><span style="font-weight: 700;">Data Source:</span> {{this.data.data.metadata.source}}</p>
            </div>
          </div>
          <br>
          <mat-card>
            <div>
              <img class="item-template" [src]="this.data.urlImage" [alt]="this.data.alt">
            </div>
           </mat-card>
        </div>
    </ng-template>
  </mat-tab>
  <!-- <mat-tab label="Trend Model & Residual Analysis"> Content 2 </mat-tab>
  <mat-tab label="Autocorrelation Analysis"> Content 3 </mat-tab>
  <mat-tab label="Seasonal & Trend Decomposition"> Content 4 </mat-tab>
  <mat-tab label="Second-Order Statistics"> Content 6 </mat-tab>
  <mat-tab label="Bayesian Network Model with Synchronized Data"> Content 7 </mat-tab> -->

</mat-tab-group>
