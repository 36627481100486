import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Services //
import { NgxSpinnerService } from 'ngx-spinner';
import { MapService } from '../../../../core/services/mapService/map.service';

@Component({
  selector: 'app-second-order',
  templateUrl: './second-order.component.html',
  styleUrls: ['./second-order.component.css']
})
export class SecondOrderComponent implements OnInit, OnDestroy {
  @Input() tab: any;
  @Input() data: any;
  @Input() typeTemplate: string = "";

  public secondOrder: any = {};
  private subscribe: any;

  public months = [
    { value: 'January', viewValue: 'January' },
    { value: 'February', viewValue: 'February' },
    { value: 'March', viewValue: 'March' },
    { value: 'April', viewValue: 'April' },
    { value: 'May', viewValue: 'May' },
    { value: 'June', viewValue: 'June' },
    { value: 'July', viewValue: 'July' },
    { value: 'August', viewValue: 'August' },
    { value: 'September', viewValue: 'September' },
    { value: 'Octuber', viewValue: 'Octuber' },
    { value: 'November', viewValue: 'November' },
    { value: 'December', viewValue: 'December' },
  ];
  public form!: FormGroup;
  
  constructor(public fb: FormBuilder,  private mapService: MapService,
    private spinner: NgxSpinnerService) {
      this.form = this.fb.group({
        startYear: [1990], // Puedes inicializar con un valor predeterminado si es necesario
        endYear: [2020],
        startMonth: [this.months[0].value],
        endMonth: [this.months[0].value]
      });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.subscribe = this.tab._closestTabGroup.animationDone.subscribe(() => {
      this.secondOrder.heatmap({
        'colorscale': 'Viridis',
      });
      this.secondOrder['layout'] = (value: any) => {
        value['title']['text'] = 'Second Order Statistics';
        value['scene']['xaxis']['text'] = '';
        value['scene']['xaxis']['fixedrange'] = true;
        value['scene']['xaxis']['automargin'] = true;
        value['scene']['xaxis']['standoff'] = 10;
        value['scene']['xaxis']['tickangle'] = 0;
  
        value['scene']['yaxis']['text'] = '';
        value['scene']['yaxis']['standoff'] = 10;
        value['scene']['yaxis']['tickangle'] = 0;
        value['scene']['yaxis']['fixedrange'] = true;
        value['scene']['yaxis']['automargin'] = true;
        return value;
      };
      this.secondOrder['data'] = (value: any) => {
        value[0]['x'] = ['PPI Wood Container', 'Gasoline Price per Gallon', 'Ethanol Price per Gallon', 'Corn Price per Brushel'];
        value[0]['y'] = ['Corn Price per Brushel', 'Ethanol Price per Gallon', 'Gasoline Price per Gallon', 'PPI Wood Container'];
        value[0]['z'] = [
          [-0.3, 0.5, -0.3, 1],
          [-0.5, 0.5, 1, -0.3],
          [0.5, 1, 0.3, 0.5],
          [1, 0.5, -0.5, -0.3]
        ];
        return value;
      };
      this.secondOrder.update();
    });
    this.spinner.hide();
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
  }

}
