
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
declare const L: any;
import 'leaflet';
import 'leaflet-timedimension';
import '../../../../../assets/js/leaflet.timedimension.geotiff';
import '../../../../../assets/js/leaflet.timedimension.heatmap.control';
import '../../../../../assets/js/geotiff';
import '../../../../../assets/js/plotty';
import '../../../../../assets/js/leaflet-geotiff';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-dynamic-geotiff-map',
  templateUrl: './dynamic-geotiff-map.component.html',
  styleUrls: ['./dynamic-geotiff-map.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS,
    },
  ],
})
export class DynamicGeotiffMapComponent {
  @ViewChild('map', { read: ElementRef })
  private mapEl!: ElementRef<HTMLElement>;
  @ViewChild('picker', { static: false })
  private picker!: MatDatepicker<Date>;
  @Input()
  data!: any;
  @Input() dates!: any;
  @Input() formatInfo!: any;
  @Input() mapInfo!: any;
  private map: any;
  private geojson!: any;
  private player!: any;
  private heatmapControl!: any;

  public selectYear!: Date;
  public minDate!: Date;
  public maxDate!: Date;
  public showYearSelector: boolean = false;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.minDate = new Date(this.dates.at(0));
    this.maxDate = new Date(this.dates.at(-1));
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.showYearSelector = true;

    this.initMap();
    this.onReload();
  }
  chosenYearHandler(ev: any, input: any) {
    let { _d } = ev;
    this.selectYear = _d;
    this.picker.close();
    this.getNearestDateFromYear(this.selectYear.getFullYear());
  }

  getNearestDateFromYear(year: number) {
    this.spinner.show();

    const datesFromYear: string[] = this.dates.filter((date: string) =>
      date.includes(year.toString())
    );
    if (!datesFromYear.length) {
      this.spinner.hide();
      return;
    }
    this.map.timeDimension.setCurrentTime(datesFromYear.at(0));
    this.spinner.hide();
  }

  stopPlayer() {
    this.player.stop();
  }

  private initMap(): void {
    this.map = L.map('mapTiff', {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 12,
        minZoom: 1,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: () => {
          return '<img class="icon-svg" src="' + environment.BaseAssets + '/house-solid.svg" alt="home-icon">';
        },
        zoomHomeTitle: 'Zoom home',
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + '-in',
          container,
          this._zoomIn
        );
        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + '-home',
          container,
          this._zoomHome
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + '-out',
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (
        html: any,
        title: any,
        className: any,
        container: any,
        fn: any
      ) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  onReload() {
    this.initStatesLayer();
  }

  private initStatesLayer() {
    var timeDimension = new L.TimeDimension({
      period: 'PT1H',
      times: this.dates,
    });
    this.map.timeDimension = timeDimension;
    this.player = new L.TimeDimension.Player(
      {
        transitionTime: 2000,
        loop: false,
        startOver: false,
      },
      timeDimension
    );
    var timeDimensionControlOptions = {
      player: this.player,
      timeDimension: timeDimension,
      position: 'bottomleft',
      autoPlay: false,
      speedSlider: false,
      minSpeed: 1,
      speedStep: 1,
      maxSpeed: 1,
      timeSliderDragUpdate: false,
      region: this.formatInfo.region,
      format: this.formatInfo.format,
      addDays: this.formatInfo.addDays,
      timeSlider: false,
      playButton: false,
    };
    var timeDimensionControl = new L.Control.TimeDimensionHeatmap(
      timeDimensionControlOptions
    );

    this.map.addControl(timeDimensionControl);
    this.map.timeDimension.setCurrentTime(this.dates[0]);
    this.addData();
  }
  private addData() {
    this.geojson = L.timeDimension.layer.Geotiff(this.data, {
      GeotiffOptions: {
        band: 0,
        displayMin: 0,
        displayMax: 40,
        colorScale: 'rainbow', // viridis | rainbow | greys
        clampLow: false,
        clampHigh: false,
      },
    });
    this.geojson.addTo(this.map);
  }
}