import { Component, OnInit } from '@angular/core';
import { ReferenceModel } from 'src/app/core/models/menu.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(private homeService: HomeService, private homeStore: HomeStore) {}
  public step: number = 0;
  public referenceData!: ReferenceModel[];

  ngOnInit(): void {
    const model = { bayesianId: this.homeStore.getSelectedSecondMenuOption() };
    this.homeService.getBayesianReferences(model).subscribe({
      next: (res) => {
        this.referenceData = res;
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
