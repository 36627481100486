<div class="d-flex justify-content-between pb-2" >
    <button class="btn btn-home" *ngIf="this.dataRes.hasStudyButton" (click)="openStudyDialog()" title="Current variable: {{this.dataRes.data.metadata.name}}">Select variable</button>
  </div>
  <!--INFORMACION DE CABECERA-->
  <div class="card">
    <div class="card-body">
      <div class="row gap-1">
        <div class="location">{{this.dataRes.data.metadata.name}}</div>
        <p>
          {{this.dataRes.data.metadata.description}}
        </p>
      </div>
  
      <div class="subtitle2 pt-2">Metadata</div>
      <div class="row">
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span>
              {{this.dataRes.data.metadata.classification == "" ? "N/A" : this.dataRes.data.metadata.classification}}</li>
            <li class="list-group-item"><span class="subtitle">Type of Evidence:</span>
              {{this.dataRes.data.metadata.evidenceType}}</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Temporal Resolution:</span>
              {{this.dataRes.data.metadata.temporalResolution}}</li>
            
              <li class="list-group-item"><span class="subtitle">Time Period:</span>{{this.dataRes.data.metadata.timePeriod}}</li>         
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <!-- <li class="list-group-item"><span class="subtitle">Spatial Resolution:</span>California</li> -->
            <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.dataRes.data.metadata.source}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--TAB OPTIONS-->
  <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button (click)="tabEventAction('Metadata')" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
        aria-controls="home" aria-selected="true">
        <span class="textMedium">Metadata & Visualization</span>
      </button>
    </li>
  </ul>
  <!--TABS CONTENTS-->
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <!--FILTRO INTERNOS-->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="showBorder">
          <div class="card half-border">
            <div *ngIf="this.dataRes.hasFilter">
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.oneFilter">
  
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-two-single-filters [data]="this.dataRes.filterInfoSecond"
                    (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                </div>
  
                <div *ngIf="!this.dataRes.filterInfoSecond">
                  <app-two-single-filters [data]="this.dataRes.filterInfo"
                    (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                </div>
              </div>
  
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.twoFilters">
  
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-two-single-filters [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                </div>
  
                <div *ngIf="!this.dataRes.filterInfoSecond">
                  <app-two-single-filters [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                </div>
  
              </div>
  
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.threeFilters">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-three-single-filters [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)"
                    (thirdValue)="emitThirdValue($event)" [defaultSelection]="idsFilters"></app-three-single-filters>
                </div>
                <div *ngIf="!this.dataRes.filterInfoSecond">
                  <app-three-single-filters [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)"
                    (thirdValue)="emitThirdValue($event)" [defaultSelection]="idsFilters"></app-three-single-filters>
                </div>
  
              </div>
  
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.fourFilters">
                <app-four-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                            (firstValue)="emitFirstValue($event)"
                                            (secondValue)="emitSecondValue($event)"
                                            (thirdValue)="emitThirdValue($event)"
                                            (fourthValue)="emitFourthValue($event)"
                                            [defaultSelection]="idsFilters"></app-four-single-filter>
  
              </div>
  
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.fiveFilters">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-five-single-filter [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" (thirdValue)="emitThirdValue($event)"
                    (fourthValue)="emitFourthValue($event)"
                    (fifthValue)="emitFifthValue($event)" [defaultSelection]="idsFilters"></app-five-single-filter>
                </div>
                <div *ngIf="!this.dataRes.filterInfoSecond">
                  <app-five-single-filter [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" (thirdValue)="emitThirdValue($event)"
                    (fourthValue)="emitFourthValue($event)"
                    (fifthValue)="emitFifthValue($event)" [defaultSelection]="idsFilters"></app-five-single-filter>
                </div>
  
              </div>
  
              <div class="row" *ngIf="this.dataRes.filterType === this.filterType.sixFilters">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-six-single-filter [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" (thirdValue)="emitThirdValue($event)"
                    (fourthValue)="emitFourthValue($event)" (fifthValue)="emitFifthValue($event)"
                    (sixthValue)="emitSixthValue($event)" [defaultSelection]="idsFilters"></app-six-single-filter>
                </div>
                <div *ngIf="!this.dataRes.filterInfoSecond">
                  <app-six-single-filter [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)"
                    (secondValue)="emitSecondValue($event)" (thirdValue)="emitThirdValue($event)"
                    (fourthValue)="emitFourthValue($event)" (fifthValue)="emitFifthValue($event)"
                    (sixthValue)="emitSixthValue($event)" [defaultSelection]="idsFilters"></app-six-single-filter>
                </div>
  
              </div>
  
              <!--YAxis-->
              <div *ngIf="this.dataRes.filterInfoSecond!=null && this.dataRes.filterInfoSecond.yAxis!=null">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-y-axis-filter [data]="this.dataRes.filterInfoSecond"
                    (yAxisValue)="emitYAxisValue($event)" [defaultSelection]="idsFilters"></app-y-axis-filter>
                </div>
              </div>
  
            </div>
            <br>
            <div *ngIf="this.dataRes.data.listYears">
              <app-selector-years [listYears]="this.dataRes.data.listYears" (selectoryearValue)="emitSelectedYearValue($event)"></app-selector-years>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal open if the 'showModal' variable is true -->
      <div *ngIf="showModal" class="modal" id="creative" #modal tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
        <div class="modal-dialog" role="document">
          <div class="modal-content creative-modal">
            <div class="modal-body">
              <p class="modal-text">The combination does not fetch any data.</p>
              <button type="button" id="closeButton" (click)="closeModal()">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!--PRIMERA LINEA DE CONTENEDORES-->
      <div class="row" [ngClass]="{'p-2': showBorder == true }">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card" [ngClass]="{'half-border': showBorder == false }">
            <div *ngIf="this.XYChart != undefined && this.dataRes.timeUnit == 'quarter'"
              style="box-shadow: none; height: 300px !important">
              <app-line-category [data]="this.XYChart" [barId]="'line-category-data'" [metadata]="this.dataRes.data.metadata"
                style="--minWidth: 0px; --minHeight: 300px;"></app-line-category>
            </div>
            <div *ngIf="this.XYChart != undefined && this.dataRes.timeUnit != 'quarter'">
              <app-line [data]="this.XYChart" [barId]="'line-data'" [timeUnit]="this.dataRes.timeUnit" [metadata]="this.dataRes.data.metadata"></app-line>
            </div>
          </div>
        </div>
      </div>
      <!--SEGUNDA LINEA DE CONTENEDORES-->
      <div class="row p-2">
        <!--PRIMERA COLUMNA-->
        <div class="col-sm-6 col-md-6 col-lg-6">
          <!--PRIMERA GRAFICA-->
          <div class="card" *ngIf="this.ecdfChart != undefined">
            <div *ngIf="showAlertEcdf; else elseBlockEcdf">
              <br>
              <h3>Due to the data, it's not possible to generate a correct ECDF plot to show.</h3>
            </div>
            <ng-template #elseBlockEcdf>
              <app-ecdf-chart [data]="ecdfChart.data" [hasMinMax]="false" [datasetPosition]="'top'"
                [datasetAlign]="'center'" [xLabel]="ecdfChart.xLabel" [yLabel]="ecdfChart.yLabel"
                [title]="ecdfChart.title" [um]="dataRes.data.yAxisName"> 
              </app-ecdf-chart>
            </ng-template>
          </div>
        </div>
        <!--SEGUNDA COLUMNA-->
        <div class="col-sm-6 col-md-6 col-lg-6">
          <!--SEGUNDA GRAFICA-->
          <div class="card" *ngIf="this.histogram != undefined">
            <div *ngIf="showAlertEcdf; else elseBlockHistogram">
              <br>
              <h3>Due to the data, it's not possible to generate a correct Histogram to show.</h3>
            </div>
            <ng-template #elseBlockHistogram>
              <app-d3-histogram [um]="dataRes.data.yAxisName" [data]="this.histogram.data" [plusMax]="this.dataRes.plusMax"
                [title]="this.histogram.title" [xLabel]="this.histogram.xLabel" [yLabel]="this.histogram.yLabel"
                [Id]="'histogram-county'"></app-d3-histogram>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </div>
  
