import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chart, registerables, LinearScale, CategoryScale } from 'chart.js';
import {
  BoxPlotController,
  BoxAndWiskers,
} from '@sgratzl/chartjs-chart-boxplot';

@Component({
  selector: 'app-boxplot',
  templateUrl: './boxplot.component.html',
  styleUrls: ['./boxplot.component.css'],
})
export class BoxplotComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLElement>;
  @Input() data: any = null;
  @Input() yLabel: string | string[] = '';
  @Input() title: string = '';
  private options: any = null;
  public chart: any;
  private validate: boolean = false;

  constructor() {
    Chart.register(...registerables);
    Chart.register(
      BoxPlotController,
      BoxAndWiskers,
      LinearScale,
      CategoryScale
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initOptions();
  }

  ngOnInit(): void {}

  private initOptions(): void {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 20,
        },
      },
      scales: {
        x: {
          min: 0,
          max: 3,
        },
        y: {
          beginAtZero: true,
          grace: '10%',
          title: {
            display: true,
            text: this.yLabel,
            font: {
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
    };
    this.createChart();
  }

  private createChart(): void {
    const scrollButtons = {
      id: 'scrollButtons',
      afterEvent(chart: any, args: any) {
        const {
          ctx,
          canvas,
          data,
          chartArea: { top, bottom, left, right, height },
          scales: { x },
        } = chart;

        canvas.addEventListener('mousemove', (event: any) => {
          const xCoor = args.event.x;
          const yCoor = args.event.y;

          if (
            xCoor >= left - 18 &&
            xCoor <= left + 18 &&
            yCoor >= top + height / 2 - 18 &&
            yCoor <= top + height / 2 + 18
          ) {
            canvas.style.cursor = 'pointer';
          } else if (
            xCoor >= right - 18 &&
            xCoor <= right + 18 &&
            yCoor >= top + height / 2 - 18 &&
            yCoor <= top + height / 2 + 18
          ) {
            canvas.style.cursor = 'pointer';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDatasetsDraw(chart: any, args: any, plugOptions: any) {
        const {
          ctx,
          data,
          chartArea: { top, bottom, left, right, height },
          scales: { x },
        } = chart;
        ctx.save();
        const lastValue = data.labels.length - 1;
        const angle = Math.PI / 180;
        const radius = 15;
        function buttons(
          x: any,
          y: any,
          r: any,
          aS: any,
          aE: any,
          text: string
        ) {
          ctx.beginPath();
          ctx.lineWidth = 3;
          ctx.strokeStyle = 'rgba(255,26,104,1)';
          ctx.fillStyle = 'white';
          ctx.arc(x, y, r, aS, aE, false);
          ctx.closePath();
          ctx.stroke();
          ctx.fill();

          ctx.font = 'bold 20px sans-serif';
          ctx.textAlign = 'center';
          ctx.fillStyle = 'rgba(102, 102, 102, 1)';
          ctx.fillText(text, x, y);
          ctx.restore();
        }
        if (x.min > 0) {
          buttons(left, top + height / 2, radius, angle * 0, angle * 360, '<');
        }
        if (x.max < lastValue) {
          buttons(right, top + height / 2, radius, angle * 0, angle * 360, '>');
        }
      },
    };

    this.destroyChart();
    this.chart = new Chart(this.chartEl.nativeElement as HTMLCanvasElement, {
      type: 'boxplot',
      data: this.data,
      options: this.options,
      plugins: [scrollButtons],
    });
    if (!this.validate) {
      this.chart.canvas.addEventListener('click', (e: any) => {
        scrollEffect(e);
      });
      this.validate = true;
    }

    const scrollEffect = (click: any) => {
      const {
        ctx,
        canvas,
        data,
        chartArea: { top, bottom, left, right, height },
        scales: { x },
      } = this.chart;

      const xCoor = click.offsetX;
      const yCoor = click.offsetY;
      const lastValue = data.labels.length - 1;
      if (this.chart.options.scales.x.min > 0) {
        if (
          xCoor >= left - 18 &&
          xCoor <= left + 18 &&
          yCoor >= top + height / 2 - 18 &&
          yCoor <= top + height / 2 + 18
        ) {
          this.chart.options.scales.x.min = this.chart.options.scales.x.min - 4;
          this.chart.options.scales.x.max = this.chart.options.scales.x.max - 4;
        } else {
          this.chart.options.scales.x.min === 0;
        }
      }
      if (this.chart.options.scales.x.max < lastValue) {
        if (
          xCoor >= right - 18 &&
          xCoor <= right + 18 &&
          yCoor >= top + height / 2 - 18 &&
          yCoor <= top + height / 2 + 18
        ) {
          this.chart.options.scales.x.min = this.chart.options.scales.x.min + 4;
          this.chart.options.scales.x.max = this.chart.options.scales.x.max + 4;
        } else {
          this.chart.options.scales.x.max === lastValue;
        }
      }

      this.chart.update();
    };
  }
  private destroyChart() {
    if (this.chart != null || this.chart != undefined) {
      this.chart.destroy();
    }
  }
  ngOnDestroy(): void {
    this.destroyChart();
  }
}
