
  <div style="display: flex; justify-content: flex-start;gap: 2rem;">
    <button class="btn btn-home" *ngIf="this.dataRes.hasStudyButton" (click)="openStudyDialog()" title="Current variable: {{this.dataRes.data.metadata.name}}">Select variable</button>
    <button class="btn btn-home" *ngIf="this.goToCounty" (click)="returnToState()">Return to States</button>
  </div>
  <br/>
  <!-- METADATA-->
  <div class="card">
    <div class="card-body">
      <div class="row gap-1">
        <div class="location">{{this.dataRes.data.metadata.name}}</div>
        <p>
            {{this.dataRes.data.metadata.description}}
        </p>
      </div>
      
      <div class="subtitle2 pt-2">Metadata</div>
      <div class="row">
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span> {{this.dataRes.data.metadata.classification == "" ? "N/A" : this.dataRes.data.metadata.classification}}</li>
            <li class="list-group-item"><span class="subtitle">Type of Evidence:</span> {{this.dataRes.data.metadata.evidenceType}}</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Spatial Resolution:</span> {{this.dataRes.data.metadata.spatialResolution}}</li>
            <li class="list-group-item"><span class="subtitle">Temporal Resolution:</span> {{this.dataRes.data.metadata.temporalResolution}}</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Time Period:</span> {{this.dataRes.data.metadata.timePeriod}}</li>
            <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.dataRes.data.metadata.source}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- TABS-->
<ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button (click)="selectTab('metadata')" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
      <span class="textMedium">Metadata & Visualization</span>
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button (click)="selectTab('trend')" class="nav-link" id="trend-tab" data-bs-toggle="tab" data-bs-target="#trend" type="button" role="tab" aria-controls="trend" aria-selected="false">
      <span class="textMedium">Trend Model & Residual Analysis</span>
    </button>
  </li>
</ul>

<!--TABS CONTENTS-->
<div class="tab-content" id="myTabContent">

  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <!--FILTRO INTERNOS-->
    <div class="row" *ngIf="this.dataRes.hasFilter">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card half-border">
          <div *ngIf="this.dataRes.hasFilter">          
              <h4 style="font-weight: 700;">Section of filters:</h4>
  
              <div *ngIf="this.dataRes.filterType === this.filterType.oneFilter">
                
                <div *ngIf="this.dataRes.filterInfoSecond; else elseBlockFirstFilter">
                  <app-two-single-filters [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"> </app-two-single-filters>
                </div>
                <ng-template #elseBlockFirstFilter>
                  <app-two-single-filters [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"> </app-two-single-filters>
                </ng-template>
              
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.twoFilters">
                <app-two-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                        (firstValue)="emitFirstValue($event)"
                        (secondValue)="emitSecondValue($event)"
                        [defaultSelection]="idsFilters">
                </app-two-single-filters>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.threeFilters">
                  <app-three-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"
                                        (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)" [defaultSelection]="idsFilters"></app-three-single-filters>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.fourFilters">
                  <app-four-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"
                                        (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)"
                                        (fourthValue)="emitFourthValue($event)" [defaultSelection]="idsFilters"></app-four-single-filter>
              </div>

              <div *ngIf="this.dataRes.filterType === this.filterType.fiveFilters">
                  <app-five-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"
                                        (secondValue)="emitSecondValue($event)"
                                        (thirdValue)="emitThirdValue($event)"
                                        (fourthValue)="emitFourthValue($event)"
                                        (fifthValue)="emitFifthValue($event)" [defaultSelection]="idsFilters"></app-five-single-filter>
              </div>
              <div *ngIf="this.dataRes.filterType === this.filterType.sixFilters">
                <app-six-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                      (firstValue)="emitFirstValue($event)"
                                      (secondValue)="emitSecondValue($event)"
                                      (thirdValue)="emitThirdValue($event)"
                                      (fourthValue)="emitFourthValue($event)"
                                      (fifthValue)="emitFifthValue($event)"
                                      (sixthValue)="emitFifthValue($event)"
                                      [defaultSelection]="idsFilters"></app-six-single-filter>
              </div>
              <!--YAxis-->
              <div *ngIf="this.dataRes.filterInfoSecond!=null && this.dataRes.filterInfoSecond.yAxis!=null">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-y-axis-filter [data]="this.dataRes.filterInfoSecond" (yAxisValue)="emitYAxisValue($event)" [defaultSelection]="idsFilters"></app-y-axis-filter>
                </div>
              </div>

          </div>
        </div>
      </div>
    </div>

     <!-- Modal open if the 'showModal' variable is true -->
     <div *ngIf="showModal" class="modal" id="creative" #modal tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content creative-modal">
          <div class="modal-body">
            <p class="modal-text">The combination does not fetch any data.</p>
            <button type="button" id="closeButton" (click)="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--PRIMERA LINEA DE CONTENEDORES-->
    <div class="row" [ngClass]="{'p-2': dataRes.hasFilter}">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card" [ngClass]="{'half-border': !dataRes.hasFilter}">
          <div *ngIf="!this.goToCounty">
            <div *ngIf="this.dataStateMap != undefined  && this.dataRes.isCountyLevel !== true">
              <app-dynamic-states-map class="map" [data]="this.dataStateMap" [variable]="variable" [title]="this.dataRes.mapTitle"
              [mapInfo]="this.dataRes.mapInfo" [formatInfo]="this.dataRes.formatInfo" [property]="this.propertyName"
              [labelPopup] ="this.dataRes.labelPopup!" (countyIndex)="getIndex($event)" [consultedIndex]="this.indexConsulted"
              (stateSelected)="getSelectedState($event)" (currentTime)="getCurrentTime($event)" (dataUpdated)="handleDataUpdate($event)"></app-dynamic-states-map>
            </div>

            <div *ngIf="this.dataMap != undefined && this.dataRes.isCountyLevel === true">
              <app-dynamic-counties-map class="map" [data]="this.dataMap" [variable]="variable" [title]="this.dataRes.mapTitle"
              [mapInfo]="this.dataRes.mapInfo" [formatInfo]="this.dataRes.formatInfo" [property]="this.propertyName"
              [labelPopup] ="this.dataRes.labelPopup!"  (dataUpdated)="handleDataUpdate($event)"
               (currentTime)="getCurrentTime($event)"></app-dynamic-counties-map>
            </div>
          </div>

          <div *ngIf="this.goToCounty">
            <div class="principal-content" *ngIf="this.dataMap != undefined">
              <app-dynamic-counties-map [data]="this.dataMap" [variable]="variable" [title]="this.dataRes.mapTitle"
                [formatInfo]="this.dataRes.formatInfo"
                [bounds]="this.countyBounds"
                [mapInfo]="this.dataRes.mapInfo"
                [property]="this.propertyName"  [consultedIndex]="this.indexConsulted"
                [labelPopup] ="this.dataRes.labelPopup!"
                (countySelected)="getSelectedCounty($event)"
                (dataUpdated)="handleDataUpdate($event)" 
                (currentTime)="getCurrentTime($event)"
               ></app-dynamic-counties-map>
            </div>
          </div>

          <div class="container" *ngIf="this.dataRes.scale">
            <div class="d-flex justify-content-end">
                <span style="font-size: 12px"><b>Scale 1:100000</b></span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--SEGUNDA LINEA DE CONTENEDORES-->
    <div class="row p-2">
      <!--PRIMERA COLUMNA-->
      <div class="col-sm-6 col-md-6 col-lg-6">
        <!--PRIMERA GRAFICA-->
        <div class="card" *ngIf="this.ecdfChart != undefined">
          <app-ecdf-chart [data]="this.ecdfChart.data"
                [hasMinMax]="false"
                [datasetPosition]="'top'"
                [datasetAlign]="'center'"
                [xLabel]="this.ecdfChart.xLabel"
                [yLabel]="this.ecdfChart.yLabel"
                [title]="this.ecdfChart.title" [um]="dataRes.data.yAxisName"></app-ecdf-chart>
          <div class="container" *ngIf="this.dataRes.scale">
              <div class="d-flex justify-content-end">
                <span style="font-size: 12px"><b>Scale 1:100000</b></span>
              </div>
          </div>

        </div>
      </div>
      <!--SEGUNDA COLUMNA-->
      <div class="col-sm-6 col-md-6 col-lg-6">
        <!--SEGUNDA GRAFICA-->
        <div class="card" *ngIf="this.histogram != undefined">
          <app-d3-histogram [um]="dataRes.data.yAxisName"
              [data]="this.histogram.data" 
              [title]="this.histogram.title"
              [xLabel]="this.histogram.xLabel" 
              [yLabel]="this.histogram.yLabel" 
              [Id]="'histogram-general'"
            ></app-d3-histogram>

          <div class="container" *ngIf="this.dataRes.scale">
              <div class="d-flex justify-content-end">
                <span style="font-size: 12px"><b>Scale 1:100000</b></span>
              </div>
          </div>

        </div>
      </div>
    </div>
    <!--TERCERA LINEA DE CONTENEDORES-->
    <div class="row p-2">
      <!--PRIMERA COLUMNA-->
      <div class="col-sm-12 col-md-12 col-lg-12">
        <!--PRIMERA GRAFICA-->
        <div class="card" *ngIf="this.XYChart != undefined">
            <app-line [data]="this.XYChart" [barId]="'line-county'" [timeUnit]="this.dataRes.timeUnit" [metadata]="this.dataRes.data.metadata"></app-line>
        </div>
      </div>
    </div>
  </div>
  
  <div class="tab-pane fade" id="trend" role="tabpanel" aria-labelledby="trend-tab" #tab>
    <div class="row" *ngIf="this.dataRes.data != undefined && selectedTab === 'trend'">
      <app-residual-analysis [data]="this.dataRes" [tab]="tab" [selectedTab]="selectedTab" [variable]="this.variable"></app-residual-analysis>
    </div>
  </div>

</div>







