import { Component } from '@angular/core';

@Component({
  selector: 'app-articles-visualizer',
  templateUrl: './articles-visualizer.component.html',
  styleUrls: ['./articles-visualizer.component.css']
})
export class ArticlesVisualizerComponent {

}
