import { Component, OnInit, ElementRef, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
@Component({
  selector: 'app-regression-graph',
  templateUrl: './regression-graph.component.html',
  styleUrls: ['./regression-graph.component.css']
})
export class RegressionGraphComponent implements OnInit {

  @Input() idScenario!: any;
  @Input() selection!: any;
  private data = [
    {
      year: 2016,
      observation: -92.92,
      realization: [-92.92, -92.92, -92.92, -92.92, -92.92, -92.92]
    }
    
  ];
 constructor(private el: ElementRef, private generalservice: GeneralService, private cdr: ChangeDetectorRef) { }

 ngOnChanges(changes: SimpleChanges): void {
  debugger
  if (changes['selection'] || changes['idScenario']) {
    this.fetchDataAndPlot();
    this.cdr.detectChanges(); 
  }
}
ngOnInit(): void {
  this.selection = this.selection.split('¬')[0];
  this.fetchDataAndPlot();
}

fetchDataAndPlot(): void {
  this.selection = this.selection.split('¬')[0];
  this.generalservice.GetLinearModelRegression(this.idScenario, this.selection).subscribe({
    next: (response: any) => {
      // Transformar los datos del endpoint al formato deseado
      const transformedData = response.x.map((year: number, index: number) => {
        return {
          year: year,  // Año correspondiente
          observation: response.yObservation[index],  // Observación correspondiente
          realization: response.realizations[index].realization  // Realización correspondiente
        };
      });

      // Encontrar el valor más bajo y más alto entre observaciones y realizaciones
      const allValues = [
        ...response.yObservation,  // Agregar todas las observaciones
        ...response.realizations.flatMap((r: any) => r.realization)  // Aplanar las realizaciones y agregarlas
      ];

      const minValue = Math.min(...allValues);
      const maxValue = Math.max(...allValues);
debugger
      // Asignar los datos transformados
      this.data = transformedData;

      // Llamar a la función para graficar, pasando los valores mínimo y máximo para el dominio
      this.plotGraph(minValue, maxValue);
    },
    error: (err) => {
      console.error('Error al obtener los datos del servicio', err);
    }
  });
}


plotGraph(minValue: number, maxValue: number): void {
  d3.select(this.el.nativeElement.querySelector('#chart-regression')).selectAll("*").remove();

  
  // Extraer datos
  const years = this.data.map(d => d.year);
  const observations = this.data.map(d => d.observation);

  // Filtrar datos válidos
  const validYears = years.filter(y => y !== undefined);
  const validObservations = observations.filter(o => o !== undefined);

  const margin = { top: 20, right: 30, bottom: 30, left: 40 };
  const width = 960 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;

  // Escalas
  const xScale = d3.scaleLinear()
    .domain([d3.min(validYears) as number, d3.max(validYears) as number])
    .range([0, width]);

  // Crear el yScale dinámicamente usando los valores mínimos y máximos calculados
  const yScale = d3.scaleLinear()
    .domain([minValue, maxValue])
    .range([height, 0]);

  // Crear el SVG
  const svg = d3.select(this.el.nativeElement.querySelector('#chart-regression'))
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

    // Agregar el título
  svg.append('text')
  .attr('x', width / 2)  // Centrar el título horizontalmente
  .attr('y', -margin.top / 2)  // Posicionar el título justo encima del gráfico
  .attr('text-anchor', 'middle')
  .style('font-size', '16px')
  .style('font-weight', 'bold')
  .text(this.selection + ' Probabilistic Calibration of Linear Model Regression');  // Aquí puedes personalizar el texto del título

  // Eje X
  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(xScale).tickFormat(d3.format('d'))); // Para mostrar los años enteros

  // Eje Y
  svg.append('g')
    .call(d3.axisLeft(yScale));

  // Dibujar realizaciones
  const numRealizations = this.data[0].realization.length; // Número de realizaciones por año

  for (let i = 0; i < numRealizations; i++) {
    const realizationData = this.data.map(d => ({ year: d.year, value: d.realization[i] }));

    svg.append('path')
      .datum(realizationData)
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.3)
      .attr('d', d3.line<{ year: number, value: number }>()
        .x(d => xScale(d.year))
        .y(d => yScale(d.value))
      );
  }

  // Dibujar la línea de observaciones (línea roja)
  const observationData = this.data.map(d => ({ year: d.year, value: d.observation }));

  svg.append('path')
    .datum(observationData)
    .attr('fill', 'none')
    .attr('stroke', 'red')
    .attr('stroke-width', 3)
    .attr('d', d3.line<{ year: number, value: number }>()
      .x(d => xScale(d.year))
      .y(d => yScale(d.value))
    );

  // Dibujar puntos en la línea de observaciones
  svg.selectAll('.dot')
    .data(observationData)
    .enter().append('circle')
    .attr('cx', d => xScale(d.year))
    .attr('cy', d => yScale(d.value))
    .attr('r', 4)
    .attr('fill', 'red');
}
}