L.Control.TimeDimensionHeatmap = L.Control.TimeDimension?.extend({
    options: {
        styleNS: 'leaflet-control-timecontrol',
        position: 'bottomleft',
        title: 'Time Control',
        backwardButton: true,
        forwardButton: true,
        playButton: false,
        playReverseButton: false,
        loopButton: false,
        displayDate: true,
        timeSlider: true,
        timeSliderDragUpdate: false,
        limitSliders: false,
        limitMinimumRange: 5,
        speedSlider: true,
        minSpeed: 0.1,
        maxSpeed: 10,
        speedStep: 0.1,
        timeSteps: 1,
        autoPlay: false,
        playerOptions: {
            transitionTime: 1000
        },
        timeZones: ['UTC', 'Local'],
        region: "en-US",
        format: {  year: 'numeric', month: '2-digit' },
        addDays: 0
    },
    initialize: function(options) {
        L.setOptions(options);
        L.Control.prototype.initialize.call(this, options);
        this._timeZoneIndex = 0;
        this._timeDimension = this.options.timeDimension || null;
    },
    _getDisplayDateFormat: function(date){
        date.setDate(date.getDate() + this.options.addDays);
        return date.toLocaleDateString(this.options.region, this.options.format);
        }
    });