import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-seasonal-model',
  templateUrl: './seasonal-model.component.html',
  styleUrls: ['./seasonal-model.component.css']
})
export class SeasonalModelComponent {
  @Output() response = new EventEmitter<any>();

  public form!: FormGroup;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      stl_trend_smoother: ['11', [Validators.required, this.customValidator]],
      stl_seasonal_smoother: ['7', [Validators.required, this.seasonalValidator]],
      stl_period: ['7', Validators.required],
    });
  }

  customValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;
    
    if (value === null || value === undefined || value === '') return null;

    const isNumber = !isNaN(value);
    const isOdd = value % 2 !== 0;
    const isPositive = value >= 0;

    if (isNumber && isOdd && isPositive) {
      return null; // Es válido
    } else {
      return { invalidInput: true }; // Es inválido
    }
  }

  seasonalValidator(control: FormControl): { [key: string]: any } | null{
    const seasonalSmoother = control.value;
  
    if (isNaN(seasonalSmoother) || seasonalSmoother % 2 === 0 || seasonalSmoother <= 0) {
      return { 'invalidseasonalSmoother': true };
    }
  
    return null;
  }
  public onSubmit():void{
    if(this.form.invalid) return;
    this.response.emit(this.form.value);
  }

}