import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { FilterType, DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-alfalfa',
  templateUrl: './alfalfa.component.html',
  styleUrls: ['./alfalfa.component.css']
})
export class AlfalfaComponent {
  public data!: any;
  public dataMap: any = {};
  public formatInfo: any = {};
  public filterInfo: any = {};
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  public label: string = "Value";

  constructor( private transboundaryWaterService: TransboundaryWaterServiceService,
    private homeStore: HomeStore, 
    private spinner: NgxSpinnerService, ) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    }  
    if (this.isUSA) {
      this.getDataUs();
    } 
    if (this.isMx) {
      this.dataMap = {
        center: MapCenter.MEXICO,
        scale: ['fefbce', 'FFF766', 'ec0909'],
        json: MapJson.MEXICO,
        zoom: MapZoom.MEXICO,
        dateFormat: 'YYYY-mm',
        borderColor: '#000000'
      };
      this.formatInfo = {
        region: "es-MX",
        format: {  year: 'numeric' },
        addDays: 1
      };
      this.filterInfo ={
        firstFilter: {
          name: "Value Type", values: [
            {id: 0, value: 'Sembrada - Sum'},
            {id: 1, value: 'Cosechada - Sum'}, 
            {id: 2, value: 'Siniestrada - Sum'}, 
            {id: 3, value: 'Volumen Producción - Sum'},
            {id: 4, value: 'Rendimiento - AVG'}, 
            {id: 5, value: 'Precio - AVG'}, 
            {id: 6, value: 'Valor Producción - Sum'}
          ]
        }
      }

      this.getDataMx();
    }
    if(this.isTri){
      this.getDataTri();
    }

  }


  getDataMx() {
    this.transboundaryWaterService.getCroapAlfalfaStateMx()
    .subscribe(
      {
        next: (res) => {
          this.data ={
            data: res,
            title: 'Crop State',
            description: 'info',
            mapTitle: 'Crop State',
            mapInfo: this.dataMap,
            ECDF: {title: 'ECDF Crop State ',
                     yLabel: 'Cumulative Relative Frequency', xLabel: 'Crop State'
                    },
              histogram: {
                title: 'Histogram Crop State ',
                yLabel: 'Frecuency', xLabel: 'Crop State'
              },
            formatInfo: this.formatInfo,
            hasFilter: true,
            filterType:  FilterType.oneFilter,
            filterInfo: this.filterInfo,
            labelPopup: this.label,
          } as DynamicTemplateModel;
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
    
  }
  
  

  getDataUs() {
    this.spinner.hide();
  }

  getDataCN() {
    this.spinner.hide();
  }

  getDataTri(){
    this.spinner.hide();
  }


  //Reloads
  reload($event:any){
    let datoComoCadena: string = $event.toString();
    let longitud: number = datoComoCadena.length;
    let ultimoCaracter: string = datoComoCadena.charAt(longitud - 1);
    let restoCadena: string = datoComoCadena.slice(0, longitud - 1);
    this.label = restoCadena.toString();
    this.getDataMx();
  }
}
