import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
declare const L: any;
import 'leaflet';
import * as chroma  from "chroma-js";

@Component({
  selector: 'app-static-states-map',
  templateUrl: './static-states-map.component.html',
  styleUrls: ['./static-states-map.component.css']
})
export class StaticStatesMapComponent implements OnDestroy, AfterViewInit {
  @Input() labelPopup: string = "";
  @Input() data!: any;
  @Input() title: string = "";
  @Input() mapInfo!: any;
  
  @Input() property: string = "values";
  @Output() stateSelected = new EventEmitter<any>();
  @Output() currentTime = new EventEmitter<number>();
  @ViewChild('map', {read: ElementRef}) private mapEl!: ElementRef<HTMLElement>;
  private map: any;
  private states: any;
  private counties:any;
  private statesEx:any;
  private maxValue: any;
  private geojson!: any;
  private info !: any;
  promiseGeoJson!: Promise<any>;
  public values: any[] = [];
  constructor(private http: HttpClient) { }
  ngAfterViewInit(): void {
    if (this.map !== undefined) {
      this.map.remove();
    }
    this.maxValue = this.data.maxState;
    this.initMap();
    this.getGeoJsonData();
    Promise.allSettled([this.promiseGeoJson]).then(value => {
      this.onReload();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.map !== undefined) {
      this.map.remove();
    }

      this.initMap();
      this.getGeoJsonData();
      this.onReload();
    
  
  }

  ngOnDestroy(): void {

  }
  private initMap(): void {

    //this.map = L.map(this.mapEl.nativeElement, {
     
      this.map = L.map("mapState", {
      center: this.mapInfo.center,
      zoom: this.mapInfo.zoom,
      zoomControl: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 12,
      minZoom: 1,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topright',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: '<img class="icon-svg" src= "../../assets/images/house-solid.svg" alt="home-icon">',
        zoomHomeTitle: 'Zoom home'
      },
      onAdd: function (map: any) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
        this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },
      onRemove: function (map: any) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e: any) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e: any) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: (e: any) => {
        this.map.setView(this.mapInfo.center, this.mapInfo.zoom);
      },

      _createButton: function (html: any, title: any, className: any, container: any, fn: any) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled';

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      }
    });
    var zoomHome2 = new L.Control.zoomHome();
    zoomHome2.addTo(this.map);
  }
  getGeoJsonData() {
    // Cargar el archivo de estados
    this.promiseGeoJson = new Promise((resolve, reject) => {
      this.http.get(`/assets/geojson/${this.mapInfo.json}_states.json`).subscribe((states: any) => {
        this.states=states;
        this.statesEx = states.features.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature.properties.name;  // Mapea el id del estado al nombre
          return acc;
        }, {});
        resolve(this.states);
      }, error => {
        reject(error);
      });
    });
  
    // Cargar el archivo de condados
    this.promiseGeoJson = new Promise((resolve, reject) => {
      this.http.get(`/assets/geojson/${this.mapInfo.json}_counties.json`).subscribe((counties: any) => {
        this.counties = counties.features.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature.properties.NAME;  // Mapea el id del condado al nombre
          return acc;
        }, {});
        resolve(this.counties);
      }, error => {
        reject(error);
      });
    });
  }
  
  onReload() {
    this.createNewItems();
    this.initStatesLayer();
  }
  private createNewItems() {
    this.data.data_States.forEach((state: any) => {
      var itemIndex = this.states.features.findIndex((item: any) => item.id == state.name);
      if (itemIndex == -1) return;
      const copyItem = JSON.parse(JSON.stringify(this.states.features.at(itemIndex))); //deep copy for the item
      this.states.features.splice(itemIndex, 1); //remove original item.
      copyItem.properties.times = [];
      copyItem.properties.values = [];
      copyItem.properties.times.push(... this.data.dates);
      copyItem.properties.valuesString = state[this.property].map((val: number) => { return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})});
      copyItem.properties.values.push(...state[this.property]);copyItem.properties.values.push(...state.values);
      copyItem.properties.id = copyItem.id;
      this.states.features.push(copyItem);

    });
    for (var i = 0; i < this.states.features.length; i++) {
      if (this.states.features[i].properties.values === undefined) {
        this.states.features.splice(i, 1); //remove original item.
        i--;
      }
    }
    let min = Math.min( ... this.data.data_States.map((item: any) => { return Math.min(... item[this.property].filter((x: any) => x !== 0))}) );
    let max = Math.max( ... this.data.data_States.map((item: any) => { return Math.max(... item[this.property].filter((x: any) => x !== 0))}) );

    min = Math.round(min);
    max = Math.round(max);

    while(max % 10 !== 0){
      max++;
    }

    while(min % 10 !== 0){
      min--;
    }

    const length = this.data.data_States.at(0)[this.property].length;
    this.values=[];
    for(var i = 0; i < length; i++){
      const values_ = [...new Set(this.data.data_States.map((item: any) => { return item[this.property].at(i) }))].sort((a:any, b: any) => {
        return a - b;
      });
      values_.unshift(min);
      if(values_.at(-1) !== max){
        values_.pop();
        values_.push(max);
      }
      this.values.push(values_);
    }
  }
  private initStatesLayer() {
    if (this.geojson) {
      this.map.removeLayer(this.geojson);
   
  }
    this.geojson = L.geoJSON(this.states, { style: this.style, onEachFeature: this.onEachFeature });


    this.geojson.addTo(this.map);



    this.info = L.control({ position: 'topleft' });

    this.info.onAdd = function (map: any) {
      this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    this.info.update = function (props: any, map: any, mouseout: boolean) {
      let text = '';
      if (props === undefined) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return
      };
      if (mouseout) {
        text += 'Hover over a state';
        this._div.innerHTML = text;
        return;
      }
      if (props.values === undefined) {
        text += `<app-not-found></app-not-found>`;
      } else {
        text += '<b>' + props.name + '</b><br />';
        text += '<b>' + props.valuesString[0] + '</b><br />';
      }
      this._div.innerHTML = text;
    };
    if (!this.map.hasLayer(this.info)) {
      this.info.addTo(this.map);
    }

    var legend = L.control({position: 'bottomright'});

    legend.onAdd =  (map:any) =>{

        var div = L.DomUtil.create('div', 'info legend')
        const values_ = this.values[0].filter((x: any) => x !== 0).reverse();
        // loop through our density intervals and generate a label with a colored square for each interval
        for (var i = 0; i < values_.length; i++) {
            if(i == 0 || i == (values_.length-1)){
              div.innerHTML +=
              '<span style="height: 5px;"><i style="background:' + this.getColor(values_[i]) + ';height: 5px;"></i> ' +
              values_[i] +'</span>';
            }else{
              div.innerHTML +=
              '<span style="height: 5px;"><i style="background:' + this.getColor(values_[i]) + ';height: 5px;"></i> '+'</span>';
            }

        }
        if (values_.length == 2) {
          const spans = div.querySelectorAll('span');
          spans[0].style.marginBottom = '5px';
        }

       return div; 
    };

    if (!this.map.hasLayer(legend)) {
    legend.addTo(this.map);
    }


  }

  private style = (feature: any) => {
    let color = 'rgba(255,255,255,1.0)';
    if (feature!.properties!.values !== undefined) {
      color = this.getColor(feature!.properties!.values[0]);
    }
    return {
      weight: 1,
      opacity: 1,
      color: this.mapInfo.borderColor,
      dashArray: '',
      fillOpacity: 1,
      fillColor: color
    };
  }
  private getColor = (d: any) => {
    var mapScale = chroma.scale(this.mapInfo.scale).domain([this.maxValue, 0]);
    return mapScale(d).toString();
  }
  private zoomToFeature = (e: any) => {
    if (e.target.feature.properties.values === undefined) return;
    this.map.fitBounds(e.target.getBounds());
  }
  private highLightFeature = (e: any) => {
    var layer = e.target;

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
    this.info.update(e.target.feature.properties, this.map, false);
    layer.bringToFront();
  }
  private resetFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 1,
      color: this.mapInfo.borderColor,
      fillOpacity: 1,
    });
    this.info.update(e.target.feature.properties, this.map, true);
    layer.bringToFront();
  }
  private onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highLightFeature,
      mouseout: this.resetFeature,
      click: this.zoomToFeature
    });
    const total = feature.properties.valuesString === undefined ? 'N/A' : feature.properties.valuesString[0];
    layer.bindTooltip(`<p style="font-weight: 700; text-align: center;">${feature.properties.name}</p> ${this.labelPopup}:  ${total}`).openTooltip();
    layer.on('click', (e: any) => {
      this.getState(feature, e);
    });

  }
  public getState(feature: any, e: any) {
    if (feature.properties.values === undefined) return;
    const model = { name: feature.properties.name, id: feature.properties.id, bounds: e.target.getBounds() };
    this.stateSelected.emit(model);
  }

  exportToExcel() {
    const MAX_ROWS_PER_EXPORT = 50000;
    const stateData = this.data.data_States.map((state: { name: string | number; values: any[]; }) => ({
      State: this.statesEx[state.name] || state.name,  
      Value: state.values[0] 
    }));
    const countyData = this.data.data.map((county: { name: string | number; state: string | number; values: any[]; }) => ({
      County: this.counties[county.name] || county.name,  
      State: this.statesEx[county.state] || county.state, 
      Value: county.values[0] 
    }));
    const splitDataIntoChunks = (states: any[], counties: any[], chunkSize: number) => {
      const chunks = [];
      let remainingStates = states;
      let remainingCounties = counties;
  
      while (remainingStates.length > 0 || remainingCounties.length > 0) {
        const chunkStates = remainingStates.slice(0, chunkSize);
        let chunkCounties = [];
        
        if (chunkStates.length < chunkSize) {
          const remainingSpace = chunkSize - chunkStates.length;
          chunkCounties = remainingCounties.slice(0, remainingSpace);
          remainingCounties = remainingCounties.slice(remainingSpace);
        }
  
        chunks.push({ chunkStates, chunkCounties });
        remainingStates = remainingStates.slice(chunkSize);
      }
  
      return chunks;
    };
    const dataChunks = splitDataIntoChunks(stateData, countyData, MAX_ROWS_PER_EXPORT);
    dataChunks.forEach((chunk, index) => {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      if (chunk.chunkStates.length > 0) {
        const wsStates: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk.chunkStates);
        XLSX.utils.book_append_sheet(wb, wsStates, 'Estates');
      }
      if (chunk.chunkCounties.length > 0) {
        const wsCounties: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk.chunkCounties);
        XLSX.utils.book_append_sheet(wb, wsCounties, 'Counties');
      }
      const fileName = dataChunks.length === 1
      ? `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_${Date()}.xlsx`
      : `County_map_data${this.data.metadata.dashboard}_${this.data.metadata.net}_${this.data.metadata.node}_Part${index + 1}_${Date()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    });
  }
  
  

}
