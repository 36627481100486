<div class="filters-list">
  <div *ngIf="step > 0" class="fieldset">
    <label class="label">Date range</label>
    <div class="tag tag--active">
      <span>{{startDate | date : 'yyyy-MM-dd' : 'GMT'}} - {{endDate | date : 'yyyy-MM-dd' : 'GMT'}}</span>
      <span class="remove-button" *ngIf="step === 1" (click)="deleteDate()">✗</span>
    </div>
  </div>
  <div *ngIf="step > 1" class="fieldset">
    <label class="label">Content</label>
    <div class="tag tag--active">
      <span>{{content?.contentLabel}}</span>
      <span class="remove-button" *ngIf="step === 2" (click)="deleteContent()">✗</span>
    </div>
  </div>
  <div *ngIf="step > 2" class="fieldset">
    <label class="label">Topic</label>
    <div class="tag tag--active">
      <span>{{topic?.topicName}}</span>
      <span class="remove-button" *ngIf="step === 3" (click)="deleteTopic()">✗</span>
    </div>
  </div>
</div>

<ng-container *ngIf="step === 0">
  <div class="fieldset">
    <label class="label">Select a date range</label>
    <div>
      <div style="display: flex; align-items: center">
        <input
          placeholder="Date to start"
          class="form-control"
          type="date"
          id="startDate"
          [min]="minDate"
          [max]="maxDate"
          [value]="startDate | date : 'yyyy-MM-dd' : 'GMT'"
          (input)="startDate = parseDate($event.target)"
        />
        <input
          placeholder="Date to end"
          class="form-control"
          type="date"
          id="endDate"
          style="margin-left: 1rem"
          [min]="minDate"
          [max]="maxDate"
          [value]="endDate | date : 'yyyy-MM-dd' : 'GMT'"
          (input)="endDate = parseDate($event.target)"
        />
        <button
          class="btn search"
          [disabled]="!endDate || !startDate || startDate > endDate"
          (click)="selectDate()"
        >
          Search
        </button>
      </div>
      <div>
        <label class="error" *ngIf="startDate > endDate"
        >Start can't be greater than End date</label
        >
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="step === 1">
  <div class="fieldset">
    <label class="label">Select content</label>
    <div style="display: flex; gap: 16px; flex-wrap: wrap">
      <ng-container *ngFor="let content of contents">
        <div class="tag" (click)="selectContent(content)">
          <span>{{content.contentLabel}} ({{content.eventCount}})</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="step === 2">
  <div class="fieldset">
    <label class="label">Select topic</label>
    <div style="display: flex; gap: 16px; flex-wrap: wrap">
      <ng-container *ngFor="let topic of topics">
        <div class="tag" (click)="selectTopic(topic)">
          <span>{{topic.topicName}} ({{topic.eventCount}})</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
