import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { AutomobileService } from 'src/app/core/services/automobile-engine/automobile.service';
import { SystemService } from 'src/app/core/services/system/system.service';
import { HomeStore } from 'src/app/core/stores/home-store';

enum VariableType {
  Products_Recall_US_based_company = 41,
  Product_Recalls_United_States = 73,
}

@Component({
  selector: 'app-product-recall',
  templateUrl: './product-recall.component.html',
  styleUrls: ['./product-recall.component.css']
})
export class ProductRecallComponent {
  public data: any;

  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  public varType = VariableType;
  public selectedView: number = 41;
  private filterInfo!: any;


  constructor(private systemService: SystemService, 
    private automobileService: AutomobileService,
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.filterInfo = {
        firstFilter: {name: "variable Type", values: [
          {id: 0, itemId: 41, value: 'Products Recall from a US-based company'},
          {id: 1, itemId: 73, value: 'Product Recalls in the United States'}
        ]}
      }
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }

  }
  getDataCN() {
    this.spinner.hide();
  }
  //US Region
  getDataUs() {

    const dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'],
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#000000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric' },
      addDays: 1
    }
    if(this.varType.Products_Recall_US_based_company == this.selectedView){
      this.systemService.getProductRecall()
        .subscribe(
          {
            next: (res) => {
              this.data ={
                data: res,

                mapTitle: 'Product recall by State',
                mapInfo: dataMap,
                ECDF: {
                  title: `ECDF Product recall from `,
                  yLabel: 'Cumulative Relative Frequency', xLabel: 'Product recall'
                },
                histogram: {
                  title: `Histogram Product recall from `,
                  yLabel: 'Frequency', xLabel: 'Product recall'
                },
                timeUnit: 'year',
                formatInfo: formatInfo,
                labelPopup: 'Count',
                hasStudyButton: true,
                filterVariableInfo: this.filterInfo,
                selectedInfo: this.selectedView,
              } as DynamicTemplateModel;

            },
            error: (e) => { console.error(e); this.spinner.hide(); }
          }
        );
    } else if(this.varType.Product_Recalls_United_States == this.selectedView){
      this.automobileService.getProductRecallsUs()
      .subscribe(
        {
          next: (res) => {
            this.data ={
              plusMax: 10,
              data: res,
              histogram: {
                title: `Histogram Product Recalls in the United States`,
                yLabel: 'Frequency', xLabel: 'Value'
              },
              xyChart: {title: 'Product Recalls in the United States' ,
              xLabel: 'Year', yLabel: 'Product recalls'},
              timeUnit: 'year',
              hasStudyButton: true,
                filterInfo: this.filterInfo,
                selectedInfo: this.selectedView,
              isDate: true,
              // dataikuModel: {
              //   table: "VAR_083_TIT_CAN_OEC",
              //   column_to_work: "Imports",
              //   date_processed: "yes",
              //   frequency: "yearly",
              //   date_: [
              //       "Year"
              //   ],
  
              //   totalRows: res.totalRows,
              //   nlags_: 26,
              //   algorithm_: '4',
              // }
            };
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
    }
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataTri(){
    this.spinner.hide();
  }

  getValue($event:any){
    this.spinner.show();
    this.data = undefined;
    this.selectedView = $event;
    this.getDataUs();
  }
}
