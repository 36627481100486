import { GraphItemModel, GraphLinkModel } from "../models/graph.model";


/* Categories */
export const PowerGraphCategory: any[] = [
/*{ id: 0,name: 'Anthropogenic', itemStyle: { color: '#006483' } }, 
{ id: 1,name: 'Natural', itemStyle: { color: '#438bca' } },
{ id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#4966b0' } },
{ id: 3,name: 'Raw Materials & Sources', itemStyle: { color: '#c17a2f' } },
{ id: 4,name: 'Suppliers', itemStyle: { color: '#2b0171' } },
{ id: 5,name: 'Logistics', itemStyle: { color: '#500101' } },
{ id: 6,name: 'Manufacturers / Production', itemStyle: { color: '#707070' } },
{ id: 7,name: 'Retailers', itemStyle: { color: '#5a6237' } },
{ id: 8,name: 'Customers', itemStyle: { color: '#b0b1b1' } },
{ id: 9,name: 'Social', itemStyle: { color: '#ad9d68' } },
{ id: 10,name: 'Economic', itemStyle: { color: '#ad9d68' } },
{ id: 11,name: 'Environmental', itemStyle: { color: '#ad9d68' } },*/
{ id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
{ id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
{ id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
{ id: 3,name: 'Raw Materials / Sources', itemStyle: { color: '#ff9900'  } },
{ id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
{ id: 5,name: 'Logistic / Distribution ad2', itemStyle: { color: '#500101' } },
{ id: 6,name: 'Production das2', itemStyle: { color: '#707070' } },
{ id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
{ id: 8,name: 'Logistic qwe2', itemStyle: { color: '#9d1010' } },
{ id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
{ id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
{ id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
{ id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
{ id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
{ id: 14,name: 'Logistic', itemStyle: { color: '#993365' } },
{ id: 15, name: 'Production', itemStyle: { color: '#a0a0a0' } },
{ id: 16,name: 'Wholesalers / Retailers', itemStyle: { color: '#ccffcc' } },
];

export const PowerGraphCategoryLink: any[] = [
  { name: 'Threats', links: [ PowerGraphCategory[0], PowerGraphCategory[1], PowerGraphCategory[2] ]},
  { name: 'Systems', links: [ PowerGraphCategory[3], PowerGraphCategory[4], PowerGraphCategory[5],
        PowerGraphCategory[6], PowerGraphCategory[7],  PowerGraphCategory[8]
    ]},
  { name: 'State of Risk', links:  [PowerGraphCategory[9], PowerGraphCategory[10], PowerGraphCategory[11] ]}
]
/*End categories */


/*NODES*/
export const PowerGraphItems: GraphItemModel[] = [

    //Anthropogenic
  { fullname: 'Geopolitical Tension', name: 'Geopolitical\nTension', x: -25, y: -25, category: 0, isActive: false },
  { fullname: 'Natural Gas Price', name: 'Natural\nGas Price', x: -25, y: -20, category: 0, isActive: false },
  { fullname: 'Steaming Coal Price', name: 'Steaming\nCoal Price', x: -25, y: -15, category: 0, isActive: false },
  { fullname: 'Uranium Price', name: 'Uranium\nPrice', x: -25, y: -10, category: 0, isActive: false },
  { fullname: 'Semiconductor & Electronic Components Price', name: 'Semiconductor\n& Electronic\nComponents Price', x: -25, y: -3, category: 0, isActive: false },
  { fullname: 'Misinformation', name: 'Misinformation', x: -25, y: 2, category: 0, isActive: false },

  { fullname: 'Tariffs/Sanctions', name: 'Tariffs /\nSanctions', x: -20, y: -28, category: 0, isActive: false },
  { fullname: 'Aging Infrastructure', name: 'Aging\nInfrastructure', x: -20, y: -23, category: 0, isActive: false },
  { fullname: 'COVID-19 Pandemic', name: 'COVID-19\nPandemic', x: -20, y: -18, category: 0, isActive: false },
  { fullname: 'Environmental Regulations', name: 'Environmental\nRegulations', x: -20, y: -13, category: 0, isActive: false },
  { fullname: 'GGovernment Subsidies / Incentives', name: 'Government\nSubsidies /\nIncentives', x: -20, y: -8, category: 0, isActive: false },
  { fullname: 'Public Health', name: 'Public\nHealth', x: -18.5, y: -3, category: 0, isActive: false },

  { fullname: 'Physical Attacks', name: 'Physical\nAttacks', x: -15, y: -25, category: 0, isActive: false },
  { fullname: 'Terrorism / Criminal Activity', name: 'Terrorism /\nCriminal\nActivity', x: -15, y: -18, category: 0, isActive: false },
  { fullname: 'Labor Shortage (Logistics)', name: 'Labor\nShortage\n(Logistics)', x: -15, y: -11, category: 0, isActive: false },
  { fullname: 'Air Pollution', name: 'Air\nPollution', x: -15, y: -7, category: 0, isActive: false },

  { fullname: 'Cyberattacks', name: 'Cyberattacks', x: -10, y: -28, category: 0, isActive: false },
  { fullname: 'Energy Demand', name: 'Energy\nDemand', x: -10, y: -23, category: 0, isActive: true },
  { fullname: 'Labor Shortage (RMS)', name: 'Labor\nShortage\n(RMS)', x: -10, y: -18, category: 0, isActive: false },
  { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage\n(S)', x: -10, y: -13, category: 0, isActive: false },
  { fullname: 'Labor Shortage (MP)', name: 'Labor\nShortage\n(MP)', x: -10, y: -8, category: 0, isActive: false },
  { fullname: 'Labor Shortage (R)', name: 'Labor\nShortage\n(R)', x: -10, y: -3, category: 0, isActive: false },
  { fullname: 'Crop Production', name: 'Crop\nProduction', x: -10, y: 2, category: 0, isActive: false },

//natural
  { fullname: 'Rainfall', name: 'Rainfall', x: -30, y: 10, category: 1, isActive: false },
  { fullname: 'Snowfall', name: 'Snowfall', x: -30, y: 15, category: 1, isActive: false },

  { fullname: 'Drought', name: 'Drought', x: -25, y: 10, category: 1, isActive: false },
  { fullname: 'Animal Interference', name: 'Animal\nInterference', x: -25, y: 15, category: 1, isActive: false },

  { fullname: 'Surface Air Temperature', name: 'Surface\nAir\nTemperature', x: -20, y: 10, category: 1, isActive: false },
  { fullname: 'Floods', name: 'Floods', x: -20, y: 15, category: 1, isActive: false },

  { fullname: 'Earthquake', name: 'Earthquake', x: -15, y: 13, category: 1, isActive: false },

  { fullname: 'Hurricanes', name: 'Hurricanes', x: -10, y: 10, category: 1, isActive: false },
  { fullname: 'Wildfires', name: 'Wildfires', x: -10, y: 15, category: 1, isActive: false },

  //raw material
  { fullname: 'Hydrocarbon Reservoirs', name: 'Hydrocarbon\nReservoirs', x: -4, y: -28, category: 3, isActive: false },
  { fullname: 'Coal Mines', name: 'Coal\nMines', x: -4, y: -23, category: 3, isActive: false },
  { fullname: 'Coal', name: 'Coal', x: -4, y: -18, category: 3, isActive: false },
  { fullname: 'Geothermal Wells', name: 'Geothermal\nWells', x: -4, y: -13, category: 3, isActive: false },
  { fullname: 'Geothermal Reservoirs', name: 'Geothermal\nReservoirs', x: -4, y: -8, category: 3, isActive: false },
  { fullname: 'Biomass Waste', name: 'Biomass\nWaste', x: -4, y: -3, category: 3, isActive: false },
  { fullname: 'Wind', name: 'Wind', x: -4, y: 2, category: 3, isActive: false },
  { fullname: 'Critical Minerals', name: 'Critical\nMinerals', x: -4, y: 7, category: 3, isActive: false },

  { fullname: 'Gas Wells', name: 'Gas\nWells', x: 0, y: -25, category: 3, isActive: false },
  { fullname: 'Oil Wells', name: 'Oil\nWells', x: 0, y: -20, category: 3, isActive: false },
  { fullname: 'Uranium U-235', name: 'Uranium\nU-235', x: 0, y: -15, category: 3, isActive: false },
  { fullname: 'Crop Feedstock', name: 'Crop\nFeedstock', x: 0, y: -10, category: 3, isActive: false },
  { fullname: 'Wood', name: 'Wood', x: 0, y: -5, category: 3, isActive: false },
  { fullname: 'Solar', name: 'Solar', x: 0, y: 0, category: 3, isActive: false },

  //suppliers
  { fullname: 'Natural Gas Processing Plants', name: 'Natural Gas\nProcessing\nPlants', x: 6, y: -28, category: 4, isActive: false },
  { fullname: 'Liquifaction', name: 'Liquifaction', x: 6, y: -23, category: 4, isActive: false },
  { fullname: 'Geothermal Wells ', name: 'Geothermal\nWells ', x: 6, y: -18, category: 4, isActive: false },
  { fullname: 'Waste Transfer Stations', name: 'Waste\nTransfer\nStations', x: 6, y: -13, category: 4, isActive: false },
  { fullname: 'Anaerobic Digesters', name: 'Anaerobic\nDigesters', x: 6, y: -8, category: 4, isActive: false },
  { fullname: 'Ethanol Plants', name: 'Ethanol\nPlants', x: 6, y: -3, category: 4, isActive: false },
  { fullname: 'Wind Turbines', name: 'Wind\nTurbines', x: 11, y: 2, category: 4, isActive: true },
  { fullname: 'Solar PV Panels', name: 'Solar PV\nPanels', x: 6, y: 4, category: 4, isActive: false },

  { fullname: 'Crude Oil Refineries', name: 'Crude Oil\nRefineries', x: 11, y: -25, category: 4, isActive: false },
  { fullname: 'Regasification', name: 'Regasification', x: 11, y: -20, category: 4, isActive: false },
  { fullname: 'Coal Processors', name: 'Coal\nProcessors', x: 11, y: -15, category: 4, isActive: false },
  { fullname: 'Wood Products & Paper Manufacturers Waste', name: 'Wood Products &\nPaper\nManufacturers\nWaste', x: 11, y: -9, category: 4, isActive: false },
  { fullname: 'Electrolyzers', name: 'Electrolyzers', x: 11, y: -5, category: 4, isActive: false },

  //Manufacturers
  { fullname: 'Natural Gas Power Plants', name: 'Natural\nGas Power\nPlants', x: 18, y: -28, category: 6, isActive: false },
  { fullname: 'Terminal', name: 'Terminal', x: 18, y: -23, category: 6, isActive: false },
  { fullname: 'Coal Power Plants', name: 'Coal\nPower\nPlants', x: 18, y: -18, category: 6, isActive: false },
  { fullname: 'Geothermal Power Plants', name: 'Geothermal\nPower\nPlants', x: 18, y: -13, category: 6, isActive: false },
  { fullname: 'Hydroelectric Power Plants', name: 'Hydroelectric\nPower\nPlants', x: 18, y: -8, category: 6, isActive: false },
  { fullname: 'Industrial\nSectors Wastewater Treatment Plants', name: 'Industrial\nSectors\nWastewater\nTreatment\nPlants', x: 18, y: -2, category: 6, isActive: false },
  { fullname: 'Landfills', name: 'Landfills', x: 18, y: 2, category: 6, isActive: false },
  { fullname: 'Batteries', name: 'Batteries', x: 18, y: 7, category: 6, isActive: false },

  { fullname: 'Oil Power Plants', name: 'Oil Power\nPlants', x: 23, y: -25, category: 6, isActive: false },
  { fullname: 'Natural Gas Refineries', name: 'Natural\nGas\nRefineries', x: 23, y: -20, category: 6, isActive: false },
  { fullname: 'Fractionator', name: 'Fractionator', x: 23, y: -15, category: 6, isActive: false },
  { fullname: 'Fuel Cell Power Plants', name: 'Fuel Cell\nPower\nPlants', x: 23, y: -10, category: 6, isActive: false },
  { fullname: 'Nuclear Power Plants', name: 'Nuclear\nPower\nPlants', x: 23, y: -5, category: 6, isActive: false },
  { fullname: 'Waste-to-Energy Planta', name: 'Waste-to-\nEnergy\nPlants', x: 23, y: 0, category: 6, isActive: false },
  { fullname: 'Wind Power Plants', name: 'Wind\nPower\nPlants', x: 23, y: 5, category: 6, isActive: false },
  { fullname: 'Solar PV Power Plants', name: 'Solar PV\nPower\nPlants', x: 23, y: 10, category: 6, isActive: false },

  //retailers
  { fullname: 'Gas Stations', name: 'Gas\nStations', x: 30, y: -20, category: 7, isActive: false },
   
  //customers
  { fullname: 'Residential Sectors', name: 'Residential\nSectors', x: 36, y: -23, category: 8, isActive: false },
  { fullname: 'Industrial Sectors', name: 'Industrial\nSectors', x: 36, y: -18, category: 8, isActive: false },
  { fullname: 'Commercial Sectors', name: 'Commercial\nSectors', x: 36, y: -13, category: 8, isActive: false },
  { fullname: 'Electric Vehicles', name: 'Electric\nVehicles', x: 36, y: -8, category: 8, isActive: false },
  { fullname: 'City Infrastructures', name: 'City\nInfrastructures', x: 36, y: -3, category: 8, isActive: false },

  //
  { fullname: 'power Outage', name: 'Power\nOutage', x: 42, y: -28, category: 9, isActive: false },
  { fullname: 'Workforce Safety', name: 'Workforce\nSafety', x: 42, y: -23, category: 9, isActive: false },
  { fullname: 'Customer Satisfaction', name: 'Customer\nSatisfaction', x: 42, y: -18, category: 9, isActive: false },

  { fullname: 'Exports', name: 'Exports', x: 47, y: -25, category: 10, isActive: false },
  { fullname: 'Imports', name: 'Imports', x: 47, y: -20, category: 10, isActive: false },
  { fullname: 'Inflation', name: 'Inflation', x: 47, y: -15, category: 10, isActive: false },
  { fullname: 'Employment', name: 'Employment', x: 47, y: -10, category: 10, isActive: false },
  { fullname: 'Profit', name: 'Profit', x: 47, y: -5, category: 10, isActive: false },

  { fullname: 'Biodiversity Loss', name: 'Biodiversity Loss', x: 53, y: -28, category: 11, isActive: false },
  { fullname: 'Greenhouse Gas Emissions', name: 'Greenhouse\nGas\nEmissions', x: 53, y: -23, category: 11, isActive: false },
  { fullname: 'Oil Spill', name: 'Oil\nSpill', x: 53, y: -18, category: 11, isActive: false },
  { fullname: 'Radioactive Wastes', name: 'Radioactive\nWastes', x: 53, y: -13, category: 11, isActive: false },
  { fullname: 'Toxic Chemicals', name: 'Toxic\nChemicals', x: 53, y: -8, category: 11, isActive: false },
  
  //logistic
  { fullname: 'Tanker Trucks', name: 'Tanker\nTrucks', x: 0, y: 15, category: 5, isActive: false },
  { fullname: 'Trucks', name: 'Trucks', x: 5, y: 15, category: 5, isActive: false },
  { fullname: 'Rails', name: 'Rails', x: 10, y: 15, category: 5, isActive: false },

  { fullname: 'Tanker Vessels', name: 'Tanker\nVessels', x: 15, y: 15, category: 5, isActive: false },
  { fullname: 'Railroad Tanker Cars', name: 'Railroad\nTanker\nCars', x: 20, y: 15, category: 5, isActive: false },
  { fullname: 'Pipelines', name: 'Pipelines', x: 25, y: 15, category: 5, isActive: false },
  { fullname: 'Vessels', name: 'Vessels', x: 30, y: 15, category: 5, isActive: false },

  { fullname: 'Step-up Substation/Transformers', name: 'Step-up\nSubstations /\nTransformers', x: 6, y: 20, category: 5, isActive: false },
  { fullname: 'Power Transmission Lines', name: 'Power\nTransmission\nLines', x: 13, y: 20, category: 5, isActive: false },

  { fullname: 'Step-down Substation/Transformers', name: 'Step-down\nSubstations /\nTransformers', x: 20, y: 20, category: 5, isActive: false },
  { fullname: 'Power Distribution Lines', name: 'Power\nDistribution\nLines', x: 26, y: 20, category: 5, isActive: false },
  { fullname: 'Charging Stations', name: 'Charging\nStations', x: 32, y: 20, category: 5, isActive: false },


  //critical
  { fullname: 'Waste Water Infrastructure', name: 'Waste\nWater\nInfrastructure', x: -30, y: 25, category: 2, isActive: false },
  { fullname: 'Drinking Water Infrastructure', name: 'Drinking\nWater\nInfrastructure', x: -22, y: 25, category: 2, isActive: false },
  { fullname: 'Public Transportation', name: 'Public\nTransportation', x: -15, y: 25, category: 2, isActive: false },
  { fullname: 'Storm Water Infrastructure', name: 'Storm\nWater\nInfrastructure', x: -8, y: 25, category: 2, isActive: false },
  { fullname: 'Solid Waste Infrastructure', name: 'Solid\nWaste\nInfrastructure', x: 0, y: 25, category: 2, isActive: false },
  { fullname: 'Roads', name: 'Roads', x: 5, y: 25, category: 2, isActive: false },
  { fullname: 'Fuel', name: 'Fuel', x: 10, y: 25, category: 2, isActive: false },
  { fullname: 'Electricity', name: 'Electricity', x: 15, y: 25, category: 2, isActive: false },
  { fullname: 'Bridges', name: 'Bridges', x: 20, y: 25, category: 2, isActive: false },
  { fullname: 'Ports', name: 'Ports', x: 25, y: 25, category: 2, isActive: false },
  { fullname: 'Railroads', name: 'Railroads', x: 30, y: 25, category: 2, isActive: false },
  { fullname: 'Broadband', name: 'Broadband', x: 35, y: 25, category: 2, isActive: false },
  { fullname: 'Inland Waterways', name: 'Inland\nWaterways', x: 40, y: 25, category: 2, isActive: false },
  { fullname: 'Dam & Reservoirs', name: 'Dams &\nReservoirs', x: 46, y: 25, category: 2, isActive: false },


 ];
/*End Nodes*/

 
/* Links */
export const PowerGraphLinks: GraphLinkModel[] = [
  { source: 'Aging\nInfrastructure', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Step-down\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Power\nTransmission\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Air\nPollution', target: 'Solar', lineStyle: { curveness: 0.1 } },
  { source: 'Anaerobic\nDigesters', target: 'Industrial\nSectors\nWastewater\nTreatment\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Animal\nInterference', target: 'Nuclear\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Animal\nInterference', target: 'Power\nDistribution\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Animal\nInterference', target: 'Power\nTransmission\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Animal\nInterference', target: 'Step-down\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Animal\nInterference', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Batteries', target: 'Toxic\nChemicals', lineStyle: { curveness: 0.1 } },
  { source: 'Biomass\nWaste', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Biomass\nWaste', target: 'Anaerobic\nDigesters', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'City\nInfrastructures', target: 'Oil\nSpill', lineStyle: { curveness: 0.1 } },
  { source: 'Coal', target: 'Coal\nMines', lineStyle: { curveness: 0.1 } },
  { source: 'Coal\nMines', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Coal\nMines', target: 'Rails', lineStyle: { curveness: 0.1 } },
  { source: 'Coal\nMines', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Coal\nPower\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Coal\nPower\nPlants', target: 'Greenhouse\nGas\nEmissions', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage\n(Logistics)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage\n(S)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage\n(MP)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage\n(R)', lineStyle: { curveness: 0.1 } },
  { source: 'Critical\nMinerals', target: 'Solar PV\nPanels', lineStyle: { curveness: 0.1 } },
  { source: 'Critical\nMinerals', target: 'Solar PV\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Critical\nMinerals', target: 'Wind\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Critical\nMinerals', target: 'Batteries', lineStyle: { curveness: 0.1 } },
  { source: 'Crop\nFeedstock', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Crop\nProduction', target: 'Biomass\nWaste', lineStyle: { curveness: 0.1 } },
  { source: 'Crop\nProduction', target: 'Crop\nFeedstock', lineStyle: { curveness: 0.1 } },
  { source: 'Crude Oil\nRefineries', target: 'Oil Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Crude Oil\nRefineries', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Crude Oil\nRefineries', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Crude Oil\nRefineries', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Crude Oil\nRefineries', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'Broadband', lineStyle: { curveness: 0.1 } },
  { source: 'Dams &\nReservoirs', target: 'Hydroelectric\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Drinking\nWater\nInfrastructure', target: 'Public\nHealth', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Crop\nProductions', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Wildfires', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Inland\nWaterways', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Nuclear\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Wind\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Electrolyzers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Ports', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Railroads', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Railroads', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Electrolyzers', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Electrolyzers', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Electrolyzers', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Electrolyzers', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nDemand', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Environmental\nRegulations', target: 'Air\nPollution', lineStyle: { curveness: 0.1 } },
  { source: 'Environmental\nRegulations', target: 'Government\nSubsidies /\nIncentives', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol\nPlants', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol\nPlants', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol\nPlants', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol\nPlants', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Floods', target: 'Crop\nProduction', lineStyle: { curveness: 0.1 } },
  { source: 'Floods', target: 'Hazardous Waste Infrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Floods', target: 'Hydroelectric\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Fractionator', target: 'Natural\nGas\nRefineries', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel Cell\nPower\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Gas\nStations', target: 'Residential\nSectors', lineStyle: { curveness: 0.1 } },
  { source: 'Gas\nStations', target: 'Commercial\nSectors', lineStyle: { curveness: 0.1 } },
  { source: 'Gas\nWells', target: 'Natural Gas\nProcessing\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\nTension', target: 'Tariffs /\nSanctionss', lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\nTension', target: 'Terrorism /\nCriminal\nActivity', lineStyle: { curveness: 0.1 } },
  { source: 'Geothermal\nPower\nPlants', target: 'Toxic\nChemicals', lineStyle: { curveness: 0.1 } },
  { source: 'Geothermal\nPower\nPlants', target: 'Greenhouse\nGas\nEmissions', lineStyle: { curveness: 0.1 } },
  { source: 'Geothermal\nReservoirs', target: 'Geothermal\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nSubsidies /\nIncentives', target: 'Geothermal\nReservoirs', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nSubsidies /\nIncentives', target: 'Solar PV\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nSubsidies /\nIncentives', target: 'Wind\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricanes', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricanes', target: 'Gas\nStations', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricanes', target: 'Nuclear\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricanes', target: 'Ports', lineStyle: { curveness: 0.1 } },
  { source: 'Hydrocarbon\nReservoirs', target: 'Oil\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Hydrocarbon\nReservoirs', target: 'Gas\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Hydroelectric\nPower\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Industrial\nSectors\nWastewater\nTreatment\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Industrial\nSectors\nWastewater\nTreatment\nPlants', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nWaterways', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(Logistics)', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(Logistics)', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(Logistics)', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(MP)', target: 'Natural\nGas Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(MP)', target: 'Oil Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(RMS)', target: 'Gas\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(RMS)', target: 'Oil\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage\n(S)', target: 'Wind\nTurbines', lineStyle: { curveness: 0.1 } },
  { source: 'Landfills', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Landfills', target: 'Natural\nGas Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Landfills', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Liquifaction', target: 'Regasification', lineStyle: { curveness: 0.1 } },
  { source: 'Misinformation', target: 'Environmental\nRegulations', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Power\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Power\nPlants', target: 'Greenhouse\nGas\nEmissions', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Waste-to-\nEnergy\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Geothermal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Hydroelectric\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Coal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Natural\nGas Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Solar PV\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Solar PV\nPanels', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Wind\nTurbines', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Gas\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas Price', target: 'Oil\nWells', lineStyle: { curveness: 0.1 } },
  { source: 'Natural Gas\nProcessing\nPlants', target: 'Liquifaction', lineStyle: { curveness: 0.1 } },
  { source: 'Natural Gas\nProcessing\nPlants', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Natural Gas\nProcessing\nPlants', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Natural Gas\nProcessing\nPlants', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas\nRefineries', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Nuclear\nPower\nPlants', target: 'Radioactive\nWastes', lineStyle: { curveness: 0.1 } },
  { source: 'Oil Power\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Oil Power\nPlants', target: 'Greenhouse\nGas\nEmissions', lineStyle: { curveness: 0.1 } },
  { source: 'Oil\nWells', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Oil\nWells', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Oil\nWells', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Oil\nWells', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nAttacks', target: 'Power\nTransmission\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nAttacks', target: 'Power\nDistribution\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nAttacks', target: 'Step-down\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nAttacks', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Crude Oil\nRefineries', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Terminal', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Gas\nStations', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Natural\nGas Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Fractionator', lineStyle: { curveness: 0.1 } },
  { source: 'Pipelines', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Port', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nDistribution\nLines', target: 'Biodiversity Loss', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nTransmission\nLines', target: 'Biodiversity Loss', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth', target: 'Labor\nShortage\n(Logistics)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth', target: 'Labor\nShortage\n(S)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth', target: 'Labor\nShortage\n(MP)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth', target: 'Labor\nShortage\n(R)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage\n(Logistics)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage\n(S)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage\n(MP)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage\n(R)', lineStyle: { curveness: 0.1 } },
  { source: 'Railroad\nTanker\nCars', target: 'Crude Oil\nRefineries', lineStyle: { curveness: 0.1 } },
  { source: 'Railroad\nTanker\nCars', target: 'Terminal', lineStyle: { curveness: 0.1 } },
  { source: 'Railroad\nTanker\nCars' , target: 'Fractionator', lineStyle: { curveness: 0.1 } },
  { source: 'Railroad\nTanker\nCars', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Railroad\nTanker\nCars', target: 'Oil\nSpill', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Rails', target: 'Coal\nProcessors', lineStyle: { curveness: 0.1 } },
  { source: 'Rails', target: 'Coal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Rainfall', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Rainfall', target: 'Railroads', lineStyle: { curveness: 0.1 } },
  { source: 'Rainfall', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Rainfall', target: 'Power\nTransmission\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Regasification', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Roads', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Semiconductor\n& Electronic\nComponents Price', target: 'Solar PV\nPanels', lineStyle: { curveness: 0.1 } },
  { source: 'Semiconductor\n& Electronic\nComponents Price', target: 'Solar PV\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Snowfall', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Snowfall', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Snowfall', target: 'Wind\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Solar PV\nPanels', target: 'Residential\nSectors', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure', target: 'Biomass\nWaste', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Waste-to-\nEnergy\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Coal\nMines', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Geothermal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Natural\nGas Power\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Railroad\nTanker\nCars', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Solar PV\nPanels', lineStyle: { curveness: 0.1 } },
  { source: 'Steaming\nCoal Price', target: 'Solar PV\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Step-down\nSubstations /\nTransformers', target: 'Power\nDistribution\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Storm\nWater\nInfrastructure', target: 'Floods', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Energy\nDemand', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Wildfires', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Railroads', lineStyle: { curveness: 0.1 } },
  { source: 'Surface\nAir\nTemperature', target: 'Railroads', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nTrucks', target: 'Crude Oil\nRefineries', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nTrucks', target: 'Terminal', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nTrucks', target: 'Gas\nStations', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nTrucks', target: 'Fractionator', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nTrucks', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nVessels', target: 'Crude Oil\nRefineries', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nVessels', target: 'Terminal', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nVessels', target: 'Gas\nStations', lineStyle: { curveness: 0.1 } },
  { source: 'Tanker\nVessels', target: 'Fuel Cell\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Tariffs /\nSanctionss', target: 'Critical\nMinerals', lineStyle: { curveness: 0.1 } },
  { source: 'Terminal', target: 'Tanker\nTrucks', lineStyle: { curveness: 0.1 } },
  { source: 'Terminal', target: 'Pipelines', lineStyle: { curveness: 0.1 } },
  { source: 'Terminal', target: 'Tanker\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Terrorism /\nCriminal\nActivity', target: 'Cyberattacks', lineStyle: { curveness: 0.1 } },
  { source: 'Terrorism /\nCriminal\nActivity', target: 'Physical\nAttacks', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Waste\nTransfer\nStations', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Waste-to-\nEnergy\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Landfills', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Ethanol\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Wood Products &\nPaper\nManufacturers\nWaste', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Coal\nProcessors', lineStyle: { curveness: 0.1 } },
  { source: 'Trucks', target: 'Coal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Uranium\nPrice', target: 'Nuclear\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Coal\nProcessors', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Coal\nPower\nPlants', lineStyle: { curveness: 0.1 } },
  { source: 'Waste\nTransfer\nStations', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Waste\nWater\nInfrastructure', target: 'Drinking\nWater\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Waste\nWater\nInfrastructure', target: 'Public\nHealth', lineStyle: { curveness: 0.1 } },
  { source: 'Waste-to-\nEnergy\nPlants', target: 'Step-up\nSubstations /\nTransformers', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfires', target: 'Hazardous Waste Infrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfires', target: 'Power\nTransmission\nLines', lineStyle: { curveness: 0.1 } },
  { source: 'Wood', target: 'Trucks', lineStyle: { curveness: 0.1 } },
  { source: 'Wood Products &\nPaper\nManufacturers\nWaste', target: 'Trucks', lineStyle: { curveness: 0.1 } },
];