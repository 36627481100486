import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class TransboundaryWaterServiceService {

  constructor(private apiService: ApiServiceService) { }

  getGroundWaterQuality(State: string): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.groundWater}/${State}`);
  }
  getLandUses(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.landUse}_cn`);
  }
  getPopulationGrowthCN(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.populationGrowth}_cn`);
  }
  getPopulationGrowthUS(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.populationGrowth}_us`);
  }
  getWaterEfficiency(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.waterEfficiency_cn}`)
  }
  getLandUsesUS(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.landUse}_us`);
  }
  getDroughtTriDates():Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.drought}/${ApiRouting.trinational}/${ApiRouting.dates}`);
  }
  getEarthquakeGroundUs(){
    return this.apiService.get(`natural/earthquake/us/dates`);
  }
  getEarthquakeUS(model: any):Observable<any> {
    return this.apiService.post(`${ApiRouting.transboundary}${ApiRouting.earthquake}_us`, model);
  }
  getHurricaneUS():Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.hurricane}_us`);
  }
  getFireUsDates(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.wildfire}/us/${ApiRouting.dates}`);
  }
  getFireCnDates(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.wildfire}/cn/${ApiRouting.dates}`);
  }
  getFireUsData(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.wildfire}/us/${ApiRouting.data}`);
  }
  getFireCnData(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.wildfire}/cn/${ApiRouting.data}`);
  }
  getWildfireUS():Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.wildfire}_us`);
  }
  getCroapStateMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.croapState}_mx`);
  }
  getCroapAlfalfaStateMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.croapAlfalfa}_mx`);
  }

  getCanolaUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.canola}_us`);
  }
  getRiverWaterUseUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.riversWaterUse}_us`);
  }
  getAgriculturalProfitUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.stateOfRisk}/${ApiRouting.agriculturalProfit}_us`);
  }
  
}
