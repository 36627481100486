<!--
  <div class="two-filter-container">
    <mat-form-field>
        <mat-label>{{this.data.firstFilter.name}}</mat-label>
        <mat-select [formControl]="firstFilter">
          <mat-option *ngFor="let item of this.data.firstFilter.values" [value]="item.id">{{item.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="estado" >
        <mat-label>{{this.data.secondFilter.name}}</mat-label>
        <mat-select [formControl]="secondFilter" >
          <mat-option *ngFor="let item of this.data.secondFilter.values" [value]="item.id">{{item.value}}</mat-option>
        </mat-select>
      </mat-form-field>
</div> -->
<div class="row">
<div class="col-2">
  <label for="firstFilter" class="form-label subtitle">{{this.data.firstFilter.name}}</label>
    <select class="form-select"  [formControl]="firstFilter">
      <option *ngFor="let item of this.data.firstFilter.values" [value]="item.id">
        {{item.value}}
      </option>
    </select>
</div>
<div class="col-2" *ngIf="estado">
  <label for="firstFilter" class="form-label subtitle">{{this.data.secondFilter.name}}</label>
    <select class="form-select"  [formControl]="secondFilter">
      <option *ngFor="let item of this.data.secondFilter.values" [value]="item.id">
        {{item.value}}
      </option>
    </select>
</div>
</div>