import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WindTurbineComponent } from 'src/app/shared/pages/wind-turbine/wind-turbine.component';
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Wind-Turbines',
    component: WindTurbineComponent
  },
  {
    path: 'Energy-Demand',
    component: GeneralNodeComponent//EnergyDemandComponent
  }, 
  {
    path: 'Industrial-Sectors-Wastewater-Treatment-Plants',
    component: GeneralNodeComponent
  },
  {
    path: 'Electricity',
    component: GeneralNodeComponent
  },
  {
    path: 'Animal-Interference',
    component: GeneralNodeComponent
  },
  {
    path: 'Greenhouse-Gas-Emissions',
    component: GeneralNodeComponent
  },
  {
    path: 'Terrorism-/-Criminal-Activity',
    component: GeneralNodeComponent
  },
  {
    path: 'Natural-Gas-Power-Plants',
    component: GeneralNodeComponent
  },
  {
    path: 'Cyberattacks',
    component: GeneralNodeComponent
  },
  //SA/power/Earthquake
  {
    path: 'Earthquake',
    component: GeneralNodeComponent
  },
  
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PowerRoutingModule { }