import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom, ShapeMapDataLink } from 'src/app/core/enums/map.enum';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-drought',
  templateUrl: './drought.component.html',
  styleUrls: ['./drought.component.css']
})
export class DroughtComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  
  constructor(private transboundaryWaterService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }

  ngOnInit(): void {
    this.spinner.show();
    
    if(this.isTri){
      this.getDataTri();
    } else {
      this.spinner.hide();
    }

    if(this.filter!=undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
     this.select = result ? result.itemId : null;
      this.filterInfo = this.filter;
      this.hasStudyButton = true;    
    }

  }

  getDataTri(){
    const shapeTypes = [
      {id: 0, name: 'Abnormally Dry', color: 'rgba(255,255,31, 1)', shapeColor: 'rgba(255,255,31, 0.45)'},
      {id: 1, name: 'Moderate Drought', color: 'rgba(239,197,123, 1)', shapeColor: 'rgba(239,197,123, 0.45)'},
      {id: 2, name: 'Severe Drought', color: 'rgba(246,149,0, 1)', shapeColor: 'rgba(246,149,0, 0.45)'},
      {id: 3, name: 'Extreme Drought', color: 'rgba(254,1,0, 1)', shapeColor: 'rgba(254,1,0, 0.45)'},
      {id: 4, name: 'Exceptional Drought', color: 'rgba(129,1,1, 1)', shapeColor: 'rgba(129,1,1, 0.45)'},
      {id: 5, name: 'Impact lines', color: 'rgba(97,114,112, 1)', shapeColor: 'rgba(97,114,112, 0.45)'},
    ];
      this.transboundaryWaterService.getDroughtTriDates()
        .subscribe(
          {
            next: (res:any) => {
              const  dataMap = {
                center: MapCenter.USA,
                zoom: MapZoom.TRINATIONAL,
              };
              const formatInfo = {
                region: "en-US",
                format: {  year: 'numeric', month: '2-digit' },
                addDays: 1
              };
              this.data = {
                data: shapeTypes,
                metadata: res.metadata, 
                dates: res.dates,
                shapes: shapeTypes,
                mapInfo: dataMap,
                step: 12, // step year by year
                shapeUrl: ShapeMapDataLink.Drought_tri,
                formatInfo: formatInfo,
                filterInfo:this.filterInfo,
                selectedInfo:this.select,
                hasStudyButton:this.hasStudyButton,
              };  
            },
            error: (e:any) => { console.error(e); this.spinner.hide(); }
          }
        );
    
    this.spinner.hide();
  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

}
