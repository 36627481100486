import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.css'],
})
export class ArticleItemComponent {
  @Input() article: any;
  @Input() last: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  select() {
    const {start, end, content, topic} = this.route.snapshot.queryParams

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { start, end, content, topic, id: this.article?.eventId },
      queryParamsHandling: 'merge'
    });
  }
}
