<div id="mapTiff">
    <mat-form-field class="year-picker" *ngIf="showYearSelector">
        <mat-label>Select Year</mat-label>
        <input [(ngModel)]="selectYear" [min]="minDate" [max]="maxDate" matInput [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)">
        </mat-datepicker>
    </mat-form-field>
</div>

