import { GraphItemModel, GraphLinkModel } from "../models/graph.model";


/* Categories */
export const MedicalInstrumentsGraphCategory: any[] = [
    /*{ name: 'Anthropogenic', itemStyle: { color: '#006483' } }, //0
    { name: 'Raw Material', itemStyle: { color: '#c17a2f' } }, //1
    { name: 'Natural', itemStyle: { color: '#438bca' } }, //2
    { name: 'Suppliers', itemStyle: { color: '#2b0171' } },//3
    { name: 'Transportation / Logistic', itemStyle: { color: '#500101' } },//4
    { name: 'Manufacturing', itemStyle: { color: '#707070' } },//5
    { name: 'Retailers', itemStyle: { color: '#5a6237' } },//6
    { name: 'Customers', itemStyle: { color: '#b0b1b1' } },//7
    { name: 'Social', itemStyle: { color: '#ad9d68' } },//8
    { name: 'Economic', itemStyle: { color: '#ad9d68' } },//9
    { name: 'Environmental', itemStyle: { color: '#ad9d68' } },//10
    { name: 'Critical\nInfrastructure', itemStyle: { color: '#4966b0' }},*/
    { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
    { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
    { id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
      { id: 3,name: 'Water Sources asd2', itemStyle: { color: '#ff9900' } },
      { id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
      { id: 5,name: 'Manufacturing', itemStyle: { color: '#707070' } },
      { id: 6,name: 'Production das2', itemStyle: { color: '#707070' } },
      { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
      { id: 8,name: 'Logistic', itemStyle: { color: '#993365' } },
      { id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
      { id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
      { id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
      { id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
      { id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
];

export const MedicalInstrumentsGraphCategoryLink: any[] = [
    { name: 'Threats', links: [MedicalInstrumentsGraphCategory[0], MedicalInstrumentsGraphCategory[2], MedicalInstrumentsGraphCategory[11]]},
    { name: 'Systems', links: [MedicalInstrumentsGraphCategory[1], MedicalInstrumentsGraphCategory[3], 
          MedicalInstrumentsGraphCategory[4], MedicalInstrumentsGraphCategory[5],MedicalInstrumentsGraphCategory[6],
          MedicalInstrumentsGraphCategory[7]
      ]},
    { name: 'State of Risk', links: [MedicalInstrumentsGraphCategory[8], MedicalInstrumentsGraphCategory[9], 
        MedicalInstrumentsGraphCategory[10]
      ]}
  ]
/*End categories */


/*NODES*/
export const MedicalInstrumentsGraphItems: GraphItemModel[] = [

    //#region Anthropogenic
    { fullname: 'Dependence on foreign manufacturing', name: 'Dependence\non foreign\nmanufacturing', x: -25, y: -26.5, category: 0, isActive: false },
    { fullname: 'Information\nSharing', name: 'Information\nSharing', x: -25, y: -20, category: 0, isActive: false },
    
    { fullname: 'Contamination', name: 'Contamination', x: -20, y: -25, category: 0, isActive: false },
    { fullname: 'Demad', name: 'Demand', x: -20, y: -20, category: 0, isActive: false },
    { fullname: 'COVID 19', name: 'COVID 19', x: -20, y: -15, category: 0, isActive: true },
    { fullname: 'Cyberattacks', name: 'Cyberattacks', x: -20, y: -10, category: 0, isActive: true },
    { fullname: 'Fraudulent Medical Devices', name: 'Fraudulent\nMedical\nDevices', x: -20, y: -5, category: 0, isActive: false },
    { fullname: 'Public Transportation', name: 'Public\nTransportation', x: -20, y: 0, category: 0, isActive: false },
    { fullname: 'Geographic Competition', name: 'Geographic\nCompetition', x: -20, y: 5, category: 0, isActive: false },
    { fullname: 'Aging Infrastructure', name: 'Aging\nInfrastructure', x: -20, y: 10, category: 0, isActive: false },
  
    { fullname: 'Excessive Ethylene Oxide Emission', name: 'Excessive\nEthylene Oxide\nEmission', x: -15, y: -26.5, category: 0, isActive: false },
    { fullname: 'Public Health', name: 'Public\nHealth', x: -15, y: -20, category: 0, isActive: false },
    { fullname: 'Labor Shortage (RM)', name: 'Labor\nShortage (RM)', x: -15, y: -15, category: 0, isActive: false },
    { fullname: 'Labor Shortage (M)', name: 'Labor\nShortage (M)', x: -15, y: -10, category: 0, isActive: false },
    { fullname: 'Governmental Regulations', name: 'Governmental\nRegulations', x: -15, y: -5, category: 0, isActive: false },
    { fullname: 'Complexity of Device', name: 'Complexity\nof Device', x: -15, y: 0, category: 0, isActive: false },
    { fullname: 'Temperature Sensitive Equipment', name: 'Temperature\nSensitive\nEquipment', x: -15, y: 5, category: 0, isActive: false },
    { fullname: 'Vendor Managed Inventory', name: 'Vendor\nManaged\nInventory', x: -15, y: 10, category: 0, isActive: false },
  
    { fullname: 'Raw Material Shortage', name: 'Raw Material\nShortage', x: -10, y: -25, category: 0, isActive: false },
    { fullname: 'Supply bottleneck', name: 'Supply\nbottleneck', x: -10, y: -20, category: 0, isActive: false },
    { fullname: 'Semiconductor Shortage', name: 'Semiconductor\nShortage', x: -10, y: -15, category: 0, isActive: false },
    { fullname: 'Labor Shortage (R)', name: 'Labor\nShortage (R)', x: -10, y: -10, category: 0, isActive: false },
    { fullname: 'Export Restrictions', name: 'Export\nRestrictions', x: -10, y: -5, category: 0, isActive: false },
    { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage (S)', x: -10, y: 0, category: 0, isActive: false },
    { fullname: 'Blackout', name: 'Blackout', x: -10, y: 5, category: 0, isActive: false },
    //#endregion

    //#region Raw Material
    { fullname: 'Polymer', name: 'Polymer', x: -5, y: -25, category: 1, isActive: false },
    { fullname: 'Metals', name: 'Metals', x: -5, y: -20, category: 1, isActive: false },
    { fullname: 'Ceramics', name: 'Ceramics', x: -5, y: -15, category: 1, isActive: false },
    { fullname: 'Non-woven fabric', name: 'Non-woven\nfabric', x:-5, y: -10, category: 1, isActive: false },
    { fullname: 'Electricity', name: 'Electricity', x: -5, y: -5, category: 1, isActive: false },
    { fullname: 'Fuel', name: 'Fuel', x: -5, y: 0, category: 1, isActive: false },
    //#endregion
    
    //#region Supplier
    { fullname: 'International Trade', name: 'International\nTrade', x: 0, y: -25, category: 3, isActive: false },
    { fullname: 'Raw Material Supplier', name: 'Raw Material\nSupplier', x: 0, y: -20, category: 3, isActive: false },
    { fullname: 'Components Supplier', name: 'Components\nSupplier', x: 0, y: -15, category: 3, isActive: false },
    { fullname: 'Electricity (S)', name: 'Electricity\n(S)', x: 0, y: -10, category: 3, isActive: false },
    { fullname: 'Fuel (S)', name: 'Fuel (S)', x: 0, y: -5, category: 3, isActive: false },
    //#endregion

    //#region Manufacturing
    { fullname: 'Sterilization', name: 'Sterilization', x: 5, y: -25, category: 5, isActive: false },
    { fullname: 'Inventory', name: 'Inventory', x: 5, y: -20, category: 5, isActive: false },
    { fullname: 'Fabrication', name: 'Fabrication', x: 5, y: -15, category: 5, isActive: false },
    { fullname: 'Specialized Labor', name: 'Specialized\nLabor', x: 5, y: -10, category: 5, isActive: false },
    { fullname: 'Quality Control', name: 'Quality\nControl', x: 5, y: -5, category: 5, isActive: false },
    { fullname: 'Supplier Quality Standards', name: 'Supplier Quality\nStandards', x:5, y: 0, category: 5, isActive: false },
    { fullname: 'Medical Device Software', name: 'Medical\nDevice\nSoftware', x: 5, y: 5, category: 5, isActive: false },  //Medical Device Reprocessing
    
    { fullname: 'Electricity (M)', name: 'Electricity\n(M)', x: 10, y: -18, category: 5, isActive: false },
    { fullname: 'Fuel (M)', name: 'Fuel (M)', x: 10, y: -13, category: 5, isActive: false },
    { fullname: 'Design', name: 'Design', x: 10, y: -7, category: 5, isActive: false },
   //#endregion
    
   //#region Retailers
   { fullname: 'Retail Stores', name: 'Retail\nStores', x: 15, y: -25, category: 6, isActive: false },
   { fullname: 'Maintenance and Repair', name: 'Maintenance\nand Repair', x: 15, y: -20, category: 6, isActive: false },
   { fullname: 'Distributors', name: 'Distributors', x: 15, y: -15, category: 6, isActive: false },
   { fullname: 'Electricity (R)', name: 'Electricity\n(R)', x:15, y: -10, category: 6, isActive: false },
   { fullname: 'Fuel (R)', name: 'Fuel (R)', x: 15, y: 0, category: 6, isActive: false },
   //#endregion
  
   //#region Customers
    { fullname: 'Intravenous Drug Users', name: 'Intravenous\nDrug Users', x: 22, y: -25, category: 7, isActive: false },
    { fullname: 'Elderly Population', name: 'Elderly\nPopulation', x: 22, y: -20, category: 7, isActive: false },
    { fullname: 'Hospitals', name: 'Hospitals', x: 22, y: -15, category: 7, isActive: false },
    { fullname: 'Group Purchasing Organization', name: 'Group\nPurchasing\nOrganization', x: 22, y: -8, category: 7, isActive: false },
    { fullname: 'Healthcare System', name: 'Healthcare\nSystem', x: 22, y: -3, category: 7, isActive: false },
    { fullname: 'Waste Management', name: 'Waste\nManagement', x: 22, y: 2, category: 7, isActive: false },
    //#endregion
    
   //#region State of Risk
    //social
    { fullname: 'Unemployment', name: 'Unemployment', symbol: 'rect', x: 28, y: -25, category: 8, isActive: false },
    { fullname: 'Safety', name: 'Safety', symbol: 'rect', x: 28, y: -20, category: 8, isActive: false },
    { fullname: 'Public Health ', name: 'Public\nHealth ', symbol: 'rect', x: 28, y: -15, category: 8, isActive: false },
    
    { fullname: 'Death', name: 'Death', symbol: 'rect', x: 34, y: -25, category: 8, isActive: false },
    { fullname: 'Performance', name: 'Performance', symbol: 'rect', x: 34, y: -20, category: 8, isActive: false },    
    { fullname: 'Quality of Devices', name: 'Quality of\nDevices', symbol: 'rect', x: 34, y: -15, category: 8, isActive: false },
    
    //Economic
    { fullname: 'Trade Deficit', name: 'Trade\nDeficit', symbol: 'rect', x: 28, y: -10, category: 8, isActive: false },
    { fullname: 'Healthcare Cost', name: 'Healthcare\nCost', symbol: 'rect', x: 31, y: -5, category: 8, isActive: false },
    { fullname: 'Operational Cost', name: 'Operational\nCost', symbol: 'rect', x: 28, y: 0, category: 8, isActive: false },
    
    { fullname: 'Insurance Reimbursement', name: 'Insurance\nReimbursement', symbol: 'rect', x: 34, y: -10, category: 8, isActive: false },
    { fullname: 'Operational Cost ', name: 'Operational\nCost ', symbol: 'rect', x: 34, y: 0, category: 8, isActive: false },
        
    //Environmental
    { fullname: 'Global Warming', name: 'Global\nWarming', symbol: 'rect', x: 31, y: 5, category: 8, isActive: false },
    { fullname: 'Pollution', name: 'Pollution', symbol: 'rect', x: 31, y: 10, category: 8, isActive: false },
    
    //#endregion

    //#region Natural
    { fullname: 'Winter Storm', name: 'Winter\nStorm', x: -25, y: 11, category: 2, isActive: false },
    { fullname: 'Cold Snap', name: 'Cold Snap', x: -25, y: 15, category: 2, isActive: false },
    
    { fullname: 'Tsunami', name: 'Tsunami', x: -20, y: 13, category: 2, isActive: false },
    { fullname: 'Hurricanes', name: 'Hurricanes', x: -20, y: 17, category: 2, isActive: false },
    
    { fullname: 'Fire', name: 'Fire', x: -15, y: 15, category: 2, isActive: true },
    
    { fullname: 'Earthquakes', name: 'Earthquakes', x: -10, y: 11, category: 2, isActive: false },
    { fullname: 'Floods', name: 'Floods', x: -10, y: 15, category: 2, isActive: false },
    //#endregion
  
   //#region logistic
   { fullname: 'Trucks', name: 'Trucks', x: 0, y: 13, category: 4, isActive: false },
   { fullname: 'Barges', name: 'Barges', x: 5, y: 10, category: 4, isActive: false },
   { fullname: 'Vehicles', name: 'Vehicles', x: 10, y: 13, category: 4, isActive: false },
   { fullname: 'Vessels', name: 'Vessels', x: 15, y: 10, category: 4, isActive: false },
   //#endregion
    
   //#region Critical Infrastructure
   { fullname: 'Solid Waste Infrastructure', name: 'Solid\nWaste\nInfrastructure', x: -25, y: 20, category: 11, isActive: false },
   { fullname: 'Wastewater Infrastructure', name: 'Wastewater\nInfrastructure', x: -25, y: 25, category: 11, isActive: false },
   
   { fullname: 'StormWater Infrastructure', name: 'Storm\nWater\nInfrastructure', x: -20, y: 23, category: 11, isActive: false },
   
   { fullname: 'Hazardous Waste Infrastructure', name: 'Hazardous\nWaste\nInfrastructure', x: -15, y: 20, category: 11, isActive: false },
   { fullname: 'Drinking Water Infrastructure', name: 'Drinking\nWater\nInfrastructure', x: -15, y: 25, category: 11, isActive: false },
   
   { fullname: 'Electricity (RM-S)', name: 'Electricity\n(RM-S)', x: -10, y: 20, category: 11, isActive: false },
   { fullname: 'Fuel (RM-S)', name: 'Fuel (RM-S)', x: -10, y: 25, category: 11, isActive: false },

   { fullname: 'Broadband', name: 'Broadband', x: -5, y: 23, category: 11, isActive: false },

   { fullname: 'Ports', name: 'Ports', x: 0, y: 20, category: 11, isActive: false },
   { fullname: 'Rails', name: 'Rails', x: 0, y: 25, category: 11, isActive: false },

   { fullname: 'Roads', name: 'Roads', x: 5, y: 20, category: 11, isActive: false },
   { fullname: 'Aviation', name: 'Aviation', x: 5, y: 25, category: 11, isActive: false },

   { fullname: 'Bridges', name: 'Bridges', x: 10, y: 23, category: 11, isActive: false },

   { fullname: 'Electricity (S-M)', name: 'Electricity\n(S-M)', x: 15, y: 20, category: 11, isActive: false },
   { fullname: 'Fuel (S-M)', name: 'Fuel (S-M)', x: 15, y: 25, category: 11, isActive: false },

   //{ fullname: 'Braodband', name: 'Braodband', x: 20, y: 20, category: 11, isActive: false },
   { fullname: 'Electricity ', name: 'Electricity ', x: 20, y: 25, category: 11, isActive: false },
   { fullname: 'Electricity (R-C)', name: 'Electricity\n(R-C)', x: 25, y: 20, category: 11, isActive: false },
   { fullname: 'Fuel (R-C)', name: 'Fuel (R-C)', x: 25, y: 25, category: 11, isActive: false },

   //#endregion 

  { fullname: 'invisible', name: 'invisible', symbol: 'rect', x: 80, y: 12.1, itemStyle: { color: '#fff', borderWidth: 0 }, label: { color: '#fff' }, isActive: true },
    
  ];
/*End Nodes*/


/* Links */
export const MedicalInstrumentsGraphLinks: GraphLinkModel[] = [

  { target: 'Aviation', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Ceramics', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Components\nSupplier', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Components\nSupplier', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Distributors', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Distributors', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Design', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Elderly\nPopulation', source: 'Death', lineStyle: { curveness: 0.1 } },
  { target: 'Elderly\nPopulation', source: 'Death', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Death', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Safety', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Rail', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Rail', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Group\nPurchasing\nOrganization', source: 'Healthcare\nCost', lineStyle: { curveness: 0.1 } },
  { target: 'Healthcare\nSystem', source: 'Healthcare\nCost', lineStyle: { curveness: 0.1 } },
  { target: 'Healthcare\nSystem', source: 'Public\nHealth ', lineStyle: { curveness: 0.1 } },
  { target: 'Hospitals', source: 'Death', lineStyle: { curveness: 0.1 } },
  { target: 'Hospitals', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Hospitals', source: 'Public\nHealth ', lineStyle: { curveness: 0.1 } },
  { target: 'Hospitals (Customer)', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Quality Regulation and Standards', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Trade\nDeficit', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Public\nHealth ', lineStyle: { curveness: 0.1 } },
  { target: 'Intravenous\nDrug Users', source: 'Death', lineStyle: { curveness: 0.1 } },
  { target: 'Inventory', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Maintenance\nand Repair', source: 'Public\nHealth ', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Global\nWarming', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Healthcare\nCost', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Insurance\nReimbursement', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Performance', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Metals', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Non-woven\nfabric', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Polymer', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Quality\nControl', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Quality\nControl', source: 'Performance', lineStyle: { curveness: 0.1 } },
  { target: 'Quality\nControl', source: 'Safety', lineStyle: { curveness: 0.1 } },
  { target: 'Quality Regulations and Standards', source: 'Safety', lineStyle: { curveness: 0.1 } },

  { target: 'Raw Material\nSupplier', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nSupplier', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nSupplier', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Retail\nStores', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Retail\nStores', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Specialized\nLabor', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Sterilization', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Supplier Quality\nStandards', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Rail', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Waste\nManagement', source: 'Pollution', lineStyle: { curveness: 0.1 } },
  { target: 'Warehouse', source: 'Operational\nCost', lineStyle: { curveness: 0.1 } },
  { target: 'Waste\nManagement ', source: 'Operational\nCost', lineStyle: { curveness: 0.1 } },
  { target: 'Blackout', source: 'Hospital', lineStyle: { curveness: 0.1 } },
  { target: 'Blackout', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Cold Snap', source: 'Blackout', lineStyle: { curveness: 0.1 } },
  { target: 'Cold Snap', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Contamination', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Contamination', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Contamination', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'COVID 19', source: 'Supply\nbottleneck', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Polymer', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Metals', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Ceramics', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Non-woven\nfabric', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Ports', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Polymer', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Metals', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Ceramics', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Non-woven\nfabric', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Quality of\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Polymer', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Metals', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Ceramics', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Non-woven\nfabric', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Warehouse', lineStyle: { curveness: 0.1 } },

  { target: 'International\nTrade', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nSupplier', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Components\nSupplier', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nSupplier', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Components\nSupplier', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'International\nTrade', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nSupplier', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Components\nSupplier', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Sterilization', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Inventory', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Specialized\nLabor', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Quality\nControl', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Supplier Quality\nStandards', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Design', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Warehouse', source: ' Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: ' Aviation', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Rails', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Sterilization', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Inventory', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Fabrication', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Specialized\nLabor', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Quality\nControl', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Supplier Quality\nStandards', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Design', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Warehouse', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Medical\nDevice\nSoftware', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  
  { target: 'Trucking', source: 'Bridges', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Retail\nStores', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Maintenance\nand Repair', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Distributors', source: 'Barges', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Barges', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Roads', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Retail\nStores', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Maintenance\nand Repair', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Distributors', source: 'Trucking', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Trucking', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Broadband', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Aviation', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity', source: 'Metals', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel', source: 'Metals', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (S)', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (S)', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (S)', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(S)', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(S)', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(S)', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (M)', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Supplier Quality\nStandards', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(M)', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (R)', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel (R)', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Fuel(R)', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(R)', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(R)', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Electricity\n(R)', source: 'Distributors', lineStyle: { curveness: 0.1 } },

  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Supplier Quality\nStandards ', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nTransportation', source: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nTransportation', source: 'Labor\nShortage (RM) ', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nTransportation', source: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nTransportation', source: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nHealth', source: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nHealth', source: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nHealth', source: 'Labor\nShortage (RM) ', lineStyle: { curveness: 0.1 } },
  { target: 'Public\nHealth', source: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Metals', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Supplier Quality\nStandards ', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Solid\nWaste\nInfrastructure', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Wastewater\nInfrastructure', source: 'Public\nHealth', lineStyle: { curveness: 0.1 } },
  { target: 'Wastewater\nInfrastructure', source: 'Drinking\nWater\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { target: 'Drinking\nWater\nInfrastructure', source: 'Public\nHealth', lineStyle: { curveness: 0.1 } },
  { target: 'Cyberattacks', source: 'Broadband', lineStyle: { curveness: 0.1 } },
  { target: 'Demand', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'COVID 19', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Temperature\nSensitive\nEquipment', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Inadequate Storage and Transportation', source: 'Rails', lineStyle: { curveness: 0.1 } },
  { target: 'Winter\nStorm', source: 'Semiconductor\nShortage', lineStyle: { curveness: 0.1 } },
  { target: 'Aging\nInfrastructure', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Metals', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Sterilization', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Quality\nControl', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Supplier Quality\nStandards ', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Design', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Device Reprocessing', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Maintenance\nand Repair', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Distributors', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Intravenous\nDrug Users', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Healthcare\nSystem', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Waste\nManagement', lineStyle: { curveness: 0.1 } },
  { target: 'Storm\nWater\nInfrastructure', source: 'Group\nPurchasing\nOrganization', lineStyle: { curveness: 0.1 } },

  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Metals', lineStyle: { curveness: 0.1 } },
  { target: 'Hazardous\nWaste\nInfrastructure', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Information\nSharing', source: 'Hospital', lineStyle: { curveness: 0.1 } },
  { target: 'Information\nSharing', source: 'Raw Material\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Information\nSharing', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nShortage', source: 'Gold', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nShortage', source: 'Ceramics', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nShortage', source: 'Polymer', lineStyle: { curveness: 0.1 } },
  { target: 'Raw Material\nShortage', source: 'Non-woven\nfabric', lineStyle: { curveness: 0.1 } },
  { target: 'Semiconductor\nShortage', source: 'Supplier', lineStyle: { curveness: 0.1 } },
  { target: 'Semiconductor\nShortage', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Supply\nbottleneck ', source: 'Supplier', lineStyle: { curveness: 0.1 } },
  { target: 'Tsunami', source: 'Specialized\nLabor', lineStyle: { curveness: 0.1 } },
  { target: 'Vendor\nManaged\nInventory', source: 'Retail\nStores', lineStyle: { curveness: 0.1 } },
  { target: 'Export\nRestrictions', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Fire', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Floods', source: 'Warehouse', lineStyle: { curveness: 0.1 } },
  { target: 'Fraudulent\nMedical\nDevices', source: 'Elderly\nPopulation', lineStyle: { curveness: 0.1 } },
  { target: 'Fraudulent\nMedical\nDevices', source: 'Hospitals', lineStyle: { curveness: 0.1 } },
  { target: 'Fraudulent\nMedical\nDevices', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Geographic\nCompetition', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },
  { target: 'Governmental\nRegulations', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Governmental\nRegulations', source: 'Fraudulent\nMedical\nDevices', lineStyle: { curveness: 0.1 } },
  { target: 'Hurricanes', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Hurricanes', source: 'Blackout', lineStyle: { curveness: 0.1 } },
  { target: 'Cyberattacks', source: 'Medical\nDevice\nSoftware', lineStyle: { curveness: 0.1 } },
  { target: 'Demand', source: 'Aviation', lineStyle: { curveness: 0.1 } },
  { target: 'Demand', source: 'inventory', lineStyle: { curveness: 0.1 } },
  { target: 'Dependence\non foreign\nmanufacturing', source: 'Components\nSupplier', lineStyle: { curveness: 0.1 } },
  { target: 'Dependence\non foreign\nmanufacturing', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'Earthquake', source: 'Fabrication', lineStyle: { curveness: 0.1 } },
  { target: 'COVID 19', source: 'Demand', lineStyle: { curveness: 0.1 } },
  { target: 'COVID 19', source: 'International\nTrade', lineStyle: { curveness: 0.1 } },

];
