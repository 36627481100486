import {
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { HomeStore } from 'src/app/core/stores/home-store';
import { COMPONENTS, DEFAULT_COMPONENT } from './components-catalog';

@Component({
  selector: 'dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.css'],
})
export class DynamicViewComponent implements OnDestroy {
  @ViewChild('dynComponent', { read: ViewContainerRef })
  dynComponent!: ViewContainerRef;
  private subscriber;
  constructor(private homeStore: HomeStore) {
    this.subscriber = this.homeStore.appContextObservable.subscribe((ctx) => {
      if (ctx.detail) {
        let decodedDetail = decodeURIComponent(ctx.detail);

        decodedDetail = ctx.dash+'/'+ctx.section.url+'/'+ctx.detail;

        const component = COMPONENTS[decodedDetail] || DEFAULT_COMPONENT;
        
        this.dynComponent.clear();
        this.dynComponent.createComponent(component);
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
