import { AfterViewInit, Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css']
})
export class GaugeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() data!: any;
  private root!: am5.Root;
  private chart!: am5radar.RadarChart;
  constructor() { }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngAfterViewInit(): void {
    this.root = am5.Root.new(this.chartEl.nativeElement);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5radar.RadarChart.new(this.root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      innerRadius: am5.percent(20),
      startAngle: -90,
      endAngle: 180
    }));



    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    let cursor = this.chart.set("cursor", am5radar.RadarCursor.new(this.root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    let xRenderer = am5radar.AxisRendererCircular.new(this.root, {
      //minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10,

    });

    xRenderer.grid.template.setAll({
      forceHidden: false,

    });

    let xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: xRenderer,
      min: 0,
      max: 50,
      strictMinMax: true,
      numberFormat: "#'%'",

    }));


    let yRenderer = am5radar.AxisRendererRadial.new(this.root, {
      minGridDistance: 5
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontSize: 8,
      fontWeight: "900"
    });

    yRenderer.grid.template.setAll({
      forceHidden: false,
      location: 0,
      strokeOpacity: 0
    });

    let yAxis = this.chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: "category",
      renderer: yRenderer
    }));

    yAxis.data.setAll(this.data.result);


    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    let series1 = this.chart.series.push(am5radar.RadarColumnSeries.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "full",
      categoryYField: "category",
      fill: this.root.interfaceColors.get("alternativeBackground")
    }));

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20
    });

    series1.data.setAll(this.data.result);


    let series2 = this.chart.series.push(am5radar.RadarColumnSeries.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "value",
      categoryYField: "category"
    }));

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}: {valueX}%",
      cornerRadius: 20,
      templateField: "columnSettings"
    });

    series2.data.setAll(this.data.result);

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    series2.appear(1000);
    this.chart.appear(1000, 100);
  }



  ngOnInit(): void {
  }

}
