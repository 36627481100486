import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-heatmap-template',
  templateUrl: './dynamic-heatmap-template.component.html',
  styleUrls: ['./dynamic-heatmap-template.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicHeatmapTemplateComponent implements OnDestroy, OnChanges {
  @Input() data!: any;
  @Input() title: string = '';
  @Output() selectedItem = new EventEmitter<number>();

  public newData: any;
  public dates: string[] = [];
  private years: number[] = [];
  private fullDates: string[] = [];
  private size: number = 5;
  private index: number = 0;
  private maxCount: number = 3;
  onDestroy$: Subject<boolean> = new Subject();
  constructor(
    private apiService: ApiServiceService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.size = this.data.step;
      this.fullDates = JSON.parse(JSON.stringify(this.data.dates));
      this.maxCount = this.data.maxCount;
      this.dates.push(this.data.dates.at(0));
      this.years.push(new Date(this.dates[0]).getFullYear());
      this.getFirstData();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
  getData(model: any) {
    const copyDates: string[] = JSON.parse(JSON.stringify(this.fullDates));
    this.dates = copyDates.filter((item: any) =>
      this.years.includes(new Date(item).getFullYear())
    );

    this.apiService
      .post(this.data.shapeUrl, model)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (res: any) => {
          let oldData: any;
          if (this.newData !== undefined) {
            oldData = JSON.parse(JSON.stringify(this.newData));
          }

          const newData_: any = {
            data: ([] = []),
          };
          if (oldData !== undefined) {
            oldData.data.forEach((item: any) => {
              newData_.data.push(item);
            });
          }
          res.data.forEach((item: any) => {
            newData_.data.push(item);
          });

          newData_.data = newData_.data.filter((item: any) =>
            this.years.includes(new Date(item.date).getFullYear())
          );
          this.newData = newData_;
          this.spinner.hide();
        },
        error: (e: any) => {
          console.error(e);
          this.spinner.hide();
        },
      });
  }
  getFirstData() {
    this.spinner.show();
    if (this.index >= this.dates.length) {
      this.spinner.hide();
      return;
    }
    const copyDates: string[] = JSON.parse(JSON.stringify(this.dates));
    const selectedDates = copyDates.splice(this.index, this.size);
    if (!selectedDates.length) {
      this.spinner.hide();
      return;
    }
    const model = {
      FirstDate: selectedDates.at(0),
      LastDate: selectedDates.at(-1),
    };
    this.getData(model);
  }
  getNextDate() {
    this.spinner.show();

    this.index += this.size;
    if (this.index >= this.fullDates.length) {
      this.index -= this.size;
      this.spinner.hide();
      return;
    }

    const copyDates: string[] = JSON.parse(JSON.stringify(this.fullDates));
    const selectedDates = copyDates.splice(this.index, this.size);
    let dateHelper: boolean = true;
    selectedDates.forEach((date: string) => {
      const newYear = new Date(date).getFullYear();
      if (!this.years.includes(newYear)) {
        this.years.push(newYear);
        dateHelper = false;
        return;
      }
    });
    if (dateHelper) {
      this.spinner.hide();
      return;
    }

    this.years.sort((a, b) => {
      return a - b;
    });

    while (this.years.length > this.maxCount) {
      this.years.shift();
    }
    const model = {
      FirstDate: selectedDates.at(0),
      LastDate: selectedDates.at(-1),
    };
    this.getData(model);
  }
  getPreviousDate() {
    this.spinner.show();

    this.index -= this.size;
    if (this.index < 0) {
      this.index += this.size;
      this.spinner.hide();
      return;
    }

    const copyDates: string[] = JSON.parse(JSON.stringify(this.fullDates));
    const selectedDates = copyDates.splice(this.index, this.size);
    let dateHelper: boolean = true;
    selectedDates.forEach((date: string) => {
      const newYear = new Date(date).getFullYear();
      if (!this.years.includes(newYear)) {
        this.years.push(newYear);
        dateHelper = false;
        return;
      }
    });
    if (dateHelper) {
      this.spinner.hide();
      return;
    }

    this.years.sort((a, b) => {
      return a - b;
    });

    while (this.years.length > this.maxCount) {
      this.years.pop();
    }
    const model = {
      FirstDate: selectedDates.at(0),
      LastDate: selectedDates.at(-1),
    };
    this.getData(model);
  }

  setIndex(currentTme: string) {
    this.index = this.fullDates.indexOf(currentTme);
  }

  openStudyDialog() {
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        filterInfo: this.data.filterInfo,
        selectedInfo: this.data.selectedInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.data.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }
}
