import { GraphItemModel, GraphLinkModel } from "../models/graph.model";


/* Categories */
/*
export const PharmaGraphCategory: any[] = [
{ name: 'Anthropogenic', itemStyle: { color: '#006483' } }, 
{ name: 'Raw Materials / Sources', itemStyle: { color: '#c17a2f' } },
{ name: 'Natural', itemStyle: { color: '#438bca' } },
{ name: 'Suppliers', itemStyle: { color: '#2b0171' } },
{ name: 'Transportation / Logistic', itemStyle: { color: '#500101' } },
{ name: 'Manufacturer / Production', itemStyle: { color: '#707070' } },
{ name: 'WholeSealers / Retailers', itemStyle: { color: '#5a6237' } },
{ name: 'Customers', itemStyle: { color: '#b0b1b1' } },
{ name: 'Social', itemStyle: { color: '#ad9d68' } },
{ name: 'Economic', itemStyle: { color: '#ad9d68' } },
{ name: 'Environmental', itemStyle: { color: '#ad9d68' } },
{ name: 'Critical Infrastructure', itemStyle: { color: '#4966b0' } },
];*/
export const PharmaGraphCategory: any[] = [
  { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
  { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
  { id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
  { id: 3,name: 'Water Sources asd2', itemStyle: { color: '#ff9900' } },
  { id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
  { id: 5,name: 'Logistic / Distribution ad2', itemStyle: { color: '#500101' } },
  { id: 6,name: 'Production das2', itemStyle: { color: '#707070' } },
  { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
  { id: 8,name: 'Logistic qwe2', itemStyle: { color: '#9d1010' } },
  { id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
  { id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
  { id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
  { id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
  { id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
  { id: 14,name: 'Logistic', itemStyle: { color: '#993365' } },
  { id: 15, name: 'Production', itemStyle: { color: '#a0a0a0' } },
  { id: 16,name: 'Wholesalers / Retailers', itemStyle: { color: '#ccffcc' } },
];
export const PharmaGraphCategoryLink: any[] = [
  { name: 'Threats', links: [PharmaGraphCategory[0], PharmaGraphCategory[2], PharmaGraphCategory[11]]},
  { name: 'Systems', links: [PharmaGraphCategory[1], PharmaGraphCategory[3], 
        PharmaGraphCategory[4], PharmaGraphCategory[5],PharmaGraphCategory[6],
        PharmaGraphCategory[7]
    ]},
  { name: 'State of Risk', links: [PharmaGraphCategory[8], PharmaGraphCategory[9], 
      PharmaGraphCategory[10]
    ]}
]
/*End categories */


/*NODES*/
export const PharmaGraphItems: GraphItemModel[] = [

  //#region Anthropogenic
  { fullname: 'raw material characteristics', name: 'raw material\ncharacteristics', symbol: 'circle', x: -20, y: -20, category: 0, isActive: false },
  { fullname: 'Foreign Regulations', name: 'Foreign\nRegulations', symbol: 'circle', x: -20, y: -15, category: 0, isActive: false },
  { fullname: 'Regulations', name: 'Regulations', symbol: 'circle', x: -20, y: -10, category: 0, isActive: false },
  { fullname: 'War', name: 'War', symbol: 'circle', x: -20, y: -5, category: 0, isActive: false },
  { fullname: 'Man-made Disaster', name: 'Man-made\nDisaster', symbol: 'circle', x: -20, y: 0, category: 0, isActive: false },
  { fullname: 'Cyber Attack', name: 'Cyber\nAttack', symbol: 'circle', x: -20, y: 5, category: 0, isActive: false },
  { fullname: 'Water Shortage', name: 'Water\nShortage', symbol: 'circle', x: -20, y: 10, category: 0, isActive: false },
  
  { fullname: 'High logistics and inventory cost', name: 'High\nlogistics\nand\ninventory\ncost', symbol: 'circle', x: -13, y: -20, category: 0, isActive: false },
  { fullname: 'High Disposal Costs', name: 'High\nDisposal\nCosts', symbol: 'circle', x: -13, y: -15, category: 0, isActive: false },
  { fullname: 'Higher Interest Rate', name: 'Higher\nInterest\nRate', symbol: 'circle', x: -13, y: -10, category: 0, isActive: false },
  { fullname: 'CO2 Emissions', name: 'CO2\nEmissions', symbol: 'circle', x: -13, y: -5, category: 0, isActive: false },
  { fullname: 'COVID-19 Pandemic', name: 'COVID-19\nPandemic', symbol: 'circle', x: -13, y: 0, category: 0, isActive: false },
  { fullname: 'Outdated Technology', name: 'Outdated\nTechnology', symbol: 'circle', x: -13, y: 5, category: 0, isActive: false },
  { fullname: 'Regional Concentration of Suppliers (Single\nSourcing)', name: 'Regional\nConcentration\nof Suppliers\n(Single\nSourcing)', symbol: 'circle', x: -13, y: 14, category: 0, isActive: false },
  
  { fullname: 'High capital costs', name: 'High\ncapital\ncosts', symbol: 'circle', x: -6, y: -20, category: 0, isActive: true },
  { fullname: 'Labor Shortage (L)', name: 'Labor\nShortage (L)', symbol: 'circle', x: -6, y: -15, category: 0, isActive: false },
  { fullname: 'Public health', name: 'Public\nhealth', symbol: 'circle', x: -6, y: -10, category: 0, isActive: false },
  { fullname: 'Inflation', name: 'Inflation', symbol: 'circle', x: -6, y: -5, category: 0, isActive: false },
  { fullname: 'Demand Shock', name: 'Demand\nShock', symbol: 'circle', x: -6, y: 0, category: 0, isActive: false },
  { fullname: 'Transportation Disruptions', name: 'Transportation\nDisruptions', symbol: 'circle', x: -6, y: 5, category: 0, isActive: false },
  { fullname: 'Power Outages', name: 'Power\nOutages', symbol: 'circle', x: -6, y: 10, category: 0, isActive: false },
  
  { fullname: 'High Price: Supply', name: 'High Price:\nSupply', symbol: 'circle', x: 1.75, y: -20, category: 0, isActive: false },
  { fullname: 'Labor Shortage (RM)', name: 'Labor\nShortage (RM)', symbol: 'circle', x: 1.75, y: -15, category: 0, isActive: false },
  { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage (S)', symbol: 'circle', x: 1.75, y: -10, category: 0, isActive: false },
  { fullname: 'Labor Shortage (R)', name: 'Labor\nShortage (R)', symbol: 'circle', x: 1.75, y: -5, category: 0, isActive: false },
  { fullname: 'Labor Shortage (M)', name: 'Labor\nShortage (M)', symbol: 'circle', x: 1.75, y: 0, category: 0, isActive: false },
  { fullname: 'High Dependence on Foreign Suppliers', name: 'High\nDependence\non Foreign\nSuppliers', symbol: 'circle', x: 1.75, y: 7, category: 0, isActive: false },
 //#endregion
 
  //#region Raw Material / Sources
  { fullname: 'Industrial byproducts', name: 'Industrial\nbyproducts', symbol: 'circle', x: 10, y: -15, category: 1, isActive: false },
  { fullname: 'Natural resources', name: 'Natural\nresources', symbol: 'circle', x: 10, y: -10, category: 1, isActive: false },
  { fullname: 'Quarrying / Mining', name: 'Quarrying/\nMining', symbol: 'circle', x: 10, y: -5, category: 1, isActive: true },
  { fullname: 'Water', name: 'Water', symbol: 'circle', x: 10, y: 0, category: 1, isActive: false },
  
  { fullname: 'Information Technology', name: 'Information\nTechnology', symbol: 'circle', x: 18, y: -15, category: 1, isActive: true },
  { fullname: 'Salts', name: 'Salts', symbol: 'circle', x: 15, y: -10, category: 1, isActive: false },
  { fullname: 'Minerals', name: 'Minerals', symbol: 'circle', x: 15, y: -5, category: 1, isActive: false },
  { fullname: 'Natural Gas', name: 'Natural\nGas', symbol: 'circle', x: 15, y: 0, category: 1, isActive: true },
  
  { fullname: 'Raw Material Availability', name: 'Raw\nMaterial\nAvailability', symbol: 'circle', x: 20, y: -7, category: 1, isActive: false },
  //#endregion
 
  //#region Natural
  { fullname: 'Extreme Weather (Cold)', name: 'Extreme\nWeather (Cold)', symbol: 'circle', x: -21, y: 20, category: 2, isActive: false },
  { fullname: 'Extreme Weather (Hot)', name: 'Extreme\nWeather (Hot)', symbol: 'circle', x: -21, y: 25, category: 2, isActive: false },
  
  { fullname: 'Drought', name: 'Drought', symbol: 'circle', x: -15, y:20, category: 2, isActive: true },
  { fullname: 'Tsunami', name: 'Tsunami', symbol: 'circle', x: -15, y:25, category: 2, isActive: false },
  
  { fullname: 'Landslides', name: 'Landslides', symbol: 'circle', x: -10, y:20, category: 2, isActive: false },
  { fullname: 'Earthquake', name: 'Earthquake', symbol: 'circle', x: -10, y:25, category: 2, isActive: true },
  
  { fullname: 'Hurricane', name: 'Hurricane', symbol: 'circle', x:-5, y:20, category: 2, isActive: true },
  { fullname: 'Flooding', name: 'Flooding', symbol: 'circle', x: -5, y:25, category: 2, isActive: true },
  
  { fullname: 'Wildfire', name: 'Wildfire', symbol: 'circle', x: 0, y: 20, category: 2, isActive: true },
  { fullname: 'Sea Level Rise', name: 'Sea Level\nRise', symbol: 'circle', x: 0, y: 25, category: 2, isActive: false },
  //#endregion
 
  //#region Suppliers
  { fullname: 'Broadband Infrastructure-S', name: 'Broadband\nInfrastructure-S', symbol: 'circle', x: 28, y: -20, category: 3, isActive: false },
  { fullname: 'Industrial gas suppliers', name: 'Industrial\ngas\nsuppliers', symbol: 'circle', x: 28, y: -15, category: 3, isActive: false },
  { fullname: 'Chemical Distributors', name: 'Chemical\nDistributors', symbol: 'circle', x: 28, y: -10, category: 3, isActive: false },
  { fullname: 'Special chemical suppliers', name: 'Special\nchemical\nsuppliers', symbol: 'circle', x: 28, y: -5, category: 3, isActive: false },
  { fullname: 'Mining Companies', name: 'Mining\nCompanies', symbol: 'circle', x: 28, y: 0, category: 3, isActive: false },
  { fullname: 'Petrochemical companies', name: 'Petrochemical\ncompanies', symbol: 'circle', x: 28, y: 5, category: 3, isActive: false },
  
  { fullname: 'Information Technology ', name: 'Information\nTechnology ', symbol: 'circle', x: 37, y: -20, category: 3, isActive: false },
  { fullname: 'R&D', name: 'R&D', symbol: 'circle', x: 37, y: -15, category: 3, isActive: false },
  { fullname: 'Other chemicals', name: 'Other\nchemicals', symbol: 'circle', x: 37, y: -10, category: 3, isActive: false },
  { fullname: 'Halogens', name: 'Halogens', symbol: 'circle', x: 37, y: -6, category: 3, isActive: false },
  { fullname: 'Metals and Metal Compounds', name: 'Metals and\nMetal\nCompounds', symbol: 'circle', x: 37, y: 0, category: 3, isActive: false },
  { fullname: 'Petrochemicals', name: 'Petrochemicals', symbol: 'circle', x: 37, y: 4, category: 3, isActive: false },
  { fullname: 'Acids and Chemical Compounds', name: 'Acids and\nChemical\nCompounds', symbol: 'circle', x: 37, y: 10, category: 3, isActive: false },
  //#endregion
 
  //#region Transportation / Logistic
  { fullname: 'Pipeline', name: 'Pipeline', x: 7, y: 19, category: 4, isActive: false },
  { fullname: 'Trucking', name: 'Trucking', x: 12, y: 19, category: 4, isActive: false },
  { fullname: 'Vessels', name: 'Vessels', x: 17, y: 19, category: 4, isActive: false },
  { fullname: 'Barges', name: 'Barges', x: 22, y: 19, category: 4, isActive: false },

  { fullname: 'Broadband Infrastructure-RM-S', name: 'Broadband\nInfrastructure-RM-S', x: 32, y: 23, category: 4, isActive: false },
  { fullname: 'Information Technology  ', name: 'Information\nTechnology  ', x: 42, y: 23, category: 4, isActive: false },
  
  { fullname: 'Broadband Infrastructure-S-M', name: 'Broadband\nInfrastructure-S-M', x: 54, y: 19, category: 4, isActive: false },
  { fullname: 'Broadband Infrastructure-M-R', name: 'Broadband\nInfrastructure-M-R', x: 64, y: 19, category: 4, isActive: false },
  { fullname: 'Broadband Infrastructure-R-C', name: 'Broadband\nInfrastructure-R-C', x: 74, y: 19, category: 4, isActive: false },
  //#endregion
 
  //#region Manufacterer / Production
  { fullname: 'Information Technology   ', name: 'Information\nTechnology   ', x: 45, y: -20, category: 5, isActive: false },
  { fullname: 'Skilled Labor', name: 'Skilled\nLabor', x: 45, y: -15, category: 5, isActive: false },
  { fullname: 'raw material storage', name: 'raw\nmaterial\nstorage', x: 45, y: -10, category: 5, isActive: false },
  
  { fullname: 'Broadband Infrastructure-M', name: 'Broadband\nInfrastructure-M', x: 54, y: -20, category: 5, isActive: true },
  { fullname: 'Manufacturing Equipment', name: 'Manufacturing\nEquipment', x: 54, y: -15, category: 5, isActive: false },
  { fullname: 'Physical Infrastructure', name: 'Physical\nInfrastructure', x: 54, y: -10, category: 5, isActive: true },
  { fullname: 'Synthesis/Reaction', name: 'Synthesis/\nReaction', x: 54, y: -5, category: 5, isActive: false },
  { fullname: 'Product seperation and purification', name: 'Product\nseperation\nand purification', x: 54, y: 1, category: 5, isActive: false },
  { fullname: 'Landfills', name: 'Landfills', x: 54, y: 5, category: 5, isActive: false },
  
  { fullname: 'In-Plant Logistics', name: 'In-Plant\nLogistics', x: 63, y: -20, category: 5, isActive: false },
  { fullname: 'Quality control', name: 'Quality\ncontrol', x: 63, y: -15, category: 5, isActive: false },
  { fullname: 'Hazardous Waste Infrastructure', name: 'Hazardous\nWaste\nInfrastructure', x: 63, y: -10, category: 5, isActive: false },
  { fullname: 'Packaging', name: 'Packaging', x: 63, y: -5, category: 5, isActive: false },
  
  { fullname: 'Final product storage', name: 'Final\nproduct\nstorage', x: 45, y: 7, category: 5, isActive: false },
  
  { fullname: 'Inorganic chemicals shortage', name: 'Inorganic\nchemicals\nshortage', x: 63, y: 7, category: 5, isActive: false },
  
  //{ fullname: 'Power Source', name: 'Power\nSource', x: 27.5, y: 0.7, category: 5, isActive: true },
  
  //#endregion
 
  //#region WholeSealers / Retailers
  { fullname: 'Broadband Infrastructure-R', name: 'Broadband\nInfrastructure-R', x: 70, y: -20, category: 6, isActive: false },
  { fullname: 'E commerce platforms', name: 'E commerce\nplatforms', x: 70, y: -14, category: 6, isActive: false },
  { fullname: 'Wholesalers', name: 'Wholesalers', x: 70, y: -9, category: 6, isActive: false },
  
  { fullname: 'Information Technology    ', name: 'Information\nTechnology    ', x: 76, y: -17, category: 6, isActive: false },
  { fullname: 'Inorganic Chemical Distributors', name: 'Inorganic\nChemical\nDistributors', x: 76, y: -12, category: 6, isActive: false },

  { fullname: 'Hazardous Waste Infrastructure ', name: 'Hazardous\nWaste\nInfrastructure ', x: 73, y: 0, category: 6, isActive: false },
  //#endregion
 
  //#region Customers
  { fullname: 'Fertilizers', name: 'Fertilizers', x: 85, y: -20, category: 7, isActive: false },
  { fullname: 'Glass and ceramics', name: 'Glass and\nceramics', x: 85, y: -14, category: 7, isActive: false },
  { fullname: 'Explosives Manufacturing', name: 'Explosives\nManufacturing', x: 85, y: -8, category: 7, isActive: false },
  { fullname: 'Semiconductors and Electronics', name: 'Semiconductors\nand\nElectronics', x: 85, y: -2, category: 7, isActive: false },
  
  { fullname: 'Pharmaceuticals', name: 'Pharmaceuticals', x: 92, y: -20, category: 7, isActive: false },
  { fullname: 'Pigments and Dyes', name: 'Pigments and\nDyes', x: 92, y: -15, category: 7, isActive: false },
  { fullname: 'Automotive and Aerospace', name: 'Automotive\nand\nAerospace', x: 92, y: -10, category: 7, isActive: false },
  //#endregion
 
 
 //#region State of risk - Economic
 { fullname: 'Revenue', name: 'Revenue', symbol: 'rect', x: 100, y: -20, category: 8, isActive: false },
 { fullname: 'Reputation', name: 'Reputation', symbol: 'rect', x: 100, y: -10, category: 8, isActive: false },

 { fullname: 'Producer price inflation', name: 'Producer price\ninflation', symbol: 'rect', x: 105, y: -15, category: 8, isActive: false },

 { fullname: 'Exports', name: 'Exports', symbol: 'rect', x: 110, y: -20, category: 8, isActive: false },
 { fullname: 'Equipment failure', name: 'Equipment\nfailure', symbol: 'rect', x: 110, y: -10, category: 8, isActive: false },
 //#endregion
 //#region State of risk - Social
 { fullname: 'Death', name: 'Death', symbol: 'rect', x: 100, y: -5, category: 9, isActive: false },
 { fullname: 'Fire/Explosio nrisk', name: 'Fire/\nExplosion\nrisk', symbol: 'rect', x: 100, y: 5, category: 9, isActive: false },
 { fullname: 'Unemployment', name: 'Unemployment', symbol: 'rect', x: 105, y: 0, category: 9, isActive: false },
 { fullname: 'Worker Safety', name: 'Worker\nSafety', symbol: 'rect', x: 110, y: -5, category: 9, isActive: true },
 //#endregion
 //#region State of risk - Environmental
 { fullname: 'Toxic gases emission', name: 'Toxic\ngases\nemission', symbol: 'rect', x: 100, y: 12, category: 10, isActive: false },
 { fullname: 'Particulate matter', name: 'Particulate\nmatter', symbol: 'rect', x: 100, y: 22, category: 10, isActive: false },
 { fullname: 'Odor and Noise emission', name: 'Odor\nand Noise\nemission', symbol: 'rect', x: 105, y: 15, category: 10, isActive: false },
 { fullname: 'Ground and Surface Water Contamination', name: 'Ground and\nSurface\nWater\nContamination', symbol: 'rect', x: 110, y: 12, category: 10, isActive: false },
 { fullname: 'High Energy Consumption', name: 'High\nEnergy\nConsumption', symbol: 'rect', x: 110, y: 22, category: 20, isActive: false },
 //#endregion

 //#region Critical infrastructure
 { fullname: 'Waste water', name: 'Waste\nwater', x: -27, y: 33, category: 11, isActive: false },
 { fullname: 'Drinking water', name: 'Drinking\nwater', x: -27, y: 38, category: 11, isActive: false },
 { fullname: 'Public Transportation', name: 'Public\nTransportation', x: -27, y: 43, category: 11, isActive: false },
 
 { fullname: 'Stormwater Infrastructure-RM', name: 'Stormwater\nInfrastructure-RM', x: -20, y: 33, category: 11, isActive: false },
 { fullname: 'Electricy', name: 'Electricy', x: -20, y: 38, category: 11, isActive: false },
 
 { fullname: 'Levees', name: 'Levees', x: -13, y: 33, category: 11, isActive: false },
 { fullname: 'Fuel', name: 'Fuel', x: -13, y: 38, category: 11, isActive: false },
 
 { fullname: 'Stormwater Infrastructure-RM-S', name: 'Stormwater\nInfrastructure-RM-S', x: -4, y: 33, category: 11, isActive: false },
 { fullname: 'Ports Infrastructure', name: 'Ports\nInfrastructure', x: -4, y: 38, category: 11, isActive: false },
 
 { fullname: 'Railroads Infrastructure', name: 'Railroads\nInfrastructure', x: 6, y: 33, category: 11, isActive: false },
 { fullname: 'Bridges Infrastructure', name: 'Bridges\nInfrastructure', x: 6, y: 38, category: 11, isActive: false },
 
 { fullname: 'Wastewater Infrastructure-S', name: 'Wastewater\nInfrastructure-S', x: 16, y: 33, category: 11, isActive: false },
 { fullname: 'Stormwater Infrastructure-S', name: 'Stormwater\nInfrastructure-S', x: 16, y: 38, category: 11, isActive: false },
 
 { fullname: 'Aviation Infrastructure', name: 'Aviation\nInfrastructure', x: 25, y: 33, category: 11, isActive: false },
 { fullname: 'Stormwater Infrastructure-S-M', name: 'Stormwater\nInfrastructure-S-M', x: 25, y: 38, category: 11, isActive: false },
 
 { fullname: 'Roads Infrastructure', name: 'Roads\nInfrastructure', x: 38, y: 33, category: 11, isActive: false },
 { fullname: 'Inland waterways Infrastructure', name: 'Inland\nwaterways\nInfrastructure', x: 38, y: 38, category: 11, isActive: false },
 { fullname: 'Wastewater Infrastructure-M', name: 'Wastewater\nInfrastructure-M', x: 38, y: 42, category: 11, isActive: false },
 
 { fullname: 'Solid Waste Infrastructure-M', name: 'Solid\nWaste\nInfrastructure-M', x: 47, y: 33, category: 11, isActive: false },
 { fullname: 'Stormwater Systems-M', name: 'Stormwater\nSystems-M', x: 47, y: 38, category: 11, isActive: false },

 { fullname: 'Stormwater Infrastructure-M-R', name: 'Stormwater\nInfrastructure-M-R', x: 60, y: 33, category: 11, isActive: false },
 
 { fullname: 'Solid Waste Infrastructure-R', name: 'Solid\nWaste\nInfrastructure-R', x: 60, y: 40, category: 11, isActive: false },
 
 { fullname: 'Stormwater Infrastructure-R-C', name: 'Stormwater\nInfrastructure-R-C', x: 70, y: 33, category: 11, isActive: false },

 //#endregion
 
 { fullname: 'invisible', name: 'invisible', symbol: 'circle', x: 50, y: 15, itemStyle: { color: '#fff', borderWidth: 0 }, label: { color: '#fff' }, isActive: true },
 
 ];
/*End Nodes*/

 
/* Links */
export const PharmaGraphLinks: GraphLinkModel[] = [
  
  { source: 'Acids and\nChemical\nCompounds', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Acids and\nChemical\nCompounds', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Acids and\nChemical\nCompounds', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Acids and\nChemical\nCompounds', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Acids and\nChemical\nCompounds', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Automotive\nand\nAerospace', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Fertilizers', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Glass and\nceramics', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Pharmaceuticals', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Pigments and\nDyes', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Semiconductors\nand\nElectronics', lineStyle: { curveness: 0.1 } },
  { source: 'Aviation\nInfrastructure', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Automotive\nand\nAerospace', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Explosives\nManufacturing', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Glass and\nceramics', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Pharmaceuticals', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Pigments and\nDyes', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Semiconductors\nand\nElectronics', lineStyle: { curveness: 0.1 } },
  { source: 'Barges', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Stormwater\nInfrastructure-R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Stormwater\nInfrastructure-S-M', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Stormwater\nInfrastructure-M-R', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges\nInfrastructure', target: 'Stormwater\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-M', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-M-R', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-R', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-R-C', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-RM', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-RM-S', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-S', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure-S-M', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },

  { source: 'Chemical\nDistributors', target: 'Acids and\nChemical\nCompounds', lineStyle: { curveness: 0.1 } },
  { source: 'Chemical\nDistributors', target: 'Metals and\nMetal\nCompounds', lineStyle: { curveness: 0.1 } },
  { source: 'Chemical\nDistributors', target: 'Other\nchemicals', lineStyle: { curveness: 0.1 } },
  { source: 'Chemical\nDistributors', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'CO2\nEmissions', target: 'Extreme\nWeather (Cold)', lineStyle: { curveness: 0.1 } },
  { source: 'CO2\nEmissions', target: 'Extreme\nWeather (Hot)', lineStyle: { curveness: 0.1 } },
  { source: 'CO2\nEmissions', target: 'Hurricane', lineStyle: { curveness: 0.1 } },
  { source: 'CO2\nEmissions', target: 'Sea Level\nRise', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Demand\nShock', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'COVID-19\nPandemic', target: 'Inflation', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nAttack', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nAttack', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Demand\nShock', target: 'High\nlogistics\nand\ninventory\ncost', lineStyle: { curveness: 0.1 } },
  { source: 'Demand\nShock', target: 'Inorganic\nchemicals\nshortage', lineStyle: { curveness: 0.1 } },
  { source: 'Demand\nShock', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Drinking\nwater', target: 'Public\nhealth', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Water\nShortage', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Wildfire', lineStyle: { curveness: 0.1 } },
  { source: 'Drought', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'E commerce\nplatforms', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'E commerce\nplatforms', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'E commerce\nplatforms', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'E commerce\nplatforms', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'E commerce\nplatforms', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Landslides', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Tsunami', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Bridges\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-M-R', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure--R', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure--R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-RM', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Broadband\nInfrastructure-S-M', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'High\nEnergy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Industrial\nbyproducts', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Inland\nwaterways\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Inland\nwaterways\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Inland\nwaterways\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Natural\nGas', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Quarrying/\nMining', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Roads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Water', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Cold)', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Extreme\nWeather (Hot)', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },

  { source: 'Fertilizers', target: 'Producer price\ninflation', lineStyle: { curveness: 0.1 } },
  { source: 'Final\nproduct\nstorage', target: 'Inorganic\nchemicals\nshortage', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-M-R', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-S-M', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-RM', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Stormwater\nSystems-M', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Hazardous\nWaste\nInfrastructure -M', lineStyle: { curveness: 0.1 } },
  { source: 'Flooding', target: 'Hazardous\nWaste\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'Foreign\nRegulations', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'In-Plant\nLogistics', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Quarrying/\nMining', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Manufacturing\nEquipment', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel ', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel ', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel ', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel ', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Ground and\nSurface\nWater\nContamination', target: 'Stormwater\nInfrastructure-R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Ground and\nSurface\nWater\nContamination', target: 'Stormwater\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Halogens', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Halogens', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Halogens', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Halogens', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Halogens', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste\nInfrastructure -M', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste\nInfrastructure-R', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste\nInfrastructure-R', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste\nInfrastructure-R', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste\nInfrastructure-R', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'High\ncapital\ncosts', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'High\ncapital\ncosts', target: 'R&D', lineStyle: { curveness: 0.1 } },
  { source: 'High\nDependence\non Foreign\nSuppliers', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'High\nDisposal\nCosts', target: 'Solid\nWaste\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'High\nDisposal\nCosts', target: 'Solid\nWaste\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'High\nDisposal\nCosts', target: 'Wastewater\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'High\nDisposal\nCosts', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'High\nlogistics\nand\ninventory\ncost', target: 'Final\nproduct\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'High\nlogistics\nand\ninventory\ncost', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'High Price:\nSupply', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Higher\nInterest\nRate', target: 'R&D', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Flooding', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Landslides', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Hazardous\nWaste\nInfrastructure -M', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Hazardous\nWaste\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Hurricane', target: 'Levees', lineStyle: { curveness: 0.1 } },

  { source: 'Industrial\nbyproducts', target: 'Salts', lineStyle: { curveness: 0.1 } },
  { source: 'Industrial\ngas\nsuppliers', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'Industrial\ngas\nsuppliers', target: 'Halogens', lineStyle: { curveness: 0.1 } },
  { source: 'Inflation', target: 'Higher\nInterest\nRate', lineStyle: { curveness: 0.1 } },
  { source: 'Inflation', target: 'Demand\nShock', lineStyle: { curveness: 0.1 } },
  { source: 'Inflation', target: 'High Price:\nSupply', lineStyle: { curveness: 0.1 } },
  { source: 'Inflation', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Inflation', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Bridges\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Bridges\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Bridges\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'In-Plant\nLogistics', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Skilled\nLabor', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Quality\ncontrol', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Quarrying/\nMining', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Pipeline', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Inland\nwaterways\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Inland\nwaterways\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Ports\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Roads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Roads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology   ', target: 'Roads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology ', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology ', target: 'Bridges\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology ', target: 'Inland\nwaterways\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology ', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Information\nTechnology ', target: 'Roads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure ', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure ', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure ', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nwaterways\nInfrastructure ', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nChemical\nDistributors', target: 'Producer price\ninflation', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Exports', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Revenue', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Unemployment', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Inorganic\nchemicals\nshortage', target: 'Producer price\ninflation', lineStyle: { curveness: 0.1 } },
  { source: 'In-Plant\nLogistics', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },

  { source: 'Labor\nShortage (L)', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (L)', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (R)', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (R)', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (R)', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (RM)', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (RM)', target: 'Quarrying/\nMining', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Landfills', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Landslides', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Levees', target: 'Wastewater\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'Levees', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'Levees', target: 'Water', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Man-made\nDisaster', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Manufacturing\nEquipment', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Metals and\nMetal\nCompounds', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Metals and\nMetal\nCompounds', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Metals and\nMetal\nCompounds', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Metals and\nMetal\nCompounds', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Metals and\nMetal\nCompounds', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Minerals', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Mining\nCompanies', target: 'Metals and\nMetal\nCompounds', lineStyle: { curveness: 0.1 } },
  { source: 'Mining\nCompanies', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },

  { source: 'Natural\nGas', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Fuel ', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Pipeline', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nGas', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nresources', target: 'Salts', lineStyle: { curveness: 0.1 } },
  { source: 'Natural\nresources', target: 'Water', lineStyle: { curveness: 0.1 } },
  { source: 'Other\nchemicals', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Other\nchemicals', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Other\nchemicals', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Other\nchemicals', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Other\nchemicals', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Outdated\nTechnology', target: 'R&D', lineStyle: { curveness: 0.1 } },
  { source: 'Outdated\nTechnology', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Packaging', target: 'Final\nproduct\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemical\ncompanies', target: 'Petrochemicals', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemical\ncompanies', target: 'Wastewater\nInfrastructure-S', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemicals', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemicals', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemicals', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemicals', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Petrochemicals', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Pharmaceuticals', target: 'Producer price\ninflation', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nInfrastructure', target: 'Equipment\nfailure', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nInfrastructure', target: 'Final\nproduct\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nInfrastructure', target: 'Packaging', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nInfrastructure', target: 'Product\nseperation\nand purification', lineStyle: { curveness: 0.1 } },
  { source: 'Physical\nInfrastructure', target: 'Synthesis/\nReaction', lineStyle: { curveness: 0.1 } },
  { source: 'Pipeline', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Pipeline', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Ports\nInfrastructure ', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Power\nOutages', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Death', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Fire/\nExplosion\nrisk', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Odor\nand Noise\nemission', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Particulate\nmatter', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Packaging', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Toxic\ngases\nemission', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Hazardous\nWaste\nInfrastructure -M', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Solid\nWaste\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Wastewater\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'Product\nseperation\nand purification', target: 'Worker\nSafety', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nhealth', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nhealth', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nhealth', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nhealth', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nhealth', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Quality\ncontrol', target: 'Reputation', lineStyle: { curveness: 0.1 } },
  { source: 'Quality\ncontrol', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Quarrying/\nMining', target: 'Minerals', lineStyle: { curveness: 0.1 } },

  { source: 'R&D', target: 'Product\nseperation\nand purification', lineStyle: { curveness: 0.1 } },
  { source: 'R&D', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'R&D', target: 'Manufacturing\nEquipment', lineStyle: { curveness: 0.1 } },
  { source: 'R&D', target: 'Quality\ncontrol', lineStyle: { curveness: 0.1 } },
  { source: 'R&D', target: 'Synthesis/\nReaction', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure', target: 'Stormwater\nInfrastructure-S-M', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Railroads\nInfrastructure ', target: 'Stormwater\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Automotive\nand\nAerospace', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Explosives\nManufacturing', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Glass and\nceramics', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Pharmaceuticals', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Pigments and\nDyes', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Semiconductors\nand\nElectronics', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Stormwater\nInfrastructure-M-R', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Stormwater\nInfrastructure-R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Rairoads Infrastructure ', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nAvailability', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nAvailability', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nAvailability', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nAvailability', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nAvailability', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'raw material\ncharacteristics', target: 'Product\nseperation\nand purification', lineStyle: { curveness: 0.1 } },
  { source: 'raw material\ncharacteristics', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'raw\nmaterial\nstorage', target: 'Synthesis/\nReaction', lineStyle: { curveness: 0.1 } },
  { source: 'Regional\nConcentration\nof Suppliers\n(Single\nSourcing)', target: 'High Price:\nSupply', lineStyle: { curveness: 0.1 } },
  { source: 'Regional\nConcentration\nof Suppliers\n(Single\nSourcing)', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Regulations', target: 'Quality\ncontrol', lineStyle: { curveness: 0.1 } },
  { source: 'Regulations', target: 'raw material\ncharacteristics', lineStyle: { curveness: 0.1 } },
  { source: 'Regulations', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure', target: 'Stormwater\nInfrastructure-M-R', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure', target: 'Stormwater\nInfrastructure-R-C', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure ', target: 'Stormwater\nInfrastructure-RM-S', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure ', target: 'Stormwater\nInfrastructure-S-M', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure ', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Roads\nInfrastructure ', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Salts', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Sea Level\nRise', target: 'Hazardous\nWaste\nInfrastructure -M', lineStyle: { curveness: 0.1 } },
  { source: 'Sea Level\nRise', target: 'Hazardous\nWaste\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'Sea Level\nRise', target: 'Levees', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor', target: 'Final\nproduct\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor', target: 'Packaging', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor', target: 'Product\nseperation\nand purification', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor', target: 'Synthesis/\nReaction', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-M', target: 'Landfills', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-M', target: 'Revenue', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-M', target: 'Worker\nSafety', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'Landfills', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'Revenue', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Solid\nWaste\nInfrastructure-R', target: 'Worker\nSafety', lineStyle: { curveness: 0.1 } },
  { source: 'Special\nchemical\nsuppliers', target: 'Metals and\nMetal\nCompounds', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-M-R', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-R', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-R', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-R', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-R', target: 'Hazardous\nWaste\nInfrastructure-R', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-R', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S-M', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-RM', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-RM', target: 'Natural\nresources', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-RM', target: 'Quarrying/\nMining', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure-S', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nSystems-M', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nSystems-M', target: 'Hazardous\nWaste\nInfrastructure -M', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nSystems-M', target: 'Wastewater\nInfrastructure-M', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nSystems-M', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Synthesis/\nReaction', target: 'Death', lineStyle: { curveness: 0.1 } },
  { source: 'Synthesis/\nReaction', target: 'Fire/\nExplosion\nrisk', lineStyle: { curveness: 0.1 } },
  { source: 'Synthesis/\nReaction', target: 'Product\nseperation\nand purification', lineStyle: { curveness: 0.1 } },
  { source: 'Synthesis/\nReaction', target: 'Worker\nSafety', lineStyle: { curveness: 0.1 } },

  { source: 'Transportation\nDisruptions', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Pipeline', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Public\nTransportation', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Railroads\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Railroads\nInfrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Industrial\nbyproducts', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Vessel', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Transportation\nDisruptions', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Automotive\nand\nAerospace', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Explosives\nManufacturing', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Fertilizers', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Glass and\nceramics', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Pharmaceuticals', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Pigments and\nDyes', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Semiconductors\nand\nElectronics', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Labor\nShortage (L)', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Labor\nShortage (M)', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Labor\nShortage (R)', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Labor\nShortage (RM)', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Labor\nShortage (S)', lineStyle: { curveness: 0.1 } },
  { source: 'Tsunami', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Vessel', target: 'raw\nmaterial\nstorage', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Automotive\nand\nAerospace', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Chemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'E commerce\nplatforms', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Explosives\nManufacturing', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Fertilizers', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Glass and\nceramics', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Industrial\ngas\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Inorganic\nChemical\nDistributors', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Mining\nCompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Petrochemical\ncompanies', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Pharmaceuticals', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Pigments and\nDyes', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Semiconductors\nand\nElectronics', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Special\nchemical\nsuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels', target: 'Wholesalers', lineStyle: { curveness: 0.1 } },
  { source: 'War', target: 'High\nDependence\non Foreign\nSuppliers', lineStyle: { curveness: 0.1 } },
  { source: 'War', target: 'Demand\nShock', lineStyle: { curveness: 0.1 } },
  { source: 'Waste\nwater', target: 'Drinking\nwater', lineStyle: { curveness: 0.1 } },
  { source: 'Waste\nwater', target: 'Public\nhealth', lineStyle: { curveness: 0.1 } },
  { source: 'Wastewater\nInfrastructure-M', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Wastewater\nInfrastructure-S', target: 'Ground and\nSurface\nWater\nContamination', lineStyle: { curveness: 0.1 } },
  { source: 'Water', target: 'Raw\nMaterial\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Water', target: 'Pipeline', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nShortage', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nShortage', target: 'Physical\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nShortage', target: 'Water', lineStyle: { curveness: 0.1 } },
  { source: 'Wholesalers', target: 'Aviation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Wholesalers', target: 'Barges', lineStyle: { curveness: 0.1 } },
  { source: 'Wholesalers', target: 'Rairoads Infrastructure ', lineStyle: { curveness: 0.1 } },
  { source: 'Wholesalers', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Wholesalers', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfire', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfire', target: 'Information\nTechnology', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfire', target: 'Power\nOutages', lineStyle: { curveness: 0.1 } },
  { source: 'Wildfire', target: 'Transportation\nDisruptions', lineStyle: { curveness: 0.1 } },


  ];
