import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { AutomobileEngineRoutingModule } from './automobile-engine.routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductRecallComponent } from './pages/product-recall/product-recall.component';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
  declarations: [
    HomeComponent,
    ProductRecallComponent,
  ],
  imports: [
    CommonModule,
    AutomobileEngineRoutingModule,
    SharedModule,
    MatExpansionModule
  ]
})
export class AutomobileEngineModule { }
