import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';
@Injectable({
  providedIn: 'root'
})
export class GasService {

  constructor(private apiService: ApiServiceService) { }

  getNaturalGasConsumptionCn(model: any): Observable<any> {

    return this.apiService.post(`${ApiRouting.gas}/${ApiRouting.consumption}_cn`, model);
  }
}
