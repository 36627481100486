
<div *ngIf="this.isTri">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-geotiff-template [data]="this.data" (selectedItem)="handleSelectItem($event)" ></app-dynamic-geotiff-template>
    </div>      
</div>
  
<div *ngIf=" !this.isTri">
    <app-not-found></app-not-found>
</div>
  

