import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { ApiRouting } from 'src/app/core/services/apiService/api.routing';


@Component({
  selector: 'app-climate-changes',
  templateUrl: './climate-changes.component.html',
  styleUrls: ['./climate-changes.component.css']
})
export class ClimateChangesComponent implements OnInit{

  public jsonData: string = '';
  constructor(private apiService: ApiServiceService){
    
  }
  ngOnInit(): void {

    
  }
  getData(){
    const model ={
      var1: 3,
      var2: 5
      };
   
    this.apiService.post('APINode/example', model)
    .pipe(take(1))
    .subscribe(
      {
        next: (res:any) => {
          this.jsonData = res;
        },
        error: (e:any) => { console.error(e); }
      }
    );
  }
  
}
