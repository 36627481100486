import {Directive, ElementRef, HostListener, Input, Renderer2, AfterViewInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';

@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit {
  @Input() appScrollSpy!: string; // The section ID or a unique identifier
  private sections: HTMLElement[] = [];
  private currentSection: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) {
  }

  ngAfterViewInit() {
    this.sections = Array.from(document.querySelectorAll(this.appScrollSpy)) as HTMLElement[];

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.onScroll();
      });

    this.onScroll(); // Initialize active class on load
  }

  @HostListener('window:scroll', [])
  onScroll() {
    let activeSection: HTMLElement | null = null;

    this.sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        activeSection = section;
      }
    });

    if (activeSection && activeSection !== this.currentSection) {
      this.currentSection = activeSection;
      this.updateActiveClass();
    }
  }

  private updateActiveClass() {
    const links = Array.from(document.querySelectorAll(`a[href^="#"]`)) as HTMLElement[];

    links.forEach(link => {
      const sectionId = link.getAttribute('href')?.substring(1);
      if (sectionId && sectionId === this.currentSection?.id) {
        this.renderer.addClass(link, 'active');
      } else {
        this.renderer.removeClass(link, 'active');
      }
    });
  }
}
