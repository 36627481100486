<div class="container">
    <div class="xy60">

        <div class="row">
            <div class="col-4">
                <label for="variableTypeFilter" class="form-label subtitle">{{this.filtersRaw.name}}</label>
                <select class="form-select" (change)="onOptionChangeFirst($event)">
                    <option *ngFor="let item of this.typesVar" [value]="item.value">
                        {{item.value}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <label class="form-label subtitle" for="model">Model:</label>
                <div id="model" class="form-check">
                    <input class="form-check-input" type="radio" id="option1" name="model" value="AutoML" (change)="onModelChange($event)" checked>
                    <label class="form-check-label" for="option1">AutoML</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="option2" name="model" value="Partial Least Square" (change)="onModelChange($event)">
                    <label class="form-check-label" for="option2">Partial Least Square</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="option3" name="model" value="Linear Model %2B Probabilistic Calibration" (change)="onModelChange($event)">
                    <label class="form-check-label" for="option3">Linear Model + Probabilistic Calibration</label>
                </div>
            </div>
        </div>
        <div class="container">
        <app-line-two-series-scatter *ngIf="selection != '0'" [data]="this.valuesDataRSPlot"></app-line-two-series-scatter>
        </div>
    </div>
        <app-line-scatter *ngIf="this.hiddenGoodnesOfFit" [idScenario]="this.idScenario" [selection]="this.selection"></app-line-scatter>
</div>

<div *ngIf="this.modelSelection == 'Linear Model %2B Probabilistic Calibration'">
    <div *ngIf="imagePath">
        <div class="row">
          <h3>{{ typeSelection }} Joint Probability Distribution of Model Parameters</h3>
          <img [src]="imagePath" alt="{{ typeSelection }} Joint Probability Distribution of Model Parameters" style="width: 100%">
        </div>
      </div>
</div>

<br>

<div class="row" *ngIf="showRiskScenarioECDF">
    <div class="row">
        <div class="col-2">
            <label for="selectedYear" class="form-label subtitle"><h4>Scenarios Year</h4></label>
                <select class="form-select" (change)="onYearChange($event)">
                    <option *ngFor="let item of this.filterYear" [value]="item">
                        {{item}}
                    </option>
                </select>
        </div>
    </div>

    <div class="row p-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <app-ecdf-chart [Id]="'myTrendChart'" 
                            [data]="this.ecdfChart.data"
                            [hasMinMax]="false"
                            [datasetPosition]="'top'"
                            [datasetAlign]="'center'"
                            [xLabel]="this.ecdfChart.xLabel"
                            [yLabel]="this.ecdfChart.yLabel"
                            [title]="'Risk Scenario ECDF'"></app-ecdf-chart>
        </div>
    </div>

    

</div>


