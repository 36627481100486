
<!--INFORMACION DE CABECERA-->
<div class="card">
    <div class="card-body">
      <div class="row gap-1">
        <div class="location">{{this.dataRes.data.metadata.name}}</div>
        <p>
          {{this.dataRes.data.metadata.description}}
        </p>
      </div>
  
      <div class="subtitle2 pt-2">Metadata</div>
      <div class="row">
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span>
              {{this.dataRes.data.metadata.classification == "" ? "N/A" : this.dataRes.data.metadata.classification}}</li>
            <li class="list-group-item"><span class="subtitle">Type of Evidence:</span>
              {{this.dataRes.data.metadata.evidenceType}}</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Temporal Resolution:</span>
              {{this.dataRes.data.metadata.temporalResolution}}</li>
            <li class="list-group-item"><span class="subtitle">Time Period:</span>
              {{this.dataRes.data.metadata.timePeriod}}</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.dataRes.data.metadata.source}}
            </li>
          </ul>
        </div>
      </div>
    </div>
</div>

  <!--TAB OPTIONS-->
  <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
        aria-controls="home" aria-selected="true">
        <span class="textMedium">Metadata & Visualization</span>
      </button>
    </li>
  </ul>
  <!--TABS CONTENTS-->
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <!--PRIMERA LINEA DE CONTENEDORES-->
      <div class="row" >
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
              <app-graph-bar-line [data]="this.pieChart" [barId]="'bar-data'"></app-graph-bar-line>
          </div>
        </div>
      </div>
      <!--SEGUNDA LINEA DE CONTENEDORES-->
    <div class="row p-2">
        <!--PRIMERA COLUMNA-->
        <div class="col-sm-6 col-md-6 col-lg-6">
          <!--PRIMERA GRAFICA-->
          <div class="card" *ngIf="this.ecdfChart != undefined">
            <div *ngIf="showAlertEcdf; else elseBlockEcdf">
              <br>
              <h3>Due to the data, it's not possible to generate a correct ECDF plot to show.</h3>
            </div>
            <ng-template #elseBlockEcdf>
              <app-ecdf-chart [data]="ecdfChart.data" [hasMinMax]="false" [datasetPosition]="'top'"
                [datasetAlign]="'center'" [xLabel]="ecdfChart.xLabel" [yLabel]="ecdfChart.yLabel"
                [title]="ecdfChart.title" [um]="dataRes.data.yAxisName"> 
              </app-ecdf-chart>
            </ng-template>
          </div>
        </div>
        <!--SEGUNDA COLUMNA-->
        <div class="col-sm-6 col-md-6 col-lg-6">
          <!--SEGUNDA GRAFICA-->
          <div class="card" *ngIf="this.histogram != undefined">
            <div *ngIf="showAlertEcdf; else elseBlockHistogram">
              <br>
              <h3>Due to the data, it's not possible to generate a correct Histogram to show.</h3>
            </div>
            <ng-template #elseBlockHistogram>
              <app-d3-histogram [um]="dataRes.data.yAxisName" [data]="this.histogram.data" [plusMax]="this.dataRes.plusMax"
                [title]="this.histogram.title" [xLabel]="this.histogram.xLabel" [yLabel]="this.histogram.yLabel"
                [Id]="'histogram-county'"></app-d3-histogram>
            </ng-template>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  
