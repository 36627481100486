import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { InorganicChemicalService } from 'src/app/core/services/InorganicChemicalService/inorganic-chemical.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-mineral-resources',
  templateUrl: './mineral-resources.component.html',
  styleUrls: ['./mineral-resources.component.css']
})
export class MineralResourcesComponent {
  public data: any;
  public typeMap: any;
  
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();

  constructor(private inorganicChemicalService: InorganicChemicalService, 
    private homeStore: HomeStore, 
    private spinner: NgxSpinnerService) {
      if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
      else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
      else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
      else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }

    if(this.filter != undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
      this.select = result ? result.itemId : null;
      this.filterInfo = this.filter;
      this.hasStudyButton = true;
    }
  }
  
  getDataUs() {
    const  dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#000000',
      color: '#7c7c7c',
      fillColor: '#FFFFFF'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };

    this.inorganicChemicalService.getMineralResourcesUS()
    .subscribe(
      {
        next: (res:any) => {

          this.data = {
              data: res,
              formatInfo: formatInfo,
              metadata: res.metadata, 
              mapInfo: dataMap,
              filterInfo:this.filterInfo,
              selectedInfo:this.select,
              hasStudyButton:this.hasStudyButton,
              typeMap: "Static"
            };
            this.spinner.hide();
        },
        error: (error:any) => {this.spinner.hide(); console.error(error);}
      });
    
    
  }

  //MX Region
  getDataMx() {
    this.spinner.hide();
  }

  //CN Region
  getDataCN() {
      this.spinner.hide();
  }

  //Tri Region
  getDataTri(){
    this.spinner.hide();
  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }
}

