import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom, ShapeMapDataLink } from 'src/app/core/enums/map.enum';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-earthquake',
  templateUrl: './earthquake.component.html',
  styleUrls: ['./earthquake.component.css']
})
export class EarthquakeComponent {
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;

  constructor(private transboundaryWaterService: TransboundaryWaterServiceService,
    private homeStore: HomeStore, private spinner: NgxSpinnerService,) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isUSA) {
      this.getDataUs();
    } else{
      this.spinner.hide();
    }
      
    if(this.filter != undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
      this.select = result ? result.itemId : null;
      this.filterInfo = this.filter;
      this.hasStudyButton = true;
    }

  }

  getDataUs() {
    const  dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.USA,
      zoom: MapZoom.USA,
      borderColor: '#000000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric' },
      addDays: 1
    };

    this.transboundaryWaterService.getEarthquakeGroundUs().subscribe(
      {
        next: (res:any) => {
          this.data = {
              data: res,
              formatInfo: formatInfo,
              metadata: res.metadata,
              dates: res.dates,
              mapInfo: dataMap,
              filterInfo: this.filterInfo,
              selectedInfo: 30,
              hasStudyButton: this.hasStudyButton,
              step: 1, // step year by year
              shapeUrl: ShapeMapDataLink.Earthquake_us,
              maxCount: 3
            };
        },
        error: (error:any) => {this.spinner.hide(); console.error(error);}
      });
    

  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

}
