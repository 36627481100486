import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-return-home',
  templateUrl: './return-home.component.html',
  styleUrls: ['./return-home.component.css']
})
export class ReturnHomeComponent {
  constructor(private homeStore: HomeStore, private router: Router, private spinner: NgxSpinnerService) {
    
  }
  goToMainPage(){
    this.spinner.show();
    this.homeStore.setSelectedNode('');
    this.router.navigate([this.homeStore.getCurrentUrl()], { skipLocationChange: true })
      .then().catch(e => { console.error(e); console.log('Cannot redirect') });
  }
}
