var L = require('leaflet');

var leafletGeotiff = require('./leaflet-geotiff');

L.TimeDimension.Layer.Geotiff = L.TimeDimension?.Layer.extend({
            
    initialize: function(data, options) {
        
        this.options  = options;
        this._currentLoadedTime = 0;
        this._currentTimeData = {
            data: []
            };
        this.data= data;
        this.geoTIFFLayer = undefined;
    },

    onAdd: function(map) {
        L.TimeDimension.Layer.prototype.onAdd.call(this, map);
        //map.addLayer(this._baseLayer);
        if (this._timeDimension) {
            this._getDataForTime(this._timeDimension.getCurrentTime());
        }
    },

    _onNewTimeLoading: function(ev) {
        
        this._getDataForTime(ev.time);
        return;
    },

    isReady: function(time) {
        return (this._currentLoadedTime == time);
    },

    _update: function() {
        
        if(this._currentTimeData.data.length == 0) return;
        if(this.geoTIFFLayer != undefined){
            this._map.removeLayer(this.geoTIFFLayer);
        }
        this.geoTIFFLayer = L.leafletGeotiff(
            this._currentTimeData.data[0].url,
            {
                band: 0,
                displayMin: 0,
                displayMax: 40,
                colorScale: 'rainbow', // viridis | rainbow | greys
                clampLow: false,
                clampHigh: false
                }
            ).addTo(this._map);
        return true;
    },
    _getDataForTime: function(time) {
            delete this._currentTimeData.data;
            this._currentTimeData.data = [];
            var data = this.data.data.filter(item => item.date == this._timeDimension.getCurrentTime()); 
            for (var i = 0; i < data.length; i++) {
                this._currentTimeData.data.push({
                        url: data[i].url
                    });
                }
            this._currentLoadedTime = this._timeDimension.getCurrentTime();
            if (this._timeDimension && time == this._timeDimension.getCurrentTime() && !this._timeDimension.isLoading()) {
                this._update();
            }
            this.fire('timeload', {
                time: time
            });
        }        
});

L.timeDimension.layer.Geotiff = function(layer, options) {
    return new L.TimeDimension.Layer.Geotiff(layer, options);
};