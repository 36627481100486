import { Component } from '@angular/core';
import { faBars, faClose, faUser, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { HomeStore } from '../../stores/home-store';
import { HomeService } from '../../services/home/home.service';
import { AuthService } from '../../services/authService/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent {
  isUserAuthenticated = false;
  faBars = faBars;
  faClose = faClose;
  faUser = faUser;
  public faArrowLeft = faArrowLeft;

  baseAssets = environment.BaseAssets;

  // todo: replace this values in the database instead
  public COUNTRIES_TEMP: Record<string, string> = {
    Mexico: this.baseAssets + '/main/Mexico flag.svg',
    USA: this.baseAssets + '/main/US flag.svg',
    Canada: this.baseAssets + '/main/Canada flag.svg',
    Trinational: this.baseAssets + '/main/Trinational.png',
  }
  showMenu = false;
  showUserMenu = false;
  constructor(public homeStore: HomeStore, public homeService: HomeService, 
    public authService: AuthService,
    private router: Router) {}

  ngOnInit(): void {
    
    this.isUserAuthenticated = this.authService.isUserAuthenticated;

    if(!this.isUserAuthenticated && environment.qa){
      this.authService.logout();
      this.router.navigate(['']);
    }
  }

  handleLogoutClick() {
    this.authService.logout().subscribe(() => {
      this.isUserAuthenticated = false;
      // this.router.navigate([''], { skipLocationChange: true }); //no cambia la URL
      this.router.navigate(['']);
    })
  }

  goBack() {
    this.homeService.goBack();
  }
}
