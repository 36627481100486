import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';

@Component({
  selector: 'app-dynamic-point-template',
  templateUrl: './dynamic-point-template.component.html',
  styleUrls: ['./dynamic-point-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicPointTemplateComponent {
  @Input() data!: any;
  @Input() title: string = "";
  @Output() selectedItem = new EventEmitter<number>();
  @Input() AllowValueHover!: boolean;
  @Input() haveFilters!: any;
  @Input() filters!: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thridValue = new EventEmitter<string>();

  public newData: any;
  public dates: string[] = [];
  onDestroy$: Subject<boolean> = new Subject();

  constructor(private dialog: MatDialog, 
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,) {}

  openStudyDialog(){
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {filterInfo: this.data.filterInfo, selectedInfo: this.data.selectedInfo},
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.data.selectedInfo = result;
        this.selectedItem.emit(result);
      }
        
    });
   }
  //  ngOnChanges(changes: SimpleChanges): void {

  //     this.spinner.hide();
  //   }

  emitFirstValue($event: any) {
    this.firstValue.emit($event);
    this.reloadView();
    this.spinner.show();
  }

  emitSecondValue($event: any) {
    this.secondValue.emit($event);
    this.reloadView();
    this.spinner.show();
  }

  emitThridValue($event: any) {
    this.thridValue.emit($event);
    this.reloadView();
    this.spinner.show();
  }

  reloadView() {
    this.cdr.detectChanges(); // Force detetection of changes
    this.spinner.hide();

  }
}
