import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { SemiconductorRoutingModule } from './semiconductor.routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    SemiconductorRoutingModule,
    SharedModule,
    MatExpansionModule,
  ]
})
export class SemiconductorModule { }
