import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class NaturalService {

  constructor(private apiService: ApiServiceService) { }

  getSurfaceAirTemperatureDateUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.surfaceAirTemperature}/us/${ApiRouting.dates}`);
  }
  getPrecipitationDateUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.precipitation}/us/${ApiRouting.dates}`);
  }
  getHurricaneTrinationalDates(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/${ApiRouting.hurricane}/tri/${ApiRouting.dates}`);
  }
  getHurricaneTrinationalData(model: any): Observable<any> {
    return this.apiService.post(`${ApiRouting.natural}/${ApiRouting.hurricane}/tri/${ApiRouting.data}`, model);
  }
  getCo2EmissionsTri():Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/co2emissions_tri`);
  }
  getSoilGridsDateGbl(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/soilgrids/gbl/${ApiRouting.dates}`);
  }
  getForestCharacteristicsDateGbl(): Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/forestcharacteristics/gbl/${ApiRouting.dates}`);
  }
  getBridgesInfrastructure(filterParams: any): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/bridgesinfrastructure/usa/data`, filterParams);
  }
  getRoadwaysInfrastructure(filterParams: any): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/roadwaysinfrastructure/usa/data`, filterParams);
  }
  getTrucksUs(): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/trucks/usa/data`);
  }
  getFiltersInlandWaterwaysInfrastructure(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/getFilters_inlandwaterwaysinfrastructure/usa/data`);
  }
  getInlandWaterwaysInfrastructure(filterParams: any): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/inlandwaterwaysinfrastructure/usa/data`, filterParams);
  }
  getWaterSupplyInfrastructureUSA(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/watersupplyinfrastructure/usa/data`);
  }
  getRailsInfrastructure(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/railsinfrastructure/usa/data`);
  }
  getMaritimePortsInfrastructureUSA():Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/maritimeportsinfrastructure_usa`);
  }
  getMaritimePortsInfrastructureMEX():Observable<any> {
    return this.apiService.get(`${ApiRouting.natural}/maritimeportsinfrastructure_mex`);
  }
  getPrecipitationTransboundaryWater(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/precipitation_mex_water`);
  }
  getRivers(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/rivers_usa_water`);
  }
  getNaturalForestDates(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/natural_forest/tri/dates`);
  }
  getFiltersNaturalForest(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/getFilters_natural_forest`);
  }
  getFloodingDates(filterParams: any): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/flooding_cn_dates`, filterParams);
  }
  getFiltersFlooding(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/getFilters_flooding_cn`);
  }
  getFiltersReservoirs(): Observable<any>{
    return this.apiService.get(`${ApiRouting.natural}/getFilters_reservoirs_usa`);
  }
  getReservoirs(filterParams: any): Observable<any>{
    return this.apiService.post(`${ApiRouting.natural}/reservoirs/usa/data`, filterParams);
  }
}
