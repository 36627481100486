import { GraphLinkModel } from "../models/graph.model";

export const mvmLinksPharmaUSA: GraphLinkModel[] = [
  {
    "source": "Freight\nShipping\n(S-MP)",
    "target": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Freight\nShipping\n(S-MP)",
    "target": "Domestic\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Freight\nShipping\n(MP-W)",
    "target": "Domestic\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cisplatin\nDrug\nPrice",
    "target": "FDA\nForeign\nCompliance",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cisplatin\nDrug\nPrice",
    "target": "US Generic\nPharmaceutical\nManufacturing\nInvestments",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cisplatin\nDrug\nPrice",
    "target": "US Generic\nPharmaceutical\nManufacturing\nProfit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cisplatin\nDrug\nPrice",
    "target": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "target": "Freight\nShipping\n(MP-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nWholesalers",
    "target": "Freight\nShipping\n(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nWholesalers",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "FDA\nForeign\nInspections",
    "target": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "US Geopolitics",
    "target": "Foreign\nCISplatin\nSupplier\n(Active\nIngredient)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCISplatin\nSupplier\n(Active\nIngredient)",
    "target": "Freight\nShipping\n(S-MP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCISplatin\nSupplier\n(Active\nIngredient)",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "US Geopolitics",
    "target": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Overall\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Health\nProviders\nMental\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Number\nof\nPatients\nAffected",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Patient\nMental\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Oncology\nProviders\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Disease\nFree\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hospital\nPharmacies\nDrug\nAvailability",
    "target": "Progression\nFree\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCISplatin\nSupplier\n\nActive\nIngredient\n",
    "target": "Freight\nShipping\n(S-MP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCISplatin\nSupplier\n\nActive\nIngredient\n",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "target": "Vessel\n2",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "target": "Freight\nShipping\n(MP-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "US Generic\nPharmaceutical\nManufacturing\nInvestments",
    "target": "Domestic\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Health\nIndustry\nReputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Overall\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Health\nProviders\nMental\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Number\nof\nPatients\nAffected",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Patient\nMental\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Oncology\nProviders\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Disease\nFree\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "target": "Progression\nFree\nSurvival",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Pharmacy\nBenefit\nManagers",
    "target": "Cisplatin\nDrug\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "US Generic\nPharmaceutical\nManufacturing\nProfit",
    "target": "Domestic\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "FDA\nForeign\nCompliance",
    "target": "Foreign\nGeneric\nCisPlatin\nDrug\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global Drugs\nSupply\nConcentration",
    "target": "Cisplatin\nDrug\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Freight\nShipping\n(W-C)",
    "target": "Domestic\nOncology\nClinics\nDrug\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Freight\nShipping\n(W-C)",
    "target": "Hospital\nPharmacies\nDrug\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Domestic\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  }
]
