<mat-card>
    <div [formGroup]="form">
        <div mat-dialog-content class="add-field">
          <h2>Seasonal-Trend Decomposition (STL)</h2>
          <h4>Seasonal-Trend decomposition using Loess (STL) is a method used to decomposed a time series into three components: seasonal, trend, and residual (or remainder). It utilizes the Loess (Locakky Weighted Scatterplot Smoothing) method to perform local regression and extract the underlying components.</h4>
          
          <mat-form-field>
            <mat-label>Trend Smoother (must be an odd positive integer bigger than period)</mat-label>
            <input type="number" min="1" step="1" formControlName="stl_trend_smoother" ngDefaultControl matInput>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Seasonal Smother (must be and odd positive integer S>=S3)</mat-label>
            <input type="number" min="0" formControlName="stl_seasonal_smoother" ngDefaultControl matInput>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Period</mat-label>
            <input type="number" min="1" formControlName="stl_period" ngDefaultControl matInput>
          </mat-form-field>

        </div>
        <button type="submit" min="0" (click)="onSubmit()" class="btn btn-home">Search</button>
    </div>
</mat-card>