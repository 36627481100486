import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { PopulationGrowthComponent } from './pages/population-growth/population-growth.component';
import { WaterEfficiencyComponent } from './pages/water-efficiency/water-efficiency.component';
import { ClimateChangesComponent } from './pages/climate-changes/climate-changes.component';
import { DroughtComponent } from 'src/app/shared/pages/drought/drought.component';
import { EarthquakeComponent } from 'src/app/shared/pages/earthquake/earthquake.component';
import { FloodingComponent } from 'src/app/shared/pages/flooding/flooding.component';
import { SurfaceAirTemperatureComponent } from 'src/app/shared/pages/surface-air-temperature/surface-air-temperature.component';
import { PrecipitationComponent } from 'src/app/shared/pages/precipitation/precipitation.component';
import { AlfalfaComponent } from './pages/alfalfa/alfalfa.component';
import { ProfitComponent } from './pages/profit/profit.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Population-Growth',
    component: PopulationGrowthComponent
  },
  {
    path: 'Water-Efficiency',
    component: WaterEfficiencyComponent
  },
  {
    path: 'Fluvial-Flooding',
    component: FloodingComponent
  },
  {
    path: 'Drought',
    component: DroughtComponent
  },
  {
    path: 'Surface-Air-Temperature',
    component: SurfaceAirTemperatureComponent
  },
  {
    path: 'Precipitation',
    component: PrecipitationComponent
  },
  {
    path: 'Climate-Change',
    component: ClimateChangesComponent
  },  {
    path: 'Alfalfa-Hay',
    component: AlfalfaComponent
  },
  {
    path: 'Profit',
    component: ProfitComponent
  }, 
  

];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TransboundaryWaterRoutingModule { }
