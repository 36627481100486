import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as d3 from 'd3';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';

@Component({
  selector: 'app-biplot',
  templateUrl: './biplot.component.html',
  styleUrls: ['./biplot.component.css']
})
export class BiplotComponent implements AfterViewInit {
  @Input() idScenario!: any;
  
constructor(private generalService:GeneralService){}

  @ViewChild('chart', { read: ElementRef }) private chartEl!: ElementRef<HTMLElement>;

  selection = "";
  graphType = "";
  coordinateTitles = [];
  
  xScores = [];
  
  yScores = [];

  ngAfterViewInit(): void {
const idScenario= this.idScenario;//'0b670ee3-92f8-7af5-f267-141441ffb2a5';
this.generalService.getPcaBiplotByScenario(idScenario).subscribe(
  data=>{
    this.selection=data.selection;
    this.graphType=data.graphType;
    this.coordinateTitles=data.coordinateTitle;
    this.xScores=data.x;
    this.yScores=data.y;
    

    this.createBiplot();
  },
  error=>{
    console.error('Error:', error);
  }
);

  }
  private createBiplot(): void {
    const xRoundedDifference = this.calculateDifferences(this.xScores);
    const yRoundedDifference = this.calculateDifferences(this.yScores);

    const svg = d3.select(this.chartEl.nativeElement)
      .append('svg')
      .attr('width', 600)
      .attr('height', 550); 
    const margin = { top: 40, right: 20, bottom: 50, left: 60 };
    const width = +svg.attr('width') - margin.left - margin.right;
    const height = +svg.attr('height') - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .domain(d3.extent([-xRoundedDifference * 3, -xRoundedDifference * 2, -xRoundedDifference, xRoundedDifference, xRoundedDifference * 2, xRoundedDifference * 3]) as [number, number])
      .range([0, width]);

    const yDomain = [-yRoundedDifference * 3, yRoundedDifference * 3];
    const y = d3.scaleLinear()
      .domain(yDomain)
      .range([height, 0]);

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height / 2})`)
      .call(d3.axisBottom(x).tickSize(0).tickFormat(() => ''))
      .selectAll(".tick line").remove();

    const yAxis = g.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${width / 2},0)`)
      .call(d3.axisLeft(y)
        .tickValues([-yRoundedDifference, -yRoundedDifference * 2, 0, yRoundedDifference, yRoundedDifference * 2])
        .tickSize(0)
        .tickFormat(d => d.toString())
      );

    yAxis.selectAll('.tick text')
      .attr('fill', 'gray')
      .attr('x', -266)
      .attr('text-anchor', 'end');

    yAxis.selectAll('.tick line').remove();

    g.selectAll('.dot')
      .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
      .enter().append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(d.x))
      .attr('cy', d => y(d.y))
      .attr('r', 5)
      .attr('fill', 'blue');

    g.selectAll('.label')
      .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
      .enter().append('text')
      .attr('class', 'label')
      .attr('x', d => x(d.x))
      .attr('y', d => y(d.y))
      .attr('dy', -8)
      .attr('text-anchor', 'middle')
      .text(d => d.label);

    svg.append('defs').append('marker')
      .attr('id', 'arrow')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', 5)
      .attr('refY', 5)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto-start-reverse')
      .append('path')
      .attr('d', 'M 0 0 L 10 5 L 0 10 z')
      .attr('fill', 'gray');

    g.selectAll('.line')
      .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
      .enter().append('line')
      .attr('class', 'line')
      .attr('x1', width / 2)
      .attr('y1', height / 2)
      .attr('x2', d => x(d.x))
      .attr('y2', d => y(d.y))
      .attr('stroke', 'gray')
      .attr('stroke-width', 1)
      .attr('marker-end', 'url(#arrow)');

    g.selectAll('.y-crossline')
      .data([-yRoundedDifference, -yRoundedDifference * 2, yRoundedDifference, yRoundedDifference * 2])
      .enter().append('line')
      .attr('class', 'y-crossline')
      .attr('x1', 0)
      .attr('y1', d => y(d))
      .attr('x2', width)
      .attr('y2', d => y(d))
      .attr('stroke', 'gray')
      .attr('stroke-width', 0.5);

    svg.append('text')
      .attr('class', 'x-axis-label')
      .attr('x', width / 2 + margin.left)
      .attr('y', height + margin.top + 30)
      .attr('text-anchor', 'middle')
      .attr('font-weight', 'normal') 
      .attr('fill', 'gray')
      .text('PC1');

    svg.append('text')
      .attr('class', 'y-axis-label')
      .attr('x', -height / 2 - margin.top)
      .attr('y', margin.left / 2 - 20)
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('font-weight', 'normal')
      .attr('fill', 'gray')
      .text('PC2');


    svg.append('text')
      .attr('class', 'chart-title')
      .attr('x', 20)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'left')
      .attr('font-size', '9px')
      .attr('font-weight', 'bold')
      .text(this.graphType);
      window.addEventListener('resize', () => this.resizeChart());
    }
    
    private drawChart(): void {
      const xRoundedDifference = this.calculateDifferences(this.xScores);
      const yRoundedDifference = this.calculateDifferences(this.yScores);
    
      const windowWidth = window.innerWidth/2;
      const minWidth = 400; // Ancho mínimo deseado
      const finalWidth = Math.max(windowWidth, minWidth);
    
      d3.select(this.chartEl.nativeElement).select('svg').remove();
    
      const svg = d3.select(this.chartEl.nativeElement)
        .append('svg')
        .attr('width', finalWidth)
        .attr('height', 550); 
    
      const margin = { top: 40, right: 20, bottom: 50, left: 60 };
      const width = +svg.attr('width') - margin.left - margin.right;
      const height = +svg.attr('height') - margin.top - margin.bottom;
    
      const x = d3.scaleLinear()
        .domain(d3.extent([-xRoundedDifference * 3, -xRoundedDifference * 2, -xRoundedDifference, xRoundedDifference, xRoundedDifference * 2, xRoundedDifference * 3]) as [number, number])
        .range([0, width]);
    
      const yDomain = [-yRoundedDifference * 3, yRoundedDifference * 3];
      const y = d3.scaleLinear()
        .domain(yDomain)
        .range([height, 0]);
    
      const g = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
    
      g.append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${height / 2})`)
        .call(d3.axisBottom(x).tickSize(0).tickFormat(() => ''))
        .selectAll(".tick line").remove();
    
      const yAxis = g.append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(${width / 2},0)`)
        .call(d3.axisLeft(y)
          .tickValues([-yRoundedDifference, -yRoundedDifference * 2, 0, yRoundedDifference, yRoundedDifference * 2])
          .tickSize(0)
          .tickFormat(d => d.toString())
        );
    
      yAxis.selectAll('.tick text')
        .attr('fill', 'gray')
        .attr('x', -266)
        .attr('text-anchor', 'end');
    
      yAxis.selectAll('.tick line').remove();
    
      g.selectAll('.dot')
        .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
        .enter().append('circle')
        .attr('class', 'dot')
        .attr('cx', d => x(d.x))
        .attr('cy', d => y(d.y))
        .attr('r', 5)
        .attr('fill', 'blue');
    
      g.selectAll('.label')
        .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
        .enter().append('text')
        .attr('class', 'label')
        .attr('x', d => x(d.x))
        .attr('y', d => y(d.y))
        .attr('dy', -8)
        .attr('text-anchor', 'middle')
        .text(d => d.label);
    
      svg.append('defs').append('marker')
        .attr('id', 'arrow')
        .attr('viewBox', '0 0 10 10')
        .attr('refX', 5)
        .attr('refY', 5)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto-start-reverse')
        .append('path')
        .attr('d', 'M 0 0 L 10 5 L 0 10 z')
        .attr('fill', 'gray');
    
      g.selectAll('.line')
        .data(this.xScores.map((x, i) => ({ x: x, y: this.yScores[i], label: this.coordinateTitles[i] })))
        .enter().append('line')
        .attr('class', 'line')
        .attr('x1', width / 2)
        .attr('y1', height / 2)
        .attr('x2', d => x(d.x))
        .attr('y2', d => y(d.y))
        .attr('stroke', 'gray')
        .attr('stroke-width', 1)
        .attr('marker-end', 'url(#arrow)');
    
      g.selectAll('.y-crossline')
        .data([-yRoundedDifference, -yRoundedDifference * 2, yRoundedDifference, yRoundedDifference * 2])
        .enter().append('line')
        .attr('class', 'y-crossline')
        .attr('x1', 0)
        .attr('y1', d => y(d))
        .attr('x2', width)
        .attr('y2', d => y(d))
        .attr('stroke', 'gray')
        .attr('stroke-width', 0.5);
    
      svg.append('text')
        .attr('class', 'x-axis-label')
        .attr('x', width / 2 + margin.left)
        .attr('y', height + margin.top + 30)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'normal') 
        .attr('fill', 'gray')
        .text('PC1');
    
      svg.append('text')
        .attr('class', 'y-axis-label')
        .attr('x', -height / 2 - margin.top)
        .attr('y', margin.left / 2 - 20)
        .attr('text-anchor', 'middle')
        .attr('transform', 'rotate(-90)')
        .attr('font-weight', 'normal')
        .attr('fill', 'gray')
        .text('PC2');
    
    
      svg.append('text')
        .attr('class', 'chart-title')
        .attr('x', 20)
        .attr('y', margin.top / 2)
        .attr('text-anchor', 'left')
        .attr('font-size', '9px')
        .attr('font-weight', 'bold')
        .text(this.graphType);
    }
    
    private resizeChart(): void {
      this.drawChart(); // Redibuja la gráfica al redimensionar la ventana
    }

  calculateDifferences(scores: any) {
    const minValue = Math.min(...scores);
    const maxValue = Math.max(...scores);
    const negativeDifference = Math.abs(minValue);
    const positiveDifference = Math.abs(maxValue);
    const maxDifference = Math.max(negativeDifference, positiveDifference);
    let roundedDifference = maxDifference / 2;
    roundedDifference = Math.floor(roundedDifference * 10) / 10;
    return roundedDifference;
  }
}
