import { AfterContentInit, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
declare const Plotly: any;
@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.css']
})
export class HeatmapComponent implements OnInit {

  public data: any = {
    type: 'heatmap',
    hoverongaps: false,
    x: [],
    y: [],
    z: []
  };
  @Input() idScenario!: string;

  constructor(
    private generalservice: GeneralService,
    private apiService: ApiServiceService,
    private spinner: NgxSpinnerService
  ) { 
  }

  ngOnInit() {
    this.spinner.show();
    Plotly.newPlot('rs-heatmap', this.data);

      let url= `RiskScenarios/get_heatmap_by_Scenario?IdScenario=${this.idScenario}`
      this.apiService.get(url).subscribe((val:any)=>{
        this.data = val;
        // console.log(this.data, 'aqui hay datos')
        // console.log(this.data.x)
        this.heatmapInit(val);
      });
    /*
    var data = [
      {
        "z": [[ 0.85138672, -0.17103145,  0.97959926, -0.45328632, -0.08148018,
               0.64686686,  0.08257014,  0.20396973,  0.26888894,  0.76367624,
              -0.72884636,  1.0],
             [-0.50856562,  0.05382035, -0.7392275 ,  0.16647347, -0.15192754,
              -0.44053696, -0.26893403, -0.27954671, -0.36809152, -0.5743654 ,
               1.0, -0.72884636],
             [ 0.48279339, -0.17527497,  0.69989331, -0.17285509,  0.12850433,
               0.26066862, -0.07548271,  0.07113893,  0.15019274,  1.0,
              -0.5743654 ,  0.76367624],
             [ 0.05558232,  0.3146002 ,  0.33518248,  0.19471769,  0.4333856 ,
              -0.05724353,  0.93444109,  0.1068845 ,  1.0,  0.15019274,
              -0.36809152,  0.26888894],
             [ 0.09633992, -0.02933844,  0.18717529,  0.06352321,  0.18049101,
              -0.10132996,  0.19448598,  1.1,  0.1068845 ,  0.07113893,
              -0.27954671,  0.20396973],
             [-0.07774884,  0.30954301,  0.15325278,  0.2313018 ,  0.40610562,
              -0.1378339 ,  1.0,  0.19448598,  0.93444109, -0.07548271,
              -0.26893403,  0.08257014],
             [ 0.73496292, -0.01633558,  0.64435754, -0.46847899, -0.32176458,
               1.0, -0.1378339 , -0.10132996, -0.05724353,  0.26066862,
              -0.44053696,  0.64686686],
             [-0.53327156,  0.7175856 , -0.02678651,  0.82731188,  1.        ,
              -0.32176458,  0.40610562,  0.18049101,  0.4333856 ,  0.12850433,
              -0.15192754, -0.08148018],
             [-0.82286213,  0.83693823, -0.35395808,  1.0,  0.82731188,
              -0.46847899,  0.2313018 ,  0.06352321,  0.19471769, -0.17285509,
               0.16647347, -0.45328632],
             [ 0.79979226, -0.03559121,  1.0, -0.35395808, -0.02678651,
               0.64435754,  0.15325278,  0.18717529,  0.33518248,  0.69989331,
              -0.7392275 ,  0.97959926],
             [-0.49086692,  1.0, -0.03559121,  0.83693823,  0.7175856 ,
              -0.01633558,  0.30954301, -0.02933844,  0.3146002 , -0.17527497,
               0.05382035, -0.17103145],
             [ 1.0, -0.49086692,  0.79979226, -0.82286213, -0.53327156,
               0.73496292, -0.07774884,  0.09633992,  0.05558232,  0.48279339,
              -0.50856562,  0.85138672]],
          "x": ['Employment', 'Housing  Prices', 'Housing  Starts', 'Time', 'International Trade Policies', 'Interest Rates', 'Forest Fires', 'Extreme Weather', 'Forestry Sector', 'Roadways Infrastructure RM-S', 'Raw Timber Supplier', 'Sawmill Production'],
          "y": ['Sawmill Production', 'Raw Timber Supplier', 'Roadways Infrastructure RM-S', 'Forestry Sector', 'Extreme Weather', 'Forest Fires', 'Interest Rates', 'International Trade Policies', 'Time', 'Housing  Starts', 'Housing  Prices', 'Employment'],
          "type": 'heatmap',
          "hoverongaps": false
      }
    ];*/
    this.spinner.hide();
  }
  heatmapInit(val: any) {
    // console.log(val, 'update')
    // console.log(val['x'])
    Plotly.newPlot('rs-heatmap', [{
      "x": val['x'],
      "y":val['y'],
      "z":val['z'],
      "type": 'heatmap',
      "colorscale": 'Jet',
      "hoverongaps": false}], {
        yaxis: {
          automargin: true
        },
        xaxis: {
          automargin: true
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)"
      }, {responsive: true});
    }
}
