import { GraphLinkModel } from "../models/graph.model";

export const mvmLinksSemiconductorUSA: GraphLinkModel[]  = 
[
    {
      "source": "Automotive\nIndustry",
      "target": "Production\nRate",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Automotive\nIndustry",
      "target": "Revenue",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Trucks\n(M-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Vessels\n(M-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Wireless\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "North\nAmerican\nGlobal\nMarket\nShare",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Computer\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Automotive\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Revenue",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Supply\nChain\nManagement\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "Production\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Computer\nIndustry",
      "target": "Production\nRate",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Computer\nIndustry",
      "target": "Revenue",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Foreign\nInvestments\nin\nSemiconductor\nIndustry",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Gallium",
      "target": "Trucks\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Gallium",
      "target": "Vessels\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Gallium",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Geographic\nConcentration",
      "target": "Germanium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Geographic\nConcentration",
      "target": "Gallium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Geographic\nConcentration",
      "target": "Silicon",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Geographic\nConcentration",
      "target": "Rare\nEarth\nMetals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Germanium",
      "target": "Trucks\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Germanium",
      "target": "Vessels\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Germanium",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "National\nIncentives\nin\nFabrication",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "National\nIncentives\nin\nRaw\nMaterials",
      "target": "Germanium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "National\nIncentives\nin\nRaw\nMaterials",
      "target": "Gallium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "National\nIncentives\nin\nRaw\nMaterials",
      "target": "Silicon",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "National\nIncentives\nin\nRaw\nMaterials",
      "target": "Rare\nEarth\nMetals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Rare\nEarth\nMetals",
      "target": "Trucks\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Rare\nEarth\nMetals",
      "target": "Vessels\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Rare\nEarth\nMetals",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw\nMaterial\nShortage",
      "target": "Germanium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw\nMaterial\nShortage",
      "target": "Gallium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw\nMaterial\nShortage",
      "target": "Silicon",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw\nMaterial\nShortage",
      "target": "Rare\nEarth\nMetals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw\nMaterial\nShortage",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw Material\nSupplier",
      "target": "Trucks\n(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw Material\nSupplier",
      "target": "Vessels\n(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw Material\nSupplier",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw Material\nSupplier",
      "target": "Production\nRate",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Raw Material\nSupplier",
      "target": "Production\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Reliance\non\nImports",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Semiconductor\nEquipment\nManufacturers",
      "target": "Trucks\n(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Semiconductor\nEquipment\nManufacturers",
      "target": "Vessels\n(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Semiconductor\nEquipment\nManufacturers",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Silicon",
      "target": "Trucks\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Silicon",
      "target": "Vessels\n(RM-S)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Silicon",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tarrifs\nand\nExport\nControls",
      "target": "Germanium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tarrifs\nand\nExport\nControls",
      "target": "Gallium",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tarrifs\nand\nExport\nControls",
      "target": "Silicon",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tarrifs\nand\nExport\nControls",
      "target": "Rare\nEarth\nMetals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\nM\nC",
      "target": "Wireless\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\n(M-C)",
      "target": "Computer\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\n(M-C)",
      "target": "Automotive\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\n(RM-S)",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\n(RM-S)",
      "target": "Semiconductor\nEquipment\nManufacturers",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks\n(S-M)",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(M-C)",
      "target": "Wireless\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(M-C)",
      "target": "Computer\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(M-C)",
      "target": "Automotive\nIndustry",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(RM-S)",
      "target": "Raw Material\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(RM-S)",
      "target": "Semiconductor\nEquipment\nManufacturers",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vessels\n(S-M)",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Water\nShortage",
      "target": "Chip\nManufacturing",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Wireless\nIndustry",
      "target": "Production\nRate",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Wireless\nIndustry",
      "target": "Revenue",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Chip\nManufacturing",
      "target": "US Exports",
      "lineStyle": {
        "curveness": 0.1
      }
    }
  ]
  