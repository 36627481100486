import { GraphLinkModel } from "../models/graph.model";

export const  mvmLinksPublicHealthUSA: GraphLinkModel[] = [
    {
      "source": "Bias\nInformation\nSearch",
      "target": "Quality\nof\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Citation\nMisdirection",
      "target": "Quality\nof\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Data\nDredge/p-hack",
      "target": "Selective\nProtocols\nReporting",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Growth\nof\nPublication\nOutlets",
      "target": "Peer Review\nQuality",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Growth\nof\nPublication\nOutlets",
      "target": "Quality\nof\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Growth\nof\nPublication\nOutlets",
      "target": "Quantity of\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Hype\nFindings",
      "target": "False Facts",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Peer Review\nQuality",
      "target": "Quality\nof\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Peer Review\nQuality",
      "target": "Quantity of\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Quality\nof\nPublished\nResearch",
      "target": "Hype\nFindings",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Quality\nof\nPublished\nResearch",
      "target": "Replicability\nof\nResults",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Quality of \nResearch",
      "target": "Selective\nProtocols\nReporting",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Quantity of\nPublished\nResearch",
      "target": "False Facts",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Researchers\nIncentives",
      "target": "Data\nDredge/p-hack",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Researchers\nIncentives",
      "target": "Selective\nProtocols\nReporting",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Selective\nProtocols\nReporting",
      "target": "Peer Review\nQuality",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Selective\nProtocols\nReporting",
      "target": "Quality\nof\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Selective\nProtocols\nReporting",
      "target": "Quantity of\nPublished\nResearch",
      "lineStyle": {
        "curveness": 0.1
      }
    }
  ]
  