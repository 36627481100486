import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom, GeotiffDataLink } from 'src/app/core/enums/map.enum';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { SystemService } from 'src/app/core/services/system/system.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.css']
})
export class SolarComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  constructor(private systemService: SystemService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else
    this.getDataTri()

  }

  getDataTri() {
    const  dataMap = {
      center: MapCenter.USA,
      json: MapJson.USA,
      zoom: MapZoom.USA
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 1
    };
    this.systemService.getSolarDateGbl()
      .subscribe(
        {
          next: (res) => {
            this.data = {
              data: res.data.map((value:any) => {
                return {url: `${environment.BaseUrl}${environment.Api}${GeotiffDataLink.Solar_gbl}${value.fileId}`, date: value.date }
              }),
              metadata: res.metadata, 
              dates: res.data.map((value:any) => {
                return value.date
              }),
              mapInfo: dataMap,
              formatInfo: formatInfo
            };
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }
  
  getDataCN() {
    this.spinner.hide();
  }

  getDataUs() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

}
