<mat-card>
    <div [formGroup]="form">
      <div mat-dialog-content class="add-field">
        <h2>Pearson correlation coefficient</h2>

        <div *ngIf="typeTemplate == 'Time Series'" class="row">
            <div class="col-md-6">
                <h3>Year synchronization</h3>

                <div class="slide-toggle-container">
                    <span>No</span>
                    <mat-slide-toggle></mat-slide-toggle>
                    <span>Yes</span>
                </div>

                <mat-form-field class="custom-input">
                    <mat-label>Start Year</mat-label>
                    <input type="number" min="1900" [max]="2025" step="1" formControlName="startYear" matInput>
                  </mat-form-field>
                  
                  <mat-form-field class="custom-input">
                    <mat-label>End Year</mat-label>
                    <input type="number" min="1900" [max]="2025" step="1" formControlName="endYear" matInput>
                  </mat-form-field>
                  
            </div>

            <div class="col-md-6">
                <h3>Monthly synchronization</h3>
                <mat-form-field class="custom-input">
                    <mat-label>Start Month</mat-label>
                    <mat-select formControlName="startMonth" placeholder="Select Start Month">
                      <mat-option *ngFor="let month of months" [value]="month.value">
                        {{ month.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                
                  <mat-form-field class="custom-input">
                    <mat-label>End Month</mat-label>
                    <mat-select formControlName="endMonth" placeholder="Select End Month">
                      <mat-option *ngFor="let month of months" [value]="month.value">
                        {{ month.viewValue }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <mat-card class="map-container">
              <mat-card-content>
                <app-plotly [id]="'heatmapGraph_081223'"
                            [config]="secondOrder">
                </app-plotly>
              </mat-card-content>
            </mat-card>
          </div> 
        </div> 

      </div>
    </div>



  </mat-card>
  