import { Component, OnInit } from '@angular/core';
import { DefaultGraphModel } from '../../../../core/models/chartjs.model';
import { ChartjsServiceService } from '../../../../core/services/chartjsService/chartjs-service.service';
import { TransboundaryWaterServiceService } from '../../../../core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from '../../../../core/stores/home-store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-water-efficiency',
  templateUrl: './water-efficiency.component.html',
  styleUrls: ['./water-efficiency.component.css']
})
export class WaterEfficiencyComponent implements OnInit{
  public data!: DefaultGraphModel;
  public total: number = 0;

  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  
  constructor(private transboundaryService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, 
    private chartjsService: ChartjsServiceService,
    private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {

    if (this.isCN) {
      this.getDataCN();
    } else {
      this.spinner.hide();
    }

  }
  getDataCN() {
    this.transboundaryService.getWaterEfficiency()
      .subscribe(
        {
          next: (res) => {

            this.data = this.chartjsService.getDataMapped(res as DefaultGraphModel,true, true);
            this.spinner.hide();
          },
          error: (e) => console.error(e)
        }
      );
  }
}
