import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-rivers',
  templateUrl: './rivers.component.html',
  styleUrls: ['./rivers.component.css']
})
export class RiversComponent {

  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  public selection: any = { 
    filter_one: 'Lake Havasu'//Colorado River at Cibola Gage'
  };

  dataMap = {
    center: MapCenter.USA,
    scale: ['#3399FF', '#66CCFF', '#99CCFF'].reverse(), // Tonos de azul
    json: MapJson.USA,
    zoom: MapZoom.USA,
    borderColor: '#0000FF', // Borde azul
    color: '#3366CC', // Color de las áreas azul
    fillColor: '#99CCFF' // Relleno azul claro
  };
  formatInfo = {
    region: "en-US",
    format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
    addDays: 1
  };

  filterInfoValues!: any;

  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();

    setTimeout(async () => {
      // const filters = await firstValueFrom(
      //   this.naturalService.getFiltersRivers()
      // );
  
      // this.filterInfoValues = {
      //   firstFilter: filters.filter_OneData,
      // };

      if (this.isCN) {
        this.getDataCN();
      } else if (this.isUSA) {
        this.getDataUs();
      } else if (this.isMx) {
        this.getDataMx();
      }else{
        this.getDataTri();
      }
      if(this.filter!=undefined){
        let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
       this.select = result ? result.itemId : null;
        this.filterInfo=this.filter;
        this.hasStudyButton=true;
      
      }

    }, 300); 

  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

  getDataCN() {
    this.spinner.hide();
  } 

  getDataTri() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataUs() {
    this.naturalService.getRivers()
    .subscribe({
      next: (res) => {
        this.data = {
          data: res,
          formatInfo: this.formatInfo,
          metadata: res.metadata, 
          mapInfo: this.dataMap,
          filterInfo:this.filterInfo,
          selectedInfo:this.select,
          hasStudyButton:this.hasStudyButton,
        };

        this.spinner.hide();
      },
      error: (e) => { console.error(e); this.spinner.hide(); }
    });  
    
  }

  async handleFilterUpdate($event: any) {
    this.selection ={ 
      filter_one: $event.toString()
    }; 

    this.getDataUs();
  }
}


