import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { HousingPriceComponent } from './pages/housing-price/housing-price.component';
import { FloodingComponent } from 'src/app/shared/pages/flooding/flooding.component';
import { HurricaneComponent } from 'src/app/shared/pages/hurricane/hurricane.component';
import { EarthquakeComponent } from 'src/app/shared/pages/earthquake/earthquake.component';
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Housing-Prices',
    component: HousingPriceComponent
  },
  {
    path: 'Flooding',
    component: FloodingComponent
  },
  {
    path: 'Hurricane',
    component: HurricaneComponent
  },{
    path: 'Earthquake',
    component: EarthquakeComponent
  },
  {
    path: 'Drought',
    component: GeneralNodeComponent//DroughtComponent
  },
];
@NgModule({
    declarations: [],
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
export class SawmillsProductionRoutingModule { }
