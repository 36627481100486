import { AfterViewInit, Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-stacked',
  templateUrl: './stacked.component.html',
  styleUrls: ['./stacked.component.css']
})
export class StackedComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() data!: any;
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  constructor() { }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngAfterViewInit(): void {
    this.root = am5.Root.new(this.chartEl.nativeElement);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: this.root.verticalLayout
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.p50,
      x: am5.p50
    }));
    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: "cases",
      fontSize: 12,
      fontWeight: "400",
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 25
    }));


    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      behavior: "zoomX",
      crisp: true,
    }));
    cursor.lineX.setAll({
      stroke: am5.color(0xcccccc),
      strokeDasharray: []
    })
    cursor.lineY.setAll({
      "visible": false,
    });

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });

    let xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: "age",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    })

    xAxis.data.setAll(this.data.result);

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1
      })
    }));
    this.data.properties.forEach((prop:string) => {
      this.makeSeries(this.chart, xAxis, yAxis, this.root, legend, this.data.result, prop);
    })

    this.chart.zoomOutButton.set("forceHidden", true);
    // Make stuff animate on load
    // https://www.amthis.charts.com/docs/v5/concepts/animations/
    this.chart.appear(1000, 100);
    //this.this.root = this.root;
  }

  makeSeries(chart:am5xy.XYChart, xAxis: any, yAxis: any, root: am5.Root,legend: am5.Legend,data:any, name:string){
    let series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: name,
      categoryXField: "age",

      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: 'horizontal',
        labelText: "{name}: {valueY}",

      })
    }));

    series.data.setAll(data);
    legend.data.push(series);

    series.appear(1000);
    //series.labels.template.fontSize = 10;

  }


  ngOnInit(): void {
  }

}
