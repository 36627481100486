import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { DynamicTemplateModel, StaticTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-canola',
  templateUrl: './canola.component.html',
  styleUrls: ['./canola.component.css']
})
export class CanolaComponent {
  public data!: any;
  public dataMap: any = {};
  public formatInfo: any = {};
  public usData!: StaticTemplateModel;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  constructor(private transboundaryWaterService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
      if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
      else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
      else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
      else this.isTri = true;
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.dataMap = {
        center: MapCenter.USA,
        scale: [ '73daff','3e64ad', '091178'].reverse(),
        json: MapJson.USA,
        zoom: MapZoom.USA,
        borderColor: '#defdff'
      };
      this.formatInfo = {
        region: "es-MX",
        format: {  year: 'numeric' },
        addDays: 1
      };
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }
  }

  getDataUs() {
    this.transboundaryWaterService.getCanolaUs()
      .subscribe(
        {
          next: (res) => {
            this.data ={
              data: res,
              title: 'Groundwater for Rapeseeds',
              description: 'info',
              mapTitle: 'Groundwater for Rapeseeds',
              mapInfo: this.dataMap,
              ECDF: {title: 'ECDF Groundwater for Rapeseeds ',
                       yLabel: 'Cumulative Relative Frequency', xLabel: 'Groundwater for Rapeseeds'
                      },
                histogram: {
                  title: 'Histogram Groundwater for Rapeseeds ',
                  yLabel: 'Frecuency', xLabel: 'Groundwater for Rapeseeds'
                },
              formatInfo: this.formatInfo,
              hasFilter: false,
              labelPopup: 'Value',
              isCountyLevel: true
            } as DynamicTemplateModel;
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }

  getDataCN() {
    this.transboundaryWaterService.getCanolaUs()
    .subscribe(
      {
        next: (res) => {
          const dataMap = {
            center: MapCenter.USA,
            scale: [ '73daff','3e64ad', '091178'].reverse(),
            json: MapJson.USA,
            zoom: MapZoom.USA,
            borderColor: '#defdff'
          };
          this.data = {
            data: res,
            boxplotTitle: 'Average of Groundwater for Rapeseeds (%)',
            histogramTitle: 'Groundwater for Rapeseeds Frecuency',
            mapTitle: 'Groundwater for Rapeseeds',
            description: 'info',
            title: 'Groundwater for Rapeseeds',
            isCountyLevel: true,
            mapInfo: dataMap,
            labelPopup: 'Rapeseeds'
          }

        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataTri() {
    this.spinner.hide();
  }
}
