import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import CustomEase from 'gsap/CustomEase';
import EaselPlugin from 'gsap/EaselPlugin';

gsap.registerPlugin(ScrollTrigger, CustomEase, EaselPlugin);

@Component({
  selector: 'app-graph-section',
  templateUrl: './graph-section.component.html',
  styleUrls: ['./graph-section.component.css']
})
export class GraphSectionComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sectionEl', {read: ElementRef}) sectionEl: ElementRef | undefined;
  @ViewChild('titleEl', {read: ElementRef}) titleEl: ElementRef | undefined;
  @ViewChild('descriptionEl', {read: ElementRef}) descriptionEL: ElementRef | undefined;
  @ViewChild('chartColEl', {read: ElementRef}) chartColEl: ElementRef | undefined;
  @ViewChild('chartWrapperEl', {read: ElementRef}) chartWrapperEl: ElementRef | undefined;

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() inverted: boolean = false;

  gsapCtx?: gsap.Context;

  ngAfterViewInit() {
    if (this.titleEl) {
      console.log(this.titleEl)
    }

    let ctx = gsap.context(() => {
      const chartTimeline = gsap.timeline()
      chartTimeline.fromTo(this.chartWrapperEl?.nativeElement, {
        x: '100%',
      }, {
        x: '0%',
        duration: 2,
        ease: "expo.out",
      })
      chartTimeline.fromTo(this.chartWrapperEl?.nativeElement, {
        x: '0%',
      }, {
        x: '0%',
        duration: 2,
      })
      chartTimeline.fromTo(this.chartWrapperEl?.nativeElement, {
        x: '0%',
      }, {
        x: '-100%',
        duration: 3,
        ease: "expo.in",
      })

      const st = new ScrollTrigger({
        trigger: this.sectionEl?.nativeElement,
        pin: this.chartColEl?.nativeElement,
        animation: chartTimeline,
        scrub: true,
        pinSpacing: false,
        start: 'top top',
        end: 'bottom bottom'
      })
    });

    this.gsapCtx = ctx;
  }

  ngOnDestroy() {
    this.gsapCtx?.revert()
  }
}
