import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class AutomobileService {

  constructor(private apiService: ApiServiceService) { }

  getHealthInsuranceCn(): Observable<any> {
    return this.apiService.get(`${ApiRouting.stateOfRisk}/${ApiRouting.healthInsurance}_cn`);
  }
  getProductRecallsUs(): Observable<any> {
    return this.apiService.get(`${ApiRouting.stateOfRisk}/${ApiRouting.productRecalls}_us`);
  }
}
