<div class="container">
    <h2> Filter by </h2>

    <div class="row">
        <div class="col-md-5">
            <span class="subtitle">Risk component classification</span>
            <div class="radio-container">
                <mat-radio-group [(ngModel)]="selectedOptionClassification" class="horizontal-radio-group">
                    <mat-radio-button *ngFor="let option of itemsClassification" [value]="option.name" (change)="onChangeSelection()"> 
                    <fa-icon [icon]="option.icon"></fa-icon> {{ option.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-md-1 with-vertical-line"></div>
        <div class="col-md-5">
            <span class="subtitle">Variable type</span>
            <div class="radio-container">
                <mat-radio-group [(ngModel)]="selectedOptionVariableType" class="horizontal-radio-group">
                    <mat-radio-button *ngFor="let option of itemsVariableType" [value]="option.name" (change)="onChangeSelection()"> 
                    <fa-icon [icon]="option.icon"></fa-icon> {{ option.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div class=" row with-horizontal-line"></div>

    <div class="row">
        <div class="col-md-4" *ngFor="let category of categories;">
            <span class="subtitle">{{ category.name }}</span>
            <div class="row" *ngFor="let option of category.links;">
                <mat-checkbox [value]="option.name" [(ngModel)]="option.checked" (change)="option.checked = $event.checked" [disabled]="disabled_categories"> 
                    <div class="color-circle" [style.background-color]="option.itemStyle.color"></div>
                    {{ option.name }}
                </mat-checkbox>
            </div>
        </div>
    </div>
    

    <div class=" row with-horizontal-line"></div>

    <div class=" row">
        <div class="col-md-3">
            <button class="reset-btn" (click)="reset()">Reset All Filters</button>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <button class="cancel-btn" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-md-3">
            <button class="filter-btn" (click)="closeDialog()">Apply Filters</button>
        </div>
        
    </div>
</div>

  