import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-line-two-series-scatter',
  templateUrl: './line-two-series-scatter.component.html',
  styleUrls: ['./line-two-series-scatter.component.css']
})
export class LineTwoSeriesScatterComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() data!: any;
  
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  @ViewChild('elRef', { read: ElementRef }) private elRef!: ElementRef<HTMLElement>;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.chart) this.chart.dispose();
    if (this.root) this.root.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root) this.initChart();
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  initChart() {
    if (this.root) {
      this.root.dispose();
    }

    this.root = am5.Root.new(this.elRef.nativeElement);

    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelY: "zoomXY",
        pinchZoomX: true
      })
    );

    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.yLabel,
      fontSize: 16,
      fontWeight: 'bold',
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 30,
      paddingTop:30  
    }));

    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.title,
      textAlign: "center",
      fontSize: 13, 
      fontWeight: "700",
      x: am5.p50, 
      centerX: am5.p50,
      oversizedBehavior: "wrap", 
      maxWidth: 400,
    }));

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 50
    });
      // X Axis
      let xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
        autoZoom: false
        })
      );
      xRenderer.grid.template.setAll({
        location: 1
      });
  
      xRenderer.labels.template.setAll({
        oversizedBehavior: "wrap",
        maxWidth: 51,
        textAlign: "center"
      });

    xAxis.children.push(am5.Label.new(this.root, {
      text: this.data.xLabel,
      textAlign: 'center',
      x: am5.p50,
      fontSize: 14,
      fontWeight: 'bold'
    }));

    // Y Axis
    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {}),
      tooltip: am5.Tooltip.new(this.root, {}),
      autoZoom: false
    }));

    let colorSerieObservation = this.data.style?.colorSerieObservation ? this.data.style?.colorSerieObservation : "#ff0000";
    let colorSeriePrediction = this.data.style?.colorSeriePrediction ? this.data.style?.colorSeriePrediction : "#ff0000";
    let colorPointExpected = this.data.style?.colorPointExpected ? this.data.style?.colorPointExpected : "#ff0000";

    // Serie 1: Línea con círculos
    let series0 = this.chart.series.push(am5xy.LineSeries.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "yObservation",
      valueXField: "x",
      stroke: am5.color(colorSerieObservation),
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "Time: {valueX}\nObservation: {valueY}"
      }),
      name: "Observation"
    }));

    series0.strokes.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(colorSerieObservation)
    });

    series0.fills.template.setAll({
      visible: false,
      fillOpacity: 0
    });

    series0.bullets.push(() => {
      var graphics = am5.Circle.new(this.root, {
        radius: 4,
        interactive: true,
        cursorOverStyle: "ns-resize",
        stroke: series1.get("stroke"),
        fill: am5.color(colorSerieObservation),
      });

      graphics.set("tooltipText", "Time: {valueX}\nObservation: {valueY}");

      return am5.Bullet.new(this.root, {
        sprite: graphics
      });
    });

    // Serie 2: Línea con círculos
    let series1 = this.chart.series.push(am5xy.LineSeries.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "yPrediction",
      valueXField: "x",
      stroke: am5.color(colorSeriePrediction),
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "Time: {valueX}\nPrediction: {valueY}"
      }),
      name: "Prediction"
    }));

    series1.strokes.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(colorSeriePrediction)
    });

    series1.fills.template.setAll({
      visible: false,
      fillOpacity: 0
  });

    series1.bullets.push(() => {
      var graphics = am5.Circle.new(this.root, {
        radius: 4,
        interactive: true,
        cursorOverStyle: "ns-resize",
        stroke: series1.get("stroke"),
        fill: am5.color(colorSeriePrediction),
      });
      graphics.set("tooltipText", "Time: {valueX}\nObservation: {valueY}");
      
      return am5.Bullet.new(this.root, {
        sprite: graphics
      });
    });

    // Serie 3: Solo cuadrados (sin línea)
    let squareSeries = this.chart.series.push(am5xy.LineSeries.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "yExpected",
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "Time: {valueX}\nExpected: {valueY}"
      }),
      name: "Expected"
    }));

    // hidde line
    squareSeries.strokes.template.set("opacity", 0);

    squareSeries.bullets.push(() => {
      const rectangle = am5.Rectangle.new(this.root, {
        width: 10,
        height: 10,
        fill: am5.color(colorPointExpected)
      });
      
      rectangle.set("tooltipText", "Time: {valueX}\nExpected: {valueY}");
    
      return am5.Bullet.new(this.root, {
        sprite: rectangle
      });
    
    });

    // Asignar datos
    series0.data.setAll(this.data.data);
    series1.data.setAll(this.data.data);
    squareSeries.data.setAll(this.data.data);

    // Animaciones
    series0.appear(1000);
    series1.appear(1000);
    squareSeries.appear(1000);

    this.chart.appear(1000, 100);
  }
}
