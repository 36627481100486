import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthLayoutComponent} from './core/layouts/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './core/layouts/main-layout/main-layout.component';
import {AuthGuard} from './core/guards/auth.guard';
import {MainPageComponent} from './core/layouts/main-page/main-page.component';
import {DynamicViewComponent} from './core/layouts/dynamic-view/dynamic-view.component';
import {HomeComponent} from './core/layouts/home/home.component';
import {StoryV2Component} from "./modules/story/story-v2/story-v2.component";
import { LoginComponent } from './modules/auth/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'login/:redirect',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: MainPageComponent,
  },
  {
    path: ':country',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: ':section',
        component: HomeComponent,
      },
      {
        path: ':section/:detail',
        component: DynamicViewComponent,
      },
    ],
  },
  {
    path: 'story',
    component: StoryV2Component
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'landingpage',
    canActivateChild: [],
    loadChildren: () =>
      import('../app/modules/landingpage/landingpage.module').then(
        (m) => m.LandingpageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
