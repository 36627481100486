import { AfterViewInit, Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-accumulated',
  templateUrl: './accumulated.component.html',
  styleUrls: ['./accumulated.component.css']
})
export class AccumulatedComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() data!: any;
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  constructor() { }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngAfterViewInit(): void {
    this.root = am5.Root.new(this.chartEl.nativeElement);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: this.root.verticalLayout
    }));

    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: "Dayly cases",
      fontSize: 12,
      fontWeight: "400",
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 25
    }));
    this.chart.rightAxesContainer.children.push(am5.Label.new(this.root, {
      text: "Accumulated cases",
      fontSize: 12,
      fontWeight: "400",
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 90,
      paddingBottom: 55
    }));

    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      behavior: "zoomX",
      crisp: true,
    }));
    cursor.lineX.setAll({
      stroke: am5.color(0xcccccc),
      strokeDasharray: []
    })
    cursor.lineY.setAll({
      "visible": false,
    });


    let xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 60
      }),
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      fontSize: "11px"
    });



    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {

      renderer: am5xy.AxisRendererY.new(this.root, {

      })

    }));
    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    });


    let secondYAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, { opposite: true }),

    }));
    let yRendererSecond = secondYAxis.get("renderer");
    yRendererSecond.labels.template.setAll({
      fontSize: "11px"
    });



    let legend = this.chart.children.push(am5.Legend.new(this.root, {
    }));



    let scrollbar = this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
      orientation: "horizontal"

    }));

    this.chart.set("scrollbarX", scrollbar);
    this.chart.bottomAxesContainer.children.push(scrollbar);
    this.data.result.forEach((x:any) => {
        const date = new Date(x.date);
        x.dateParse = date.getTime();
        x.date = date;
    });
    this.makeSeries(this.chart, xAxis, yAxis, this.root, legend, this.data.result, 'male');
    this.makeSeries(this.chart, xAxis, yAxis, this.root, legend, this.data.result, 'female');
    this.makeLineSeries(this.chart, xAxis, secondYAxis, this.root, legend, this.data.result, 'accumulated');
    this.chart.zoomOutButton.set("forceHidden", true);
    // Make stuff animate on load
    // https://www.amthis.charts.com/docs/v5/concepts/animations/
    this.chart.appear(1000, 100);
    //this.this.root = this.root;
  }
  makeLineSeries(chart:am5xy.XYChart, xAxis: any, yAxis: any, root: am5.Root,legend: am5.Legend,data:any, name:string){
    let series = chart.series.push(am5xy.LineSeries.new(this.root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: name,
      valueXField: "dateParse",
      fill: am5.color(0x500000),
      stroke: am5.color(0x500000),
      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: 'horizontal',
        labelText: "{name}: {valueY}",
      })
    }));
    series.strokes.template.setAll({
      strokeWidth: 2,
    });


    series.data.setAll(data);
    legend.data.push(series);

    series.events.once("datavalidated", () =>{
      xAxis.zoomToDates(this.data.result!.at(-1)!.date!, this.data.result!.at(-60)!.date!);
    });
    series.appear(1000);
  }
  makeSeries(chart:am5xy.XYChart, xAxis: any, yAxis: any, root: am5.Root,legend: am5.Legend,data:any, name:string){
    let series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: name,
      valueXField: "dateParse",

      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: 'horizontal',
        labelText: "{name}: {valueY}",

      })
    }));

    series.data.setAll(data);
    legend.data.push(series);

    series.events.once("datavalidated", () =>{
      xAxis.zoomToDates(this.data.result!.at(-1)!.date!, this.data.result!.at(-60)!.date!);
    });
    series.appear(1000);
    //series.labels.template.fontSize = 10;

  }


  ngOnInit(): void {
  }

}
