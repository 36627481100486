import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { PublicHealthInfoRoutingModule } from './public-health-info.routing.module';
import { SharedModule } from '../../shared/shared.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    PublicHealthInfoRoutingModule,
    SharedModule,
    MatExpansionModule,
    MatIconModule,
    MatExpansionModule
  ]
})
export class PublicHealthInfoModule { }
