import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { FilterType } from 'src/app/core/models/templates/static-template/static-template.model';

@Component({
  selector: 'app-category-bar-template',
  templateUrl: './category-bar-template.component.html',
  styleUrls: ['./category-bar-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CategoryBarTemplateComponent implements OnInit{
  @Input() dataRes!: any;
  @Output() selectedItem = new EventEmitter<number>();
  @Input() event: string = "";
  @Output() firstValue = new EventEmitter<string>();

  public filterType = FilterType;

  constructor(private spinner: NgxSpinnerService,
    private dialog: MatDialog) {
    
  }

  ngOnInit(): void {
    this.spinner.hide();
  }
  emitFirstValue($event:any){
    this.firstValue.emit($event);
  }
  
  openStudyDialog(){
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {filterInfo: this.dataRes.filterInfo, selectedInfo: this.dataRes.selectedInfo},
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }
        
    });
   }
  }
