import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FilterType,
  TimeVisualizationTemplateModel,
} from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TrendModelComponent } from '../../components/forms/trend-model/trend-model.component';
import { HttpClient } from '@angular/common/http';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutocorrelationModelComponent } from '../../components/forms/autocorrelation-model/autocorrelation-model.component';
import { SeasonalModelComponent } from '../../components/forms/seasonal-model/seasonal-model.component';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';

@Component({
  selector: 'app-mvm-time-visualization-template',
  templateUrl: './mvm-time-visualization-template.component.html',
  styleUrls: ['./mvm-time-visualization-template.component.css']
})

export class MvmTimeVisualizationTemplateComponent
  implements OnDestroy, OnChanges
{
  @Input() dataRes!: TimeVisualizationTemplateModel;
  @Input() idsFilters!: any;
  @Input() filtro!:string[];
  @Input() event: string = 'Average Total Cost\nPer 15k Miles';
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  @Output() selectedItem = new EventEmitter<number>();
  @Output() yAxisValue = new EventEmitter<string>();
  @Output() selectedYearValue = new EventEmitter<string[]>();

  showBorder = false;
  public filters: string[] = [];
  public yaxisfilter: string[] = [];
  public showAlertEcdf = false;
  public showAlertHistogram = false;
  showModal: boolean | undefined;
  public valueYear!: any;
  public histogram!: any;
  public XYChart!: any;
  public ecdfChart!: any;
  public minDistance: number = 1;
  public yLabel: string[] | string = '';
  public filterType = FilterType;
  public propertyName: string = 'value';

  private onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private timeVisualizationService: TimeVisualizationTemplateService,
    private dataService: DataMultipleVariableService,
    private chartjsService: ChartjsServiceService,
    @Optional() private dialog: MatDialog,
    private httpClient: HttpClient
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dataRes']){
      this.filters = this.filtro ?? [];
      this.yaxisfilter = this.dataRes.dataikuModel?.y_axis_filter ?? [];
      // this.filter = this.dataRes.dataikuModel?.filter_ ?? '';
      this.reloadView();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.dataService.reloadView$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.reloadView();
    });
    this.dataService.validationArray$.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
      this.ValidationArrayWithData(data);
      this.reloadView();
    });
  }

  emitSelectedYearValue($event: any) {
    this.selectedYearValue.emit($event);
    this.spinner.show();
    this.reloadView();
  }

  emitFirstValue($event: any) {
    this.firstValue.emit($event + '1');
    this.spinner.show();
    this.reloadView();
  }

  emitSecondValue($event: any) {
    this.secondValue.emit($event + '2');
    this.spinner.show();
    this.reloadView();
  }

  emitThirdValue($event: any) {
    this.thirdValue.emit($event + '3');
    this.spinner.show();
    this.reloadView();
  }
 
  emitFourthValue($event: any) {
    this.spinner.show();
    this.fourthValue.emit($event + '4');
    this.reloadView();
  }

  emitFifthValue($event: any) {
    this.spinner.show();
    this.fifthValue.emit($event + '5');
    this.reloadView();
  }

  emitSixthValue($event: any) {
    this.spinner.show();
    this.sixthValue.emit($event + '6');
    this.reloadView();
  }

  emitYAxisValue($event: any) {
        this.spinner.show();
    this.yAxisValue.emit($event);
    this.reloadView();
  }

  reloadView() {
    
    this.histogram = {
      data: this.timeVisualizationService.getHistogram(
        this.dataRes.data,
        this.dataRes.histogram.title,
        this.propertyName
      ),
      title: this.dataRes.histogram.title,
      yLabel: this.dataRes.histogram.yLabel,
      xLabel: this.dataRes.histogram.xLabel,
    };
    if (this.dataRes.timeUnit == 'quarter') {
      this.XYChart = {
        title: this.dataRes.xyChart.title,
        data: this.timeVisualizationService.getXYCategory(this.dataRes.data),
        xLabel: this.dataRes.xyChart.xLabel,
        yLabel: this.dataRes.xyChart.yLabel,
      };
    } else {
      this.XYChart = {
        title: this.dataRes.xyChart.title,
        data: this.timeVisualizationService.getXY(
          this.dataRes.data,
          this.dataRes.isDate,
          this.propertyName
        ),
        xLabel: this.dataRes.xyChart.xLabel,
        yLabel: this.dataRes.xyChart.yLabel,
      };
    }
    //this.yLabel = this.event.split('\n');
    this.ecdfChart = {
      title: 'ECDF', // - '+ this.dataRes.xyChart.title,
      data: this.chartjsService.getDataMapped(
        this.timeVisualizationService.getECDFGeneral(
          this.dataRes.data,
          this.propertyName
        ),
        true,
        false,
        true
      ),
      yLabel: 'Cumulative Relative Frequency',
      xLabel: this.dataRes.xyChart.title,
    };
    this.showAlertHistogram = this.histogram.data.length > 1 ? false : true;
    this.showAlertEcdf = this.ecdfChart.data.datasets[0].data.length > 1 ? false : true;
    this.showBorder = this.dataRes.hasFilter == true ? true : this.dataRes.data.listYears != null ? true : false;
    
    this.cdr.detectChanges(); // Force detetection of changes
  }

  openStudyDialog() {
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        filterInfo: this.dataRes.filterVariableInfo,
        selectedInfo: this.dataRes.selectedInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }
  public tabEventAction(tabChangeEvent: string): void {
  }

  public closeModal() {
    this.showModal = false;
  }

  ValidationArray() {
    if (
      this.dataRes.data.data &&
      Array.isArray(this.dataRes.data.data) &&
      this.dataRes.data.data.length > 0
    ) {
      this.showModal = false;
    } else {
      this.showModal = true;
    }
  }

  public ValidationArrayWithData(data: any): void {
    if (
      data.data.data &&
      Array.isArray(data.data.data) &&
      data.data.data.length > 0
    ) {
      this.showModal = false;
    } else {
      this.showModal = true;
    }
    this.dataRes = data;
  }
}
