import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-rivers',
  templateUrl: './rivers.component.html',
  styleUrls: ['./rivers.component.css']
})
export class RiversComponent {
  public data!: any;
  public dataMap: any = {};

  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  constructor( private transboundaryWaterService: TransboundaryWaterServiceService,
    private homeStore: HomeStore, 
    private spinner: NgxSpinnerService, ) {
      if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
      else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
      else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
      else this.isTri = true;
  }
  
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    }  
    if (this.isUSA) {
      this.getDataUs();
    } 
    if (this.isMx) {
      this.getDataMx();
    }
    if(this.isTri){
      this.getDataTri();
    }
    if(this.filter!=undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
     this.select = result ? result.itemId : null;
      this.filterInfo=this.filter;
      this.hasStudyButton=true;
    
    }

  }

  getDataUs() {
    this.dataMap = {
      center: MapCenter.USA,
      scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
      json: MapJson.USA,
      zoom: MapZoom.USA,
      dateFormat: 'YYYY',
      borderColor: '#000000'
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric'},
      addDays: 0
    };
    this.transboundaryWaterService.getRiverWaterUseUs()
    .subscribe(
      {
        next: (res) => {
          this.data = {
            data: res,
            boxplotTitle: 'River Water Use',
            histogramTitle: 'River Water Use Frecuency',
            mapTitle: 'River Water Use by state',
            description: 'info',
            title: 'River Water Use',
            mapInfo: this.dataMap,
            labelPopup: 'Irrigational-Crop', //Irrigation-Crop, surface-water withdrawals, fresh in Mgal/d
            filterInfo:this.filterInfo,
            selectedInfo:this.select,
            hasStudyButton:this.hasStudyButton,
          }
          this.spinner.hide();
        },
        error: (e) => { 
          console.error(e); 
          this.spinner.hide(); 
        }
      }
    );

  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataCN() {
    this.spinner.hide();
  }

  getDataTri(){
    this.spinner.hide();
  }
}

