import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpClient
} from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiRouting } from './api.routing';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient) {

  }

  get(path: string, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.get(`${ApiRouting.ApiUrl}${path}`,
      { params: params, headers: headers })
      .pipe(catchError(async (err) => await console.log(err)));
  }

  post(path: string, body: Object = {}, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders): Observable<any> {
    return this.http.post(`${ApiRouting.ApiUrl}${path}`,
      body,
      { params: params, headers: headers })
      .pipe(catchError(async (err) => await console.log(err)));
  }
  postMVM(path: string, body: Object = {}, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders): Observable<any> {
    return this.http.post(`${ApiRouting.ApiUrl}${path}`,
      body,
      { params: params, headers: headers })
      .pipe(catchError(async (err) => await console.log(err)));
  }
  postSync(path: string,
    body: Object = {},
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<any> {
    return this.http.post(
      `${ApiRouting.ApiUrl}${path}`,
      body,
      { params: params, headers: headers }
    ).pipe(
      catchError(this.formatErrors)
    );
  }

  private formatErrors(error: any) {
    return throwError(() => error.error);
  }
}
