import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { AuthService } from '../services/authService/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!environment.qa) return next.handle(req);
    const token = localStorage.getItem('TOKEN');

    let request = req;
    request = req.clone({
        setHeaders: {
            authorization: `Bearer ${ token  }`
        }
    });

    return next.handle(request).pipe(
      catchError(error => {
        // Si la solicitud falla debido a un token expirado
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout(); // Limpiar el token almacenado
          this.router.navigate(['auth/login'], { skipLocationChange: true }); //redireccionar al login
        }
        return throwError(error);
      })
    );
  }
}
