import { Injectable } from '@angular/core';
import { GraphItemModel, GraphLinkModel } from '../models/graph.model';
import * as graphCornFarming from '../utils/graph.corn-farming';
import * as graphTransboundaryWater from '../utils/graph.transboundary-water';
import * as graphMedicalIntrument from '../utils/graph.medical_instrument';
import * as graphPharma from '../utils/graph.pharma';
import * as graphPublicHealth from '../utils/graph.public_health';
import * as graphSawmillsWood from '../utils/graph.sawmills_wood';
import * as graphAutomobileEngine from '../utils/graph.automobile-engine';
import * as graphSemiconductor from '../utils/graph.semiconductor';
import * as graphPower from '../utils/graph.power';
import { AuthService } from '../services/authService/auth.service';
import { MainMenuService } from '../services/menu/main-menu-service';
import { MapMenuService } from '../services/menu/map-menu-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  firstValueFrom,
  map,
  BehaviorSubject,
  concatMap,
  of,
} from 'rxjs';
import {  mvmLinksWaterUSA } from '../utils/mvm.graph.transboundary-water';
import { mvmLinksWoodUSA } from '../utils/mvm.graph.sawmills-wood';
import { mvmLinksAutomobileUSA } from '../utils/mvm.automobile-engine';
import { mvmLinksCornFarmingUSA } from '../utils/mvm.corn-farming';
import { mvmLinksPharmaUSA } from '../utils/mvm.graph.pharma';
import { mvmLinksPowerUSA } from '../utils/mvm.graph.power';
import { mvmLinksSemiconductorUSA } from '../utils/mvm.graph.semiconductor';
import { mvmLinksMedicalInstrumentUSA } from '../utils/mvm.graph.medical_instrument';
import { mvmLinksPublicHealthUSA } from '../utils/mvm.graph.public_health';
import { GeneralService } from '../services/generalService/generalService.service';
@Injectable({
  providedIn: 'root',
})
export class HomeStore {
  private contextSubject = new BehaviorSubject<{
    countries: any[];
    countryScores?: any;
    sections: any[];
    country?: string;
    section?: any;
    detail?: string;
    dash?: string;
  }>({
    countries: [],
    sections: [],
    section: undefined,
    detail: undefined,
    country: 'USA',
    dash: 'USA',
  });
  public appContextObservable = this.contextSubject.asObservable();
  idScenarioList: any;
  selectedMainMenuOption: string = ''; //'Mexico';
  selectedCountryAbrev: string = '';//MEX
  private selectedSecondMenuOption: string = '';
  private selectedNode: string = '';
  private selectedNodeOriginal: string = '';
  private currentUrl!: string;
  //MVM Bayesian Net
  private mvmGraphItems: GraphItemModel[] = [];
  private mvmGraphLinks: GraphLinkModel[] = [];
  //Bayesian net
  private graphItems: GraphItemModel[] = [];
  private graphItemsbyTypeVar: GraphItemModel[] = [];
  private graphLinks: GraphLinkModel[] = [];
  private graphCategories: any[] = [];
  private graphCategoryLinks!: any[];
  
  public Nodecenter: number[] = [0, 0];
  public nodeCenterMvm: number[] = [0, 0];
  public zoom: number = 1;
  public showMenu: boolean = false;
  constructor(
    private generalservice: GeneralService,
    private menuService: MainMenuService,
    private mapMenuService: MapMenuService,
    private router: Router
  ) {
    combineLatest([
      //Here the data from the cards is brought.
      this.mapMenuService.getMenu(),
      this.menuService.getMenu({
        level: 1,
      }),
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
    ]).subscribe(([countriesSummary, sections, navigationEvt]) => {
      let currentState = {} as any;
      currentState.bayesianSummary = countriesSummary.resums || [];
      currentState.countries = countriesSummary.mapMenu || [];
      currentState.sections = (sections || []) as any[];
      let sectionStr = '';
      let detailStr = '';
      if (navigationEvt) {
        let urlSections = (navigationEvt as NavigationEnd).url
          .split('?')[0]
          .split('/');
        if (urlSections[1]) {
          currentState.country = urlSections[1];
          currentState.dash =
            currentState.country == 'Mexico'
              ? 'MEX'
              : currentState.country == 'Canada'
              ? 'CAN'
              : currentState.country == 'Trinational'
              ? 'TRI'
              : currentState.country == 'USA'
              ? 'USA'
              : '';
          this.selectedCountryAbrev = currentState.dash;
        }
        sectionStr = urlSections[2];
        if (urlSections[3]) detailStr = urlSections[3];
      }
      currentState.section = currentState.sections.find(
        (s: any) => s.id == sectionStr
      );
      currentState.detail = detailStr;
      //console.log('current state', currentState);
      currentState.countryScores = currentState.bayesianSummary.find(
        (c: { net: any }) => c.net == currentState.section.id
      )
      this.updateContext(currentState);
    });
  }
  updateContext(context: any, merge = true) {
    //console.log(context, 'contexto')
    let cxt = context;
    if (merge)
      cxt = {
        ...this.contextSubject.value,
        ...context,
      };
    this.setGraphItemsAndLinks(cxt).then(() => this.contextSubject.next(cxt));
  }

  setSelectedMainMenuOption(option: string) {
    this.selectedMainMenuOption = option;
  }
  getSelectedMainMenuOption(): string {
    return this.selectedMainMenuOption;
  }
  getSelectedCountryAbrev(): string {
    return this.selectedCountryAbrev;
  }
  setSelectedSecondMenuOption(option: string) {
    this.selectedSecondMenuOption = option;
  }
  getSelectedSecondMenuOption(): string {
    return this.selectedSecondMenuOption;
  }
  setSelectedNode(node: string): void {
    this.selectedNode = node;
  }
  getSelectedNode(): string {
    return this.selectedNode;
  }
  setSelectedNodeOriginal(node: string): void {
    this.selectedNodeOriginal = node;
  }
  getSelectedNodeOriginal(): string {
    return this.selectedNodeOriginal;
  }
  getDataForURL() {
    let x = {
      dash: this.selectedMainMenuOption,
      net: this.selectedSecondMenuOption,
      node: this.selectedNode,
    };
    return x;
  }
  getCurrentUrl(): string {
    return this.currentUrl;
  }
  async setGraphItemsAndLinks(ctx?: any) {
    if (!ctx) ctx = this.contextSubject.value;
    if (!ctx?.country || !ctx?.section?.url) return;
    this.selectedMainMenuOption = ctx.country || 'MEX';
    this.selectedSecondMenuOption = ctx.section?.url;
    this.currentUrl = `${this.selectedMainMenuOption}/${this.selectedSecondMenuOption}`;
    let mdl: {
      nameNet: string;
      nameDash: string;
    };

    let dash: string = 'MEX';

    if (this.selectedMainMenuOption == 'Mexico') dash = 'MEX';
    if (this.selectedMainMenuOption == 'Canada') dash = 'CAN';
    if (this.selectedMainMenuOption == 'Trinational') dash = 'TRI';
    if (this.selectedMainMenuOption == 'USA') dash = 'USA';

     //idScenario -> risk scenario tab
    let datosmodel = {
      net: this.selectedSecondMenuOption,
      country: dash,
    };

    this.idScenarioList = await firstValueFrom(
      this.generalservice.getScenarios(datosmodel)
    );

    mdl = {
      nameNet: this.selectedSecondMenuOption,
      nameDash: dash,
    };
    //GENERAL BAYESIAN NET
    this.graphItems = await firstValueFrom(
      this.generalservice.getGraphItems(mdl)
    );
    // MVM BAYESIAN NET
    this.mvmGraphItems = await firstValueFrom(
      this.generalservice.getGraphItemsMVM(mdl)
    );
    switch (this.selectedSecondMenuOption) {
      case 'corn-farming':
        //this.graphItems = graphCornFarming.CornFarmingGraphItems;
        this.mvmGraphLinks = mvmLinksCornFarmingUSA;
        this.graphLinks = graphCornFarming.CorFarmingGraphLinks;
        this.graphCategories = graphCornFarming.CornFarmingGraphCategory;
        this.graphCategoryLinks = graphCornFarming.CornFarmingGraphCategoryLink;
        this.Nodecenter = [2400, 600];
        this.nodeCenterMvm = [1620, 600];
        this.zoom = 1.25;
        break;
      case 'transboundary-water':
        //this.graphItems = graphTransboundaryWater.TransboundaryWaterItems;
        this.mvmGraphLinks = mvmLinksWaterUSA;
        this.graphLinks = graphTransboundaryWater.TransboundaryWaterLinks;
        this.graphCategories =
          graphTransboundaryWater.TransboundaryWaterGraphCategory;
        this.graphCategoryLinks =
          graphTransboundaryWater.TransboundaryWaterGraphCategoryLink;
        this.Nodecenter = [2340, 900];
        this.nodeCenterMvm = [1800, 700];
        this.zoom = 1.25;
        break;
      case 'sawmills-production':
        //this.graphItems = graphSawmillsWood.WoodGraphItems;
        //this.mvmGraphItems = mvmDataWoodUSA;
        this.mvmGraphLinks = mvmLinksWoodUSA;
        this.graphLinks = graphSawmillsWood.WoodGraphLinks;
        this.graphCategories = graphSawmillsWood.WoodGraphCategory;
        this.graphCategoryLinks = graphSawmillsWood.WoodGraphCategoryLink;
        this.Nodecenter = [450, 45];
        this.nodeCenterMvm = [1300, 600];
        this.zoom = 1.15;
        break;
      case 'public-health-info':
        //this.graphItems = graphPublicHealth.PublicHealthGraphItems;
        //this.mvmGraphItems = [];
        this.mvmGraphLinks = mvmLinksPublicHealthUSA;
        this.graphLinks = graphPublicHealth.PublicHealthGraphLinks;
        this.graphCategories = graphPublicHealth.PublicHealthGraphCategory;
        this.graphCategoryLinks =
          graphPublicHealth.PublicHealthGraphCategoryLink;
        this.Nodecenter = [32, 5];
        this.nodeCenterMvm = [3200, 600];
        this.zoom = 1.15;
        break;
      case 'inorganic-chemical':
        //this.graphItems = graphPharma.PharmaGraphItems;
        this.mvmGraphLinks = mvmLinksPharmaUSA
        this.graphLinks = graphPharma.PharmaGraphLinks;
        this.graphCategories = graphPharma.PharmaGraphCategory;
        this.graphCategoryLinks = graphPharma.PharmaGraphCategoryLink;
        this.Nodecenter = [43, 10];
        this.nodeCenterMvm = [1300, 600];
        this.zoom = 1.25;
        break;
      case 'medical-device':
        //this.graphItems = graphMedicalIntrument.MedicalInstrumentsGraphItems;
        this.mvmGraphLinks = mvmLinksMedicalInstrumentUSA;
        this.graphLinks = graphMedicalIntrument.MedicalInstrumentsGraphLinks;
        this.graphCategories =
          graphMedicalIntrument.MedicalInstrumentsGraphCategory;
        this.graphCategoryLinks =
          graphMedicalIntrument.MedicalInstrumentsGraphCategoryLink;
        this.Nodecenter = [180, 0];
        this.nodeCenterMvm = [1300, 800];
        this.zoom = 1.5;
        break;
      case 'semiconductor-selectronic':
        //this.graphItems = graphSemiconductor.SemiconductorsGraphItems;
        this.mvmGraphLinks = mvmLinksSemiconductorUSA;
        this.graphLinks = graphSemiconductor.SemiconductorsGraphLinks;
        this.graphCategories = graphSemiconductor.SemiconductorsGraphCategory;
        this.graphCategoryLinks =
          graphSemiconductor.SemiconductorsGraphCategoryLink;
        this.Nodecenter = [50, -10];
        this.nodeCenterMvm = [1300, 800];
        this.zoom = 1.5;
        break;
      case 'automobile-engine':
        //console.log('case automobile')
        this.mvmGraphLinks = mvmLinksAutomobileUSA;
        //this.graphItems = graphAutomobileEngine.AutomotiveGraphItems;
        this.graphLinks = graphAutomobileEngine.AutomotiveGraphLinks;
        this.graphCategories = graphAutomobileEngine.AutomotiveGraphCategory;
        this.graphCategoryLinks =
          graphAutomobileEngine.AutomotiveGraphCategoryLink;
        this.Nodecenter = [2000, 900];
        this.nodeCenterMvm = [1300, 500];
        this.zoom = 1.5;
        break;
      case 'power':
        //this.graphItems = graphPower.PowerGraphItems;
        this.mvmGraphLinks = mvmLinksPowerUSA;
        this.graphLinks = graphPower.PowerGraphLinks;
        this.graphCategories = graphPower.PowerGraphCategory;
        this.graphCategoryLinks = graphPower.PowerGraphCategoryLink;
        this.Nodecenter = [10, -5];
        this.nodeCenterMvm = [1800, 500];
        this.zoom = 1.5;
        break;
      default:
        this.mvmGraphItems = [];
        this.mvmGraphLinks = [];
        this.graphItems = [];
        this.graphLinks = [];
        this.graphCategories = [];
        this.graphCategoryLinks = [];
        break;
    }
  }
  async setGraphItemsByVarType(varType: string) {
    let mdl: {
      nameNet: string;
      nameDash: string;
      variableType: string;
    };

    let dash: string = '';

    if (this.selectedMainMenuOption == 'Mexico') dash = 'MEX';
    if (this.selectedMainMenuOption == 'Canada') dash = 'CAN';
    if (this.selectedMainMenuOption == 'Trinational') dash = 'TRI';
    if (this.selectedMainMenuOption == 'USA') dash = 'USA';

    mdl = {
      nameNet: this.selectedSecondMenuOption,
      nameDash: dash,
      variableType: varType,
    };

    //console.log(mdl);
    await firstValueFrom(
      this.generalservice.getGraphItemsByFilterVariableType(mdl)
    ).then((res: any) => {
      if (res) {
        this.graphItemsbyTypeVar = res;
      }
    });
  }
  getGraphItemsByVarType(): GraphItemModel[] {
    return this.graphItemsbyTypeVar;
  }
  getGraphItems(): GraphItemModel[] {
    return this.graphItems;
  }
  getGraphLinks(): GraphLinkModel[] {
    return this.graphLinks;
  }
  getGraphCategories(): any[] {
    return this.graphCategories;
  }
  getGraphCategoryLinks(): any[] {
    return this.graphCategoryLinks;
  }
  // MVM BAYESIAN NET
  getMVMGraphItems(): GraphItemModel[] {
    return this.mvmGraphItems;
  }
  getMVMGraphLinks(): GraphLinkModel[] {
    return this.mvmGraphLinks;
  }
  getIdScenario(){
    return this.idScenarioList;
  }
}
