import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

/* Categories */
export const SemiconductorsGraphCategory: any[] = [
  /*{ id: 0, name: 'Natural', itemStyle: { color: '#438bca' } },
  { id: 1, name: 'Anthropogenic', itemStyle: { color: '#006483' } },
  { id: 2, name: 'Raw Material', itemStyle: { color: '#c17a2f' } },
  { id: 3, name: 'Suppliers', itemStyle: { color: '#2b0171' } },
  { id: 4, name: 'Vendor/Manufacturer', itemStyle: { color: '#707070' } },
  { id: 5,name: 'Logistic/Retailers', itemStyle: { color: '#5a6237' } },
  { id: 6,name: 'Customers', itemStyle: { color: '#b0b1b1' } },
  { id: 7,name: '--', itemStyle: { color: '#500101' } },
  { id: 8,name: 'Social', itemStyle: { color: '#ad9d68' } },
  { id: 9,name: 'Economic', itemStyle: { color: '#ad9d68' } },
  { id: 10,name: 'Environmental', itemStyle: { color: '#ad9d68' } },
  { id: 11,name: 'Critical Infrastructure', itemStyle: { color: '#4966b0' } },*/
  { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
  { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
  { id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
  { id: 3,name: 'Raw Materials / Sources', itemStyle: { color: '#ff9900' } },
  { id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
  { id: 5,name: 'Logistic / Distribution ad2', itemStyle: { color: '#500101' } },
  { id: 6,name: 'Wholesalers / Retailers', itemStyle: { color: '#ccffcc' } },
  { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
  { id: 8,name: 'Logistic qwe2', itemStyle: { color: '#9d1010' } },
  { id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
  { id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
  { id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
  { id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
  { id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
  { id: 14,name: 'Logistic', itemStyle: { color: '#993365' } },
  { id: 15, name: 'Production', itemStyle: { color: '#a0a0a0' } },
  
];

export const SemiconductorsGraphCategoryLink: any[] = [
  { name: 'Threats', links: [SemiconductorsGraphCategory[1], SemiconductorsGraphCategory[0], SemiconductorsGraphCategory[11]]},
  { name: 'Systems', links: [SemiconductorsGraphCategory[2], SemiconductorsGraphCategory[3], 
        SemiconductorsGraphCategory[4], SemiconductorsGraphCategory[5],SemiconductorsGraphCategory[6],
        SemiconductorsGraphCategory[7]
    ]},
  { name: 'State of Risk', links: [SemiconductorsGraphCategory[8], SemiconductorsGraphCategory[9], 
      SemiconductorsGraphCategory[10]
    ]}
]

/*End categories */

/*NODES*/
export const SemiconductorsGraphItems: GraphItemModel[] = [
  //#region --
  { fullname: 'Shipping Ports', name: 'Shipping\nPorts', symbol: 'circle', x: -4, y: 9, category: 7, isActive: false },
  { fullname: 'Barges', name: 'Barges', symbol: 'circle', x: 0, y: 9, category: 7, isActive: false },
  { fullname: 'Trucks', name: 'Trucks', symbol: 'circle', x: 5, y: 9, category: 7, isActive: false },
  { fullname: 'Vessels', name: 'Vessels', symbol: 'circle', x: 10, y: 9, category: 7, isActive: false },
  //#endregion
 
  //#region Athropogenic
  { fullname: 'Economic Competition', name: 'Economic\nCompetition', symbol: 'circle', x: -30, y: -23, category: 1, isActive: false },
  { fullname: 'Shipping bag reliability', name: 'Shipping\nbag\nreliability', symbol: 'circle', x: -30, y: -15, category: 1, isActive: false },
  { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage (S)', symbol: 'circle', x: -30, y: -10, category: 1, isActive: false },
  { fullname: 'Labor Shortage (M)', name: 'Labor\nShortage (M)', symbol: 'circle', x: -30, y: -5, category: 1, isActive: false },
  
  { fullname: 'Offshoring ICT production', name: 'Offshoring ICT\nproduction', symbol: 'circle', x: -24, y: -25, category: 1, isActive: false },
  { fullname: 'Pandemic/COVID-19', name: 'Pandemic/\nCOVID-19', symbol: 'circle', x: -24, y: -20, category: 1, isActive: false },
  { fullname: 'Inefficient facilities', name: 'Inefficient\nfacilities', symbol: 'circle', x: -24, y: -15, category: 1, isActive: false },
  { fullname: 'Stockpiles', name: 'Stockpiles', symbol: 'circle', x: -24, y: -10, category: 1, isActive: false },
  { fullname: 'International Competition', name: 'International\nCompetition', symbol: 'circle', x: -24, y: -5, category: 1, isActive: false },
  { fullname: 'Labor Shortage (C)', name: 'Labor\nShortage (C)', symbol: 'circle', x: -24, y: 0, category: 1, isActive: false },
  { fullname: 'Labor Shortage (R)', name: 'Labor\nShortage (R)', symbol: 'circle', x: -24, y: 5, category: 1, isActive: false },
  
  { fullname: 'National Funding', name: 'National\nFunding', symbol: 'circle', x: -15, y: -28, category: 1, isActive: false },
  { fullname: 'Local Investments', name: 'Local\nInvestments', symbol: 'circle', x: -15, y: -23, category: 1, isActive: false },
  { fullname: 'Global reach', name: 'Global\nreach', symbol: 'circle', x: -15, y: -18, category: 1, isActive: false },
  { fullname: 'Public Health', name: 'Public\nHealth', symbol: 'circle', x: -15, y: -13, category: 1, isActive: false },
  { fullname: 'Drinking Water', name: 'Drinking\nWater', symbol: 'circle', x: -15, y: -8, category: 1, isActive: false },
  { fullname: 'Globally Distributed Supply Chain', name: 'Globally\nDistributed\nSupply Chain', symbol: 'circle', x: -15, y: -3, category: 1, isActive: false },
  { fullname: 'Crypto Mining', name: 'Crypto\nMining', symbol: 'circle', x: -15, y: 2, category: 1, isActive: false },
  
  { fullname: 'Counterfeit parts', name: 'Counterfeit\nParts', symbol: 'circle', x: -8, y: -25, category: 1, isActive: false },
  { fullname: 'Geopolitical tension', name: 'Geopolitical\ntension', symbol: 'circle', x: -8, y: -20, category: 1, isActive: false },
  { fullname: 'Geographic Concentration', name: 'Geographic\nConcentration', symbol: 'circle', x: -8, y: -15, category: 1, isActive: false },
  { fullname: 'Refiance on Imports', name: 'Refiance on\nImports', symbol: 'circle', x: -8, y: -10, category: 1, isActive: false },
  { fullname: 'Theft', name: 'Theft', symbol: 'circle', x: -8, y: -5, category: 1, isActive: false },
  { fullname: 'Speciality Expertise', name: 'Speciality\nExpertise', symbol: 'circle', x: -8, y: 0, category: 1, isActive: false },
 
  { fullname: 'Terrorist attacks', name: 'Terrorist\nattacks', symbol: 'circle', x: -2, y: -23, category: 1, isActive: false },
  { fullname: 'Derived Demand', name: 'Derived\nDemand', symbol: 'circle', x: -2, y: -7, category: 1, isActive: false },
  //#endregion

  //#region Natural
  { fullname: 'Drought', name: 'Drought', symbol: 'circle', x: -28, y: 10, category: 0, isActive: true },
  { fullname: 'Hails', name: 'Hails', symbol: 'circle', x: -28, y: 15, category: 0, isActive: false },
  
  { fullname: 'Heavy winds', name: 'Heavy\nwinds', symbol: 'circle', x: -23, y: 10, category: 0, isActive: false },
  { fullname: 'Icy roads', name: 'Icy\nroads', symbol: 'circle', x: -23, y: 15, category: 0, isActive: false },
  
  { fullname: 'Earthquake', name: 'Earthquake', symbol: 'circle', x: -18, y: 7, category: 0, isActive: false },
  { fullname: 'Fires', name: 'Fires', symbol: 'circle', x: -18, y: 11, category: 0, isActive: true },
  { fullname: 'Winterstorm', name: 'Winterstorm', symbol: 'circle', x: -18, y: 15, category: 0, isActive: false },
  
  { fullname: 'Rainfall', name: 'Rainfall', symbol: 'circle', x: -13, y: 10, category: 0, isActive: false },
  //#endregion
  
  //#region Raw material
  { fullname: 'Silicon', name: 'Silicon', symbol: 'circle', x: 4, y: -20, category: 2, isActive: true },
  { fullname: 'Germanium', name: 'Germanium', symbol: 'circle', x: 4, y: -15, category: 2, isActive: false },
  { fullname: 'Electricity', name: 'Electricity', symbol: 'circle', x: 4, y: -10, category: 2, isActive: false },
  { fullname: 'Fuel', name: 'Fuel', symbol: 'circle', x: 4, y: -5, category: 2, isActive: false },
  
  { fullname: 'Carbon', name: 'Carbon', symbol: 'circle', x: 10, y: -18, category: 2, isActive: true },
  { fullname: 'Nanomagnets/Nanoelectronics', name: 'Nanomagnets/\nNanoelectronics', symbol: 'circle', x: 10, y: -6, category: 2, isActive: false },
  { fullname: 'Gallium', name: 'Gallium', symbol: 'circle', x: 10, y: -13, category: 2, isActive: false },
  //#endregion 
 
  //#region suppliers
  { fullname: 'Component Suppliers', name: 'Component\nSuppliers', symbol: 'circle', x: 17, y: -25, category: 3, isActive: false },
  { fullname: 'Raw Material Supplier', name: 'Raw Material\nSupplier', symbol: 'circle', x: 17, y: -20, category: 3, isActive: false },
  { fullname: 'Global Trade', name: 'Global\nTrade', symbol: 'circle', x: 17, y: -15, category: 3, isActive: false },
  { fullname: 'Electricity', name: 'Electricity ', symbol: 'circle', x:17, y: -10, category: 3, isActive: false },
  { fullname: 'Fuel', name: 'Fuel ', symbol: 'circle', x: 17, y: -5, category: 3, isActive: false },
  //#endregion
  
  //#region Vendor/Manufacturer
  { fullname: 'Electricity', name: 'Electricity  ', symbol: 'circle', x: 25, y: -8, category: 4, isActive: false },
  { fullname: 'Fuel', name: 'Fuel  ', symbol: 'circle', x: 25, y: -3, category: 4, isActive: false },
  
  { fullname: 'Processing Facilities', name: 'Processing\nFacilities', symbol: 'circle', x: 25, y: -18, category: 4, isActive: false },
  { fullname: 'Worker Health Safety', name: 'Worker\nHealth\nSafety', symbol: 'circle', x: 25, y: -13, category: 4, isActive: false },
  
  { fullname: 'Chip Manufacturing', name: 'Chip\nManufacturing', symbol: 'circle', x: 25, y: -28, category: 4, isActive: false },
  { fullname: 'Material Supply', name: 'Material\nSupply', symbol: 'circle', x: 25, y: -23, category: 4, isActive: false },
  //#endregion

  //#region Logistic/Retailer
  { fullname: 'Data technology Companies', name: 'Data\ntechnology\nCompanies', symbol: 'circle', x: 32, y: -23, category: 5, isActive: false },
  { fullname: 'North American Global Market Share', name: 'North\nAmerican\nGlobal\nMarket\nShare', symbol: 'circle', x: 32, y: -15, category: 5, isActive: false },
  
  { fullname: 'Electricity', name: 'Electricity   ', symbol: 'circle', x: 32, y: -10, category: 5, isActive: false },
  { fullname: 'Fuel', name: 'Fuel   ', symbol: 'circle', x: 32, y: -5, category: 5, isActive: false },
  //#endregion

  //#region Customers
  { fullname: 'Automotive Industry', name: 'Automotive\nIndustry', symbol: 'circle', x: 38, y: -25, category: 6, isActive: false },
  { fullname: 'Medical Device Manufacturing', name: 'Medical\nDevice\nManufacturing', symbol: 'circle', x: 42, y: -20, category: 6, isActive: false },
  { fullname: 'Telecomunication Industry', name: 'Telecomunication\nIndustry', symbol: 'circle', x: 38, y: -14, category: 6, isActive: false },
  { fullname: 'Electric Vehicles Market', name: 'Electric\nVehicles\nMarket', symbol: 'circle', x: 42, y: -10, category: 6, isActive: false },
  { fullname: 'Smarthphone Industry', name: 'Smarthphone\nIndustry', symbol: 'circle', x: 38, y: -4, category: 6, isActive: true },
  { fullname: 'Military Systems', name: 'Military\nSystems', symbol: 'circle', x: 42, y: -1, category: 6, isActive: false },
  //#endregion
  
  //#region State of risk
  //social
  { fullname: 'Customer Satisfaction', name: 'Customer\nSatisfaction', symbol: 'rect', x: 50, y: -28, category: 8, isActive: false },
  { fullname: 'Quality of Goods', name: 'Quality\nof Goods', symbol: 'rect', x: 57, y: -25, category: 8, isActive: false },
  { fullname: 'Reputation', name: 'Reputation', symbol: 'rect', x: 50, y: -23, category: 8, isActive: false },
  
  //economic
  { fullname: 'Unemployment', name: 'Unemployment', symbol: 'rect', x: 50, y: -16, category: 9, isActive: false },
  { fullname: 'Revenue', name: 'Revenue', symbol: 'rect', x: 50, y: -11, category: 9, isActive: false },
  { fullname: 'Production Cost', name: 'Production\nCost', symbol: 'rect', x: 50, y: -6, category: 9, isActive: false },
  { fullname: 'Production Rate', name: 'Production\nRate', symbol: 'rect', x: 50, y: -1, category: 9, isActive: true },
 
  { fullname: 'Data Breach', name: 'Data\nBreach', symbol: 'rect', x: 57, y: -16, category: 9, isActive: false },
  { fullname: 'Legal Penalties', name: 'Legal\nPenalties', symbol: 'rect', x: 57, y: -11, category: 9, isActive: false },
  { fullname: 'Property Damage', name: 'Property\nDamage', symbol: 'rect', x: 57, y: -6, category: 9, isActive: false },
  { fullname: 'Supply Chain Management cost', name: 'Supply\nChain\nManagement\ncost', symbol: 'rect', x: 57, y: 0, category: 9, isActive: false },
  
  //environmental
  { fullname: 'Facility Shut Down', name: 'Facility Shut\nDown', symbol: 'rect', x: 50, y: 7, category: 10, isActive: false },
  { fullname: 'Death', name: 'Death', symbol: 'rect', x: 50, y: 12, category: 10, isActive: false },
  { fullname: 'Environmental\nDamage', name: 'Environmental\nDamage', symbol: 'rect', x: 57, y: 10, category: 10, isActive: false },
  //#endregion
 
  //#region Critical
  { fullname: 'Storm Water Infrastructure', name: 'Storm Water\nInfrastructure', x: -30, y: 22, category: 11, isActive: false },
  { fullname: 'Waste Water', name: 'Waste\nWater', x: -30, y: 27, category: 11, isActive: false },
  { fullname: 'Solidwaste Infrastructure', name: 'Solidwaste\nInfraestructure', x: -30, y: 32, category: 11, isActive: false },
  
  { fullname: 'Public Transportation', name: 'Public Transportation', x: -20, y: 22, category: 11, isActive: false },
  { fullname: 'Hazardous Waste', name: 'Hazardous\nWaste', x: -20, y: 27, category: 11, isActive: false },
  
  { fullname: 'Electricity', name: 'Electricity    ', x: -10, y: 22, category: 11, isActive: false },
  { fullname: 'Fuel', name: 'Fuel    ', x: -10, y: 27, category: 11, isActive: false },
  { fullname: 'Roads', name: 'Roads', x: -10, y: 32, category: 11, isActive: false },
  
  { fullname: 'Broadband Infrastructure (RM)', name: 'Broadband\nInfrastructure(RM)', x: 0, y: 22, category: 11, isActive: false },
  { fullname: 'Aviation', name: 'Aviation', x: 0, y: 27, category: 11, isActive: false },
  
  { fullname: 'Broadband Infrastructure (S-M)', name: 'Broadband\nInfrastructure(S-M)', x: 10, y: 22, category: 11, isActive: false },
  { fullname: 'Inland Waterways', name: 'Inland\nWaterways', x: 10, y: 27, category: 11, isActive: false },
  { fullname: 'Rails', name: 'Rails', x: 10, y: 32, category: 11, isActive: false },

  { fullname: 'Broadband Infrastructure (M-R)', name: 'Broadband\nInfrastructure(M-R)', x: 20, y: 22, category: 11, isActive: false },
  { fullname: 'Broadband Infrastructure (R-C)', name: 'Broadband\nInfrastructure(R-C)', x: 20, y: 27, category: 11, isActive: false },
 
  //#endregion

  //Invisible for size
  { fullname: 'invisible', name: 'invisible', symbol: 'circle', x: 80, y: 12.1, itemStyle: { color: '#fff', borderWidth: 0 }, label: { color: '#fff' }, isActive: true },
  
];
/*End Nodes*/



/* Links */
export const SemiconductorsGraphLinks: GraphLinkModel[] = [
  { source: 'Aviation' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nreach' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Globally\nDistributed\nSupply Chain' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'heavy\nprecipitation\n(rainfall)' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (C)' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Material\nSupply' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-19' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Terrorist\nattacks' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Winterstorm' , target: 'Automotive\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Carbon' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Component\nSupplier' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nTrade' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Nanomagnets/\nNanoelectronics' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nSupplier' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Silicon' , target: 'Aviation',   lineStyle: { curveness: 0.1 } },
  { source: 'Carbon' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Chip\nManufacturing' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Component\nSupplier' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nTrade' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Material\nSupply' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Nanomagnets/\nNanoelectronics' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Ports' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nSupplier' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Silicon' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Carbon',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Carbon',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Carbon',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Carbon',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(S-M)' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Counterfeit\nParts' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Crypto\nMining' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Drought' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Hails' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Heavy\nwinds' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'National\nFunding' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-20' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Speciality\nExpertise' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Stockpiles' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Terrorist\nattacks' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Chip\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Carbon' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Component\nSupplier' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Nanomagnets/\nNanoelectronics' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Processing\nFacilities' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nbag\nreliability' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Silicon' , target: 'Chip\nQuality',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Component\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Component\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Component\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nbag\nreliability' , target: 'Customer\nSatisfaction',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Data\nBreach',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(M-R)' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (R)' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Rails' , target: 'Data\ntechnology\nCompanies',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Data\ntechnology\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Speciality\nExpertise' , target: 'Data\ntechnology\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Automotive\nIndustry' , target: 'Death',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Death',   lineStyle: { curveness: 0.1 } },
  { source: 'Electric\nVehicles Market' , target: 'Death',   lineStyle: { curveness: 0.1 } },
  { source: 'Telecomunication\nIndustry' , target: 'Death',   lineStyle: { curveness: 0.1 } },
  { source: 'Worker\nHealth\nSafety' , target: 'Death',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Electric\nVehicles (EV)\nMarket',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nreach' , target: 'Electric\nVehicles (EV)\nMarket',   lineStyle: { curveness: 0.1 } },
  { source: 'Speciality\nExpertise' , target: 'Electric\nVehicles (EV)\nMarket',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (C)' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Electric\nVehicles Market',   lineStyle: { curveness: 0.1 } },
  { source: 'Crypto\nMining' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nCompetition' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
  { source: 'Automotive\nIndustry' , target: 'Environmental\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'Electric\nVehicles Market' , target: 'Environmental\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'smartphones' , target: 'Environmental\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'Telecomunication\nIndustry' , target: 'Environmental\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'Worker\nHealth\nSafety' , target: 'Facility Shut\nDown',   lineStyle: { curveness: 0.1 } },
  { source: 'Drought' , target: 'Fires',   lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nCompetition' , target: 'Gallium',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Gallium',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Gallium',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Gallium',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'Geopolitical\ntension',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-21' , target: 'Geopolitical\ntension',   lineStyle: { curveness: 0.1 } },
  { source: 'Terrorist\nattacks' , target: 'Geopolitical\ntension',   lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nCompetition' , target: 'Germanium',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Germanium',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Germanium',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-22' , target: 'Global\nreach',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(S-M)' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Geographic\nConcentration' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-23' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Global\nTrade',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nTrade' , target: 'Inland\nWaterways',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth' , target: 'Labor\nShortage (C)',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation' , target: 'Labor\nShortage (C)',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation' , target: 'Labor\nShortage (R)',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nTransportation' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
  { source: 'North America Global\nMarket Share' , target: 'Legal\nPenalties',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'Local\nInvestments',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(S-M)' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-24' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Terrorist\nattacks' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Material\nSupply',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (C)' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Medical\nDevice\nManufacturing',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'International\nCompetition' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Military\nSystems',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Nanomagnets/\nNanoelectronics',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(RM)' , target: 'Nanomagnets/\nNanoelectronics',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Nanomagnets/\nNanoelectronics',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Nanomagnets/\nNanoelectronics',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Nanomagnets/\nNanoelectronics',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Geographic\nConcentration' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'International\nCompetition' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Local\nInvestments' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-25' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Rails' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Theft' , target: 'North America Global\nMarket Share',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(S-M)' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Counterfeit\nParts' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Drought' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (M)' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Speciality\nExpertise' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Processing\nFacilities',   lineStyle: { curveness: 0.1 } },
  { source: 'Automotive\nIndustry' , target: 'Production\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Production\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Production\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Production\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Telecomunication\nIndustry' , target: 'Production\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Production\nRate',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Production\nRate',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Property\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nbag\nreliability' , target: 'Property\nDamage',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Public\nHealth',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-26' , target: 'Public\nHealth',   lineStyle: { curveness: 0.1 } },
  { source: 'Chip\nManufacturing' , target: 'Rails',   lineStyle: { curveness: 0.1 } },
  { source: 'Material\nSupply' , target: 'Rails',   lineStyle: { curveness: 0.1 } },
  { source: 'Winterstorm' , target: 'Rails',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'raw\nMaterial\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'raw\nMaterial\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'raw\nMaterial\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (S)' , target: 'raw\nMaterial\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'raw\nMaterial\nSupplier',   lineStyle: { curveness: 0.1 } },
  { source: 'Automotive\nIndustry' , target: 'Reputation',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Reputation',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Electric\nVehicles Market' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Material\nSupply' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Medical\nDevice\nManufacturing' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Military System' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'North America Global\nMarket Share' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'smartphones' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Telecomunication\nIndustry' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
  { source: 'Icy\nroads' , target: 'Roads',   lineStyle: { curveness: 0.1 } },
  { source: 'smartphones' , target: 'Service\nFailure\nCost',   lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nCompetition' , target: 'Shipping\nbag\nreliability',   lineStyle: { curveness: 0.1 } },
  { source: 'Globally\nDistributed\nSupply Chain' , target: 'Shipping\nbag\nreliability',   lineStyle: { curveness: 0.1 } },
  { source: 'Earthquake' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nTrade' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Heavy\nwinds' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-27' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Terrorist\nattacks' , target: 'Shipping\nPorts',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Geographic\nConcentration' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Geopolitical\ntension' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Reliance on\nImports' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Silicon',   lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nCompetition' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'smartphones',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (C)' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Material\nSupply' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-28' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Smartphone\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nreach' , target: 'smartphones',   lineStyle: { curveness: 0.1 } },
  { source: 'Pandemic/\nCOVID-29' , target: 'Supply and\ndemand\ninconsistencies',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nbag\nreliability' , target: 'Supply\nChain\nManagement\ncost',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nPorts' , target: 'Supply\nChain\nManagement\ncost',   lineStyle: { curveness: 0.1 } },
  { source: 'Shipping\nPorts' , target: 'Supply\nChain\nManagement\ncost',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure(R-C)' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Derived\nDemand' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'International\nCompetition' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage (C)' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Telecommunication\nIndustry',   lineStyle: { curveness: 0.1 } },
  { source: 'North America Global\nMarket Share' , target: 'Supply\nChain\nManagement\ncost',   lineStyle: { curveness: 0.1 } },
  { source: 'Carbon' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Component\nSupplier' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Data\ntechnology\nCompanies' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Germanium' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Global\nTrade' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Nanomagnets/\nNanoelectronics' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Raw\nMaterial\nSupplier' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Roads' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Silicon' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Winterstorm' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
  { source: 'Electric\nVehicles Market' , target: 'Unemployment',   lineStyle: { curveness: 0.1 } },
  { source: 'North America Global\nMarket Share' , target: 'Unemployment',   lineStyle: { curveness: 0.1 } },
  { source: 'Inland\nWaterways' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
  { source: 'Aviation' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Barges' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Electricity' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Fires' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Fuel' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Gallium' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Hails' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'heavy\nprecipitation\n(rainfall)' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Heavy\nwinds' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Icy\nroads' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfraestructure' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Trucks' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Winterstorm' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Inefficient\nfacilities' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
  { source: 'Offshoring ICT\nproduction' , target: 'Worker\nHealth\nSafety',   lineStyle: { curveness: 0.1 } },
];


