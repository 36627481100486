import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ReferenceModel } from 'src/app/core/models/menu.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { concatMap, firstValueFrom, of } from 'rxjs';
import { GraphItemModel, GraphLinkModel } from '../../models/graph.model';
import { ApiServiceService } from '../../services/apiService/api-service.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnDestroy {
  public referenceData!: ReferenceModel[];
  private subscriber;
  tab = 'stories'; //'home';
  showGraphFlag = false;
  public tabSelected = 'stories';//'home';
  public baseUrl?: SafeResourceUrl;
  public isLoading = true;
  public mvmData: GraphItemModel[] = [];
  public mvmLinks: GraphLinkModel[] = [];
  public stories: any[] = [];
  public selectedStoryId: string | null = null;
  article: any = undefined;
  public country = '';
  public net = '';
  public riskScenario: any = undefined;
  public retenedorpais: any=null;
  public retenedormenu:any=null;

  baseAssets = environment.BaseAssets;
  isQA = true;//environment.qa;

  constructor(
    private homeService: HomeService,
    public homeStore: HomeStore,
    private api: ApiServiceService,
    private sanitizer: DomSanitizer
  ) {
    // firstValueFrom(this.api.get('arcgisscraper/list')).then((res) => {
    //   this.stories = res;
    // });
    this.subscriber = this.homeStore.appContextObservable
      .pipe(
        concatMap((ctx) => {
          if (!ctx.section?.url) return of([]);
          const model = { bayesianId: ctx.section?.url };
          return this.homeService.getBayesianReferences(model);
        })
      )
      .subscribe((res) => {
        this.country = this.homeStore.getSelectedCountryAbrev();
        this.net = this.homeStore.getSelectedSecondMenuOption();

      if(this.net != '' || this.country != ''){
        firstValueFrom(this.api.get(`arcgisscraper/list?country=${this.country}&target=${this.net}`)).then((res) => {
          this.stories = res;
        });

        if(this.retenedormenu==null || this.retenedorpais==null){
          this.retenedormenu=this.net;
          this.retenedorpais=this.country;
        }
        if(this.retenedormenu!=this.net || this.retenedorpais!=this.country){
          this.retenedormenu=this.net;
          this.retenedorpais=this.country;
          this.resetTab();
        
        if (this.tabSelected !== 'nlp' && this.tabSelected !== 'profile' && this.tabSelected !== 'riskscenario') {
          this.referenceData = res;
          this.tabSelected = this.isQA ? 'stories' : 'home';
          this.resetTab();
        }
      }
      }
      });
  }
  showGraph(event: any) {
    this.tab = event;
    this.showGraphFlag = true;
    this.tabSelected = event;
    setTimeout(() => window.dispatchEvent(new Event('nlp-tab')), 200);
    setTimeout(() => window.dispatchEvent(new Event('riskscenario-tab')), 200);
  }
  resetTab() {
    this.tab = this.isQA ? 'stories' : 'home';
    this.onSelectStory('');
    this.tabSelected = this.isQA ? 'stories' : 'home';

    document.querySelectorAll('[role="tab"]').forEach(function (el) {
      el.classList.remove('active');
    });
    if(this.isQA){
      document.getElementById('stories')?.classList.add('active');
      document.getElementById('stories-tab')?.classList.add('active');
    } else {
      document.getElementById('home')?.classList.add('active');
      document.getElementById('home-tab')?.classList.add('active');
    }
   
  }
  getMVM(mvm: string) {
    return true;
  }
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  setArticle(article: any) {
    this.article = article;
  }
  onIframeLoad(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
  }
  onSelectStory(storyId: string): void {
    if (storyId)
      this.baseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        (
          environment.BaseUrl +
          environment.Api +
          'arcgisscraper/story/' +
          storyId
        ).trim()
      );
    else this.baseUrl = undefined;
    this.selectedStoryId = storyId;
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
  }
}
