<div class="w-100" *ngIf="homeStore.appContextObservable | async as context">
  <div class="w-100 scorecards" *ngIf="context.countryScores">
    <div class="scorecard">
      <div>
        <div class="title truncate">Point process</div>
        <div class="count">{{ context.countryScores.pointProcess }}</div>
        <div class="line"></div>
        <div class="percentage">
          {{ context.countryScores.pointProcessPercentage }} %
        </div>
      </div>
      <img src="{{ baseAssets }}/main/Point Process.svg" alt="" />
    </div>
    <div class="scorecard">
      <div>
        <div class="title truncate">Time series</div>
        <div class="count">{{ context.countryScores.timeSerie }}</div>
        <div class="line"></div>
        <div class="percentage">
          {{ context.countryScores.timeSeriePercentage }} %
        </div>
      </div>
      <img src="{{ baseAssets }}/main/Time series.svg" alt="" />
    </div>
    <div class="scorecard">
      <div>
        <div class="title truncate">Spatial</div>
        <div class="count">{{ context.countryScores.spatial }}</div>
        <div class="line"></div>
        <div class="percentage">
          {{ context.countryScores.spatialPercentage }} %
        </div>
      </div>
      <img src="{{ baseAssets }}/main/Spatial.svg" alt="" />
    </div>
    <div class="scorecard">
      <div>
        <div class="title truncate">Spatial-temporal</div>
        <div class="count">{{ context.countryScores.spatialTemporal }}</div>
        <div class="line"></div>
        <div class="percentage">
          {{ context.countryScores.spatialTemporalPercentage }} %
        </div>
      </div>
      <img src="{{ baseAssets }}/main/Spatial-temporal.svg" alt="" />
    </div>
  </div>

  <!--TAB OPTIONS-->
  <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation" *ngIf="isQA == false">
      <button
        class="nav-link"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        [ngClass]="{'active': !isQA}"
        (click)="showGraph('home')"
      >
        <span class="textMedium" style="padding: 1.5rem">General</span>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="stories-tab"
        data-bs-toggle="tab"
        data-bs-target="#stories"
        type="button"
        role="tab"
        aria-controls="stories"
        aria-selected="false"
        [ngClass]="{'active': isQA}"
        (click)="showGraph('stories')"
      >
        <span class="textMedium" style="padding: 1.5rem">Stories</span>
      </button>
    </li>
    <li
      class="nav-item"
      role="presentation"
      *ngIf="
        [
          'USA/corn-farming',
          'USA/sawmills-production',
          'USA/automobile-engine',
          'Mexico/automobile-engine',
          'USA/transboundary-water',
          'Mexico/transboundary-water',
          'USA/inorganic-chemical',
          'USA/power',
          'Mexico/power',
          'USA/semiconductor-selectronic',
          'USA/medical-device',
          'USA/public-health-info'
        ].includes(context.country + '/' + context.section?.url)
      "
    >
      <button
        class="nav-link"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
        (click)="showGraph('profile')"
      >
        <span class="textMedium" style="padding: 1.5rem"
          >Minimum Viable Model</span
        >
      </button>
    </li>
    <li class="nav-item" role="presentation"
      *ngIf="
      context.country === 'USA'
    ">
      <button class="nav-link" type="button" role="tab"
        id="riskscenario-tab" 
        data-bs-toggle="tab"
        data-bs-target="#riskscenario"
        aria-controls="riskscenario"
        aria-selected="false"
        (click)="showGraph('riskscenario')"
      >
        <span class="textMedium" style="padding: 1.5rem">Risk Scenarios</span>
      </button>
    </li>
    <li
      class="nav-item"
      role="presentation"
      *ngIf="
      context.country === 'USA' && context.section?.url !== 'medical-device'
      "
    >
      <button
        class="nav-link"
        id="nlp-tab"
        data-bs-toggle="tab"
        data-bs-target="#nlp"
        type="button"
        role="tab"
        aria-controls="nlp"
        aria-selected="false"
        (click)="showGraph('nlp')"
      >
        <span class="textMedium" style="padding: 1.5rem">Natural Language Processing</span>
      </button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div *ngIf="isQA == false"
      class="tab-pane"
      id="home"
      role="tabpanel"
      [hidden]="tab != 'home'"
      aria-labelledby="home-tab"
      [ngClass]="{'active': !isQA}"
    >
      <div class="card half-border">
        <app-graph *ngIf="tabSelected == 'home'"></app-graph>
      </div>
    </div>
    <div
      class="tab-pane"
      id="profile"
      role="tabpanel"
      [hidden]="tab != 'profile'"
      aria-labelledby="profile-tab"
    >
      <div
        class="row"
        *ngIf="
          showGraphFlag == true &&
          [
            'USA/corn-farming',
            'USA/sawmills-production',
            'USA/automobile-engine',
            'Mexico/automobile-engine',
            'USA/transboundary-water',
            'Mexico/transboundary-water',
            'USA/inorganic-chemical',
            'USA/power',
            'Mexico/power',
            'USA/semiconductor-selectronic',
            'USA/medical-device',
            'USA/public-health-info'
          ].includes(context.country + '/' + context.section?.url)
        "
      >
        <div class="col-12">
          <div class="card half-border">
            <app-graph
              [id]="'mvm'"
              [isMVM]="true"
              [graphItems]="mvmData"
              [graphLinks]="mvmLinks"
              *ngIf="
                tabSelected == 'profile' &&
                [
                  'USA/corn-farming',
                  'USA/sawmills-production',
                  'USA/automobile-engine',
                  'Mexico/automobile-engine',
                  'USA/transboundary-water',
                  'Mexico/transboundary-water',
                  'USA/inorganic-chemical',
                  'USA/power',
                  'Mexico/power',
                  'USA/semiconductor-selectronic',
                  'USA/medical-device',
                  'USA/public-health-info'
                ].includes(context.country + '/' + context.section?.url)
              "
            ></app-graph>
          </div>
        </div>
      </div>

    </div>
    <div
      class="tab-pane"
      id="nlp"
      role="tabpanel"
      [hidden]="tab != 'nlp'"
      aria-labelledby="nlp-tab"
    >
      <div
        class="row"
        *ngIf="
          context.country === 'USA' && context.section?.url !== 'medical-device'
        "
      >
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="card half-border">
            <h2 class="articles-title">Articles</h2>
            <app-articles-filters />
            <app-article-single />
            <app-articles-list />
            <ng-container *ngIf="!article"> </ng-container>
            <ng-container *ngIf="article">
              <div class="article-single">
                <div class="article-single-header"></div>
                <div class="article-single-body">
                  <div class="article-single-sidenav">
                    <h5>Content</h5>
                    <div class="table-content"></div>
                  </div>
                  <div class="article-single-main">
                    <div class="article">
                      <h1>{{ article.title }}</h1>
                      <div>
                        <span>Published: <time>24 april 2024</time></span>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab, aliquam aliquid animi corporis cumque
                        distinctio dolorum eos hic illum natus odit officiis
                        omnis optio porro quae, recusandae, repellat rerum
                        tempore.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tab-pane"
      id="stories"
      role="tabpanel"
      [hidden]="tab != 'stories'"
      aria-labelledby="stories-tab"
      [ngClass]="{'active': isQA}"
    >
      <div class="card half-border">
        <ng-container *ngIf="!selectedStoryId">
          <div class="story-list">
            <div *ngFor="let story of stories" class="item" (click)="onSelectStory(story.storyId)">
              <img [src]="'data:image/jpeg;base64,' + story.thumbnail" alt="Thumbnail" class="thumbnail">
              <div class="item-content">
                <h3>{{ story.title }}</h3>
                <span>{{ story.summary }}</span>
                <span>{{ story.byline }}</span>
                <span>{{ story.publishedDate | date:'medium' }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedStoryId">
          <div style="width: 100%; display: flex; justify-content: flex-end;">
            <button class="back" (click)="onSelectStory('')">Back to list</button>
          </div>
          <div *ngIf="isLoading" class="overlay">
            <div class="loader"></div>
          </div>
          <iframe
            *ngIf="baseUrl"
            [src]="baseUrl"
            (load)="onIframeLoad()"
            frameborder="0"
            style="height: 70vh; width: 100%;"
            class="iframe-content"
          ></iframe>
        </ng-container>
      </div>
    </div>

    <div
      class="tab-pane active"
      id="riskscenario"
      role="tabpanel"
      [hidden]="tab != 'riskscenario'"
      aria-labelledby="riskscenario-tab"
      *ngIf="tabSelected === 'riskscenario'"
    >
      <div class="card half-border">
        <app-risk-scenarios-template></app-risk-scenarios-template>
      </div>
    </div>
  </div>
</div>
