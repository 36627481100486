var L = require('leaflet');
var heatmap = require('./heatmap');
var HeatmapOverlay = require('./leaflet-heatmap');

L.TimeDimension.Layer.Heatmap = L.TimeDimension?.Layer.extend({
            
    initialize: function(data, options) {
        var heatmapCfg = {
            radius: 0.1,
            maxOpacity: 0.5,
            scaleRadius: true,
            useLocalExtrema: true,
            latField: 'lat',
            lngField: 'lng',
            valueField: 'count',
            onExtremaChange: (extremeData) => {
                        options.heatmapOptions.heatmapControl.updateLegend(extremeData);
            }
        };
        //heatmapCfg = $.extend({}, heatmapCfg, options.heatmapOptions || {});
        var layer = new HeatmapOverlay(heatmapCfg);
        L.TimeDimension.Layer.prototype.initialize.call(this, layer, options);
        this._currentLoadedTime = 0;
        this._currentTimeData = {
            data: []
            };
        this.data= data;
        
        this.defaultWeight = heatmapCfg.defaultWeight || 1;
        
    },

    onAdd: function(map) {
        L.TimeDimension.Layer.prototype.onAdd.call(this, map);
        map.addLayer(this._baseLayer);
        if (this._timeDimension) {
            this._getDataForTime(this._timeDimension.getCurrentTime());
        }
    },

    _onNewTimeLoading: function(ev) {
        this._getDataForTime(ev.time);
        return;
    },

    isReady: function(time) {
        return (this._currentLoadedTime == time);
    },

    _update: function() {
        this._baseLayer.setData(this._currentTimeData);
        if(this._currentTimeData.data.length > 0)
            this._map.fitBounds(this._getBounds());
        return true;
    },
    _getBounds: function(){
        const locs = this._currentTimeData.data;
        var smallestLat = locs[0], smallestLng = locs[0], biggestLat = locs[0], biggestLng = locs[0];

        locs.forEach(function(coord){
        if(coord.lat < smallestLat.lat) smallestLat = coord;
        if(coord.lng < smallestLng.lng) smallestLng = coord;
        if(coord.lat > biggestLat.lat) biggestLat = coord;
        if(coord.lng > biggestLng.lng) biggestLng = coord;
        });

        var southWest = L.latLng(smallestLat.lat, biggestLng.lng),
        northEast = L.latLng(biggestLat.lat, smallestLng.lng),
        bounds = L.latLngBounds(southWest, northEast)

        return bounds;
    },
    _getDataForTime: function(time) {
            //Cambiar esto

            delete this._currentTimeData.data;
            this._currentTimeData.data = [];
            
            var data = this.data.data.filter(item => item.date == this._timeDimension.getCurrentTime()); 

            for (var i = 0; i < data.length; i++) {
                this._currentTimeData.data.push({
                        lat: data[i].lat,
                        lng: data[i].lng,
                        count: data[i].value
                    });
                }
            this._currentLoadedTime = this._timeDimension.getCurrentTime();
            if (this._timeDimension && time == this._timeDimension.getCurrentTime() && !this._timeDimension.isLoading()) {
                this._update();
            }
            this.fire('timeload', {
                time: time
            });
        }        
});

L.timeDimension.layer.Heatmap = function(layer, options) {
    return new L.TimeDimension.Layer.Heatmap(layer, options);
};