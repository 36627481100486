
<div style="display: flex; justify-content: flex-start;gap: 2rem;">
    <button class="btn btn-home" *ngIf="this.dataRes.hasStudyButton" (click)="openStudyDialog()">Select variable</button>
  </div>
  <br />
  <mat-tab-group>

    <mat-tab label="Metadata & Visualization">
      <ng-template matTabContent>
          <div>
            <div style="padding: 1rem; background-color: var(--white);">
              <h2>{{this.dataRes.metadata.name}}</h2>
              <p>
                  {{this.dataRes.metadata.name}}
              </p>
              <div style="padding-left: 3rem;">
                <p><span style="font-weight: 700;">Risk Component Classification:</span> {{this.dataRes.metadata.classification}}</p>
                <p><span style="font-weight: 700;">Type of Evidence:</span> {{this.dataRes.metadata.evidenceType}}</p>
                <p><span style="font-weight: 700;">Temporal Resolution:</span> {{this.dataRes.metadata.temporalResolution}}</p>
                <p><span style="font-weight: 700;">Time Period:</span> {{this.dataRes.metadata.timePeriod}}</p>
                <p><span style="font-weight: 700;">Data Source:</span> {{this.dataRes.metadata.source}}</p>

              </div>
            </div>
            <br>
            <mat-card>
              <div *ngIf="this.dataRes.filterType === this.filterType.oneFilter">
                <div *ngIf="this.dataRes.filterInfoSecond">
                  <app-two-single-filters [data]="this.dataRes.filterInfoSecond"
                    (firstValue)="emitFirstValue($event)"></app-two-single-filters>
                </div>
                <div *ngElse>
                  <app-two-single-filters [data]="this.dataRes.filterInfo"
                                        (firstValue)="emitFirstValue($event)"></app-two-single-filters>
                </div>


              </div>
              <div class="row" *ngIf="this.dataRes.data != undefined">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                  <div *ngIf="this.dataRes.data != undefined">
                    <app-bar-chart-horizontal [barId]="'alc-chart'" [data]="this.dataRes" [title]="'Economic Units According to Availability of internet service (according to company size)'"></app-bar-chart-horizontal>
                  </div>


                </div>



              </div>
            </mat-card>

        </div>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="Trend Model & Residual Analysis"> Content 2 </mat-tab>
    <mat-tab label="Autocorrelation Analysis"> Content 3 </mat-tab>
    <mat-tab label="Seasonal & Trend Decomposition"> Content 4 </mat-tab>
    <mat-tab label="Second-Order Statistics"> Content 6 </mat-tab>
    <mat-tab label="Bayesian Network Model with Synchronized Data"> Content 7 </mat-tab> -->

  </mat-tab-group>




