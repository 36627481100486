import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { PowerRoutingModule } from './power-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    PowerRoutingModule,
    SharedModule,
    MatExpansionModule
  ]
})
export class PowerModule { }
