import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  FilterType,
  TimeVisualizationTemplateModel,
} from 'src/app/core/models/templates/static-template/static-template.model';

@Component({
  selector: 'app-trend-model',
  templateUrl: './trend-model.component.html',
  styleUrls: ['./trend-model.component.css'],
})
export class TrendModelComponent implements OnChanges {
  @Output() response = new EventEmitter<any>();
  @Input() algorithm!: string;
  @Input() filtersList!: string[];
  @Input() yAxisFilter!: string[];
  @Input() dataRes!: TimeVisualizationTemplateModel;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  @Output() yAxisValue = new EventEmitter<string>();
  public form!: FormGroup;
  public filterType = FilterType;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      algorithm_: ['1', Validators.required],
      window_size_0_1: [0.5, Validators.required],
      window_size: [5, Validators.required],
      degree_: [3, Validators.required],
      smoothing_factor: [3, Validators.required],
      filters_: [this.filtersList ? this.filtersList : null],
      y_axis_filter: [this.yAxisFilter ? this.yAxisFilter : null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['algorithm']) {
      this.form.get('algorithm_')?.setValue(this.algorithm.toString() || '1');
    }
    if (changes['filtersList']) {
      // todo: question: why do we need to validate if list is > 0?
      if (this.filtersList.length > 0) {
        this.form.get('filters_')?.setValue(this.filtersList);
      }
    }
    if (changes['yAxisFilter']) {
      if (this.yAxisFilter.length > 0) {
        this.form.get('y_axis_filter')?.setValue(this.yAxisFilter);
      }
    }
  }

  public onSubmit(): void {
    if (this.form.invalid) return;
    this.response.emit(this.form.value);
  }

  emitFirstValue($event: any) {
    this.firstValue.emit($event + '1');
    this.filtersList[0] = $event;
    //console.log($event);
  }

  emitSecondValue($event: any) {
    this.secondValue.emit($event + '2');
    this.filtersList[1] = $event;
  }

  emitThirdValue($event: any) {
    this.thirdValue.emit($event + '3');
    this.filtersList[2] = $event;
  }

  emitFourthValue($event: any) {
    this.fourthValue.emit($event + '4');
    this.filtersList[3] = $event;
  }

  emitFifthValue($event: any) {
    this.fifthValue.emit($event + '5');
    this.filtersList[4] = $event;
  }

  emitSixthValue($event: any) {
    this.sixthValue.emit($event + '6');
    this.filtersList[5] = $event;
  }

  emitYAxisValue($event: any) {
    
    //console.log($event);
    this.yAxisValue.emit($event);
    this.yAxisFilter[0] = $event;
  }
}
