import { AfterViewInit, Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() barId!: string;
  @Input() data!: any;

  @ViewChild('elRef', {read: ElementRef}) private elRef!: ElementRef<HTMLElement>;

  private root!: am5.Root;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.root)
      this.initChart();
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  initChart(){
    // Create root and chart
    var root = am5.Root.new(this.elRef.nativeElement);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    var chart = root.container.children.push( 
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout
      }) 
    );


    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "value",
        categoryField: "category",
        legendLabelText: "[{fill}]{category}[/]",
        legendValueText: "[bold {fill}]{value}[/]"
      })
    );
    series.data.setAll(this.data.data);

    series.labels.template.setAll({
      text: '{category}: {value}'
    });

    series.labels.template.set("forceHidden", true);
    series.ticks.template.set("forceHidden", true);

    series.slices.template.set("tooltipText", "[bold]{category}: {value}[/]");

    // Add legend
    var legend = chart.children.push(am5.Legend.new(root, {
      centerY: am5.percent(50),
      y: am5.percent(50),
      layout: root.verticalLayout,
      height: am5.percent(100),
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: "vertical"
      })
    }));

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);

  }

}
