import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private apiService: ApiServiceService) { }
  
  getProductRecall(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.product_recall}_us`);
  }
  getPeopleOneDoseVaccinationCn(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.peopleOneDoseVaccination}_cn`);
  }
  getFullyVaccinatedPeopleCn(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.fullyVaccinatedPeople}_cn`);
  }
  getNetAcccessMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/${ApiRouting.net_access}_mx`);
  }
  getSolarDateGbl(): Observable<any> {
    return this.apiService.get(`${ApiRouting.system}/solar/gbl/${ApiRouting.dates}`);
  }
}
