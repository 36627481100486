import { GraphItemModel, GraphLinkModel } from "../models/graph.model"
export const mvmLinksWaterUSA: GraphLinkModel[] = [
  {
    "source": "Alfalfa/Hay",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Crime",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Production\nValue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Sustainability of\nNative\nCommunities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa/Hay",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\n(L/D)",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\nL\n\nD",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Cultural Values, \nEducation, \nWater Habits, \nAnd Awareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Tropical\nCyclones",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Geopolitics",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Legal\nFramework",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Public\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Financing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Drought",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Cultural Values, \nEducation, \nWater Habits, \nAnd Awareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Water\nAccess",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cultural Values, \nEducation, \nWater Habits, \nAnd Awareness",
    "target": "Water\nOver-Exploitation\nMisuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Broadband\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Dam\nInfrastructure",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Cultural Values, \nEducation, \nWater Habits, \nAnd Awareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nOver-Exploitation\n/\nMisuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nAccess",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Cultural Values, \nEducation, \nWater Habits, \nAnd Awareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitics",
    "target": "Legal\nFramework",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ground\nWater\nQuality",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Labor\nShortage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Electricity\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface\nWater\nQuality",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface\nWater\nQuality",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Tropical\nCyclones",
    "target": "Fluvial\nFlooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Tropical\nCyclones",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAccess",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAccess",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\nMisuse",
    "target": "Surface\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\nMisuse",
    "target": "Ground\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\nMisuse",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\nMisuse",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver-Exploitation\nMisuse",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver-Exploitation\nMisuse",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver-Exploitation\nMisuse",
    "target": "Ground\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver-Exploitation\nMisuse",
    "target": "Surface\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Public\nHealth\nThreat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Irrigation\nDistricts\nand\nCommunity\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Alfalfa/Hay",
    "lineStyle": {
      "curveness": 0.1
    }
  }
]