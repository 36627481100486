<div class="login">
    <!-- Backdrop -->
    <div class="login__backdrop flex-grow-1">
        <div class="login__container">

            <!-- Logotipo -->
            <div class="logo__container">
                <svg id="Capa_1"
                    data-name="Capa 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 172.14 141.34">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #500000;
                            }
                        </style>
                    </defs>
                    <polygon class="cls-1"
                        points="170.56 46.38 153.47 46.38 152.47 46.38 152.04 47.27 139.64 73.07 127.21 47.27 126.79 46.38 125.79 46.38 109.28 46.38 107.7 46.38 107.7 47.95 107.7 56.75 107.7 58.32 109.28 58.32 112.11 58.32 112.11 95.26 109.05 95.26 107.47 95.26 107.47 96.84 107.47 105.63 107.47 107.21 109.05 107.21 127.6 107.21 129.18 107.21 129.18 105.63 129.18 96.84 129.18 95.26 127.6 95.26 124.39 95.26 124.39 69.05 138.24 97.63 139.65 100.54 141.08 97.63 155.08 68.99 155.08 95.26 152.01 95.26 150.43 95.26 150.43 96.84 150.43 105.63 150.43 107.21 152.01 107.21 170.56 107.21 172.14 107.21 172.14 105.63 172.14 96.84 172.14 95.26 170.56 95.26 167.37 95.26 167.37 58.32 170.56 58.32 172.14 58.32 172.14 56.75 172.14 47.95 172.14 46.38 170.56 46.38" />
                    <path class="cls-1"
                        d="M62.85,95.23H57.93L41.87,58.12h3.07v-12H19.16v12h3.55l-16,37.11H0v11.94H23.4V95.23H20l2.67-6.15H42l2.66,6.15H41v11.94H64.43V95.23Zm-26-18.13h-9l4.48-10.37Z" />
                    <path class="cls-1"
                        d="M150.93,0H19V38.37H45V23.65H72.82v91.64H58.1v26.05h55.5v-26H98.9V23.65h27.6V38.2h26.25V0h-1.82ZM102.64,126.07l5.5-5.66V136l-5.5-5.27Zm-9.18-5.25L88,126.24V12.84l5.5,5.51Zm-63.61-110L24.18,5.32H144.54l-7.12,5.51ZM147.52,33.18,142,27.92v-14l5.5-5.66Z" />
                    <path class="cls-1"
                        d="M119.69,135.94a5.34,5.34,0,1,1,5.33,5.33h0A5.34,5.34,0,0,1,119.69,135.94Zm9.37,0a4,4,0,1,0-4,4.29,4.1,4.1,0,0,0,4-4.16A.57.57,0,0,0,129.06,135.94ZM123,132.86h2.25c1.44,0,2.22.51,2.22,1.77a1.51,1.51,0,0,1-1.38,1.63.67.67,0,0,1-.2,0l1.62,2.65h-1.15l-1.57-2.58h-.67v2.6H123Zm1.13,2.58h1c.67,0,1.24-.08,1.24-.89s-.64-.83-1.22-.83h-1v1.72Z" />
                </svg>
            </div>

            <!-- Formulario -->
            <form [formGroup]="loginForm"
                class="login__form mt-4">

                <!-- Nombre de usuario -->
                <div appearance="outline"
                    class="login__form-field">
                    <input class="login__form-input login__form-input--username"
                        placeholder="Username"
                        matInput
                        [formControlName]="CONTROL_NAMES.username"
                        cdkFocusRegionStart
                        cdkFocusInitial>
                    <svg class="login__form-input-suffix"
                        id="Capa_1"
                        data-name="Capa 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.45 19.32">
                        <defs>
                            <style>
                                .cls-2 {
                                    fill: #494949;
                                }
                            </style>
                        </defs>
                        <path class="cls-2"
                            d="M8.12,10.34A5,5,0,0,1,4.46,8.83,5,5,0,0,1,2.94,5.17,5,5,0,0,1,4.46,1.51,5,5,0,0,1,8.12,0a5,5,0,0,1,3.65,1.51,5,5,0,0,1,1.52,3.66,5,5,0,0,1-1.52,3.66A5,5,0,0,1,8.12,10.34ZM8.12,2a3,3,0,0,0-2.25.93,3,3,0,0,0-.93,2.24,3,3,0,0,0,.93,2.24,3,3,0,0,0,2.25.93,3,3,0,0,0,2.24-.93,3,3,0,0,0,.93-2.24,3,3,0,0,0-.93-2.24A3,3,0,0,0,8.12,2Z" />
                        <path class="cls-2"
                            d="M12.39,19.32H4.05a4,4,0,0,1-2.89-1.08A3.9,3.9,0,0,1,0,15.34c0-.37,0-.74,0-1.09a9.14,9.14,0,0,1,.15-1.2,10.07,10.07,0,0,1,.3-1.23A6.3,6.3,0,0,1,1,10.6a5,5,0,0,1,.86-1.12,4.11,4.11,0,0,1,1.3-.82,4.32,4.32,0,0,1,1.62-.29A2.25,2.25,0,0,1,6,8.81l.7.46a3.63,3.63,0,0,0,.68.29A2.64,2.64,0,0,0,9,9.56a3.52,3.52,0,0,0,.69-.29l.7-.46a2.22,2.22,0,0,1,1.22-.44,4.32,4.32,0,0,1,1.62.29,4.11,4.11,0,0,1,1.3.82,4.83,4.83,0,0,1,.86,1.12A7,7,0,0,1,16,11.82a9.63,9.63,0,0,1,.3,1.23c.07.42.12.83.15,1.2h0c0,.35,0,.72,0,1.09a3.89,3.89,0,0,1-1.17,2.9A4,4,0,0,1,12.39,19.32Zm-7.66-9a2.28,2.28,0,0,0-.8.15,2,2,0,0,0-.66.41,2.61,2.61,0,0,0-.5.66,3.91,3.91,0,0,0-.37.84,6.55,6.55,0,0,0-.24,1,9.64,9.64,0,0,0-.13,1c0,.3,0,.63,0,.95a1.91,1.91,0,0,0,.54,1.45,2.08,2.08,0,0,0,1.51.53h8.34a2,2,0,0,0,1.51-.53,1.88,1.88,0,0,0,.55-1.45c0-.33,0-.65,0-.95h0c0-.31-.07-.64-.13-1a7.64,7.64,0,0,0-.24-1,3.91,3.91,0,0,0-.37-.84,2.61,2.61,0,0,0-.5-.66,2,2,0,0,0-.66-.41,2.29,2.29,0,0,0-.81-.15l-.2.12-.72.46a4.89,4.89,0,0,1-1.14.51,4.48,4.48,0,0,1-2.85,0A5.19,5.19,0,0,1,5.65,11l-.71-.46A1,1,0,0,0,4.73,10.37Z" />
                    </svg>
                </div>

                <!-- Contraseña -->
                <div class="login__form-field mt-4">
                    <input class="login__form-input"
                        [type]="passwordShown?'text':'password'"
                        placeholder="Password"
                        matInput
                        [formControlName]="CONTROL_NAMES.password">
                    <svg class="login__form-input-suffix"
                        id="Capa_1"
                        data-name="Capa 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 19.53 12.71"
                        (click)="handlePasswordClick()">
                        <defs>
                            <style>
                                .cls-2 {
                                    fill: #494949;
                                }
                            </style>
                        </defs>
                        <path class="cls-2"
                            d="M10,3a3.39,3.39,0,1,0,3.39,3.38A3.38,3.38,0,0,0,10,3Zm0,5.32a1.94,1.94,0,1,1,1.94-1.94A1.93,1.93,0,0,1,10,8.29Z" />
                        <path class="cls-2"
                            d="M19.2,5.27A12.48,12.48,0,0,0,15,1.38,10.71,10.71,0,0,0,9.83,0H9.7A10.77,10.77,0,0,0,4.53,1.38,12.48,12.48,0,0,0,.33,5.27a2,2,0,0,0,0,2.17,12.35,12.35,0,0,0,4.2,3.88A10.64,10.64,0,0,0,9.7,12.71h.07A10.62,10.62,0,0,0,15,11.32a12.35,12.35,0,0,0,4.2-3.88,1.91,1.91,0,0,0,0-2.17ZM18,6.63a11,11,0,0,1-3.7,3.43,9.31,9.31,0,0,1-4.47,1.2H9.71a9.28,9.28,0,0,1-4.47-1.2,11,11,0,0,1-3.7-3.43.47.47,0,0,1,0-.55,10.91,10.91,0,0,1,3.7-3.43,9.17,9.17,0,0,1,4.47-1.2h.06a9.17,9.17,0,0,1,4.53,1.2A10.91,10.91,0,0,1,18,6.08a.5.5,0,0,1,0,.55Z" />
                    </svg>
                    <i *ngIf="passwordShown"
                        class="login__form-input-suffix-slash"></i>
                </div>

                <span *ngIf="errorMessage"
                    class="login__message-error mt-3">
                    {{errorMessage}}
                </span>

                <!-- Iniciar sesión -->
                <button class="login__button login__button--login mt-5 mat-flat-button"
                    mat-flat-button
                    type="submit"
                    cdkFocusRegionEnd
                    [disabled]="loginForm.invalid || loggingIn"
                    (click)="handleLoginClick()">
                    Log in
                </button>

                <!-- Recuperar contraseña -->
                <!-- <button class="login__button mt-4"
                    mat-button
                    [disabled]="true">
                    Have you forgotten your password?
                </button> -->
            </form>
        </div>
    </div>
    <!-- <footer class="login__footer flex-shrink-0 d-flex flex-column align-items-center justify-content-center">
        <div class="login__footer-line w-100 d-flex flex-column align-items-center justify-content-center p-4">
            <img src="assets/images/login-footer.png"
                alt="">
        </div>
        <div
            class="login__footer-line login__footer-line--2 w-100 d-flex flex-column align-items-center justify-content-center p-4">
            <img src="assets/images/TAMUS-White-Logo.png"
                alt="footer">
            <div class="mt-5 d-flex align-items-center">
                <span>© 2023 TEXAS A&M UNIVERSITY</span>
                <span class="mx-5">SITE POLICIES</span>
            </div>
        </div>
    </footer> -->
</div>