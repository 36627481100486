import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';

@Component({
  selector: 'app-image-template',
  templateUrl: './image-template.component.html',
  styleUrls: ['./image-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageTemplateComponent {
 @Input() data: any;
 @Output() selectedItem = new EventEmitter<number>();

 constructor(private dialog: MatDialog) {
  
 }
 openStudyDialog(){
  const dialogRef = this.dialog.open(SelectStudyComponent, {
    height: '380px',
    width: '90%',
    minWidth: '300px',
    maxWidth: '600px',
    data: {filterInfo: this.data.filterInfo, selectedInfo: this.data.selectedInfo},
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result !== undefined){
      this.data.selectedInfo = result;
      this.selectedItem.emit(result);
    }
      
  });
 }
}
