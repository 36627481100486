import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-bridges-infrastructure',
  templateUrl: './bridges-infrastructure.component.html',
  styleUrls: ['./bridges-infrastructure.component.css']
})
export class BridgesInfrastructureComponent {
  public data: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  private filterInfo!: any;
  public select!:any;
  public hasStudyButton!:any;
  @Input() filter: any=undefined;
  @Input() numvariable!:any;
  @Output() selectedItem = new EventEmitter<number>();
  public selection: any = { 
    filter_one: 'Ferry'
  };
  public filterInfoValues = {
    "firstFilter": {
      "name": "Options",
      "values": [
        {
          "id": 0,
          "value": "Ferry"
        },
        {
          "id": 1,
          "value": "Paved"
        },
        {
          "id": 2,
          "value": "Unpaved"
        }
      ]
    }
  };

  dataMap = {
    center: MapCenter.CANADA,
    scale: ['#3399FF', '#66CCFF', '#99CCFF'].reverse(), // Tonos de azul
    json: MapJson.USA,
    zoom: MapZoom.GENERAL,
    borderColor: '#0000FF', // Borde azul
    color: '#3366CC', // Color de las áreas azul
    fillColor: '#99CCFF' // Relleno azul claro
  };
  formatInfo = {
    region: "en-US",
    format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
    addDays: 1
  };

  constructor(private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs(this.selection);
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }
    if(this.filter!=undefined){
      let result = this.filter.firstFilter.values.find((item: { itemId: any; }) => item.itemId === this.numvariable);
     this.select = result ? result.itemId : null;
      this.filterInfo=this.filter;
      this.hasStudyButton=true;
    
    }

  }

  handleSelectItem(data: any) {
    this.selectedItem.emit(data);
  }

  getDataCN() {
    this.spinner.hide();
  } 

  getDataTri() {
    this.spinner.hide();
  }

  getDataMx() {
    this.spinner.hide();
  }

  getDataUs(selection: any) {
    this.naturalService.getBridgesInfrastructure(selection)
    .subscribe({
      next: (res) => {
        this.data = {
          data: res,
          formatInfo: this.formatInfo,
          metadata: res.metadata, 
          mapInfo: this.dataMap,
          filterInfo:this.filterInfo,
          selectedInfo:this.select,
          hasStudyButton:this.hasStudyButton,
          typeMap: 'Static',
        };

        this.spinner.hide();
      },
      error: (e) => { console.error(e); this.spinner.hide(); }
    });  
    
  }

  async handleFilterUpdate($event: any) {
    this.selection ={ 
      filter_one: $event.toString()
    }; 

    this.getDataUs(this.selection);
  }
}
