import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-one-single-one-multi-filters',
  templateUrl: './one-single-one-multi-filters.component.html',
  styleUrls: ['./one-single-one-multi-filters.component.css'],
})
export class OneSingleOneMultiFiltersComponent implements OnChanges {
  @Input() data!: any;
  @Output() response = new EventEmitter<any>();
  public info1: any[] = [];
  public info2: any[] = [];

  public firstFilter = new FormControl<string>('');
  public secondFilter = new FormControl<string[]>([]);

  private selectedSingleFilterId!: string;
  private selectAll: boolean = false;

  constructor() {
    this.firstFilter.valueChanges.subscribe((x) => {
      if (x === null) return;
      this.selectedSingleFilterId = x;
      this.secondFilter.patchValue([]);
      this.getDataFromSingleFilter();
      this.selectAll = false;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data' && this.data]) {
      this.info1 = this.data.map((item: any) => {
        return { id: item.year, value: item.year };
      });
      this.selectedSingleFilterId = this.info1[0].id;
      this.getDataFromSingleFilter();
      //initialize filters
      this.firstFilter.patchValue(this.selectedSingleFilterId);
      this.secondFilter.patchValue([this.info2[0].id]);
      this.pushModel();
    }
  }

  private getDataFromSingleFilter() {
    this.info2 = this.data.find(
      (item: any) => item.year === this.selectedSingleFilterId
    ).data;
  }

  public pushModel() {
    if (
      this.secondFilter.value === null ||
      this.secondFilter.value.length === 0
    )
      return;
    const model = { hurricanes: this.secondFilter.value };
    this.response.emit(model);
  }
  public toggleAll() {
    this.selectAll = !this.selectAll;

    if (this.selectAll) {
      this.secondFilter.patchValue([0, ...this.info2.map((item) => item.id)]);
    } else {
      this.secondFilter.patchValue([]);
    }
  }
}
