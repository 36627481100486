import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DroughtComponent } from 'src/app/shared/pages/drought/drought.component';
import { WildfireComponent } from 'src/app/shared/pages/wildfire/wildfire.component';
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Drought',
    component: DroughtComponent
  },
  {
    path: 'Fires',
    component: WildfireComponent
  },
  {
    path: 'Production-Rate',
    component: GeneralNodeComponent//ProductionRateComponent
  },
  {
    path: 'Silicon',
    component: GeneralNodeComponent//SiliconComponent
  },
  {
    path: 'Carbon',
    component: GeneralNodeComponent//CarbonComponent
  },
  {
    path: 'Smarthphone-Industry',
    component: GeneralNodeComponent//SmarthphoneIndustryComponent
  },
  {
    path: 'Waste-Water',
    component: GeneralNodeComponent
  },
  {
    path: 'Public-Transportation',
    component: GeneralNodeComponent
  },
  {
    path: 'Electricity',
    component: GeneralNodeComponent
  },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SemiconductorRoutingModule { }
