<div *ngIf="this.isCN">
    <div *ngIf="this.data !== undefined">
      <app-dynamic-template [dataRes]="this.data" (firstValue)="reloadCn($event)"></app-dynamic-template>
    </div>
</div>

<div *ngIf="!this.isCN ">
  <app-not-found></app-not-found>
</div>

