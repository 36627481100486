import {has} from "@amcharts/amcharts5/.internal/core/util/Array";

type Query = {
  [prop: string]: string
  __output__: string;
}

type Result = {
  predictedValue: number;
}

const settings = [
  {
    name: 'crime',
    label: 'Crime',
    canBeOutput: false,
  },
  {
    name: 'water_habits',
    label: 'Water habits',
    canBeOutput: false,
  },
  {
    name: 'food_demand',
    label: 'Food demand',
    canBeOutput: false,
  },
  {
    name: 'export',
    label: 'Export',
    canBeOutput: false,
  },
  {
    name: 'water_access',
    label: 'Water access',
    canBeOutput: false,
  },
  {
    name: 'geopolitics',
    label: 'Geopolitics',
    canBeOutput: false,
  },
  {
    name: 'alfalfa_hay_production',
    label: 'Alfalfa/Hay production',
    canBeOutput: true,
  },
  {
    name: 'employment',
    label: 'Employment',
    canBeOutput: true,
  }
];

const isEqual = function(a: any, b: any) {
  let p, t;
  for (p in a) {
    if (typeof b[p] === 'undefined') {
      return false;
    }
    if (b[p] && !a[p]) {
      return false;
    }
    t = typeof a[p];
    if (t === 'object' && !isEqual(a[p], b[p])) {
      return false;
    }
    if (t === 'function' && (typeof b[p] === 'undefined' || a[p].toString() !== b[p].toString())) {
      return false;
    }
    if (a[p] !== b[p]) {
      return false;
    }
  }
  for (p in b) {
    if (typeof a[p] === 'undefined') {
      return false;
    }
  }
  return true;
};

const randomBoolean = () => {
  return Math.random() < 0.5;
}

const getRandomLevel = () => {
  const values = ['low', 'medium', 'high'];
  return values[Math.floor(Math.random() * values.length)];
};

const getRandomFloat = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}


const generateResultsMock = (amount: number = 50) => {
  const cachedResults: Array<{
    query: Query,
    result: Result,
  }> = [];

  while (cachedResults.length < amount) {
    const outputSetting: any = settings.find(setting => setting.canBeOutput);
    const query: Query = {
      __output__: outputSetting.name
    };

    settings.forEach(setting => {
      if (outputSetting.name !== setting.name) {
        query[setting.name] = getRandomLevel()
      }
    });

    const result: Result = {
      predictedValue: getRandomFloat(0.5, 3)
    };

    const isDuplicate = cachedResults.some((item: any) => {
      const keys = Object.keys(item.query);
      return keys.every(key => item.query[key] === query[key]);
    });

    if (!isDuplicate) {
      cachedResults.push({
        query: { ...query },
        result: result
      });
    }
  }

  return cachedResults;
}

export const MOCK_RESULTS = generateResultsMock()

export const findResult = (query: Query) => {
  return MOCK_RESULTS.find(cached => {
    return isEqual(cached.query, query);
  })
}

const cachedResults = new Map()

export const generateResult = (query: Query) => {
  const hash = JSON.stringify(query);

  if (cachedResults.has(hash)) {
    return cachedResults.get(hash)
  }

  const predictedValue = getRandomFloat(0.5, 3).toFixed(2);
  const accuracy = getRandomFloat(0.5, 1).toFixed(3);

  const variablesPredictions = settings.map(setting => {
    return {
      name: setting.name,
      label: setting.label,
      predictedValue: getRandomFloat(0.5, 1).toFixed(3),
      highlight: randomBoolean(),
      width: randomInt(40, 100),
    }
  })

  const result = {
    predictedValue,
    accuracy,
    variables: variablesPredictions,
  }

  cachedResults.set(hash, result)

  return result;
}
