<ng-container *ngIf="this.estadocomponente">
  <app-dynamic-container [numvariable]="numVariable" [filter]="this.filterInfo" (selectedItem)="getValue($event)"></app-dynamic-container>
  </ng-container>
 
<ng-template #notFound>
  <app-not-found *ngIf="!this.estadocomponente"></app-not-found>
</ng-template>

<div *ngIf="this.isMx && data; else notFound">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template [data]="this.data" [AllowValueHover]="true"></app-dynamic-point-template>
   </div>   
</div>
  
