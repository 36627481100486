<div *ngIf="this.isCN">
  <div>
    <h2>Lorem ipsum dolor sit amet</h2>
    <p>
      consectetur adipiscing elit. Aenean nec tortor nulla. Duis tellus lorem, tempor a lacinia vel, efficitur tristique ipsum. Sed et metus sit amet elit cursus pulvinar. Duis tristique, mauris nec tincidunt sodales, eros quam commodo magna, et molestie massa sem sit amet turpis. Aenean dictum non lectus vitae elementum. Vivamus et tortor libero. Vestibulum ante eros, dapibus ut urna non, tincidunt luctus eros. Morbi at lobortis massa, vel congue nisl. Mauris dictum et odio a efficitur. Nullam hendrerit magna ex, eget porta lorem scelerisque ut. Duis pulvinar felis vel orci egestas, sit amet viverra leo vestibulum. Ut et mauris dapibus, placerat enim sit amet, sollicitudin libero. Phasellus scelerisque sem metus, eget dignissim justo vehicula in.
    </p>
  </div>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.data != undefined">
      <app-line-chart [data]="this.data" [title]="'Daily Total Water Use Per Capita by Province'"  [hasMinMax]="true" [yLabel]="'Liters per Person per Day'" [xLabel]="'Year'"></app-line-chart>
    </div>

  </div>
</div>
<div *ngIf="!this.isCN">
  <app-not-found></app-not-found>
</div>

