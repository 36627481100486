import { AfterViewInit, Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent {
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @Input() barId!: string;
  @Input() distance: number = 1;
  @Input() data!: any;
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
    }
  ngOnChanges(changes: SimpleChanges): void {
    this.initChart();
  }
  ngAfterViewInit(): void {
    this.initChart();
  }
  initChart(){
  if(this.chart != null && this.chart != undefined  && this.root != null && this.root != undefined){
    this.chart!.dispose();
    this.root!.dispose();
  }
  this.root = am5.Root.new(this.chartEl.nativeElement);
  this.root.setThemes([
      am5themes_Animated.new(this.root)
  ]);

  this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
  panX: true,
  panY: true,
  wheelX: "panX",
  wheelY: "zoomX",
  pinchZoomX:true
  }));

  this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
    text: this.data.yLabel,
    fontSize: 12,
    fontWeight: 'bold',
    x: am5.p50,
    y: am5.p50,
    centerX: am5.p50,
    centerY: am5.p50,
    rotation: 270,
    paddingBottom: 25
  }));

  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
    behavior: "none"
  }));
  cursor.lineY.set("visible", false);


  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
    maxDeviation:0.2,
    baseInterval: { timeUnit: "year", count: this.distance },
    renderer: am5xy.AxisRendererX.new(this.root, {pan:"zoom"}),
    tooltip: am5.Tooltip.new(this.root, {})
  }));
  xAxis.children.push(am5.Label.new(this.root, {
    text: this.data.xLabel,
    textAlign: 'center',
    x: am5.p50,
    fontSize: 12,
    fontWeight: 'bold'
  }));
  let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
    renderer: am5xy.AxisRendererY.new(this.root, {})
  }));


  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series = this.chart.series.push(am5xy.StepLineSeries.new(this.root, {
    minBulletDistance: 10,
    name: "Series",
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "valueY",
    valueXField: "valueX",
    tooltip: am5.Tooltip.new(this.root, {
      labelText: "{valueY}"
    })
  }));


series.bullets.push(() => {
  let circle = am5.Circle.new(this.root, {
    radius: 4,
    fill: series.get("fill"),
    stroke: this.root.interfaceColors.get("background"),
    strokeWidth: 2,
    centerX: am5.percent(50),
    centerY: am5.percent(50),
  });

  return am5.Bullet.new(this.root, {
    locationX: 0,
    locationY: 0,
    sprite: circle

  });
});


  let scrollbar = this.chart.set("scrollbarX", am5xy.XYChartScrollbar.new(this.root, {
    orientation: "horizontal",
    height: 20
  }));
  this.chart.set("scrollbarX", scrollbar);
  this.chart.bottomAxesContainer.children.push(scrollbar);
  this.chart.zoomOutButton.set("forceHidden", true);
  // Set data
  series.data.setAll(this.data.data);


  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear(1000);
  this.chart.appear(1000, 100);
  }

}
