import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';;
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },

  
  
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AutomobileEngineRoutingModule { }
