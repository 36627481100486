import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) {
  }

  get(url: string, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.get(url, {params: params, headers: headers})
      .pipe(catchError(this.formatErrors));
  }

  surface(centroids: any, main: any) {
    let clone_x: any[] = Object.assign([], centroids.x.filter((value: any) => value != null));
    const minX = Math.min(...clone_x);
    const maxX = Math.max(...clone_x);
    const x = [maxX, maxX, minX, minX];

    let clone_y: any[] = Object.assign([], centroids.y.filter((value: any) => value != null));
    const minY = Math.min(...clone_y);
    const maxY = Math.max(...clone_y);
    const y = [maxY, minY, maxY, minY];

    let clone_z: any[] = Object.assign([], centroids.z.filter((value: any) => value != null));
    const minZ = Math.min(...clone_z);
    const maxZ = Math.max(...clone_z);
    let z: number[];

    switch (main) {
      case 'mexico':
        z = [(minZ + 15.3254), (minZ + 0.32544), maxZ, maxZ - 15.3254];
        break;
      case 'usa':
        z = [(minZ + -0.1196), (maxZ + -0.6079), (minZ + 0.9904), (maxZ + 0.4921)];
        break;
      case 'canada':
        z = [minZ, maxZ, (minZ + 2000), (maxZ + 2000)];
        break;
      default:
        z = [minZ, minZ, maxZ, maxZ];
        break;
    }

    return {x, y, z};
  }

  residual(centroids: any) {
    let indices: any = [];
    let clone_centroids: any = {x: [], y: [], z: []};
    for (let axis in centroids) {
      for (let coords in centroids[axis]) {
        if (centroids[axis][coords]) {
          clone_centroids[axis].push(centroids[axis][coords]);
          if (axis == 'x') {
            indices.push(coords);
          }
        }
      }
    }
    indices.sort((a: any, b: any) => clone_centroids.x[b] - clone_centroids.x[a]);
    let x = indices.map((index: any) => clone_centroids.x[index]);
    let y = clone_centroids.y.sort((a: number, b: number) => b - a);
    let z = indices.map((index: any) => clone_centroids.z[index]);

    x = this.group(x);
    y = this.repeat(y);
    z = this.group(z);

    return {x, y, z};
  }

  serialized(input: any[], z_input: any[]) {
    let points: any[] = [];
    input.map((feature: any) => {
      if (feature.geometry) {
        switch (feature.geometry.type) {
          case 'Polygon':
            points.push(...feature.geometry.coordinates[0], [null, null]);
            break;
          case 'MultiPolygon':
            feature.geometry.coordinates.forEach((coords: any[]) => {
              points.push(...coords[0], [null, null]);
            });
            break;
          case 'LineString':
            points.push(...feature.geometry.coordinates, [null, null]);
            break;
        }
      } else {
        points.push(...feature, [null, null]);
      }
    });

    const x = points.map(point => point[0]);
    const y = points.map(point => point[1]);
    const z = z_input.flat(1);

    return {x, y, z};
  }

  sequential(arr: any) {
    const validValues = arr.filter((value: number) => value ?? value === 0);
    const minValue = Math.min(...validValues);
    const maxValue = Math.max(...validValues);
    const seqValue = validValues.map((_: any, i: number) => (i / (validValues.length - 1)) * (maxValue - minValue) + minValue);
    return {seqValue, minValue, maxValue};
  }

  linear(arr: any, arr_z: any) {
    let result: any = {};
    Object.keys(arr).forEach((clave) => {
      let list: any[] = [];
      arr[clave].forEach((valor: any, index: number) => {
        list.push(valor);
        if (clave == 'z')
          list.push(arr_z[index]);
        else
          list.push(valor);
        if (index < arr[clave].length - 1)
          list.push(null);
      });
      result[clave] = list;
    });
    return result;
  }

  private group(arr: any[]) {
    const grouped: any = {};
    arr.map(element => grouped[element] = grouped[element] || Array(arr.length).fill(element));
    return Object.values(grouped);
  };

  private repeat(arr: any[]) {
    return arr.map(() => [...arr]);
  }

  private formatErrors(error: any) {
    return throwError(() => error.error);
  }
}
