import { Component, ElementRef, Inject, NgZone } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID } from '@angular/core';
import { ViewChild } from "@angular/core";

declare const am5: any;
declare const am5xy: any;
declare const am5themes_Animated: any;

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent {
  @ViewChild('chart', {read: ElementRef}) private chartEl!: ElementRef<HTMLElement>;
  @ViewChild('chart2', {read: ElementRef}) private chartEl2!: ElementRef<HTMLElement>;
  private root: any;
  private root2:any;
// Variables para cada selector
selectedEvent1: string = "";
articleCount1: number = 0;

selectedEvent2: string = "";
articleCount2: number = 0;
  data = [
    { country: "Pharmaceutical Industry Developments", cantidad: 330 },
    { country: "Shares Transaction 2023 Pharma", cantidad: 70 },
    { country: "Financial Performance Analysis", cantidad: 50 },
    { country: "Keyword Analysis for Document Classification", cantidad: 40 },
    { country: "Pharmaceutical Tenders 2023", cantidad: 30 },
    { country: "Online Filings Viewer Software", cantidad: 20 },
    { country: "West Pharmaceutical Earnings Analysis", cantidad: 10 }
  ];

  data2=[
    { country: " GROW-STRONG", cantidad: 18 },
    { country: "CAUSE-MOVEMENT-UP-GAIN", cantidad: 10 },
    { country: "MOVEMENT-UP-GAIN", cantidad: 50 },
    { country: "POSITION-HIGH", cantidad: 5 },
    { country: "POSITION-LOW", cantidad: 4 },
    { country: "MOVEMENT-DOWN-LOSS", cantidad: 10 },
    { country: "SHORTAGE", cantidad: 4 },
    { country: "GEOPOLITICAL-TENSION", cantidad: 3 },
    { country: "CAUSE-MOVEMENT-DOWN-LOSS", cantidad: 3 },
    { country: "MOVEMENT-FLAT", cantidad: 4 }
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  


// Método para manejar el cambio de selección en el select
// Método para manejar el cambio de selección en el select
onEventSelect(event: any, data: any[], isSecondSelector: boolean) {
  const selectedIndex = event.target.selectedIndex - 1;
  const selectedOption = data[selectedIndex];

  if (selectedOption) {
    if (isSecondSelector) {
      this.selectedEvent2 = selectedOption.country;
      this.articleCount2 = selectedOption.cantidad;
    } else {
      this.selectedEvent1 = selectedOption.country;
      this.articleCount1 = selectedOption.cantidad;
    }
  }
}
  ngAfterViewInit() {
    this.browserOnly(() => {
      let root = am5.Root.new(this.chartEl.nativeElement);
let root2=am5.Root.new(this.chartEl2.nativeElement);
      root.setThemes([am5themes_Animated.new(root)]);
      root2.setThemes([am5themes_Animated.new(root2)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingLeft:0,
          paddingRight:1
        })
      );

      // Add cursor
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      // Create axes
      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30, minorGridEnabled:true });

      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        fontSize:15,
        
        
      });
      
      xRenderer.grid.template.setAll({
        location: 1
      })
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "country",
        renderer: xRenderer,
             
        tooltip: am5.Tooltip.new(root, {})
        
      }));



      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));
      

      // Create series
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cantidad",
        sequencedInterpolation: true,
        categoryXField: "country",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
          
        }),
        fill: am5.color("#0047AB"), // Azul fuerte en formato hexadecimal
        fillOpacity: 0.8 // Opacidad del color
       
   
      }));

  

      chart.children.unshift(am5.Label.new(root, {
        text: "Topic distribution",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0
      }));
      

      xAxis.data.setAll(this.data);
      series.data.setAll(this.data);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);

      let chart2 = root2.container.children.push(
        am5xy.XYChart.new(root2, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingLeft:0,
          paddingRight:1
        })
      );

      // Add cursor
      let cursor2 = chart2.set("cursor", am5xy.XYCursor.new(root2, {}));
      cursor2.lineY.set("visible", false);

      // Create axes
      let xRenderer2 = am5xy.AxisRendererX.new(root2, { minGridDistance: 30, minorGridEnabled:true });

      xRenderer2.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        fontSize:15,
        
        
      });
      
      xRenderer2.grid.template.setAll({
        location: 1
      })
      let xAxis2 = chart2.xAxes.push(am5xy.CategoryAxis.new(root2, {
        maxDeviation: 0.3,
        categoryField: "country",
        renderer: xRenderer2,
             
        tooltip: am5.Tooltip.new(root2, {})
        
      }));



      let yAxis2 = chart2.yAxes.push(am5xy.ValueAxis.new(root2, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root2, {})
      }));
      

      // Create series
      let series2 = chart2.series.push(am5xy.ColumnSeries.new(root2, {
        xAxis: xAxis2,
        yAxis: yAxis2,
        valueYField: "cantidad",
        sequencedInterpolation: true,
        categoryXField: "country",
        tooltip: am5.Tooltip.new(root2, {
          labelText: "{valueY}",
          
        }),
        fill: am5.color("#0047AB"), // Azul fuerte en formato hexadecimal
        fillOpacity: 0.8 // Opacidad del color
       
   
      }));

  
      chart2.children.unshift(am5.Label.new(root2, {
        text: "Events distribution",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0
      }));

      

      xAxis2.data.setAll(this.data2);
      series2.data.setAll(this.data2);
      

           // Make stuff animate on load
           series2.appear(1000);
           chart2.appear(1000, 100);
    });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      if (this.root2) {
        this.root2.dispose();
      }
    });
  }
}