import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

export const AutomotiveGraphCategory: any[] = [
    { name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
    { name: 'Natural', itemStyle: { color: '#ccffff' } },
    { name: 'Critical\nInfrastructure', itemStyle: { color: '#3366ff' }},
    { name: 'Raw Materials / Sources', itemStyle: { color: '#ff9900' } },
    { name: 'Suppliers', itemStyle: { color: '#000080' } },
    { name: 'Manufacturing / Production', itemStyle: { color: '#c0c0c0' } },
    { name: 'WholeSealers / Retailers', itemStyle: { color: '#ccffcc' } },
    { name: 'Customers', itemStyle: { color: '#ffffff' } },
    { name: 'Transportation / Logistics', itemStyle: { color: '#993366' } },
    { name: 'Environmental', itemStyle: { color: '#ffc000' } },
    { name: 'Economic', itemStyle: { color: '#ffc000' } },
    { name: 'Social', itemStyle: { color: '#ffc000' } },
];

export const AutomotiveGraphCategoryLink: any[] = [
    {
        name: 'Threats',
        links: [
            AutomotiveGraphCategory[0], AutomotiveGraphCategory[1], AutomotiveGraphCategory[2]
        ]
    },
    {
        name: 'Systems',
        links: [
            AutomotiveGraphCategory[3], AutomotiveGraphCategory[4], AutomotiveGraphCategory[5],
            AutomotiveGraphCategory[6], AutomotiveGraphCategory[7], AutomotiveGraphCategory[8]
        ]
    },
    {
        name: 'State of Risk',
        links: [
            AutomotiveGraphCategory[9], AutomotiveGraphCategory[10], AutomotiveGraphCategory[11]
        ]
    }
]

/* Links */
export const AutomotiveGraphLinks: GraphLinkModel[] = [
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Electricity\nShortage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Roads\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Bridges\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Electricity(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Roads\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Bridges\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Electricity(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Bridges\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Roads\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Inland\nWaterways\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Electricity(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Roads\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nTransportation\nInfrastructure",
    "target": "Bridges\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aluminum",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Assembly",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Aviation\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Vehicle(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Vessel(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Trucks(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Auto\nParts\nWholesaling",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n1",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n1",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n1",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n1",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n2",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n3",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aviation\n4",
    "target": "Motor\nHome\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barges",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n1",
    "target": "Trucks(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n2",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n3",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n4",
    "target": "Trucks(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n1",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n2",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n2",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n2",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n2",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Supplier\nQuality\nStandards",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n3",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n4",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n4",
    "target": "Service\nFailure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n4",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n4",
    "target": "Legal\nPenalties",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n4",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Car\nand\nAutomobile\nManufacturing",
    "target": "Sales",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Changing\nConsumer\nBehaviour",
    "target": "Engine\nVehicle\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Changing\nConsumer\nBehaviour",
    "target": "Technological\nAdvancements",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Floods",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Fire",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Recycled\nMetals",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Competition",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Aviation\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Component\nSuppliers",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Income\nlevel",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Engine\nVehicle\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Workforce\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Labor\nShortage\n(RM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "COVID-19\nPandemic\nCases",
    "target": "Labor\nShortage\n(M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cultural\nand\nLinguistic\nDifferences",
    "target": "Offshore/Nearshore\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Broadband\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Broadband\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Broadband\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "CyberSecurity",
    "target": "Broadband\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Workforce\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Duty\nand\nCustoms\nRegulations",
    "target": "Offshore/Nearshore\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Roads\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Bridges\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Roads\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Bridges\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Bridges\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Roads\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Roads\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Bridges\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n1",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n2",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n2",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n2",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n2",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Quality\nControl",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n3",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n4",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n5",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n6",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n7",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n8",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\nShortage",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Worker\nSafety",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nBlock\nManufacturing",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Infrastructure\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Engine\nVehicle\nDemand",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fire",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fire",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fire",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Roads\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Roads\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Roads\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Floods",
    "target": "Roads\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nExchange\nRate\nFluctuation",
    "target": "Offshore/Nearshore\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Infrastructure\nCapacity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nInvestments",
    "target": "Roads\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n1",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n2",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n2",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n2",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n2",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n3",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n3",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n3",
    "target": "Quality\nControl",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n3",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n3",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n4",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n5",
    "target": "Aviation\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n5",
    "target": "Vehicle(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n5",
    "target": "Vessel(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n5",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n5",
    "target": "Trucks(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n6",
    "target": "Aviation\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n6",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n6",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n6",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n6",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n7",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n8",
    "target": "Aviation\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n8",
    "target": "Rails\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n8",
    "target": "Vehicle(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n8",
    "target": "Vessel(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n8",
    "target": "Trucks(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geographically\nDiverse\nSuppliers",
    "target": "Inventory\nLevel",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitical\nTension",
    "target": "Offshore/Nearshore\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitical\nTension",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Aviation\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Global\nTrade",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Greenhouse\nGas\nConcentration",
    "target": "Laws\nand\nRegulations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Greenhouse\nGas\nConcentration",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Greenhouse\nGas\nConcentration",
    "target": "Workforce\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Greenhouse\nGas\nConcentration",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity(M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inclement\nWeather",
    "target": "Electricity\nShortage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Income\nlevel",
    "target": "Engine\nVehicle\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Income\nlevel",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Income\nlevel",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inflation",
    "target": "Income\nlevel",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inflation",
    "target": "Foreign\nExchange\nRate\nFluctuation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Broadband\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Broadband\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Broadband\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Information\nSharing",
    "target": "Broadband\nInfrastructure(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Infrastructure\nCapacity",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Infrastructure\nCapacity",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inland\nWaterways",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inventory\nLevel",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Just\nin\nTime\nManufacturing",
    "target": "Inventory\nLevel",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nM",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nM",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nM",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nM",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nR",
    "target": "Vehicle(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nR",
    "target": "Vessel(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nR",
    "target": "Trucks(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Supplier\nQuality/Reliability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Late\nPayment",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Laws\nand\nRegulations",
    "target": "Engine\nVehicle\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Laws\nand\nRegulations",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Magnesium",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Number\nof\nSuppliers",
    "target": "Geographically\nDiverse\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Offshoring\nManufacturing",
    "target": "Inventory\nLevel",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Offshoring\nManufacturing",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Service\nFailure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Legal\nPenalties",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Deaths",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Product\nRecall",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Energy\nConsumption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Original\nEquipment\nManufacturer\nOEM",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Environmental\nDamage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Parts\nRemanufacturing",
    "target": "Energy\nConsumption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n1",
    "target": "Vessel(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n2",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n3",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n4",
    "target": "Vessel(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nDesign",
    "target": "Environmental\nDamage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Aviation\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Rails\nInfrastructure(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Vessel(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Barges(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Trucks(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Legal\nPenalties",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Deaths",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Product\nRecall",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Product\nTesting",
    "target": "Energy\nConsumption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Labor\nShortage\n(RM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Labor\nShortage\n(M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\ntransportation",
    "target": "Engine\nVehicle\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\ntransportation",
    "target": "Labor\nShortage\n(RM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\ntransportation",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\ntransportation",
    "target": "Labor\nShortage\n(M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Purchasing\nDepartment",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Purchasing\nDepartment",
    "target": "Quality\nControl",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Purchasing\nDepartment",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Purchasing\nDepartment",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Purchasing\nDepartment",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Quality\nControl",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Quality\nControl",
    "target": "Recycled\nMetals",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Quality\nControl",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Quality\nControl",
    "target": "Product\nRecall",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n1",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n1",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n1",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n1",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n2",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n3",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rails\n4",
    "target": "Motor\nHome\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Aviation\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Vehicle(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Vessel(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Rails\nInfrastructure(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Trucks(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Supplier\nQuality\nStandards",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Legal\nPenalties",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nExtraction",
    "target": "Environmental\nDamage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Aviation\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Product\nRecall",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Raw\nmaterial\nsuppliers",
    "target": "Energy\nConsumption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Recycled\nMetals",
    "target": "Operational\nCost",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Recycled\nMetals",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Recycled\nMetals",
    "target": "Environmental\nDamage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Recycled\nMetals",
    "target": "Energy\nConsumption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n1",
    "target": "Vehicle(RM-S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n2",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n3",
    "target": "Vehicle(M-W)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n4",
    "target": "Vehicle(W-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Aviation\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Vehicle(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Rails\nInfrastructure(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Trucks(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Vessel(S-M)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Semiconductor\nSuppliers",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Purchasing\nDepartment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Quality\nControl",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Specialized\nLabor",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Steel",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Floods",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\n\nReliability",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\n\nReliability",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\n\nReliability",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Reputation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Customer\nSatisfaction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Legal\nPenalties",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Supplier\nQuality\nStandards",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "SUV\nand\nLight\nTruck\nManufacturing",
    "target": "Sales",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Technological\nAdvancements",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Technological\nAdvancements",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Technological\nAdvancements",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Titanium",
    "target": "Raw\nmaterial\nExtraction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n1",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n1",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n1",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n1",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n2",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n3",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucks\n4",
    "target": "Motor\nHome\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n1",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n1",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n1",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n1",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n2",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n3",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vehicle\n4",
    "target": "Motor\nHome\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n1",
    "target": "Raw\nmaterial\nsuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n1",
    "target": "Component\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n1",
    "target": "Global\nTrade",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n1",
    "target": "Semiconductor\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Product\nDesign",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Original\nEquipment\nManufacturer\n(OEM)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Engine\nBlock\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Assembly",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Parts\nRemanufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n2",
    "target": "Product\nTesting",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n3",
    "target": "Auto\nParts\nWholesaling",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Small\nAviation\nPlanes\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Truck\nand\nBus\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "SUV\nand\nLight\nTruck\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Car\nand\nAutomobile\nManufacturing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Small\nGas\nEngines\nMarket\n(Industrial,\nGardening,\nConstruction\nequipment)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "AfterMarket\nSuppliers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Motorcycle\nmanufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Boat\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Trailer\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n4",
    "target": "Motor\nHome\nManufacturers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waste\nWater\nInfrastructure",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waste\nWater\nInfrastructure",
    "target": "Workforce\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  }
]
