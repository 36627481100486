<mat-card>
    <div [formGroup]="form">
        <div mat-dialog-content class="add-field">
          <h2>Autocorrelation Plot</h2>
          <mat-form-field>
            <mat-label>Number of Lags (Maximun {{max}})</mat-label>
            <input type="number" min="1" [max]="max" step="1" formControlName="nlags_" ngDefaultControl matInput>
          </mat-form-field>
        </div>
        <button type="submit" min="0" (click)="onSubmit()" class="btn btn-home">Search</button>
    </div>
</mat-card>


