import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

interface studyDialog{
  filterInfo: any;
  selectedInfo: number;
}

@Component({
  selector: 'app-select-study',
  templateUrl: './select-study.component.html',
  styleUrls: ['./select-study.component.css']
})
export class SelectStudyComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectStudyComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: studyDialog,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  getValue($event:any){
    this.data.selectedInfo = $event;
  }
}
