import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';
import { PopulationGrowthComponent } from 'src/app/modules/transboundary-water/pages/population-growth/population-growth.component';
import { FloodingComponent } from '../../../shared/pages/flooding/flooding.component';
import { HousingPriceComponent } from 'src/app/modules/sawmills-production/pages/housing-price/housing-price.component';
import { BroadbandComponent } from '../../../shared/pages/broadband/broadband.component';
import { MineralResourcesComponent } from '../../../shared/pages/mineral-resources/mineral-resources.component';
import { NaturalGasConsumptionComponent } from '../../../shared/pages/natural-gas-consumption/natural-gas-consumption.component';
import { Covid19Component } from '../../../shared/pages/covid19/covid19.component';
import { SolarComponent } from 'src/app/shared/pages/solar/solar.component';
import { Co2emissionsComponent } from 'src/app/shared/pages/co2emissions/co2emissions.component';
import { SoilGridsComponent } from 'src/app/shared/pages/soil-grids/soil-grids.component';
import { ForestCharacteristicComponent } from 'src/app/shared/pages/forest-characteristic/forest-characteristic.component';
import { BridgesInfrastructureComponent } from 'src/app/shared/pages/bridges-infrastructure/bridges-infrastructure.component';
import { TrucksComponent } from 'src/app/shared/pages/trucks/trucks.component';
import { RoadwaysInfrastructureComponent } from 'src/app/shared/pages/roadways-infrastructure/roadways-infrastructure.component';
import { InlandWaterwaysInfrastructureComponent } from 'src/app/shared/pages/inland-waterways-infrastructure/inland-waterways-infrastructure.component';
import { MaritimePortsInfrastructureComponent } from 'src/app/shared/pages/maritime-ports-infrastructure/maritime-ports-infrastructure.component';
import { PrecipitationNetWaterComponent } from 'src/app/shared/pages/precipitation-net-water/precipitation-net-water.component';
import { NaturalForestComponent } from 'src/app/shared/pages/natural-forest/natural-forest.component';

export const DEFAULT_COMPONENT = GeneralNodeComponent;

// TODO: this is an intermediate step before migrating to lazy load components
export const COMPONENTS: Record<string, any> = {
  'MEX/inorganic-chemical/Broadband-Infrastructure-M': BroadbandComponent,
  'MEX/medical-device/COVID-19': Covid19Component,
  'CAN/inorganic-chemical/Natural-Gas': NaturalGasConsumptionComponent,
  'USA/sawmills-production/Housing-Prices': HousingPriceComponent,
  'USA/transboundary-water/Population-Growth': PopulationGrowthComponent,

  // shared components
  'TRI/power/Solar': SolarComponent,
  'MEX/transboundary-water/Precipitation': PrecipitationNetWaterComponent,
  'USA/sawmills-production/Natural-Forest': NaturalForestComponent,

};
