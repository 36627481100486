import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { delay, finalize } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

import { LOGIN_CONTROL_NAMES } from './login.constants';
import { AuthService } from '../../../core/services/authService/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loggingIn = false;

  logo = '';

  loginForm: FormGroup = this.fb.group({});

  CONTROL_NAMES = LOGIN_CONTROL_NAMES;

  errorMessage = '';

  passwordShown = false;

  get username(): string {
    return this.loginForm.get(this.CONTROL_NAMES.username)?.value
  }

  get password(): string {
    return this.loginForm.get(this.CONTROL_NAMES.password)?.value
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.hide();
    this.createForm();
  }

  private createForm() {

    const usernameControl = this.fb.control(null, [Validators.required]);
    const passwordControl = this.fb.control(null, [Validators.required, Validators.minLength(8)]);

    this.loginForm.addControl(this.CONTROL_NAMES.username, usernameControl);
    this.loginForm.addControl(this.CONTROL_NAMES.password, passwordControl);
  }

  handleLoginClick() {

    this.errorMessage = '';

    if (this.loginForm.invalid) return;

    this.spinner.show();
    this.authService.login(
      this.username?.toLowerCase(),
      this.password
    ).pipe(
      finalize(() => this.spinner.hide())
    ).subscribe(() => {
      this.redirectToHome();
    }, error => {
      this.errorMessage = error;
    });
  }

  handlePasswordClick() {
    this.passwordShown = !this.passwordShown;
  }

  private redirectToHome(): void {
    this.router.navigate(['home']);
    //this.router.navigate([''], { skipLocationChange: true });
  }
}