import { AfterViewInit, Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as XLSX from 'xlsx';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { DataPart } from 'src/app/core/models/amchart.model';


@Component({
  selector: 'app-line-category',
  templateUrl: './line-category.component.html',
  styleUrls: ['./line-category.component.css']
})
export class LineCategoryComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() barId!: string;
  @Input() data!: any;
  @Input() isGeneral!: any;
  @Input() customHeight!: string;
  @Input() metadata!:any;
  @Input() showDownloadButton:boolean = true;
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  @ViewChild('elRef', {read: ElementRef}) private elRef!: ElementRef<HTMLElement>;
  MAX_ROWS_PER_EXPORT = 50000; 
  dataParts: DataPart[] = [];
  datad: { category: string | undefined; value: number | undefined }[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.chart.dispose();
    this.root.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.root){
      if(this.isGeneral){
        this.initChart();
      }
      else {
        this.initChartRS();
      }
    }
      
  }

  ngAfterViewInit(): void {
    if(this.isGeneral){
      this.initChart();
    }
    else {
      this.initChartRS();
    }

    const series = this.chart?.series?.getIndex(0);
    if (series) {
      this.datad = series.dataItems.map(item => ({
        category: item.get("categoryX"),
        value: item.get("valueY")
      }));
    } else {
      console.error("There's no data");
    }
this.splitDataIntoParts();

  }
   splitDataIntoParts() {
    const totalData = this.datad.length;
    const totalParts = Math.ceil(totalData / this.MAX_ROWS_PER_EXPORT);

    for (let i = 0; i < totalParts; i++) {
      const start = i * this.MAX_ROWS_PER_EXPORT;
      const end = Math.min(start + this.MAX_ROWS_PER_EXPORT, totalData);
      this.dataParts.push({
        label: i + 1, 
        start: start + 1, 
        end: end, 
        selected: false  
      });
    }
  }

  

  initChart(){
    if(this.chart != null && this.chart != undefined  && this.root != null && this.root != undefined){
      this.chart!.dispose();
      this.root!.dispose();
    }
    this.root = am5.Root.new(this.elRef.nativeElement);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelY: "zoomXY",
        pinchZoomX: true
      })
    );

    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.yLabel,
      fontSize: 16,
      fontWeight: 'bold',
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 25
    }));
    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.title,
      textAlign: "center",
      fontSize: 16,
      fontWeight: "700",
      x: am5.p50,
      centerX: am5.p50,
      oversizedBehavior: "wrap",
    }));

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 50
    });

    let xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      oversizedBehavior: "wrap",
      maxWidth: 51,
      textAlign: "center"
    });

    xAxis.children.push(am5.Label.new(this.root, {
      text: this.data.xLabel,
      textAlign: 'center',
      x: am5.p50,
      fontSize: 14,
      fontWeight: 'bold'
    }));

    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 0.1
        }),
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    // Create series
    let series = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
      //am5xy.SmoothedXLineSeries.new(this.root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{valueY}"
        })
      })
    );

    let seriesSecond = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
      //am5xy.SmoothedXLineSeries.new(this.root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "valueSecond",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{valueY}"
        })
      })
    );

    series.bullets.push(() => {
      var graphics = am5.Circle.new(this.root, {
        radius: 4,
        interactive: true,
        cursorOverStyle: "ns-resize",
        stroke: series.get("stroke"),
        fill: am5.color("#67B7DC"),
      });
    
      return am5.Bullet.new(this.root, {
        sprite: graphics
      });
    });
    
    // Add scrollbar
    this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
      orientation: "horizontal"
    }));

    // Add cursor
    this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      snapToSeries: [series]
    }));

    series.data.setAll(this.data.data);
    series.fills.template.setAll({fillOpacity: 0.5, visible: true});
    seriesSecond.data.setAll(this.data.data);
    seriesSecond.fills.template.setAll({fillOpacity: 0.5, visible: true});
    xAxis.data.setAll(this.data.data);

    this.chart.zoomOutButton.set("forceHidden", true);

    // Make stuff animate on load
    series.appear(1000);
    this.chart.appear(1000, 100);

  }

  initChartRS() {
    if (this.chart != null && this.chart != undefined && this.root != null && this.root != undefined) {
      this.chart!.dispose();
      this.root!.dispose();
    }
  
    // Create root element
    this.root = am5.Root.new(this.elRef.nativeElement);
  
    // Set themes
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);
  
    // Create chart
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelY: "zoomXY",
        pinchZoomX: true
      })
    );
  
    this.chart.leftAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.yLabel,
      fontSize: 16,
      fontWeight: 'bold',
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      rotation: 270,
      paddingBottom: 25
    }));
    this.chart.topAxesContainer.children.push(am5.Label.new(this.root, {
      text: this.data.title,
      textAlign: "center",
      fontSize: this.getAdaptiveFontSize(this.data.title),
      fontWeight: "700",
      x: am5.p50,
      centerX: am5.p50,
      oversizedBehavior: "wrap",
      maxWidth: this.elRef.nativeElement.clientWidth * 0.9,  // Ajusta el ancho máximo del texto al 90% del ancho del contenedor
    }));
  
    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 20
    });
  
    let xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );
  
    xRenderer.grid.template.setAll({
      location: 1
    });
  
    xRenderer.labels.template.setAll({
      oversizedBehavior: "none", 
      maxWidth: 51, 
      textAlign: "center"
    });
  
    xAxis.children.push(am5.Label.new(this.root, {
      text: this.data.xLabel,
      textAlign: 'center',
      x: am5.p50,
      fontSize: 14,
      fontWeight: 'bold'
    }));
  
    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0, // Min Y
        max: 1, // Max Y
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 0.1
        }),
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );
  
    let colorSerie = this.data.style?.colorSerieData ? this.data.style?.colorSerieData : "#67B7DC";
    let colorSerieSecond = this.data.style?.colorSerieNormalFit ? this.data.style?.colorSerieNormalFit : "#759edf";
  
    // Create series
    let series = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: "Data",
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        stroke: am5.color(colorSerie),
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{valueY}"
        })
      })
    );
  
    let seriesSecond = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: "Normal Fit",
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "valueSecond",
        categoryXField: "category",
        stroke: am5.color(colorSerieSecond),
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{valueY}"
        })
      })
    );
  
    // Customize series appearance
    if (this.data.style) {
      series.strokes.template.setAll({
        strokeWidth: 2,
        stroke: am5.color(colorSerie)
      });
  
      seriesSecond.strokes.template.setAll({
        strokeWidth: 2,
        stroke: am5.color(colorSerieSecond)
      });
  
    } else {
      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });
  
      series.bullets.push(() => {
        var graphics = am5.Circle.new(this.root, {
          radius: 4,
          interactive: true,
          cursorOverStyle: "ns-resize",
          stroke: series.get("stroke"),
          fill: am5.color("#67B7DC"),
        });
  
        return am5.Bullet.new(this.root, {
          sprite: graphics
        });
      });
  
      seriesSecond.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });
    }
  
    // Add scrollbar
    this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
      orientation: "horizontal"
    }));
  
    // Add cursor
    this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      xAxis: xAxis,
      yAxis: yAxis,
      snapToSeries: [series]
    }));
  
    series.data.setAll(this.data.data);
    seriesSecond.data.setAll(this.data.data);
    xAxis.data.setAll(this.data.data);
  
    // // Add a legend
    // let legend = this.chart.children.push(am5.Legend.new(this.root, {
    //   centerX: am5.p50,
    //   x: am5.p50,
    // }));
  
    // legend.data.setAll(this.chart.series.values);
  
    this.chart.zoomOutButton.set("forceHidden", true);
  
    // Make stuff animate on load
    series.appear(1000);
    this.chart.appear(1000, 100);
  } 
  getAdaptiveFontSize(text: string): number {
    const baseSize = 16;  // Tamaño de fuente base
    const maxSize = 24;   // Tamaño de fuente máximo
    const minSize = 12;   // Tamaño de fuente mínimo
  
    // Calcular tamaño basado en la longitud del texto
    let calculatedSize = baseSize - (text.length / 10);
  
    // Limitar el tamaño dentro de los valores máximos y mínimos
    if (calculatedSize > maxSize) calculatedSize = maxSize;
    if (calculatedSize < minSize) calculatedSize = minSize;
  
    return calculatedSize;
  }

  exportAll() {
    const totalData = this.datad;
    const totalRows = totalData.length;
    if (totalRows > this.MAX_ROWS_PER_EXPORT) {
      const totalParts = Math.ceil(totalRows / this.MAX_ROWS_PER_EXPORT);
      for (let i = 0; i < totalParts; i++) {
        const start = i * this.MAX_ROWS_PER_EXPORT;
        const end = Math.min(start + this.MAX_ROWS_PER_EXPORT, totalRows);
        const dataChunk = totalData.slice(start, end);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataChunk);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `Part ${i + 1}`);
        XLSX.writeFile(wb, `Line_graph_data${this.data.title}_${this.metadata.dashboard}_${this.metadata.net}_${this.metadata.node}_Part_${i + 1}.xlsx`);
      }
    } else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(totalData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `All_data`);
      XLSX.writeFile(wb, `Complete_data_line_graph${this.data.title}_${this.metadata.dashboard}_${this.metadata.net}_${this.metadata.node}_${Date()}.xlsx`);
    }
  }
}

