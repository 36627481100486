import { GraphLinkModel } from "../models/graph.model";

export const mvmLinksCornFarmingUSA: GraphLinkModel[] = [
    {
      "source": "Aging\nInfrastructure",
      "target": "Inland Waterway (S-WR)\nMississippi River",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure",
      "target": "International Port (WR-C)\nNew Orleans",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Barge\n(S-WR)",
      "target": "International\nMerchandising\nConventional Feed",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Conventional Crop Yield\n(USA Mid-West States)",
      "target": "Conventional\nField\nCorn",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Conventional Field\nCorn",
      "target": "Barge\n(S-WR)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Conventional\nField\nCorn",
      "target": "Water Footprint\n(USA)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Cyberattacks",
      "target": "International Port (WR-C)\nNew Orleans",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Cyberattacks",
      "target": "International\nVessels\n(WR-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Mississippi\nRiver\nWater\nLevel",
      "target": "Inland Waterway (S-WR)\nMississippi River",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tropical\nCyclones",
      "target": "Flooding",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Mississippi\nRiver\nWater\nLevels",
      "target": "Inland Waterway (S-WR)\nMississippi River",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Flooding",
      "target": "International Port (WR-C)\nNew Orleans",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Tropical\nCyclones",
      "target": "International Port (WR-C)\nNew Orleans",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Inland Waterway (S-WR)\nMississippi River",
      "target": "Barge\n(S-WR)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "International\nMerchandising\nConventional Feed",
      "target": "International\nVessels\n(WR-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "International Port (WR-C)\nNew Orleans",
      "target": "International\nVessels\n(WR-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "International\nVessels\n(WR-C)",
      "target": "Livestock Operations\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Livestock Operations\n(Mexico)",
      "target": "Water Footprint\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Livestock Operations\n(Mexico)",
      "target": "Food Security\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Livestock Operations\n(Mexico)",
      "target": "Cattle Price\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Livestock Operations\n(Mexico)",
      "target": "Corn Imports\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Livestock Operations\n(Mexico)",
      "target": "Cattle Exports\n(Mexico)",
      "lineStyle": {
        "curveness": 0.1
      }
    }
  ]
  