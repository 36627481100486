<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template [data]="this.data" [AllowValueHover]="true"></app-dynamic-point-template>
   </div>   
</div>
  
<div *ngIf=" !this.isUSA">
    <app-not-found></app-not-found>
</div>
  

