import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

declare const Plotly: any;

@Component({
  selector: 'app-plotly',
  templateUrl: './plotly.component.html',
  styleUrls: ['./plotly.component.css'],
})
export class PlotlyComponent implements OnChanges {
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLElement>;
  @Input() config: any;
  private _graph: any;
  private readonly _config: any;

  constructor() {
    this._config = {
      data: [],
      layout: {
        font: {
          family: 'Open Sans, sans-serif',
        },
        legend: {
          font: {
            family: 'Open Sans, sans-serif',
            size: 15,
          },
        },
        scene: {
          xaxis: {
            title: 'x',
            visible: false,
          },
          yaxis: {
            title: 'y',
            visible: false,
          },
          zaxis: {
            title: 'z',
            visible: false,
          },
          annotations: [
            {
              text: 'Consulting Information...',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: {
                size: 28,
              },
            },
          ],
          camera: {
            center: {
              x: 0,
              y: 0,
              z: -0.3,
            },
            eye: {
              x: 1.8,
              y: -1.8,
              z: 0.8,
            },
            up: {
              x: 0,
              y: 0,
              z: 1,
            },
          },
          aspectratio: {
            x: 1.8,
            y: 1,
            z: 0.8,
          },
        },
        title: {
          text: '',
        },
        margin: {
          t: -100,
        },
        width: 1000,
        height: 800,
        autosize: false,
        showlegend: false,
      },
      frames: [],
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.settingGraph();
    this.configGraph();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeGraph();
  }

  settingGraph() {
    try {
      this.config['update'] = () => {
        this.updateGraph();
      };
      this.config['delete'] = () => {
        this.purgeGraph();
      };
      this.config['scatter3d'] = (value: any = {}) => {
        let scatter3d: any = {
          line: {
            width: 1.6,
            color: 'rgba(0, 60, 130, 1)',
          },
          type: 'scatter3d',
          x: [],
          y: [],
          z: [],
        };
        this._config['data'].push({ ...scatter3d, ...value });
      };
      this.config['surface'] = (value: any = {}) => {
        let surface: any = {
          type: 'surface',
          x: [],
          y: [],
          z: [],
        };
        this._config['data'].push({ ...surface, ...value });
      };
      this.config['heatmap'] = (value: any = {}) => {
        let heatmap: any = {
          type: 'heatmap',
          x: [],
          y: [],
          z: [],
          colorbar: {
            title: {
              text: '',
              font: {
                family: 'Open Sans, sans-serif',
                size: 18,
              },
            },
            titleside: 'right',
          },
        };
        this._config['data'].push({ ...heatmap, ...value });
      };
      this.config['mesh3d'] = (value: any = {}) => {
        let mesh3d: any = {
          type: 'mesh3d',
        };
        this._config['data'].push({ ...mesh3d, ...value });
      };
    } catch (e) {
      console.error(
        "Incorrect initialization, doesn't accept null and undefined values."
      );
    }
  }

  updateGraph() {
    if (this.config['data']) {
      this._config['layout']['scene']['xaxis']['visible'] = true;
      this._config['layout']['scene']['yaxis']['visible'] = true;
      this._config['layout']['scene']['zaxis']['visible'] = true;
      this._config['layout']['scene']['annotations'] = [];
      this._config['data'] = this.config['data'](this._config['data']);
    }
    if (this.config['layout']) {
      this._config['layout'] = this.config['layout'](this._config['layout']);
    }
    if (this.config['frames']) {
      this._config['frames'] = this.config['frames'](this._config['frames']);
    }

    this.configGraph();
  }

  configGraph() {
    if (this.chartEl == null || !this.chartEl.nativeElement) return;
    this.generateGraph(this.chartEl.nativeElement, this._config);
  }

  generateGraph(graph: any, setting: any) {
    Plotly.newPlot(graph, {
      data: setting.data,
      layout: setting.layout,
      frames: setting.frames,
      config: {
        displayModeBar: true,
        showLink: false,
        linkText: 'Export to plotly.com',
      },
    });
    setTimeout(() => this.resizeGraph());
  }

  purgeGraph() {
    Plotly.purge(this._graph);
  }

  redrawGraph() {
    Plotly.redraw(this._graph);
  }

  resizeGraph() {
    if (this._graph) {
      let boundingClientRect = this._graph.getBoundingClientRect();
      Plotly.relayout(this._graph, {
        width: boundingClientRect.width,
        height: boundingClientRect.height,
      });
      this.redrawGraph();
    }
  }
}
