<!-- <div class="one-filter-container">
  <mat-form-field>
      <mat-label>{{this.data.yAxisName}}</mat-label>
      <mat-select [formControl]="yAxisFilter">
        <mat-option *ngFor="let item of this.data.yAxis" [value]="item.id">{{item.shortName}}</mat-option>
      </mat-select>
    </mat-form-field>

</div> -->

<div class="row">
  <div class="col-2">
    <label for="firstFilter" class="form-label subtitle">{{this.data.yAxisName}}</label>
      <select class="form-select"  [formControl]="yAxisFilter">
        <option *ngFor="let item of this.data.yAxis" [value]="item.id">
          {{item.shortName}}
        </option>
      </select>
  </div>
</div>

