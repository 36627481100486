import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { FilterType, DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { ManufacturingService } from 'src/app/core/services/manufacturing/manufacturing.service';
import { HomeStore } from 'src/app/core/stores/home-store';

enum VariableType {
  Availability_Internet_Service = 123,
  Internet_Usage = 124
}

@Component({
  selector: 'app-broadband',
  templateUrl: './broadband.component.html',
  styleUrls: ['./broadband.component.css']
})
export class BroadbandComponent {
  public data!: any;
  public dataMap: any = {};
  public varType = VariableType;
  public selectedView: number = 124;
  private filterInfo!: any;
  private filterInfoDos!: any;

  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  public label: string = "Size";
  public series: [] | any;

  constructor( private manufacturingService: ManufacturingService,
    private homeStore: HomeStore, private spinner: NgxSpinnerService, ) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      
      this.getDataCN();
    }  
    if (this.isUSA) {
      this.getDataUs();
    } 
    if (this.isMx) {
      this.dataMap = {
        center: MapCenter.MEXICO,
        scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
        json: MapJson.MEXICO,
        zoom: MapZoom.MEXICO,
        dateFormat: 'YYYY-mm',
        borderColor: '#000000'
      };
      this.filterInfo = {
        firstFilter: {name: "variable Type", values: [
          {id: 0, itemId: 123, value: 'Availability Internet Service'},
          {id: 1, itemId: 124, value: 'Internet Usage'}, 
        ]}
      }
      this.filterInfoDos = {
        firstFilter: {name: "Type", values: [
          {id: 0, value: 'Size'},
          {id: 1, value: 'Age'}, 
        ]}
      }
      const model = {Type: this.label};
      this.getDataMx(model);
    }
    if(this.isTri){
      this.getDataTri();
    }

  }
  getDataMx(model: any) {
    if(this.varType.Internet_Usage == this.selectedView){
      this.manufacturingService.getBroadbandMx()
      .subscribe(
        {
          next: (res) => {
            this.data ={
              data: res,
              mapInfo: this.dataMap,
              isStateOnly: true,
              hasStudyButton: true,
              filterInfo: this.filterInfo,
              //filterVariableInfo: this.filterInfo,
              selectedInfo: this.selectedView,
              labelPopup: 'Value'
            } ;
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );

    } else if(this.varType.Availability_Internet_Service == this.selectedView){

      this.manufacturingService.getAviabilityInternetMx(model)
      .subscribe(
        {
          next: (res) => {

            this.data ={
              data: res.data,
              metadata: res.metadata,
              series: [
                {propertyName: "percentage", longName: "Units Without Internet Services", color: ""},
                {propertyName: "percentage2", longName: "Units With Internet Services", color: ""},
                {propertyName: "percentage3", longName: "Units Without Computer Equipment", color: ""},
                {propertyName: "percentage4", longName: "Units With Computer Equipment", color: ""},
              ],
              stacked: true,
              percentage: true,
              hasStudyButton: true,
              filterInfo: this.filterInfo,
              selectedInfo: this.selectedView,
              
              hasFilter: true,
              filterType:  FilterType.oneFilter,
              filterInfoSecond: this.filterInfoDos

            } 
            this.spinner.hide();
          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
    }
    
  }
  //US Region
  getDataUs() {
    this.spinner.hide();
  }

  //Cn Region
  getDataCN() {
    this.spinner.hide();
  }

  //Trinational region
  getDataTri(){
    this.spinner.hide();
  }


  getValue($event:any){
    this.spinner.show();
    this.data = undefined;
    this.selectedView = $event;

    const model = {Type: this.label};
    this.getDataMx(model);
  }

   //Reloads
   reloadMx($event:any){

    if($event=='Size'){
      this.filterInfoDos = {
        firstFilter: {name: "Type", values: [
          {id: 0, value: 'Size'},
          {id: 1, value: 'Age'}, 
        ]}
      }
    }
    else{
      this.filterInfoDos = {
        firstFilter: {name: "Type", values: [
          {id: 0, value: 'Age'},
          {id: 1, value: 'Size'}, 
        ]}
      }
    }

    this.label = $event.toString();
    this.data = undefined;
    
    const model = {Type: this.label};
    this.getDataMx(model);
  }
}
