export enum MapJson {
    MEXICO = "mx",
    USA = "us",
    CANADA = "cn",
    TRI = "tri"
};
export const MapCenter = 
{
    MEXICO: [23.80545, -99.536133],
    USA: [40.0000, -96.0000],
    USA_CALIFORNIA: [37.1601169,-124.5977737],
    CANADA: [60.0000 , -96.0000]
}
export const MapZoom = 
{
    MEXICO: 3.5,
    USA: 4.3,
    CANADA: 3.5,
    TRINATIONAL: 2.5,
    GENERAL: 3,
    USA_CALIFORNIA: 5,
}

export enum ShapeMapDataLink {
    Drought_tri = "natural/drought/tri/data",
    Earthquake_us = "natural/earthquake/us/data",
    Wildfire_us = `natural/wildfire/us/data`,
    Wildfire_cn = `natural/wildfire/cn/data`,
    Natural_forest_us = "natural/natural_forest/usa/data",
    Flooding_cn = "natural/flooding/cn/data",
}
export enum GeotiffDataLink{
    SurfaceAirTemperature_us = "natural/surfaceAirTemperature/us/data/",
    Precipitation_us = "natural/precipitation/us/data/",
    Solar_gbl = "system/solar/gbl/data/",
    SoilGrids_gbl = "natural/soilgrids/gbl/data/",
    ForestCharacteristic_gbl = "natural/forestcharacteristics/gbl/data/",
}