<section class="section pt-0">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-5">
        <form *ngIf="inputsForm" [formGroup]="inputsForm">
          <div class="graph-card">
            <h3 class="text-center mb-5">Inputs</h3>
            <div
              id="inputs"
              cdkDropList
              [cdkDropListData]="variables"
              cdkDropListSortingDisabled
              cdkDropListConnectedTo="output"
              class="variables-grid"
            >
              <ng-container *ngFor="let variable of variables">
                <div class="" style="min-width: 0" cdkDrag [cdkDragDisabled]="!variable.canBeOutput" [cdkDragData]="variable">
                  <mat-form-field [class.static]="!variable.canBeOutput" [class.disabled]="output?.name === variable.name"
                                  appearance="fill">
                    <mat-label>{{ variable.label }}</mat-label>
                    <input type="text" placeholder="" matInput [formControlName]="variable.name" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option value="low">Low</mat-option>
                      <mat-option value="medium">Medium</mat-option>
                      <mat-option value="high">High</mat-option>
                    </mat-autocomplete>
                    <button *ngIf="variable.canBeOutput" mat-icon-button matSuffix cdkDragHandle style="cursor:grab;">
                      <mat-icon>drag_indicator</mat-icon>
                    </button>
                  </mat-form-field>
                  <!--<ng-container *ngIf="!input.output">
                    <div class="selection">
                      {{input.name}}</div>
                  </ng-container>
                  <ng-container *ngIf="input.output">
                    <div class="selection selection&#45;&#45;simple">
                      {{input.name}}</div>
                  </ng-container>-->
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
      <div class="col-2">
        <div class="graph-card">
          <h3 class="text-center mb-5">Model</h3>
          <button class="btn w-100 btn-blue justify-content-center" [disabled]="!inputsForm?.valid" (click)="run()">Run</button>
        </div>
      </div>
      <div class="col-4">
        <div class="graph-card">
          <h3 class="text-center mb-5">Outputs</h3>
          <div class="row mb-5">
            <div class="col-10 mx-auto">
              <div
                id="output"
                cdkDropList
                [cdkDropListData]="variables"
                (cdkDropListDropped)="onDrop($event)"
                (cdkDropListEntered)="onEntered($event)"
                [cdkDropListDisabled]="!!output"
              >
                <ng-container *ngIf="output">
                  <mat-form-field appearance="fill" readonly style="pointer-events: none">
                    <mat-label>{{ output.label }}</mat-label>
                    <input type="text" placeholder="" matInput [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option value="low">Low</mat-option>
                      <mat-option value="medium">Medium</mat-option>
                      <mat-option value="high">High</mat-option>
                    </mat-autocomplete>
                    <button mat-icon-button matSuffix cdkDragHandle (click)="removeOutput($event)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-center">
            <div class="col col-auto" style="font-size: 1.5rem">
              Predicted value:
            </div>
            <div class="col col-auto">
              <input class="text-center input" type="text" [value]="result?.predictedValue" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="result !== null">
  <section class="section pt-0">
    <div class="container mb-5rem">
      <div class="row">
        <div class="col-12 mb-5">
          <h3>Results</h3>
          <p>
            Predicted approach
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="graph-card">
        <div class="row align-items-center">
          <div class="col">
            <table class="table border">
              <thead>
              <tr>
                <th scope="col" colspan="2">Inputs</th>
                <th scope="col" colspan="2">Variable importance</th>
                <th scope="col" colspan="8"></th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let input of result.variables">
                <tr [class.highlighted]="input.highlighted">
                  <th>{{input.label}}</th>
                  <td>{{input.predictedValue}}</td>
                  <td><div class="bar" [style.width.%]="input.width"></div></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div class="col-auto">
            <div class="row align-items-center justify-content-center mb-5">
              <div class="col col-auto" style="font-size: 1.5rem">
                Predicted value:
              </div>
              <div class="col col-auto">
                <input class="text-center input" type="text" [value]="result.predictedValue" readonly>
              </div>
            </div>
            <div class="row align-items-center justify-content-center">
              <div class="col col-auto" style="font-size: 1.5rem">
                Accuracy:
              </div>
              <div class="col col-auto">
                <input class="text-center input" type="text" [value]="result.accuracy" readonly>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

