import { GraphItemModel, GraphLinkModel } from "../models/graph.model";
/* Categories */
export const CornFarmingGraphCategory: any[] = [
  /*{ name: 'Anthropogenic', itemStyle: { color: '#006483' } },
  { name: 'Raw Materials & Sources', itemStyle: { color: '#c17a2f' } },
  { name: 'Natural', itemStyle: { color: '#438bca' } },
  { name: 'Suppliers', itemStyle: { color: '#2b0171' } },
  { name: 'Local Logistic', itemStyle: { color: '#500101' } },
  { name: 'Domestic Manufacturing & Production', itemStyle: { color: '#707070' } },
  { name: 'Logistic', itemStyle: { color: '#9d1010' } },
  { name: 'WholeSealers / Retailers', itemStyle: { color: '#5a6237' } },
  { name: 'Customers', itemStyle: { color: '#b0b1b1' } },
  { name: 'Environmental', itemStyle: { color: '#ad9d68' } },
  { name: 'Economic', itemStyle: { color: '#ad9d68' } },
  { name: 'Social', itemStyle: { color: '#ad9d68' } },
  { name: 'Critical\nInfrastructure', itemStyle: { color: '#4966b0' }},*/

  { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
  { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
  { id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
  { id: 3,name: 'Water Sources asd2', itemStyle: { color: '#ff9900' } },
  { id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
  { id: 5,name: 'Logistic / Distribution ad2', itemStyle: { color: '#500101' } },
  { id: 6,name: 'Wholesalers / Retailers', itemStyle: { color: '#ccffcc' } },
  { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
  { id: 8,name: 'Logistic qwe2', itemStyle: { color: '#9d1010' } },
  { id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
  { id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
  { id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
  { id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
  { id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
  { id: 14,name: 'Logistic', itemStyle: { color: '#993365' } },
  { id: 15, name: 'Vendor/Manufacturer', itemStyle: { color: '#a0a0a0' } },
];
export const CornFarmingGraphCategoryLink: any[] = [
  { name: 'Threats', links: [CornFarmingGraphCategory[0], CornFarmingGraphCategory[2], CornFarmingGraphCategory[12]]},
  { name: 'Systems', links: [CornFarmingGraphCategory[1], CornFarmingGraphCategory[3], 
        CornFarmingGraphCategory[4], CornFarmingGraphCategory[5],CornFarmingGraphCategory[6],
        CornFarmingGraphCategory[7], CornFarmingGraphCategory[8]
    ]},
  { name: 'State of Risk', links: [CornFarmingGraphCategory[9], CornFarmingGraphCategory[10], CornFarmingGraphCategory[11]
    ]}
]
/*End categories */

/*NODES*/
export const CornFarmingGraphItems: GraphItemModel[] = [
  { fullname:'Season', name: 'Season', x: 89, y: -25, symbol:"rect", category: 2, isActive: false },
  { fullname:'Regulators', name: 'Regulators', x: 89, y: -20, category: 0, isActive: false },
  { fullname:'Insurance', name: 'Insurance', x: 89, y: -15, category: 0, isActive: false },
 
  //#region Anthropogenic
  { fullname:'Energy Policies', name: 'Energy\nPolicies', x: -25, y: -25, category: 0, isActive: true },
  { fullname:'Agriculture Policies', name: 'Agriculture\nPolicies', x: -25, y: -20, category: 0, isActive: false },
  { fullname:'Water Policies', name: 'Water Policies', x: -26, y: -15, category: 0, isActive: false },
  { fullname:'Lack of Infraestructure', name: 'Lack of\nInfrastructure', x: -25, y: -10, category: 0, isActive: false },
  { fullname:'Climate Change', name: 'Climate\nChange', x: -25, y: -5, category: 0, isActive: false },
 
  { fullname:'Trade Policies', name: 'Trade Policies', x: -20, y: -25, category: 0, isActive: false },
  { fullname:'Local Policies', name: 'Local\nPolicies', x: -20, y: -20, category: 0, isActive: false },
  { fullname:'Expected Market Prices', name: 'Expected\nMarket Prices', x:-20, y: -15, category: 0, isActive: false },
  { fullname:'Labor Shortage', name: 'Labor\nShortage', x: -20, y: -10, category: 0, isActive: false },
  
  { fullname:'International Policies', name: 'International\nPolicies', x: -15, y: -25, category: 0, isActive: false },
  { fullname:'Market Concentration', name: 'Market\nConcentration', x: -15, y: -20, category: 0, isActive: false },
  { fullname:'Immigation Policies', name: 'Immigation\nPolicies', x: -15, y: -15, category: 0, isActive: false },
  { fullname:'Land Ownership', name: 'Land\nOwnership', x: -15, y: -10, category: 0, isActive: false },
  { fullname:'Age', name: 'Age', x: -15, y: -5, category: 0, isActive: false },
  
  { fullname:'Organized Crime', name: 'Organized\nCrime', x: -10, y: -27, category: 0, isActive: false },
  { fullname:'Machinery\nInfrastructure', name: 'Machinery\nInfrastructure', x: -10, y: -22, category: 0, isActive: false },
  { fullname:'Fuel Prices', name: 'Fuel\nPrices', x: -10, y: -15, category: 0, isActive: false },
  { fullname:'Ethanol', name: 'Ethanol', x: -10, y: -10, category: 0, isActive: false },
  { fullname:'Futures Market', name: 'Futures\nMarket', x: -10, y: -5, category: 0, isActive: false },
  { fullname:'Health', name: 'Health', x: -10, y: 0, category: 0, isActive: false },
  
  { fullname:'Machine Parts', name: 'Machine Parts', x: -5, y: -25, category: 0, isActive: false },
  { fullname:'Information technology', name: 'Information\ntechnology', x: -5, y: -20, category: 0, isActive: false },
  { fullname:'Cyberattacks', name: 'Cyberattacks', x: -5, y: -15, category: 0, isActive: false },
  { fullname:'Credit', name: 'Credit', x: -5, y: -10, category: 0, isActive: false },
  { fullname:'Urbanization', name: 'Urbanization', x: -5, y: -5, category: 0, isActive: false },
  { fullname:'Skilled Labor Availability', name: 'Skilled\nLabor\nAvailability', x: -5, y: 0, category: 0, isActive: false },
  
  { fullname:'Federal Transportation Policies', name: 'Federal\nTransportation\nPolicies', x: 0, y: -27, category: 0, isActive: false },
  { fullname:'Aging\nInfrastructure', name: 'Aging\nInfrastructure', x: 0, y: -22, category: 0, isActive: false },
  { fullname:'Machinery Repairments', name: 'Machinery\nRepairments', x: 0, y: -17, category: 0, isActive: false },
  { fullname:'Rental Markets', name: 'Rental\nMarkets', x: 0, y: -12, category: 0, isActive: false },
  
  //#endregion
 
  //#region Raw Material
  { fullname:'Fertilizers', name: 'Fertilizers', x: 7, y: -25, category: 1, isActive: false },
  { fullname:'Fungicides', name: 'Fungicides', x: 7, y: -20, category: 1, isActive: false },
  { fullname:'Organic Crop Yield', name: 'Organic\nCrop Yield', x: 7, y: -15, category: 1, isActive: false },
  { fullname:'Machinery Availability', name: 'Machinery\nAvailability', x: 7, y: -10, category: 1, isActive: false },
  { fullname:'Conventional Crop Yield', name: 'Conventional\nCrop Yield', x: 7, y: -5, category: 1, isActive: false },
  { fullname:'Seed Genetic Availability', name: 'Seed\nGenetic\nAvailability', x: 7, y: 0, category: 1, isActive: false },
 
  { fullname:'Pesticides', name: 'Pesticides', x: 12, y: -18, category: 1, isActive: false },
  { fullname:'Land Availability', name: 'Land\nAvailability', x: 12, y:-12, category: 1, isActive: false },
  { fullname:'Water Irrigation', name: 'Water\nIrrigation', x: 12, y: -7, category: 1, isActive: false },
  //#endregion
   
  //#region Suppliers
  { fullname:'Organic Farmers', name: 'Organic\nFarmers', x: 18, y: -23, category: 3, isActive: false },
  { fullname:'Conventional Farmers', name: 'Conventional\nFarmers', x: 18, y: -18, category: 3, isActive: false },
  { fullname:'Custom Operations', name: 'Custom\nOperations', x: 18, y: -13, category: 3, isActive: false },
  { fullname:'Organic Silage', name: 'Organic\nSilage', x: 18, y: -7, category: 3, isActive: false },
 
  { fullname:'Certification', name: 'Certification', x: 24, y: -25, category: 3, isActive: false },
  { fullname:'Organic Field Corn', name: 'Organic\nField\nCorn', x: 24, y: -20, category: 3, isActive: false },
  { fullname:'Conventional Field Corn', name: 'Conventional\nField\nCorn', x: 24, y: -15, category: 3, isActive: false },
   { fullname:'Conventional Silage', name: 'Conventional\nSilage', x: 24, y: -10, category: 3, isActive: false },
   //#endregion
  
   //#region  Domestc Manufacturing & Production
   { fullname:'Organic Feed Supply', name: 'Organic\nFeed\nSupply', x: 30, y: -25, category: 5, isActive: false },
   { fullname:'Ethanol ', name: 'Ethanol ', x: 30, y: -20, category: 5, isActive: true },
   { fullname:'Domestic Organic Feed Manufacturer', name: 'Domestic Organic\nFeed Manufacturer', x: 30, y: -15, category: 5, isActive: false },
   { fullname:'Domestic Organic Grain Food Manufacturers', name: 'Domestic Organic\nGrain Food\nManufacturers', x: 30, y: -10, category: 5, isActive: false },
   { fullname:'Domestic Ethanol Market', name: 'Domestic\nEthanol\nMarket', x: 30, y: -5, category: 5, isActive: false },
   { fullname:'Other Corn-Based Food Ingredients', name: 'Other Corn-Based\nFood Ingredients', x: 30, y: 0, category: 5, isActive: false },
   
   { fullname:'Organic Food Uses', name: 'Organic\nFood\nUses', x: 35, y: -23, category: 5, isActive: false },
   { fullname:'Conventional Feed Supply', name: 'Conventional\nFeed Supply', x: 35, y: -18, category: 5, isActive: false },
   { fullname:'Bevarages Ethanol', name: 'Bevarages\nEthanol', x: 35, y: -13, category: 5, isActive: true },
   { fullname:'Domestic Conventional Feed Manufacturer', name: 'Domestic\nConventional\nFeed Manufacturer', x: 36, y: -6, category: 5, isActive: false },
   { fullname:'DSGs', name: 'DSGs', x: 35, y: 3, category: 5, isActive: false },
   
   { fullname:'Organic Sweeteners', name: 'Organic\nSweeteners', x: 40, y: -25, category: 5, isActive: false },
   { fullname:'Conventional Food Uses', name: 'Conventional\nFood\nUses', x: 40, y: -20, category: 5, isActive: false },
   { fullname:'Production of Sweeteners', name: 'Production of\nSweeteners', x: 40, y: -15, category: 5, isActive: false },
   { fullname:'Other Organic Corn-Based Food Ingredients', name: 'Other Organic\nCorn-Based\nFood Ingredients', x: 40, y: -9, category: 5, isActive: false },
   { fullname:'Domestic Conventional Food Manufacturer', name: 'Domestic\nConventional Food\nManufacturer', x: 40, y: 0, category: 5, isActive: false },
  //#endregion 
  
  //#region WholeSealers / Retailers
  { fullname:'Domestic Organic Food Wholesealers', name: 'Domestic\nOrganic Food\nWholesealers', x: 48, y: -25, category: 7, isActive: false },
  { fullname:'Domestic Merchandising Conventional Feed', name: 'Domestic\nMerchandising\nConventional Feed', x: 48, y: -20, category: 7, isActive: false },
  { fullname:'Domestic Organic Food Retailers', name: 'Domestic\nOrganic Food\nRetailers', x: 48, y: -15, category: 7, isActive: false },
  { fullname:'International Merchandising Organic Feed', name: 'Domestic\nMerchandising\nConventional Feed', x: 48, y: -10, category: 7, isActive: false },
 
  { fullname:'Gasoline Market', name: 'Gasoline\nMarket', x: 53, y: -23, category: 7, isActive: false },
  { fullname:'Domestic Conventional Food Wholesealers', name: 'Domestic\nConventional\nFood\nWholesealers', x: 53, y: -13, category: 7, isActive: false },
  
  { fullname:'Domestic Conventional Food Retailers', name: 'Domestic\nConventional\nFood\nRetailers', x: 58, y: -25, category: 7, isActive: false },
  { fullname:'International Ethanol Market', name: 'International\nEthanol Market', x: 58, y: -20, category: 7, isActive: false },
  { fullname:'Organic Sweeteners ', name: 'Organic\nSweeteners ', x: 58, y: -16, category: 7, isActive: false },
  { fullname:'International Merchandising Conventional Food', name: 'International\nMerchandising\nConventional Food', x: 58, y: -10, category: 7, isActive: false },
  { fullname:'Conventional Sweeteners', name: 'Conventional\nSweeteners', x: 58, y: -6, category: 7, isActive: false },
   { fullname:'International Merchandising Conventional Feed', name: 'International\nMerchandising\nConventional Feed', x: 53, y: -2, category: 7, isActive: false },
  
  { fullname:'International Merchandising Organic Food', name: 'International\nMerchandising\nOrganic Food', x: 65, y: -18, category: 7, isActive: false },
  { fullname:'Domestic Merchandising Organic Feed', name: 'Domestic\nMerchandising\nOrganic Feed Feed Feed', x:65, y: -8, category: 7, isActive: false },
  //#endregion
  
  //#region Customers
  { fullname:'Domestic Organic Food Demand', name: 'Domestic Organic\nFood Demand', x: 72, y: -25, category: 8, isActive: false },
  { fullname:'Domestic Conventional Food Demand', name: 'Domestic\nConventional\nFood Demand', x: 72, y: -19, category: 8, isActive: false },
  { fullname:'International Food Production', name: 'International\nFood Production', x: 71, y: -15, category: 8, isActive: false },
  { fullname:'International Organic Food Production', name: 'International\nOrganic\nFood Production', x: 72, y: -10, category: 8, isActive: false },
  
  { fullname:'Domestic Livestock Operations', name: 'Domestic\nLivestock\nOperations', x: 78, y: -28, category: 8, isActive: false },
  { fullname:'Domestic Organic Livestock Operations', name: 'Domestic\nOrganic\nLivestock\nOperations', x: 78, y: -20, category: 8, isActive: false },
  { fullname:'International Livestock Operations', name: 'International\nLivestock\nOperations', x: 78, y: -13, category: 8, isActive: false },
  { fullname:'International Organic Livestock Operations', name: 'International\nOrganic\nLivestock Operations', x: 78, y: -7, category: 8, isActive: false },
  //#endregion
  
//#region States of risk
  { fullname:'Water Availability ', name: 'Water\nAvailability ', symbol: 'rect', x: 83, y: -25, category: 9, isActive: false },
  { fullname:'Water Quality ', name: 'Water\nQuality ', symbol: 'rect', x: 83, y: -20, category: 9, isActive: false },
  { fullname:'Abandoned Acres Rate', name: 'Abandoned\nAcres Rate', symbol: 'rect', x: 83, y: -15, category: 9, isActive: false },
  
  { fullname:'Price', name: 'Price', symbol: 'rect', x: 83, y: -10, category: 10, isActive: false },
  { fullname:'Profit', name: 'Profit', symbol: 'rect', x: 83, y: -5, category: 10, isActive: false },
  { fullname:'Market Disruption', name: 'Market\nDisruption', symbol: 'rect', x: 83, y: 0, category: 10, isActive: false },
  
  { fullname:'Wellbeing', name: 'Wellbeing', symbol: 'rect', x: 83, y: 5, category: 11, isActive: false },
  { fullname:'Public Health', name: 'Public\nHealth', symbol: 'rect', x: 83, y: 10, category: 11, isActive: false },
  //#endregion

  
 // #region Natural
  { fullname:'Planting Rotations', name: 'Planting\nRotations', x: -25, y: 5, category: 2, isActive: false },
  { fullname:'Planting Decision', name: 'Planting\nDecision', x: -25, y: 10, category: 2, isActive: false },

  { fullname:'Surface Air Temperature', name: 'Surface Air\nTemperature', x: -20, y: 5, category: 2, isActive: true },
  { fullname:'Resistence to Insecticides', name: 'Resistence\nto\nInsecticides', x: -20, y: 10, category: 2, isActive: false },
  { fullname:'Wind/Storms', name: 'Wind/\nStorms', x: -20, y: 15, category: 2, isActive: false },
  
  { fullname:'Pest Pressures', name: 'Pest\nPressures', x: -15, y: 5, category: 2, isActive: false },
  { fullname:'Feral Hogs', name: 'Feral\nHogs', x: -15, y: 10, category: 2, isActive: false },
  { fullname:'Water Quality', name: 'Water\nQuality', x: -15, y: 15, category: 2, isActive: false },
  { fullname:'Droughts', name: 'Droughts', x: -15, y: 20, category: 2, isActive: true },

  { fullname:'Soil Quality', name: 'Soil\nQuality', x: -10, y: 5, category: 2, isActive: false },  
  { fullname:'Soil Moisture', name: 'Soil\nMoisture', x: -10, y: 10, category: 2, isActive: false },
  { fullname:'Insect Pressures', name: 'Insect\nPressures', x: -10, y: 15, category: 2, isActive: false },
  { fullname:'Planting Delay', name: 'Planting\nDelay', x: -10, y: 20, category: 2, isActive: false },
  { fullname:'Pasture Lands', name: 'Pasture\nLands', x: -10, y: 25, category: 2, isActive: false },
  
  
  { fullname:'Microbes', name: 'Microbes', x: -5, y: 5, category: 2, isActive: false },
  { fullname:'Disease Pressures', name: 'Disease\nPressures', x: -5, y: 10, category: 2, isActive: false },
  { fullname:'Snowpack', name: 'Snowpack', x: -5, y: 15, category: 2, isActive: false },
  { fullname:'Water Availability', name: 'Water\nAvailability', x: -5, y: 20, category: 2, isActive: false },

  { fullname:'Precipitation', name: 'Precipitation', x: 0, y: 8, category: 2, isActive: true },
  { fullname:'Sunlight', name: 'Sunlight', x: 0, y: 18, category: 2, isActive: false },
  
  //#endregion
  
   // #region local logistiic
  { fullname:'Organic Commercial Storage', name: 'Organic\nCommercial\nStorage', x: 7, y: 11, category: 4, isActive: false },
  { fullname:'Drying Capacity', name: 'Drying\nCapacity', x: 7, y: 17, category: 4, isActive: false },
  
  { fullname:'Barge', name: 'Barge', x: 12, y: 13, category: 4, isActive: false },
  { fullname:'Rail', name: 'Rail', x: 12, y: 18, category: 4, isActive: false },
  
  { fullname:'Organic On Farm Storage', name: 'Organic\nOn Farm\nStorage', x: 17, y: 10, category: 4, isActive: false },
  { fullname:'Trucking', name: 'Trucking', x: 17, y: 15, category: 4, isActive: false },
  
  { fullname:'Vessels', name: 'Vessels', x: 23, y: 8, category: 4, isActive: false },
  { fullname:'Commercial Storage', name: 'Commercial\nStorage', x: 23, y: 13, category: 4, isActive: false },
  { fullname:'On Farm Storage', name: 'On Farm\nStorage', x: 23, y: 18, category: 4, isActive: false },
  //#endregion

  //#region logistic
  { fullname:'Trucking ', name: 'Trucking ', x: 30, y: 12, category: 6, isActive: false },
  { fullname:'Vessels ', name: 'Vessels ', x: 35, y: 12, category: 6, isActive: false },
  { fullname:'Barge ', name: 'Barge ', x: 40, y: 12, category: 6, isActive: false },
  { fullname:'Rail ', name: 'Rail ', x: 45, y: 12, category: 6, isActive: false },
  { fullname:'It Systems', name: 'It Systems', x: 50, y:12, category: 6, isActive: false },
  
  { fullname:'Local Transportation', name: 'Local\nTransportation', x: 55, y: 10, category: 6, isActive: false },
  { fullname:'International Vessels', name: 'International\nVessels', x: 55, y: 18, category: 6, isActive: false },
  
  { fullname:'Domestic Trucking', name: 'Domestic\nTrucking', x: 60, y: 5, category: 6, isActive: false },
  { fullname:'Retail Trucking', name: 'Retail\nTrucking', x: 60, y: 10, category: 6, isActive: false },
  { fullname:'International Barge', name: 'International\nBarge', x: 60, y: 15, category: 6, isActive: false },
  { fullname:'International Trucking', name: 'International\nTrucking', x: 60, y: 20, category: 6, isActive: false },

  { fullname:'International Rail', name: 'International\nRail', x: 65, y: 13, category: 6, isActive: false },
  { fullname:'Domestic Rail', name: 'Domestic\nRail', x: 65, y: 18, category: 6, isActive: false },
  //#endregion

  //#region Critical
  { fullname:'Transit\nInfrastructure', name: 'Transit\nInfrastructure', x: -12, y: 30, category: 12, isActive: false },
  { fullname:'Levees Infrastructure', name: 'Levees\nInfrastructure', x: -12, y: 35, category: 12, isActive: false },
  { fullname:'Broadband', name: 'Broadband', x: -12, y: 40, category: 12, isActive: false },
  
  { fullname:'Hazardous Waste ', name: 'Hazardous\nWaste ', x: -4, y: 30, category: 12, isActive: false },
  { fullname:'Solidwaste Infrastructure', name: 'Solidwaste\nInfrastructure', x: -4, y: 35, category: 12, isActive: false },
  
  { fullname:'Drinking Water Infrastructure', name: 'Drinking\nWater\nInfrastructure', x: 2, y: 33, category: 12, isActive: false },
  { fullname:'Stormwater Infrastructure', name: 'Stormwater\nInfrastructure', x: 2, y: 38, category: 12, isActive: false },
 
  { fullname:'Fuel', name: 'Fuel', x: 7, y: 30, category: 12, isActive: false },
  { fullname:'Wastewater Infrastructure', name: 'Wastewater\nInfrastructure', x: 7, y: 35, category: 12, isActive: false },
  
  { fullname:'Roads', name: 'Roads', x: 12, y: 28, category: 12, isActive: false },
  { fullname:'Electricity', name: 'Electricity', x: 12, y: 33, category: 12, isActive: false },
  { fullname:'Bridges', name: 'Bridges', x: 12, y: 38, category: 12, isActive: false },
  
  { fullname:'Ports', name: 'Ports', x: 17, y: 30, category: 12, isActive: false },
  { fullname:'Inland Waterways', name: 'Inland Waterways', x: 17, y: 35, category: 12, isActive: false },

  { fullname:'nternational Ports', name: 'International\nPorts', x: 22, y: 28, category: 12, isActive: false },
  { fullname:'Domestic Roads', name: 'Domestic\nRoads', x: 22, y: 33, category: 12, isActive: false },

  { fullname:'International Inland Waterways', name: 'International\nInland\nWaterways', x: 27, y: 30, category: 12, isActive: false },
  { fullname:'Domestic Bridges', name: 'Domestic\nBridges', x: 27, y: 35, category: 12, isActive: false },

  { fullname:'International Roads', name: 'International\nRoads', x: 33, y: 30, category: 12, isActive: false },
  { fullname:'International Bridges', name: 'International\nBridges', x: 33, y: 35, category: 12, isActive: false },
//#endregion
  
  ];
/*End Nodes*/

/* Links */
export const CorFarmingGraphLinks: GraphLinkModel[] = 
[
  {
    "source": "Age",
    "target": "Organic\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Age",
    "target": "Conventional\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Levees\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Stormwater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Wastewater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Transit\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Ports\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Inland\nWaterways\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Inland\nWaterways\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Ports\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Futures\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Credit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Certification",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Conventional\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Agriculture\npolicies",
    "target": "Ethanol",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "Domestic\nMerchandising\nConventional Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "Domestic\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional\nFeed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "Domestic\nConventional\nFood\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(DMP-WR)",
    "target": "Gasoline\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(S-WR)",
    "target": "International\nMerchandising\nConventional\nFeed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(S-WR)",
    "target": "International\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(S-WR)",
    "target": "International\nMerchandising\nOrganic Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(S-WR)",
    "target": "International\nMerchandising\nConventional Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barge\n(S-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bevarages\nEthanol",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bevarages\nEthanol",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bevarages\nEthanol",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bevarages\nEthanol",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bevarages\nEthanol",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n(DMP-WR)",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bridges\n(S-DMP)",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Transit\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Machinery\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n(DMP-WR)",
    "target": "Ports\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure\n(WR-C)",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Certification",
    "target": "Organic\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Certification",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Surface Air\nTemperature",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Wind\n/\nStorms",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Hurricanes",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Drought",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Futures\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Energy\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Water Policies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Insect\nPressures",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Commercial\nStorage\n(S-DMP)",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nCrop Yield",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFarmers",
    "target": "Custom\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFarmers",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFarmers",
    "target": "Conventional\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFarmers",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFeed Supply",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFeed Supply",
    "target": "Domestic\nConventional\nFeed Manufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFeed Supply",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFeed Supply",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFeed Supply",
    "target": "Commercial\nStorage\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "On Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nField\nCorn",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFood\nUses",
    "target": "Other Corn-based\nFood Ingredients",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFood\nUses",
    "target": "Production of\nSweeteners",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFood\nUses",
    "target": "Domestic\nConventional Food\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nFood\nUses",
    "target": "Commercial\nStorage\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSilage",
    "target": "On Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSilage",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSilage",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSilage",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSilage",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Conventional\nSweeteners",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Credit",
    "target": "Organic\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Credit",
    "target": "Machinery\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Credit",
    "target": "Conventional\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Custom\nOperations",
    "target": "Organic\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Custom\nOperations",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Custom\nOperations",
    "target": "Conventional\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Custom\nOperations",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Custom\nOperations",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Wastewater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Broadband\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Transit\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Ports\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Broadband\nInfrastructure\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Ports\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Broadband\nInfrastructure\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Domestic\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Disease\nPressures",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Disease\nPressures",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Distillers\nSpent\nGrains",
    "target": "Conventional\nFeed Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Distillers\nSpent\nGrains",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nBridges\n(WR-C)",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFeed Manufacturer",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFeed Manufacturer",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFeed Manufacturer",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nDemand",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nDemand",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nDemand",
    "target": "Obesity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nDemand",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nDemand",
    "target": "Corn\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional Food\nManufacturer",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional Food\nManufacturer",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional Food\nManufacturer",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional Food\nManufacturer",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nRetailers",
    "target": "Domestic\nOrganic\nFood\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nWholesalers",
    "target": "Retail\nTrucking\n(WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nConventional\nFood\nWholesalers",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nEthanol\nMarket",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nEthanol\nMarket",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Poultry\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Cattle\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Hog\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nLivestock\nOperations",
    "target": "Meat\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nMerchandising\nConventional Feed",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nMerchandising\nConventional Feed",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nMerchandising\nOrganic Feed",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nMerchandising\nOrganic Feed",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic Organic\nFeed Manufacturer",
    "target": "Organic\nFeed\nSupply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic Organic\nFeed Manufacturer",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic Organic\nFeed Manufacturer",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic Organic\nFeed Manufacturer",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nFood\nDemand",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nFood\nDemand",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nFood\nDemand",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nFood\nDemand",
    "target": "Corn\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic Food\nRetailers",
    "target": "Domestic\nOrganic\nFood\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic Food\nWholesalers",
    "target": "Retail\nTrucking\n(WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic Food\nWholesalers",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nOrganic\nLivestock\nOperations",
    "target": "Meat\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nRail\n(WR-C)",
    "target": "Domestic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nRail\n(WR-C)",
    "target": "International\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nRailroads\n(WR-C)",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nRoads\n(WR-C)",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nTrucking\n(WR-C)",
    "target": "Domestic\nConventional\nFood\nRetailers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nTrucking\n(WR-C)",
    "target": "Domestic\nOrganic Food\nRetailers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nTrucking\n(WR-C)",
    "target": "Domestic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Domestic\nTrucking\n(WR-C)",
    "target": "Domestic\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Labor\nShortage\n(RMS)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Labor\nShortage\n(Logistics)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Public\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Foreign\nCompetition",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Flooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Wastewater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Soil\nMoisture",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Inland\nWaterways\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Pasture\nLands",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Inland\nWaterways\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drying\nCapacity (S-DMP)",
    "target": "On Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drying\nCapacity (S-DMP)",
    "target": "Organic\nOn Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drying\nCapacity (S-DMP)",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drying\nCapacity (S-DMP)",
    "target": "Organic\nCommercial\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP)",
    "target": "Ethanol",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP)",
    "target": "Production of\nSweeteners",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP-WR)",
    "target": "Ports\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP-WR)",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP-WR)",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP-WR)",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(DMP-WR)",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Drying\nCapacity (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "On Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Organic\nOn Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-DMP)",
    "target": "Organic\nCommercial\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-WR)",
    "target": "Ports\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-WR)",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S-WR)",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(WR-C)",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Electricity\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Fuel\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Electricity\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Fuel\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Electricity\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Fuel\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Electricity\n(DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Ethanol",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Electricity\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Fuel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Energy\nPolicies",
    "target": "Fuel\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Bevarages\nEthanol",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Distillers'\nSpent\nGrains",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Domestic\nEthanol\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Gasoline\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ethanol",
    "target": "Commercial\nStorage\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Expected Market\nPrices",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Farming\nPractices",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Farming\nPractices",
    "target": "Soil\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Ports\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Ports\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Retail\nTrucking\n(WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Domestic\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Federal\nTransportation\nPolicies",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Feral\nHogs",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Feral\nHogs",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fertilizers",
    "target": "Soil\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fertilizers",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fertilizers",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Hazardous\nWaste",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Wastewater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Inland\nWaterways\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Inland\nWaterways\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Flooding",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCompetition",
    "target": "Market\nConcentration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Foreign\nCompetition",
    "target": "Expected Market\nPrices",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(DMP-WR)",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(DMP-WR)",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(DMP-WR)",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(DMP-WR)",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\nS",
    "target": "Custom\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(S-DMP)",
    "target": "Drying\nCapacity (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(S-DMP)",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(S-WR)",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(S-WR)",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "Retail\nTrucking\n(WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\n(WR-C)",
    "target": "Domestic\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Futures\nMarket",
    "target": "Expected Market\nPrices",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Gasoline\nMarket",
    "target": "Ethanol\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitics",
    "target": "International\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitics",
    "target": "Trade Policies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitics",
    "target": "Energy\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Levees\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Bridges\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity\n(DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Electricity\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Domestic\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Bridges\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "International\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "International\nBridges\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "International\nPorts\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hurricanes",
    "target": "Domestic\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Immigration\nPolicies",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Immigration\nPolicies",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Immigration\nPolicies",
    "target": "Labor\nShortage\n(RMS)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Immigration\nPolicies",
    "target": "Labor\nShortage\n(Logistics)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inland\nWaterways\n(DMP-WR)",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Inland\nWaterways\n(S-WR)",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Insect\nPressures",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Insect\nPressures",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Insect\nPressures",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nBridges\n(WR-C)",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nEthanol Market",
    "target": "Ethanol\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nFood Production",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nFood Production",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nFood Production",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nFood Production",
    "target": "Obesity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nFood Production",
    "target": "Corn\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Cattle\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nLivestock\nOperations",
    "target": "Meat\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nConventional\nFeed",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nConventional\nFeed",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nConventional\nFeed",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nConventional Food",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Feed",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Feed",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Feed",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Food",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Food",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nMerchandising\nOrganic Food",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nFood\nProduction",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nFood\nProduction",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nFood\nProduction",
    "target": "Corn\nPrice",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nLivestock\nOperations",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nLivestock\nOperations",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nLivestock\nOperations",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nLivestock\nOperations",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nOrganic\nLivestock\nOperations",
    "target": "Meat\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPolicies",
    "target": "Trade Policies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPolicies",
    "target": "Futures\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPolicies",
    "target": "Energy\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPolicies",
    "target": "Water Policies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPolicies",
    "target": "Machine Parts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nPorts\n(WR-C)",
    "target": "International\nVessels (WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRail\n(WR-C)",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRail\n(WR-C)",
    "target": "International\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRail\n(WR-C)",
    "target": "International\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRail\n(WR-C)",
    "target": "International\nFood Production",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRail\n(WR-C)",
    "target": "International\nOrganic\nFood\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRailroads\n(WR-C)",
    "target": "International\nRail\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nRoads\n(WR-C)",
    "target": "International\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nTrucking\n(WR-C)",
    "target": "International\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nTrucking\n(WR-C)",
    "target": "International\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nTrucking\n(WR-C)",
    "target": "International\nFood Production",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nTrucking\n(WR-C)",
    "target": "International\nOrganic\nFood\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nVessels (WR-C)",
    "target": "International\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nVessels (WR-C)",
    "target": "International\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nVessels (WR-C)",
    "target": "International\nFood Production",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nVessels (WR-C)",
    "target": "International\nOrganic\nFood\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nLogistics\n",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nLogistics\n",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nLogistics\n",
    "target": "Domestic\nTrucking\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nLogistics\n",
    "target": "Retail\nTrucking\n(WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nRMS",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Organic\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS",
    "target": "Conventional\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Land\nAvailability",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Land\nAvailability",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Levees\nInfrastructure",
    "target": "Flooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Levees\nInfrastructure",
    "target": "Water\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Levees\nInfrastructure",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Livestock\nDisease\nPressures",
    "target": "Expected Market\nPrices",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Local\nPolicies",
    "target": "Agriculture\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Local\nPolicies",
    "target": "Water Policies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Local\nPolicies",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machine Parts",
    "target": "Machinery\nRepairments",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machine Parts",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nAvailability",
    "target": "Custom\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nAvailability",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nAvailability",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nInfrastructure",
    "target": "Machinery\nRepairments",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nInfrastructure",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Machinery\nRepairments",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Market\nConcentration",
    "target": "Fertilizers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Market\nConcentration",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Market\nConcentration",
    "target": "Pesticides",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Market\nConcentration",
    "target": "Seed\nGenetic\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Microbes",
    "target": "Soil\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "OnFarmStorageSDMP",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "OnFarmStorageSDMP",
    "target": "Ethanol",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nCommercial\nStorage (S-DMP)",
    "target": "Organic\nFood\nUses",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nCrop Yield",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Custom\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Organic\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Conventional\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFarmers",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFeed\nSupply",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFeed\nSupply",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFeed\nSupply",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFeed\nSupply",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Organic\nOn Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Organic\nCommercial\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Domestic\nOrganic\nFood\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nField\nCorn",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Other Corn-based\nFood Ingredients",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Other Organic\nCorn-based\nFood Ingredients",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nFood\nUses",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nOn Farm\nStorage (S-DMP)",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nOn Farm\nStorage (S-DMP)",
    "target": "Organic\nFood\nUses",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nSilage",
    "target": "On Farm\nStorage (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nSilage",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nSilage",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nSilage",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organic\nSilage",
    "target": "Water\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organized\nCrime",
    "target": "Machine Parts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organized\nCrime",
    "target": "Feral\nHogs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nCorn\nbased\nFood\nIngredients",
    "target": "Domestic\nConventional Food\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nCorn\nbased\nFood\nIngredients",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nOrganic\nCorn\nbased\nFood\nIngredients",
    "target": "Domestic\nOrganic\nGrain\nFood\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nOrganic\nCorn\nbased\nFood\nIngredients",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Pasture\nLands",
    "target": "Conventional\nFeed Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Pasture\nLands",
    "target": "Organic\nFeed\nSupply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Pesticides",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Pesticides",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Soil\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Disease\nPressures",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDecision",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDelay",
    "target": "Insect\nPressures",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDelay",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Planting\nDelay",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n(DMP-WR)",
    "target": "Barge\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n(DMP-WR)",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Ports\n(S-WR)",
    "target": "Barge\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Drought",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Flooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Water\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Soil\nMoisture",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Production of\nSweeteners",
    "target": "Domestic\nConventional Food\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Organic\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nHealth",
    "target": "Conventional\nFarmers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional\nFeed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "Conventional\nSweeteners",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "Domestic\nConventional\nFood\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(DMP-WR)",
    "target": "Gasoline\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "International\nMerchandising\nConventional\nFeed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "International\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "International\nMerchandising\nOrganic Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "International\nMerchandising\nConventional Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rail\n(S-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Railroads\n(DMP-WR)",
    "target": "Rail\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Railroads\n(S-WR)",
    "target": "Rail\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Agriculture\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Livestock\nDisease\nPressures",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Seed\nGenetic\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Domestic\nConventional Food\nManufacturer",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Domestic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "Domestic\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "International\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "International\nOrganic\nLivestock\nOperations",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "International\nFood Production",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Regulators",
    "target": "International\nOrganic\nFood\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rental\nMarkets",
    "target": "Machinery\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Resistance\nto\nInsecticides",
    "target": "Insect\nPressures",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Retail\nTrucking\n(WR)",
    "target": "Domestic\nConventional\nFood\nRetailers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Retail\nTrucking\n(WR)",
    "target": "Domestic\nOrganic Food\nRetailers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n(DMP-WR)",
    "target": "Trucking (DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Roads\n(S-DMP)",
    "target": "Trucking (S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Seed\nGenetic\nAvailability",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Seed\nPrice",
    "target": "Organic\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Seed\nPrice",
    "target": "Conventional\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Seed\nPrice",
    "target": "Conventional\nSilage",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Seed\nPrice",
    "target": "Organic\nField\nCorn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Snowpack",
    "target": "Water\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nMoisture",
    "target": "Planting\nDelay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nMoisture",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nMoisture",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nMoisture",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nQuality",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nQuality",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soil\nQuality",
    "target": "Arable\nLand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solidwaste\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Stormwater\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sunlight",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sunlight",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Drought",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Hazardous\nWaste",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Snowpack",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Soil\nMoisture",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Roads\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Railroads\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Roads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Domestic\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Railroads\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "International\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "International\nRoads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Surface Air\nTemperature",
    "target": "Domestic\nRailroads\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Market\nConcentration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Agriculture\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Futures\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Energy\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Immigration\nPolicies",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "Domestic\nEthanol\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trade Policies",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Transit\nInfrastructure",
    "target": "Labor\nShortage\n(S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Transit\nInfrastructure",
    "target": "Labor\nShortage\n(RMS)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Transit\nInfrastructure",
    "target": "Labor\nShortage\n(Logistics)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Domestic\nMerchandising\nConventional Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Domestic\nMerchandising\nOrganic Feed Feed Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Vessel\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Domestic\nOrganic Food\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Domestic\nConventional\nFood\nWholesalers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (DMP-WR)",
    "target": "Gasoline\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Trucking (S-DMP)",
    "target": "Commercial\nStorage\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Urbanization",
    "target": "Land\nAvailability",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional\nFeed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Feed",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "International\nMerchandising\nOrganic Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "International\nMerchandising\nConventional Food",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "Conventional\nSweeteners",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "International\nEthanol Market",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Vessel\n(DMP-WR)",
    "target": "Gasoline\nMarket",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wastewater\nInfrastructure",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAvailability",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nIrrigation",
    "target": "Soil\nMoisture",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nIrrigation",
    "target": "Conventional\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nIrrigation",
    "target": "Organic\nCrop Yield",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water Policies",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water Policies",
    "target": "Planting\nDecision",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nQuality",
    "target": "Water\nIrrigation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Weed\nPressures",
    "target": "Farming\nPractices",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Electricity\n(DMP-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Solidwaste\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Levees\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Hazardous\nWaste",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Stormwater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Broadband\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Transit\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Electricity\n(S-DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Electricity\n(S-WR)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Electricity\n(DMP)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wind/Storms",
    "target": "Electricity\n(WR-C)",
    "lineStyle": {
      "curveness": 0.1
    }
  }
]

/*[
  { source: 'Age', target: 'Skilled\nLabor\nAvailability', lineStyle: { curveness: 0.1 } },
  //{ source: 'Aging\nInfrastructure', target: '', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Ports', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Roads', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Bridges', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Rail', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Ports', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Inland Waterways', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Domestic\nRoads', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Domestic\nBridges', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'Domestic\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'International\nInland\nWaterways', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'International\nRoads', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'International\nBridges', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'International\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'Aging\nInfrastructure', target: 'nternational\nPorts', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Futures\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Credit', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Skilled\nLabor\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Agriculture\nPolicies', target: 'Ethanol', lineStyle: { curveness: 0.1 } },
  { source: 'Barge', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'International\nMerchandising\nOrganic Food', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'International\nMerchandising Conventional Food', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'Gasoline\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'Domestic\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'International\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'Domestic\nMerchandising\nOrganic Feed Feed Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Barge ', target: 'Domestic\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Bevarages\nEthanol', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Bevarages\nEthanol', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Bridges', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nInfrastructure', target: 'Machine\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Certification', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Futures\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Pest\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Droughts', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Precipitation', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Surface Air\nTemperature', lineStyle: { curveness: 0.1 } },
  { source: 'Climate\nChange', target: 'Insect\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Commercial\nStorage', target: 'Conventional\nFood\nUses', lineStyle: { curveness: 0.1 } },
  { source: 'Commercial\nStorage', target: 'Conventional\nFeed Supply', lineStyle: { curveness: 0.1 } },
  { source: 'Commercial\nStorage', target: 'Ethanol ', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nCrop Yield', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFarmers', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFarmers', target: 'Custom\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFarmers', target: 'Conventional\nSilage', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFeed Supply', target: 'Domestic\nConventional\nFeed Manufacturer', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFeed Supply', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFeed Supply', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFeed Supply', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nField\nCorn', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nField\nCorn', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nField\nCorn', target: 'Rail', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nField\nCorn', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nField\nCorn', target: 'Barge', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFood\nUses', target: 'Domestic\nConventional Food\nManufacturer', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFood\nUses', target: 'Production of\nSweeteners', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nFood\nUses', target: 'Other Corn-Based\nFood Ingredients', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nSilage', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nSilage', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Conventional\nSweeteners', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Credit', target: 'Machinery\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Custom\nOperations', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Custom\nOperations', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Custom\nOperations', target: 'Conventional\nSilage', lineStyle: { curveness: 0.1 } },
  { source: 'Custom\nOperations', target: 'Organic\nSilage', lineStyle: { curveness: 0.1 } },
  { source: 'Cyberattacks', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },

  { source: 'Disease\nPressures', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Disease\nPressures', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nBridges', target: 'Domestic\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nConventional\nFeed Manufacturer', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nConventional Food\nManufacturer', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nConventional Food\nManufacturer', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nConventional Food\nManufacturers', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Conventional Food Wholesalers', target: 'Retail\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nEthanol\nMarket', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nEthanol\nMarket', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nConventional Feed', target: 'Domestic\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nConventional Feed', target: 'Domestic\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nOrganic Feed Feed Feed', target: 'Domestic\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nOrganic Feed Feed Feed', target: 'Domestic\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Organic\nFeed Manufacturer', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Organic Food Wholesalers', target: 'Retail\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Organic Grain Food Manufacturer', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Organic Grain Food Manufacturer', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic Organic Grain Food Manufacturer', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nRail', target: 'Domestic\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nRail', target: 'Domestic\nOrganic\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nRoads', target: 'Domestic\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nTrucking', target: 'Domestic\nConventional\nFood\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nTrucking', target: 'Domestic\nOrganic Food\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nTrucking', target: 'Domestic\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nTrucking', target: 'Domestic\nOrganic\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Drinking\nWater\nInfrastructure', target: 'Health', lineStyle: { curveness: 0.1 } },
  { source: 'Droughts', target: 'Pasture\nLands', lineStyle: { curveness: 0.1 } },
  { source: 'Droughts', target: 'Soil\nMoisture', lineStyle: { curveness: 0.1 } },
  { source: 'Droughts', target: 'Water\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Drying\nCapacity', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Drying\nCapacity', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Drying\nCapacity', target: 'Organic\nOn Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Drying\nCapacity', target: 'Organic Commerical Storage', lineStyle: { curveness: 0.1 } },
  { source: 'DSGs', target: 'Conventional\nFeed Supply', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Organic\nCommercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity', target: 'Organic On-farm Storage', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nPolicies', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nPolicies', target: 'Electricity', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nPolicies', target: 'Ethanol', lineStyle: { curveness: 0.1 } },
  { source: 'Energy\nPolicies', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol', target: 'Beverage Ethanol', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol', target: 'Domestic\nEthanol\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'Domestic\nEthanol\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'DSGs', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol', target: 'Gasoline\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Ethanol ', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Expected\nMarket Prices', target: 'Planting\nRotations', lineStyle: { curveness: 0.1 } },

  { source: 'Federal\nTransportation\nPolicies', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Federal\nTransportation\nPolicies', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Federal\nTransportation\nPolicies', target: 'Rail', lineStyle: { curveness: 0.1 } },
  { source: 'Federal\nTransportation\nPolicies', target: 'Barge', lineStyle: { curveness: 0.1 } },
  { source: 'Feral\nHogs', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Feral\nHogs', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Fertilizers', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Fertilizers', target: 'Soil\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Fertilizers', target: 'Water\nQuality ', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Drying\nCapacity', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Rail', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Barge', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Domestic\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'Retail\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'Fuel\nPrices', target: 'Fuel', lineStyle: { curveness: 0.1 } },
  { source: 'Fungicides', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Fungicides', target: 'Water\nQuality ', lineStyle: { curveness: 0.1 } },
  { source: 'Futures\nMarket', target: 'Expected\nMarket Prices', lineStyle: { curveness: 0.1 } },
  { source: 'Hazardous\nWaste', target: 'Water\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Health', target: 'Organic\nFarmers', lineStyle: { curveness: 0.1 } },
  { source: 'Health', target: 'Skilled\nLabor\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Health ', target: 'Conventional\nFarmers', lineStyle: { curveness: 0.1 } },
  { source: 'Immigation\nPolicies', target: 'Skilled\nLabor\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Immigation\nPolicies', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Immigation\nPolicies', target: 'Labor\nShortage', lineStyle: { curveness: 0.1 } },
  // { source: 'Inland Waterways (DMP-W/R)', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  // { source: 'Inland Waterways (S-DMP)', target: 'Barge', lineStyle: { curveness: 0.1 } },
  { source: 'Inland Waterway', target: 'Barge', lineStyle: { curveness: 0.1 } },
  { source: 'Insect\nPressures', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Insect\nPressures', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Insect\nPressures', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'International\nBridges', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nConventional Feed', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nConventional Feed', target: 'International\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nConventional Feed', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising Conventional Food', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nConventional Feed', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nConventional Feed', target: 'International\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'Domestic\nMerchandising\nConventional Feed', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nOrganic Food', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nOrganic Food', target: 'International\nRail', lineStyle: { curveness: 0.1 } },
  { source: 'International\nMerchandising\nOrganic Food', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nPolicies', target: 'Machine Parts', lineStyle: { curveness: 0.1 } },
  { source: 'International\nPolicies', target: 'Futures\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'International\nPolicies', target: 'Water Policies', lineStyle: { curveness: 0.1 } },
  { source: 'International\nPolicies', target: 'Energy\nPolicies', lineStyle: { curveness: 0.1 } },
  { source: 'International\nPolicies', target: 'Trade Policies', lineStyle: { curveness: 0.1 } },
  { source: 'nternational\nPorts', target: 'International\nVessels', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRail', target: 'International\nFood Production', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRail', target: 'International\nOrganic\nFood Production', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRail', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRail', target: 'International\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRail', target: 'International\nOrganic\nLivestock Operations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nRoads', target: 'International\nTrucking', lineStyle: { curveness: 0.1 } },
  { source: 'International\nTrucking', target: 'International\nFood Production', lineStyle: { curveness: 0.1 } },
  { source: 'International\nTrucking', target: 'International\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nTrucking', target: 'International\nOrganic\nLivestock Operations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nTrucking', target: 'International\nOrganic\nFood Production', lineStyle: { curveness: 0.1 } },
  { source: 'International\nVessels', target: 'International\nOrganic\nFood Production', lineStyle: { curveness: 0.1 } },
  { source: 'International\nVessels', target: 'International\nLivestock\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nVessels', target: 'International\nOrganic\nLivestock Operations', lineStyle: { curveness: 0.1 } },
  { source: 'International\nVessels', target: 'International\nFood Production', lineStyle: { curveness: 0.1 } },
  
  { source: 'Labor\nShortage', target: 'Drinking\nWater\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Labor\nShortage', target: 'Skilled\nLabor\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Lack of\nInfrastructure', target: 'Water\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Land\nAvailability', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Land\nAvailability', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Land\nOwnership', target: 'Land\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Levees\nInfrastructure', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Levees\nInfrastructure', target: 'Water\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Local\nPolicies', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Machine Parts', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Machine Parts', target: 'Machinery\nRepairments', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nAvailability', target: 'Custom\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nAvailability', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nAvailability', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nInfrastructure', target: 'Machinery\nRepairments', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nInfrastructure', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Machinery\nRepairments', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Market\nConcentration', target: 'Seed\nGenetic\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Market\nConcentration', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Market\nConcentration', target: 'Fertilizers', lineStyle: { curveness: 0.1 } },
  { source: 'Market\nConcentration', target: 'Fungicides', lineStyle: { curveness: 0.1 } },
  { source: 'Market\nConcentration', target: 'Pesticides', lineStyle: { curveness: 0.1 } },
  { source: 'Microbes', target: 'Soil\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'On Farm\nStorage', target: 'Ethanol ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nCrop Yield', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Custom\nOperations', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Organic\nSilage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Conventional\nSilage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFarmers', target: 'Certification', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFeed\nSupply', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFeed\nSupply', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFeed\nSupply', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFeed\nSupply', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nField\nCorn', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nField\nCorn', target: 'Organic\nOn Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nField\nCorn', target: 'Organic\nCommercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Domestic Organic\nGrain Food\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Other Corn-Based\nFood Ingredients', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nFood\nUses', target: 'Other Organic\nCorn-Based\nFood Ingredients', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nOn Farm\nStorage', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nSilage', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nSilage', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nSweeteners', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nSweeteners', target: 'Rail ', lineStyle: { curveness: 0.1 } },
  { source: 'Organic\nSweeteners', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Organized\nCrime', target: 'Machine Parts', lineStyle: { curveness: 0.1 } },
  { source: 'Other Corn-Based\nFood Ingredients', target: 'Domestic\nConventional Food\nManufacturer', lineStyle: { curveness: 0.1 } },
  { source: 'Other Organic\nCorn-Based\nFood Ingredients', target: 'Domestic Organic Grain Food Manufacturer', lineStyle: { curveness: 0.1 } },

  { source: 'Pasture\nLands', target: 'Organic\nFeed\nSupply', lineStyle: { curveness: 0.1 } },
  { source: 'Pasture\nLands', target: 'Conventional\nFeed Supply', lineStyle: { curveness: 0.1 } },
  { source: 'Pest\nPressures', target: 'Planting\nDelay', lineStyle: { curveness: 0.1 } },
  { source: 'Pest\nPressures', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Pest\nPressures', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Pesticides', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Pesticides', target: 'Water\nQuality ', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDecision ', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDecision ', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDecision ', target: 'Organic\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDecision ', target: 'Conventional\nField\nCorn', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDelay', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDelay', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nDelay', target: 'Insect\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nRotations', target: 'Disease\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nRotations', target: 'Soil\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nRotations', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Planting\nRotations', target: 'Pest\nPressures', lineStyle: { curveness: 0.1 } },
  //{ source: 'Ports', target: 'Barge ', lineStyle: { curveness: 0.1 } },
  { source: 'Ports', target: 'Barge', lineStyle: { curveness: 0.1 } },
  // { source: 'Ports', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Ports', target: 'Vessels', lineStyle: { curveness: 0.1 } },
  { source: 'Precipitation', target: 'Water\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Precipitation', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Precipitation', target: 'Soil\nMoisture', lineStyle: { curveness: 0.1 } },
  { source: 'Production of\nSweeteners', target: 'Domestic\nConventional Food\nManufacturer', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Rail', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'Organic\nSweeteners', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'Conventional\nSweeteners', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'Gasoline\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'International\nMerchandising\nOrganic Food', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'International\nMerchandising Conventional Food', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'Domestic\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Rail ', target: 'International\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Rental\nMarkets', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Resistence\nto\nInsecticides', target: 'Insect\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Resistence\nto\nInsecticides', target: 'Pest\nPressures', lineStyle: { curveness: 0.1 } },
  { source: 'Retail\nTrucking', target: 'Domestic\nConventional\nFood\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Retail\nTrucking', target: 'Domestic\nOrganic Food\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Roads', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  //{ source: 'Roads', target: 'Trucking ', lineStyle: { curveness: 0.1 } },
  { source: 'Seed\nGenetic\nAvailability', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor\nAvailability', target: 'Trucking', lineStyle: { curveness: 0.1 } },
  { source: 'Skilled\nLabor\nAvailability', target: 'Machinery\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Snowpack', target: 'Water\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nMoisture', target: 'Planting\nDelay', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nMoisture', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nMoisture', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nMoisture', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nQuality', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Soil\nQuality', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Solidwaste\nInfrastructure', target: 'Water\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Stormwater\nInfrastructure', target: 'Water\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Sunlight', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Sunlight', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Surface Air\nTemperature', target: 'Soil\nMoisture', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'Futures\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'Immigration Policies', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'Agricultural Policy', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'Domestic\nEthanol\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Trade Policies', target: 'Market\nConcentration', lineStyle: { curveness: 0.1 } },
  { source: 'Transit\nInfrastructure', target: 'Labor\nShortage', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'Vessels ', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'Organic\nSweeteners', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'Gasoline\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'On Farm\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking', target: 'Commercial\nStorage', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'Domestic\nMerchandising\nOrganic Feed Feed Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Trucking ', target: 'Domestic\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Urbanization', target: 'Land\nAvailability', lineStyle: { curveness: 0.1 } },
  { source: 'Urbanization', target: 'Land\nOwnership', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'Conventional\nSweeteners', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'International\nEthanol Market', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'International\nMerchandising\nOrganic Food', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'International\nMerchandising Conventional Food', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'Domestic\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'International\nMerchandising\nConventional Feed', lineStyle: { curveness: 0.1 } },
  { source: 'Vessels ', target: 'Gasoline\nMarket', lineStyle: { curveness: 0.1 } },
  { source: 'Wastewater\nInfrastructure', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nAvailability', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nIrrigation', target: 'Soil\nMoisture', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nIrrigation', target: 'Conventional\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nIrrigation', target: 'Organic\nCrop Yield', lineStyle: { curveness: 0.1 } },
  { source: 'Water Policies', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Water Policies', target: 'Planting\nDecision', lineStyle: { curveness: 0.1 } },
  { source: 'Water\nQuality', target: 'Water\nIrrigation', lineStyle: { curveness: 0.1 } },
  { source: 'Winds/Storms', target: 'Stormwater\nInfrastructure', lineStyle: { curveness: 0.1 } },

  
];
/*End Links*/
