<div style="display: flex; justify-content: flex-start;gap: 2rem;">
    <button class="btn btn-home" *ngIf="this.dataRes.hasStudyButton" (click)="openStudyDialog()" title="Current variable: {{this.dataRes.data.metadata.name}}">Select variable</button>
    
    <button class="btn btn-home" *ngIf="this.goToCounty" (click)="returnToState()">Return to States</button>
  </div>
  <br />
    <!-- METADATA-->
    <div class="card">
      <div class="card-body">
        <div class="row gap-1">
          <div class="location">{{this.dataRes.data.metadata.name}}</div>
          <p>
              {{this.dataRes.data.metadata.description}}
          </p>
        </div>
  
        <div class="subtitle2 pt-2">Metadata</div>
        <div class="row">
          <div class="col-md-4">
            <ul>
              <li class="list-group-item"><span class="subtitle">Risk Component Classification:</span> {{this.dataRes.data.metadata.classification == "" ? "N/A" : this.dataRes.data.metadata.classification}}</li>
              <li class="list-group-item"><span class="subtitle">Type of Evidence:</span> {{this.dataRes.data.metadata.evidenceType}}</li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul>
              <li class="list-group-item"><span class="subtitle">Spatial Resolution:</span> {{this.dataRes.data.metadata.spatialResolution}} </li>
              <li class="list-group-item"><span class="subtitle">Data Source:</span> {{this.dataRes.data.metadata.source}}</li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul>
              <li class="list-group-item"><span class="subtitle">Time Period:</span> {{this.dataRes.data.metadata.timePeriod}}</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  
    <!--TAB OPTIONS-->
    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button (click)="selectTab('metadata')" class="nav-link active" id="metadata-tab" data-bs-toggle="tab" data-bs-target="#metadata" type="button" role="tab" aria-controls="metadata" aria-selected="true">
          <span class="textMedium">Metadata & Visualization</span>
        </button>
      </li>
    </ul>
  
    <!--TABS CONTENTS-->
    <div class="tab-content" id="myTabContent">
  
      <div class="tab-pane fade show active" id="metadata" role="tabpanel" aria-labelledby="metadata-tab">
        <!--FILTRO INTERNOS-->
        <div class="row" *ngIf="this.dataRes.hasFilter">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card half-border">
              <div *ngIf="this.dataRes.hasFilter">
                  <h4 style="font-weight: 700;">Section of filters:</h4>
  
                  <div *ngIf="this.dataRes.filterType === this.filterType.oneFilter">
  
                    <div *ngIf="this.dataRes.filterInfoSecond; else elseBlockFirstFilter">
                      <app-two-single-filters [data]="this.dataRes.filterInfoSecond" (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                    </div>
                    <ng-template #elseBlockFirstFilter>
                        <app-two-single-filters [data]="this.dataRes.filterInfo" (firstValue)="emitFirstValue($event)" [defaultSelection]="idsFilters"></app-two-single-filters>
                    </ng-template>
  
                  </div>
  
                  <div *ngIf="this.dataRes.filterType === this.filterType.twoFilters">
                    <app-two-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                            (firstValue)="emitFirstValue($event)"
                            (secondValue)="emitSecondValue($event)"
                            [defaultSelection]="idsFilters">
                    </app-two-single-filters>
                  </div>
  
                  <div *ngIf="this.dataRes.filterType === this.filterType.threeFilters">
                      <app-three-single-filters [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                            (firstValue)="emitFirstValue($event)"
                                            (secondValue)="emitSecondValue($event)"
                                            (thirdValue)="emitThirdValue($event)"
                                            [defaultSelection]="idsFilters"></app-three-single-filters>
                  </div>
  
                  <div *ngIf="this.dataRes.filterType === this.filterType.fourFilters">
                      <app-four-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                            (firstValue)="emitFirstValue($event)"
                                            (secondValue)="emitSecondValue($event)"
                                            (thirdValue)="emitThirdValue($event)"
                                            (fourthValue)="emitFourthValue($event)"
                                            [defaultSelection]="idsFilters"></app-four-single-filter>
                  </div>
  
                  <div *ngIf="this.dataRes.filterType === this.filterType.fiveFilters">
                      <app-five-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                            (firstValue)="emitFirstValue($event)"
                                            (secondValue)="emitSecondValue($event)"
                                            (thirdValue)="emitThirdValue($event)"
                                            (fourthValue)="emitFourthValue($event)"
                                            (fifthValue)="emitFifthValue($event)"
                                            [defaultSelection]="idsFilters"></app-five-single-filter>
                  </div>
                  <div *ngIf="this.dataRes.filterType === this.filterType.sixFilters">
                    <app-six-single-filter [data]="this.dataRes.filterInfoSecond ? this.dataRes.filterInfoSecond : this.dataRes.filterInfo"
                                          (firstValue)="emitFirstValue($event)"
                                          (secondValue)="emitSecondValue($event)"
                                          (thirdValue)="emitThirdValue($event)"
                                          (fourthValue)="emitFourthValue($event)"
                                          (fifthValue)="emitFifthValue($event)"
                                          (sixthValue)="emitFifthValue($event)"
                                          [defaultSelection]="idsFilters"></app-six-single-filter>
                  </div>
                <!--YAxis-->
                <div *ngIf="this.dataRes.filterInfoSecond!=null && this.dataRes.filterInfoSecond.yAxis!=null">
                  <div *ngIf="this.dataRes.filterInfoSecond">
                    <app-y-axis-filter [data]="this.dataRes.filterInfoSecond" (SelectedItemValue)="emitSelectedItemValue($event)"
                                          (yAxisValue)="emitYAxisValue($event)" (yAxisValueName)="emitYAxisValueName($event)" 
                                          [defaultSelection]="idsFilters"></app-y-axis-filter>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--PRIMERA LINEA DE CONTENEDORES-->
        <div class="row" [ngClass]="{'p-2': dataRes.hasFilter}">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card" [ngClass]="{'half-border': !dataRes.hasFilter}">
              <div *ngIf="!this.goToCounty">
                <div *ngIf="this.dataStateMap != undefined && this.dataRes.isCountyLevel != true">
                  <app-static-states-map class="map" [mapInfo]="this.dataRes.mapInfo" [data]="this.dataStateMap"
                  [title]="this.dataRes.mapTitle" [labelPopup] ="this.dataRes.labelPopup!" [property]="this.propertyName"
                  (stateSelected)="getSelectedState($event)"></app-static-states-map>
                </div>
  
                <div *ngIf="this.dataMap != undefined && this.dataRes.isCountyLevel === true">
                  <app-static-counties-map class="map" [data]="this.dataMap" [mapInfo]="this.dataRes.mapInfo" [title]="this.dataRes.mapTitle" [labelPopup] ="this.dataRes.labelPopup!"></app-static-counties-map>
                </div>
              </div>
  
              <div *ngIf="this.goToCounty">
                <div *ngIf="this.dataMap != undefined" style="box-shadow: none;">
                  <app-static-counties-map class="map" [data]="this.dataMap" [mapInfo]="this.dataRes.mapInfo" [bounds]="this.countyBounds" (countySelected)="getSelectedCounty($event)" [title]="this.dataRes.mapTitle" [labelPopup] ="this.dataRes.labelPopup!"></app-static-counties-map>
                </div>
              </div>

              <div class="container" *ngIf="this.dataRes.scale">
                <div class="d-flex justify-content-end">
                    <span style="font-size: 12px"><b>Scale 1:100000</b></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  
    </div>
  
  
  
  
  
  
