import { Component, OnInit } from '@angular/core';
import { TransboundaryWaterServiceService } from '../../../../core/services/transboundaryWaterService/transboundary-water-service.service';
import { LandUseModelResponse } from '../../../../core/models/TransboundaryWater/LandUse/LandUse.model';
import { HomeStore } from '../../../../core/stores/home-store';
import { DefaultGraphModel } from '../../../../core/models/chartjs.model';
import { ChartjsServiceService } from '../../../../core/services/chartjsService/chartjs-service.service';
import {  NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-land-use',
  templateUrl: './land-use.component.html',
  styleUrls: ['./land-use.component.css']
})
export class LandUseComponent implements OnInit {

  public data!: LandUseModelResponse;
  public dataUSA!: DefaultGraphModel;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  constructor(private transboundaryService: TransboundaryWaterServiceService, 
    private homeStore: HomeStore, 
    private chartjsService: ChartjsServiceService,
    private spinner: NgxSpinnerService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {

    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUSA();
    } else {
      this.spinner.hide();
    }
    
  }
  getDataCN() {
    this.transboundaryService.getLandUses()
      .subscribe(
        {
          next: (res) => {
            this.data = res as LandUseModelResponse;
            this.spinner.hide();
          },
          error: (e) => {console.error(e);this.spinner.hide();}
        }
      );
  }
  getDataUSA() {
    this.transboundaryService.getLandUsesUS()
      .subscribe(
        {
          next: (res) => {
            this.dataUSA = this.chartjsService.getDataMapped(res as DefaultGraphModel, true, true);
            this.spinner.hide();
          },
          error: (e) => {console.error(e);this.spinner.hide();}
        }
      );
  }
}
