<ng-container *ngIf="!hidden">
  <div class="title">
    Results
  </div>

  <ng-container *ngIf="!articles?.length">
    <div class="results-list">
      <div class="empty-state">
        <div>
          <img src="./assets/images/icons/not-found-icon.svg" alt="">
        </div>
        <div class="empty-state-title">No articles found</div>
        <div class="empty-state-description">
          Please select a date range to explore related content
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="articles?.length">
    <div class="list-row">
      <div class="side-panel">
        <div class="w-100 grow" style="height: 400px;" EchartDirective [echartOptions]="chartDefinition"></div>
        <div style="text-align: center; margin-bottom: 3rem">
          <div style="font-size: 1.5rem; color: #00000073">EVENTS</div>
          <div style="font-size: 2rem; font-weight: 500;">{{totalCount}}</div>
        </div>
        <div style="text-align: center; color: #00000073">
          These events are calculated with deep learning
        </div>
      </div>
      <div class="list">
        <ng-container *ngFor="let article of articles; let last = last;">
          <app-article-item [article]="article" [last]="last" />
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
