import {Component, OnInit} from '@angular/core';
import { footerLinks } from './core/utils/footer-links';
import { environment } from 'src/environments/environment.quality';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  footerLinks = footerLinks;
  baseAssets = environment.BaseAssets;
  constructor() {}
}
