import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';

@Component({
  selector: 'app-four-single-filter',
  templateUrl: './four-single-filter.component.html',
  styleUrls: ['./four-single-filter.component.css'],
})
export class FourSingleFilterComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() data!: any;
  @Input() defaultSelection!: any;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  firstFilter = new FormControl<string>('');
  secondFilter = new FormControl<string>('');
  thirdFilter = new FormControl<string>('');
  fourthFilter = new FormControl<string>('');
  suma = 0;
  dataFilter:any;

  constructor(private dataService:DataMultipleVariableService) {
    this.dataService.dataFilter$.subscribe((data)=>{
this.dataFilter=data;
    })
    this.firstFilter.valueChanges.subscribe((x) => {
      let data_;
      if (x !== null) {
      const index = parseInt(x, 10);
      if (!isNaN(index)) {
        data_ = this.data.firstFilter.values.at(index);
      } else {
        data_ = this.data.firstFilter.values.find((item: { value: string; }) => item.value === x);
      }
    }
      if (data_ !== undefined) this.firstValue.emit(data_.value);
    });
    this.secondFilter.valueChanges.subscribe((x) => {
      let data_;
      if (x !== null) {
      const index = parseInt(x, 10);
      if (!isNaN(index)) {
        data_ = this.data.secondFilter.values.at(index);
      } else {
        data_ = this.data.secondFilter.values.find((item: { value: string; }) => item.value === x);
      }
    }
      if (data_ !== undefined) this.secondValue.emit(data_.value);
    });
    this.thirdFilter.valueChanges.subscribe((x) => {
      let data_;
      if (x !== null) {
      const index = parseInt(x, 10);

      if (!isNaN(index)) {
        data_ = this.data.thirdFilter.values.at(index);
      } else {
        data_ = this.data.thirdFilter.values.find((item: { value: string; }) => item.value === x);
      }
    }
      if (data_ !== undefined) this.thirdValue.emit(data_.value);
    });
    this.fourthFilter.valueChanges.subscribe((x) => {
      let data_;
      if (x !== null) {
      const index = parseInt(x, 10);
      if (!isNaN(index)) {
        data_ = this.data.fourthFilter.values.at(index);
      } else {
        data_ = this.data.fourthFilter.values.find((item: { value: string; }) => item.value === x);
      }
    }
      if (data_ !== undefined) this.fourthValue.emit(data_.value);
    });
    if (this.defaultSelection && this.defaultSelection.Filter_One !== null) {
      this.firstFilter.setValue(this.defaultSelection.Filter_One);
    }
    if (this.defaultSelection && this.defaultSelection.Filter_Two !== null) {
      this.secondFilter.setValue(this.defaultSelection.Filter_Two);
    }
    if (this.defaultSelection && this.defaultSelection.Filter_Three !== null) {
      this.thirdFilter.setValue(this.defaultSelection.Filter_Three);
    }
    if (this.defaultSelection && this.defaultSelection.Filter_Four !== null) {
      this.fourthFilter.setValue(this.defaultSelection.Filter_Four);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
     const firstFilterIndex = this.data.firstFilter.values.findIndex(
      (item: { value: string; }) => item.value === this.dataFilter.Filter_One
    );
    if (firstFilterIndex !== -1) {
      this.firstFilter.patchValue(this.data.firstFilter.values[firstFilterIndex].id);
    } else {
      this.firstFilter.patchValue(this.data.firstFilter.values[0].id);
    }
    const secondFilterIndex = this.data.secondFilter.values.findIndex(
      (item: { value: string; }) => item.value === this.dataFilter.Filter_Two
    );
    if (secondFilterIndex !== -1) {
      this.secondFilter.patchValue(this.data.secondFilter.values[secondFilterIndex].id);
    } else {
      this.secondFilter.patchValue(this.data.secondFilter.values[0].id);
    }
    const thirdFilterIndex = this.data.thirdFilter.values.findIndex(
      (item: { value: string; }) => item.value === this.dataFilter.Filter_Three
    );
    if (thirdFilterIndex !== -1) {
      this.thirdFilter.patchValue(this.data.thirdFilter.values[thirdFilterIndex].id);
    } else {
      this.thirdFilter.patchValue(this.data.thirdFilter.values[0].id);
    }
    const fourthFilterIndex = this.data.fourthFilter.values.findIndex(
      (item: { value: string; }) => item.value === this.dataFilter.Filter_Four
    );
    if (fourthFilterIndex !== -1) {
      this.fourthFilter.patchValue(this.data.fourthFilter.values[fourthFilterIndex].id);
    } else {
      this.fourthFilter.patchValue(this.data.fourthFilter.values[0].id);
    }
    }
  }
  

  ngOnInit() {
    
    this.suma = this.suma++;
    setTimeout(() => {
      if (this.defaultSelection && this.defaultSelection.Filter_One !== null) {
        this.firstFilter.setValue(this.defaultSelection.Filter_One);
      }
      if (this.defaultSelection && this.defaultSelection.Filter_Two !== null) {
        this.secondFilter.setValue(this.defaultSelection.Filter_Two);
      }
      if (this.defaultSelection && this.defaultSelection.Filter_Three !== null) {
        this.thirdFilter.setValue(this.defaultSelection.Filter_Three);
      }
      if (this.defaultSelection && this.defaultSelection.Filter_Four !== null) {
        this.fourthFilter.setValue(this.defaultSelection.Filter_Four);
      } 
    });
  }

  ngAfterViewInit() {
    
    // setTimeout(() => {
    //   console.log("after", this.defaultSelection);
    
    //   if (this.defaultSelection && this.defaultSelection.Filter_One !== null) {
    //     this.firstFilter.setValue(this.defaultSelection.Filter_One);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Two !== null) {
    //     this.secondFilter.setValue(this.defaultSelection.Filter_Two);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Three !== null) {
    //     this.thirdFilter.setValue(this.defaultSelection.Filter_Three);
    //   }
    //   if (this.defaultSelection && this.defaultSelection.Filter_Four !== null) {
    //     this.fourthFilter.setValue(this.defaultSelection.Filter_Four);
    //   }
    // });
  }
}
