
<div class="row">
    <div class="col-2">
        <label for="variableTypeFilter" class="form-label subtitle">{{this.typesVar.name}}</label>
        <select class="form-select"  (change)="onOptionChangeFirst($event)">
            <option *ngFor="let item of this.typesVar.values" [value]="item.value">
                {{item.value}}
            </option>
        </select>
    </div>
</div>
        


<div class="row">
    <div class="col-2">
        <label for="variablesOptionFilter" class="form-label subtitle">Variables Options</label>
        <select class="form-select" (change)="onOptionChangeSecond($event)">
            <option *ngFor="let item of this.optionsByType" [value]="item.value">
                {{item.value}}
            </option>
        </select>
    </div>
</div>

<div *ngFor="let data of chartData; let i = index">
    <div class="container">
        <div class="xy60">
            <app-line [data]="data.lineData" [barId]="'line-data-' + i" [timeUnit]="'year'" [customHeight]="'40rem'" [showDownloadButton]="false"></app-line>
    </div>
        <div class="xy40">
            <app-line-category [data]="data.ecdfData" [barId]="'line-category-data-' + i"
                style="--minWidth: 0px; --minHeight: 33rem;" [customHeight]="'40rem'" [showDownloadButton]="false"></app-line-category>
        </div>
    </div>
</div>






