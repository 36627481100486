import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartjsServiceService } from '../../../core/services/chartjsService/chartjs-service.service';
import { HomeStore } from '../../../core/stores/home-store';
import { TransboundaryWaterServiceService } from 'src/app/core/services/transboundaryWaterService/transboundary-water-service.service';
import { StaticTemplateService } from 'src/app/core/services/shared/static-template/static-template.service';
import { StaticTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { NaturalService } from 'src/app/core/services/natural/natural.service';

@Component({
  selector: 'app-hurricane',
  templateUrl: './hurricane.component.html',
  styleUrls: ['./hurricane.component.css']
})
export class HurricaneComponent {
  public data!: any;
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;
  constructor(private transboundaryWaterService: TransboundaryWaterServiceService,
    private naturalService: NaturalService, 
    private homeStore: HomeStore, private spinner: NgxSpinnerService, 
    private chartjsService: ChartjsServiceService,
    private staticTemplateService: StaticTemplateService) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    } else if (this.isUSA) {
      this.getDataUs();
    } else if (this.isMx) {
      this.getDataMx();
    }else{
      this.getDataTri();
    }

  }
  getDataCN() {
    this.spinner.hide();
  }
  //US Region
  getDataUs() {
    this.transboundaryWaterService.getHurricaneUS()
      .subscribe(
        {
          next: (res) => {
            const  dataMap = {
              center: MapCenter.USA,
              scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
              json: MapJson.USA,
              zoom: MapZoom.USA,
              borderColor: '#000000'
            };
            this.data = {
              data: res,
              boxplotTitle: 'Average of Hurricanes (%)',
              histogramTitle: 'Hurricanes Frecuency',
              mapTitle: 'Hurricanes by state',
              description: 'consectetur adipiscing elit. Aenean nec tortor nulla. Duis tellus lorem, tempor a lacinia vel, efficitur tristique ipsum. Sed et metus sit amet elit cursus pulvinar. Duis tristique, mauris nec tincidunt sodales, eros quam commodo magna, et molestie massa sem sit amet turpis. Aenean dictum non lectus vitae elementum. Vivamus et tortor libero. Vestibulum ante eros, dapibus ut urna non, tincidunt luctus eros. Morbi at lobortis massa, vel congue nisl. Mauris dictum et odio a efficitur. Nullam hendrerit magna ex, eget porta lorem scelerisque ut. Duis pulvinar felis vel orci egestas, sit amet viverra leo vestibulum. Ut et mauris dapibus, placerat enim sit amet, sollicitudin libero. Phasellus scelerisque sem metus, eget dignissim justo vehicula in.',
              title: 'Hurricanes',
              mapInfo: dataMap,
              labelPopup: 'Risk score'
            }

          },
          error: (e) => { console.error(e); this.spinner.hide(); }
        }
      );
  }

  //end us  region



  //MX Region
  getDataMx() {
    this.spinner.hide();
  }
  getDataTri(){
    this.naturalService.getHurricaneTrinationalDates()
    .subscribe(
      {
        next: (res) => {
          const  dataMap = {
            center: MapCenter.USA,
            zoom: MapZoom.TRINATIONAL,
          };
          this.data = {
            metadata: res.metadata,
            filterInfo: res.filterInfo,
            mapInfo: dataMap
          }
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
  }
}
