import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

/* Categories */
export const WoodGraphCategory: any[] = [
    { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
    { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
    { id: 2,name: 'Raw Material/Sources', itemStyle: { color: '#c17a2f' } },
    { id: 3,name: 'Supplier', itemStyle: { color: '#2d2b76' } },
    { id: 4,name: 'Transportation/Logistics', itemStyle: { color: '#500101' } },
    { id: 5,name: 'Manufacturer/Production', itemStyle: { color: '#707070' } },
    { id: 6,name: 'Wholesaler/Retailer', itemStyle: { color: '#5a6237' } },
    { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
    { id: 8,name: 'Social', itemStyle: { color: '#ad9d68' } },
    { id: 9,name: 'Economic', itemStyle: { color: '#ffc000' } },
    { id: 10,name: 'Environmental', itemStyle: { color: '#ad9d68' }, },
    { id: 11,name: '', itemStyle: { color: '#e376a7' }, },
    { id: 12,name: 'Logistic', itemStyle: { color: '#993365' } },
    { id: 13,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
];
export const WoodGraphCategoryLink: any[] = [
  { name: 'Threats', links: [WoodGraphCategory[0], WoodGraphCategory[1]]},
  { name: 'Systems', links: [WoodGraphCategory[2], WoodGraphCategory[3], 
        WoodGraphCategory[4], WoodGraphCategory[5],WoodGraphCategory[6],
        WoodGraphCategory[7], WoodGraphCategory[11]
    ]},
  { name: 'State of Risk', links: [WoodGraphCategory[8], WoodGraphCategory[9], 
      WoodGraphCategory[10]
    ]}
]
/*End categories */

/*NODES*/
export const WoodGraphItems: GraphItemModel[] = [
  //#region Athropogenic
  { fullname: 'Geopolitics/War', name: 'Geopolitics/War', x: -9.3, y: -13, category: 0, isActive: false },
  { fullname: 'International Trade Policies', name: 'International\nTrade\nPolicies', x: -13, y: -12.5, category: 0, isActive: false }, 
  { fullname: 'Housing Demand', name: 'Housing\nDemand', x: -6.2, y: -12.5, category: 0, isActive: true },
  { fullname: 'Value of Residential Construction', name: 'Value of\nResidential\nConstruction', x: -8.3, y: -9.3, category: 0, isActive: true },
  { fullname: 'Dependence on Foreign Suppliers', name: 'Dependence\non Foreign\nSuppliers', x: -13, y: -9.5, category: 0, isActive: false },
  { fullname: 'Wood Demand', name: 'Wood\nDemand', x: -4.2, y: -9, category: 0, isActive: false },
  { fullname: 'Environmental Regulations', name: 'Environmental\nRegulations', x: -15, y: -7, category: 0, isActive: false },
  { fullname: 'Illegal Logging', name: 'Illegal\nLogging', x: -12, y: -7, category: 0, isActive: false },
  { fullname: 'Technology Change', name: 'Technology\nChange', x: -9, y: -7, category: 0, isActive: false },
  { fullname: 'Raw Timber Demand', name: 'Raw\nTimber\nDemand', x: -6, y: -7, category: 0, isActive: false },  
  { fullname: 'Over Explotation of Resources', name: 'Over\nExplotation of\nResources', x: -15, y: -4.6, category: 0, isActive: false },
  { fullname: 'Deforestation', name: 'Deforestation', x: -12, y: -4.6, category: 0, isActive: false },
  { fullname: 'Funding', name: 'Funding', x: -9.5, y: -5, category: 0, isActive: false },
  { fullname: 'COVID-19 Pandemic', name: 'COVID-19\nPandemic', x: -7, y: -5, category: 0, isActive: false },
  { fullname: 'Labor Shortage (RM)', name: 'Labor\nShortage (RM)', x: -3.3, y: -7, category: 0, isActive: false },
  { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage (S)', x: -3.3, y: -5, category: 0, isActive: false },
  { fullname: 'Labor Shortage (M)', name: 'Labor\nShortage (M)', x: -4, y: -3, category: 0, isActive: false },
  { fullname: 'Labor Shortage (L)', name: 'Labor\nShortage (L)', x: -4, y: -1, category: 0, isActive: false },
  { fullname: 'Labor Shortage (W)', name: 'Labor\nShortage (W)', x: -4, y: 1, category: 0, isActive: false },
  { fullname: 'Public Health', name: 'Public\nHealth', x: -8.2, y: -3, category: 0, isActive: false },           
  { fullname: 'Power Outage', name: 'Power\nOutage', x: -13.9, y: -2, category: 0, isActive: false },
  { fullname: 'Transportation Disruption', name: 'Transportation\nDisruption', x: -10, y: -1, category: 0, isActive: false },

  //#endregion
  //#region natural
  { fullname: 'Earthquake', name: 'Earthquake', x: -15, y: 1.5, category: 1, isActive: true },
  { fullname: 'Landslides', name: 'Landslides', x: -12, y: 2.5, category: 1, isActive: false },
  { fullname: 'Drought', name: 'Drought', x: -14.4, y: 4, category: 1, isActive: true },
  { fullname: 'Extreme Weather (Cold)', name: 'Extreme\nWeather\n(Cold)', x: -12, y: 5.5, category: 1, isActive: false },
  { fullname: 'Flooding', name: 'Flooding', x: -9, y: 2.5, category: 1, isActive: true },
  { fullname: 'Soil Physical and Mechanical Properties', name: 'Soil\nPhysical and\nMechanical\nProperties', x: -6.4, y: 2.5, category: 1, isActive: false },
  { fullname: 'Hurricane', name: 'Hurricane', x: -9, y: 5.5, category: 1, isActive: true },
  { fullname: 'Forest fires', name: 'Forest\nFires', x: -4, y: 3.2, category: 1, isActive: false },
  { fullname: 'Invasive species', name: 'Invasive\nspecies', x: -5.6, y: 5.7, category: 1, isActive: false },
  { fullname:'Surface Air Temperature', name: 'Surface Air\nTemperature', symbol: 'circle', x: -3, y: 5.7, category: 1, isActive: true },
  //#endregion


  //#region Raw Material /Source
  { fullname: 'Forest Plantations', name: 'Forest\nPlantations', x: 0.5, y: -9, category: 2, isActive: false },
  { fullname: 'Other Raw Material', name: 'Other\nRaw\nMaterial', x: 3.4, y: -9, category: 2, isActive: false },
  { fullname: 'Forest Harvesting Rate', name: 'Forest\nHarvesting\nRate', x: 5.8, y: -9, category: 2, isActive: false },
  { fullname: 'Forest Characteristics', name: 'Forest\nCharacteristics', x: 3.4, y: -6.5, category: 2, isActive: false },
  { fullname: 'Natural Forest', name: 'Natural\nForest', x: 0.5, y: -4.5, category: 2, isActive: false },
  { fullname: 'Forest Harvesting Quality', name: 'Forest\nHarvesting\nQuality', x: 5.8, y: -4.5, category: 2, isActive: false }, 
  //#endregion

  //#region supplier
  { fullname: 'Raw Timber Supply', name: 'Raw\nTimber\nSupply', x: 8, y: -9, category: 3, isActive: false },
  { fullname: 'Raw Timber Shortage', name: 'Raw\nTimber\nShortage', x: 11, y: -9, category: 3, isActive: false },
  { fullname: 'Hazardous Byproducts', name: 'Hazardous\nByproducts', x: 8, y: -6, category: 3, isActive: false },
  { fullname: 'Byproducts', name: 'Byproducts', x: 11, y: -6, category: 3, isActive: false },
  //#endregion
  //#region transportation/logistic
  // { fullname: 'Transportation/Logistics Operator. S-M', name: 'Transportation/\nLogistics\nOperator. S-M', x: 0, y: 7.6, category: 4, isActive: false },
  { fullname: 'Aviation Infrastructure', name: 'Aviation\nInfrastructure', x: 2.6, y: 10, category: 4, isActive: false },
  { fullname: 'Inland Waterways Infrastructure', name: 'Inland\nWaterways\nInfrastructure', x: 6, y: 10, category: 4, isActive: false },
  { fullname: 'Roadways Infraestructure', name: 'Roadways\nInfrastructure', x: 9, y: 10, category: 4, isActive: false },
  { fullname: 'Railroads Infrastructure', name: 'Railroads\nInfrastructure', x: 12.5, y: 10, category: 4, isActive: false },
  { fullname: 'Maritime (Ports)', name: 'Maritime\n(Ports)\nInfrastructure', x:15.8, y: 10, category: 4, isActive: false },
  // { fullname: 'Transportation/Logistics Operator. M-C', name: 'Transportation/\nLogistics\nOperator. M-C', x: 15.8, y: 7, category: 4, isActive: false },
  { fullname: 'Levees', name: 'Levees', x: 2, y: 5.2, category: 4, isActive: false },
  { fullname: 'Wastewater Infrastructure', name: 'Wastewater\nInfrastructure', x: -8.6, y: 9.5, category: 4, isActive: false },
  { fullname: 'Drinking Water Infrastructure', name: 'Drinking\nWater\nInfrastructure', x: -6, y: 8.5, category: 4, isActive: true },
  { fullname: 'Public Transportation Infrastructure', name: 'Public\nTransportation\nInfrastructure', x: -3.4, y: 9.5, category: 4, isActive: false },
  { fullname: 'Broadband Infrastructure', name: 'Broadband\nInfrastructure', x:0, y: 4.4, category: 4, isActive: false },
  { fullname: 'Electricity', name: 'Electricity', x:0, y: 7.6, category: 4, isActive: false },
  { fullname: 'Fuel', name: 'Fuel', x:4, y: 7.6, category: 4, isActive: false },
  { fullname: 'Bridges Infrastructure', name: 'Bridges\nInfrastructure', x:7, y: 7.6, category: 4, isActive: false },
  { fullname: 'Industrial Wastewater Infrastructure', name: 'Industrial\nWastewater\nInfrastructure', x:10.5, y: 7.6, category: 4, isActive: false },
  //#endregion
  //#region Manufacturer/Production
  { fullname: 'Information Technology', name: 'Information\nTechnology', x: 19, y: -13, category: 5, isActive: false },
  { fullname: 'Manufacturing Equipment', name: 'Manufacturing\nEquipment', x: 14.5, y: -10, category: 5, isActive: true },
  { fullname: 'Warehouse', name: 'Warehouse', x: 17, y: -10, category: 5, isActive: false },
  { fullname: 'In-Plant Logistics', name: 'In-Plant\nLogistics', x: 21, y: -10, category: 5, isActive: false },
  { fullname: 'Labor', name: 'Labor', x: 14, y: -7, category: 5, isActive: true },
  { fullname: 'Physical Infrastructure', name: 'Physical\nInfrastructure', x: 19, y: -7, category: 5, isActive: true },
  { fullname: 'Stormwater Infrastructure', name: 'Stormwater\nInfrastructure', x: 23, y: -7, category: 5, isActive: false },
  { fullname: 'Mill Closures', name: 'Mill\nClosures', x: 12.6, y: -4, category: 5, isActive: false },
  { fullname: 'Plywood Manufacturing', name: 'Plywood\nManufacturing', x: 17, y: -4, category: 5, isActive: false },
  { fullname: 'Millwork Manufacturing', name: 'Millwork\nManufacturing', x: 21.6, y: -4, category: 5, isActive: false },
  { fullname: 'Veneering', name: 'Veneering', x: 25.5, y: -4, category: 5, isActive: false },
  { fullname: 'Wood Product Manufacturing', name: 'Wood\nProduct\nManufacturing', x: 19, y: -1, category: 5, isActive: true },
  { fullname: 'Wood Shortage', name: 'Wood\nShortage', x: 26.5, y: -1, category: 5, isActive: false },
  { fullname: 'Water', name: 'Water', x: 23, y: -2.5, category: 5, isActive: false },
  { fullname: 'Wood Byproducts', name: 'Wood\nByproducts', x: 23, y: 1, category: 5, isActive: false },           
  //#endregion
  //#region Wholesaler/Retalier
  { fullname: 'Wholesalers/Retailers', name: 'Wholesalers/\nRetailers', x: 30, y: 5, category: 6, isActive: false },           
  //#endregion
  //#region Customer
  { fullname: 'Container Manufacturing Industry', name: 'Container\nManufacturing\nIndustry', x: 30, y: -10, category: 7, isActive: false },           
  { fullname: 'Other Wood Products Industry', name: 'Other\nWood\nProducts\nIndustry', x: 30, y: -6.5, category: 7, isActive: true },           
  { fullname: 'Bioenergy Industry', name: 'Bioenergy\nIndustry', x: 30, y: -4, category: 7, isActive: true },           
  { fullname: 'Construction Industry', name: 'Construction\nIndustry', x: 30, y: -1.5, category: 7, isActive: false },           
  { fullname: 'Paper, Pulp, and Paperboard Industry', name: 'Paper,\nPulp, and\nPaperboard\nIndustry', x: 30, y: 2, category: 7, isActive: true },           
  //#endregion
  //#region State of risk
//Economic
{ fullname: 'Exports', name: 'Exports', symbol: 'rect',  x: 35, y: -7, category: 8, isActive: true },           
{ fullname: 'Lumber Prices', name: 'Lumber\nPrices', symbol: 'rect',  x: 38, y: -7, category: 8, isActive: true },           
{ fullname: 'Revenue', name: 'Revenue', symbol: 'rect',  x: 41, y: -7, category: 8, isActive: true },           
{ fullname: 'Housing Prices', name: 'Housing\nPrices', symbol: 'rect',  x: 44, y: -7, category: 8, isActive: true },           
   { fullname: 'Materials\nTheft', name: 'Materials\nTheft', symbol: 'rect',  x: 42, y: -10, category: 8, isActive: true },     
//Social
{ fullname: 'Supply Dependecy', name: 'Supply\nDependecy',symbol: 'rect',  x: 35, y: -2.5, category: 8, isActive: false },           
{ fullname: 'Unemployment', name: 'Unemployment',symbol: 'rect',  x: 40, y: -2.5, category: 8, isActive: true },           

//Environmental
{ fullname: 'Ground and Surface Water Contamination', name: 'Ground and Surface\nWater Contamination',symbol: 'rect',  x: 35, y: 2, category: 8, isActive: false },           
{ fullname: 'Chemical Pollution', name: 'Chemical\nPollution', symbol: 'rect', x: 40, y: 2, category: 8, isActive: false },

//news
{ fullname: 'Barges', name: 'Barges', x: 7, y: 0, category: 11, isActive: false },
{ fullname: 'Vessels', name: 'Vessels', x: 4, y: 0, category: 11, isActive: false },
{ fullname: 'Trucks', name: 'Trucks', x: 5.3, y: 2.5, category: 11, isActive: false },

//Invisible for size
{ fullname: 'invisible', name: 'invisible', symbol: 'circle', x: 80, y: 12.1, itemStyle: { color: '#fff', borderWidth: 0 }, label: { color: '#fff' }, isActive: false },

//#endregion

];
/*End Nodes*/



/* Links */
export const WoodGraphLinks: GraphLinkModel[] = [
    
{ source: 'Aviation\nInfrastructure' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Aviation\nInfrastructure' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Barges' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Bioenergy\nIndustry' , target: 'Supply\nDependecy',   lineStyle: { curveness: 0.1 } },
{ source: 'Bridges\nInfrastructure' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Broadband\nInfrastructure' , target: 'Information\nTechnology',   lineStyle: { curveness: 0.1 } },
{ source: 'Byproducts' , target: 'Ground and Surface\nWater Contamination',   lineStyle: { curveness: 0.1 } },
{ source: 'Construction\nIndustry' , target: 'Housing\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'Construction\nIndustry' , target: 'Supply\nDependecy',   lineStyle: { curveness: 0.1 } },
{ source: 'Construction\nIndustry' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'Container\nManufacturing\nIndustry' , target: 'Supply\nDependecy',   lineStyle: { curveness: 0.1 } },
{ source: 'Container\nManufacturing\nIndustry' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'COVID-19\nPandemic' , target: 'Public\nHealth',   lineStyle: { curveness: 0.1 } },
{ source: 'COVID-19\nPandemic' , target: 'International\nTrade\nPolicies',   lineStyle: { curveness: 0.1 } },
{ source: 'COVID-19\nPandemic' , target: 'Raw\nTimber\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'COVID-19\nPandemic' , target: 'Housing\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Deforestation' , target: 'Landslides',   lineStyle: { curveness: 0.1 } },
{ source: 'Deforestation' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Deforestation' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Dependence\non Foreign\nSuppliers' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Dependence\non Foreign\nSuppliers' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Dependence\non Foreign\nSuppliers' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Dependence\non Foreign\nSuppliers' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Drinking\nWater\nInfrastructure' , target: 'Public\nHealth',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Invasive\nspecies',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Forest\nfires',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Forest Characteristics',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Landslides',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Drought' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Flooding',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Landslides',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Labor\nShortage\n(Harvest)',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Warehouse',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Earthquake' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Electricity' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Environmental\nRegulations' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Environmental\nRegulations' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Extreme\nWeather\n(Cold)' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Levees',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Warehouse',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Flooding' , target: 'Stormwater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nCharacteristics' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nCharacteristics' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nCharacteristics' , target: 'Forest\nDegradation',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Deforestation',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Warehouse',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nfires' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nQuality' , target: 'Forest\nDegradation',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Other\nRaw\nMaterial',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nHarvesting\nRate' , target: 'Forest\nDegradation',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nPlantations' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Forest\nPlantations' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Fuel' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Wastewater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Drinking\nWater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Public\nTransportation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Water',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Stormwater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Inland\nWaterways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Maritime\n(Ports)\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Roadways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Bridges\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Wood\nByproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Hazardous\nByproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Byproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Funding' , target: 'Stormwater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Dependence\non Foreign\nSuppliers',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Geopolitics/War' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hazardous\nByproducts' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Hazardous\nByproducts' , target: 'Ground and Surface\nWater Contamination',   lineStyle: { curveness: 0.1 } },
{ source: 'Housing\nDemand' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Housing\nDemand' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Flooding',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Landslides',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Labor\nShortage (RL)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Warehouse',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Hurricane' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Illegal\nLogging' , target: 'Forest\nfires',   lineStyle: { curveness: 0.1 } },
{ source: 'Illegal\nLogging' , target: 'Deforestation',   lineStyle: { curveness: 0.1 } },
{ source: 'In-Plant\nLogistics' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Industrial\nWastewater\nInfrastructure' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Industrial\nWastewater\nInfrastructure' , target: 'Ground and Surface\nWater Contamination',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'In-Plant\nLogistics',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Manufacturing\nEquipment',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Warehouse',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Inland\nWaterways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Maritime\n(Ports)\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Roadways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Bridges\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Information\nTechnology' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Inland\nWaterways\nInfrastructure' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Inland\nWaterways\nInfrastructure' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'International\nTrade\nPolicies' , target: 'Dependence\non Foreign\nSuppliers',   lineStyle: { curveness: 0.1 } },
{ source: 'International\nTrade\nPolicies' , target: 'Transportation/\nLogistics\nOperator. S-M',   lineStyle: { curveness: 0.1 } },
{ source: 'Invasive\nspecies' , target: 'Forest\nFires',   lineStyle: { curveness: 0.1 } },
{ source: 'Invasive\nspecies' , target: 'Forest\nCharacteristics',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (L)' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (M)' , target: 'Labor',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (RM)' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (RM)' , target: 'Other\nRaw\nMaterial',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (S)' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Labor\nShortage (W)' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Power\nOutage',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Transportation\nDisruption',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Landslides' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Levees' , target: 'Forest\nPlantations',   lineStyle: { curveness: 0.1 } },
{ source: 'Levees' , target: 'Natural\nForest',   lineStyle: { curveness: 0.1 } },
{ source: 'Manufacturing\nEquipment' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Maritime\n(Ports)\nInfrastructure' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Maritime\n(Ports)\nInfrastructure' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Mill\nClosures' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Mill\nClosures' , target: 'Unemployment',   lineStyle: { curveness: 0.1 } },
{ source: 'Millwork\nManufacturing' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Natural\nForest' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Natural\nForest' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nRaw\nMaterial' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nRaw\nMaterial' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nRaw\nMaterial' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nRaw\nMaterial' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nWood\nProducts\nIndustry' , target: 'Supply\nDependecy',   lineStyle: { curveness: 0.1 } },
{ source: 'Other\nWood\nProducts\nIndustry' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'Over\nExploitation\nof\nResources' , target: 'Forest\nHarvesting\nQuality',   lineStyle: { curveness: 0.1 } },
{ source: 'Over\nExploitation\nof\nResources' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Paper,\nPulp, and\nPaperboard\nIndustry' , target: 'Supply\nDependecy',   lineStyle: { curveness: 0.1 } },
{ source: 'Paper,\nPulp, and\nPaperboard\nIndustry' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Stormwater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Wood\nByproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Hazardous\nByproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Physical\nInfrastructure' , target: 'Industrial\nWastewater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Plywood\nManufacturing' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Power\nOutage' , target: 'Electricity',   lineStyle: { curveness: 0.1 } },
{ source: 'Power\nOutage' , target: 'Fuel',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nHealth' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nHealth' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nHealth' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nHealth' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nHealth' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nTransportation\nInfrastructure' , target: 'Labor\nShortage (M)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nTransportation\nInfrastructure' , target: 'Labor\nShortage (RM)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nTransportation\nInfrastructure' , target: 'Labor\nShortage (L)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nTransportation\nInfrastructure' , target: 'Labor\nShortage (S)',   lineStyle: { curveness: 0.1 } },
{ source: 'Public\nTransportation\nInfrastructure' , target: 'Labor\nShortage (W)',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Railroads\nInfrastructure' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nDemand' , target: 'Dependence\non Foreign\nSuppliers',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nDemand' , target: 'Forest\nHarvesting\nRate',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nDemand' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nShortage' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nShortage' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nShortage' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nShortage' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nShortage' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nSupply' , target: 'Raw\nTimber\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nSupply' , target: 'Byproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Raw\nTimber\nSupply' , target: 'Hazardous\nByproducts',   lineStyle: { curveness: 0.1 } },
{ source: 'Roadways\nInfrastructure' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Soil\nPhysical and\nMechanical\nProperties' , target: 'Forest\nCharacteristics',   lineStyle: { curveness: 0.1 } },
{ source: 'Stormwater\nInfrastructure' , target: 'Ground and Surface\nWater Contamination',   lineStyle: { curveness: 0.1 } },
{ source: 'Surface Air\nTemperature' , target: 'Invasive\nspecies',   lineStyle: { curveness: 0.1 } },
{ source: 'Surface Air\nTemperature' , target: 'Forest\nFires',   lineStyle: { curveness: 0.1 } },
{ source: 'Technology\nChange' , target: 'Broadband\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Technology\nChange' , target: 'Information\nTechnology',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Public\nTransportation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Inland\nWaterways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Maritime\n(Ports)\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Roadways\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Bridges\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Transportation\nDisruption' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Trucks' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Value of\nResidential\nConstruction' , target: 'Housing\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Value of\nResidential\nConstruction' , target: 'Wood\nDemand',   lineStyle: { curveness: 0.1 } },
{ source: 'Veneering' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Raw\nTimber\nSupply',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Mill\nClosures',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Millwork\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Veneering',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Container\nManufacturing\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Paper,\nPulp, and\nPaperboard\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Other\nWood\nProducts\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Vessels' , target: 'Wholesalers/\nRetailers',   lineStyle: { curveness: 0.1 } },
{ source: 'Warehouse' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Wastewater\nInfrastructure' , target: 'Drinking\nWater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Wastewater\nInfrastructure' , target: 'Public\nHealth',   lineStyle: { curveness: 0.1 } },
{ source: 'Water' , target: 'Physical\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Water' , target: 'Plywood\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Wholesalers/\nRetailers' , target: 'Lumber\nPrices',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nByproducts' , target: 'Construction\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nByproducts' , target: 'Bioenergy\nIndustry',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nByproducts' , target: 'Ground and Surface\nWater Contamination',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nDemand' , target: 'Wood\nProduct\nManufacturing',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nDemand' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nProduct\nManufacturing' , target: 'Wood\nShortage',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nProduct\nManufacturing' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nProduct\nManufacturing' , target: 'Exports',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nProduct\nManufacturing' , target: 'Industrial\nWastewater\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Revenue',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Trucks',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Vessels',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Barges',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Aviation\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Railroads\nInfrastructure',   lineStyle: { curveness: 0.1 } },
{ source: 'Wood\nShortage' , target: 'Materials\nTheft',   lineStyle: { curveness: 0.1 } },

];
