import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.css'],
})
export class HistogramComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLCanvasElement>;
  @Input() data: any = null;
  @Input() id: string = '';
  @Input() title: string = '';
  private options: any = null;
  public chart: any;
  private scrollEffect: any;
  private helper: boolean = false;
  constructor() {
    Chart.register(...registerables);
  }
  ngAfterViewInit(): void {
    this.initOptions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initOptions();
  }
  ngOnInit(): void {}

  private initOptions(): void {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 20,
          bottom: 30,
        },
      },
      scales: {
        x: {
          type: 'linear',
          min: 0,
          max: 5,
          ticks: {
            // forces step size to be 50 units
            stepSize: 0.1,
          },
          offset: false,
          grid: {
            offset: false,
          },
        },
        y: {
          // beginAtZero: true
          title: {
            display: false,
            text: 'Visitors',
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 20,
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
    };
    this.createChart();
  }

  private createChart(): void {
    const scrollButtons ={
      id: 'scrollButtons',
      afterEvent(chart: any, args: any){
        const { ctx, canvas, data, 
          chartArea: { top, bottom, left, right, height}, 
          scales: {x} } = chart;

        canvas.addEventListener('mousemove', (event:any) =>{
            const xCoor = args.event.x;
            const yCoor = args.event.y;

            if(xCoor >= left - 18 && xCoor <= left + 18 
              && yCoor >= top + (height / 2) - 18 
              && yCoor <= top + (height / 2) + 18){
              canvas.style.cursor = 'pointer';
            }else if(xCoor >= right - 18 && xCoor <= right + 18 
              && yCoor >= top + (height / 2) - 18 
              && yCoor <= top + (height / 2) + 18){
                canvas.style.cursor = 'pointer';
            } else{
              canvas.style.cursor = 'default';
            }
        })
      },
      afterDatasetsDraw(chart:any,  args:any, plugOptions:any){
          const { ctx, data, 
            chartArea: { top, bottom, left, right, width, height}, 
            scales: {x} } = chart;
          ctx.save(); 
          const lastValue = parseInt(data.labels.at(-1));
          const angle = Math.PI / 180;
          const radius = 15;
          function buttons (x:any, y:any, r:any, aS:any, aE:any, text: string){
            ctx.beginPath();
            ctx.lineWidth = 3;
            ctx.strokeStyle = 'rgba(255,26,104,1)';
            ctx.fillStyle = 'white';
            ctx.arc(x, y, r, aS, aE, false);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();

            ctx.font = 'bold 20px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillStyle ='rgba(102, 102, 102, 1)';
            ctx.fillText(text, x, y);
            ctx.restore();
          };
          if(x.min > 0){
            buttons(left, top + (height / 2), radius, angle * 0, angle *360, '-');
          }
          if(x.max < lastValue){
            buttons(right, top + (height / 2), radius, angle * 0, angle *360, '+');
          }

          // ctx.beginPath();
          // ctx.fillStyle  = 'lightgrey';
          // ctx.rect(left, bottom +  30, width, 15); 
          // ctx.fill();
          // ctx.closePath();
      }
    };

    this.destroyChart();
    this.chart = new Chart(this.id, {
      type: 'bar',
      data: this.data,
      options: this.options,
      plugins: [scrollButtons]

    });
    if(!this.helper){
      this.chart.canvas.addEventListener('click', (e:any) => {
        this.scrollEffect(e);
      });
      this.helper = true;
    }
    
    this.scrollEffect = (click:any) => {
      const { ctx, canvas, data, 
        chartArea: { top, bottom, left, right, height}, 
        scales: {x} } = this.chart;

        const xCoor = click.offsetX;
        const yCoor = click.offsetY;
       
        const lastValue = parseInt(data.labels.at(-1));
       if(this.chart.options.scales.x.min > 0){
          if(xCoor >= left - 18 && xCoor <= left + 18 
            && yCoor >= top + (height / 2) - 18 
            && yCoor <= top + (height / 2) + 18){
              this.chart.options.scales.x.min = this.chart.options.scales.x.min - 10;
              this.chart.options.scales.x.max = this.chart.options.scales.x.max - 5;
            }else{
              this.chart.options.scales.x.min === 0;
            }
       }
       if(this.chart.options.scales.x.max < lastValue){
        if(xCoor >= right - 18 && xCoor <= right + 18 
          && yCoor >= top + (height / 2) - 18 
          && yCoor <= top + (height / 2) + 18){
            this.chart.options.scales.x.min = this.chart.options.scales.x.min + 5;
            this.chart.options.scales.x.max = this.chart.options.scales.x.max + 10;
          }else{
            this.chart.options.scales.x.max === lastValue;
          }
     }


       this.chart.update();
    }
  }
  private destroyChart() {
    if (this.chart != null || this.chart != undefined) {
      this.chart.destroy();
    }
  }
  ngOnDestroy(): void {
    this.destroyChart();
  }
}
