import { GraphLinkModel } from "../models/graph.model";

export const mvmLinksPowerUSA: GraphLinkModel[] = [
    {
      "source": "Aging\nInfrastructure\n(C)",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(CI)",
      "target": "Electricity",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(L)",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(L)",
      "target": "Transmission\nPipleines\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(L)",
      "target": "Transmission\nPipelines\n(R-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(S)",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Aging\nInfrastructure\n(S)",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Compressor\nStations\n(MP-R)",
      "target": "Transmission\nPipleines\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Compressor\nStations\n(MP-R)",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Compressor\nStations\n(S-MP)",
      "target": "Natural Gas\nProcessing\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Compressor\nStations\n(S-MP)",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Conventional\nReservoirs",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Compressor\nStations\n(S-MP)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Natural Gas\nProcessing\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Compressor\nStations\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Electricity",
      "target": "Natural\nGas\nPhysical\nHubs",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Energy\nDemand",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Energy\nDemand",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Energy\nDemand",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Gathering\nPipelines",
      "target": "Compressor\nStations\n(S-MP)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Gathering\nPipelines",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas\nPhysical\nHubs",
      "target": "Transmission\nPipelines\n(R-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Electricity\nExports\nto\nMexico",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Deaths",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Electricity\nPrice",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Hospitalizations",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Food\nSpoilage",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural\nGas Power\nPlants",
      "target": "Number\nof\nCustomers\nAffected",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural Gas\nProcessing\nPlants",
      "target": "Compressor\nStations\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Natural Gas\nProcessing\nPlants",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Onshore\nHorizontal\nWells",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Onshore\nHorizontal\nWells",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Onshore\nVertical\nWells",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Onshore\nVertical\nWells",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(C)",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(L)",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(L)",
      "target": "Compressor\nStations\n(S-MP)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(L)",
      "target": "Compressor\nStations\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(L)",
      "target": "Transmission\nPipleines\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(L)",
      "target": "Transmission\nPipelines\n(R-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(MP)",
      "target": "Natural Gas\nProcessing\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(S)",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Repair\nServices\n(S)",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Shale\nGas",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Electricity",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Winter\nWeather",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Energy\nDemand",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Compressor\nStations\n(S-MP)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Natural Gas\nProcessing\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Compressor\nStations\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Transmission\nPipleines\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Transmission\nPipelines\n(R-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Surface\nAir\nTemperature",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipelines\n(R-C)",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipelines\n(R-C)",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipleines\n(MP-R)",
      "target": "Underground\nStorage\nFacilities",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipleines\n(MP-R)",
      "target": "Natural\nGas\nPhysical\nHubs",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipleines\n(MP-R)",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Transmission\nPipleines\n(MP-R)",
      "target": "Natural\nGas\nExports\nto\nMexico",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Underground\nStorage\nFacilities",
      "target": "Natural\nGas\nPhysical\nHubs",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Underground\nStorage\nFacilities",
      "target": "Greenhouse\nGas\nEmissions",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Electricity",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Onshore\nVertical\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Onshore\nHorizontal\nWells",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Gathering\nPipelines",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Compressor\nStations\n(S-MP)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Natural Gas\nProcessing\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Compressor\nStations\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Transmission\nPipleines\n(MP-R)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Transmission\nPipelines\n(R-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Winter\nWeather",
      "target": "Natural\nGas Power\nPlants",
      "lineStyle": {
        "curveness": 0.1
      }
    }
  ]
  