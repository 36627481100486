import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class DataMultipleVariableService {
  private dataSubject = new BehaviorSubject<any>(null);
  private dataFilterSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();
  public dataFilter$=this.dataFilterSubject.asObservable();
  private reloadViewSubject = new Subject<void>();
  private validationArraySubject = new Subject<any>();


  reloadView$ = this.reloadViewSubject.asObservable();
  validationArray$ = this.validationArraySubject.asObservable();

  setData(data: any) {
    this.dataSubject.next(data);
  }

  setFilter(data:any){
    this.dataFilterSubject.next(data);
  }

  emitReloadView(): void{
    this.reloadViewSubject.next();
  }

  emitValidationArray(data: any): void {
    this.validationArraySubject.next(data);
  }

}
