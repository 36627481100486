import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../../services/apiService/api-service.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'app-articles-filters',
  templateUrl: './articles-filters.component.html',
  styleUrls: ['./articles-filters.component.css'],
})
export class ArticlesFiltersComponent implements OnInit, OnDestroy {
  public step = 0;
  public endDate = new Date();
  public startDate = new Date(new Date().setDate(this.endDate.getDate() - 7));
  public minDate = this.startDate.toISOString().slice(0, 10);
  public maxDate = this.endDate.toISOString().slice(0, 10);
  public content: any = '';
  public topic: any = '';
  private _destroyed = new Subject();
  public contents: any[] = [];
  public topics: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiServiceService,
    private homeStore: HomeStore
  ) {}

  ngOnInit() {
    this.resetDates();
  
    this.route.queryParams
      .pipe(takeUntil(this._destroyed))
      .subscribe(async (params) => {
        var net = await this.homeStore.getSelectedSecondMenuOption();
        this.step = 0;
        const hasStartAndEndParams = params['start'] && params['end'];
  
        if (hasStartAndEndParams) {
          this.startDate = this.parseDate({ value: params['start'] });
          this.endDate = this.parseDate({ value: params['end'] });
        } else {
          this.resetDates();
        }
  
        if (!Object.keys(params).length) return;
  
        if (hasStartAndEndParams) {
          this.step = 1;
          this.contents = await firstValueFrom(
            this.api.get(
              `event/contents?startDate=${params['start']}&endDate=${params['end']}&net=${net}`
            )
          );
        }
  
        if (params['content']) {
          this.content = this.contents.find((c) => c.contentId == params['content']);
          this.step = 2;
          this.topics = await firstValueFrom(
            this.api.get(
              `event/topics?startDate=${params['start']}&endDate=${params['end']}&contentId=${params['content']}&net=${net}`
            )
          );
          if (params['topic']) {
            this.step = 3;
            this.topic = this.topics.find((t) => t.topicId == params['topic']);
          }
        }
  
        this.handleSearch(params);
      });
  
    this.api.get('event/min-max-dates').subscribe(({ minDate, maxDate }) => {
      this.minDate = minDate;
      this.maxDate = maxDate;
      this.endDate = new Date(maxDate + 'T00:00:00.000Z');
      this.startDate = new Date(minDate + 'T00:00:00.000Z');
    });
  }
  

  resetDates() {
    this.endDate = new Date(this.maxDate + 'T00:00:00.000Z');
    this.startDate = new Date(this.minDate + 'T00:00:00.000Z');
  }
  ngOnDestroy() {
    this._destroyed.next(undefined);
  }

  public parseDate(target: any): Date {
    if (target.value) {
      return new Date(target.value);
    }
    return new Date();
  }

  selectDate() {
    this.handleSearch({
      start: this.startDate.toISOString().slice(0, 10),
      end: this.endDate.toISOString().slice(0, 10),
    });
    this.step = 1;
  }

  deleteDate() {
    this.handleSearch({
      start: undefined,
      end: undefined,
      content: undefined,
      topic: undefined,
      id: undefined,
    });
    this.step = 0;
  }

  selectContent(content: any) {
    this.handleSearch({
      start: this.startDate.toISOString().slice(0, 10),
      end: this.endDate.toISOString().slice(0, 10),
      content: content?.contentId,
    });
    this.content = content;
    this.step = 2;
  }

  deleteContent() {
    this.handleSearch({
      start: this.startDate.toISOString().slice(0, 10),
      end: this.endDate.toISOString().slice(0, 10),
      content: undefined,
      topic: undefined,
      id: undefined,
    });
    this.content = undefined;
    this.contents = [];
    this.step = 1;
  }

  selectTopic(topic: any) {
    this.handleSearch({
      start: this.startDate.toISOString().slice(0, 10),
      end: this.endDate.toISOString().slice(0, 10),
      content: this.content?.contentId,
      topic: topic?.topicId,
      id: undefined,
    });
    this.topic = topic;
    this.step = 3;
  }

  deleteTopic() {
    this.handleSearch({
      start: this.startDate.toISOString().slice(0, 10),
      end: this.endDate.toISOString().slice(0, 10),
      content: this.content?.contentId,
      topic: undefined,
      id: undefined,
    });
    this.topic = undefined;
    this.topics = [];
    this.step = 2;
  }

  // Min-Max Event Dates
  // https://localhost:44368/api/event/min-max-dates
  // Contents By Date Range
  // https://localhost:44368/api/event/contents?startDate=2023-01-01&endDate=2023-01-29
  // Topics By Date Range and Content ID
  // https://localhost:44368/api/event/topics?startDate=2023-01-01&endDate=2023-12-29&contentId=84b3e78f-7730-51c8-8ea9-5bd3e1c7c475
  // Events By Date Range, Content ID, Topic ID with pagination (limit, offset)
  // https://localhost:44368/api/event/events?startDate=2023-01-01&endDate=2023-12-29&contentId=84b3e78f-7730-51c8-8ea9-5bd3e1c7c475&topicId=066a1b3d-2546-5245-a81d-ccc63a391bf7&limit=10&offset=0
  // Event Details By Event ID
  // https://localhost:44368/api/event/event-details/00920fcc-3616-5c8b-bdd0-ab395dcd0153

  handleSearch(queryParams: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
