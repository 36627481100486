import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { SawmillsProductionRoutingModule } from './sawmills-production.routing.module';
import { SharedModule } from '../../shared/shared.module';
import { HousingPriceComponent } from './pages/housing-price/housing-price.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeComponent,
    HousingPriceComponent
  ],
  imports: [
    CommonModule,
    SawmillsProductionRoutingModule,
    SharedModule,
    MatExpansionModule,
    MatIconModule
  ]
})
export class SawmillsProductionModule { }
