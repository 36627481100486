import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  PopulationGrowthModelResponse,
  PopulationUsCountyModel,
  PopulationUsStateModel,
} from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DefaultBoxPlotGraphModel } from 'src/app/core/models/chartjs.model';
import {
  FilterType,
  StaticTemplateModel,
} from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { MatDialog } from '@angular/material/dialog';
import { DynamicTemplateService } from 'src/app/core/services/shared/dynamic-template/dynamic-template.service';

@Component({
  selector: 'app-static-template',
  templateUrl: './static-template.component.html',
  styleUrls: ['./static-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class StaticTemplateComponent implements OnInit {
  @Input() dataRes!: StaticTemplateModel;
  @Input() idsFilters!: any;
  @Input() variable!: number;
  @Output() selectedItem = new EventEmitter<number>();
  @Input() event!: string;
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();
  @Output() thirdValue = new EventEmitter<string>();
  @Output() fourthValue = new EventEmitter<string>();
  @Output() fifthValue = new EventEmitter<string>();
  @Output() sixthValue = new EventEmitter<string>();
  @Output() yAxisValue = new EventEmitter<string>();
  @Output() yAxisValueName = new EventEmitter<string>();

  public firstValueSelected:string|any = null;
  public secondValueSelected:string|any = null;
  public thridValueSelected:string|any = null;
  public fourthValueSelected:string|any = null;
  public fifthValueSelected:string|any = null;
  public sixthValueSelected:string|any = null;
  public yAxisValueSelected:string|any = null;

  public filterType = FilterType;

  public data!: PopulationGrowthModelResponse;
  public dataMap!: PopulationUsCountyModel;
  public dataMapCopy!: PopulationUsCountyModel;
  public dataStateMap!: PopulationUsStateModel | undefined;
  public boxPlot!: DefaultBoxPlotGraphModel;
  public histogram!: any;
  public ecdfChart!: any;
  public formatInfo = {
    region: 'en-US',
    format: { year: 'numeric' },
    addDays: 1,
  };
  private selectedState!: any;
  private selectedCounty!: any;
  public countyBounds!: any;
  public minYear!: any;
  public maxYear!: any;
  public valueYear!: any;
  public goToCounty: boolean = false;
  public propertyName: string = 'values';

  selectedTab: string = 'metadata';

  constructor(
    private dynamicTemplateService: DynamicTemplateService,
    private chartjsService: ChartjsServiceService,
    private spinner: NgxSpinnerService,
    @Optional() private dialog: MatDialog
  ) {}

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.dataRes.data.hasCountyLevel === true ||
      this.dataRes.data.hasCountyLevel === undefined
    ) {
      this.dataMap = this.dataRes.data as PopulationUsCountyModel;
      this.dataMapCopy = JSON.parse(
        JSON.stringify(this.dataMap)
      ) as PopulationUsCountyModel;
      this.dataStateMap = this.dataRes.data as PopulationUsStateModel;

      this.minYear = this.dataMapCopy.dates.at(0);
      this.valueYear = this.dataMapCopy.dates.at(0);
      this.maxYear = this.dataMapCopy.dates.at(-1);

      this.histogram = {
        data: this.dynamicTemplateService.getHistogram(
          this.dataMapCopy,
          this.valueYear,
          this.dataRes.histogram.title,
          this.propertyName
        ),
        title: this.dataRes.histogram.title,
        yLabel: this.dataRes.histogram.yLabel,
        xLabel: this.dataRes.histogram.xLabel,
      };

      this.ecdfChart = {
        title: this.dataRes.ECDF.title,
        data: this.chartjsService.getDataMapped(
          this.dynamicTemplateService.getECDFGeneral(
            this.dataMapCopy,
            this.valueYear,
            this.formatInfo,
            this.propertyName
          ),
          true,
          false,
          true
        ),
        yLabel: this.dataRes.ECDF.yLabel,
        xLabel: this.dataRes.ECDF.xLabel,
      };
    } else {
      this.dataStateMap = this.dataRes.data as PopulationUsStateModel;

      this.minYear = this.dataStateMap.dates.at(0);
      this.valueYear = this.dataStateMap.dates.at(0);
      this.maxYear = this.dataStateMap.dates.at(-1);

      this.histogram = {
        data: this.dynamicTemplateService.getHistogramByState(
          this.dataStateMap,
          this.valueYear,
          this.dataRes.histogram.title,
          this.propertyName
        ),
        title:
          this.dataRes.histogram.title,
        yLabel: this.dataRes.histogram.yLabel,
        xLabel: this.dataRes.histogram.xLabel,
      };

      this.ecdfChart = {
        title: this.dataRes.ECDF.title,
        data: this.chartjsService.getDataMapped(
          this.dynamicTemplateService.getECDFByState(
            this.dataStateMap,
            this.valueYear,
            this.formatInfo,
            this.propertyName
          ),
          true,
          false,
          true
        ),
        yLabel: this.dataRes.ECDF.yLabel,
        xLabel: this.dataRes.ECDF.xLabel,
      };
    }

    this.spinner.hide();
  }
  ngOnInit(): void {
    this.initAllValues();
  }
  emitFirstValue($event: any) {
    this.firstValueSelected=$event;
    this.firstValue.emit($event  + '1');
    this.spinner.show();
  }
  emitSecondValue($event: any) {
    this.secondValueSelected=$event;
    this.secondValue.emit($event + '2');
    this.spinner.show();
    //console.log($event);
    if ($event === 'Expand health care') {
      this.event = $event;
      this.propertyName = 'valuesSecond';
    } else {
      this.event = $event;
      this.propertyName = 'values';
    }
  }
  emitThirdValue($event: any) {
    this.thridValueSelected=$event;
    this.thirdValue.emit($event + '3');
  }
  emitFourthValue($event: any) {
    this.fourthValueSelected=$event;
    this.fourthValue.emit($event + '4');
  }
  emitFifthValue($event: any) {
    this.fifthValueSelected=$event;
    this.fifthValue.emit($event + '5');
  }
  emitSixthValue($event: any) {
    this.sixthValueSelected=$event;
    this.sixthValue.emit($event + '5');
  }
  emitYAxisValue($event: any) {
    this.yAxisValue.emit($event);
   this.spinner.show();
  }
  emitYAxisValueName($event: any){
    this.yAxisValueSelected = $event;
    this.yAxisValueName.emit($event);
  }
  emitSelectedItemValue($event: any) {
    //console.log("item: ", $event);
    //console.log("item: ", this.dataRes.numVariable);
    this.selectedItem.emit(this.dataRes.numVariable);
    this.reloadView();
  }
  reloadView() {
    this.spinner.show();

    this.minYear = this.dataStateMap!.dates.at(0);
    this.valueYear = this.dataStateMap!.dates.at(0);
    this.maxYear = this.dataStateMap!.dates.at(-1);
    this.dataStateMap = undefined;

    setTimeout(() => {
      this.initAllValues();
    }, 300);
  }
  initAllValues() {
    this.dataMap = this.dataRes.data as PopulationUsCountyModel;
    this.dataMapCopy = JSON.parse(
      JSON.stringify(this.dataMap)
    ) as PopulationUsCountyModel;
    this.dataStateMap = this.dataRes.data as PopulationUsStateModel;
    this.spinner.hide();
  }
  getSelectedState(state: any) {
    if (this.dataRes.data.hasCountyLevel === false || this.dataRes.isStateOnly)
      return;

    this.spinner.show();

    this.countyBounds = state.bounds;
    this.selectedState = { id: state.id, name: state.name };

    const dataCopy = JSON.parse(
      JSON.stringify(this.dataMapCopy)
    ) as PopulationUsCountyModel;
    const filteredData = dataCopy.data.filter(
      (county: any) => county.state === state.id
    );
    const max = Math.max(
      ...[].concat(
        ...filteredData.map((item: any) => {
          return item.values;
        })
      )
    );

    this.dataMap.data = filteredData;
    this.dataMap.maxCounty = max;

    const firstCounty = { id: filteredData[0].name };
    this.getSelectedCounty(firstCounty);

    this.goToCounty = true;
    this.spinner.hide();
  }

  getSelectedCounty(county: any) {
    this.spinner.show();
    this.selectedCounty = { id: county.id };
    setTimeout(() => {
      this.ecdfChart = {
        title: `${this.dataRes.ECDF.titleCounty} ${
          this.selectedCounty.name
        } county ${
          this.selectedCounty.id
        }~from ${this.dynamicTemplateService.getFormatFromDate(
          this.minYear.toString(),
          this.formatInfo
        )} to ${this.dynamicTemplateService.getFormatFromDate(
          this.maxYear.toString(),
          this.formatInfo
        )}`.split('~'),
        data: this.chartjsService.getDataMapped(
          this.dynamicTemplateService.getECDFByCounty(
            this.dataMapCopy,
            this.selectedCounty,
            this.dataRes.ECDF.title,
            this.selectedState,
            this.propertyName
          ),
          true,
          false,
          true
        ),
        yLabel: this.dataRes.ECDF.yLabel,
        xLabel: this.dataRes.ECDF.xLabel,
      };

      this.histogram = {
        data: this.dynamicTemplateService.getHistogramByCounty(
          this.dataMapCopy,
          this.selectedCounty,
          this.dataRes.histogram.title,
          this.selectedState
        ),
        title: `${this.dataRes.histogram.titleCounty} ${
          this.selectedCounty.name
        } county ${
          this.selectedCounty.id
        }\nfrom ${this.dynamicTemplateService.getFormatFromDate(
          this.minYear.toString(),
          this.formatInfo
        )} to ${this.dynamicTemplateService.getFormatFromDate(
          this.maxYear.toString(),
          this.formatInfo
        )}`,
        yLabel: this.dataRes.histogram.yLabel,
        xLabel: this.dataRes.histogram.xLabel,
      };
      this.goToCounty = true;
      this.spinner.hide();
    }, 300);
  }

  returnToState() {
    this.spinner.show();
    this.goToCounty = !this.goToCounty;
    this.spinner.hide();
  }
  openStudyDialog() {
    const filterInfo = this.dataRes.filterVariableInfo
      ? this.dataRes.filterVariableInfo
      : this.dataRes.filterInfo;

    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: { filterInfo: filterInfo, selectedInfo: this.dataRes.selectedInfo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }
}
