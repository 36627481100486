import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, delay, firstValueFrom } from 'rxjs';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { FilterType } from 'src/app/core/models/templates/static-template/static-template.model';
import { DataMultipleVariableService } from 'src/app/core/services/shared/data-multiple-variable/data-multiple-variable.service';
import { HomeStore } from 'src/app/core/stores/home-store';
import { DynamicContainerComponent } from '../dynamic-container/dynamic-container.component';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';

@Component({
  selector: 'app-general-node',
  templateUrl: './general-node.component.html',
  styleUrls: ['./general-node.component.css'],
})
export class GeneralNodeComponent implements OnDestroy {
  private subscriber: Subscription;
  public title = '';
  public data: any;
  public idVariable: any;
  public numVariable: number | undefined;
  public filterType: string = '';
  public filterInfo!: any;
  private filterInfoValues!: any;
  allFiltersUndefined!: any;
  public hasChangeVariableBtn = false;
  public dash = '';
  public currentId?: number;
  public eventOne = '';
  public eventTwo = '';
  public eventThree = '';
  public eventFour = '';
  public eventFive = '';
  public eventSix = '';
  public eventYAxis = '';
  public eventSelectYears: any = null;
  public typeVariable = '';
  public idsFilters: any = null;
  public filtro: string[] = [];
  public numvariable:any=null;
  public isMVM = false;
  public net = '';
  @ViewChild(DynamicContainerComponent) dynamicContainerRef!: DynamicContainerComponent;

  public estadocomponente:boolean =false;
  constructor(
    private generalservice: GeneralService,
    private dataService: DataMultipleVariableService,
    public homeStore: HomeStore,
    
    private spinner: NgxSpinnerService
  ) {
    this.subscriber = homeStore.appContextObservable.subscribe(async (ctx) => {
      this.dash = ctx.dash || '';
      this.spinner.show();

      let decodedDetail = decodeURIComponent(ctx.detail!);
      while (decodedDetail.includes('%')) {
        decodedDetail = decodeURIComponent(decodedDetail);
      }
      
      if(decodedDetail.includes('mvm')){
        this.isMVM = true;
        decodedDetail = decodedDetail.replace(/-mvm/g, '');
      }

      let datosmodel = {
        nameNode: decodedDetail,
        nameNet: ctx.section?.url,
        nameDash: this.dash,
        isForMVM: this.isMVM,
      };

      this.net =  ctx.section?.url;
      this.currentId = await firstValueFrom(
        this.generalservice.getDefaultTemplate(datosmodel)
      );

      if (this.currentId != 0) await this.getValue(this.currentId);
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
  

  searchIdFilter(valueFilter: any, filterInfoValues: { [x: string]: { id: any; value: any; }; }) {
    for (const key in filterInfoValues) {
      if (filterInfoValues[key] && filterInfoValues[key].value === valueFilter) {
        return filterInfoValues[key].id; // Devolvemos el id correspondiente
      }
    }
    return null; 
  }

  async fetchData(res: any) {
    let mdl;
    if (res.variablesSameNode) {
      this.filterInfo = {
        firstFilter: res.variablesSameNode,
      };
      // Buscar el objeto en el array que tiene el 'itemId' correspondiente al 'numeroDeseado'
      const objetoEncontrado = this.filterInfo.firstFilter.values.find(
        (item: { itemId: any }) => item.itemId === res.numVariable
      );
      this.dataService.setData(objetoEncontrado.id);
      this.hasChangeVariableBtn = true;
    }
    this.filterType = '';
    if (res.filter_Six) {
      this.filterType = FilterType.sixFilters;
    } else if (res.filter_Five) {
      this.filterType = FilterType.fiveFilters;
    } else if (res.filter_Four) {
      this.filterType = FilterType.fourFilters;
    } else if (res.filter_Three) {
      this.filterType = FilterType.threeFilters;
    } else if (res.filter_Two) {
      this.filterType = FilterType.twoFilters;
    } else if (res.filter_One) {
      this.filterType = FilterType.oneFilter;
    }
    this.eventOne = res.filter_One;
    this.eventTwo = res.filter_Two;
    this.eventThree = res.filter_Three;
    this.eventFour = res.filter_Four;
    this.eventFive = res.filter_Five;
    this.eventSix = res.filter_Six;
    this.eventYAxis = res.yAxisValue;
    this.filterInfoValues = {
      numVariable: res.numVariable,
      firstFilter: res.filter_One != null ? res.filter_OneData : null,
      secondFilter: res.filter_Two != null ? res.filter_TwoData : null,
      thirdFilter: res.filter_Three != null ? res.filter_ThreeData : null,
      fourthFilter: res.filter_Four != null ? res.filter_FourData : null,
      fifthFilter: res.filter_Five != null ? res.filter_FiveData : null,
      sixthFilter: res.filter_Six != null ? res.filter_SixData : null,
      yAxis: res.yAxis != null ? res.yAxis : null,
      yAxisName: res.yAxisName,
    };
     //this.allFiltersUndefined = Object.values(this.filterInfoValues).every(value => value === undefined || value === null);
     this.allFiltersUndefined = this.filterInfoValues.firstFilter === null && this.filterInfoValues.yAxis === null;

    mdl = {
      numVariable: res.numVariable,
      Filter_One: res.filter_One,
      Filter_Two: res.filter_Two,
      Filter_Three: res.filter_Three,
      Filter_Four: res.filter_Four,
      Filter_Five: res.filter_Five,
      Filter_Six: res.filter_Six,
      YAxisValue: res.yAxisValue,
    };
    
    this.filtro = [
      res.filter_One,
      res.filter_Two,
      res.filter_Three,
      res.filter_Four,
      res.filter_Five,
      res.filter_Six
    ].filter(value => value !== undefined);

    this.idsFilters =  {
      Filter_One: mdl.Filter_One != null && this.filterInfoValues?.firstFilter?.values ? this.searchIdFilter(mdl.Filter_One, this.filterInfoValues.firstFilter.values) : null,
      Filter_Two: mdl.Filter_Two != null && this.filterInfoValues?.secondFilter?.values ? this.searchIdFilter(mdl.Filter_Two, this.filterInfoValues.secondFilter.values) : null, 
      Filter_Three: mdl.Filter_Three != null && this.filterInfoValues?.thirdFilter?.values ? this.searchIdFilter(mdl.Filter_Three, this.filterInfoValues.thirdFilter.values) : null,
      Filter_Four: mdl.Filter_Four != null && this.filterInfoValues?.fourthFilter?.values ? this.searchIdFilter(mdl.Filter_Four, this.filterInfoValues.fourthFilter.values) : null,
      Filter_Five: mdl.Filter_Five != null && this.filterInfoValues?.fifthFilter?.values ? this.searchIdFilter(mdl.Filter_Five, this.filterInfoValues.fifthFilter.values) : null,
      Filter_Six: mdl.Filter_Six != null && this.filterInfoValues?.sixthFilter?.values ? this.searchIdFilter(mdl.Filter_Six, this.filterInfoValues.sixthFilter.values) : null,
      YAxisValue: mdl.YAxisValue != null ? parseInt(mdl.YAxisValue) : null,
    }
    
    this.idVariable = res.numVariable;
    this.currentId = res.numVariable;
    
    this.dataService.setFilter(mdl);
    this.getDataShow(mdl);
  }

  getDataShow(model: any) {
    
    var varTypeRes:any;
    this.generalservice.getTypeVariable(model).subscribe(x=>{
      
      this.spinner.show();
        varTypeRes=x;
        //this.typeVariable = 'Bar Pie';
        this.typeVariable = varTypeRes.typeVar;
    if (this.typeVariable == 'Time Series') {

      this.generalservice.getDataTimeSeries(model).subscribe(y=>{
        
        var timeSeriesData=y;
        this.title = timeSeriesData.metadata.nameShort;
      var yAxisTitle = timeSeriesData.yAxisName;
      this.data = {
        plusMax: 0,
        data: timeSeriesData,
        histogram: {
          title: `Histogram `, //+res.metadata.nameShort,
          yLabel: 'Frequency',
          xLabel: timeSeriesData.metadata.nameShort,
        },
        xyChart: {
          title: this.title, //res.metadata.nameShort,
          yLabel: yAxisTitle, //res.metadata.yAxis,
          xLabel: timeSeriesData.metadata.temporalResolution,
        },
        timeUnit: timeSeriesData.metadata.timeUnit.toLowerCase(),
        isDate: timeSeriesData.metadata.isDate,
        hasStudyButton: this.hasChangeVariableBtn,
        filterVariableInfo: this.filterInfo,
        hasFilter: !this.allFiltersUndefined,
        filterType: this.filterType,
        filterInfoSecond: this.allFiltersUndefined ? {} :this.filterInfoValues,
        emitFirstValue: true,
        dataikuModel: {
          table:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.table
              : null,
          column_to_work:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.columnToWork
              : null,
          frequency:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.frecuency
              : null,
          date_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.date
              : null,
          algorithm_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.algorithm
              : null,
          nlags_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.nlags
              : null,
          totalRows: timeSeriesData.totalrows,
          filters_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.filters
              : null,
          filter_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.filter
              : null,
          y_axis_filter:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.yaxisfilter
              : null,
          project_:
            timeSeriesData.dataikuD != null
              ? timeSeriesData.dataikuD.project
              : null,
        },
        listYears: timeSeriesData.listYears != null ? timeSeriesData.listYears : null,
      };
    
      
      this.dataService.emitValidationArray(this.data);
      this.dataService.emitReloadView();
      this.spinner.hide();
      
 
      
      });
      
    } else if (this.typeVariable == 'Spatial') {

      if(this.isMVM){
        this.generalservice.getDataSpatialMVM(model).subscribe(z=>{
          let spatialData = z;
          var dataMap = {
            center: MapCenter.USA_CALIFORNIA,
            scale: ['fefbce', 'FFF766', 'ec0909'],
            json: MapJson.USA,
            zoom: MapZoom.USA_CALIFORNIA,
            dateFormat: 'YYYY',
            borderColor: '#000000',
          };
         
          this.data = {
            numVariable: this.numVariable,
            data: spatialData,
            ECDF: {
              title: `ECDF `, //- `+res.metadata.nameShort,
              yLabel: 'Cumulative Relative Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            histogram: {
              title: `Histogram `, //+res.metadata.nameShort,
              yLabel: 'Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            mapTitle: spatialData.metadata.name,
            description: 'info',
            title: spatialData.metadata.name,
            mapInfo: dataMap,
            labelPopup:spatialData.metadata.mapValueHover,
            hasStudyButton: this.hasChangeVariableBtn,
            filterVariableInfo: this.filterInfo,
            hasFilter: !this.allFiltersUndefined,
            filterType: this.filterType,
            filterInfoSecond: this.filterInfoValues,
            emitFirstValue: true,
          };

          if (spatialData.hasStateLevel == false) {
            this.data.isCountyLevel = spatialData.hasCountyLevel;
          }
        });
      } else {
        this.generalservice.getDataSpatial(model).subscribe(z=>{
          let spatialData=z;
          var dataMap = {
            center: MapCenter.USA,
            scale: ['fefbce', 'FFF766', 'ec0909'],
            json: MapJson.USA,
            zoom: MapZoom.USA,
            dateFormat: 'YYYY',
            borderColor: '#000000',
          };
          switch (spatialData.metadata.dashboard) {
            case 'TRI':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.TRI,
                zoom: MapZoom.TRINATIONAL,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'MEX':
              dataMap = {
                center: MapCenter.MEXICO,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.MEXICO,
                zoom: MapZoom.MEXICO,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'CAN':
              dataMap = {
                center: MapCenter.CANADA,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.CANADA,
                zoom: MapZoom.CANADA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'USA':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.USA,
                zoom: MapZoom.USA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
          }
          this.data = {
            numVariable: this.numVariable,
            data: spatialData,
            //boxplotTitle: res.metadata.name,
            //histogramTitle: res.metadata.name +' Frecuency',
            ECDF: {
              title: `ECDF `, //- `+res.metadata.nameShort,
              yLabel: 'Cumulative Relative Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            histogram: {
              title: `Histogram `, //+res.metadata.nameShort,
              yLabel: 'Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            mapTitle: spatialData.metadata.name,
            description: 'info',
            title: spatialData.metadata.name,
            mapInfo: dataMap,
            labelPopup:spatialData.metadata.mapValueHover,//spatialData.yAxisName
            hasStudyButton: this.hasChangeVariableBtn,
            filterVariableInfo: this.filterInfo,
            hasFilter: !this.allFiltersUndefined,//this.filterInfoValues != null ? true : false,
            filterType: this.filterType,
            filterInfoSecond: this.filterInfoValues,
            emitFirstValue: true,
          };

          if (spatialData.hasStateLevel == false) {
            this.data.isCountyLevel = spatialData.hasCountyLevel;
          }
        });
      }
      
    } else if (this.typeVariable == 'Spatial Temporal') {
      
      if(this.isMVM){
        this.generalservice.getDataSpatialTemporalToTimeSeriesMVM(model).subscribe(a=>{
          var timeSeriesData = a;
          this.title = timeSeriesData.metadata.nameShort;
          var yAxisTitle = timeSeriesData.yAxisName;

          this.data = {
            plusMax: 0,
            data: timeSeriesData,
            histogram: {
              title: `Histogram `, //+res.metadata.nameShort,
              yLabel: 'Frequency',
              xLabel: timeSeriesData.metadata.nameShort,
            },
            xyChart: {
              title: this.title, //res.metadata.nameShort,
              yLabel: yAxisTitle, //res.metadata.yAxis,
              xLabel: timeSeriesData.metadata.temporalResolution,
            },
            timeUnit: timeSeriesData.metadata.timeUnit.toLowerCase(),
            isDate: timeSeriesData.metadata.isDate,
            hasStudyButton: this.hasChangeVariableBtn,
            filterVariableInfo: this.filterInfo,
            hasFilter: !this.allFiltersUndefined,
            filterType: this.filterType,
            filterInfoSecond: this.allFiltersUndefined ? {} :this.filterInfoValues,
            emitFirstValue: true,
          }

          this.dataService.emitValidationArray(this.data);
          this.dataService.emitReloadView();
          this.spinner.hide();
          
        });
      } else {
          this.generalservice.getDataSpatialTemporal(model).subscribe(a=>{
            var spatialTemporalData = a;

            if(a!=undefined){
              var dateFormat = 'yyyy';
              var format: any = {  year: 'numeric'};
      
              if(spatialTemporalData.metadata.temporalResolution != 'Yearly'){
                  dateFormat = 'yyyy-MM-dd';
                  format = {  year: 'numeric', month: '2-digit', day: '2-digit' };
              }
      
              var dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.USA,
                zoom: MapZoom.USA,
                dateFormat: dateFormat,
                borderColor: '#000000',
              };
              switch (spatialTemporalData.metadata.dashboard) {
                case 'TRI':
                  dataMap = {
                    center: MapCenter.USA,
                    scale: ['fefbce', 'FFF766', 'ec0909'],
                    json: MapJson.TRI,
                    zoom: MapZoom.TRINATIONAL,
                    dateFormat: 'YYYY',
                    borderColor: '#000000',
                  };
                  break;
                case 'MEX':
                  dataMap = {
                    center: MapCenter.MEXICO,
                    scale: ['fefbce', 'FFF766', 'ec0909'],
                    json: MapJson.MEXICO,
                    zoom: MapZoom.MEXICO,
                    dateFormat: 'YYYY',
                    borderColor: '#000000',
                  };
                  break;
                case 'CAN':
                  dataMap = {
                    center: MapCenter.CANADA,
                    scale: ['fefbce', 'FFF766', 'ec0909'],
                    json: MapJson.CANADA,
                    zoom: MapZoom.CANADA,
                    dateFormat: 'YYYY',
                    borderColor: '#000000',
                  };
                  break;
                case 'USA':
                  dataMap = {
                    center: MapCenter.USA,
                    scale: ['fefbce', 'FFF766', 'ec0909'],
                    json: MapJson.USA,
                    zoom: MapZoom.USA,
                    dateFormat: 'YYYY',
                    borderColor: '#000000',
                  };
                  break;
              }
              const formatInfo = {
                region: 'en-US',
                format: format,
                addDays: 0,
              };
              this.data = {
                data: spatialTemporalData,
                ECDF: {
                  title: `ECDF `, //- `+res.metadata.nameShort+` - `,
                  yLabel: 'Cumulative Relative Frequency',
                  xLabel: spatialTemporalData.metadata.nameShort,
                  titleCounty: spatialTemporalData.metadata.nameShort,
                },
                histogram: {
                  title: `Histogram `, //+res.metadata.nameShort,
                  yLabel: 'Frequency',
                  xLabel: spatialTemporalData.metadata.nameShort,
                  titleCounty: spatialTemporalData.metadata.nameShort,
                },
                xyChart: {
                  title: spatialTemporalData.metadata.nameShort,
                  yLabel:
                    spatialTemporalData.metadata.yAxis != null
                      ? spatialTemporalData.metadata.yAxis
                      : '',
                  xLabel:
                    spatialTemporalData.metadata.temporalResolution != null
                      ? spatialTemporalData.metadata.temporalResolution
                      : '',
                },
                timeUnit:
                  spatialTemporalData.metadata.temporalResolution != null
                    ? spatialTemporalData.metadata.temporalResolution
                    : '',
                mapTitle: spatialTemporalData.metadata.name,
                description: 'info',
                title: spatialTemporalData.metadata.name,
                mapInfo: dataMap,
                labelPopup:spatialTemporalData.yAxisName,
                hasStudyButton: this.hasChangeVariableBtn,
                filterVariableInfo: this.filterInfo,
                hasFilter: !this.allFiltersUndefined,//this.filterInfoValues != null ? true : false,
                filterType: this.filterType,
                filterInfoSecond: this.filterInfoValues,
                emitFirstValue: true,
                formatInfo: formatInfo,
                scale: spatialTemporalData.scale,
              };
      
              if (spatialTemporalData.hasStateLevel == false) {
                this.data.isCountyLevel = spatialTemporalData.hasCountyLevel;
              }

            }

            this.dataService.emitValidationArray(a);
            this.dataService.emitReloadView();
            this.spinner.hide();

          });
         
      }
      
    } else if (this.typeVariable == 'Bar Chart') {
      
      this.generalservice.getDataBarChart(model).subscribe( y =>{
        
        var pieData = y;
        this.title = pieData.metadata.nameShort;
        var yAxisTitle = pieData.yAxisName;
      
        this.data = {
        plusMax: 0,
        data: pieData,
        histogram: {
          title: `Histogram `, 
          yLabel: 'Frequency',
          xLabel: pieData.metadata.nameShort,
        },
        ECDF: {
          title: `ECDF `,
          yLabel: 'Cumulative Relative Frequency',
          xLabel:  pieData.metadata.nameShort,
        },
        pieChart: {
          title: this.title, 
          yLabel: yAxisTitle, 
          xLabel: 'Categories',//timeSeriesData.metadata.temporalResolution,
        },
       // hasStudyButton: this.hasChangeVariableBtn,
        //filterVariableInfo: this.filterInfo,
        //emitFirstValue: true,

      };
    
      
      this.dataService.emitValidationArray(this.data);
      this.dataService.emitReloadView();
      this.spinner.hide();
      }); 
      
    } else if (this.typeVariable == 'Time Series MVM') {

      this.generalservice.getDataTimeSeriesMVM(model).subscribe(y=>{
        
        var timeSeriesData = y;
        this.title = timeSeriesData.metadata.nameShort;
        var yAxisTitle = timeSeriesData.yAxisName;

        this.data = {
          plusMax: 0,
          data: timeSeriesData,
          histogram: {
            title: `Histogram `, //+res.metadata.nameShort,
            yLabel: 'Frequency',
            xLabel: timeSeriesData.metadata.nameShort,
          },
          xyChart: {
            title: this.title, //res.metadata.nameShort,
            yLabel: yAxisTitle, //res.metadata.yAxis,
            xLabel: timeSeriesData.metadata.temporalResolution,
          },
          timeUnit: timeSeriesData.metadata.timeUnit.toLowerCase(),
          isDate: timeSeriesData.metadata.isDate,
          hasStudyButton: this.hasChangeVariableBtn,
          filterVariableInfo: this.filterInfo,
          hasFilter: !this.allFiltersUndefined,
          filterType: this.filterType,
          filterInfoSecond: this.allFiltersUndefined ? {} :this.filterInfoValues,
          emitFirstValue: true,
          listYears: timeSeriesData.listYears != null ? timeSeriesData.listYears : null,
        }

        this.dataService.emitValidationArray(this.data);
        this.dataService.emitReloadView();
        this.spinner.hide();
      
      });
      
    } else if (this.typeVariable == 'Spatial MVM') {

      this.generalservice.getDataSpatialMVM(model).subscribe(z=>{
          let spatialData = z;
          var dataMap = {
            center: MapCenter.USA,
            scale: ['fefbce', 'FFF766', 'ec0909'],
            json: MapJson.USA,
            zoom: MapZoom.USA,
            dateFormat: 'YYYY',
            borderColor: '#000000',
          };

          var map = spatialData.metadata.countryMap != null ? spatialData.metadata.countryMap : spatialData.metadata.dashboard; 
          
          switch (map) {
            case 'TRI':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.TRI,
                zoom: MapZoom.TRINATIONAL,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'MEX':
              dataMap = {
                center: MapCenter.MEXICO,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.MEXICO,
                zoom: MapZoom.MEXICO,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'CAN':
              dataMap = {
                center: MapCenter.CANADA,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.CANADA,
                zoom: MapZoom.CANADA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'USA':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'].reverse(),
                json: MapJson.USA,
                zoom: MapZoom.USA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
          }
          this.data = {
            numVariable: this.numVariable,
            data: spatialData,
            //boxplotTitle: res.metadata.name,
            //histogramTitle: res.metadata.name +' Frecuency',
            ECDF: {
              title: `ECDF `, //- `+res.metadata.nameShort,
              yLabel: 'Cumulative Relative Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            histogram: {
              title: `Histogram `, //+res.metadata.nameShort,
              yLabel: 'Frequency',
              xLabel: spatialData.metadata.nameShort,
            },
            mapTitle: spatialData.metadata.name,
            description: 'info',
            title: spatialData.metadata.name,
            mapInfo: dataMap,
            labelPopup:spatialData.metadata.mapValueHover,//spatialData.yAxisName
            hasStudyButton: this.hasChangeVariableBtn,
            filterVariableInfo: this.filterInfo,
            hasFilter: !this.allFiltersUndefined,//this.filterInfoValues != null ? true : false,
            filterType: this.filterType,
            filterInfoSecond: this.filterInfoValues,
            emitFirstValue: true,
          };

          if (spatialData.hasStateLevel == false) {
            this.data.isCountyLevel = spatialData.hasCountyLevel;
          }
        });
      
    } else if (this.typeVariable == 'Spatial Temporal MVM') {
      this.generalservice.getDataSpatialTemporalMVM(model).subscribe(a=>{
        var spatialTemporalData = a;

        if(a!=undefined){
          var dateFormat = 'yyyy';
          var format: any = {  year: 'numeric'};
  
          //if(spatialTemporalData.metadata.temporalResolution != 'Yearly')
          {
              dateFormat = 'yyyy-MM-dd';
              format = {  year: 'numeric', month: '2-digit', day: '2-digit' };
          }
  
          var dataMap = {
            center: MapCenter.USA,
            scale: ['fefbce', 'FFF766', 'ec0909'],
            json: MapJson.USA,
            zoom: MapZoom.USA,
            dateFormat: dateFormat,
            borderColor: '#000000',
          };

          var map = spatialTemporalData.metadata.countryMap != null ? spatialTemporalData.metadata.countryMap : spatialTemporalData.metadata.dashboard; 
          
          switch (map) {
            case 'TRI':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.TRI,
                zoom: MapZoom.TRINATIONAL,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'MEX':
              dataMap = {
                center: MapCenter.MEXICO,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.MEXICO,
                zoom: MapZoom.MEXICO,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'CAN':
              dataMap = {
                center: MapCenter.CANADA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.CANADA,
                zoom: MapZoom.CANADA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
            case 'USA':
              dataMap = {
                center: MapCenter.USA,
                scale: ['fefbce', 'FFF766', 'ec0909'],
                json: MapJson.USA,
                zoom: MapZoom.USA,
                dateFormat: 'YYYY',
                borderColor: '#000000',
              };
              break;
          }
          const formatInfo = {
            region: 'en-US',
            format: format,
            addDays: 0,
          };
          this.data = {
            data: spatialTemporalData,
            ECDF: {
              title: `ECDF `, //- `+res.metadata.nameShort+` - `,
              yLabel: 'Cumulative Relative Frequency',
              xLabel: spatialTemporalData.metadata.nameShort,
              titleCounty: spatialTemporalData.metadata.nameShort,
            },
            histogram: {
              title: `Histogram `, //+res.metadata.nameShort,
              yLabel: 'Frequency',
              xLabel: spatialTemporalData.metadata.nameShort,
              titleCounty: spatialTemporalData.metadata.nameShort,
            },
            xyChart: {
              title: spatialTemporalData.metadata.nameShort,
              yLabel:
                spatialTemporalData.metadata.yAxis != null
                  ? spatialTemporalData.metadata.yAxis
                  : '',
              xLabel:
                spatialTemporalData.metadata.temporalResolution != null
                  ? spatialTemporalData.metadata.temporalResolution
                  : '',
            },
            timeUnit:
              spatialTemporalData.metadata.temporalResolution != null
                ? spatialTemporalData.metadata.temporalResolution
                : '',
            mapTitle: spatialTemporalData.metadata.name,
            description: 'info',
            title: spatialTemporalData.metadata.name,
            mapInfo: dataMap,
            labelPopup:spatialTemporalData.yAxisName,
            hasStudyButton: this.hasChangeVariableBtn,
            filterVariableInfo: this.filterInfo,
            hasFilter: !this.allFiltersUndefined,//this.filterInfoValues != null ? true : false,
            filterType: this.filterType,
            filterInfoSecond: this.filterInfoValues,
            emitFirstValue: true,
            formatInfo: formatInfo,
            scale: spatialTemporalData.scale,
          };
  
          if (spatialTemporalData.hasStateLevel == false) {
            this.data.isCountyLevel = spatialTemporalData.hasCountyLevel;
          }

        }

        this.dataService.emitValidationArray(a);
        this.dataService.emitReloadView();
        this.spinner.hide();

      });  
    } 
    else{
      
          this.spinner.hide();
    }
    //this.spinner.hide();
      }
    );
    
  }

  async handleFilterUpdate($event: any) {
    let mdl;

    if (Array.isArray($event)) {
      this.eventSelectYears = $event;
    } 
    else {
      this.eventSelectYears = null;
      let dataAsString: string = $event.toString();
      let lng: number = dataAsString.length;
      let lastChar: string = dataAsString.charAt(lng - 1);
      let leftString: string = dataAsString.slice(0, lng - 1);

      if (this.eventSix != '' && this.eventSix != null && lastChar=='6') {
        if (
          this.filterInfoValues.sixthFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventSix = leftString;
        }
      }
      if (this.eventFive != '' && this.eventFive != null && lastChar=='5') {
        if (
          this.filterInfoValues.fifthFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventFive = leftString;
        }
      }
      if (this.eventFour != '' && this.eventFour != null && lastChar=='4') {
        if (
          this.filterInfoValues.fourthFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventFour = leftString;
        }
      }
      if (this.eventThree != '' && this.eventThree != null && lastChar=='3') {
        if (
          this.filterInfoValues.thirdFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventThree = leftString;
        }
      }
      if (this.eventTwo != '' && this.eventTwo != null && lastChar=='2') {
        if (
          this.filterInfoValues.secondFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventTwo = leftString;
        }
      }
      if (this.eventOne != '' && this.eventOne != null && lastChar=='1') {
        if (
          this.filterInfoValues.firstFilter.values.find(
            (item: { value: any }) => item.value === leftString
          )
        ) {
          this.eventOne = leftString;
        }
      }
      if (this.eventYAxis != '' && this.eventYAxis != null) {
        if (
          this.filterInfoValues.yAxis.find((x: any) => x.id.toString() === $event)
        ) {
          this.eventYAxis = $event;
        }
      }
    }

    mdl = {
      numVariable: this.currentId,
      Filter_One: this.eventOne != null ? this.eventOne : null,
      Filter_Two: this.eventTwo != null ? this.eventTwo : null,
      Filter_Three: this.eventThree != null ? this.eventThree : null,
      Filter_Four: this.eventFour != null ? this.eventFour : null,
      Filter_Five: this.eventFive != null ? this.eventFive : null,
      Filter_Six: this.eventSix != null ? this.eventSix : null,
      YAxisValue: this.eventYAxis != null ? this.eventYAxis : null,
      SelectYears: this.eventSelectYears != null ? this.eventSelectYears : null,
    };
    
    this.getDataShow(mdl);
  }

  async getValue($event: any) {
    this.spinner.show();
    this.data = undefined;
    this.currentId=$event;
    const datosmodel = 
      { 
        numVariable: $event, 
        nameNet: this.net,
        isForMVM: this.isMVM
      };
    
    const filters = await firstValueFrom(
      this.generalservice.getFilters(datosmodel)
    );
    this.numVariable=filters.numVariable;
    

    if(filters.fileName!=="variable_spatial" && filters.fileName!=="variable_spatial_temporal" 
          && filters.fileName!=="variable_time_value" && filters.fileName!=="variable_bar_chart"
          && filters.fileName!=="variable_mvm_time_series" && filters.fileName!=="variable_mvm_spatial" && filters.fileName!=="variable_mvm_spatial_temporal" ){

            if (filters.variablesSameNode) {
              this.filterInfo = {
                firstFilter: filters.variablesSameNode,
              };
              
              const objetoEncontrado = this.filterInfo.firstFilter.values.find(
                (item: { itemId: any }) => item.itemId === filters.numVariable
              );
              this.dataService.setData(objetoEncontrado.id);
              this.hasChangeVariableBtn = true;
            }
            
      this.estadocomponente=true;
      this.spinner.hide();
      // this.loadDynamicComponent();
    }
    else{
      this.estadocomponente=false;
      await this.fetchData(filters);
      this.spinner.hide();
    }

  }
  
}

