<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-shapefiles-template [data]="this.data" [title]="'Natural Forests'" [haveFilters]="2" [filters]="this.filterInfoValues"
        (firstValue)="handleFilterUpdate($event)"
        (secondValue)="handleFilterSecondUpdate($event)" ></app-dynamic-shapefiles-template>
    </div>
</div>

<div *ngIf="!this.isUSA">
  <app-not-found></app-not-found>
</div>
