import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DroughtComponent } from 'src/app/shared/pages/drought/drought.component';
import { SurfaceAirTemperatureComponent } from 'src/app/shared/pages/surface-air-temperature/surface-air-temperature.component';
import { PrecipitationComponent } from 'src/app/shared/pages/precipitation/precipitation.component';
import { GeneralNodeComponent } from 'src/app/shared/pages/general-node/general-node.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Organized-Crime',
    component: GeneralNodeComponent//OrganizedCrimeComponent
  },
  {
    path: 'Droughts',
    component: DroughtComponent
  },
  {
    path: 'Ethanol-',
    component: GeneralNodeComponent//EthanolUsManufacturingComponent
  },
  {
    path: 'Bevarages-Ethanol',
    component: GeneralNodeComponent//AlcoholUsComponent
  },
  {
    path: 'Surface-Air-Temperature',
    component: SurfaceAirTemperatureComponent
  },
  {
    path: 'Precipitation',
    component: PrecipitationComponent
  },
  {
    path: 'Energy-Policies',
    component: GeneralNodeComponent//FossilFuelDependenceComponent
  },
  {
    path: 'Domestic-Ethanol-Market',
    component: GeneralNodeComponent
  },
  {
    path: 'Snowpack',
    component: GeneralNodeComponent
  },
  {
    path: 'Water-Irrigation',
    component: GeneralNodeComponent
  },
  {
    path: 'Ethanol',
    component: GeneralNodeComponent
  },
  {
    path: 'Organic-Feed-Supply',
    component: GeneralNodeComponent
  },
  {
    path: 'Custom-Operations',
    component: GeneralNodeComponent
  },
  {
    path: 'Credit',
    component: GeneralNodeComponent
  },
  
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CornFarmingRoutingModule { }
