import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { HousingPriceFuncModel } from 'src/app/core/models/sawmillsProduction/housingPrice/housingPriceModel';

@Component({
  selector: 'app-d3-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.css']
})
export class HistogramD3Component implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLElement>;
  @ViewChild('hist', { read: ElementRef })
  private histEl!: ElementRef<HTMLElement>;
  @Input() data!: any;
  @Input() title: string = '';
  @Input() yLabel: string = '';
  @Input() xLabel: string = '';
  @Input() Id!: string;
  @Input() um!: string;
  xLabelOrigin: string='';
  @Input() plusMax: number = 10;

  private max: number = 0;
  private min: number = 0;
  private svg!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private margin = 60;
  private width = 650 - (this.margin * 2);
  private height = 350 - (this.margin * 2);
  private x!: any;
  private y!: any;
  private xAxis!: any;
  private yAxis!: any;
  public value: number = 10;
  private tooltip!: any;
  private textTitle!:any;
  private textX!:any;

  constructor() {}

  private deleteSvg(): void{
    d3.select(`#my_dataviz`).remove();
  }
  private createSvg(): void {
    d3.select(`#my_dataviz`).select("svg").remove();
    this.svg = d3.select(`#my_dataviz`)
      .append('svg')
      .attr('width', this.width + (this.margin * 2) + 100) // Aumenta el ancho para el scroll
      .attr('height', this.height + (this.margin * 2))
      .append('g')
      .attr('transform', 'translate(' + this.margin + ',' + this.margin + ')');
      
  }
  

  private drawBars(): void {

    // Create the X-axis band scale
    this.x = d3.scaleLinear()
      .domain([this.min, this.max + this.plusMax])
      .range([0, this.width]);

    // Draw the X-axis on the DOM
    // this.xAxis = this.svg.append('g')
    //   .attr('transform', 'translate(0,' + this.height + ')')
    //   .call(d3.axisBottom(this.x));
      // Draw the X-axis on the DOM
  this.xAxis = this.svg.append('g')
  .attr('transform', 'translate(0,' + this.height + ')')
  .call(d3.axisBottom(this.x)
    .tickFormat((d: any) => this.abbreviateNumber(d))
  );

    //Draw the title on the DOM
    this.textTitle= this.svg.append("text")
      .attr("x", this.width/2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .style("font-weight", "700")
      .style('fill', '#44B5A4')
      .text(this.title);

    //Draw the X-Label on the DOM
    const xLabelLines = this.xLabelOrigin.split('\n');
    const xLabelElement = this.svg.append("text")
    .attr("transform", "rotate(0)")
      .attr("x", (this.width/2))
      .attr("y", (250))
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("font-weight", "700")
      .style('fill', '#44B5A4');
      xLabelLines.forEach((line, i) => {
        xLabelElement.append('tspan')
          .attr('x', 300)
        
          .attr('dy', `${1}em`)
          .text(line);
      });
    
    // Create the Y-axis band scale
    this.y = d3.scaleLinear()
      .range([this.height, 0]);

    // Create a group for the Y-axis and set its id
    this.yAxis = this.svg.append("g")
      .attr('id', 'y-axis');

    
    //Draw the Y-Label on the DOM
    this.svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -(this.height/2))
      .attr("y", -40)
      .attr("text-anchor", "middle")
      .style('fill', '#44B5A4')
      .style("font-size", "16px")
      .style("font-weight", "700")
      .text(this.yLabel);


  }
  private update(nBin:  number){
    const dom = this.x.domain();
    const histogram = d3.histogram()
    .value((d:any) => d.value)   // I need to give the vector of value
    .domain([dom[0], dom[1]])  // then the domain of the graphic
    .thresholds(this.x.ticks(nBin)); // then the numbers of bins

    // And apply this function to data to get the bins
    const bins = histogram(this.data);
  
      // Y axis: update now that we know the domain
      this.y.domain([0, d3.max(bins, function(d) { return d.length; })]);   // d3.hist has to be called before the Y axis obviously

      this.yAxis
  .transition()
  .duration(1000)
  .call(
    d3.axisLeft(this.y)
      .ticks(5) // Número de ticks (puedes ajustar este valor)
      .tickFormat((d: any) => d % 1 === 0 ? d : '') // Mostrar solo enteros
  );

          this.xAxis
          .transition()
          .duration(1000)
          .call(d3.axisBottom(this.x)
            .tickFormat((d: any) => this.abbreviateNumber(d))
          );
      // Join the rect with the bins data
      const u:any = this.svg.selectAll("rect")
          .data(bins)
  
      // Manage the existing bars and eventually the new ones:
      u
          .enter()
          .append("rect") // Add a new rect for each new elements
          .merge(u) // get the already existing elements as well
          .transition() // and apply changes to all of them
          .duration(1000)
            .attr("x", 1)
            .attr("transform", (d:any) => { return "translate(" + this.x(d.x0) + "," + this.y(d.length) + ")"; })
            .attr("width", (d:any) => { return this.x(d.x1) - this.x(d.x0) -1 ; })
            .attr('height', (d: string | any[]) => this.height - this.y(d.length))
            .style("fill", "#69b3a2")
            .style("height", 100)
  
  
      // If less bar in the new histogram, I delete the ones not in use anymore
      u
          .exit()
          .remove()


              // Add event listener for scrolling
    d3.select('#my_dataviz').on('scroll', () => {
      const myDataviz = d3.select('#my_dataviz').node();
      if (myDataviz instanceof HTMLElement) {
        const scrollLeft = myDataviz.scrollLeft;
        d3.select('#y-axis').attr('transform', `translate(${scrollLeft}, 0)`).raise();
      }
    });

    // Move the Y-axis to the front
    d3.select('#y-axis').raise();
  
      }
    private getMaxValue(): void{
      this.max = Math.max(... this.data.map((x:HousingPriceFuncModel) => {return x.value}));
    }
    private getMinValue(): void{
      this.min = Math.min(... this.data.map((x:HousingPriceFuncModel) => {return x.value}));
    }
  ngOnInit(): void {
    
  }

  private abbreviateNumber(value: number): string {
    if (value >= 1_000_000_000) {
      return (value / 1_000_000_000).toFixed(2).replace(/\.?0+$/, '') + 'B';
    } else if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(2).replace(/\.?0+$/, '') + 'M';
    } else {
      return value.toString();
    }
  }
  // private processData(data: any[]): any[] {
  //   return data.map(item => {
  //     if (item.hasOwnProperty('value') && typeof item.value === 'number' && item.value >= 10000000) {
  //       item.value = Math.floor(item.value / 1000); // Divide entre 1000 y redondea hacia abajo
  //     }
  //     return item;
  //   });
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data !== undefined){
      this.xLabelOrigin = '';
      const maxLineLength = 90;
      if (this.um) {
        this.xLabelOrigin = this.formatXAxisLabel(this.xLabel + ' (' + this.um + ')', maxLineLength);
      } else {
        this.xLabelOrigin = this.formatXAxisLabel(this.xLabel, maxLineLength);
      }
      //this.deleteSvg();
      // this.data = this.processData(this.data);
      this.createSvg();
      this.getMaxValue();
      this.getMinValue();
      this.drawBars();
      this.update(this.value);
      this.updateWidth();
    }
    
  }
  ngOnDestroy(): void {
    this.deleteSvg();
  }

  updateChart(){
    this.update(this.value);
  }
  updateWidth(){
    const svg_ = document.querySelector<HTMLElement>('#my_dataviz > svg');
    const currentWidth = parseInt(d3.select('#hist-container').style('width'), 10);
    this.width = currentWidth;
    svg_!.style.width = `${currentWidth}px`;
    this.x.range([ 0, currentWidth]);
    this.xAxis.call(d3.axisBottom(this.x));
    this.textTitle.attr('x',currentWidth*0.43);
    this.textX.attr('x',currentWidth*0.43);
    this.textX.style("font-size",16);

    if(currentWidth<290){
      this.textTitle.attr('x',(currentWidth*0.43)-20);
      this.textX.attr('x',(currentWidth*0.43)-20);
      this.textX.style("font-size", `${currentWidth*0.035}`);
    }
    this.updateChart();
  }

  private formatXAxisLabel(text: string, maxLineLength: number): string {
    const words = text.split(' ');
    let formattedText = '';
    let line = '';

    words.forEach((word) => {
      if ((line + word).length > maxLineLength) {
        formattedText += line.trim() + '\n';
        line = '';
      }
      line += word + ' ';
    });

    formattedText += line.trim();
    return formattedText;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.updateWidth();
  }
  
}
