<script defer="" nomodule="" src="./files/polyfills-c67a75d1b6f99dc8.js.download"></script>
<script src="./files/webpack-eb8613d444c7aed9.js.download" defer=""></script>
<script src="./files/framework-f1a84b2556744d26.js.download" defer=""></script>
<script src="./files/main-1a399d07b475a8df.js.download" defer=""></script>
<script src="./files/_app-da54ae582621f68e.js.download" defer=""></script>
<script src="./files/2edb282b-793f4410c215ca5d.js.download" defer=""></script>
<script src="./files/74610-adf2b5bb955989b8.js.download" defer=""></script>
<script src="./files/28836-622d0b03962370c8.js.download" defer=""></script>
<script src="./files/6358-8007aaafcec5293a.js.download" defer=""></script>
<script src="./files/53743-e718fab504f3a507.js.download" defer=""></script>
<script src="./files/76199-8cf522ee919b0a33.js.download" defer=""></script>
<script src="./files/25436-77adadc7a7590a79.js.download" defer=""></script>
<script src="./files/60955-c2f03b7bcb5c7c63.js.download" defer=""></script>
<script src="./files/45156-d452a4a6a8a84f07.js.download" defer=""></script>
<script src="./files/40908-5ec11ad69c6764ee.js.download" defer=""></script>
<script src="./files/75328-3c64d05bb7488e20.js.download" defer=""></script>
<script src="./files/27177-1cb08ba1b11d9a71.js.download" defer=""></script>
<script src="./files/83160-d8ff0c04348f5903.js.download" defer=""></script>
<script src="./files/59243-bc30ad84cd2a9d48.js.download" defer=""></script>
<script src="./files/29774-bd828bcdf8e5bf11.js.download" defer=""></script>
<script src="./files/62736-e9fa8998003f2bb5.js.download" defer=""></script>
<script src="./files/view-0f2470e4ea9369f3.js.download" defer=""></script>
<script src="./files/_buildManifest.js.download" defer=""></script>
<script src="./files/_ssgManifest.js.download" defer=""></script>

<link rel="stylesheet" href="/files/main.css">

<style id="__jsx-1328779564">
    html {
        font-size: 62.5%;
        padding: 0;
        margin: 0
    }

    div,
    ol,
    ul,
    li,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    figure,
    fieldset,
    textarea,
    pre,
    body,
    dl,
    dd,
    dt {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    li {
        text-indent: 0;
        list-style-type: none
    }

    html[lang="zh-cn"] body,
    html[lang="zh-hk"] body,
    html[lang="zh-tw"] body {
        font-family: "Avenir Next World", "Helvetica Neue", "Microsoft JhengHei", "Microsoft YaHei", sans-serif
    }

    body,
    input,
    textarea,
    select,
    button {
        font-family: "Avenir Next World", sans-serif
    }

    img,
    iframe {
        border: 0
    }

    button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin: 0;
        color: inherit
    }

    input::-ms-clear {
        display: none
    }

    fieldset {
        border: none
    }
</style>
<style id="__jsx-1659836331">
    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0000-007f, u+0080-00ff, u+2000-206f, u+2070-209f, u+20a0-20cf, u+2100-214f, u+2200-22ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0000-007f, u+0080-00ff, u+2000-206f, u+2070-209f, u+20a0-20cf, u+2100-214f, u+2200-22ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/b9c5b839-db56-4419-8fcb-6ab661babb1d.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0000-007f, u+0080-00ff, u+2000-206f, u+2070-209f, u+20a0-20cf, u+2100-214f, u+2200-22ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0000-007f, u+0080-00ff, u+2000-206f, u+2070-209f, u+20a0-20cf, u+2100-214f, u+2200-22ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0000-007f, u+0080-00ff, u+2000-206f, u+2070-209f, u+20a0-20cf, u+2100-214f, u+2200-22ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/471f2b8b-e0d5-45a0-b033-d1cdb57fba1b.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0100-017f, u+0180-024f, u+02b0-02ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-002.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0100-017f, u+0180-024f, u+02b0-02ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/b9c5b839-db56-4419-8fcb-6ab661babb1d-002.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0100-017f, u+0180-024f, u+02b0-02ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-002.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0100-017f, u+0180-024f, u+02b0-02ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-002.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0100-017f, u+0180-024f, u+02b0-02ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/471f2b8b-e0d5-45a0-b033-d1cdb57fba1b-002.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0370-03ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-003.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0370-03ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/b9c5b839-db56-4419-8fcb-6ab661babb1d-003.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0370-03ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-003.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0370-03ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-003.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0370-03ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/471f2b8b-e0d5-45a0-b033-d1cdb57fba1b-003.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0400-04ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-004.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0400-04ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/b9c5b839-db56-4419-8fcb-6ab661babb1d-004.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0400-04ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-004.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0400-04ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-004.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0400-04ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/471f2b8b-e0d5-45a0-b033-d1cdb57fba1b-004.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+10a0-10ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-005.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+10a0-10ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-005.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+10a0-10ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-005.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+10a0-10ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-005.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+10a0-10ff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-005.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0600-06ff, u+fb50-fdff, u+fe70-feff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-006.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0600-06ff, u+fb50-fdff, u+fe70-feff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-006.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0600-06ff, u+fb50-fdff, u+fe70-feff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-006.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0600-06ff, u+fb50-fdff, u+fe70-feff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-006.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0600-06ff, u+fb50-fdff, u+fe70-feff;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-006.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0590-05ff, u+fb00-fb4f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-007.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0590-05ff, u+fb00-fb4f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/b9c5b839-db56-4419-8fcb-6ab661babb1d-007.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0590-05ff, u+fb00-fb4f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-007.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0590-05ff, u+fb00-fb4f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-007.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0590-05ff, u+fb00-fb4f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/471f2b8b-e0d5-45a0-b033-d1cdb57fba1b-007.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0e00-0e7f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-008.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0e00-0e7f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-008.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0e00-0e7f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-008.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+0e00-0e7f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-008.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+0e00-0e7f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-008.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: u+1e00-1eff, u+0300-036f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-009.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 700;
        font-style: italic;
        font-display: swap;
        unicode-range: u+1e00-1eff, u+0300-036f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/074dfc70-6436-4f4b-9314-d1e69287aea1-009.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        unicode-range: u+1e00-1eff, u+0300-036f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/a53dd870-d60f-11ec-9404-069abf4c3446-009.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        unicode-range: u+1e00-1eff, u+0300-036f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-009.woff2")
    }

    @font-face {
        font-family: "Avenir Next World";
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        unicode-range: u+1e00-1eff, u+0300-036f;
        src: url("/static/storymaps-website-common/fonts/Avenir_Next_World/09ab0626-bb45-4650-acc8-0182d693df02-009.woff2")
    }
</style>
<style id="__jsx-331915672">
    body.print {
        width: 7.5in;
        margin: 0 auto
    }

    body.print .page-container {
        background: #FFFFFF
    }
</style>
<style id="__jsx-2067809186">
    .preview-controls-container.jsx-2067809186 {
        position: fixed;
        z-index: 98;
        bottom: -webkit-calc(0rem + 4.0rem);
        bottom: -moz-calc(0rem + 4.0rem);
        bottom: calc(0rem + 4.0rem);
        right: 7.2rem
    }

    html[dir='rtl'] .preview-controls-container.jsx-2067809186 {
        right: unset;
        left: 7.2rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .toast-frame.jsx-2302946830 {
        position: fixed;
        z-index: 9999;
        top: 8.4rem
    }

    html:not([dir='rtl']) .toast-frame.jsx-2302946830 {
        right: 2.4rem
    }

    html[dir='rtl'] .toast-frame.jsx-2302946830 {
        left: 2.4rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .start-content.jsx-1604684696 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.6rem;
        align-items: center;
        justify-content: center;
        ;
    }

    button.inherit.jsx-1604684696 {
        color: inherit;
        text-align: inherit
    }
</style>
<style type="text/css" data-styled-jsx="">
    .content-container.jsx-1931885094 {
        padding: 0 1.6rem;
        max-width: 1280px;
        margin: 0 auto;
        width: 100%
    }

    @media(min-width:700px) {
        .content-container.jsx-1931885094 {
            padding: 0 1.6rem
        }
    }

    @media(min-width:960px) {
        .content-container.jsx-1931885094 {
            padding: 0 1.6rem
        }
    }

    @media(min-width:1280px) {
        .content-container.jsx-1931885094 {
            padding: 0 1.6rem
        }
    }

    body.basic-print .content-container.jsx-1931885094 {
        padding: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3680692050 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    svg.jsx-3680692050 {
        width: 1.6rem;
        height: 1.6rem;
        fill: currentColor
    }

    body.user-is-tabbing .icon.jsx-3680692050 svg.jsx-3680692050:focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    body:not(.user-is-tabbing) .icon.jsx-3680692050 svg.jsx-3680692050:focus {
        outline: none
    }

    html[lang="ar"] .ar-flip.jsx-3680692050 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-3680692050 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    body:not(.user-is-tabbing) .button.jsx-393389289 {
        outline: none
    }

    .button.jsx-393389289,
    .icon-wrapper.jsx-393389289 {
        border: 0;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
        user-select: none;
        display: inline-grid;
        grid-auto-flow: column;
        grid-gap: 0.8rem;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 1.6rem;
        text-decoration: none;
        -webkit-border-radius: 0.2rem;
        -moz-border-radius: 0.2rem;
        border-radius: 0.2rem
    }

    .button.jsx-393389289:not(.icon-button):not(.disabled)[aria-pressed="true"],
    .button.jsx-393389289:not(.icon-button):not(.disabled)[aria-pressed="true"]:hover,
    .icon-button.jsx-393389289:not(.disabled)[aria-pressed="true"]>*.jsx-393389289,
    .icon-button.jsx-393389289:not(.disabled)[aria-pressed="true"]:hover>*.jsx-393389289 {
        color: #FFFFFF;
        background-color: #003836
    }

    .button.jsx-393389289 {
        background-color: var(--color-bg-default);
        color: var(--color-fg-default)
    }

    .button.jsx-393389289:not(.disabled):hover {
        background-color: var(--color-bg-hover)
    }

    .button.jsx-393389289:is(:active, [aria-haspopup][aria-expanded="true"], [aria-haspopup][aria-expanded="true"]:hover) {
        background-color: var(--color-bg-active)
    }

    .button.disabled.jsx-393389289 {
        background-color: var(--color-bg-disabled);
        color: var(--color-fg-disabled)
    }

    .primary.jsx-393389289 {
        --color-bg-default: #00807C;
        --color-bg-hover: #00615F;
        --color-bg-active: #003836;
        --color-bg-disabled: #C7D6D5;
        --color-fg-default: #F2F8F7;
        --color-fg-disabled: #F2F8F7
    }

    .secondary.jsx-393389289 {
        --color-bg-default: transparent;
        --color-bg-hover: #E1F4F2;
        --color-bg-active: #D0EBE7;
        --color-bg-disabled: #F4F6F6;
        --color-fg-default: #001F1E;
        --color-fg-disabled: #A5BBBB;
        -webkit-box-shadow: inset 0 0 0 1px #00807C;
        -moz-box-shadow: inset 0 0 0 1px #00807C;
        box-shadow: inset 0 0 0 1px #00807C
    }

    .secondary.disabled.jsx-393389289 {
        -webkit-box-shadow: inset 0 0 0 1px #C7D6D5;
        -moz-box-shadow: inset 0 0 0 1px #C7D6D5;
        box-shadow: inset 0 0 0 1px #C7D6D5
    }

    .dangerous.jsx-393389289 {
        --color-bg-default: transparent;
        --color-bg-hover: #FFF1F0;
        --color-bg-active: #FEE9E6;
        --color-bg-disabled: transparent;
        --color-fg-default: #D43121;
        --color-fg-disabled: #D43121;
        -webkit-box-shadow: inset 0 0 0 1px #D43121;
        -moz-box-shadow: inset 0 0 0 1px #D43121;
        box-shadow: inset 0 0 0 1px #D43121
    }

    .dangerous.disabled.jsx-393389289 {
        -webkit-box-shadow: inset 0 0 0 1px #D43121;
        -moz-box-shadow: inset 0 0 0 1px #D43121;
        box-shadow: inset 0 0 0 1px #D43121
    }

    .text.jsx-393389289 {
        --color-bg-default: transparent;
        --color-bg-hover: #E1F4F2;
        --color-bg-active: #D0EBE7;
        --color-bg-disabled: transparent;
        --color-fg-default: #001F1E;
        --color-fg-disabled: #A5BBBB
    }

    .text-compact.jsx-393389289 {
        --color-bg-default: transparent;
        --color-bg-hover: transparent;
        --color-bg-active: transparent;
        --color-bg-disabled: transparent;
        --color-fg-default: #001F1E;
        --color-fg-disabled: #A5BBBB;
        padding: 0.8rem 0
    }

    .floating.jsx-393389289 {
        --color-bg-default: #FFFFFF;
        --color-bg-hover: #E1F4F2;
        --color-bg-active: #D0EBE7;
        --color-bg-disabled: #F4F6F6;
        --color-fg-default: #001F1E;
        --color-fg-disabled: #A5BBBB;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px -1px rgba(0, 0, 0, 0.03);
    }

    .floating.disabled.jsx-393389289 {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    .icon-wrapper.jsx-393389289 {
        padding: 0.8rem;
        line-height: 0
    }

    .jsx-393389289:is(.icon-button, .single-icon.large-icon>*) {
        padding: 0.4rem
    }

    .icon-button.jsx-393389289>*.jsx-393389289 {
        background-color: var(--color-bg-icon-default);
        color: var(--color-fg-icon-default)
    }

    .icon-button.jsx-393389289:not(.disabled):hover>*.jsx-393389289,
    .icon-button.jsx-393389289:not(.disabled):not([aria-haspopup])[aria-expanded]:hover>*.jsx-393389289 {
        background-color: var(--color-bg-icon-hover)
    }

    .icon-button.jsx-393389289:not(.disabled):active>*.jsx-393389289,
    .icon-button.jsx-393389289:not(.disabled):is([aria-haspopup][aria-expanded="true"], [aria-haspopup][aria-expanded="true"]:hover, :not([aria-haspopup])[aria-expanded]:active)>*.jsx-393389289 {
        background-color: var(--color-bg-icon-active)
    }

    .icon-button.jsx-393389289:not(.disabled):not([aria-haspopup])[aria-expanded]>*.jsx-393389289 {
        background-color: var(--color-bg-icon-default)
    }

    .icon-button.disabled.jsx-393389289>*.jsx-393389289 {
        background-color: var(--color-bg-icon-disabled);
        color: var(--color-fg-icon-disabled)
    }

    .single-icon.jsx-393389289,
    .expandable-icon.jsx-393389289,
    .floating-icon.jsx-393389289,
    .outlined-icon.jsx-393389289 {
        --color-bg-default: transparent;
        --color-bg-hover: transparent;
        --color-bg-active: transparent;
        --color-bg-disabled: transparent;
        --color-fg-default: transparent;
        --color-fg-disabled: transparent;
        --color-fg-icon-default: #001F1E;
        --color-bg-icon-hover: #E1F4F2;
        --color-bg-icon-active: #D0EBE7;
        --color-bg-icon-disabled: transparent;
        --color-fg-icon-disabled: #A5BBBB
    }

    .single-icon.jsx-393389289,
    .expandable-icon.jsx-393389289 {
        --color-bg-icon-default: transparent
    }

    .floating-icon.jsx-393389289,
    .outlined-icon.jsx-393389289 {
        --color-bg-icon-default: #FFFFFF
    }

    .outlined-icon.jsx-393389289 {
        --color-bg-icon-disabled: #F4F6F6
    }

    .floating-icon.jsx-393389289>*.jsx-393389289 {
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1), 0px 3px 3px -2px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
    }

    .outlined-icon.jsx-393389289>*.jsx-393389289 {
        -webkit-box-shadow: inset 0 0 0 1px #C7D6D5;
        -moz-box-shadow: inset 0 0 0 1px #C7D6D5;
        box-shadow: inset 0 0 0 1px #C7D6D5;
        color: #00807C
    }

    .jsx-393389289:is(.outlined-icon.horizontal, .outlined-icon.vertical>*) {
        padding: 0.8rem 0.4rem
    }

    .jsx-393389289:is(.outlined-icon.vertical, .outlined-icon.horizontal>*) {
        padding: 0.4rem 0.8rem
    }

    .outlined-icon.jsx-393389289:not(.disabled):hover>*.jsx-393389289 {
        -webkit-box-shadow: inset 0 0 0 1px #57C7C3;
        -moz-box-shadow: inset 0 0 0 1px #57C7C3;
        box-shadow: inset 0 0 0 1px #57C7C3
    }

    .outlined-icon.jsx-393389289:not(.disabled):is(:active, :focus)>*.jsx-393389289 {
        color: #00807C;
        background-color: var(--color-bg-icon-active);
        -webkit-box-shadow: inset 0 0 0 2px #00807C;
        -moz-box-shadow: inset 0 0 0 2px #00807C;
        box-shadow: inset 0 0 0 2px #00807C
    }

    .block.jsx-393389289 {
        width: 100%;
        text-align: center
    }

    .disabled.icon-button.jsx-393389289,
    .non-icon-button-wrapper.jsx-393389289 {
        cursor: not-allowed
    }

    .disabled.icon-button.jsx-393389289>*.jsx-393389289,
    .disabled.jsx-393389289:not(.icon-button) {
        pointer-events: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .target-container.jsx-977eed10e523113b {
        display: inherit
    }
</style>
<style type="text/css" data-styled-jsx="">
    .tippy-box.jsx-1181711484 {
        outline: none
    }

    .tippy-box[data-placement^="top"].jsx-1181711484 .tippy-arrow {
        bottom: -0.6rem;
        border-top: 0.6rem solid #FFFFFF;
        border-right: 0.6rem solid transparent;
        border-left: 0.6rem solid transparent
    }

    .tippy-box[data-placement^="right"].jsx-1181711484 .tippy-arrow {
        left: -0.6rem;
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-right: 0.6rem solid #FFFFFF
    }

    .tippy-box[data-placement^="bottom"].jsx-1181711484 .tippy-arrow {
        position: absolute;
        top: -0.6rem;
        border-right: 0.6rem solid transparent;
        border-bottom: 0.6rem solid #FFFFFF;
        border-left: 0.6rem solid transparent
    }

    .tippy-box[data-placement^="left"].jsx-1181711484 .tippy-arrow {
        right: -0.6rem;
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-left: 0.6rem solid #FFFFFF
    }
</style>
<style type="text/css" data-styled-jsx="">
    html.ReactModal__Html--open.no-scroll {
        overflow-y: hidden
    }

    body.modal-body-open {
        position: static;
        overflow-y: scroll
    }

    .ReactModalPortal {
        position: fixed;
        z-index: 99;
        height: 1px;
        width: 1px
    }

    .modal-overlay {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(11, 15, 15, 0.4);
        overflow-y: hidden;
        -webkit-transition: opacity 150ms ease-in-out;
        -moz-transition: opacity 150ms ease-in-out;
        -o-transition: opacity 150ms ease-in-out;
        transition: opacity 150ms ease-in-out
    }

    .modal-overlay.should-center {
        display: grid;
        justify-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .modal-overlay.white-overlay {
        background-color: rgba(255, 255, 255, 0.8)
    }

    .modal-overlay.before-close {
        -webkit-transition-delay: 300ms;
        -moz-transition-delay: 300ms;
        -o-transition-delay: 300ms;
        transition-delay: 300ms
    }

    .modal-content {
        -webkit-transition: opacity 300ms ease-in-out;
        -moz-transition: opacity 300ms ease-in-out;
        -o-transition: opacity 300ms ease-in-out;
        transition: opacity 300ms ease-in-out
    }

    .modal-content.after-open.top-offset {
        position: absolute;
        top: 5vh
    }

    .modal-content.after-open:not(.before-close) {
        -webkit-transition-delay: 150ms;
        -moz-transition-delay: 150ms;
        -o-transition-delay: 150ms;
        transition-delay: 150ms
    }

    .modal-overlay.after-open,
    .modal-content.after-open {
        opacity: 1
    }

    .modal-overlay,
    .modal-content,
    .modal-overlay.before-close,
    .modal-content.before-close {
        opacity: 0
    }

    .modal-content.after-open {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    .modal-content.after-open:not(.top-offset) {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    .modal-content,
    .modal-content.before-close {
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9);
        transform: scale(.9)
    }

    .modal-content.before-close {
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none
    }

    body:not(.user-is-tabbing) .modal-content:focus {
        outline: none
    }

    @media(prefers-reduced-motion:reduce) {

        .modal-content,
        .modal-overlay {
            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none
        }
    }

    @media(max-height:600px) {
        .modal-overlay.after-open.should-center {
            overflow-y: auto
        }

        .modal-content.after-open.should-center {
            position: absolute;
            top: 5vh;
            margin-bottom: 5vh;
            left: unset;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .indicator-container.jsx-2387440874 {
        margin: 0.4rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inline-actions-container.jsx-1055934235 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 4.8rem 0
    }

    .inline-buttons.jsx-1055934235,
    .header-actions-container.jsx-1055934235 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0;
        align-items: center;
        justify-content: center;
        ;
    }

    @media(min-width:960px) {
        .inline-actions-container.jsx-1055934235 {
            margin: 6.4rem 0
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .avatar-image.jsx-2900785540 {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        height: 4.0rem;
        width: 4.0rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .end-content.jsx-3372688612 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0;
        align-items: center;
        justify-content: center;
        ;
    }

    body.print .end-content.jsx-3372688612 {
        display: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .draft-preview-banner.jsx-3874758734 {
        position: absolute;
        top: 6.4rem;
        left: 0;
        width: 100%;
        z-index: 1
    }

    body.print .draft-preview-banner.jsx-3874758734 {
        display: none
    }

    body.frame {
        -webkit-touch-callout: none;
        -webkit-user-select: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .viewer-main.jsx-1283827886 {
        min-height: -webkit-calc(100vh - 6.4rem);
        min-height: -moz-calc(100vh - 6.4rem);
        min-height: calc(100vh - 6.4rem)
    }

    .viewer-main.device-preview.jsx-1283827886 {
        min-height: 0
    }

    *:focus {
        outline-color: #009e9b
    }

    body.print .viewer-main.jsx-1283827886 {
        min-height: 0
    }

    @supports(height:1svh) {
        .viewer-main.jsx-1283827886 {
            min-height: -webkit-calc(100svh - 6.4rem);
            min-height: -moz-calc(100svh - 6.4rem);
            min-height: calc(100svh - 6.4rem)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .tooltip-container.jsx-1951206448 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        color: #F2F8F7;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.017rem;
        position: relative;
        background: #354545;
        width: 100%;
        border: .1rem solid #779897;
        -webkit-border-radius: 0.2rem;
        -moz-border-radius: 0.2rem;
        border-radius: 0.2rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 32rem;
        padding: 0.2rem 0.8rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .menu-item.jsx-3315021373 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    p.jsx-3315021373 {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-margin-start: 2.4rem;
        -moz-margin-start: 2.4rem;
        margin-inline-start: 2.4rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .dropdown-menu-item.jsx-3510539111 {
        border: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        min-height: 2.4rem;
        background-color: transparent;
        position: relative;
        -webkit-transition: background-color.15s ease-out;
        -moz-transition: background-color.15s ease-out;
        -o-transition: background-color.15s ease-out;
        transition: background-color.15s ease-out;
        text-align: start;
        text-decoration: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #001F1E
    }

    .dropdown-menu-item.dangerous.jsx-3510539111 {
        color: #D43121
    }

    button.dropdown-menu-item.jsx-3510539111 {
        width: 100%
    }

    .padded.jsx-3510539111 {
        padding: 0.8rem 2.4rem;
    }

    .dropdown-menu-item.disabled.jsx-3510539111 {
        cursor: not-allowed;
        color: #A5BBBB
    }

    .dropdown-menu-item.jsx-3510539111:not(.selected):not(.disabled):not(:active):hover {
        background-color: #E1F4F2
    }

    .dropdown-menu-item.selected.jsx-3510539111,
    .dropdown-menu-item.jsx-3510539111:not(.selected):not(.disabled):active {
        color: #F2F8F7;
        background-color: #003836
    }

    .dropdown-menu-item-content.jsx-3510539111 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

    .jsx-3510539111:not(.full-width) .dropdown-menu-item-content.jsx-3510539111 {
        min-width: 18rem
    }

    .full-width.jsx-3510539111 .dropdown-menu-item-content.jsx-3510539111 {
        min-width: 100%
    }

    .dropdown-menu-item-text.jsx-3510539111 {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
    }

    .dropdown-menu-item-text.bold.jsx-3510539111 {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.013rem;
        line-height: 2.4rem;
    }

    .dropdown-menu-item-icon-wrapper.jsx-3510539111 {
        width: 1.6rem;
        height: 1.6rem;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-margin-end: 1.6rem;
        -moz-margin-end: 1.6rem;
        margin-inline-end: 1.6rem
    }

    .badge-container.jsx-3510539111 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-margin-start: 1.6rem;
        -moz-margin-start: 1.6rem;
        margin-inline-start: 1.6rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3737844888 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    svg.jsx-3737844888 {
        width: 1.6rem;
        height: 1.6rem;
        fill: #9F52AD
    }

    body.user-is-tabbing .icon.jsx-3737844888 svg.jsx-3737844888:focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    body:not(.user-is-tabbing) .icon.jsx-3737844888 svg.jsx-3737844888:focus {
        outline: none
    }

    html[lang="ar"] .ar-flip.jsx-3737844888 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-3737844888 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    div.jsx-abc55cb7c1e03d2b {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        word-break: break-word
    }
</style>
<style type="text/css" data-styled-jsx="">
    .icon.jsx-403013660 {
        -webkit-margin-start: 0;
        -moz-margin-start: 0;
        margin-inline-start: 0;
        padding: 1.2rem
    }

    .icon.small.jsx-403013660 {
        padding: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .dropdown-menu-divider.jsx-1962588329 {
        position: relative;
        padding: 0.8rem 0
    }

    .dropdown-menu-divider.jsx-1962588329::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #C7D6D5;
        opacity: .3
    }
</style>
<style type="text/css" data-styled-jsx="">
    .dropdown-menu.jsx-2377709066 {
        min-width: 21.2rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        background-color: #FFFFFF;
        -webkit-border-radius: 0.2rem;
        -moz-border-radius: 0.2rem;
        border-radius: 0.2rem;
        box-shadow: 0px 9px 12px 1px rgba(0, 0, 0, 0.1), 0px 3px 16px 2px rgba(0, 0, 0, 0.05), 0px 5px 6px -3px rgba(0, 0, 0, 0.03);
        padding: 1.6rem 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: none
    }

    .full-width.jsx-2377709066 {
        width: 100%
    }

    .limited-width.jsx-2377709066:not(.full-width) {
        max-width: 30rem
    }

    .max-height.jsx-2377709066 {
        max-height: undefined;
        overflow: auto
    }

    .disabled.jsx-2377709066 {
        cursor: not-allowed
    }
</style>
<style type="text/css" data-styled-jsx="">
    .avatar-details.jsx-256806197 {
        width: 32rem;
        max-width: 100%;
        padding: 0.8rem 2.4rem;
    }

    .avatar-details.jsx-256806197:hover {
        background-color: #E1F4F2
    }

    .anchor.jsx-256806197 {
        display: block;
        text-decoration: none;
        color: #001F1E
    }

    .content-container.jsx-256806197 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.6rem;
        align-items: center;
        justify-content: start;
        ;
    }

    .anchor-text-container.jsx-256806197 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden
    }

    .anchor-text.jsx-256806197 {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.013rem;
        line-height: 2.4rem;
    }

    .anchor-subtext.jsx-256806197 {
        color: #5B7675;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sign-out.jsx-256806197 {
        display: block;
        margin: 0 0 1.6rem -webkit-calc(4rem + 1.6rem);
        margin: 0 0 1.6rem -moz-calc(4rem + 1.6rem);
        margin: 0 0 1.6rem calc(4rem + 1.6rem);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.017rem;
        line-height: 1.6rem;
        padding: 0.8rem 0;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .dropdown-wrapper.jsx-ff327a2cf1595427 {
        width: 21.2rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .story-title.jsx-1729238887 {
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: calc(2 * 1.65 * calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1)));
    }
</style>
<style type="text/css" data-styled-jsx="">
    .single-icon.fav-btn.themed.jsx-2597802011>* {
        color: #222222;
        background-color: #ffffff
    }

    .single-icon.fav-btn.themed.jsx-2597802011:not(.disabled):hover>* {
        background-color: #ebebeb
    }

    .single-icon.fav-btn.themed.jsx-2597802011:not(.disabled):is(:active, [aria-expanded="true"], [aria-expanded="true"]:hover)>* {
        color: #ffffff;
        background-color: #222222
    }

    .single-icon.fav-btn.themed.disabled.jsx-2597802011>* {
        color: #c2c2c2
    }
</style>
<style type="text/css" data-styled-jsx="">
    .single-icon.context-menu-btn.themed.jsx-591192987>* {
        color: #222222;
        background-color: #ffffff
    }

    .single-icon.context-menu-btn.themed.jsx-591192987:not(.disabled):hover>* {
        background-color: #ebebeb
    }

    .single-icon.context-menu-btn.themed.jsx-591192987:not(.disabled):is(:active, [aria-expanded="true"], [aria-expanded="true"]:hover)>* {
        color: #ffffff;
        background-color: #222222
    }

    .single-icon.context-menu-btn.themed.disabled.jsx-591192987>* {
        color: #c2c2c2
    }
</style>
<style type="text/css" data-styled-jsx="">
    .dropdown-target.jsx-683931831 {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        height: 5.6rem;
        width: 5.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 0.6rem;
    }

    .dropdown-target.jsx-683931831:hover {
        background-color: #ebebeb
    }

    .dropdown-target.jsx-683931831:active,
    .dropdown-target[aria-expanded="true"].jsx-683931831 {
        background-color: #222222
    }

    body:not(.user-is-tabbing) .dropdown-target.jsx-683931831:focus {
        outline: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .viewer-header.jsx-3805626681 {
        position: fixed;
        top: undefined;
        left: 0rem;
        right: 0rem;
        z-index: 99;
        color: #222222;
        background-color: #ffffff;
        border-bottom: 1px solid #c2c2c2
    }

    .viewer-header.disabled.jsx-3805626681 {
        pointer-events: none
    }

    .content.jsx-3805626681 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 3.2rem;
        align-items: center;
        justify-content: space-between;
        ;
        height: -webkit-calc(6.4rem - 1px);
        height: -moz-calc(6.4rem - 1px);
        height: calc(6.4rem - 1px)
    }

    body:not(.user-is-tabbing) .viewer-header.jsx-3805626681 *:focus {
        outline: none
    }

    body.print .viewer-header.jsx-3805626681 {
        position: relative;
        color: #001C1B;
        background-color: #FFFFFF;
        padding: 0 1.6rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .header-space.jsx-3805626681 {
        height: 6.4rem
    }

    body.print .viewer-header.jsx-3805626681,
    body.print .header-space.jsx-3805626681 {
        display: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .skip-link.jsx-491196851 {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        left: 0.8rem;
        top: 0.8rem;
        z-index: 100
    }

    .skip-link.underline.jsx-491196851 {
        text-decoration: underline
    }

    .skip-link.jsx-491196851:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;

        position: absolute;
        padding: 0.8rem 1.6rem
    }

    html[dir='rtl'] .skip-link.jsx-491196851 {
        left: unset;
        right: 0.8rem
    }

    .skip-link.theme-styled.jsx-491196851 {
        color: #ffffff;
        background: #003c71
    }

    .skip-link.theme-styled.jsx-491196851:hover,
    .skip-link.theme-styled.jsx-491196851:active,
    .skip-link.theme-styled.jsx-491196851:focus {
        background-color: #003c71
    }
</style>
<style type="text/css" data-styled-jsx="">
    .story-overlay.jsx-561397911 {
        background-color: #ffffff;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        opacity: 1;
        pointer-events: none
    }

    .fade-in.jsx-561397911 {
        -webkit-animation: fadeIn 250ms ease-in;
        -moz-animation: fadeIn 250ms ease-in;
        -o-animation: fadeIn 250ms ease-in;
        animation: fadeIn 250ms ease-in
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @-o-keyframes fadeIn {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    .fade-out.jsx-561397911 {
        -webkit-animation: fadeOut 250ms ease-out;
        -moz-animation: fadeOut 250ms ease-out;
        -o-animation: fadeOut 250ms ease-out;
        animation: fadeOut 250ms ease-out
    }

    @-webkit-keyframes fadeOut {
        0% {
            opacity: 1
        }

        100% {
            opacity: 0
        }
    }

    @-moz-keyframes fadeOut {
        0% {
            opacity: 1
        }

        100% {
            opacity: 0
        }
    }

    @-o-keyframes fadeOut {
        0% {
            opacity: 1
        }

        100% {
            opacity: 0
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1
        }

        100% {
            opacity: 0
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sc-title.jsx-2d3cd4428adb0f1d {
        padding: 0.8rem 0px;
        margin-bottom: 1.6rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sc-title.jsx-884a897778ff5589,
    .sc-summary.jsx-884a897778ff5589,
    .sc-byline.jsx-884a897778ff5589 {
        padding: 0;
        overflow-wrap: break-word;
        word-wrap: break-word;

        margin-top: 0;
        -webkit-margin-start: 0;
        -moz-margin-start: 0;
        margin-inline-start: 0;
        -webkit-margin-end: 0;
        -moz-margin-end: 0;
        margin-inline-end: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sc-summary.jsx-6b2ea21b5b83d1b8 {
        padding: 0.8rem 0px
    }

    .sc-summary.stacked.jsx-6b2ea21b5b83d1b8 {
        margin-bottom: 1.6rem
    }

    @media(min-width:700px) {
        .sc-summary.stacked.jsx-6b2ea21b5b83d1b8 {
            margin-bottom: 3.2rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sc-published-date.jsx-2037423108 {
        white-space: nowrap;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        color: #ffffff;
        text-shadow: none
    }

    @media(min-width:700px) {
        .sc-published-date.jsx-2037423108 {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
        }

        .sc-published-date.viewer.is-showing-avatar.jsx-2037423108 {
            -webkit-margin-start: 0.8rem;
            -moz-margin-start: 0.8rem;
            margin-inline-start: 0.8rem
        }

        .sc-published-date.viewer.is-showing-avatar.with-divider.jsx-2037423108 {
            -webkit-margin-start: 0;
            -moz-margin-start: 0;
            margin-inline-start: 0
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sc-metadata.jsx-3616690771 {
        display: initial;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        text-align: start
    }

    .sc-metadata.with-divider.jsx-3616690771 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 0.8rem;
        margin: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .divider.jsx-3616690771 {
        width: 1px;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        background-color: #ffffff
    }

    .stacked-container.jsx-3616690771 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    @media(min-width:700px) {
        .sc-metadata.jsx-3616690771 {
            text-align: start
        }

        .sc-metadata.with-divider.jsx-3616690771 {
            gap: 1.6rem
        }
    }

    @media(min-width:960px) {
        .sc-metadata.jsx-3616690771 {
            text-align: center
        }

        .sc-metadata.with-divider.jsx-3616690771 {
            gap: 3.2rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.print .sc-title,
    .sc-title {
        color: #ffffff;
        caret-color: #ffffff;
        text-shadow: none;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(3.98rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
        text-align: start;
    }

    body.print .sc-summary,
    .sc-summary {
        color: #ffffff;
        caret-color: #ffffff;
        text-shadow: none;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        text-align: start;
    }

    .sc-byline {
        color: #ffffff;
        caret-color: #ffffff;
        text-shadow: none;
        margin-bottom: 0
    }

    .sc-byline,
    body.print .sc-byline,
    body.print .sc-published-date {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    .sc-title::-webkit-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-title:-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-title::-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-title:-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-title::-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-title::placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary::-webkit-input-placeholder,
    .sc-byline::-webkit-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary:-moz-placeholder,
    .sc-byline:-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary::-moz-placeholder,
    .sc-byline::-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary:-ms-input-placeholder,
    .sc-byline:-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary::-ms-input-placeholder,
    .sc-byline::-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .sc-summary::placeholder,
    .sc-byline::placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    @media(min-width:700px) {
        .sc-title {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(4.77rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.2;
            text-align: start;
        }

        .sc-summary {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
            text-align: start;
        }

        .sc-byline {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.65;
        }
    }

    @media(min-width:960px) {
        .sc-title {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(5.73rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.2;
            text-align: center;
        }

        .sc-summary {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
            text-align: center;
        }

        .sc-summary.inline.is-viewer {
            width: 60%
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .grid-item-lite.jsx-43199366 {
        --grid-gap-width: 0.8rem;
        --grid-edges: calc(1.6rem * 2);
        --total-grid-width: calc(100% - var(--grid-edges));
        --number-of-possible-columns: 6;
        --number-of-columns: 6;
        --number-of-leftover-columns: calc(var(--number-of-possible-columns) - var(--number-of-columns));
        width: -webkit-calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        width: -moz-calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        width: calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        -webkit-margin-start: auto;
        -moz-margin-start: auto;
        margin-inline-start: auto;
        -webkit-margin-end: auto;
        -moz-margin-end: auto;
        margin-inline-end: auto;
        position: relative
    }

    .grid-item-lite.full-width.jsx-43199366 {
        width: 100%;
        max-width: none
    }

    @media(min-width:700px) {
        .grid-item-lite.jsx-43199366 {
            --number-of-columns: 12;
            --number-of-possible-columns: 12;
            --grid-gap-width: 0.8rem
        }
    }

    @media(min-width:960px) {
        .grid-item-lite.jsx-43199366 {
            --number-of-columns: 12;
            --number-of-possible-columns: 12;
            --grid-gap-width: 1.6rem
        }

        #storymaps-root.inline-story .grid-item-lite.jsx-43199366 {
            --number-of-columns: 12
        }
    }

    @media(min-width:1280px) {
        .grid-item-lite.jsx-43199366 {
            --total-grid-width: calc(min(100% - var(--grid-edges), 1280px - var(--grid-edges)))
        }
    }

    body.basic-print .grid-item-lite.jsx-43199366 {
        --grid-edges: 0px;
        --number-of-possible-columns: 12;
        --grid-gap-width: 0.8rem;
        --number-of-columns: 10
    }

    body.basic-print .grid-item-lite.full-width.jsx-43199366 {
        max-width: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .title-panel.jsx-2146826404 {
        position: relative;
        z-index: 2;
        -webkit-margin-start: 1.6rem;
        -moz-margin-start: 1.6rem;
        margin-inline-start: 1.6rem;
        -webkit-margin-end: 1.6rem;
        -moz-margin-end: 1.6rem;
        margin-inline-end: 1.6rem;
        padding: 3.2rem 1.6rem
    }

    .vertical-position-top.jsx-2146826404 {
        margin-top: 9.6rem;
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .vertical-position-bottom.jsx-2146826404 {
        margin-bottom: 9.6rem;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .is-themed.jsx-2146826404 {
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-border-radius: 0.2rem;
        -moz-border-radius: 0.2rem;
        border-radius: 0.2rem;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1), 0px 3px 3px -2px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
    }

    .title-panel.is-editing.jsx-2146826404:not(.is-themed) {
        border: 0.2rem dashed rgba(255, 255, 255, 0.4)
    }

    .position-popover-container.jsx-2146826404 {
        position: absolute;
        left: 50%;
        z-index: 1;
        text-align: start
    }

    .position-popover-top.jsx-2146826404,
    .position-popover-middle.jsx-2146826404 {
        bottom: 0;
        -webkit-transform: translate(-50%, 50%);
        -moz-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        -o-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%)
    }

    .position-popover-bottom.jsx-2146826404 {
        top: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    @media(min-width:960px) {
        .title-panel.jsx-2146826404 {
            -webkit-margin-start: auto;
            -moz-margin-start: auto;
            margin-inline-start: auto;
            -webkit-margin-end: auto;
            -moz-margin-end: auto;
            margin-inline-end: auto
        }

        .horizontal-position-start.jsx-2146826404 {
            -webkit-margin-start: 8rem;
            -moz-margin-start: 8rem;
            margin-inline-start: 8rem
        }

        .horizontal-position-end.jsx-2146826404 {
            -webkit-margin-end: 8rem;
            -moz-margin-end: 8rem;
            margin-inline-end: 8rem
        }

        .vertical-position-middle.jsx-2146826404 {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%)
        }

        .vertical-position-middle.horizontal-position-start.jsx-2146826404 {
            left: 0%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%)
        }

        html[dir='rtl'] .vertical-position-middle.horizontal-position-start.jsx-2146826404 {
            left: unset;
            right: 0%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%)
        }

        .vertical-position-middle.horizontal-position-end.jsx-2146826404 {
            right: 0%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%)
        }

        html[dir='rtl'] .vertical-position-middle.horizontal-position-end.jsx-2146826404 {
            right: unset;
            left: 0%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%)
        }

        .size-small.jsx-2146826404 {
            width: 50%
        }

        .size-medium.jsx-2146826404 {
            width: 60%
        }

        .size-large.jsx-2146826404 {
            width: 70%
        }
    }

    body.basic-print .title-panel.jsx-2146826404 {
        text-align: center;
        width: 90%;
        padding: 2.4rem 0
    }

    body.basic-print .vertical-position-top.jsx-2146826404 {
        margin-top: 4.0rem
    }

    body.basic-print .vertical-position-bottom.jsx-2146826404 {
        margin-bottom: 4.0rem
    }

    body.basic-print .horizontal-position-start.jsx-2146826404 {
        text-align: start
    }

    body.basic-print .horizontal-position-end.jsx-2146826404 {
        text-align: end
    }
</style>
<style type="text/css" data-styled-jsx="">
    img.jsx-3352559419 {
        max-width: 100%;
        height: auto
    }

    .hidden.jsx-3352559419 {
        visibility: hidden
    }

    .placeholder.jsx-3352559419,
    .loading.jsx-3352559419 {
        opacity: 0;
        -webkit-filter: blur(20px);
        filter: blur(20px)
    }

    .placeholder-loaded.jsx-3352559419 {
        opacity: 1;
        -webkit-transition: opacity 500ms;
        -moz-transition: opacity 500ms;
        -o-transition: opacity 500ms;
        transition: opacity 500ms
    }

    .loaded.jsx-3352559419 {
        opacity: 1;
        -webkit-filter: blur(.1px);
        filter: blur(.1px);
        -webkit-transition: opacity 500ms, -webkit-filter 500ms;
        -moz-transition: opacity 500ms, filter 500ms;
        -o-transition: opacity 500ms, filter 500ms;
        transition: opacity 500ms, -webkit-filter 500ms;
        transition: opacity 500ms, filter 500ms
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-1656411247 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-1656411247 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/H1oJ0bP_09kyzyISczquB.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }


    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-1656411247 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-1656411247 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-1656411247 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .image.jsx-1134208798 {
        height: 100%;
        width: 100%
    }

    @media(min-width:576px) {
        .image.is-full-view.jsx-1134208798 {
            -o-object-fit: contain;
            object-fit: contain
        }
    }

    video.image.jsx-1134208798:not(.is-cropped, .is-fit) {
        -o-object-fit: contain;
        object-fit: contain;
        max-width: 100%;
        height: auto
    }

    .image.is-cropped.jsx-1134208798:not(.is-fit) {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%
    }

    .image.is-cropped.is-fit.jsx-1134208798 {
        -o-object-fit: contain;
        object-fit: contain;
        background-color: undefined
    }

    .image.is-fit.is-expanded.jsx-1134208798 {
        background-color: unset
    }

    body.basic-print .image.jsx-1134208798 {
        max-height: -webkit-calc(10in - 3rem);
        max-height: -moz-calc(10in - 3rem);
        max-height: calc(10in - 3rem);
        -o-object-fit: contain;
        object-fit: contain
    }

    body.print .image.jsx-1134208798 {
        background-color: #ffffff;
        ;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .image-container.is-fit.custom-styles.jsx-2522058164 {
        background-color: undefined
    }

    .image-container.custom-styles.jsx-2522058164 {
        padding: 0;
    }

    @media(min-width:700px) {
        .image-container.custom-styles.jsx-2522058164 {
            padding: 0;
        }
    }

    @media(min-width:960px) {
        .image-container.custom-styles.jsx-2522058164 {
            padding: 0;
        }
    }

    @media(min-width:1280px) {
        .image-container.custom-styles.jsx-2522058164 {
            padding: 0;
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .icon-information.jsx-3608073122 {
        --spacing: 2.4rem;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        ;
        background-color: rgba(255, 255, 255, 0.9) !important;
        border: 1px solid #c2c2c2;
        z-index: 3;
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        line-height: 0
    }

    .icon-information.jsx-3608073122 .icon {
        font-size: unset;
        font-weight: unset;
        display: block
    }

    .icon-information.jsx-3608073122 button.popover-target::after {
        --padding-for-click: -0.9rem;
        content: "";
        left: var(--padding-for-click);
        right: var(--padding-for-click);
        bottom: var(--padding-for-click);
        top: var(--padding-for-click);
        position: absolute
    }

    html[dir='rtl'] .icon-information.jsx-3608073122 {
        right: var(--spacing);
        left: unset
    }

    .icon-information.should-flip.jsx-3608073122 {
        right: var(--spacing);
        left: unset
    }

    html[dir='rtl'] .icon-information.should-flip.jsx-3608073122 {
        left: var(--spacing);
        right: unset
    }

    .icon-information.jsx-3608073122 .popover-container {
        box-shadow: 0px 9px 12px 1px rgba(0, 0, 0, 0.1), 0px 3px 16px 2px rgba(0, 0, 0, 0.05), 0px 5px 6px -3px rgba(0, 0, 0, 0.03);
    }

    .icon-information.jsx-3608073122 .popover-container a {
        color: #500000
    }

    .icon-information.jsx-3608073122,
    .icon-information.jsx-3608073122 .popover-container {
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid #c2c2c2
    }

    .icon-information.jsx-3608073122 .icon,
    .icon-information.jsx-3608073122 .popover-container {
        color: #222222
    }

    @media(max-width:960px) {
        .icon-information.jsx-3608073122 {
            --spacing: 1.6rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .media-wrapper.jsx-2439863738 {
        position: relative
    }

    .media-wrapper.fill-parent.jsx-2439863738 {
        height: 100%;
        width: 100%
    }

    .media-wrapper-children.jsx-2439863738 {
        width: 100%;
        margin: 0 auto;
        line-height: 0;
        position: relative
    }

    .media-wrapper-children.fill-parent.jsx-2439863738 {
        height: 100%
    }

    body.print .media-wrapper-children.jsx-2439863738 {
        -webkit-box-shadow: inset 0 0 2px #d4d4d4;
        -moz-box-shadow: inset 0 0 2px #d4d4d4;
        box-shadow: inset 0 0 2px #d4d4d4
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-2008215935 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-2008215935 {
        padding-bottom: 0;
        background-color: rgba(0, 0, 0, 0.1);
        ;
        ;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-2008215935 {
            aspect-ratio: 0;
            width: auto
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .media-panel.jsx-2685999826 {
        background-color: #ffffff
    }

    .media-panel.jsx-2685999826:not(.background) {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        height: 100%;
        position: relative
    }

    .media-panel.background.jsx-2685999826 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }

    .background-color.jsx-2685999826 {
        height: 100%;
        width: 100%
    }

    .mask.jsx-2685999826 {
        --gradient-position: to top;
        background-image: -webkit-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.05) 100%);
        background-image: -moz-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.05) 100%);
        background-image: -o-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.05) 100%);
        background-image: linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.05) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1
    }

    @media(min-height:1025px) {
        .mask.jsx-2685999826 {
            background-image: -webkit-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 60%);
            background-image: -moz-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 60%);
            background-image: -o-linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 60%);
            background-image: linear-gradient(var(--gradient-position), rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 60%)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    @-webkit-keyframes line {
        0% {
            opacity: 1;
            -webkit-transform: translatey(-36px);
            transform: translatey(-36px)
        }

        50% {
            opacity: 1;
            -webkit-transform: translatey(0px);
            transform: translatey(0px)
        }

        100% {
            opacity: 0;
            -webkit-transform: translatey(0px);
            transform: translatey(0px)
        }
    }

    @-moz-keyframes line {
        0% {
            opacity: 1;
            -moz-transform: translatey(-36px);
            transform: translatey(-36px)
        }

        50% {
            opacity: 1;
            -moz-transform: translatey(0px);
            transform: translatey(0px)
        }

        100% {
            opacity: 0;
            -moz-transform: translatey(0px);
            transform: translatey(0px)
        }
    }

    @-o-keyframes line {
        0% {
            opacity: 1;
            -o-transform: translatey(-36px);
            transform: translatey(-36px)
        }

        50% {
            opacity: 1;
            -o-transform: translatey(0px);
            transform: translatey(0px)
        }

        100% {
            opacity: 0;
            -o-transform: translatey(0px);
            transform: translatey(0px)
        }
    }

    @keyframes line {
        0% {
            opacity: 1;
            -webkit-transform: translatey(-36px);
            -moz-transform: translatey(-36px);
            -o-transform: translatey(-36px);
            transform: translatey(-36px)
        }

        50% {
            opacity: 1;
            -webkit-transform: translatey(0px);
            -moz-transform: translatey(0px);
            -o-transform: translatey(0px);
            transform: translatey(0px)
        }

        100% {
            opacity: 0;
            -webkit-transform: translatey(0px);
            -moz-transform: translatey(0px);
            -o-transform: translatey(0px);
            transform: translatey(0px)
        }
    }

    .icon-animating-arrow>svg>path {
        -webkit-animation: line 2s infinite;
        -moz-animation: line 2s infinite;
        -o-animation: line 2s infinite;
        animation: line 2s infinite
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-2781388330 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0
    }

    svg.jsx-2781388330 {
        width: auto;
        height: 4.0rem;
        fill: #ffffff
    }

    html[lang="ar"] .ar-flip.jsx-2781388330 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-2781388330 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .full-cover-container.jsx-191223531 {
        min-height: -webkit-calc(100vh - 6.4rem);
        min-height: -moz-calc(100vh - 6.4rem);
        min-height: calc(100vh - 6.4rem);
        position: relative;
        z-index: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .full-cover-container.top.jsx-191223531 {
        padding-bottom: 9.6rem
    }

    .full-cover-container.bottom.jsx-191223531 {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding-top: 9.6rem
    }

    .full-cover-container.middle.jsx-191223531 {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .icon-container.jsx-191223531,
    .headline-icon-container.jsx-191223531 {
        position: absolute;
        z-index: 1
    }

    .headline-icon-container.jsx-191223531 {
        bottom: 4.0rem;
        left: 50%;
        -webkit-transform: translate(-50%, 50%);
        -moz-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        -o-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%)
    }

    .icon-container.jsx-191223531 {
        bottom: 2.4rem
    }

    html:not([dir='rtl']) .icon-container.jsx-191223531 {
        left: 1.6rem
    }

    html[dir='rtl'] .icon-container.jsx-191223531 {
        right: 1.6rem
    }

    @media(min-width:960px) {
        .icon-container.jsx-191223531 {
            bottom: 3.2rem
        }

        html:not([dir='rtl']) .icon-container.jsx-191223531 {
            left: 2.4rem
        }

        html[dir='rtl'] .icon-container.jsx-191223531 {
            right: 2.4rem
        }
    }

    body.basic-print .icon-container.jsx-191223531,
    body.basic-print .headline-icon-container.jsx-191223531 {
        display: none
    }

    body.basic-print .full-cover-container.jsx-191223531 {
        min-height: 0;
        height: -webkit-calc(10in / 1.7778);
        height: -moz-calc(10in / 1.7778);
        height: calc(10in / 1.7778)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-997092493 {
        --small-and-print-margin-top: 0rem;
        --small-and-print-margin-bottom: 0rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        break-inside: avoid;
        clear: both;
    }

    .layout-wrapper.text-cursor.jsx-997092493 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-997092493 {
            margin-top: 0rem;
            margin-bottom: 0rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-997092493 {
            margin-top: 0rem;
            margin-bottom: 0rem
        }
    }

    body.basic-print .layout-wrapper.jsx-997092493 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1159706149 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    svg.jsx-1159706149 {
        width: 2.4rem;
        height: 2.4rem;
        fill: #222222
    }

    body.user-is-tabbing .icon.jsx-1159706149 svg.jsx-1159706149:focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    body:not(.user-is-tabbing) .icon.jsx-1159706149 svg.jsx-1159706149:focus {
        outline: none
    }

    html[lang="ar"] .ar-flip.jsx-1159706149 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-1159706149 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .arrow.jsx-1157198351 {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-image: -webkit-linear-gradient(to left, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: -moz-linear-gradient(to left, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: -o-linear-gradient(to left, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: linear-gradient(to left, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        padding: 0 1.6rem;
        top: 0;
        bottom: 0
    }

    .left.jsx-1157198351 {
        left: 0;
        display: none
    }

    .right.jsx-1157198351 {
        right: 0;
        display: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .icon-left.jsx-d15d0b8e8b3d2b1b {
        position: absolute;
        right: 0
    }

    .icon-right.jsx-d15d0b8e8b3d2b1b {
        position: absolute;
        left: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .page-anchor-list-item.jsx-3881477015:focus-within {
        outline: #500000 solid 2px
    }

    body:not(.user-is-tabbing) .page-anchor-list-item.jsx-3881477015:focus-within {
        outline: none
    }

    .page-anchor-link.jsx-3881477015 {
        white-space: nowrap;
        color: #222222;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        ;
        text-decoration: none;
        cursor: pointer;
        border-bottom: 1px solid transparent
    }

    .page-anchor-link.jsx-3881477015:focus {
        outline-width: 0
    }

    @media(hover:hover) {
        .page-anchor-link.jsx-3881477015:hover {
            border-bottom: 1px solid #500000
        }
    }

    [aria-current="true"].page-anchor-link.jsx-3881477015 {
        border-bottom: 2px solid #500000
    }
</style>
<style type="text/css" data-styled-jsx="">
    .arrow.jsx-134091668 {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-image: -webkit-linear-gradient(to right, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: -moz-linear-gradient(to right, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: -o-linear-gradient(to right, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        background-image: linear-gradient(to right, rgba(245, 245, 245, 0.4), #ebebeb 100%);
        padding: 0 1.6rem;
        top: 0;
        bottom: 0
    }

    .left.jsx-134091668 {
        left: 0;
        display: none
    }

    .right.jsx-134091668 {
        right: 0;
        display: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .bar-container.jsx-862068705 {
        height: 6.4rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .content.jsx-862068705 {
        width: 100%;
        height: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 3.2rem;
        align-items: center;
        justify-content: center;
        ;
        margin: 0 8.4rem;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none
    }

    .content.jsx-862068705::-webkit-scrollbar {
        display: none;
        width: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .navigation-viewer.jsx-3664291679 {
        background-color: #f5f5f5;
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        ;
        border-bottom: 1px solid #ebebeb;
        color: #222222
    }

    body.print .navigation-viewer.jsx-3664291679 {
        display: none
    }

    .layout-wrapper.navigation-bar {
        position: -webkit-sticky;
        position: sticky;
        top: 6.4rem;
        z-index: 13
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-342868882 {
        --small-and-print-margin-top: 0rem;
        --small-and-print-margin-bottom: 3.2rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        break-inside: avoid;
        clear: both;
    }

    .layout-wrapper.text-cursor.jsx-342868882 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-342868882 {
            margin-top: 0rem;
            margin-bottom: 3.2rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-342868882 {
            margin-top: 0rem;
            margin-bottom: 3.2rem
        }
    }

    body.basic-print .layout-wrapper.jsx-342868882 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    p.jsx-3865404721 {
        --font-weight-bold: 700;
        --font-style-italic: italic
    }
</style>
<style type="text/css" data-styled-jsx="">
    p.jsx-1871000441 {
        color: #222222;
        caret-color: #222222;
        font-weight: 400;
        white-space: pre-wrap;
        text-align: start
    }

    p.large.jsx-1871000441,
    p.large.jsx-1871000441 .placeholder,
    body.basic-print p.large.jsx-1871000441 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    body.basic-print p.jsx-1871000441 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    body.print p.jsx-1871000441 {
        color: #001C1B
    }

    body.basic-print p.block-caption.jsx-1871000441 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    p.jsx-1871000441,
    p.jsx-1871000441 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    p.block-caption.jsx-1871000441,
    p.block-caption.jsx-1871000441 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    p.briefing.large.jsx-1871000441,
    body.print p.briefing.large.jsx-1871000441,
    p.briefing.large.jsx-1871000441 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    p.briefing.medium.jsx-1871000441,
    body.print p.briefing.medium.jsx-1871000441,
    p.briefing.medium.jsx-1871000441 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    p.briefing.small.jsx-1871000441,
    body.print p.briefing.small.jsx-1871000441,
    p.briefing.small.jsx-1871000441 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    @media screen and (min-width:700px) {

        p.responsive.jsx-1871000441,
        p.responsive.jsx-1871000441 .placeholder,
        body.basic-print p.responsive.jsx-1871000441 {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
        }

        p.block-caption.responsive.jsx-1871000441,
        p.block-caption.responsive.jsx-1871000441 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
        }

        p.responsive.large.jsx-1871000441,
        p.responsive.large.jsx-1871000441 .placeholder,
        body.basic-print p.responsive.large.jsx-1871000441 {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .text-viewer.jsx-2727493191 {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .grid-item-lite.jsx-1732939524 {
        --grid-gap-width: 0.8rem;
        --grid-edges: calc(1.6rem * 2);
        --total-grid-width: calc(100% - var(--grid-edges));
        --number-of-possible-columns: 6;
        --number-of-columns: 6;
        --number-of-leftover-columns: calc(var(--number-of-possible-columns) - var(--number-of-columns));
        width: -webkit-calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        width: -moz-calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        width: calc(var(--total-grid-width)*(var(--number-of-columns)/var(--number-of-possible-columns)) - (var(--number-of-leftover-columns)/var(--number-of-possible-columns))*var(--grid-gap-width));
        -webkit-margin-start: auto;
        -moz-margin-start: auto;
        margin-inline-start: auto;
        -webkit-margin-end: auto;
        -moz-margin-end: auto;
        margin-inline-end: auto;
        position: relative
    }

    .grid-item-lite.full-width.jsx-1732939524 {
        width: 100%;
        max-width: none
    }

    @media(min-width:700px) {
        .grid-item-lite.jsx-1732939524 {
            --number-of-columns: 12;
            --number-of-possible-columns: 12;
            --grid-gap-width: 0.8rem
        }
    }

    @media(min-width:960px) {
        .grid-item-lite.jsx-1732939524 {
            --number-of-columns: 8;
            --number-of-possible-columns: 12;
            --grid-gap-width: 1.6rem
        }

        #storymaps-root.inline-story .grid-item-lite.jsx-1732939524 {
            --number-of-columns: 12
        }
    }

    @media(min-width:1280px) {
        .grid-item-lite.jsx-1732939524 {
            --total-grid-width: calc(min(100% - var(--grid-edges), 1280px - var(--grid-edges)))
        }
    }

    body.basic-print .grid-item-lite.jsx-1732939524 {
        --grid-edges: 0px;
        --number-of-possible-columns: 12;
        --grid-gap-width: 0.8rem;
        --number-of-columns: 8
    }

    body.basic-print .grid-item-lite.full-width.jsx-1732939524 {
        max-width: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-690966242 {
        --small-and-print-margin-top: 0.8rem;
        --small-and-print-margin-bottom: 2.4rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        clear: none;
    }

    .layout-wrapper.text-cursor.jsx-690966242 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-690966242 {
            margin-top: 1.6rem;
            margin-bottom: 3.2rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-690966242 {
            margin-top: 1.6rem;
            margin-bottom: 3.2rem
        }
    }

    body.basic-print .layout-wrapper.jsx-690966242 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-232369701 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    svg.jsx-232369701 {
        width: 1.6rem;
        height: 1.6rem;
        fill: #222222
    }

    body.user-is-tabbing .icon.jsx-232369701 svg.jsx-232369701:focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    body:not(.user-is-tabbing) .icon.jsx-232369701 svg.jsx-232369701:focus {
        outline: none
    }

    html[lang="ar"] .ar-flip.jsx-232369701 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-232369701 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .heading.jsx-3814754407 {
        --font-weight-bold: 700;
        --font-style-italic: italic
    }
</style>
<style type="text/css" data-styled-jsx="">
    h2.jsx-315162382,
    h3.jsx-315162382,
    h4.jsx-315162382 {
        color: #222222;
        caret-color: #222222;
    }

    h2.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(3.98rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
    }

    h2.briefing.jsx-315162382,
    body.print h2.briefing.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(3.98rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
    }

    h2.frame.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    h3.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.4;
    }

    h3.briefing.jsx-315162382,
    body.print h3.briefing.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(3.31rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.4;
    }

    h3.frame.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    h4.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    h4.briefing.jsx-315162382,
    body.print h4.briefing.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.4;
    }

    h4.frame.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    .responsive.jsx-315162382,
    .responsive.jsx-315162382 .placeholder {
        text-align: start;
        white-space: pre-wrap
    }

    h2.responsive.jsx-315162382,
    h2.responsive.jsx-315162382 .placeholder,
    body.basic-print h2.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.4;
    }

    h3.responsive.jsx-315162382,
    h3.responsive.jsx-315162382 .placeholder,
    body.basic-print h3.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    h4.responsive.jsx-315162382,
    h4.responsive.jsx-315162382 .placeholder,
    body.basic-print h4.jsx-315162382 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 700;
        font-style: normal;
        line-height: 1.65;
    }

    body.print .heading.jsx-315162382 {
        color: #001C1B
    }

    @media screen and (min-width:700px) {

        h2.responsive.jsx-315162382,
        h2.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(3.31rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.4;
        }

        h3.responsive.jsx-315162382,
        h3.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.4;
        }

        h4.responsive.jsx-315162382,
        h4.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.65;
        }
    }

    @media screen and (min-width:960px) {

        h2.responsive.jsx-315162382,
        h2.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(3.98rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.2;
        }

        h3.responsive.jsx-315162382,
        h3.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.4;
        }

        h4.responsive.jsx-315162382,
        h4.responsive.jsx-315162382 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 700;
            font-style: normal;
            line-height: 1.65;
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .link-spacer-container.jsx-375145049 {
        display: flow-root;
        position: relative
    }

    .link-spacer.jsx-375145049 {
        position: absolute;
        top: 0.4rem;
        left: 0;
        -webkit-transform: translatex(-100%);
        -moz-transform: translatex(-100%);
        -ms-transform: translatex(-100%);
        -o-transform: translatex(-100%);
        transform: translatex(-100%);
        z-index: 2;
        -webkit-padding-end: 0.8rem;
        -moz-padding-end: 0.8rem;
        padding-inline-end: 0.8rem
    }

    html[dir='rtl'] .link-spacer.jsx-375145049 {
        left: unset;
        right: 0;
        -webkit-transform: translatex(100%);
        -moz-transform: translatex(100%);
        -ms-transform: translatex(100%);
        -o-transform: translatex(100%);
        transform: translatex(100%)
    }

    .link-button.jsx-375145049 {
        --inner-padding: 0.8rem;
        position: relative;
        -webkit-transition: -webkit-transform.2s ease-in-out, opacity.2s ease-in-out;
        -moz-transition: -moz-transform.2s ease-in-out, opacity.2s ease-in-out;
        -o-transition: -o-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -webkit-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -moz-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -o-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: transform.2s ease-in-out, opacity.2s ease-in-out;
        -webkit-transform: translatex(25%);
        -moz-transform: translatex(25%);
        -ms-transform: translatex(25%);
        -o-transform: translatex(25%);
        transform: translatex(25%);
        opacity: 0;
        line-height: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        padding: var(--inner-padding);
        margin: 0.4rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: #ffffff
    }

    html[dir='rtl'] .link-button.jsx-375145049 {
        -webkit-transform: translatex(-25%);
        -moz-transform: translatex(-25%);
        -ms-transform: translatex(-25%);
        -o-transform: translatex(-25%);
        transform: translatex(-25%)
    }

    .link-button.flashy.jsx-375145049 {
        --border-width: 0.1rem;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px -1px rgba(0, 0, 0, 0.03);
        border: var(--border-width) solid transparent;
        padding: -webkit-calc(var(--inner-padding) - var(--border-width));
        padding: -moz-calc(var(--inner-padding) - var(--border-width));
        padding: calc(var(--inner-padding) - var(--border-width))
    }

    .link-button.jsx-375145049:hover,
    .link-button.jsx-375145049:focus {
        background-color: #ebebeb
    }

    .link-button.flashy.jsx-375145049:hover,
    .link-button.flashy.jsx-375145049:focus {
        border-color: #c2c2c2
    }

    .text-container.jsx-375145049:hover .link-button.jsx-375145049,
    .link-spacer.jsx-375145049:hover .link-button.jsx-375145049,
    .user-is-tabbing .link-button.jsx-375145049:focus,
    html[dir='rtl'] .user-is-tabbing .link-button.jsx-375145049:focus {
        -webkit-transform: translatex(0%);
        -moz-transform: translatex(0%);
        -ms-transform: translatex(0%);
        -o-transform: translatex(0%);
        transform: translatex(0%);
        opacity: 1
    }

    .user-is-tabbing .link-button.jsx-375145049:focus {
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none
    }

    .link-button.jsx-375145049::before {
        content: "";
        position: absolute;
        top: -0.4rem;
        left: -0.4rem;
        bottom: -0.4rem;
        right: -0.4rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-3036348328 {
        --small-and-print-margin-top: 4.8rem;
        --small-and-print-margin-bottom: 0.8rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        clear: none;
    }

    .layout-wrapper.text-cursor.jsx-3036348328 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-3036348328 {
            margin-top: 6.4rem;
            margin-bottom: 1.6rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-3036348328 {
            margin-top: 6.4rem;
            margin-bottom: 1.6rem
        }
    }

    body.basic-print .layout-wrapper.jsx-3036348328 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-964098143 {
        color: #011110
    }
</style>
<style type="text/css" data-styled-jsx="">
    em.jsx-3ea0a99d3ffd0d56 {
        font-style: var(--font-style-italic, italic)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-4032175127 {
        color: #808080
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1848375421 {
        color: #575050
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3349735503 {
        color: #030d07
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3115930760 {
        color: #1b7344
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3842211387 {
        color: #0F994F
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1657040797 {
        color: #010f0e
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-427649609 {
        color: #040d06
    }
</style>
<style type="text/css" data-styled-jsx="">
    li.jsx-4024294980 {
        --font-weight-bold: 700;
        --font-style-italic: italic
    }
</style>
<style type="text/css" data-styled-jsx="">
    li.jsx-3057927435 {
        white-space: pre-wrap;
        text-align: start
    }

    .text-editor li.jsx-3057927435 span[data-slate-placeholder="true"] {
        display: none !important
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-2243148830 {
        color: #032220
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1376378763 {
        color: #04B938
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3548323868 {
        color: #011614
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3253138633 {
        color: #030c06
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-2292627359 {
        color: #022220
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-256549535 {
        color: #021614
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1033068395 {
        color: #03100f
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1183824233 {
        color: #01100f
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1557423967 {
        color: #011514
    }
</style>
<style type="text/css" data-styled-jsx="">
    .generic-list.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        color: #222222;
        caret-color: #222222;

        position: relative;
        left: 1.15em;
        width: -webkit-calc(100% - 1.15em);
        width: -moz-calc(100% - 1.15em);
        width: calc(100% - 1.15em)
    }

    .generic-list.large.jsx-1201566770,
    .generic-list.large.jsx-1201566770 span[data-slate-placeholder="true"] {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    html[dir='rtl'] .generic-list.jsx-1201566770 {
        left: unset;
        right: 1.15em
    }

    body.basic-print .generic-list.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    body.print .generic-list.jsx-1201566770 {
        color: #001C1B
    }

    .generic-list.briefing.large.jsx-1201566770,
    body.print .generic-list.briefing.large.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    .generic-list.briefing.medium.jsx-1201566770,
    body.print .generic-list.briefing.medium.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(2.30rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
    }

    .generic-list.briefing.small.jsx-1201566770,
    body.print .generic-list.briefing.small.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    .generic-list.block-caption.jsx-1201566770,
    body.print .generic-list.block-caption.jsx-1201566770 {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    @media screen and (min-width:700px) {
        .generic-list.responsive.jsx-1201566770 {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.92rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
        }

        .generic-list.block-caption.responsive.jsx-1201566770 {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
        }

        .generic-list.large.responsive.jsx-1201566770,
        .generic-list.large.responsive.jsx-1201566770 span[data-slate-placeholder="true"] {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(2.76rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.4;
        }
    }

    .bullet-list.jsx-1201566770>li {
        list-style-type: disc
    }

    .bullet-list.jsx-1201566770>li>.bullet-list li,
    .bullet-list.jsx-1201566770>.bullet-list li {
        list-style-type: circle
    }

    .numbered-list.jsx-1201566770>li {
        list-style-type: decimal
    }

    .numbered-list.jsx-1201566770>li>.numbered-list li,
    .numbered-list.jsx-1201566770>.numbered-list li {
        list-style-type: lower-latin
    }

    :is(.float-layout.end + .layout-wrapper:not(.float-layout) > .grid-item-lite, .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite,
        .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite,
        .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite) .generic-list.jsx-1201566770 {
        position: static;
        left: unset;
        -webkit-padding-start: 1.15em;
        -moz-padding-start: 1.15em;
        padding-inline-start: 1.15em;
        width: unset
    }

    html[dir='rtl'] :is(.float-layout.end + .layout-wrapper:not(.float-layout) > .grid-item-lite, .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite,
        .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite,
        .float-layout.end + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) + .layout-wrapper:not(.float-layout) > .grid-item-lite) .generic-list.jsx-1201566770 {
        right: unset
    }
</style>
<style type="text/css" data-styled-jsx="">
    .separator-wrapper.jsx-2505901888 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: flex-start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    html[dir='rtl'] .separator-wrapper.jsx-2505901888 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -moz-box-orient: horizontal;
        -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .separator.jsx-2505901888 {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        width: 100%;
        margin: 0
    }

    .line-single.jsx-2505901888 {
        width: 40%;
        display: block;
        height: .1rem;
        background-color: #500000
    }

    .line-double.jsx-2505901888 {
        width: 40%;
        padding-top: 0.4rem;
        border-top: 0.4rem solid #500000;
        border-bottom: 0.2rem solid #500000
    }

    .diamonds-triple.jsx-2505901888 {
        --color: #500000;
        --size: 0.8rem;
        --offset: calc(1.75 * var(--size));
        --negOffset: calc(-1 * var(--offset));
        height: var(--size);
        width: var(--size);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: var(--color);
        -webkit-box-shadow: var(--negOffset)var(--offset)0 0 var(--color), var(--offset)var(--negOffset)0 0 var(--color);
        -moz-box-shadow: var(--negOffset)var(--offset)0 0 var(--color), var(--offset)var(--negOffset)0 0 var(--color);
        box-shadow: var(--negOffset)var(--offset)0 0 var(--color), var(--offset)var(--negOffset)0 0 var(--color);
        margin: 0 -webkit-calc(var(--offset) + var(--size));
        margin: 0 -moz-calc(var(--offset) + var(--size));
        margin: 0 calc(var(--offset) + var(--size))
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-1305723053 {
        --small-and-print-margin-top: 3.2rem;
        --small-and-print-margin-bottom: 3.2rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        break-inside: avoid;
        clear: both;
    }

    .layout-wrapper.text-cursor.jsx-1305723053 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-1305723053 {
            margin-top: 6.4rem;
            margin-bottom: 6.4rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-1305723053 {
            margin-top: 6.4rem;
            margin-bottom: 6.4rem
        }
    }

    body.basic-print .layout-wrapper.jsx-1305723053 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .number-part.jsx-1724752384 {
        line-height: 1;
        color: #3d6665;
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        line-height: 1.8rem;
        ;
    }

    .number-part.jsx-1724752384:not(.integrated) {
        color: #FFFFFF
    }

    .nav-numbers.jsx-1724752384 {
        display: grid;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0;
        grid-gap: 0.8rem;
        padding: 0.8rem;
        pointer-events: auto
    }

    .nav-numbers.jsx-1724752384:not(.integrated) {
        background-color: rgba(0, 0, 0, 0.3);
        pointer-events: auto;
        -webkit-transition: background-color.15s;
        -moz-transition: background-color.15s;
        -o-transition: background-color.15s;
        transition: background-color.15s
    }

    .nav-numbers.is-using-expanded-nav-numbers.jsx-1724752384:hover {
        background-color: #f5f5f5;
        cursor: pointer
    }

    .nav-numbers.vertical.jsx-1724752384 {
        grid-auto-flow: row;
        margin-bottom: 3.2rem;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        border-radius: 1.6rem
    }

    .nav-numbers.vertical.is-using-expanded-nav-numbers.jsx-1724752384 {
        margin-bottom: 0.2rem;
        -webkit-border-radius: unset;
        -moz-border-radius: unset;
        border-radius: unset
    }

    .nav-numbers.horizontal.jsx-1724752384 {
        grid-auto-flow: column
    }

    .user-is-tabbing .nav-numbers.jsx-1724752384:not(.integrated):focus-within {
        background-color: rgba(0, 0, 0, 0.6)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .nav-dots-container.jsx-3487873800 {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: 2;
        pointer-events: none
    }

    .nav-dots-wrapper.horizontal.jsx-3487873800 .arrow-button {
        line-height: 0
    }

    .nav-dots-container.no-show.jsx-3487873800,
    .modal-body-open .nav-dots-container.jsx-3487873800 {
        display: none
    }

    .nav-dots-wrapper.jsx-3487873800 {
        height: var(--height);
        position: -webkit-sticky;
        position: sticky;
        top: -webkit-calc(100vh - var(--height) - 0rem);
        top: -moz-calc(100vh - var(--height) - 0rem);
        top: calc(100vh - var(--height) - 0rem);
        z-index: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex
    }

    .nav-dots-wrapper.vertical.jsx-3487873800 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .nav-dots-wrapper.horizontal.jsx-3487873800 {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -moz-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
</style>
<style type="text/css" data-styled-jsx="">
    .core-map.jsx-3938492037 {
        height: 100%;
        width: 100%;
        z-index: 0;
        position: relative
    }

    .error-container.jsx-3938492037 {
        display: grid;
        grid-template-rows: 1fr auto;
        padding: 1.6rem;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
        background-color: #f5f5f5;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }

    .error-icon-container.jsx-3938492037 {
        margin: 1.6rem
    }

    .error-center-content.jsx-3938492037 {
        display: grid;
        gap: 0.8rem;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center
    }

    .error-title.jsx-3938492037 {
        color: #222222;
        font-size: 2.4rem;
        font-weight: 400;
        letter-spacing: -0.05rem;
        line-height: 3.2rem;
    }

    .error-description.jsx-3938492037 {
        color: #3d6665;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
    }

    .error-copy-button.jsx-3938492037 {
        color: #001F1E;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.017rem;
        line-height: 1.6rem;
    }

    .copy-icon-container.jsx-3938492037 {
        padding: 0 0.8rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    #tour-map-displayer-n-MJiD0R .esri-ui-corner .esri-component {
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1), 0px 3px 3px -2px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
    }

    #tour-map-displayer-n-MJiD0R .esri-attribution.esri-widget {
        background-color: rgba(255, 255, 255, 0.5);
        color: rgba(34, 34, 34, 0.7)
    }
</style>
<style type="text/css" data-styled-jsx="">
    body:not(.user-is-tabbing) .esri-view *:focus::after {
        outline: none
    }

    .esri-view-user-storage div.legend-icon-container svg {
        fill: #222222
    }

    .esri-view-user-storage div.legend-icon-container {
        background-color: #ffffff
    }

    .esri-ui .esri-widget--button,
    .esri-ui .esri-widget.esri-widget--button,
    .esri-ui .esri-expand__container *,
    .esri-ui .esri-expand__container *:focus,
    .esri-ui .esri-widget--button.esri-widget--button-active:focus,
    .esri-ui .esri-menu .esri-menu__header {
        background-color: #ffffff;
        color: #222222
    }

    .esri-ui .esri-widget--button:hover,
    .esri-ui .esri-expand__container *:not(.esri-menu__header, .esri-icon-search, .esri-collapse__icon, .esri-icon-down-arrow, .esri-icon-close):hover {
        background-color: #f5f5f5;
        color: #222222
    }

    .esri-ui .esri-widget--button {
        --calcite-color-text-1: #222222;
        --calcite-color-text-3: #222222;
        --calcite-color-foreground-1: transparent;
        --calcite-color-foreground-2: transparent;
        --calcite-color-foreground-3: transparent;
        --calcite-color-brand: transparent;
        --calcite-color-border-1: transparent;
        --calcite-opacity-disabled: 1
    }

    .user-is-tabbing .esri-ui .esri-widget--button:focus {
        outline: #500000 auto 1px
    }

    .esri-ui .esri-widget {
        --calcite-font-size-0: 1.6rem;
        --calcite-font-size--1: 1.2rem;
        --calcite-font-size--2: 1.2rem
    }

    .esri-ui .esri-widget--button calcite-loader {
        --calcite-loader-size-inline: 1.2rem;
        stroke: #222222
    }

    .esri-ui .esri-widget--button.esri-disabled:is(*, :hover) {
        background-color: #ffffff;
        cursor: not-allowed
    }

    .esri-widget.esri-floor-filter__button-container {
        background-color: #ffffff
    }

    .esri-widget.esri-floor-filter {
        z-index: 1
    }

    .esri-widget.esri-floor-filter~.esri-component.overview-map-container {
        z-index: 0
    }

    .esri-widget.esri-floor-filter~.esri-component.overview-map-container {
        right: 6.4rem
    }

    html[dir='rtl'] .esri-widget.esri-floor-filter~.esri-component.overview-map-container {
        left: 6.4rem;
        right: unset
    }

    .esri-ui .esri-collapse__icon,
    .esri-ui .esri-icon-down-arrow,
    .esri-ui .esri-icon-close,
    .esri-ui .esri-compass,
    .esri-ui .esri-search__suggestions-menu .esri-menu__list-item * {
        background-color: transparent
    }

    .esri-ui .esri-ui-top-left {
        top: 0.8rem;
        left: 0.8rem
    }

    html[dir='rtl'] .esri-ui .esri-ui-top-right {
        top: 0.8rem;
        right: 0.8rem
    }

    .esri-ui .esri-ui-bottom-right {
        right: 0.8rem
    }

    html[dir='rtl'] .esri-ui .esri-ui-bottom-left {
        right: unset;
        left: 0.8rem
    }

    .esri-ui .esri-widget--button,
    .esri-ui .esri-search .esri-input[type="text"] {
        height: 4rem
    }

    .esri-ui .esri-widget--button {
        width: 4rem
    }

    .esri-ui .esri-floor-filter .esri-widget--button {
        width: 100%
    }

    .esri-ui .esri-search .esri-search__submit-button,
    .esri-ui .esri-search .esri-search__suggestions-list--current-location {
        display: none
    }

    .esri-ui .esri-expand .esri-expand__content {
        margin-left: 0;
        margin-right: 0
    }

    .esri-ui .esri-expand .esri-search__form {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .esri-ui .esri-search .esri-search__form .esri-input,
    .esri-ui .esri-search .esri-search__suggestions-menu .esri-menu__header {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    .esri-ui .esri-search .esri-search__form .esri-input::-webkit-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__form .esri-input:-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__form .esri-input::-moz-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__form .esri-input:-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__form .esri-input::-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__form .esri-input::placeholder {
        color: rgba(34, 34, 34, 0.5)
    }

    .esri-ui .esri-search .esri-search__suggestions-menu .esri-search__suggestions-list>li {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    .esri-scale-bar__bar-container--line {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -moz-box-orient: horizontal;
        -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 1.6rem
    }

    .esri-scale-bar__bar-container--line:last-child {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .esri-scale-bar__label-container--line {
        position: relative;
        top: auto
    }

    .esri-scale-bar__label {
        font-weight: 400;
        padding: 0 0.4rem;
        color: rgba(34, 34, 34, 0.7)
    }

    .esri-scale-bar__line {
        height: 7px;
        background-color: transparent
    }

    .esri-scale-bar__line--top,
    .esri-scale-bar__line--bottom {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-width: 1px;
        border-color: rgba(34, 34, 34, 0.7)
    }

    .esri-scale-bar__line.esri-scale-bar__line--top:before,
    .esri-scale-bar__line.esri-scale-bar__line--top:after,
    .esri-scale-bar__line.esri-scale-bar__line--bottom:before,
    .esri-scale-bar__line.esri-scale-bar__line--bottom:after {
        width: 1px;
        background-color: transparent;
        border-width: 1px;
        border-color: rgba(34, 34, 34, 0.7);
        top: 0;
        bottom: 0;
        height: 6px
    }

    .esri-scale-bar__line.esri-scale-bar__line--top {
        top: 0;
        bottom: 0
    }

    .esri-scale-bar__line.esri-scale-bar__line--bottom {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        top: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    #tour-map-displayer-n-MJiD0R .esri-ui {
        z-index: 2
    }

    #tour-map-displayer-n-MJiD0R .esri-ui-corner {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 0.8rem;
        align-items: initial;
        justify-content: initial;
        ;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .tour-map-displayer.jsx-1bb94093ee892d1a {
        position: relative;
        height: 100%
    }

    .tour-map-displayer.jsx-1bb94093ee892d1a .esri-ui {
        left: 0 !important;
        right: 0 !important
    }
</style>
<style type="text/css" data-styled-jsx="">
    .map-wrapper.jsx-1234849363 {
        position: relative;
        height: 100%;
        background-color: #ffffff
    }

    .map-wrapper.jsx-1234849363::before {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.1)
    }

    .mask.jsx-1234849363 {
        position: absolute;
        height: 100%;
        top: 0;
        width: 100%;
        z-index: 1
    }

    .map-wrapper.jsx-1234849363 .esri-ui .esri-ui-corner-container .esri-widget.esri-home,
    .map-wrapper.jsx-1234849363 .esri-ui .esri-ui-corner-container .esri-widget.esri-zoom,
    .map-wrapper.jsx-1234849363 .esri-ui .esri-ui-corner-container .esri-widget.esri-navigation-toggle {}

    .map-wrapper.jsx-1234849363 .esri-ui .esri-ui-corner-container .esri-widget.esri-compass {}

    @media(min-width:700px) {
        .map-wrapper.jsx-1234849363 .tour-map-displayer .esri-locate {
            display: none
        }
    }

    @media print {
        body.print .map-wrapper.jsx-1234849363 .esri-ui-inner-container.esri-ui-corner-container {
            display: none
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-3655090622 {
        visibility: hidden
    }

    .full-screen-modal.jsx-3655090622 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-3655090622 {
        cursor: initial
    }

    .placeholder-image.jsx-3655090622 {
        max-height: -webkit-calc(100% - 0px);
        max-height: -moz-calc(100% - 0px);
        max-height: calc(100% - 0px)
    }

    .expanded-caption-container.jsx-3655090622 figcaption {
        width: initial;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .expanded-caption-container.jsx-3794216292 figcaption {
        cursor: text
    }

    .full-screen-modal.jsx-3794216292 {
        position: fixed;
        -webkit-transition: background-color.3s ease-in-out;
        -moz-transition: background-color.3s ease-in-out;
        -o-transition: background-color.3s ease-in-out;
        transition: background-color.3s ease-in-out;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 6.4rem
    }

    figure.jsx-3794216292 {
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ;
    }

    .placeholder-image.jsx-3794216292 {
        max-width: 100%
    }

    .header.jsx-3794216292 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 6.4rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 2.4rem;
        -webkit-box-pack: flex-end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: flex-end;
        -ms-flex-pack: flex-end;
        justify-content: flex-end
    }

    .end-items.jsx-3794216292 {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.6rem;
        align-items: center;
        justify-content: initial;
        ;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .nav-dot.jsx-3929248709 {
        pointer-events: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .dot-button.jsx-3929248709 {
        height: .8rem;
        width: .8rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%
    }

    .dot-button.integrated.jsx-3929248709 {
        background-color: #5B7675
    }

    .dot-button.jsx-3929248709:not(.integrated) {
        background-color: #A9B4B3;
        border: 1px solid transparent
    }

    body:not(.user-is-tabbing) .dot-button.jsx-3929248709:focus {
        outline: none
    }

    .dot-button.integrated.jsx-3929248709:hover {
        background-color: #5B7675
    }

    .dot-button.jsx-3929248709:not(.integrated):hover {
        border-color: #F2F8F7
    }

    .nav-dot.selected.jsx-3929248709 .dot-button.integrated.jsx-3929248709 {
        background-color: #500000
    }

    .nav-dot.selected.jsx-3929248709 .dot-button.jsx-3929248709:not(.integrated) {
        background-color: #F2F8F7
    }
</style>
<style type="text/css" data-styled-jsx="">
    .nav-dots.jsx-3457483899 {
        display: grid;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0
    }

    .nav-dots.jsx-3457483899:not(.integrated) {
        background-color: rgba(0, 0, 0, 0.3)
    }

    .nav-dots.jsx-3457483899 {
        grid-gap: 1.6rem
    }

    .nav-dots.vertical.jsx-3457483899 {
        grid-auto-flow: row;
        margin-bottom: 3.2rem
    }

    .nav-dots.horizontal.jsx-3457483899 {
        grid-auto-flow: column
    }

    .nav-dots.jsx-3457483899:not(.integrated) {
        pointer-events: auto;
        -webkit-transition: background-color.15s;
        -moz-transition: background-color.15s;
        -o-transition: background-color.15s;
        transition: background-color.15s;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        border-radius: 1.6rem
    }

    .nav-dots.jsx-3457483899:not(.integrated):hover {
        background-color: rgba(0, 0, 0, 0.6)
    }

    .user-is-tabbing .nav-dots.jsx-3457483899:not(.integrated):focus-within {
        background-color: rgba(0, 0, 0, 0.6)
    }

    .nav-dots.vertical.jsx-3457483899:not(.integrated) {
        width: 3.2rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem
    }

    .nav-dots.horizontal.jsx-3457483899:not(.integrated) {
        height: 3.2rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .nav-dots-container.jsx-4035823098 {
        display: block;
        --height: calc(35rem + 0px);
        top: auto
    }

    .nav-dots-wrapper.jsx-4035823098 {
        padding: 0 3.2rem 0px
    }

    .nav-dots-wrapper.vertical.jsx-4035823098 {
        -webkit-box-align: flex-start;
        -webkit-align-items: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start
    }

    .nav-dots-wrapper.horizontal.jsx-4035823098 {
        padding-bottom: 1.6rem
    }

    @media(min-width:960px) {
        .nav-dots-wrapper.horizontal.jsx-4035823098 {
            padding-bottom: 2.4rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-2557655573 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-2557655573 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/jZlNV7t-LRtzsrEy1pH0X.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-2557655573 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-2557655573 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-2557655573 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .image.jsx-3969215229 {
        height: 100%;
        width: 100%
    }

    @media(min-width:576px) {
        .image.is-full-view.jsx-3969215229 {
            -o-object-fit: contain;
            object-fit: contain
        }
    }

    video.image.jsx-3969215229:not(.is-cropped, .is-fit) {
        -o-object-fit: contain;
        object-fit: contain;
        max-width: 100%;
        height: auto
    }

    .image.is-cropped.jsx-3969215229:not(.is-fit) {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%
    }

    .image.is-cropped.is-fit.jsx-3969215229 {
        -o-object-fit: contain;
        object-fit: contain;
        background-color: #ffffff
    }

    .image.is-fit.is-expanded.jsx-3969215229 {
        background-color: unset
    }

    body.basic-print .image.jsx-3969215229 {
        max-height: -webkit-calc(10in - 3rem);
        max-height: -moz-calc(10in - 3rem);
        max-height: calc(10in - 3rem);
        -o-object-fit: contain;
        object-fit: contain
    }

    body.print .image.jsx-3969215229 {
        background-color: #ffffff;
        ;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .image-container.is-fit.custom-styles.jsx-1220048055 {
        background-color: #ffffff
    }

    .image-container.custom-styles.jsx-1220048055 {
        padding: 0;
    }

    @media(min-width:700px) {
        .image-container.custom-styles.jsx-1220048055 {
            padding: 0;
        }
    }

    @media(min-width:960px) {
        .image-container.custom-styles.jsx-1220048055 {
            padding: 0;
        }
    }

    @media(min-width:1280px) {
        .image-container.custom-styles.jsx-1220048055 {
            padding: 0;
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .pageable-item.jsx-4149835010 {
        position: relative;
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        width: 100%;
        height: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: auto
    }

    body.print .pageable-item.jsx-4149835010 {
        visibility: visible;
        opacity: 1
    }
</style>
<style type="text/css" data-styled-jsx="">
    .container.jsx-2615453098 {
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: auto;
        pointer-events: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .nav-container.jsx-1564581120 {
        grid-column: 1/2;
        grid-row: 1/2;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }

    .nav-container.expanded.jsx-1564581120 {
        position: fixed;
        width: -webkit-calc(100vw - 0.8rem);
        width: -moz-calc(100vw - 0.8rem);
        width: calc(100vw - 0.8rem);
        height: 100vh
    }

    .nav-arrow-container.jsx-1564581120 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .nav-arrow-container.first-item.jsx-1564581120 {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .carousel.jsx-1564581120 {
        display: grid;
        position: relative
    }

    .carousel.fill-parent.jsx-1564581120 {
        height: 100%;
        width: 100%;
        grid-template-rows: minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .reparent-wrapper.jsx-2575393672 {
        cursor: zoom-in
    }
</style>
<style type="text/css" data-styled-jsx="">
    .parent.jsx-1966727344 {
        width: 100%;
        height: 100%
    }

    .reparent-wrapper.jsx-1966727344 {
        width: 100%;
        height: 100%;
        -webkit-transition: all 150ms ease-out;
        -moz-transition: all 150ms ease-out;
        -o-transition: all 150ms ease-out;
        transition: all 150ms ease-out;
        -webkit-transition-property: top, left, width, height;
        -moz-transition-timing-function: top, left, width, height;
        -o-transition-timing-function: top, left, width, height;
        transition-property: top, left, width, height
    }
</style>
<style type="text/css" data-styled-jsx="">
    @media(min-width:960px) {
        .modal-body-open .container.partial-screen.docked.jsx-809857508 {
            position: fixed;
            width: -webkit-calc((100vw - 0rem) * (1 - 0.4));
            width: -moz-calc((100vw - 0rem) * (1 - 0.4));
            width: calc((100vw - 0rem) * (1 - 0.4));
            max-width: calc(100vw - 0rem - 41rem);
            min-width: calc(100vw - 0rem - 56rem)
        }

        html:not([dir='rtl']) .modal-body-open .container.partial-screen.docked.jsx-809857508:not(.end) {
            right: 0rem
        }

        html:not([dir='rtl']) .modal-body-open .container.partial-screen.docked.end.jsx-809857508 {
            left: 0rem
        }

        html[dir='rtl'] .modal-body-open .container.partial-screen.docked.jsx-809857508:not(.end) {
            left: 0rem
        }

        html[dir='rtl'] .modal-body-open .container.partial-screen.docked.end.jsx-809857508 {
            right: 0rem
        }
    }

    @media(min-width:1280px) {
        .modal-body-open .container.partial-screen.docked.jsx-809857508 {
            width: -webkit-calc((100vw - 0rem) * (1 - 0.33));
            width: -moz-calc((100vw - 0rem) * (1 - 0.33));
            width: calc((100vw - 0rem) * (1 - 0.33))
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .landmark.jsx-253267522 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        z-index: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-3728417570 {
        --small-and-print-margin-top: 2.4rem;
        --small-and-print-margin-bottom: 0.8rem;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        clear: none;
    }

    .layout-wrapper.text-cursor.jsx-3728417570 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-3728417570 {
            margin-top: 3.2rem;
            margin-bottom: 1.6rem
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-3728417570 {
            margin-top: 3.2rem;
            margin-bottom: 1.6rem
        }
    }

    body.basic-print .layout-wrapper.jsx-3728417570 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1063714973 {
        color: #010b0a
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-64242908 {
        color: #021514
    }
</style>
<style type="text/css" data-styled-jsx="">
    @media(min-width:960px) {
        body:not(.print) div.jsx-1878338049 {
            min-width: 41rem;
            max-width: 56rem;
            width: -webkit-calc((100vw - 0rem) * 0.4);
            width: -moz-calc((100vw - 0rem) * 0.4);
            width: calc((100vw - 0rem) * 0.4);
            grid-column: 1 / 2
        }
    }

    @media(min-width:1280px) {
        body:not(.print) div.jsx-1878338049 {
            min-width: auto;
            max-width: auto;
            width: -webkit-calc((100vw - 0rem) * 0.33);
            width: -moz-calc((100vw - 0rem) * 0.33);
            width: calc((100vw - 0rem) * 0.33)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .place-mark.jsx-2500772944 {
        top: -50vh
    }

    @media(min-height:1280px) {
        .place-mark.jsx-2500772944 {
            top: -60vh
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-3674170421 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-3674170421 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/JwijS8l0A3ZiUZWTNIOZ0.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-3674170421 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-3674170421 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-3674170421 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .pageable-item.jsx-1672455938 {
        position: relative;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        width: 100%;
        height: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none
    }

    body.print .pageable-item.jsx-1672455938 {
        visibility: visible;
        opacity: 1
    }
</style>
<style type="text/css" data-styled-jsx="">
    .place-number-traditional.jsx-3182421706 {
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.013rem;
        line-height: 2.4rem;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
    }

    .place-number-traditional.jsx-3182421706 {
        background-color: #A9B4B3;
        -webkit-box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem #A9B4B3;
        -moz-box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem #A9B4B3;
        box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem #A9B4B3
    }

    .place-number-traditional.jsx-3182421706:not(.outlined) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-3481559898 {
        color: #041716
    }
</style>
<style type="text/css" data-styled-jsx="">
    @media(min-width:960px) {
        body:not(.print) div.jsx-2417920930 {
            min-width: 41rem;
            max-width: 56rem;
            width: -webkit-calc((100vw - 0rem) * 0.4);
            width: -moz-calc((100vw - 0rem) * 0.4);
            width: calc((100vw - 0rem) * 0.4);
            grid-column: auto
        }
    }

    @media(min-width:1280px) {
        body:not(.print) div.jsx-2417920930 {
            min-width: auto;
            max-width: auto;
            width: -webkit-calc((100vw - 0rem) * 0.33);
            width: -moz-calc((100vw - 0rem) * 0.33);
            width: calc((100vw - 0rem) * 0.33)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    @media(min-width:960px) {
        body:not(.print) .media-focused.jsx-1946825718 {
            position: relative;
            grid-template-rows: auto;
            grid-template-columns: auto 1fr;
            min-height: 100vh;
            -webkit-align-content: start;
            -ms-flex-line-pack: start;
            align-content: start
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    @media(max-width:960px) {
        .bottom-sentinel.sentinel-tour.jsx-2047687406 {
            bottom: 40vh
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.print .media-focused.jsx-2297549235 {
        display: block
    }
</style>
<style type="text/css" data-styled-jsx="">
    .immersive.jsx-3f9ea0437cf4a588 {
        position: relative;
        z-index: 0;
        background: transparent;
        display: grid
    }

    .sentinel-top-docked.jsx-3f9ea0437cf4a588,
    .sentinel-bottom-docked.jsx-3f9ea0437cf4a588,
    .first-create-dock-node.jsx-3f9ea0437cf4a588 {
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        height: .1rem
    }

    .sentinel-top-docked.jsx-3f9ea0437cf4a588,
    .first-create-dock-node.jsx-3f9ea0437cf4a588 {
        top: 0
    }

    .sentinel-bottom-docked.jsx-3f9ea0437cf4a588 {
        bottom: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .layout-wrapper.jsx-1513615927 {
        --small-and-print-margin-top: 3.2rem;
        --small-and-print-margin-bottom: 0;
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom);
        clear: both;
    }

    .layout-wrapper.text-cursor.jsx-1513615927 {
        cursor: text
    }

    @media(min-width:700px) {
        .layout-wrapper.jsx-1513615927 {
            margin-top: 3.2rem;
            margin-bottom: 0
        }
    }

    @media(min-width:960px) {
        .layout-wrapper.jsx-1513615927 {
            margin-top: 6.4rem;
            margin-bottom: 6.4rem
        }
    }

    body.basic-print .layout-wrapper.jsx-1513615927 {
        margin-top: var(--small-and-print-margin-top);
        margin-bottom: var(--small-and-print-margin-bottom)
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1341826036 {
        color: #020911
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-1f24ab0bc0e7a45f {
        font-size: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    a.jsx-327186198 {
        cursor: pointer
    }

    a.jsx-327186198,
    a.jsx-327186198:visited {
        color: inherit
    }

    .plain.jsx-327186198 {
        text-decoration: underline
    }

    .underline-dotted.jsx-327186198,
    .underline-solid.jsx-327186198 {
        -webkit-text-decoration-color: #020911;
        -moz-text-decoration-color: #020911;
        text-decoration-color: #020911;
        text-underline-offset: 0.8ex
    }

    .underline-solid.jsx-327186198 {
        -webkit-text-decoration-style: solid;
        -moz-text-decoration-style: solid;
        text-decoration-style: solid;
        text-decoration-thickness: 1px
    }

    .underline-solid.jsx-327186198:hover {
        text-decoration-thickness: 2px
    }

    .underline-dotted.jsx-327186198 {
        -webkit-text-decoration-style: dotted;
        -moz-text-decoration-style: dotted;
        text-decoration-style: dotted;
        text-decoration-thickness: 2px
    }

    .underline-dotted.jsx-327186198:hover {
        -webkit-text-decoration-style: solid;
        -moz-text-decoration-style: solid;
        text-decoration-style: solid
    }

    .filled.jsx-327186198 {
        text-decoration: none;
        background-color: #500000;
        padding: 0 0.4rem;
        -webkit-box-decoration-break: clone
    }

    .filled.jsx-327186198,
    .filled.jsx-327186198:visited {
        color: #fff;
        caret-color: #fff;
    }

    @media print {
        a.jsx-327186198 {
            -webkit-print-color-adjust: exact;
            color-adjust: exact
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .caption.jsx-3172456529 {
        margin-top: 0.8rem;
        text-align: center;
        position: relative;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
        color: #3d6665
    }

    .caption.jsx-3172456529,
    .caption.jsx-3172456529 .placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: calc(1.11rem * var(--aspect-ratio-canvas-font-scale, 1));
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
    }

    .content-container.full-width .caption.jsx-3172456529 {
        padding: 0 1.6rem
    }

    @media(min-width:700px) {

        .caption.jsx-3172456529,
        .caption.jsx-3172456529 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.33rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
            ;
        }
    }

    @media(min-width:960px) {

        .caption.jsx-3172456529,
        .caption.jsx-3172456529 .placeholder {
            font-family: 'Open Sans', sans-serif;
            font-size: calc(1.6rem * var(--aspect-ratio-canvas-font-scale, 1));
            font-weight: 400;
            font-style: normal;
            line-height: 1.65;
            ;
        }
    }

    body.print .caption.jsx-3172456529 {
        color: #001C1B
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-3420557937 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-3420557937 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/NdolIM1rjJpS3XdaTLQpY.jpeg?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-3420557937 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-3420557937 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-3420557937 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .image.jsx-3822368546 {
        height: 100%;
        width: 100%
    }

    @media(min-width:576px) {
        .image.is-full-view.jsx-3822368546 {
            -o-object-fit: contain;
            object-fit: contain
        }
    }

    video.image.jsx-3822368546:not(.is-cropped, .is-fit) {
        -o-object-fit: contain;
        object-fit: contain;
        max-width: 100%;
        height: auto
    }

    .image.is-cropped.jsx-3822368546:not(.is-fit) {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%
    }

    .image.is-cropped.is-fit.jsx-3822368546 {
        -o-object-fit: contain;
        object-fit: contain;
        background-color: undefined
    }


    .image.is-fit.is-expanded.jsx-3822368546 {
        background-color: unset
    }

    body.basic-print .image.jsx-3822368546 {
        max-height: -webkit-calc(10in - 3rem);
        max-height: -moz-calc(10in - 3rem);
        max-height: calc(10in - 3rem);
        -o-object-fit: contain;
        object-fit: contain
    }
</style>

<style type="text/css" data-styled-jsx="">
    .float-layout.jsx-3740718615 {
        clear: both
    }

    .float-wrapper.jsx-3740718615 {
        position: relative;
        margin-bottom: 3.2rem;
        z-index: 2;
        -webkit-margin-start: 1.6rem;
        -moz-margin-start: 1.6rem;
        margin-inline-start: 1.6rem;
        -webkit-margin-end: 1.6rem;
        -moz-margin-end: 1.6rem;
        margin-inline-end: 1.6rem
    }

    @media(min-width:700px) {
        .float-wrapper.jsx-3740718615 {
            -webkit-margin-start: 3.2rem;
            -moz-margin-start: 3.2rem;
            margin-inline-start: 3.2rem;
            -webkit-margin-end: 3.2rem;
            -moz-margin-end: 3.2rem;
            margin-inline-end: 3.2rem;
            --grid-edges: calc(1.6rem * 2);
            --grid-gap: 0.8rem;
            --max-width: calc(min(100%, 1280px) - var(--grid-edges));
            width: -webkit-calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap));
            width: -moz-calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap));
            width: calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap))
        }

        .float-wrapper.start.jsx-3740718615 {
            -webkit-margin-start: 1.6rem;
            -moz-margin-start: 1.6rem;
            margin-inline-start: 1.6rem
        }

        .float-wrapper.end.jsx-3740718615 {
            -webkit-margin-end: 1.6rem;
            -moz-margin-end: 1.6rem;
            margin-inline-end: 1.6rem
        }

        .float-wrapper.start.jsx-3740718615,
        html[dir='rtl'] .float-wrapper.end.jsx-3740718615 {
            float: left
        }

        .float-wrapper.end.jsx-3740718615,
        html[dir='rtl'] .float-wrapper.start.jsx-3740718615 {
            float: right
        }
    }

    body.basic-print .float-wrapper.jsx-3740718615 {
        -webkit-margin-start: 1.6rem;
        -moz-margin-start: 1.6rem;
        margin-inline-start: 1.6rem;
        -webkit-margin-end: 1.6rem;
        -moz-margin-end: 1.6rem;
        margin-inline-end: 1.6rem;
        margin-bottom: 1.6rem;
        --grid-edges: calc(1.6rem * 2);
        --grid-gap: 0.8rem;
        --max-width: calc(min(100%, 1280px) - var(--grid-edges));
        width: -webkit-calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap));
        width: -moz-calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap));
        width: calc(var(--max-width) * 0.4166666666666667 - 0.5833333333333334 * var(--grid-gap))
    }

    body.basic-print .float-wrapper.start.jsx-3740718615 {
        -webkit-margin-start: 0;
        -moz-margin-start: 0;
        margin-inline-start: 0
    }

    body.basic-print .float-wrapper.end.jsx-3740718615 {
        -webkit-margin-end: 0;
        -moz-margin-end: 0;
        margin-inline-end: 0
    }

    body.basic-print .float-wrapper.start.jsx-3740718615,
    html[dir='rtl'] body.basic-print .float-wrapper.end.jsx-3740718615 {
        float: left
    }

    body.basic-print .float-wrapper.end.jsx-3740718615,
    html[dir='rtl'] body.basic-print .float-wrapper.start.jsx-3740718615 {
        float: right
    }

    @media(min-width:960px) {
        .float-wrapper.jsx-3740718615 {
            --grid-gap: 1.6rem
        }
    }

    @media(min-width:1280px) {
        .float-wrapper.jsx-3740718615 {
            --float-offset: calc((100% - 1280px) / 2 + var(--grid-gap))
        }

        .float-wrapper.start.jsx-3740718615 {
            -webkit-margin-start: var(--float-offset);
            -moz-margin-start: var(--float-offset);
            margin-inline-start: var(--float-offset)
        }

        .float-wrapper.end.jsx-3740718615 {
            -webkit-margin-end: var(--float-offset);
            -moz-margin-end: var(--float-offset);
            margin-inline-end: var(--float-offset)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-4223169629 {
        color: #031918
    }
</style>
<style type="text/css" data-styled-jsx="">
    a.jsx-217766488 {
        cursor: pointer
    }

    a.jsx-217766488,
    a.jsx-217766488:visited {
        color: inherit
    }

    .plain.jsx-217766488 {
        text-decoration: underline
    }

    .underline-dotted.jsx-217766488,
    .underline-solid.jsx-217766488 {
        -webkit-text-decoration-color: #500000;
        -moz-text-decoration-color: #500000;
        text-decoration-color: #500000;
        text-underline-offset: 0.8ex
    }

    .underline-solid.jsx-217766488 {
        -webkit-text-decoration-style: solid;
        -moz-text-decoration-style: solid;
        text-decoration-style: solid;
        text-decoration-thickness: 1px
    }

    .underline-solid.jsx-217766488:hover {
        text-decoration-thickness: 2px
    }

    .underline-dotted.jsx-217766488 {
        -webkit-text-decoration-style: dotted;
        -moz-text-decoration-style: dotted;
        text-decoration-style: dotted;
        text-decoration-thickness: 2px
    }

    .underline-dotted.jsx-217766488:hover {
        -webkit-text-decoration-style: solid;
        -moz-text-decoration-style: solid;
        text-decoration-style: solid
    }

    .filled.jsx-217766488 {
        text-decoration: none;
        background-color: #500000;
        padding: 0 0.4rem;
        -webkit-box-decoration-break: clone
    }

    .filled.jsx-217766488,
    .filled.jsx-217766488:visited {
        color: #fff;
        caret-color: #fff;
    }

    @media print {
        a.jsx-217766488 {
            -webkit-print-color-adjust: exact;
            color-adjust: exact
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-248956040 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-248956040 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/pClVmRl_jCBrgOBMCsPnO.jpeg?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }


    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-248956040 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-248956040 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-248956040 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .link-spacer-container.jsx-508825460 {
        display: flow-root;
        position: relative
    }

    .link-spacer.jsx-508825460 {
        position: absolute;
        top: -0.4rem;
        left: 0;
        -webkit-transform: translatex(-100%);
        -moz-transform: translatex(-100%);
        -ms-transform: translatex(-100%);
        -o-transform: translatex(-100%);
        transform: translatex(-100%);
        z-index: 2;
        -webkit-padding-end: 0.8rem;
        -moz-padding-end: 0.8rem;
        padding-inline-end: 0.8rem
    }

    html[dir='rtl'] .link-spacer.jsx-508825460 {
        left: unset;
        right: 0;
        -webkit-transform: translatex(100%);
        -moz-transform: translatex(100%);
        -ms-transform: translatex(100%);
        -o-transform: translatex(100%);
        transform: translatex(100%)
    }

    .link-button.jsx-508825460 {
        --inner-padding: 0.8rem;
        position: relative;
        -webkit-transition: -webkit-transform.2s ease-in-out, opacity.2s ease-in-out;
        -moz-transition: -moz-transform.2s ease-in-out, opacity.2s ease-in-out;
        -o-transition: -o-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -webkit-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -moz-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: -o-transform.2s ease-in-out, opacity.2s ease-in-out;
        transition: transform.2s ease-in-out, opacity.2s ease-in-out;
        -webkit-transform: translatex(25%);
        -moz-transform: translatex(25%);
        -ms-transform: translatex(25%);
        -o-transform: translatex(25%);
        transform: translatex(25%);
        opacity: 0;
        line-height: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        padding: var(--inner-padding);
        margin: 0.4rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: #ffffff
    }

    html[dir='rtl'] .link-button.jsx-508825460 {
        -webkit-transform: translatex(-25%);
        -moz-transform: translatex(-25%);
        -ms-transform: translatex(-25%);
        -o-transform: translatex(-25%);
        transform: translatex(-25%)
    }

    .link-button.flashy.jsx-508825460 {
        --border-width: 0.1rem;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px -1px rgba(0, 0, 0, 0.03);
        border: var(--border-width) solid transparent;
        padding: -webkit-calc(var(--inner-padding) - var(--border-width));
        padding: -moz-calc(var(--inner-padding) - var(--border-width));
        padding: calc(var(--inner-padding) - var(--border-width))
    }

    .link-button.jsx-508825460:hover,
    .link-button.jsx-508825460:focus {
        background-color: #ebebeb
    }

    .link-button.flashy.jsx-508825460:hover,
    .link-button.flashy.jsx-508825460:focus {
        border-color: #c2c2c2
    }

    .text-container.jsx-508825460:hover .link-button.jsx-508825460,
    .link-spacer.jsx-508825460:hover .link-button.jsx-508825460,
    .user-is-tabbing .link-button.jsx-508825460:focus,
    html[dir='rtl'] .user-is-tabbing .link-button.jsx-508825460:focus {
        -webkit-transform: translatex(0%);
        -moz-transform: translatex(0%);
        -ms-transform: translatex(0%);
        -o-transform: translatex(0%);
        transform: translatex(0%);
        opacity: 1
    }

    .user-is-tabbing .link-button.jsx-508825460:focus {
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none
    }

    .link-button.jsx-508825460::before {
        content: "";
        position: absolute;
        top: -0.4rem;
        left: -0.4rem;
        bottom: -0.4rem;
        right: -0.4rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-4019594102 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-4019594102 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/Uy8IUrB05WL9lqFxOsy8d.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-4019594102 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-4019594102 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-4019594102 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-2561276702 {
        color: #020f0e
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-2753355602 {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    svg.jsx-2753355602 {
        width: 2.4rem;
        height: 2.4rem;
        fill: currentColor
    }

    body.user-is-tabbing .icon.jsx-2753355602 svg.jsx-2753355602:focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    body:not(.user-is-tabbing) .icon.jsx-2753355602 svg.jsx-2753355602:focus {
        outline: none
    }

    html[lang="ar"] .ar-flip.jsx-2753355602 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }

    html[dir='rtl'] .rtl-flip.jsx-2753355602 {
        -webkit-transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        transform: scale(-1, 1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .overlay-button.jsx-3850199254 {
        -webkit-transition: background-color.15s;
        -moz-transition: background-color.15s;
        -o-transition: background-color.15s;
        transition: background-color.15s;
        background-color: #ffffff;
        color: #222222;
        line-height: 0;
        height: 4.8rem;
        width: 4.8rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px -1px rgba(0, 0, 0, 0.03);
    }

    .overlay-button.is-activated.jsx-3850199254,
    button.overlay-button.jsx-3850199254:hover,
    .user-is-tabbing button.overlay-button.jsx-3850199254:focus {
        background-color: #ebebeb
    }

    body:not(.user-is-tabbing) button.overlay-button.jsx-3850199254:focus {
        outline: none
    }

    .inner-container.jsx-3850199254 {
        height: 100%;
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0.8rem;
        align-items: center;
        justify-content: center;
        ;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .white-space-item.jsx-472442185 {
        grid-column: span 0;
        display: none
    }

    @media(min-width:700px) {
        .white-space-item.jsx-472442185 {
            grid-column: span 1;
            display: block
        }
    }

    @media(min-width:960px) {
        .white-space-item.jsx-472442185 {
            grid-column: span 1;
            display: block
        }

        #storymaps-root.inline-story .white-space-item.jsx-472442185 {
            grid-column: span 0;
            display: none
        }
    }

    body.print .white-space-item.jsx-472442185 {
        grid-column: span 1;
        display: block
    }
</style>
<style type="text/css" data-styled-jsx="">
    span.jsx-667435231 {
        color: #011312
    }
</style>
<style type="text/css" data-styled-jsx="">
    .narrative-panel-wrapper.jsx-10827080 {
        position: relative;
        background-color: rgba(255, 255, 255, 0.9);
        pointer-events: auto;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translatey(5rem);
        -moz-transform: translatey(5rem);
        -ms-transform: translatey(5rem);
        -o-transform: translatey(5rem);
        transform: translatey(5rem);
        height: 0;
        padding: 1.6rem 0;
        border: none
    }

    .narrative-panel-wrapper.jsx-10827080:empty {
        padding: 0
    }

    .narrative-panel-wrapper.jsx-10827080:not(.background-transparent) {
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1), 0px 3px 3px -2px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
    }

    .narrative-panel-wrapper.selected.jsx-10827080 {
        height: auto;
        opacity: 1;
        visibility: visible;
        -webkit-transform: translatey(0);
        -moz-transform: translatey(0);
        -ms-transform: translatey(0);
        -o-transform: translatey(0);
        transform: translatey(0);
        -webkit-transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -webkit-transform.8s ease;
        -moz-transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -moz-transform.8s ease;
        -o-transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -o-transform.8s ease;
        transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -webkit-transform.8s ease;
        transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -moz-transform.8s ease;
        transition: opacity.5s ease-in-out, visibility.5s ease-in-out, -o-transform.8s ease;
        transition: opacity.5s ease-in-out, visibility.5s ease-in-out, transform.8s ease
    }

    .narrative-panel-wrapper.selected.no-transition.jsx-10827080 {
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none
    }

    .narrative-panel-wrapper.selected.hide.jsx-10827080 {
        visibility: hidden;
        opacity: 0
    }

    .narrative-panel-wrapper.jsx-10827080>.layout-wrapper:first-child {
        margin-top: 0
    }

    .narrative-panel-wrapper.jsx-10827080>.layout-wrapper:last-child {
        margin-bottom: 0
    }

    .narrative-panel-wrapper.jsx-10827080 .text-viewer,
    .narrative-panel-wrapper.jsx-10827080 .text-viewer p,
    .narrative-panel-wrapper.jsx-10827080 .text-viewer li,
    .narrative-panel-wrapper.jsx-10827080 .text-editor :not(.caption-editor-container) p,
    .narrative-panel-wrapper.jsx-10827080 .text-editor li,
    .narrative-panel-wrapper.jsx-10827080 .main-button {
        color: #222222;
        caret-color: #222222;
        text-shadow: none;
    }

    .narrative-panel-wrapper.jsx-10827080 .text-viewer h2,
    .narrative-panel-wrapper.jsx-10827080 .text-viewer h3,
    .narrative-panel-wrapper.jsx-10827080 .text-viewer h4,
    .narrative-panel-wrapper.jsx-10827080 .text-viewer blockquote,
    .narrative-panel-wrapper.jsx-10827080 .text-editor h2,
    .narrative-panel-wrapper.jsx-10827080 .text-editor h3,
    .narrative-panel-wrapper.jsx-10827080 .text-editor h4,
    .narrative-panel-wrapper.jsx-10827080 .text-editor blockquote {
        color: #222222;
        caret-color: #222222;
        text-shadow: none;
    }

    .narrative-panel-wrapper.jsx-10827080 .caption-viewer-container .media-caption,
    .narrative-panel-wrapper.jsx-10827080 .text-editor figcaption {
        color: #3d6665;
        caret-color: #3d6665;
        text-shadow: none;
    }

    .narrative-panel-wrapper.jsx-10827080 .text-editor .placeholder {
        color: rgba(34, 34, 34, 0.5);
        caret-color: rgba(34, 34, 34, 0.5);
    }

    .narrative-panel-wrapper.background-transparent.jsx-10827080 .text-editor .caption-editor-container .placeholder {
        color: rgba(61, 102, 101, 0.6);
        caret-color: rgba(61, 102, 101, 0.6);
        text-shadow: none;
    }

    @media(min-width:700px) {
        .narrative-panel-wrapper.jsx-10827080 {
            padding: 3.2rem 1.6rem
        }
    }

    body.print .narrative-panel-wrapper.jsx-10827080:not(:empty) {
        padding: 1.6rem 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .grid-item.jsx-915691696 {
        position: relative;
        min-width: 0;
        grid-column: span 6
    }

    @media(min-width:700px) {
        .grid-item.jsx-915691696 {
            grid-column: span 6
        }
    }

    @media(min-width:960px) {
        .grid-item.jsx-915691696 {
            grid-column: span 3
        }

        #storymaps-root.inline-story .grid-item.jsx-915691696 {
            grid-column: span 6
        }
    }

    body.basic-print .grid-item.jsx-915691696 {
        grid-column: span 3
    }
</style>
<style type="text/css" data-styled-jsx="">
    .grid-container.jsx-1978474844 {
        --medium-and-print-grid: repeat(12, 1fr);
        --medium-and-print-gap: 0.8rem;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 0.8rem;
        -webkit-box-align: start;
        -webkit-align-items: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: start
    }

    .grid-container.eq-height.jsx-1978474844 {
        grid-auto-rows: 1fr;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -moz-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    @media(min-width:700px) {
        .grid-container.jsx-1978474844 {
            grid-template-columns: var(--medium-and-print-grid);
            grid-gap: var(--medium-and-print-gap)
        }
    }

    @media(min-width:960px) {
        .grid-container.jsx-1978474844 {
            grid-template-columns: repeat(12, 1fr);
            grid-gap: 1.6rem
        }
    }

    body.basic-print .grid-container.jsx-1978474844 {
        grid-template-columns: var(--medium-and-print-grid);
        grid-gap: var(--medium-and-print-gap)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .content-container.jsx-3512300925 {
        padding: 0 2.4rem;
        max-width: none;
        margin: 0 auto;
        width: 100%
    }

    @media(min-width:700px) {
        .content-container.jsx-3512300925 {
            padding: 0
        }
    }

    @media(min-width:960px) {
        .content-container.jsx-3512300925 {
            padding: 0
        }
    }

    @media(min-width:1280px) {
        .content-container.jsx-3512300925 {
            padding: 0
        }
    }

    body.basic-print .content-container.jsx-3512300925 {
        padding: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-1410773851 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-1410773851 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/s7kKEa538jIYxYAZx2lEu.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-1410773851 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-1410773851 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-1410773851 {
        height: 100%;
        width: 100%
    }
</style>

<style type="text/css" data-styled-jsx="">
    .image.jsx-4047871960 {
        height: 100%;
        width: 100%
    }

    @media(min-width:576px) {
        .image.is-full-view.jsx-4047871960 {
            -o-object-fit: contain;
            object-fit: contain
        }
    }

    video.image.jsx-4047871960:not(.is-cropped, .is-fit) {
        -o-object-fit: contain;
        object-fit: contain;
        max-width: 100%;
        height: auto
    }

    .image.is-cropped.jsx-4047871960:not(.is-fit) {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 66%;
        object-position: 50% 66%
    }

    .image.is-cropped.is-fit.jsx-4047871960 {
        -o-object-fit: contain;
        object-fit: contain;
        background-color: #ffffff
    }

    .image.is-fit.is-expanded.jsx-4047871960 {
        background-color: unset
    }

    body.basic-print .image.jsx-4047871960 {
        max-height: -webkit-calc(10in - 3rem);
        max-height: -moz-calc(10in - 3rem);
        max-height: calc(10in - 3rem);
        -o-object-fit: contain;
        object-fit: contain
    }

    body.print .image.jsx-4047871960 {
        background-color: #ffffff;
        ;
    }
</style>

<style type="text/css" data-styled-jsx="">
    audio.jsx-4f1efd599be6e18b {
        display: none
    }
</style>

<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-115959619 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-115959619 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/bdM1EXk5OpQV9WiBShZOz.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }


    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-115959619 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-115959619 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-115959619 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sidecar.jsx-1000321570 {
        grid-template-columns: minmax(0, auto)
    }

    .sidecar.docked-panel.jsx-1000321570 {
        grid-template-rows: auto 1fr
    }

    .sidecar.floating-panel.jsx-1000321570 {
        grid-template-rows: auto
    }

    .sidecar.slideshow.jsx-1000321570 {
        grid-template-rows: auto;
        grid-template-columns: auto
    }

    @media(min-width:960px) {
        .sidecar.docked-panel.jsx-1000321570 {
            grid-template-rows: auto;
            grid-template-columns: auto minmax(0, 1fr)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-2435219292 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-2435219292 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/bm-rQz3QpBiYXtyIqFwq8.jpeg?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-2435219292 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-2435219292 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-2435219292 {
        height: 100%;
        width: 100%
    }
</style>

<style type="text/css" data-styled-jsx="">
    span.jsx-1607562268 {
        color: #000f0e
    }
</style>

<style type="text/css" data-styled-jsx="">
    .inner-container.jsx-2087254752 {
        width: 100%;
        height: 100%
    }

    .background-image.jsx-2087254752 {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url(https://www.arcgis.com/sharing/rest/content/items/cdbc78e53f68442a9978d7faff6baac0/resources/Ko_5cyESWKXTfczvigwc2.png?w=20&token=3NKHt6i2urmWtqOuugvr9Zuc8mayjQ6442pBYJGoGai0PK6zjvvJNmaK_B6uo6zj2d4A3LGi2N7Khc6kOMGFiGoyJgdneKFuJ6YcNBv1oMQLgKES7NRg835pcPrlJNYlxboVx_0jdRAhubckSSavaYTxoiW48nqrciRMRw9vk2J0h-7Q2HRWmQlGH31uyG6igq4QE_sq5Y9T42mO0-xeWJpCZaJ8oQ1m7SnAFTSF4PccNtGv6_wNgExPyeWRf56U)
    }

    .image-container {
        text-align: center;
        overflow: hidden
    }

    .inner-container.blur.jsx-2087254752 {
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px)
    }

    @media(min-width:576px) {
        .image-container.height-constrained.jsx-2087254752 {
            height: 100%
        }
    }

    .image-container.fill-parent.jsx-2087254752 {
        height: 100%;
        width: 100%
    }
</style>
<style type="text/css" data-styled-jsx="">
    .bottom-sentinel.sentinel-sidecar.jsx-689937321 {
        bottom: 40vh
    }

    @media(min-width:960px) {
        .bottom-sentinel.sentinel-sidecar.jsx-689937321 {
            bottom: 0
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .credits-container .layout-wrapper:first-child {
        margin-top: 0;
        margin-bottom: 0.8rem
    }

    .dnd-drag-attr .plus-button-container,
    .dnd-drag-attr .action-btns-container {
        visibility: hidden
    }

    .dnd-drag-attr {
        height: unset !important;
        background-color: #ffffff;
        max-height: 6rem;
        overflow: hidden
    }

    .dnd-ghost-attr {
        width: 65%;
        height: .2rem;
        margin: auto;
        padding: 1px;
        overflow: hidden;
        background: #009E9A
    }

    body.user-is-dragging .action-btns-container {
        visibility: hidden
    }

    body:not(.user-is-dragging) .action-btns-container {
        visibility: visible
    }

    body.user-is-dragging .attribution:hover {
        background: transparent
    }
</style>
<style type="text/css" data-styled-jsx="">
    .story-footer.jsx-3718694568 {
        padding: 4.8rem 0;
        background-color: #f5f5f5;
        margin-top: 4.8rem
    }

    .story-footer.jsx-3718694568>div:nth-child(2) {
        margin-top: 4.8rem
    }

    @media(min-width:700px) {
        .story-footer.jsx-3718694568 {
            padding: 6.4rem 0;
            margin-top: 6.4rem
        }

        .story-footer.jsx-3718694568>div:nth-child(2) {
            margin-top: 6.4rem
        }
    }

    body.print .story-footer.jsx-3718694568 {
        background-color: #FFFFFF
    }
</style>
<style type="text/css" data-styled-jsx="">
    body {
        background-color: #ffffff
    }
</style>
<style type="text/css" data-styled-jsx="">
    .story.jsx-2605853879 {
        padding-bottom: 0;
        position: relative;
        z-index: 0
    }

    .story-content-clearfix.jsx-2605853879 {
        clear: both
    }

    *:focus {
        outline-color: #500000
    }

    body.print {
        background-color: #f2f2f2;
        background: #f2f2f2;
        color: #001C1B
    }

    @media print {
        body.print {
            background-color: #FFFFFF
        }
    }

    body:not(.user-is-tabbing) .focus-jumping,
    .focus-jumped {
        outline-style: none
    }
</style>
<!-- <link rel="stylesheet" class="google-font-css" data-google-font-key="Open%20Sans400700" href="./files/css2"> -->
<style type="text/css" data-styled-jsx="">
    .nav-dots-container.jsx-3067500324 {
        display: block;
        --height: calc(35rem + 0px);
        top: calc(100vh - 12.8rem - var(--height) - 0rem)
    }

    .nav-dots-wrapper.jsx-3067500324 {
        padding: 0 3.2rem 0px
    }

    .nav-dots-wrapper.vertical.jsx-3067500324 {
        -webkit-box-align: flex-start;
        -webkit-align-items: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start
    }

    .nav-dots-wrapper.horizontal.jsx-3067500324 {
        padding-bottom: calc(0px + 3.2rem)
    }

    @media(min-width:960px) {
        .nav-dots-wrapper.horizontal.jsx-3067500324 {
            padding-bottom: calc(0px + 3.2rem)
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .container.jsx-1892766130 {
        --main-screen-height: calc(100vh - (12.8rem + 0px));
        --bottom-margin: 0px;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 12.8rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        max-height: 100%;
        pointer-events: none
    }

    .container.appended.jsx-1892766130 {
        z-index: 2
    }

    .container.partial-screen.jsx-1892766130 {
        grid-row: 1/2;
        grid-column: 1/2;
        height: -webkit-calc(40vh - (12.8rem));
        height: -moz-calc(40vh - (12.8rem));
        height: calc(40vh - (12.8rem))
    }

    .container.partial-screen.main.jsx-1892766130 {
        z-index: 1
    }

    .container.partial-screen.show-shadow.jsx-1892766130 {
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px -1px rgba(0, 0, 0, 0.03);
    }

    .container.full-screen.jsx-1892766130 {
        grid-row: 1;
        grid-column: 1;
        height: var(--main-screen-height);
        margin-bottom: var(--bottom-margin)
    }

    .modal-body-open .container.full-screen.docked.jsx-1892766130 {
        position: fixed;
        left: 0rem;
        right: 0rem;
        width: auto
    }

    .container.full-screen.main.jsx-1892766130 {
        z-index: 0
    }

    @media(min-width:960px) {
        .container.partial-screen.jsx-1892766130 {
            grid-row: 1/2;
            grid-column: 2 / 3;
            height: var(--main-screen-height);
            margin-bottom: var(--bottom-margin);
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none
        }

        .container.partial-screen.main.jsx-1892766130 {
            z-index: 0
        }

        .container.partial-screen.show-shadow.jsx-1892766130 {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none
        }
    }

    body.print .container.main.jsx-1892766130 {
        padding: 0;
        position: static;
        z-index: 1
    }

    body.print .container.partial-screen.main.jsx-1892766130 {
        height: -webkit-calc(10in / 2);
        height: -moz-calc(10in / 2);
        height: calc(10in / 2);
        grid-column: 1/2
    }

    body.print .container.full-screen.main.jsx-1892766130 {
        height: -webkit-calc(10in / 2);
        height: -moz-calc(10in / 2);
        height: calc(10in / 2)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .media-container.jsx-2499223119 {
        position: absolute;
        height: 100%;
        width: 100%;
        grid-column: 2 / 3;
        grid-row: 1;
        pointer-events: none
    }

    .content-container.jsx-2499223119 {
        padding-bottom: 20rem;
        min-height: 35rem;
        position: relative;
        opacity: .6;
        position: relative;
        z-index: 0;
        -webkit-padding-start: 6.4rem;
        -moz-padding-start: 6.4rem;
        padding-inline-start: 6.4rem;
        -webkit-padding-end: 3.2rem;
        -moz-padding-end: 3.2rem;
        padding-inline-end: 3.2rem
    }

    .content-container.animated.jsx-2499223119 {
        -webkit-transition: opacity.3s ease-in-out;
        -moz-transition: opacity.3s ease-in-out;
        -o-transition: opacity.3s ease-in-out;
        transition: opacity.3s ease-in-out
    }

    .content-container.first.jsx-2499223119 {
        --map-spacing: calc((100vh - 12.8rem) * 0.33);
        --remaining-space: calc(100vh - var(--map-spacing));
        margin-top: -webkit-calc(var(--map-spacing) + (var(--remaining-space) * 0.35));
        margin-top: -moz-calc(var(--map-spacing) + (var(--remaining-space) * 0.35));
        margin-top: calc(var(--map-spacing) + (var(--remaining-space) * 0.35))
    }

    .content-container.last.jsx-2499223119 {
        padding-bottom: 50vh
    }

    .content-container.selected.jsx-2499223119 {
        opacity: 1;
        z-index: 1
    }

    .sentinel.jsx-2499223119 {
        position: absolute;
        top: 25vh;
        left: 0;
        width: 100%;
        height: .1rem;
        z-index: 0
    }

    .number-container.jsx-2499223119 {
        position: absolute;
        top: 0.4rem
    }

    html:not([dir='rtl']) .number-container.jsx-2499223119 {
        left: 2.4rem
    }

    html[dir='rtl'] .number-container.jsx-2499223119 {
        right: 2.4rem
    }

    .content-wrapper.jsx-2499223119>.layout-wrapper:first-child {
        margin-top: 0
    }

    .content-wrapper.jsx-2499223119>.layout-wrapper:last-child {
        margin-bottom: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .map-section.jsx-3270843587 {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        grid-column: 1 / 2;
        grid-row: 1;
        pointer-events: none
    }

    .map-container.jsx-3270843587 {
        position: -webkit-sticky;
        position: sticky;
        top: 12.8rem;
        height: -webkit-calc(100vh - 12.8rem - 0rem);
        height: -moz-calc(100vh - 12.8rem - 0rem);
        height: calc(100vh - 12.8rem - 0rem)
    }

    .map-wrapper.jsx-3270843587 {
        height: -webkit-calc((100vh - 12.8rem) * 0.33);
        height: -moz-calc((100vh - 12.8rem) * 0.33);
        height: calc((100vh - 12.8rem) * 0.33);
        pointer-events: auto
    }

    .modal-body-open .map-wrapper.docked.jsx-3270843587 {
        position: fixed;
        top: 12.8rem
    }

    html:not([dir='rtl']) .modal-body-open .map-wrapper.docked.start.jsx-3270843587,
    html[dir='rtl'] .modal-body-open .map-wrapper.docked.end.jsx-3270843587 {
        left: 0rem
    }

    html:not([dir='rtl']) .modal-body-open .map-wrapper.docked.end.jsx-3270843587,
    html[dir='rtl'] .modal-body-open .map-wrapper.docked.start.jsx-3270843587 {
        right: 0rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .top-sentinel.sentinel-tour.jsx-2927485373 {
        top: -webkit-calc(12.8rem * -1);
        top: -moz-calc(12.8rem * -1);
        top: calc(12.8rem * -1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .arrow-container-outer.jsx-3367499604 {
        position: absolute;
        top: 0;
        bottom: 0
    }

    .arrow-container-outer.static.jsx-3367499604 {
        position: static
    }

    .arrow-container-outer.click-through.jsx-3367499604 {
        pointer-events: none
    }

    html:not([dir='rtl']) .arrow-container-outer.start.jsx-3367499604 {
        left: 0
    }

    html[dir='rtl'] .arrow-container-outer.start.jsx-3367499604 {
        right: 0
    }

    html:not([dir='rtl']) .arrow-container-outer.end.jsx-3367499604 {
        right: 0
    }

    html[dir='rtl'] .arrow-container-outer.end.jsx-3367499604 {
        left: 0
    }

    .arrow-container-inner.jsx-3367499604 {
        padding: 1.6rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        --top-spacing: 10rem;
        --bottom-spacing: calc(10rem + 0px);
        --button-height: calc(100vh - 12.8rem - var(--top-spacing) - var(--bottom-spacing));
        height: var(--button-height);
        margin-top: var(--top-spacing);
        top: -webkit-calc(var(--top-spacing) + 12.8rem);
        top: -moz-calc(var(--top-spacing) + 12.8rem);
        top: calc(var(--top-spacing) + 12.8rem);
        margin-bottom: var(--bottom-spacing);
        position: -webkit-sticky;
        position: sticky;
        z-index: 2;
        -webkit-tap-highlight-color: transparent
    }

    .arrow-container-inner.fit-content.jsx-3367499604 {
        --button-height: 4.8rem;
        --top-spacing: calc((50vh - var(--button-height) / 2) - 12.8rem);
        --bottom-spacing: calc(50vh - var(--button-height) / 2);
        height: var(--button-height);
        margin-top: var(--top-spacing);
        margin-bottom: var(--bottom-spacing)
    }

    .arrow-container-inner.click-through.jsx-3367499604 {
        pointer-events: auto
    }

    .arrow-container-outer.static.jsx-3367499604 .arrow-container-inner.jsx-3367499604 {
        margin-bottom: 0
    }

    body:not(.user-is-tabbing) .arrow-container-inner.jsx-3367499604:focus {
        outline: none
    }

    body:not(.user-is-tabbing) .arrow-container-inner.jsx-3367499604::-moz-focus-inner {
        border: 0
    }

    .button-container.jsx-3367499604 {
        -webkit-transition: -webkit-transform.15s;
        -moz-transition: -moz-transform.15s;
        -o-transition: -o-transform.15s;
        transition: -webkit-transform.15s;
        transition: -moz-transform.15s;
        transition: -o-transform.15s;
        transition: transform.15s
    }

    .button-container.jsx-3367499604:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 1px #c2c2c2;
        -moz-box-shadow: 0 0 0 1px #c2c2c2;
        box-shadow: 0 0 0 1px #c2c2c2
    }

    .button-container.can-dock.jsx-3367499604 {
        position: -webkit-sticky;
        position: sticky;
        top: -webkit-calc(12.8rem + 3.2rem);
        top: -moz-calc(12.8rem + 3.2rem);
        top: calc(12.8rem + 3.2rem);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%
    }

    .button-container.can-dock.jsx-3367499604::after {
        -webkit-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0.75);
        -moz-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0.75);
        box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0.75)
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1)
        }

        70% {
            -webkit-box-shadow: 0 0 0 -webkit-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 -webkit-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0)
        }

        100% {
            -webkit-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0)
        }
    }

    @-moz-keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1)
        }

        70% {
            -moz-box-shadow: 0 0 0 -moz-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 -moz-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0)
        }

        100% {
            -moz-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0)
        }
    }

    @-o-keyframes pulse {
        0% {
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1)
        }

        70% {
            box-shadow: 0 0 0 calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0)
        }

        100% {
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0)
        }
    }

    @keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1);
            -moz-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 1)
        }

        70% {
            -webkit-box-shadow: 0 0 0 -webkit-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            -moz-box-shadow: 0 0 0 -moz-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 -webkit-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 -moz-calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 calc(0.8rem + 2.5rem)rgba(34, 34, 34, 0)
        }

        100% {
            -webkit-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0);
            -moz-box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0);
            box-shadow: 0 0 0 0.8rem rgba(34, 34, 34, 0)
        }
    }

    .button-container.pulse.jsx-3367499604 {
        -webkit-animation: pulse 2s ease-in-out 1;
        -moz-animation: pulse 2s ease-in-out 1;
        -o-animation: pulse 2s ease-in-out 1;
        animation: pulse 2s ease-in-out 1
    }

    .arrow-container-inner.jsx-3367499604 .button-container.jsx-3367499604,
    .user-is-tabbing .arrow-container-inner.jsx-3367499604 .button-container.jsx-3367499604 {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    .arrow-container-inner.jsx-3367499604:hover .button-container.jsx-3367499604,
    .user-is-tabbing .arrow-container-inner.jsx-3367499604:focus .button-container.jsx-3367499604 {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .landmark.jsx-894698023 {
        position: absolute;
        top: calc(12.8rem * -1);
        left: 0;
        width: 100%;
        height: 0;
        z-index: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .immersive-narrative-panel.jsx-3115549450 {
        position: relative;
        min-height: -webkit-calc(100vh - (12.8rem + 0px));
        min-height: -moz-calc(100vh - (12.8rem + 0px));
        min-height: calc(100vh - (12.8rem + 0px));
        padding-top: 0;
        padding-bottom: 70vh
    }

    .immersive-narrative-panel.slideshow.jsx-3115549450 {
        --padding-below-panel: 70vh;
        --spacing-from-edge: calc((100vh - 12.8rem) * 0.2);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-bottom: var(--spacing-from-edge);
        padding-top: var(--spacing-from-edge);
        grid-column: 1;
        grid-row: 1;
        z-index: 0;
        pointer-events: none
    }

    .immersive-narrative-panel.slideshow.visible.jsx-3115549450 {
        z-index: 1
    }

    .immersive-narrative-panel.stacked.jsx-3115549450 {
        z-index: 0;
        min-height: 12.8rem;
        padding-top: 0;
        padding-bottom: 0
    }

    .immersive-narrative-panel.docked.jsx-3115549450 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
        z-index: 1
    }

    .immersive-narrative-panel.overlaid.jsx-3115549450 {
        grid-column: 1;
        z-index: 1;
        pointer-events: none;
        padding-left: 0;
        padding-right: 0;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .immersive-narrative-panel.first.stacked.jsx-3115549450 {
        min-height: -webkit-calc(12.8rem + 12.8rem);
        min-height: -moz-calc(12.8rem + 12.8rem);
        min-height: calc(12.8rem + 12.8rem)
    }

    .immersive-narrative-panel.first.jsx-3115549450:not(.stacked):not(.slideshow) {
        margin-top: -webkit-calc((100vh - 12.8rem) * 0.35);
        margin-top: -moz-calc((100vh - 12.8rem) * 0.35);
        margin-top: calc((100vh - 12.8rem) * 0.35)
    }

    .immersive-narrative-panel.overlaid.first.jsx-3115549450 {
        grid-row: 1
    }

    .immersive-narrative-panel.stacked.bottom-spacing.jsx-3115549450:not(.last) {
        margin-bottom: 19.2rem
    }

    .immersive-narrative-panel.last.stacked.jsx-3115549450 {
        min-height: -webkit-calc(100vh - (40vh - 12.8rem));
        min-height: -moz-calc(100vh - (40vh - 12.8rem));
        min-height: calc(100vh - (40vh - 12.8rem))
    }

    .immersive-narrative-panel.last.stacked.jsx-3115549450>.layout-wrapper:last-child {
        margin-bottom: 8rem
    }

    .immersive-narrative-panel.last.jsx-3115549450:not(.stacked):not(.slideshow) {
        padding-bottom: -webkit-calc(100vh - 12.8rem);
        padding-bottom: -moz-calc(100vh - 12.8rem);
        padding-bottom: calc(100vh - 12.8rem)
    }

    .sentinel.jsx-3115549450 {
        position: absolute;
        left: 0;
        width: 100%;
        height: .1rem;
        z-index: 0
    }

    .sentinel.stacked.jsx-3115549450 {
        top: -webkit-calc((100vh - 12.8rem) * 0.47);
        top: -moz-calc((100vh - 12.8rem) * 0.47);
        top: calc((100vh - 12.8rem) * 0.47)
    }

    .sentinel.jsx-3115549450:not(.stacked) {
        top: -webkit-calc((100vh - 12.8rem) * 0.17);
        top: -moz-calc((100vh - 12.8rem) * 0.17);
        top: calc((100vh - 12.8rem) * 0.17)
    }

    .spacer-stacked.jsx-3115549450 {
        margin-bottom: 12.8rem
    }

    .immersive-narrative-panel.vertical-start.jsx-3115549450 {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .immersive-narrative-panel.vertical-center.jsx-3115549450 {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .immersive-narrative-panel.vertical-end.jsx-3115549450 {
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -moz-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    @media(min-width:700px) {
        .immersive-narrative-panel.stacked.jsx-3115549450 {
            min-height: 16rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .top-sentinel.sentinel-sidecar.jsx-2380784448 {
        top: -webkit-calc(12.8rem * -1);
        top: -moz-calc(12.8rem * -1);
        top: calc(12.8rem * -1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .bottom-sentinel.sentinel-sidecar.jsx-1267272323 {
        bottom: 12.8rem
    }

    @media(min-width:960px) {
        .bottom-sentinel.sentinel-sidecar.jsx-1267272323 {
            bottom: 12.8rem
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .first-create-node.sentinel-sidecar.jsx-1933451343 {
        top: -webkit-calc(12.8rem * -1);
        top: -moz-calc(12.8rem * -1);
        top: calc(12.8rem * -1)
    }
</style>
<style type="text/css" data-styled-jsx="">
    .sentinel-top-interactable.jsx-2401134527,
    .sentinel-bottom-interactable.jsx-2401134527 {
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        height: .1rem
    }

    .sentinel-top-interactable.jsx-2401134527 {
        top: -webkit-calc(12.8rem * -1 - 12.5rem);
        top: -moz-calc(12.8rem * -1 - 12.5rem);
        top: calc(12.8rem * -1 - 12.5rem)
    }

    .sentinel-bottom-interactable.jsx-2401134527 {
        bottom: -12.5rem
    }
</style>
<style type="text/css" data-styled-jsx="">
    .landmark.jsx-430516373 {
        position: absolute;
        top: calc(12.8rem * -1 - 10vh);
        left: 0;
        width: 100%;
        height: 0;
        z-index: 0
    }
</style>
<style type="text/css" data-styled-jsx="">
    .tippy-box.jsx-1321307548 {
        outline: none
    }

    .tippy-box[data-placement^="top"].jsx-1321307548 .tippy-arrow {
        bottom: -0.6rem;
        border-top: 0.6rem solid rgba(0, 0, 0, 0.5);
        border-right: 0.6rem solid transparent;
        border-left: 0.6rem solid transparent
    }

    .tippy-box[data-placement^="right"].jsx-1321307548 .tippy-arrow {
        left: -0.6rem;
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-right: 0.6rem solid rgba(0, 0, 0, 0.5)
    }

    .tippy-box[data-placement^="bottom"].jsx-1321307548 .tippy-arrow {
        position: absolute;
        top: -0.6rem;
        border-right: 0.6rem solid transparent;
        border-bottom: 0.6rem solid rgba(0, 0, 0, 0.5);
        border-left: 0.6rem solid transparent
    }

    .tippy-box[data-placement^="left"].jsx-1321307548 .tippy-arrow {
        right: -0.6rem;
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-left: 0.6rem solid rgba(0, 0, 0, 0.5)
    }
</style>
<style type="text/css" data-styled-jsx="">
    body:not(.user-is-tabbing) .popover-target.jsx-538326453 {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .icon-information.jsx-63711278 {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 2.4rem;
        height: 2.4rem
    }

    .icon-information.jsx-63711278 button.popover-target {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
</style>
<style type="text/css" data-styled-jsx="">
    .popover-container.jsx-3335564695 {
        max-width: max(1199.2px, calc(10 * 2.4rem));
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 100%;
        color: #FFFFFF;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.017rem;
        line-height: 1.6rem;
        ;
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        border-radius: 0.8rem;
        padding: 0.8rem 1.2rem
    }

    .popover-container.jsx-3335564695 a {
        color: #FFFFFF
    }
</style>
<style type="text/css" data-styled-jsx="">
    .popover-container.jsx-1213386694 {
        max-width: max(798.4000000000001px, calc(10 * 2.4rem));
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 100%;
        color: #FFFFFF;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.017rem;
        line-height: 1.6rem;
        ;
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        border-radius: 0.8rem;
        padding: 0.8rem 1.2rem
    }

    .popover-container.jsx-1213386694 a {
        color: #FFFFFF
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-2934554792 {
        visibility: hidden
    }

    .full-screen-modal.jsx-2934554792 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-2934554792 {
        cursor: initial
    }

    .placeholder-image.jsx-2934554792 {
        max-height: -webkit-calc(100% - 26px);
        max-height: -moz-calc(100% - 26px);
        max-height: calc(100% - 26px)
    }

    .expanded-caption-container.jsx-2934554792 figcaption {
        width: 613px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-2047162628 {
        visibility: hidden
    }

    .full-screen-modal.jsx-2047162628 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-2047162628 {
        cursor: initial
    }

    .placeholder-image.jsx-2047162628 {
        max-height: -webkit-calc(100% - 26px);
        max-height: -moz-calc(100% - 26px);
        max-height: calc(100% - 26px)
    }

    .expanded-caption-container.jsx-2047162628 figcaption {
        width: 769px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-1882938873 {
        visibility: hidden
    }

    .full-screen-modal.jsx-1882938873 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-1882938873 {
        cursor: initial
    }

    .placeholder-image.jsx-1882938873 {
        max-height: -webkit-calc(100% - 0px);
        max-height: -moz-calc(100% - 0px);
        max-height: calc(100% - 0px)
    }

    .expanded-caption-container.jsx-1882938873 figcaption {
        width: 908px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-1155925912 {
        visibility: hidden
    }

    .full-screen-modal.jsx-1155925912 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-1155925912 {
        cursor: initial
    }

    .placeholder-image.jsx-1155925912 {
        max-height: -webkit-calc(100% - 0px);
        max-height: -moz-calc(100% - 0px);
        max-height: calc(100% - 0px)
    }

    .expanded-caption-container.jsx-1155925912 figcaption {
        width: 808px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-2075909530 {
        visibility: hidden
    }

    .full-screen-modal.jsx-2075909530 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-2075909530 {
        cursor: initial
    }

    .placeholder-image.jsx-2075909530 {
        max-height: -webkit-calc(100% - 0px);
        max-height: -moz-calc(100% - 0px);
        max-height: calc(100% - 0px)
    }

    .expanded-caption-container.jsx-2075909530 figcaption {
        width: 561px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-725024804 {
        visibility: hidden
    }

    .full-screen-modal.jsx-725024804 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-725024804 {
        cursor: initial
    }

    .placeholder-image.jsx-725024804 {
        max-height: -webkit-calc(100% - 26px);
        max-height: -moz-calc(100% - 26px);
        max-height: calc(100% - 26px)
    }

    .expanded-caption-container.jsx-725024804 figcaption {
        width: 811px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    .close-button.jsx-1302136560 {
        visibility: hidden
    }

    .full-screen-modal.jsx-1302136560 {
        visibility: hidden;
        z-index: -99;
        background-color: transparent
    }

    figure.jsx-1302136560 {
        cursor: initial
    }

    .placeholder-image.jsx-1302136560 {
        max-height: -webkit-calc(100% - 0px);
        max-height: -moz-calc(100% - 0px);
        max-height: calc(100% - 0px)
    }

    .expanded-caption-container.jsx-1302136560 figcaption {
        width: 910px;
        visibility: hidden
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-3778046943 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-3778046943 {
        padding-bottom: 0;
        ;
        ;
        ;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-3778046943 {
            aspect-ratio: 0;
            width: auto
        }

        .media-wrapper-children.jsx-3778046943 {}
    }
</style>
<style type="text/css" data-styled-jsx="">
    .map-scale-bar.jsx-e13d247901766e75 {
        --parent-offset: 10rem;
        position: absolute;
        bottom: 0;
        right: var(--parent-offset);
        z-index: 2
    }
</style>
<style type="text/css" data-styled-jsx="">
    .with-scale-bar .esri-attribution__sources {
        --offset: 30rem;
        max-width: -webkit-calc(100% - var(--offset));
        max-width: -moz-calc(100% - var(--offset));
        max-width: calc(100% - var(--offset))
    }

    .with-scale-bar .esri-attribution__sources--open {
        max-width: 100%
    }

    .esri-attribution--open~.map-scale-bar {
        display: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-2542711982 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-2542711982 {
        padding-bottom: 0;
        ;
        height: 340px;
        ;
        max-width: 613px;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-2542711982 {
            aspect-ratio: 1.5024509803921569;
            width: auto
        }

        .media-wrapper-children.jsx-2542711982 {
            max-height: calc((100vh - 6.4rem) * 0.91);
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    .place-number-traditional.jsx-3853658730 {
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.013rem;
        line-height: 2.4rem;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
    }

    .place-number-traditional.jsx-3853658730 {
        background-color: rgb(40, 128, 81);
        -webkit-box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem rgb(40, 128, 81);
        -moz-box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem rgb(40, 128, 81);
        box-shadow: 0 0 0 .2rem #000000, 0 0 0 0.4rem rgb(40, 128, 81)
    }

    .place-number-traditional.jsx-3853658730:not(.outlined) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
</style>
<style type="text/css" data-styled-jsx="">
    .pageable-item.jsx-3815938683 {
        position: relative;
        visibility: visible;
        opacity: 1;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
        width: 100%;
        height: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: auto
    }

    body.print .pageable-item.jsx-3815938683 {
        visibility: visible;
        opacity: 1
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-3191748012 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-3191748012 {
        padding-bottom: 0;
        ;
        height: 844px;
        ;
        max-width: 4400px;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-3191748012 {
            aspect-ratio: 1.478494623655914;
            width: auto
        }
    }
</style>
<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-4205848973 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-4205848973 {
        padding-bottom: 0;
        ;
        height: 307px;
        ;
        max-width: 930px;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-4205848973 {
            aspect-ratio: 1.6607142857142858;
            width: auto
        }
    }
</style>

<style type="text/css" data-styled-jsx="">
    body.basic-print .media-wrapper.jsx-2079883271 {
        max-height: 10in
    }

    .media-wrapper-children.jsx-2079883271 {
        padding-bottom: 0;
        ;
        height: 328px;
        ;
        max-width: 1116px;
    }

    @media(min-width:576px) {
        .preserve-aspect-ratio.jsx-2079883271 {
            aspect-ratio: 1.558659217877095;
            width: auto
        }
    }
</style>

<!-- <link as="script" rel="prefetch" href="./files/99779-ef9211dcf5ce070e.js.download">
<link as="script" rel="prefetch" href="./files/87301-383a68bdcd6a073e.js.download">
<link as="script" rel="prefetch" href="./files/45408-eccc752c5ca8b56d.js.download">
<link as="script" rel="prefetch" href="./files/58238-a84c04464ef788d2.js.download">
<link as="script" rel="prefetch" href="./files/52498-f55284ed3816be1d.js.download">
<link as="script" rel="prefetch" href="./files/84246-57cc88adb2de3ab5.js.download">
<link as="script" rel="prefetch" href="./files/76187-92946473059fd7b8.js.download">
<link as="script" rel="prefetch" href="./files/78779-9949143b08c3f445.js.download">
<link as="script" rel="prefetch" href="./files/stories-fd0c02af20c4bc6b.js.download"> -->


<div id="__next">
    <div id="storymaps-root" class="page-container">
        <main class="jsx-331915672 jsx-1283827886 viewer-main story-container">
            <article data-blockid="n-lmUNUy" class="jsx-2605853879 story">
                <div data-blockid="n-8r3GBQ" id="n-8r3GBQ" class="jsx-997092493 layout-wrapper drag-sentinel">
                    <div class="jsx-191223531 full-cover-container bottom">
                        <div
                            class="jsx-2146826404 title-panel size-large vertical-position-bottom horizontal-position-center">
                            <div class="jsx-43199366 grid-item-lite">
                                <h1 class="sc-title jsx-2d3cd4428adb0f1d jsx-884a897778ff5589">The Colorado River </h1>
                                <p class="sc-summary is-viewer stacked jsx-6b2ea21b5b83d1b8 jsx-884a897778ff5589">The
                                    Lifeblood of the Southwest</p>
                                <div class="jsx-3616690771 sc-metadata">
                                    <p class="sc-byline jsx-884a897778ff5589">Nikhitha Pasula</p>
                                    <p class="jsx-2037423108 sc-published-date viewer"><time
                                            datetime="2024-04-03T18:48:43.094Z">April 3, 2024</time></p>
                                </div>
                            </div>
                        </div>
                        <div class="jsx-2685999826 media-panel background">
                            <figure class="jsx-3778046943 jsx-2439863738 media-wrapper fill-parent">
                                <div class="jsx-3778046943 jsx-2439863738 media-wrapper-children fill-parent">
                                    <div data-blockid="n-JVVG1c"
                                        class="jsx-1656411247 image-container fill-parent jsx-2522058164">
                                        <div class="jsx-1656411247 inner-container">
                                            <img alt=""
                                                class="jsx-3352559419 image jsx-1134208798 is-cropped" loading="eager"
                                                width="946" height="575"
                                                src="./assets/story_img/the-colorado-river/face.png">
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <div class="jsx-2685999826 mask"></div>
                        </div>
                        <div class="jsx-191223531 icon-container"><span
                                class="jsx-2781388330 icon icon-animating-arrow"><svg viewBox="0 0 13 42"
                                    aria-hidden="true" class="jsx-2781388330">
                                    <path d="M7 35.4h5.7l-6.3 6.3L0 35.4h6V0h1z" class="jsx-2781388330 "></path>
                                </svg></span></div>
                    </div>
                </div>
                <div data-blockid="n-yABS80" id="n-yABS80"
                    class="jsx-342868882 layout-wrapper navigation-bar  drag-sentinel">
                    <nav data-blockid="n-yABS80" aria-label="Story headings navigation"
                        class="jsx-3664291679 navigation-viewer">
                        <div class="jsx-862068705 bar-container"><button aria-label="Previous headings"
                                class="jsx-1157198351 arrow left"><span
                                    class="jsx-1159706149 icon jsx-d15d0b8e8b3d2b1b icon-left"><svg viewBox="0 0 24 24"
                                        aria-hidden="true" class="jsx-1159706149">
                                        <path
                                            d="M21 13H4.707l2.646 2.646-.707.707L2.793 12.5l3.853-3.854.707.707L4.707 12H21z"
                                            class="jsx-1159706149"></path>
                                    </svg></span></button>
                            <ol class="jsx-862068705 content">
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#introduction_:r3i:"
                                        id="l-n-0GFdTm" class="jsx-3881477015 page-anchor-link">Introduction</a></li>
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#californiasstake_:r3l:"
                                        id="l-n-DqSFDc" class="jsx-3881477015 page-anchor-link">California's Stake</a>
                                </li>
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#waterirrigation_:r3t:"
                                        id="l-n-Gz0wkf" class="jsx-3881477015 page-anchor-link">Water Irrigation - CA,
                                        USA</a></li>
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#alfalfaproduction_:r42:"
                                        id="l-n-yYU0uj" class="jsx-3881477015 page-anchor-link">Alfalfa Production - CA,
                                        USA</a></li>
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#exports_:r4f:"
                                        id="l-n-a0HF0t" class="jsx-3881477015 page-anchor-link">Exports - CA, USA</a>
                                </li>
                                <li class="jsx-3881477015 page-anchor-list-item"><a
                                        href="story#sources_:r4t:"
                                        id="l-n-LjLa8L" class="jsx-3881477015 page-anchor-link"
                                        aria-current="true">Sources</a></li>
                            </ol><button aria-label="Next headings" class="jsx-134091668 arrow right"><span
                                    class="jsx-1159706149 icon jsx-d15d0b8e8b3d2b1b icon-right"><svg viewBox="0 0 24 24"
                                        aria-hidden="true" class="jsx-1159706149">
                                        <path
                                            d="M16.646 15.646L19.293 13H3v-1h16.293l-2.647-2.646.707-.707 3.854 3.853-3.854 3.854z"
                                            class="jsx-1159706149"></path>
                                    </svg></span></button>
                        </div>
                    </nav>
                </div>
                <div data-blockid="n-1QcUM1" id="n-1QcUM1" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-0GFdTm" id="n-0GFdTm" class="jsx-3036348328 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-375145049 text-container">
                            <div class="jsx-375145049 link-spacer-container">
                                <div class="jsx-375145049 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby="introduction_:r3i:" class="jsx-375145049 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h2 id="introduction_:r3i:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Introduction</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-8B4vcP" id="n-8B4vcP" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <ul data-testid="BulletList"
                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-964098143">The </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">USA stands
                                            third</em></span><span data-testid="CustomText-Color"
                                        class="jsx-1848375421"> </span><span data-testid="CustomText-Color"
                                        class="jsx-3349735503">worldwide</span><span data-testid="CustomText-Color"
                                        class="jsx-3115930760"> </span><span data-testid="CustomText-Color"
                                        class="jsx-964098143">for its renewable freshwater reserves, totaling around
                                    </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">3,069</em></span><span
                                        data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                        data-testid="CustomText-Color" class="jsx-1657040797">cubic kilometers,
                                        pre</span><span data-testid="CustomText-Color" class="jsx-964098143">dominantly
                                        sourced from surface water like lakes and </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">rivers
                                        </em></span><span data-testid="CustomText-Color"
                                        class="jsx-427649609">(</span><span data-testid="CustomText-Color"
                                        class="jsx-964098143">Misachi, 2018). </span></li>
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-2243148830">The</span><span
                                        data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">Colorado
                                            River</em></span><span data-testid="CustomText-Color"
                                        class="jsx-2243148830"> is one of the most important waterbodies in the United
                                        States of America, journeys over</span><span data-testid="CustomText-Color"
                                        class="jsx-1376378763"> </span><span data-testid="CustomText-Color"
                                        class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">1,400 miles</em></span><span
                                        data-testid="CustomText-Color" class="jsx-2243148830"> supplying freshwater to
                                        more than&nbsp;</span><span data-testid="CustomText-Color"
                                        class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">40 million</em></span><span
                                        data-testid="CustomText-Color" class="jsx-1376378763"> </span><span
                                        data-testid="CustomText-Color" class="jsx-3548323868">people&nbsp;in&nbsp;seven
                                    </span><span data-testid="CustomText-Color"
                                        class="jsx-2243148830">states&nbsp;and</span><span
                                        data-testid="CustomText-Color" class="jsx-1376378763">&nbsp;</span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">29 federally
                                        </em></span><span data-testid="CustomText-Color"
                                        class="jsx-3253138633">recognized tribes</span><span
                                        data-testid="CustomText-Color" class="jsx-2243148830">&nbsp;across the
                                        south-west,&nbsp;as well&nbsp;as northern Mexico&nbsp;(Yachnin, 2022).​​ </span>
                                </li>
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-2243148830">T</span><span
                                        data-testid="CustomText-Color" class="jsx-2292627359">he Colorado River waters
                                        more than </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">3 million
                                            acres</em></span><span data-testid="CustomText-Color"
                                        class="jsx-1376378763"> </span><span data-testid="CustomText-Color"
                                        class="jsx-256549535">(1.2 million hectares) o</span><span
                                        data-testid="CustomText-Color" class="jsx-2292627359">f farmland and grazing
                                        land, with</span><span data-testid="CustomText-Color" class="jsx-1033068395">
                                        agricultural water use comprising nearly</span><span
                                        data-testid="CustomText-Color" class="jsx-1376378763"> </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">80%</em></span><span
                                        data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                        data-testid="CustomText-Color" class="jsx-1183824233">of total consumption
                                        i</span><span data-testid="CustomText-Color" class="jsx-2292627359">n the basin,
                                        with </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">55%
                                        </em></span><span data-testid="CustomText-Color"
                                        class="jsx-1557423967">allocated to feed crops including alfalfa,</span><span
                                        data-testid="CustomText-Color" class="jsx-2292627359"> grass hay, haylage, and
                                        corn silage, reflecting the high demand for irrigation due to its geographical
                                        location and arid climate. (Yachnin, 2023).​ </span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-NxrKVT" id="n-NxrKVT" class="jsx-1305723053 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2505901888 separator-wrapper">
                            <hr class="jsx-2505901888 separator line-single">
                        </div>
                    </div>
                </div>
                <div data-blockid="n-gDkcko" id="n-gDkcko" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>

                <div data-blockid="n-DqSFDc" id="n-DqSFDc" class="jsx-3036348328 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-375145049 text-container">
                            <div class="jsx-375145049 link-spacer-container">
                                <div class="jsx-375145049 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby="californiasstake_:r3l:" class="jsx-375145049 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h2 id="californiasstake_:r3l:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">California's Stake</h2>
                            </div>
                        </div>
                    </div>
                </div> 

                <div data-blockid="n-O0j6Ac" id="n-O0j6Ac" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-DashAq" id="n-DashAq" class="jsx-1513615927 layout-wrapper">
                    <div class="grid-container">
                        <div class="izq">         
                            <div class="jsx-2499223119 content-wrapper">
                                <img alt=""
                                    class="jsx-3352559419 image jsx-3969215229 is-cropped is-fit"
                                    loading="lazy" width="498"
                                    height="501"
                                    src="./assets/story_img/the-colorado-river/map.png">
                                    <br>
  
                                <div data-blockid="n-1Uaf6h" id="n-1Uaf6h" class="jsx-3728417570 layout-wrapper">
                                    <div class="jsx-43199366 grid-item-lite">
                                        <div class="jsx-2727493191 text-viewer">
                                            <div class="jsx-2499223119 number-container">
                                                <div class="jsx-3853658730 place-number-traditional">1</div>
                                            </div>
                                            <br>
                                            <h3 id=":r3n:" data-testid="Heading"
                                                class="jsx-315162382 heading responsive jsx-3814754407">California's
                                                Stake</h3>
                                        </div>
                                    </div>
                                </div>
                                <div data-blockid="n-I3GLM3" id="n-I3GLM3" class="jsx-690966242 layout-wrapper">
                                    <div class="jsx-43199366 grid-item-lite">
                                        <div class="jsx-2727493191 text-viewer">
                                            <ul data-testid="BulletList"
                                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                                        data-testid="CustomText-Color" class="jsx-1063714973">The chart
                                                        here illustrates the distribution of the Colorado river among
                                                        seven states - </span><span data-testid="CustomText-Color"
                                                        class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                                            class="jsx-3ea0a99d3ffd0d56">Colorado, Wyoming, Utah, New
                                                            Mexico, Arizona, California, and Nevada.</em></span></li>
                                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                            data-testid="CustomText-Italic"
                                                            class="jsx-3ea0a99d3ffd0d56">California</em></span><span
                                                        data-testid="CustomText-Color" class="jsx-3842211387">
                                                    </span><span data-testid="CustomText-Color"
                                                        class="jsx-64242908">takes more water from the Colorado River
                                                        than any other state, and the majority of it is allocated to
                                                        the</span><span data-testid="CustomText-Color"
                                                        class="jsx-3842211387"> </span><span
                                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                            data-testid="CustomText-Italic"
                                                            class="jsx-3ea0a99d3ffd0d56">Imperial
                                                            Valley</em></span><span data-testid="CustomText-Color"
                                                        class="jsx-64242908"> in the southern part of the state.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="derecha">
                            <img alt=""
                            class="jsx-3352559419 image jsx-3969215229 is-cropped is-fit"
                            loading="lazy" width="498"
                            height="501"
                            src="./assets/story_img/the-colorado-river/californias_stake.png">
                        </div>
                       
                    </div>
                    <br>
                    <div class="grid-container">
                        <div class="izq"> 
                            <div class="jsx-2499223119 content-wrapper">
                                <div data-blockid="n-FgAfyz" id="n-FgAfyz" class="jsx-3728417570 layout-wrapper">
                                    <div class="jsx-43199366 grid-item-lite">
                                        <div class="jsx-2727493191 text-viewer">
                                            <div class="jsx-2499223119 number-container">
                                                <div class="jsx-3182421706 place-number-traditional outlined">2</div>
                                            </div>
                                            <br>
                                            <h3 id=":r3p:" data-testid="Heading"
                                                class="jsx-315162382 heading responsive jsx-3814754407">Water consumed
                                                by alfalfa </h3>
                                        </div>
                                    </div>
                                </div>
                                <div data-blockid="n-JZqJia" id="n-JZqJia" class="jsx-690966242 layout-wrapper">
                                    <div class="jsx-43199366 grid-item-lite">
                                        <div class="jsx-2727493191 text-viewer">
                                            <ul data-testid="BulletList"
                                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                                        data-testid="CustomText-Color"
                                                        class="jsx-64242908">P</span><span
                                                        data-testid="CustomText-Color"
                                                        class="jsx-3481559898">articularly, the usage by</span><span
                                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                            data-testid="CustomText-Italic"
                                                            class="jsx-3ea0a99d3ffd0d56"> alfalfa </em></span><span
                                                        data-testid="CustomText-Color" class="jsx-964098143">farmers,
                                                        which</span><span data-testid="CustomText-Color"
                                                        class="jsx-3481559898"> accounts for </span><span
                                                        data-testid="CustomText-Color" class="jsx-4032175127">80%,
                                                    </span><span data-testid="CustomText-Color"
                                                        class="jsx-3481559898">has drawn significant attention due to
                                                        its impact on</span><span data-testid="CustomText-Color"
                                                        class="jsx-3842211387"> </span><span
                                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                            data-testid="CustomText-Italic"
                                                            class="jsx-3ea0a99d3ffd0d56">water scarcity</em></span><span
                                                        data-testid="CustomText-Color" class="jsx-3842211387">
                                                    </span><span data-testid="CustomText-Color"
                                                        class="jsx-3481559898">and resource allocation (Torrella, 2023).
                                                    </span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="derecha">
                            <img alt=""
                            class="jsx-3352559419 image jsx-3969215229 is-cropped is-fit"
                            loading="lazy" width="498"
                            height="501"
                            src="./assets/story_img/the-colorado-river/water_consumed_by_alfalfa.png">
                        </div>
                       
                    </div>
                </div> 
                
                <div data-blockid="n-wlOTOO" id="n-wlOTOO" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-yWFNcd" id="n-yWFNcd" class="jsx-1305723053 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2505901888 separator-wrapper">
                            <hr class="jsx-2505901888 separator line-single">
                        </div>
                    </div>
                </div>
                <div data-blockid="n-N7f2Je" id="n-N7f2Je" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-Gz0wkf" id="n-Gz0wkf" class="jsx-3036348328 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-375145049 text-container">
                            <div class="jsx-375145049 link-spacer-container">
                                <div class="jsx-375145049 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby="waterirrigation_:r3t:" class="jsx-375145049 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h2 id="waterirrigation_:r3t:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Water Irrigation - CA, USA
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-438LKY" id="n-438LKY" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-5xbolK" id="n-5xbolK"
                    class="jsx-3740718615 float-layout start layout-wrapper is-draggable">
                    <div class="jsx-3740718615 float-wrapper start">
                        <figure class="jsx-2542711982 jsx-2439863738 media-wrapper">
                            <div class="jsx-2542711982 jsx-2439863738 media-wrapper-children preserve-aspect-ratio">
                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                    <div role="button" tabindex="0"
                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                        <div data-blockid="n-5xbolK"
                                            class="jsx-3420557937 image-container height-constrained jsx-2522058164">
                                            <div class="jsx-3420557937 inner-container">
                                                <img alt=""
                                                    class="jsx-3352559419 image jsx-3822368546 is-full-view"
                                                    loading="lazy" width="613" height="408"
                                                    src="./assets/story_img/the-colorado-river/water_irrigation_ca_usa.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <figcaption id="caption-:r40:" class="jsx-3172456529 caption">
                                <p data-testid="TextInput" class="text-input"><span data-testid="CustomText-Color"
                                        class="jsx-1341826036">Irrigation system for Alfalfa&nbsp;licensed
                                        under&nbsp;</span><a target="_blank" rel="noreferrer noopener" data-id="byYGJyK"
                                        href="https://creativecommons.org/licenses/by/3.0/"
                                        class="jsx-327186198 underline-dotted"><span contenteditable="false"
                                            class="jsx-1f24ab0bc0e7a45f">&nbsp;</span><span
                                            data-testid="CustomText-Color" class="jsx-1341826036">CC BY - NC</span><span
                                            contenteditable="false" class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a><span
                                        data-testid="CustomText-Color" class="jsx-1341826036">​</span></p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div data-blockid="n-ZpdVBi" id="n-ZpdVBi" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <ul data-testid="BulletList"
                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-4223169629">The&nbsp;</span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">Imperial
                                            Valley</em></span><span data-testid="CustomText-Color"
                                        class="jsx-3842211387">&nbsp;</span><span data-testid="CustomText-Color"
                                        class="jsx-4223169629">is an ideal location for crops such as&nbsp;</span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">alfalfa</em></span><span
                                        data-testid="CustomText-Color" class="jsx-3842211387">&nbsp;</span><span
                                        data-testid="CustomText-Color" class="jsx-4223169629">(Lakhani,
                                        2023).&nbsp;To&nbsp;cultivate crops in such an arid region, farmers have
                                        historically utilized&nbsp;more water&nbsp;than&nbsp;the river can sustainably
                                        provide. This practice, which is echoed around the globe,&nbsp;underscores the
                                        critical challenge of maintaining&nbsp;access to water&nbsp;while
                                        avoiding&nbsp;over-exploitation.​</span></li>
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">Alfalfa&nbsp;</em></span><span
                                        data-testid="CustomText-Color" class="jsx-4223169629">can be irrigated using
                                    </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">pressurized
                                            systems</em></span><span data-testid="CustomText-Color"
                                        class="jsx-3842211387"> </span><span data-testid="CustomText-Color"
                                        class="jsx-4223169629">such as&nbsp;</span><span data-testid="CustomText-Color"
                                        class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">sprinklers&nbsp;</em></span><span
                                        data-testid="CustomText-Color" class="jsx-4223169629">or&nbsp;drip
                                        irrigation&nbsp;being commonly employed due to their water
                                        application&nbsp;efficiency, which is crucial in areas where&nbsp;</span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">water
                                            conservation</em></span><span data-testid="CustomText-Color"
                                        class="jsx-4223169629">&nbsp;is essential (Hrozencik,&nbsp;2017).</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-EuWsGP" id="n-EuWsGP" class="jsx-1305723053 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2505901888 separator-wrapper">
                            <hr class="jsx-2505901888 separator line-single">
                        </div>
                    </div>
                </div>
                <div data-blockid="n-yYU0uj" id="n-yYU0uj" class="jsx-3036348328 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-375145049 text-container">
                            <div class="jsx-375145049 link-spacer-container">
                                <div class="jsx-375145049 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby="alfalfaproduction_:r42:" class="jsx-375145049 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h2 id="alfalfaproduction_:r42:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Alfalfa Production - CA, USA
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-ecTQGz" id="n-ecTQGz" class="jsx-1305723053 layout-wrapper">
                    <div class="jsx-43199366 grid-item-lite">
                        <figure class="jsx-3191748012 jsx-2439863738 media-wrapper">
                            <div class="jsx-3191748012 jsx-2439863738 media-wrapper-children">
                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                    <div role="button" tabindex="0"
                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                        <div data-blockid="n-ecTQGz"
                                            class="jsx-248956040 image-container jsx-2522058164">
                                            <div class="jsx-248956040 inner-container">
                                                <img alt=""
                                                    class="jsx-3352559419 image jsx-3822368546" loading="lazy"
                                                    width="4400" height="2976"
                                                    src="./assets/story_img/the-colorado-river/alfalfa_production.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <figcaption id="caption-:r44:" class="jsx-3172456529 caption">
                                <p data-testid="TextInput" class="text-input">Alfalfa Production licensed under <a
                                        target="_blank" rel="noreferrer noopener" data-id="GNjHJ9B"
                                        href="https://creativecommons.org/licenses/by-nc/3.0/"
                                        class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                            class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>CC BY-NC<span
                                            contenteditable="false" class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a></p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div data-blockid="n-dHJSto" id="n-dHJSto" class="jsx-3728417570 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-508825460 text-container">
                            <div class="jsx-508825460 link-spacer-container">
                                <div class="jsx-508825460 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby=":r45:" class="jsx-508825460 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h4 id=":r45:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Water Footprint of Alfalfa
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-aoKFSb" id="n-aoKFSb"
                    class="jsx-3740718615 float-layout start layout-wrapper is-draggable">
                    <div class="jsx-3740718615 float-wrapper start">
                        <figure class="jsx-4205848973 jsx-2439863738 media-wrapper">
                            <div class="jsx-4205848973 jsx-2439863738 media-wrapper-children">
                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                    <div role="button" tabindex="0"
                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                        <div data-blockid="n-aoKFSb"
                                            class="jsx-4019594102 image-container jsx-2522058164">
                                            <div class="jsx-4019594102 inner-container">
                                                <img alt=""
                                                    class="jsx-3352559419 image jsx-1134208798" loading="lazy"
                                                    width="930" height="560"
                                                    src="./assets/story_img/the-colorado-river/water_use.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <figcaption id="caption-:r46:" class="jsx-3172456529 caption">
                                <p data-testid="TextInput" class="text-input"></p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div data-blockid="n-McDN0C" id="n-McDN0C" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">&nbsp;</span><span
                                    data-testid="CustomText-Color" class="jsx-4032175127"><em
                                        data-testid="CustomText-Italic"
                                        class="jsx-3ea0a99d3ffd0d56">Alfalfa&nbsp;</em></span><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">is the most&nbsp;</span><span
                                    data-testid="CustomText-Color" class="jsx-4032175127"><em
                                        data-testid="CustomText-Italic"
                                        class="jsx-3ea0a99d3ffd0d56">water-intensive&nbsp;</em></span><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">crop grown in California,
                                    requiring&nbsp;5.1&nbsp;feet of water&nbsp;(Cooley, 2015). Alfalfa cultivation,
                                    vital for the&nbsp;</span><span data-testid="CustomText-Color"
                                    class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                        class="jsx-3ea0a99d3ffd0d56">livestock</em></span><span
                                    data-testid="CustomText-Color" class="jsx-3842211387">&nbsp;</span><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">industry, exacerbates
                                    water&nbsp;scarcity issues, especially in&nbsp;</span><span
                                    data-testid="CustomText-Color" class="jsx-4032175127"><em
                                        data-testid="CustomText-Italic"
                                        class="jsx-3ea0a99d3ffd0d56">drought-prone</em></span><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">&nbsp;regions like
                                    the&nbsp;</span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                        data-testid="CustomText-Italic"
                                        class="jsx-3ea0a99d3ffd0d56">Colorado</em></span><span
                                    data-testid="CustomText-Color" class="jsx-2561276702">&nbsp;River Basin.​</span></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-JWRvFs" id="n-JWRvFs" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-vV1L69" id="n-vV1L69" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-7QDV4z" id="n-7QDV4z" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-fqZXGT" id="n-fqZXGT" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-4AJ0ff" id="n-4AJ0ff" class="jsx-3728417570 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-508825460 text-container">
                            <div class="jsx-508825460 link-spacer-container">
                                <div class="jsx-508825460 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby=":r4c:" class="jsx-508825460 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h4 id=":r4c:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Allocation of water from the
                                    Colorado River among crops in CA, USA.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-VhU1Mc" id="n-VhU1Mc" class="jsx-1513615927 layout-wrapper">
                    <div class="jsx-2401134527 immersive-container">
                        <div class="jsx-3f9ea0437cf4a588 immersive sidecar jsx-1000321570 slideshow"><span
                                class="jsx-3f9ea0437cf4a588 first-create-dock-node first-create-node sentinel-sidecar jsx-1933451343"></span><span
                                class="jsx-3f9ea0437cf4a588 sentinel-top-docked top-sentinel sentinel-sidecar jsx-2380784448"></span><span
                                class="jsx-3f9ea0437cf4a588 sentinel-bottom-docked bottom-sentinel sentinel-sidecar jsx-1267272323"></span><span
                                class="sentinel-top-interactable"></span><span
                                class="sentinel-bottom-interactable"></span>
                            <div data-testid="nav-dots-container"
                                class="jsx-3487873800 jsx-3067500324 nav-dots-container">
                                <div class="jsx-3487873800 jsx-3067500324 nav-dots-wrapper horizontal">
                                    <ul class="jsx-3457483899 nav-dots horizontal">
                                        <li class="jsx-3929248709 nav-dot selected"><button aria-label="Slide 1"
                                                class="jsx-3929248709 dot-button"></button></li>
                                        <li class="jsx-3929248709 nav-dot"><button aria-label="Slide 2"
                                                class="jsx-3929248709 dot-button"></button></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="jsx-3367499604 arrow-container-outer end"><button aria-label="Next slide"
                                    class="jsx-3367499604 arrow-container-inner fit-content">
                                    <div class="jsx-3367499604 button-container can-dock">
                                        <div class="overlay-button jsx-3850199254">
                                            <div class="inner-container jsx-3850199254"><span
                                                    class="jsx-2753355602 icon"><svg viewBox="0 0 24 24"
                                                        aria-hidden="true" class="jsx-2753355602">
                                                        <path d="M8.793 5h1.414l7 7-7 7H8.793l7-7z"
                                                            class="jsx-2753355602"></path>
                                                    </svg></span></div>
                                        </div>
                                    </div>
                                </button></div>
                            <div data-blockid="n-L6XyU8"
                                class="jsx-3115549450 immersive-narrative-panel slideshow visible first bottom-spacing vertical-start">
                                <span class="jsx-894698023 landmark"></span><span
                                    class="jsx-3115549450 sentinel"></span>
                                <div class="jsx-3512300925 content-container full-width">
                                    <div class="jsx-1978474844 grid-container">
                                        <div aria-hidden="true" class="jsx-472442185 white-space-item"></div>
                                        <div class="jsx-915691696 grid-item">
                                            <div class="jsx-10827080 narrative-panel-wrapper selected">
                                                <div data-blockid="n-pZBYUy" id="n-pZBYUy"
                                                    class="jsx-690966242 layout-wrapper">
                                                    <div class="jsx-43199366 grid-item-lite">
                                                        <div class="jsx-2727493191 text-viewer">
                                                            <ul data-testid="BulletList"
                                                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                                                <li data-testid="ListItem"
                                                                    class="jsx-3057927435 jsx-4024294980"><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-667435231">Analysis indicates that
                                                                        alfalfa is among the most water-intensive crops,
                                                                        consuming over a </span><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">million gallons
                                                                            of Colorado River</em></span><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-667435231"> water, showcasing the
                                                                        scale at which water resources are dedicated to
                                                                        livestock feed production in </span><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">California
                                                                        </em></span><span data-testid="CustomText-Color"
                                                                        class="jsx-667435231">(K.Torella, 2023)</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="jsx-809857508 jsx-1892766130 container full-screen main">
                                <div class="jsx-4149835010 pageable-item">
                                    <div class="jsx-2685999826 media-panel">
                                        <figure class="jsx-3778046943 jsx-2439863738 media-wrapper fill-parent">
                                            <div
                                                class="jsx-3778046943 jsx-2439863738 media-wrapper-children fill-parent">
                                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                                    <div role="button" tabindex="0"
                                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                                        <div data-blockid="n-3983gE"
                                                            class="jsx-1410773851 image-container fill-parent is-fit jsx-1220048055">
                                                            <div class="jsx-1410773851 inner-container">
                                                                <img alt=""
                                                                    class="jsx-3352559419 image jsx-4047871960 is-cropped is-fit"
                                                                    loading="lazy" width="1039" height="703"
                                                                    src="./assets/story_img/the-colorado-river/water_received.png">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div data-blockid="n-mRQSBb"
                                class="jsx-3115549450 immersive-narrative-panel slideshow last bottom-spacing vertical-start">
                                <span class="jsx-894698023 landmark"></span><span
                                    class="jsx-3115549450 sentinel"></span>
                                <div class="jsx-3512300925 content-container full-width">
                                    <div class="jsx-1978474844 grid-container">
                                        <div aria-hidden="true" class="jsx-472442185 white-space-item"></div>
                                        <div class="jsx-915691696 grid-item">
                                            <div class="jsx-10827080 narrative-panel-wrapper">
                                                <div data-blockid="n-PMSuVd" id="n-PMSuVd"
                                                    class="jsx-690966242 layout-wrapper">
                                                    <div class="jsx-43199366 grid-item-lite">
                                                        <div class="jsx-2727493191 text-viewer">
                                                            <ul data-testid="BulletList"
                                                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                                                <li data-testid="ListItem"
                                                                    class="jsx-3057927435 jsx-4024294980">Despite
                                                                    concerns regarding water usage, <span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">alfalfa
                                                                        </em></span>remains lucrative for farmers due to
                                                                    high domestic and <span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">international
                                                                            demand,</em></span> shaping market dynamics
                                                                    and presenting policymakers with challenges in
                                                                    balancing financial gains with <span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">conservation</em></span>
                                                                    efforts.&nbsp;</li>
                                                                <li data-testid="ListItem"
                                                                    class="jsx-3057927435 jsx-4024294980">This
                                                                    underlines the fact that <span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">decrease</em></span>
                                                                    in water levels lead to decrease in <span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">alfalfa
                                                                            production</em></span><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-3842211387"> </span>which impacts
                                                                    the<em data-testid="CustomText-Italic"
                                                                        class="jsx-3ea0a99d3ffd0d56"> </em><span
                                                                        data-testid="CustomText-Color"
                                                                        class="jsx-4032175127"><em
                                                                            data-testid="CustomText-Italic"
                                                                            class="jsx-3ea0a99d3ffd0d56">price
                                                                        </em></span>of alfalfa. </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="jsx-809857508 jsx-1892766130 container full-screen main">
                                <div class="jsx-1672455938 pageable-item">
                                    <div class="jsx-2685999826 media-panel">
                                        <figure class="jsx-3778046943 jsx-2439863738 media-wrapper fill-parent">
                                            <div
                                                class="jsx-3778046943 jsx-2439863738 media-wrapper-children fill-parent">
                                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                                    <div role="button" tabindex="0"
                                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                                        <div data-blockid="n-mlGTJk"
                                                            class="jsx-115959619 image-container fill-parent is-fit jsx-1220048055">
                                                            <div class="jsx-115959619 inner-container">
                                                                <img alt=""
                                                                    class="jsx-3352559419 image jsx-3969215229 is-cropped is-fit"
                                                                    loading="lazy" width="878" height="855"
                                                                    src="./assets/story_img/the-colorado-river/cropland.png">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><span class="jsx-63711278 icon-information jsx-3608073122"><button
                                                        aria-label="Attribution" class="jsx-538326453 popover-target"
                                                        aria-expanded="false"><span class="jsx-3680692050 icon"><svg
                                                                viewBox="0 0 16 16" aria-hidden="true"
                                                                class="jsx-3680692050">
                                                                <path
                                                                    d="M7.5 2.5a1 1 0 1 1 1 1 1 1 0 0 1-1-1zM9 14V5H7v1h1v8H7v1h3v-1z"
                                                                    class="jsx-3680692050"></path>
                                                            </svg></span></button></span>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-ZOwofv" id="n-ZOwofv" class="jsx-1305723053 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2505901888 separator-wrapper">
                            <hr class="jsx-2505901888 separator line-single">
                        </div>
                    </div>
                </div>
                <div data-blockid="n-a0HF0t" id="n-a0HF0t" class="jsx-3036348328 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-375145049 text-container">
                            <div class="jsx-375145049 link-spacer-container">
                                <div class="jsx-375145049 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby="exports_:r4f:" class="jsx-375145049 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h2 id="exports_:r4f:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Exports - CA, USA</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-iDr7rl" id="n-iDr7rl" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-xgOd93" id="n-xgOd93" class="jsx-3728417570 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-508825460 text-container">
                            <div class="jsx-508825460 link-spacer-container">
                                <div class="jsx-508825460 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby=":r4h:" class="jsx-508825460 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h4 id=":r4h:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">Global Demand Impacting
                                    local production</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-GLgfvC" id="n-GLgfvC"
                    class="jsx-3740718615 float-layout end layout-wrapper is-draggable">
                    <div class="jsx-3740718615 float-wrapper end">
                        <figure class="jsx-2079883271 jsx-2439863738 media-wrapper">
                            <div class="jsx-2079883271 jsx-2439863738 media-wrapper-children">
                                <div class="jsx-1966727344 jsx-2575393672 parent">
                                    <div role="button" tabindex="0"
                                        class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                        <div data-blockid="n-GLgfvC"
                                            class="jsx-2435219292 image-container jsx-2522058164">
                                            <div class="jsx-2435219292 inner-container">
                                                <img alt=""
                                                    class="jsx-3352559419 image jsx-3822368546" loading="lazy"
                                                    width="1116" height="716"
                                                    src="./assets/story_img/the-colorado-river/exports.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <figcaption id="caption-:r4j:" class="jsx-3172456529 caption">
                                <p data-testid="TextInput" class="text-input">Cattle at a feedlot. licensed under <a
                                        target="_blank" rel="noreferrer noopener" data-id="8XAFgqQ"
                                        href="https://creativecommons.org/licenses/by-nc/3.0/"
                                        class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                            class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>CC BY-NC<span
                                            contenteditable="false" class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a></p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div data-blockid="n-82brzM" id="n-82brzM" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <ul data-testid="BulletList"
                                class="jsx-1201566770 generic-list bullet-list responsive medium">
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-964098143">Increasing </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">demand</em></span><span
                                        data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                        data-testid="CustomText-Color" class="jsx-964098143">for dairy products in the
                                    </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">Middle East
                                        </em></span><span data-testid="CustomText-Color" class="jsx-964098143">and
                                        skyrocketing beef consumption worldwide are boosting demand; in 2020, 40% of
                                        California's alfalfa was shrink-wrapped, containerized, and shipped globally to
                                        feed</span><span data-testid="CustomText-Color" class="jsx-3842211387">
                                    </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">livestock</em></span><span
                                        data-testid="CustomText-Color" class="jsx-964098143">. (Naishadham,
                                        2023).</span></li>
                                <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                        data-testid="CustomText-Color" class="jsx-964098143">The United Arab
                                        Emirates-owned </span><span data-testid="CustomText-Color"
                                        class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">Al Dahra ACX </em></span><span
                                        data-testid="CustomText-Color" class="jsx-964098143">Global Inc. grows forage
                                        crops in </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic"
                                            class="jsx-3ea0a99d3ffd0d56">California</em></span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127">, </span><span
                                        data-testid="CustomText-Color" class="jsx-964098143">and is a major </span><span
                                        data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">North
                                            American</em></span><span data-testid="CustomText-Color"
                                        class="jsx-964098143"> exporter of hay, by which they have faced two years of
                                        federal </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">water
                                            cuts</em></span><span data-testid="CustomText-Color" class="jsx-964098143">
                                        from the Colorado River, a primary water source for the state. (Naishadham,
                                        2023)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-W0lrPe" id="n-W0lrPe" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-pX90ec" id="n-pX90ec" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-lghH5u" id="n-lghH5u" class="jsx-3728417570 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-508825460 text-container">
                            <div class="jsx-508825460 link-spacer-container">
                                <div class="jsx-508825460 link-spacer"><button aria-label="Copy section link"
                                        aria-describedby=":r4n:" class="jsx-508825460 link-button"><span
                                            class="jsx-232369701 icon"><svg viewBox="0 0 16 16" aria-hidden="true"
                                                class="jsx-232369701">
                                                <path
                                                    d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                    class="jsx-232369701"></path>
                                            </svg></span></button></div>
                            </div>
                            <div class="jsx-2727493191 text-viewer">
                                <h4 id=":r4n:" data-testid="Heading"
                                    class="jsx-315162382 heading responsive jsx-3814754407">California's Alfalfa Export
                                    Dynamics</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-tsHGFp" id="n-tsHGFp" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                


                <div data-blockid="n-2MuLgo" id="n-2MuLgo" class="jsx-1513615927 layout-wrapper">
                    <div class="jsx-3f9ea0437cf4a588 immersive sidecar jsx-1000321570 docked-panel"><span
                            class="jsx-3f9ea0437cf4a588 first-create-dock-node"></span><span
                            class="jsx-3f9ea0437cf4a588 sentinel-top-docked top-sentinel sentinel-sidecar jsx-2380784448"></span><span
                            class="jsx-3f9ea0437cf4a588 sentinel-bottom-docked bottom-sentinel sentinel-sidecar jsx-689937321"></span>
                        <div data-testid="nav-dots-container"
                            class="jsx-3487873800 jsx-3067500324 nav-dots-container no-show">
                            <div class="jsx-3487873800 jsx-3067500324 nav-dots-wrapper vertical">
                                <ul class="jsx-3457483899 nav-dots vertical integrated">
                                    <li class="jsx-3929248709 nav-dot selected"><button aria-label="Slide 1"
                                            class="jsx-3929248709 dot-button integrated"></button></li>
                                </ul>
                            </div>
                        </div>
                        <div class="grid-container-mas">
                            
                            <div class="izq-mas">  
                                <div data-blockid="n-vZ6K2q"
                            class="jsx-3115549450 immersive-narrative-panel jsx-1878338049 first last docked bottom-spacing">
                            <span class="jsx-430516373 landmark"></span><span class="jsx-3115549450 sentinel"></span>
                            <div data-blockid="n-QCimgH" id="n-QCimgH" class="jsx-690966242 layout-wrapper">
                                <div class="jsx-43199366 grid-item-lite">
                                    <div class="jsx-2727493191 text-viewer">
                                        <ul data-testid="BulletList"
                                            class="jsx-1201566770 generic-list bullet-list responsive medium">
                                            <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">As
                                                </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                        data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">water scarcity</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268"> from the
                                                    Colorado River affects </span><span data-testid="CustomText-Color"
                                                    class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">alfalfa production</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">, the
                                                    resulting decrease in supply can lead to increased</span><span
                                                    data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                                    data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                        data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">export values,</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268"> driven by
                                                    high international </span><span data-testid="CustomText-Color"
                                                    class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">demand</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">and reduced
                                                    competition.</span></li>
                                            <li data-testid="ListItem" class="jsx-3057927435 jsx-4024294980"><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">The strategic
                                                    management of</span><span data-testid="CustomText-Color"
                                                    class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56"> water</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                                    data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                        data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">resources </em></span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">and
                                                </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                        data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">alfalfa</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">production can
                                                    influence</span><span data-testid="CustomText-Color"
                                                    class="jsx-4032175127"><em data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56"> export </em></span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">dynamics,
                                                    showcasing the need for sustainable practices to balance
                                                </span><span data-testid="CustomText-Color" class="jsx-4032175127"><em
                                                        data-testid="CustomText-Italic"
                                                        class="jsx-3ea0a99d3ffd0d56">economic</em></span><span
                                                    data-testid="CustomText-Color" class="jsx-3842211387"> </span><span
                                                    data-testid="CustomText-Color" class="jsx-1607562268">interests with
                                                    environmental conservation.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> </div>
                            <div class="derecha-mas"> 
                                <div class="jsx-4205848973 jsx-2439863738 media-wrapper-children">
                                    <div class="jsx-1966727344 jsx-2575393672 parent">
                                        <div role="button" tabindex="0"
                                            class="jsx-1966727344 jsx-2575393672 reparent-wrapper">
                                            <div data-blockid="n-aoKFSb"
                                                class="jsx-4019594102 image-container jsx-2522058164">
                                                <div class="jsx-4019594102 inner-container">
                                                    <img alt=""
                                                        class="jsx-3352559419 image jsx-1134208798" loading="lazy"
                                                        width="1057" height="635"
                                                        src="./assets/story_img/the-colorado-river/Californias_Alfalfa_Export_Dynamics.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <img alt=""
                                    class="jsx-3352559419 image jsx-3969215229 is-cropped is-fit"
                                    loading="lazy" width="1057" height="635"
                                    src="./assets/story_img/the-colorado-river/Californias_Alfalfa_Export_Dynamics.png"> -->
                                                                
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                <div data-blockid="n-2K1Qva" id="n-2K1Qva" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-xDLvHg" id="n-xDLvHg" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div data-blockid="n-wiInHr" id="n-wiInHr" class="jsx-690966242 layout-wrapper">
                    <div class="jsx-1732939524 grid-item-lite">
                        <div class="jsx-2727493191 text-viewer">
                            <p data-testid="Paragraph" class="jsx-1871000441 jsx-3865404721 medium responsive"></p>
                        </div>
                    </div>
                </div>
                <div class="jsx-2605853879 story-content-clearfix"></div>
                <footer class="jsx-3718694568 story-footer drag-sentinel">
                    <div class="credits-container">
                        <div data-blockid="n-LjLa8L" id="n-LjLa8L" class="jsx-3728417570 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-508825460 text-container">
                                    <div class="jsx-508825460 link-spacer-container">
                                        <div class="jsx-508825460 link-spacer"><button aria-label="Copy section link"
                                                aria-describedby="sources_:r4t:" class="jsx-508825460 link-button"><span
                                                    class="jsx-232369701 icon"><svg viewBox="0 0 16 16"
                                                        aria-hidden="true" class="jsx-232369701">
                                                        <path
                                                            d="M5 9.5a4.505 4.505 0 0 0 .048.493c-.007-.007-.016-.011-.023-.018l-3-3a3.5 3.5 0 1 1 4.95-4.95l3 3a3.486 3.486 0 0 1-1.31 5.76l-.226-.225a1.484 1.484 0 0 1-.37-.631 2.495 2.495 0 0 0 1.199-4.197l-3-3a2.5 2.5 0 1 0-3.536 3.536L5.09 8.625A4.498 4.498 0 0 0 5 9.5zm11 3a3.475 3.475 0 0 0-1.025-2.475l-3-3c-.007-.007-.016-.011-.023-.018A4.505 4.505 0 0 1 12 7.5a4.498 4.498 0 0 1-.089.876l2.357 2.356a2.5 2.5 0 0 1-.009 3.544 2.561 2.561 0 0 1-3.527-.008l-3-3a2.5 2.5 0 0 1-.094-3.436 2.43 2.43 0 0 1 1.3-.74 1.485 1.485 0 0 0-.377-.652l-.222-.222a3.482 3.482 0 0 0-1.314 5.757l3 3a3.5 3.5 0 0 0 4.937.012l-.351-.355.352.355A3.476 3.476 0 0 0 16 12.5z"
                                                            class="jsx-232369701"></path>
                                                    </svg></span></button></div>
                                    </div>
                                    <div class="jsx-2727493191 text-viewer">
                                        <h4 id="sources_:r4t:" data-testid="Heading"
                                            class="jsx-315162382 heading responsive jsx-3814754407">Sources</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-Y8hCnu" id="n-Y8hCnu" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">
                                        Naishadham, S. (2023, April 28). In drought-stricken Arizona, fresh scrutiny of
                                        Saudi Arabia-owned farm’s water use. PBS NewsHour. <a target="_blank"
                                            rel="noreferrer noopener" data-id="-DDd0cC"
                                            href="https://www.pbs.org/newshour/politics/in-drought-stricken-arizona-fresh-scrutiny-of-saudi-arabia-owned-farms-water-use"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>https://www.pbs.org/newshour/politics/in-drought-stricken-arizona-fresh-scrutiny-of-saudi-arabia-owned-farms-water-use<span
                                                contenteditable="false" class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-rnB8hi" id="n-rnB8hi" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">Lakhani,
                                        N. (2023, May 31). The farmers dealing with water shortages even before historic
                                        Colorado River deal. The Guardian. <a target="_blank" rel="noreferrer noopener"
                                            data-id="MrwzS8b"
                                            href="https://www.theguardian.com/global/2023/may/31/arizona-farmers-water-colorado-river-cuts"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>https://www.theguardian.com/global/2023/may/31/arizona-farmers-water-colorado-river-cuts<span
                                                contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>&nbsp;&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-GhtMna" id="n-GhtMna" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">Misachi,
                                        John. “Which Country Has the Most Fresh Water?” WorldAtlas, WorldAtlas, 15 Mar.
                                        2018, <a target="_blank" rel="noreferrer noopener" data-id="zbiS3EY"
                                            href="http://www.worldatlas.com/articles/countries-with-the-most-freshwater-resources.html"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>www.worldatlas.com/articles/countries-with-the-most-freshwater-resources.html<span
                                                contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>.&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-hOZhXd" id="n-hOZhXd" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption"> Cooley,
                                        H. (n.d.). Need to know. Pacinst.org. Retrieved February 7, 2024, from <a
                                            target="_blank" rel="noreferrer noopener" data-id="_jDTx-U"
                                            href="https://pacinst.org/wp-content/uploads/2015/04/CA-Ag-Water-Use.pdf"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>https://pacinst.org/wp-content/uploads/2015/04/CA-Ag-Water-Use.pdf<span
                                                contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-sgOzS9" id="n-sgOzS9" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">J.
                                        Yachnin, “Can alfalfa survive a fight over Colorado River water?,” E&amp;E News
                                        by POLITICO, Sep. 08, 2023. <a target="_blank" rel="noreferrer noopener"
                                            data-id="gHV7M69"
                                            href="https://www.eenews.net/articles/can-alfalfa-survive-a-fight-over-colorado-river-water/#:~:text=The%20research%20showed%20that%20irrigated,hay%2C%20haylage%20and%20corn%20silage"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>https://www.eenews.net/articles/can-alfalfa-survive-a-fight-over-colorado-river-water/#:~:text=The%20research%20showed%20that%20irrigated,hay%2C%20haylage%20and%20corn%20silage<span
                                                contenteditable="false" class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-cGLL5T" id="n-cGLL5T" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">K.
                                        Torrella, “Why is the Colorado river drying up? Feeding cows is a large
                                        part,” Vox, Apr. 10, 2023. <a target="_blank" rel="noreferrer noopener"
                                            data-id="BIhZ_k-"
                                            href="https://www.vox.com/the-highlight/23655640/colorado-river-water-alfalfa-dairy-beef-meat"
                                            class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>https://www.vox.com/the-highlight/23655640/colorado-river-water-alfalfa-dairy-beef-meat<span
                                                contenteditable="false"
                                                class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a>.&nbsp;&nbsp;Hrozencik</p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-53UwWA" id="n-53UwWA" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">Hrozencik,
                                        “USDA ERS - Irrigation &amp; Water Use,”&nbsp;<em
                                            data-testid="CustomText-Italic" class="jsx-3ea0a99d3ffd0d56">Usda.gov</em>,
                                        2017.
                                        https://www.ers.usda.gov/topics/farm-practices-management/irrigation-water-use/
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-Lvj1kc" id="n-Lvj1kc" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">‌</p>
                                </div>
                            </div>
                        </div>
                        <div data-blockid="n-uZtRZk" id="n-uZtRZk" class="jsx-690966242 layout-wrapper">
                            <div class="jsx-1732939524 grid-item-lite">
                                <div class="jsx-2727493191 text-viewer">
                                    <p data-testid="Paragraph"
                                        class="jsx-1871000441 jsx-3865404721 medium responsive block-caption">&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </article>
        </main>
        <div class="jsx-3794216292 jsx-3655090622 full-screen-modal">
            <div class="jsx-3794216292 jsx-3655090622 header">
                <div class="jsx-3794216292 jsx-3655090622 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-3655090622 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-3655090622"></figure>
        </div>
        <div class="jsx-3794216292 jsx-3655090622 full-screen-modal">
            <div class="jsx-3794216292 jsx-3655090622 header">
                <div class="jsx-3794216292 jsx-3655090622 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-3655090622 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-3655090622"></figure>
        </div>
        <div class="jsx-3794216292 jsx-2934554792 full-screen-modal">
            <div class="jsx-3794216292 jsx-2934554792 header">
                <div class="jsx-3794216292 jsx-2934554792 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-2934554792 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-2934554792"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;613px&#39; height=&#39;408px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-2934554792 placeholder-image">
                <div class="jsx-3794216292 jsx-2934554792 expanded-caption-container">
                    <figcaption id="caption-:r3v:" class="jsx-3172456529 caption">
                        <p data-testid="TextInput" class="text-input"><span data-testid="CustomText-Color"
                                class="jsx-1341826036">Irrigation system for Alfalfa&nbsp;licensed under&nbsp;</span><a
                                target="_blank" rel="noreferrer noopener" data-id="sm32wHB"
                                href="https://creativecommons.org/licenses/by/3.0/"
                                class="jsx-327186198 underline-dotted"><span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span><span data-testid="CustomText-Color"
                                    class="jsx-1341826036">CC BY - NC</span><span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a><span data-testid="CustomText-Color"
                                class="jsx-1341826036">​</span></p>
                    </figcaption>
                </div>
            </figure>
        </div>
        <div class="jsx-3794216292 jsx-2047162628 full-screen-modal">
            <div class="jsx-3794216292 jsx-2047162628 header">
                <div class="jsx-3794216292 jsx-2047162628 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-2047162628 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-2047162628">
                <img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;4400px&#39; height=&#39;2976px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-2047162628 placeholder-image">
                <div class="jsx-3794216292 jsx-2047162628 expanded-caption-container">
                    <figcaption id="caption-:r43:" class="jsx-3172456529 caption">
                        <p data-testid="TextInput" class="text-input">Alfalfa Production licensed under <a
                                target="_blank" rel="noreferrer noopener" data-id="f2UNpM3"
                                href="https://creativecommons.org/licenses/by-nc/3.0/"
                                class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>CC BY-NC<span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a></p>
                    </figcaption>
                </div>
            </figure>
        </div>
        <div class="jsx-3794216292 jsx-1882938873 full-screen-modal">
            <div class="jsx-3794216292 jsx-1882938873 header">
                <div class="jsx-3794216292 jsx-1882938873 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-1882938873 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-1882938873"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;930px&#39; height=&#39;560px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-1882938873 placeholder-image"></figure>
        </div>
        <div class="jsx-3794216292 jsx-1155925912 full-screen-modal">
            <div class="jsx-3794216292 jsx-1155925912 header">
                <div class="jsx-3794216292 jsx-1155925912 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-1155925912 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-1155925912"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;1039px&#39; height=&#39;703px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-1155925912 placeholder-image"></figure>
        </div>
        <div class="jsx-3794216292 jsx-2075909530 full-screen-modal">
            <div class="jsx-3794216292 jsx-2075909530 header">
                <div class="jsx-3794216292 jsx-2075909530 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-2075909530 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-2075909530"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;878px&#39; height=&#39;855px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-2075909530 placeholder-image"></figure>
        </div>
        <div class="jsx-3794216292 jsx-725024804 full-screen-modal">
            <div class="jsx-3794216292 jsx-725024804 header">
                <div class="jsx-3794216292 jsx-725024804 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-725024804 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-725024804"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;1116px&#39; height=&#39;716px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-725024804 placeholder-image">
                <div class="jsx-3794216292 jsx-725024804 expanded-caption-container">
                    <figcaption id="caption-:r4i:" class="jsx-3172456529 caption">
                        <p data-testid="TextInput" class="text-input">Cattle at a feedlot. licensed under <a
                                target="_blank" rel="noreferrer noopener" data-id="zpsDqeG"
                                href="https://creativecommons.org/licenses/by-nc/3.0/"
                                class="jsx-217766488 underline-dotted"><span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span>CC BY-NC<span contenteditable="false"
                                    class="jsx-1f24ab0bc0e7a45f">&nbsp;</span></a></p>
                    </figcaption>
                </div>
            </figure>
        </div>
        <div class="jsx-3794216292 jsx-1302136560 full-screen-modal">
            <div class="jsx-3794216292 jsx-1302136560 header">
                <div class="jsx-3794216292 jsx-1302136560 end-items"><button aria-label="Close"
                        class="jsx-3794216292 jsx-1302136560 close-button"><span class="jsx-1159706149 icon"><svg
                                viewBox="0 0 24 24" aria-hidden="true" class="jsx-1159706149">
                                <path
                                    d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"
                                    class="jsx-1159706149"></path>
                            </svg></span></button></div>
            </div>
            <figure class="jsx-3794216292 jsx-1302136560"><img
                    src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; width=&#39;1057px&#39; height=&#39;635px&#39; %3E%3C/svg%3E"
                    alt="" loading="lazy" class="jsx-3794216292 jsx-1302136560 placeholder-image"></figure>
        </div>
    </div>
</div>

<script src="./files/99779-ef9211dcf5ce070e.js.download"></script>
<script src="./files/87301-383a68bdcd6a073e.js.download"></script>
<script src="./files/45408-eccc752c5ca8b56d.js.download"></script>
<script src="./files/58238-a84c04464ef788d2.js.download"></script>
<script src="./files/52498-f55284ed3816be1d.js.download"></script>
<script src="./files/84246-57cc88adb2de3ab5.js.download"></script>
<script src="./files/76187-92946473059fd7b8.js.download"></script>
<script src="./files/78779-9949143b08c3f445.js.download"></script>
<script src="./files/stories-fd0c02af20c4bc6b.js.download"></script>