import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  ViewContainerRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { WildfireComponent } from "../wildfire/wildfire.component";
import { RiversComponent } from "../rivers/rivers.component";
import { PrecipitationComponent } from "../precipitation/precipitation.component";
import { BridgesInfrastructureComponent } from "../bridges-infrastructure/bridges-infrastructure.component";
import { Co2emissionsComponent } from "../co2emissions/co2emissions.component";
import { TrucksComponent } from "../trucks/trucks.component";
import { SoilGridsComponent } from "../soil-grids/soil-grids.component";
import { ForestCharacteristicComponent } from "../forest-characteristic/forest-characteristic.component";
import { RailInfrastructureComponent } from "../rail-infrastructure/rail-infrastructure.component";
import { InlandWaterwaysInfrastructureComponent } from "../inland-waterways-infrastructure/inland-waterways-infrastructure.component";
import { RoadwaysInfrastructureComponent } from "../roadways-infrastructure/roadways-infrastructure.component";
import { FloodingComponent } from "../flooding/flooding.component";
import { NotFoundComponent } from "../../components/not-found/not-found.component";
import { ReservoirsComponent } from "../reservoirs/reservoirs.component";import { EarthquakeComponent } from "../earthquake/earthquake.component";
import { DroughtComponent } from "../drought/drought.component";
import { HurricaneComponent } from "../hurricane/hurricane.component";
import { SurfaceAirTemperatureComponent } from "../surface-air-temperature/surface-air-temperature.component";
import { WindTurbineComponent } from "../wind-turbine/wind-turbine.component";
import { MineralResourcesComponent } from "../mineral-resources/mineral-resources.component";
import { MaritimePortsInfrastructureComponent } from "../maritime-ports-infrastructure/maritime-ports-infrastructure.component";
9
@Component({
  selector: "app-dynamic-container",
  templateUrl: "./dynamic-container.component.html",
  styleUrls: ["./dynamic-container.component.css"],
})
export class DynamicContainerComponent implements OnInit {
  @ViewChild("dynamicContainer", { read: ViewContainerRef, static: true })
  dynamicContainer!: ViewContainerRef;
  @Input() numvariable!: any;
  @Input() filter: any = undefined;
  @Output() selectedItem = new EventEmitter<number>();

  private componentRef!: ComponentRef<any>;
  public componentFactory: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.upComponent();
  }

  upComponent(): void {
    switch (this.numvariable) {
      case 1:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            WildfireComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 2:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            WildfireComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 4:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            DroughtComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 8:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            HurricaneComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 9:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            FloodingComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 13:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            SurfaceAirTemperatureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;    
      case 15:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            PrecipitationComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 30:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            EarthquakeComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
      break;
      case 34:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            WindTurbineComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
      break; 
      case 43:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            MineralResourcesComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
      break; 
      case 220:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            Co2emissionsComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 288:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            SoilGridsComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 310:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            ForestCharacteristicComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 349:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            RailInfrastructureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      // case 370:
      //   this.componentFactory =
      //     this.componentFactoryResolver.resolveComponentFactory(
      //       MaritimePortsInfrastructureComponent
      //     );
      //   this.dynamicContainer.clear();
      //   this.componentRef = this.dynamicContainer.createComponent(
      //     this.componentFactory
      //   );
      //   this.componentRef.instance.filter = this.filter;
      //   this.componentRef.instance.numvariable = this.numvariable;
      //   this.componentRef.instance.selectedItem.subscribe((data: any) => {
      //     this.downloadComponent();
      //     this.selectedItem.emit(data);
      //   });
      //   break;            
      case 375:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            RoadwaysInfrastructureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 379:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            BridgesInfrastructureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
      break;
      case 396:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            InlandWaterwaysInfrastructureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 400:
          this.componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
              TrucksComponent
            );
          this.dynamicContainer.clear();
          this.componentRef = this.dynamicContainer.createComponent(
            this.componentFactory
          );
          this.componentRef.instance.filter = this.filter;
          this.componentRef.instance.numvariable = this.numvariable;
          this.componentRef.instance.selectedItem.subscribe((data: any) => {
            this.downloadComponent();
            this.selectedItem.emit(data);
          });
          break;
      case 402:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            MaritimePortsInfrastructureComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;  
      case 994:
      this.componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ReservoirsComponent
        );
      this.dynamicContainer.clear();
      this.componentRef = this.dynamicContainer.createComponent(
        this.componentFactory
      );
      this.componentRef.instance.filter = this.filter;
      this.componentRef.instance.numvariable = this.numvariable;
      this.componentRef.instance.selectedItem.subscribe((data: any) => {
        this.downloadComponent();
        this.selectedItem.emit(data);
      });
      break;
      case 995:
        this.downloadComponent();
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
           RiversComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
        break;
      case 1016:
        this.componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            PrecipitationComponent
          );
        this.dynamicContainer.clear();
        this.componentRef = this.dynamicContainer.createComponent(
          this.componentFactory
        );
        this.componentRef.instance.filter = this.filter;
        this.componentRef.instance.numvariable = this.numvariable;
        this.componentRef.instance.selectedItem.subscribe((data: any) => {
          this.downloadComponent();
          this.selectedItem.emit(data);
        });
      break;
      default:
      this.downloadComponent();
      this.componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          NotFoundComponent
        );
      this.dynamicContainer.clear();
      this.componentRef = this.dynamicContainer.createComponent(
        this.componentFactory
      );
      break;
    }
  }

  downloadComponent(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
