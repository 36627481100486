import { Component } from '@angular/core';

@Component({
  selector: 'app-the-colorado-river',
  templateUrl: './the-colorado-river.component.html',
  styleUrls: ['./the-colorado-river.component.css']
})
export class TheColoradoRiverComponent {

}
