<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template [data]="this.data" ></app-dynamic-point-template>
    </div>
</div>
<div *ngIf="this.isMx">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template [data]="this.data" ></app-dynamic-point-template>
    </div>
</div>

<div *ngIf="!this.isUSA && !this.isMx">
  <app-not-found></app-not-found>
</div>
