import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, AfterViewInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit{
  @ViewChild('chart', { read: ElementRef })
  private chartEl!: ElementRef<HTMLCanvasElement>;
  @Input() data: any = null;
  @Input() title: string = '';
  @Input() yLabel: string = '';
  @Input() xLabel: string = '';
  @Input() hasMinMax: boolean = false;
  @Input() datasetPosition: string = 'right';
  @Input() datasetAlign: string = 'start';
  @Input() callbackFunction!: (value: any) => string | number;
  private initialCallback!: (value:any) => string | number;
  private options: any = null;
  public chart: any;
  private min!: number;
  private max!: number;
  constructor() {
    Chart.register(...registerables);
  }
  ngAfterViewInit(): void {
    this.initCallback();
    this.initOptions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasMinMax) {
      this.min = parseInt(this.data.labels.at(0)) - 1;
      this.max = parseInt(this.data.labels.at(-1)) + 1;
    } else {
      this.min = parseInt(this.data.labels.at(0));
      this.max = parseInt(this.data.labels.at(-1)) + 1;
    }
    this.initOptions();
  }
  ngOnInit(): void {

  }

  private initCallback():void {
    if (this.callbackFunction === this.initialCallback) {
      this.callbackFunction = (value: number): string | number => {
        if (value >= 1000000000 || value <= -1000000000)
          return value / 1e9 + 'MM';
        else if (value >= 1000000 || value <= -1000000)
          return value / 1e6 + 'M';
        else if (value >= 1000 || value <= -1000)
          return value / 1e3 + 'K';

        return value;
      };
    }
  }
  private initOptions():void {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      showLine: true,
      scales: {
        x: {
          min: this.min,
          max: this.max,
          ticks: {
            callback: (value:any) => {
              return `${value}`.replace(',', '');
            }
          },
          title: {
            display: true,
            text: this.xLabel,
            color: '#000',
            font: {
              size: 16,
              weight: 'bold'
            },
          }
        },
        y: {
          beginAtZero: true,
          grace: '10%',
          ticks: {
            // Abbreviate the millions
            callback: this.callbackFunction
          },
          title: {
            display: true,
            text: this.yLabel,
            color: '#000',
            font: {
              size: 16,
              weight: 'bold'
            },
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: this.title,
          color: '#000',
          font: {
            size: 18,
            weight: 'bold'
          }
        },
        legend: {
          position: this.datasetPosition,
          align: this.datasetAlign
        },
        tooltip: {
          mode: 'point',
          intersect: true,
          callbacks: {
            label: (value: any) => {
              return `${value.parsed.x}:  ${value.parsed.y }`;
            }
          }
        }
      }
    };
    this.createChart();
  }

  private createChart():void {
    this.destroyChart();
    this.chart = new Chart(this.chartEl.nativeElement, {
      type: 'scatter',
      data: this.data,
      options: this.options

    });
  }
  private destroyChart() {
    if (this.chart != null || this.chart != undefined) {
      this.chart.destroy();
    }
  }
  ngOnDestroy(): void {
    this.destroyChart();
  }

}
