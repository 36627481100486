<div *ngIf="this.isMx">

    <div *ngIf="this.data !== undefined && this.varType.Internet_Usage === this.selectedView">
      <app-static-template [dataRes]="this.data" [event]="'Internet Usage'" (selectedItem)="getValue($event)"></app-static-template>
    </div>

    <div *ngIf="this.data !== undefined && this.varType.Availability_Internet_Service === this.selectedView">
      <app-category-bar-template [dataRes]="this.data"  (selectedItem)="getValue($event)" (firstValue)="reloadMx($event)"></app-category-bar-template>
    </div>
  
</div>

<div *ngIf="!this.isMx">
  <app-not-found></app-not-found>
</div>
