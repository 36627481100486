import { Component, Input, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
declare const Plotly: any;
@Component({
  selector: 'app-line-scatter',
  templateUrl: './line-scatter.component.html',
  styleUrls: ['./line-scatter.component.css']
})

export class LineScatterComponent implements OnInit {
  @Input() idScenario!: any;
  @Input() selection!: any;
  public data: any = [];
  public layout: any = {
    width: 500, // Ancho de la gráfic
    backgroundColor: "#d6d3c4",
    xaxis: {
      title: {
        text: 'Predictions',
        font: {
          family: 'Arial, sans-serif',
          size: 14,
          color: '#000000'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Observations', 
        font: {
          family: 'Arial, sans-serif',
          size: 14,
          color: '#000000'
        }
      }
    },
    plot_bgcolor: "rgba(0,0,0,0)",
    paper_bgcolor: "rgba(0,0,0,0)",

    
  };
  constructor(private apiService: ApiServiceService) {}

  public ngOnInit(): void {
    // var trace1 = {
    //   x: [8.983333333, 8.533333333, 8.583333332999999, 9.0, 9.166666667000001, 9.033333333, 8.241666667, 7.475, 6.266666667000001, 5.9916666670000005, 5.858333332999999, 6.066666667000001, 6.3666666670000005, 6.408333333, 6.6333333329999995, 6.425, 6.408333333, 6.458333333, 6.2583333329999995, 6.066666667000001],
    //   y: [8.97895622253418, 8.53243923187256, 8.58893871307373, 8.479456901550291, 9.1637077331543, 9.02890777587891, 8.24420261383057, 7.45773649215698, 6.01292943954468, 6.002216339111331, 6.01746702194214, 6.0603327751159695, 6.363122463226321, 6.41052055358887, 6.60580825805664, 6.4869246482849094, 6.397403717041019, 6.452045440673831, 6.32751655578613, 6.043433666229251],
    //   mode: 'markers',
    //   name: 'Data Point',
    //   type: 'scatter'
    // };
    
    // // /*var trace2 = {
    // //   x: [2, 3, 4, 5],
    // //   y: [16, 5, 11, 9],
    // //   mode: 'lines',
    // //   name: 'y = 0.97x + 0.21',
    // //   type: 'scatter'
    // // };*/

    // /*var trace3 = {
    //   x: [5.858333332999999, 6.032456140052631, 6.206578947105262, 6.3807017541578945, 6.554824561210526, 6.7289473682631575, 6.903070175315789, 7.077192982368421, 7.251315789421053, 7.425438596473684, 7.599561403526316, 7.773684210578947, 7.947807017631579, 8.121929824684212, 8.296052631736842, 8.470175438789475, 8.644298245842107, 8.818421052894738, 8.99254385994737, 9.166666667000001],
    //   y:[5.875032969317504, 6.043332495177973, 6.211632021038443, 6.379931546898913, 6.548231072759382, 6.716530598619851, 6.884830124480321, 7.053129650340791, 7.22142917620126, 7.3897287020617295, 7.5580282279221995, 7.726327753782669, 7.894627279643138, 8.062926805503608, 8.231226331364077, 8.399525857224548, 8.567825383085017, 8.736124908945486, 8.904424434805955, 9.072723960666425],
    //   mode: 'lines+markers',
    //   name: 'y = 0.97x + 0.21',
    //   type: 'scatter'
    // };*/
    // let trace3 = {
    //   x: [5.858333332999999, 6.032456140052631, 6.206578947105262, 6.3807017541578945, 6.554824561210526, 6.7289473682631575, 6.903070175315789, 7.077192982368421, 7.251315789421053, 7.425438596473684, 7.599561403526316, 7.773684210578947, 7.947807017631579, 8.121929824684212, 8.296052631736842, 8.470175438789475, 8.644298245842107, 8.818421052894738, 8.99254385994737, 9.166666667000001],
    //   y:[5.875032969317504, 6.043332495177973, 6.211632021038443, 6.379931546898913, 6.548231072759382, 6.716530598619851, 6.884830124480321, 7.053129650340791, 7.22142917620126, 7.3897287020617295, 7.5580282279221995, 7.726327753782669, 7.894627279643138, 8.062926805503608, 8.231226331364077, 8.399525857224548, 8.567825383085017, 8.736124908945486, 8.904424434805955, 9.072723960666425],
    //   mode: 'lines',
    //   name: 'y = 0.97x + 0.21',
    //   type: 'scatter'
    // };
    // //var data = [trace1,  trace3];
    // var layout =  {
    //   backgroundColor: "#d6d3c4"
    // }
    Plotly.newPlot('plotly-scatter-line', this.data, this.layout);
    this.updateGraph(this.idScenario, this.selection);

  }

  public updateGraph(idScenario: any, selection: any) {
    Plotly.purge('plotly-scatter-line');
    let url= `RiskScenarios/get_risk_scenario_GoodnesOfFit?IdScenario=${idScenario}&Selection=${selection}`
       this.apiService.get(url).subscribe((val:any)=>{
        let trace1 = {
          x: val.pintData.x_data,
          y: val.pintData.y_data,
          mode: 'markers',
          name: 'Data Point',
          type: 'scatter',
          marker: {
            color: 'rgb(0, 0, 0)',
            size: 10,
            opacity: 1
          }
        }
        let trace3 = {
          x: val.lineData.x_data,
          y: val.lineData.y_data,
          mode: 'lines',
          name: val.lineData.titleLegend,
          type: 'scatter',
          line: {
            color: 'rgb(255, 0, 0)', // Azul opaco
            width: 2,
            opacity: 1
          }
        }
        this.data = [trace1, trace3];
        Plotly.newPlot('plotly-scatter-line', this.data,this.layout);
     });
  }
}
