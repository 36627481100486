
<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined && this.varType.Products_Recall_US_based_company === this.selectedView">
      <app-dynamic-template [dataRes]="this.data " [event]="'Total Income'" (selectedItem)="getValue($event)"></app-dynamic-template>
    </div>
    <div *ngIf="this.data !== undefined && this.varType.Product_Recalls_United_States === this.selectedView">
      <app-time-visualization-template [dataRes]="this.data" [event]="'Product Recalls'" (selectedItem)="getValue($event)"></app-time-visualization-template>
    </div>
      
  </div>
  <div *ngIf="!this.isUSA">
    <app-not-found></app-not-found>
  </div>
  