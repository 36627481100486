<div *ngIf="this.isUSA">

    <div *ngIf="this.data !== undefined">
        <app-dynamic-template [dataRes]="this.data"></app-dynamic-template>
    </div>
      
</div>
  
<div *ngIf=" !this.isUSA">
    <app-not-found></app-not-found>
</div>

