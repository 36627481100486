import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapCenter, MapJson, MapZoom } from 'src/app/core/enums/map.enum';
import { PopulationGrowthModelResponse } from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DynamicTemplateModel } from 'src/app/core/models/templates/static-template/static-template.model';
import { Covid19Service } from 'src/app/core/services/covid19/covid19.service';
import { HomeStore } from 'src/app/core/stores/home-store';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component {
  public data!: any;
  public dataMap: any = {};
  public isUSA: boolean = false;
  public isCN: boolean = false;
  public isMx: boolean = false;
  public isTri: boolean = false;

  constructor( private covid19Service: Covid19Service,private homeStore: HomeStore, private spinner: NgxSpinnerService, ) {
    if (homeStore.getSelectedMainMenuOption() === 'Mexico') this.isMx = true;
    else if (homeStore.getSelectedMainMenuOption() === 'USA') this.isUSA = true;
    else if (homeStore.getSelectedMainMenuOption() === 'Canada') this.isCN = true;
    else this.isTri = true;
  }
  ngOnInit(): void {
    this.spinner.show();
    if (this.isCN) {
      this.getDataCN();
    }  
    if (this.isUSA) {
      this.getDataUs();
    } 
    if (this.isMx) {
      const model = {tipo_caso: "positivo", hasConcentratedData: false};
      this.getDataMx(model);
    }
    if(this.isTri){
      this.getDataTri();
    }

  }
  getDataCN() {
    this.spinner.hide();
  }
  //US Region
  getDataUs() {
    this.spinner.hide();
  }

  //end us  region



  //MX Region
  getDataMx(model: any) {
    this.dataMap = {
      center: MapCenter.MEXICO,
      scale: ['fefbce', 'FFF766', 'ec0909'],
      json: MapJson.MEXICO,
      zoom: MapZoom.MEXICO
    };
    const formatInfo = {
      region: "en-US",
      format: {  year: 'numeric', month: '2-digit', day: '2-digit' },
      addDays: 0
    };
    this.covid19Service.getCovid19National(model)
    .subscribe(
      {
        next: (res) => {
          this.data ={
            data: res,
            title: 'COVID 19',
            description: 'info',
            mapTitle: 'COVID 19 by state',
            mapInfo: this.dataMap,
            ECDF: {title: 'ECDF Covid-19 Confirmed Cases from ',
                     yLabel: 'Cumulative Relative Frequency', xLabel: 'Confirmed Cases',
                     titleCounty: 'Covid-19 Confirmed Cases -',
                    },
              histogram: {
                title: 'Histogram Covid-19 Confirmed Cases by Municipality from ',
                yLabel: 'Frecuency', xLabel: 'No. Confirmed Cases',
                titleCounty: 'Covid-19 Confirmed Cases -',
              },
            xyChart: {title: 'COVID-19 Confirmed Cases -' ,xLabel: 'Day', yLabel: 'COVID-19 Confirmed Cases'},
            timeUnit: 'day',
            formatInfo: formatInfo,
            labelPopup: 'Cases'
          } as DynamicTemplateModel;
          this.spinner.hide();
        },
        error: (e) => { console.error(e); this.spinner.hide(); }
      }
    );
  }

  //Trinational region
  getDataTri(){
    this.spinner.hide();
  }
}
