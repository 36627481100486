import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiServiceService } from '../apiService/api-service.service';
import { ApiRouting } from '../apiService/api.routing';

const TOKEN_KEY = 'TOKEN';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Obtiene si existe usuario autenticado
   */
  get isUserAuthenticated(): boolean {
    const token = localStorage.getItem(TOKEN_KEY) || '';
    return !!token;
  }

  constructor(
    private apiService: ApiServiceService
  ) { }

  login(
    username: string,
    password: string
  ): Observable<string> {

    const payload = {
      username,
      password
    }

    return this.apiService.postSync(
      `${ApiRouting.auth}/${ApiRouting.login}`,
      payload
    ).pipe(
      map((response: { token: string }) => {
        // Almacena token de autenticación
        const token = response.token;
        localStorage.setItem(TOKEN_KEY, token);
        return token;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  logout(): Observable<boolean> {
    localStorage.removeItem(TOKEN_KEY);
    return of(true);
  }

   /**
   * Verifica si el token ha expirado
   **/
  isTokenExpired(): boolean {
    const tokenData = localStorage.getItem(TOKEN_KEY);
    if (tokenData) {
      const { expirationTime } = JSON.parse(tokenData);
      return Date.now() > expirationTime;
    }
    return true; // If there no token, we consider it as expired
  }

}
