import { Injectable } from '@angular/core';
import { ChartJSDataModel, DefaultGraphModel } from '../../models/chartjs.model';

@Injectable({
  providedIn: 'root'
})
export class ChartjsServiceService {

  private  colors = ['#BA007c', '#C20E1A', '#FF981A', '#FFE135', '#96BE37',
    '#6D0B4D', '#7A0404', '#c44f00', '#bc9800',
    '#3c7a00', '#A4C639', '#915C83', '#795c34', '#63c5da'
  ];
  constructor() { }
  getDataMapped(data: DefaultGraphModel,isXYData?: boolean, isLineChart?: boolean, isECDFChart?: boolean): DefaultGraphModel {
    data.datasets = data.datasets.map((x, i) => {

      if (isLineChart) {
        let data_: number[] | ChartJSDataModel[] = [];


        if (isXYData) {
          data_ = x.data.map((value, index) => {
            return { x: data.labels!.at(index), y: value } as ChartJSDataModel
          })
        } else {
          data_ = x.data;
        }


        return {
          label: x.label, data: data_, backgroundColor: this.getNextColor(i),
          borderColor: this.getNextColor(i), tension: 1, borderWidth: 2, hoverBorderWdth: 4, cubicInterpolationMode: 'monotone',
          pointRadius: 3, hoverpointRaius: 5 , yAxisID: x.yAxisID,
        };


      } 
      
      else {


        if (isECDFChart) {
          let data_: number[] | ChartJSDataModel[] = [];


          if (isXYData) {
            data_ = x.data.map((value, index) => {
              return { x: data.labels!.at(index), y: value } as ChartJSDataModel
            })
          } else {
            data_ = x.data;
          }


          return {
            label: x.label, data: data_, backgroundColor: this.getNextColor(i),
            borderColor: this.getNextColor(i), tension: 1, borderWidth: 1, hoverBorderWdth: 1,
            pointRadius: 1.3, hoverpointRaius: 1.5 , yAxisID: x.yAxisID, stepped: true
          };
        }
        
        else{
          return { label: x.label, data: x.data, backgroundColor: this.getNextColor(i) };
        }
        
      }

    });
    const arrayContainer: string[][] = [];
    data.labels!.forEach((x: any, i) => {
      if (x === null) return;
      const stringSplitted = x.split(' ');
      const newArrayString: string[] = [];

      let newString = '';
      let count = 1;
      stringSplitted.forEach((z: any) => {
        newString += ' ' + z;
        if (count % 3 === 0) {
          newArrayString.push(newString.trim());
          newString = '';
        }
        count++;
      });
      // validamos que newString no se haya quedado con contenido
      if (newString !== '') {
        newArrayString.push(newString);
      }
      arrayContainer.push(newArrayString);
    }
    );
    data.labels = arrayContainer;
    return data;
  }
  getNextColor(i: number): string {
    const total = this.colors.length;
    if (i > total) {
      this.getNextColor((i - total));
    } else {
      return this.colors[i];
    }
    return '';
  }

}
