import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { TransboundaryWaterRoutingModule } from './transboundary-water-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { LandUseComponent } from './pages/land-use/land-use.component';
import { PopulationGrowthComponent } from './pages/population-growth/population-growth.component';
import { WaterEfficiencyComponent } from './pages/water-efficiency/water-efficiency.component';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ClimateChangesComponent } from './pages/climate-changes/climate-changes.component';
import { AlfalfaComponent } from './pages/alfalfa/alfalfa.component';
import { CanolaComponent } from './pages/canola/canola.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RiversComponent } from './pages/rivers/rivers.component';
import { ProfitComponent } from './pages/profit/profit.component';


@NgModule({
  declarations: [
    HomeComponent,
    LandUseComponent,
    PopulationGrowthComponent,
    WaterEfficiencyComponent,
    ClimateChangesComponent,
    AlfalfaComponent,
    CanolaComponent,
    RiversComponent,
    ProfitComponent,
  ],
  imports: [
    CommonModule,
    TransboundaryWaterRoutingModule,
    SharedModule,
    MatSliderModule,
    FormsModule,
    MatCardModule,
    MatExpansionModule,
  ]
})
export class TransboundaryWaterModule { }
