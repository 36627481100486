import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  TimeVisualizationTemplateModel,
  FilterType,
} from 'src/app/core/models/templates/static-template/static-template.model';
import { ChartjsServiceService } from 'src/app/core/services/chartjsService/chartjs-service.service';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';
import { HttpClient } from '@angular/common/http';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject, takeUntil, finalize } from 'rxjs';
import { AutocorrelationModelComponent } from '../../components/forms/autocorrelation-model/autocorrelation-model.component';
import { SeasonalModelComponent } from '../../components/forms/seasonal-model/seasonal-model.component';
import { TrendModelComponent } from '../../components/forms/trend-model/trend-model.component';

@Component({
  selector: 'app-time-category-visualization-template',
  templateUrl: './time-category-visualization-template.component.html',
  styleUrls: ['./time-category-visualization-template.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TimeCategoryVisualizationTemplateComponent
  implements OnDestroy, OnChanges
{
  @Input() dataRes!: TimeVisualizationTemplateModel;
  @Input() event: string = 'Average Total Cost\nPer 15k Miles';
  @Output() selectedItem = new EventEmitter<number>();
  @Output() firstValue = new EventEmitter<string>();
  @Output() secondValue = new EventEmitter<string>();

  public totalRows: number = 100;

  public nlags: number = 100;
  public algorithm: string = '1';

  public histogram!: any;
  public XYChart!: any;
  public XYCategoryChart!: any;
  public ecdfChart!: any;
  public yLabel: string[] | string = '';

  public filterType = FilterType;
  public propertyName: string = 'value';

  /* Variables for Trend model */
  @ViewChild('trendModel', { static: false })
  trendComponent!: TrendModelComponent;
  public XYChartTrend!: any;
  public XYChartRemain!: any;
  public ecdfChartTrend!: any;
  public data!: any;
  private onDestroy$: Subject<boolean> = new Subject();

  /* End Variables for Trend model */

  /* Variables for Autocorrelation model */
  @ViewChild('autocorrelationModel', { static: false })
  autocorrelationComponent!: AutocorrelationModelComponent;
  public XYChartAutocorrelation!: any;
  /* End Variables for Autocorrelation  model */

  /* Variables for seasonal and trend model */
  @ViewChild('seasonalModel', { static: false })
  seasonalComponent!: SeasonalModelComponent;
  public XYChartTrendSeasonal!: any;
  public XYChartSeasonal!: any;
  public XYChartResidualSeasonal!: any;

  /* End Variables for seasonal  model */

  constructor(
    private spinner: NgxSpinnerService,
    private timeVisualizationService: TimeVisualizationTemplateService,
    private chartjsService: ChartjsServiceService,
    private dialog: MatDialog,
    private httpClient: HttpClient
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dataRes'] && this.dataRes){
      this.totalRows = this.dataRes.dataikuModel?.totalRows ?? 100;
      this.nlags = this.dataRes.dataikuModel?.nlags_ ?? 100;
      this.algorithm = this.dataRes.dataikuModel?.algorithm_ ?? '1';
      this.reloadView();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  emitFirstValue($event: any) {
    this.firstValue.emit($event);
    if (this.dataRes.emitFirstValue) this.reloadView();
  }
  emitSecondValue($event: any) {
    //console.log($event);
    this.secondValue.emit($event);
    this.spinner.show();

    this.event = $event;
    this.propertyName = 'value';

    this.reloadView();
  }

  reloadView() {
    this.spinner.show();
    setTimeout(() => {
      this.histogram = {
        data: this.timeVisualizationService.getHistogram(
          this.dataRes.data,
          this.dataRes.histogram.title,
          this.propertyName
        ),
        title: this.dataRes.histogram.title,
        yLabel: this.dataRes.histogram.yLabel,
        xLabel: this.dataRes.histogram.xLabel,
      };

      this.yLabel = this.event.split('\n');
      this.ecdfChart = {
        title: 'ECDF - ' + this.dataRes.xyChart.title,
        data: this.chartjsService.getDataMapped(
          this.timeVisualizationService.getECDFGeneral(
            this.dataRes.data,
            this.propertyName
          ),
          true,
          false,
          true
        ),
        yLabel: 'Cumulative Relative Frequency',
        xLabel: this.event,
      };

      this.XYCategoryChart = {
        title: this.dataRes.xyChart.title,
        data: this.timeVisualizationService.getXYCategory(this.dataRes.data),
        xLabel: this.dataRes.xyChart.xLabel,
        yLabel: this.event,
      };

      this.spinner.hide();
    }, 300);
  }

  openStudyDialog() {
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {
        filterInfo: this.dataRes.filterInfo,
        selectedInfo: this.dataRes.selectedInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.dataRes.selectedInfo = result;
        this.selectedItem.emit(result);
      }
    });
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) this.trendComponent.onSubmit();

    if (tabChangeEvent.index == 2) this.autocorrelationComponent.onSubmit();

    if (tabChangeEvent.index == 3) this.seasonalComponent.onSubmit();
  }

  public getTrendModel(event: any) {
    this.spinner.show();
    event.algorithm_ = parseInt(event.algorithm_);
    event.filters_ = event.filters_;

    let model = {
      table: this.dataRes.dataikuModel?.table,
      column_to_work: this.dataRes.dataikuModel?.column_to_work,
      frequency: this.dataRes.dataikuModel?.frequency,
      date_: this.dataRes.dataikuModel?.date_,
      filters_: event.filters_,
      algorithm_: event.algorithm_,
      window_size_0_1: parseFloat(event.window_size_0_1),
      window_size: parseFloat(event.window_size),
      degree_: parseFloat(event.degree_),
      smoothing_factor: parseFloat(event.smoothing_factor),
    };

    this.httpClient
      .post(
        'http://10.55.164.42:11200/public/api/v1/service_time_series_variables/trend_model_and_residual_analysis/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res.response);

          this.XYChartTrend = {
            title: '',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.trend_graph
            ),
            xLabel: '',
            yLabel: '',
          };

          this.XYChartRemain = {
            title: '',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.remain_graph
            ),
            xLabel: '',
            yLabel: '',
          };

          this.ecdfChartTrend = {
            title: '',
            data: this.chartjsService.getDataMapped(
              this.timeVisualizationService.getECDFDataiku(response.ecdf_graph),
              true,
              false,
              true
            ),
            yLabel: '',
            xLabel: '',
          };
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  public getAutocorrelationModel(event: any) {
    this.spinner.show();
    event.nlags_ = parseInt(event.nlags_);
    let model = event;
    model.table = this.dataRes.dataikuModel?.table;
    model.column_to_work = this.dataRes.dataikuModel?.column_to_work;
    model.frecuency = this.dataRes.dataikuModel?.frequency;
    model.date_ = this.dataRes.dataikuModel?.date_;
    model.filters_ = event.filters_;

    this.httpClient
      .post(
        'http://10.55.164.42:11200/public/api/v1/service_time_series_variables/autocorrelation_analysis/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res.response);
          this.XYChartAutocorrelation = {
            title: 'Autocorrelation Function (ACF) Plot',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.graph1
            ),
            xLabel: 'Lag(Month)',
            yLabel: 'Autocorrelation',
          };
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  public getSeasonalModel(event: any) {
    this.spinner.show();
    event.stl_trend_smoother = parseInt(event.stl_trend_smoother);
    event.stl_seasonal_smoother = parseInt(event.stl_seasonal_smoother);
    event.stl_period = parseInt(event.stl_period);

    let model = event;
    model.table = this.dataRes.dataikuModel?.table;
    model.column_to_work = this.dataRes.dataikuModel?.column_to_work;
    model.frequency = this.dataRes.dataikuModel?.frequency;
    model.date_ = this.dataRes.dataikuModel?.date_;
    model.filters_ = event.filters_;
    this.httpClient
      .post(
        'http://10.55.164.42:11200/public/api/v1/service_time_series_variables/seasonal_and_trend_decomposition/run',
        model
      )
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res.response);

          //trend_graph
          this.XYChartTrendSeasonal = {
            title: 'Trend Time-Series',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.trend_graph
            ),
            xLabel: '',
            yLabel: '',
          };
          //seasonal_graph
          this.XYChartSeasonal = {
            title: 'Seasonal Time-Series',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.seasonal_graph
            ),
            xLabel: '',
            yLabel: '',
          };
          //residual_graph
          this.XYChartResidualSeasonal = {
            title: 'Residial Time-Series ',
            data: this.timeVisualizationService.getXYCategoryDataiku(
              response.residual_graph
            ),
            xLabel: '',
            yLabel: '',
          };
        },
        error: (e) => {
          console.error(e);
        },
      });
  }
}
