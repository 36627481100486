import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SelectStudyComponent } from '../../components/dialogs/select-study/select-study.component';

@Component({
  selector: 'app-dynamic-geotiff-template',
  templateUrl: './dynamic-geotiff-template.component.html',
  styleUrls: ['./dynamic-geotiff-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicGeotiffTemplateComponent {
  @Input() data!: any;
  @Input() title: string = "";
  @Output() selectedItem = new EventEmitter<number>();
  
  public newData: any;
  public dates: string[] = [];
  onDestroy$: Subject<boolean> = new Subject();

  constructor(private dialog: MatDialog) { }
  openStudyDialog(){
    const dialogRef = this.dialog.open(SelectStudyComponent, {
      height: '380px',
      width: '90%',
      minWidth: '300px',
      maxWidth: '600px',
      data: {filterInfo: this.data.filterInfo, selectedInfo: this.data.selectedInfo},
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.data.selectedInfo = result;
        this.selectedItem.emit(result);
      }
        
    });
   }
}
