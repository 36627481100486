import { GraphLinkModel } from "../models/graph.model";

export const  mvmLinksMedicalInstrumentUSA: GraphLinkModel[] = [
    {
      "source": "Clinical\nTrials",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Clinical\nTrials",
      "target": "Safety",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Clinics",
      "target": "Healthcare\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Clinics",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Complexity\nof Device",
      "target": "Design",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Complexity\nof Device",
      "target": "Fabrication",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Components\nSupplier",
      "target": "Trucks(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Components\nSupplier",
      "target": "Vehicles(S-M)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Demand",
      "target": "Hospitals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Demand",
      "target": "Clinics",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Design",
      "target": "Fabrication",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Design",
      "target": "Healthcare\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Design",
      "target": "Medical\nDevice\nSoftware",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Design",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Design",
      "target": "Safety",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Fabrication",
      "target": "Trucks(M-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Fabrication",
      "target": "Vehicles(M-C)",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Fabrication",
      "target": "Sterilization",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Fabrication",
      "target": "Clinical\nTrials",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Fabrication",
      "target": "Healthcare\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Components\nSupplier",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Design",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Fabrication",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Hospitals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Clinics",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Clinical\nTrials",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Govermental\nRegulations",
      "target": "Medical\nDevice\nSoftware",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Hospitals",
      "target": "Healthcare\nCost",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Hospitals",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Medical\nDevice\nSoftware",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Medical\nDevice\nSoftware",
      "target": "Safety",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Sterilization",
      "target": "Product\nRecalls",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Sterilization",
      "target": "Safety",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks(M-C)",
      "target": "Hospitals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks(M-C)",
      "target": "Clinics",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Trucks(S-M)",
      "target": "Fabrication",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vehicles(M-C)",
      "target": "Hospitals",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vehicles(M-C)",
      "target": "Clinics",
      "lineStyle": {
        "curveness": 0.1
      }
    },
    {
      "source": "Vehicles(S-M)",
      "target": "Fabrication",
      "lineStyle": {
        "curveness": 0.1
      }
    }
  ]
  