import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from '../apiService/api-service.service';
import { MapMenuModel } from '../../models/main-page/map-menu-model';

@Injectable({
  providedIn: 'root'
})
export class MapMenuService {
  public caseModel?:MapMenuModel;
  constructor(private apiService: ApiServiceService) { }

  getMenu(): Observable<any> {
    return this.apiService.post(`MapMenu/menu`);
  }
}