import { Injectable } from '@angular/core';
import { ApiServiceService } from '../apiService/api-service.service';
import { Observable } from 'rxjs';
import { ApiRouting } from '../apiService/api.routing';

@Injectable({
  providedIn: 'root'
})
export class SawmillsProductionService {

  constructor(private apiService: ApiServiceService) { }

  getHousingPricesUS(): Observable<any> {
    return this.apiService.get(`${ApiRouting.transboundary}${ApiRouting.housingPrices}_us`);
  }
  getTotalGrossProductionMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.manufacturing}/${ApiRouting.totalGrossProduction}_mx`);
  }
  getTotalIncomeMx(): Observable<any> {
    return this.apiService.get(`${ApiRouting.manufacturing}/${ApiRouting.totalIncome}_mx`);
  }

}
