import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

//Categories
export const TransboundaryWaterGraphCategory: any[] = [
  { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
  { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
  { id: 2,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
  { id: 3,name: 'Water Sources asd2', itemStyle: { color: '#ff9900' } },
  { id: 4,name: 'Suppliers', itemStyle: { color: '#2d2b76' } },
  { id: 5,name: 'Logistic / Distribution ad2', itemStyle: { color: '#500101' } },
  { id: 6,name: 'Production das2', itemStyle: { color: '#707070' } },
  { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
  { id: 8,name: 'Logistic qwe2', itemStyle: { color: '#9d1010' } },
  { id: 9,name: 'Social', itemStyle: { color: '#ffc000' } },
  { id: 10,name: 'Economic', itemStyle: { color: '#ffc000' } },
  { id: 11,name: 'Environmental', itemStyle: { color: '#ffc000' } },
  { id: 12,name: 'Water Sources', itemStyle: { color: '#ff9900' } },
  { id: 13,name: 'Logistic / Distribution', itemStyle: { color: '#993365' } },
  { id: 14,name: 'Logistic', itemStyle: { color: '#993365' } },
  { id: 15, name: 'Production', itemStyle: { color: '#a0a0a0' } },
];

export const TransboundaryWaterGraphCategoryLink: any[] = [
  {name: 'Threats / Drivers of Change', links: [TransboundaryWaterGraphCategory[0], TransboundaryWaterGraphCategory[1], TransboundaryWaterGraphCategory[2]]},
  {name: 'Systems', links: [TransboundaryWaterGraphCategory[12], TransboundaryWaterGraphCategory[4], 
        TransboundaryWaterGraphCategory[13], TransboundaryWaterGraphCategory[15],TransboundaryWaterGraphCategory[14],
        TransboundaryWaterGraphCategory[7]
    ]},
  {name: 'State of Risk', links: [TransboundaryWaterGraphCategory[9], TransboundaryWaterGraphCategory[10], 
      TransboundaryWaterGraphCategory[11]
    ]}
]
//End Categories

//nodes
export const TransboundaryWaterItems: GraphItemModel[] = [
  //Anthropogenic Threats
  { fullname: 'Land Use', name: 'Land\nUse', symbol: 'circle', x: -20, y: -20, category: 0, isActive: false },
  { fullname: 'Nitrates', name: 'Nitrates', symbol: 'circle', x: -20, y: -15, category: 0, isActive: false },
  { fullname: 'Cultural Values', name: 'Cultural\nValues', symbol: 'circle', x: -20, y: -10, category: 0, isActive: true },
  { fullname: 'Water Habits', name: 'Water\nHabits', symbol: 'circle', x: -20, y: -5, category: 0, isActive: false },
 
  { fullname: 'Climate Change', name: 'Climate\nChange', symbol: 'circle', x: -15, y: -20, category: 0, isActive: false },
  { fullname: 'Water Quality', name: 'Water\nQuality', symbol: 'circle', x: -15, y: -15, category: 0, isActive: false },
  { fullname: 'Technology / Innovation', name: 'Technology /\nInnovation', symbol: 'circle', x: -15, y: -10, category: 0, isActive: false },
  { fullname: 'Scientific Knowledge', name: 'Scientific\nKnowledge', symbol: 'circle', x: -15, y: -5, category: 0, isActive: false },
  { fullname: 'Public Health', name: 'Public\nHealth', symbol: 'circle', x: -15, y: 0, category: 0, isActive: false },
  { fullname: 'Lack of Public Awareness', name: 'Lack of\nPublic\nAwareness', symbol: 'circle', x: -15, y: 5, category: 0, isActive: false },
  { fullname: 'International Boundary and Water Commission', name: 'International\nBoundary\nand Water\nCommission', symbol: 'circle', x: -15, y: 10, category: 0, isActive: false },
  
  { fullname: 'Migration', name: 'Migration', symbol: 'circle', x: -10, y: -20, category: 0, isActive: false },
  { fullname: 'Education', name: 'Education', symbol: 'circle', x: -10, y: -15, category: 0, isActive: false },
  { fullname: 'Geopolitics', name: 'Geopolitics', symbol: 'circle', x: -10, y: -10, category: 0, isActive: true },
  { fullname: 'Legal Framework', name: 'Legal\nFramework', symbol: 'circle', x: -10, y: -5, category: 0, isActive: false },
  { fullname: 'Heavy Metal Contamination', name: 'Heavy Metal\nContamination', symbol: 'circle', x: -10, y: 0, category: 0, isActive: false },
  
  { fullname: 'Organized Crime', name: 'Organized\nCrime', symbol: 'circle', x: -5, y: -20, category: 0, isActive: false },
  { fullname: 'Population Growth', name: 'Population\nGrowth', symbol: 'circle', x: -5, y: -15, category: 0, isActive: true },
  { fullname: 'Water Over-exploitation / Misuse', name: 'Water\nOver-exploitation\n/ Misuse', symbol: 'circle', x: -5, y: -10, category: 0, isActive: false },
  
  { fullname: 'Fuel Demand', name: 'Fuel\nDemand', symbol: 'circle', x: 0, y: -20, category: 0, isActive: false },
  { fullname: 'Fiber Demand', name: 'Fiber\nDemand', symbol: 'circle', x: 0, y: -15, category: 0, isActive: false },
  { fullname: 'Water Access', name: 'Water\nAccess', symbol: 'circle', x: 0, y: -10, category: 0, isActive: false },
  { fullname: 'Biofilm', name: 'Biofilm', symbol: 'circle', x: 0, y: -5, category: 0, isActive: false },
  { fullname: 'Aquifer Artificial Recharge', name: 'Aquifer\nArtificial\nRecharge', symbol: 'circle', x: 0, y: 0, category: 0, isActive: false },
  { fullname: 'Subsidence', name: 'Subsidence', symbol: 'circle', x: 0, y: 5, category: 0, isActive: false },
  
  { fullname: 'Financing', name: 'Financing', symbol: 'circle', x: 5, y: -20, category: 0, isActive: false },
  { fullname: 'Bureau of Reclamation', name: 'Bureau of\nReclamation', symbol: 'circle', x: 5, y: -15, category: 0, isActive: false },
  { fullname: 'Food Demand', name: 'Food\nDemand', symbol: 'circle', x: 5, y: -10, category: 0, isActive: false },
  { fullname: 'Aging Infrastructure', name: 'Aging\nInfrastructure', symbol: 'circle', x: 5, y: -5, category: 0, isActive: false },
  { fullname: 'Labor Shortage (S)', name: 'Labor\nShortage (S)', symbol: 'circle', x: 5, y: 0, category: 0, isActive: false },
  { fullname: 'Labor Shortage (L/D)', name: 'Labor\nShortage (L/D)', symbol: 'circle', x: 5, y: 5, category: 0, isActive: false },
  { fullname: 'Labor Shortage (L)', name: 'Labor\nShortage (L)', symbol: 'circle', x: 5, y: 10, category: 0, isActive: false },
  { fullname: 'Labor Shortage (P)', name: 'Labor\nShortage (P)', symbol: 'circle', x: 5, y: 15, category: 0, isActive: false },
  { fullname: 'Water Price', name: 'Water\nPrice', symbol: 'circle', x: 5, y: 20, category: 0, isActive: false },
  { fullname: 'Cyberattacks', name: 'Cyberattacks', symbol: 'circle', x: 5, y: 25, category: 0, isActive: false },
  
  //Water Retargets
  { fullname: 'Reservoirs', name: 'Reservoirs', symbol: 'circle', x: 12, y: -20, category: 2, isActive: true },
  { fullname: 'Rivers', name: 'Rivers', symbol: 'circle', x: 12, y: -10, category: 2, isActive: true },
  { fullname: 'Groundwater', name: 'Groundwater', symbol: 'circle', x: 12, y: 0, category: 2, isActive: false },
 
  //Supliers
  { fullname: 'Wells', name: 'Wells', symbol: 'circle', x: 19, y: -17, category: 3, isActive: false },

  //Logistic / Distribution
  { fullname: 'Water Pipe Network', name: 'Water Pipe\nNetwork', symbol: 'circle', x: 22, y: 18, category: 4, isActive: false },
  { fullname: 'Diversion Canals', name: 'Diversion\nCanals', symbol: 'circle', x: 22, y: 23, category: 4, isActive: false },
  { fullname: 'Aqueducts\n(L/D)', name: 'Aqueducts\n(L/D)', symbol: 'circle', x: 22, y: 28, category: 4, isActive: false },

   //Production
   { fullname: 'Irrigation Districts', name: 'Irrigation\nDistricts', symbol: 'circle', x: 29, y: -15, category: 5, isActive: false },
   { fullname: 'Community Irrigation Systems', name: 'Community\nIrrigation\nSystems', symbol: 'circle', x: 29, y: -5, category: 5, isActive: true },
   { fullname: 'City / Municipalities', name: 'City /\nMunicipalities', symbol: 'circle', x: 29, y: 5, category: 5, isActive: false },
  
  //Logistics
  { fullname: 'Water Pipe Network ', name: 'Water Pipe\nNetwork ', symbol: 'circle', x: 36, y: 23, category: 6, isActive: false },
  { fullname: 'Diversion Canals ', name: 'Diversion\nCanals ', symbol: 'circle', x: 36, y: 28, category: 6, isActive: false },


  //Customers
  { fullname: 'Alfalfa / Hay', name: 'Alfalfa\nHay', symbol: 'circle', x: 45, y: -20, category: 7, isActive: true },

  { fullname: 'Wheat', name: 'Wheat', symbol: 'circle', x: 43, y: -15, category: 7, isActive: true },
  { fullname: 'Cotton', name: 'Cotton', symbol: 'circle', x: 43, y: -10, category: 7, isActive: false }, 
  { fullname: 'Soybeans', name: 'Soybeans', symbol: 'circle', x: 43, y: -5, category: 7, isActive: false },
  { fullname: 'Sorghum', name: 'Sorghum', symbol: 'circle', x: 43, y: 0, category: 7, isActive: false },
  { fullname: 'Canola', name: 'Canola', symbol: 'circle', x: 43, y: 5, category: 7, isActive: false },
  
  { fullname: 'Other Produce', name: 'Other\nProduce', symbol: 'circle', x: 48, y: -15, category: 7, isActive: false },
  { fullname: 'Corn', name: 'Corn', symbol: 'circle', x: 48, y: -10, category: 7, isActive: false },
  { fullname: 'Barley', name: 'Barley', symbol: 'circle', x: 48, y: -5, category: 7, isActive: false },
  { fullname: 'Oats', name: 'Oats', symbol: 'circle', x: 48, y: 5, category: 7, isActive: false },

  //social
  { fullname: 'Crime', name: 'Crime', symbol: 'rect', x: 58, y: -20, category: 8, isActive: false },
  { fullname: 'Migration ', name: 'Migration ', symbol: 'rect', x: 58, y: -15, category: 8, isActive: false },
  { fullname: 'Sustainability of Native Communities', name: 'Sustainability of\nNative\nCommunities', symbol: 'rect', x: 58, y: -8, category: 8, isActive: false },
  { fullname: 'Drinking Water Supply', name: 'Drinking\nWater Supply', symbol: 'rect', x: 58, y: -1, category: 8, isActive: false },
  { fullname: 'Food Security', name: 'Food\nSecurity', symbol: 'rect', x: 58, y: 5, category: 8, isActive: false },
  { fullname: 'Domestic Conflict', name: 'Domestic\nConflict', symbol: 'rect', x: 58, y: 11, category: 8, isActive: false },
  { fullname: 'International Conflict', name: 'International\nConflict', symbol: 'rect', x: 58, y: 17, category: 8, isActive: false },
  { fullname: 'Public Health ', name: 'Public\nHealth ', symbol: 'rect', x: 58, y: 23, category: 8, isActive: false },
  
  //economic 9
  { fullname: 'Employment', name: 'Employment', symbol: 'rect', x: 66, y: -20, category: 9, isActive: false },
  { fullname: 'Revenue', name: 'Revenue', symbol: 'rect', x: 66, y: -15, category: 9, isActive: true },
  { fullname: 'Exports', name: 'Exports', symbol: 'rect', x: 66, y: -10, category: 9, isActive: true },
  { fullname: 'Imports', name: 'Imports', symbol: 'rect', x: 66, y: -5, category: 9, isActive: false },
  { fullname: 'Profit', name: 'Profit', symbol: 'rect', x: 67, y: 0, category: 9, isActive: true },
  { fullname: 'Inflation', name: 'Inflation', symbol: 'rect', x: 66, y: 5, category: 9, isActive: false },
  { fullname: 'Electricy Production', name: 'Electricy\nProduction', symbol: 'rect', x: 66, y: 11, category: 9, isActive: false },
  { fullname: 'Fuel Production', name: 'Fuel\nProduction', symbol: 'rect', x: 66, y: 17, category: 9, isActive: false },
  { fullname: 'Income', name: 'Income', symbol: 'rect', x: 66, y: 22, category: 9, isActive: false },

  //envitonmental
  { fullname: 'Land Conversion', name: 'Land\nConversion', symbol: 'rect', x: 73, y: -20, category: 10, isActive: false },
  { fullname: 'Land Degradation', name: 'Land\nDegradation', symbol: 'rect', x: 73, y: -15, category: 10, isActive: false },
  { fullname: 'Water Quality Degradation', name: 'Water\nQuality\nDegradation', symbol: 'rect', x: 73, y: -10, category: 10, isActive: false },
  { fullname: 'Carbon Footprint', name: 'Carbon\nFootprint', symbol: 'rect', x: 73, y: -5, category: 10, isActive: false },
  { fullname: 'Subsidence ', name: 'Subsidence ', symbol: 'rect', x: 73, y: 0, category: 10, isActive: false },
 
   //Natural Threats
   { fullname: 'Natural Water Quality', name: 'Natural\nWater\nQuality', symbol: 'circle', x: -10, y: 31, category: 1, isActive: false },
  
   { fullname: 'Drought', name: 'Drought', symbol: 'circle', x: -5, y: 31, category: 1, isActive: true },
   
   { fullname: 'Volcanic Eruption', name: 'Volcanic\nEruption', symbol: 'circle', x: 0, y: 31, category: 1, isActive: false },
   { fullname: 'Extreme Meteorological Events', name: 'Extreme\nMeteorological\nEvents', symbol: 'circle', x: 0, y: 36, category: 1, isActive: false },
   
   { fullname: 'Earthquakes', name: 'Earthquakes', symbol: 'circle', x: 5, y: 31, category: 1, isActive: true },
   { fullname: 'Fluvial Flooding', name: 'Fluvial\nFlooding', symbol: 'circle', x: 5, y: 36, category: 1, isActive: true },
   { fullname: 'Precipitation', name: 'Precipitation', symbol: 'circle', x: 5, y: 42, category: 1, isActive: true },
   
   //Critical Infrastructure
   { fullname: 'Waste\nWater\nInfrastructure', name: 'Waste\nWater\nInfrastructure', symbol: 'circle', x: -20, y: 50, category: 11, isActive: false },
   
   { fullname: 'Solid Waste Infrastructure', name: 'Solid\nWaste\nInfrastructure', symbol: 'circle', x: -15, y: 55, category: 11, isActive: false },
   
   { fullname: 'Hazardous Waste Infrastructure', name: 'Hazardous\nWaste\nInfrastructure', symbol: 'circle', x: -10, y: 50, category: 11, isActive: false },
   
   { fullname: 'Levee Infrastructure', name: 'Levee\nInfrastructure', symbol: 'circle', x: -2, y: 50, category: 11, isActive: false },
   { fullname: 'Storm Water Infrastructure', name: 'Storm\nWater\nInfrastructure', symbol: 'circle', x: -2, y: 55, category: 11, isActive: false },
   
   { fullname: 'Public Transportation', name: 'Public\nTransportation', symbol: 'circle', x: 5, y: 50, category: 11, isActive: false },
   { fullname: 'Drinking Water Infrastructure', name: 'Drinking\nWater\nInfrastructure', symbol: 'circle', x: 5, y: 55, category: 11, isActive: false },
 
   { fullname: 'Broadband Infrastructure', name: 'Broadband\nInfrastructure', symbol: 'circle', x: 12, y: 50, category: 11, isActive: false },
   
   { fullname: 'Dam\nInfrastructure', name: 'Dam\nInfrastructure', symbol: 'circle', x: 19, y: 50, category: 11, isActive: false },
   { fullname: 'Electricity\n(S)', name: 'Electricity\n(S)', symbol: 'circle', x: 19, y: 55, category: 11, isActive: false },
 
   { fullname: 'Electricity\n(C)', name: 'Electricity\n(C)', symbol: 'circle', x: 42, y: 53, category: 11, isActive: false },
   
  
  //Invisible for size
  { fullname: 'invisible', name: 'invisible', symbol: 'circle', x: 80, y: 12.1, itemStyle: { color: '#fff', borderWidth: 0 }, label: { color: '#fff' }, isActive: true },
];
//End nodes

//Links
export const TransboundaryWaterLinks: GraphLinkModel[] = [
  {
    "source": "Aging\nInfrastructure",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Levee\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Storm\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aging\nInfrastructure",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Sustainability of\nNative\nCommunities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Alfalfa\n\nHay",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\n(L/D)",
    "target": "City/Municipalities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\n(L/D)",
    "target": "Irrigation\nDistricts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\n(L/D)",
    "target": "Community\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aqueducts\n(L/D)",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Aquifer\nArtificial\nRecharge",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Barley",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Biofilm",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Biofilm",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Biofilm",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Education",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Lack of\nPublic\nAwareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Storm\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Irrigation\nDistricts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Broadband\nInfrastructure",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bureau\nof\nReclamation",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bureau\nof\nReclamation",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bureau\nof\nReclamation",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bureau\nof\nReclamation",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Bureau\nof\nReclamation",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Canola",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "City\n\nMunicipalities",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "City\n\nMunicipalities",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Drought",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Water\nAccess",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Extreme\nMeteorological\nEvents",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Financing",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Waterborne\nDisease",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Migration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Geopolitics",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Legal\nFramework",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Fluvial\nFlooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Technology /\nInnovation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Cultural\nValues",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Climate\nChange",
    "target": "Education",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Community\nIrrigation\nSystems",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Community\nIrrigation\nSystems",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Migrant\nFarmworkers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Sustainability of\nNative\nCommunities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Fuel\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Corn",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cotton",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cultural\nValues",
    "target": "Water\nHabits",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Broadband\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Cyberattacks",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Dam\nInfrastructure",
    "target": "Electricity\n(C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Dam\nInfrastructure",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Dam\nInfrastructure",
    "target": "Electricity\n(C)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "City/Municipalities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(D)",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Diversion\nCanals\n(L/D)",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drinking\nWater\nInfrastructure",
    "target": "Waterborne\nDisease",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nAccess",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nHabits",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Water\nOver-Exploitation\n/ Misuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Natural\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Drought",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Volcanic\nEruption",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Levee\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Hazardous\nWaste\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Solid\nWaste\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Natural\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Earthquakes",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Education",
    "target": "Lack of\nPublic\nAwareness",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(C)",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Electricity\n(S)",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Fluvial\nFlooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Precipitation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Storm\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Extreme\nMeteorological\nEvents",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fiber\nDemand",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Dam\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Bureau\nof\nReclamation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Levee\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Technology /\nInnovation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Education",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Hazardous\nWaste\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Solid\nWaste\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Storm\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Irrigation\nDistricts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Financing",
    "target": "Community\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Storm\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fluvial\nFlooding",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Food\nDemand",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\nDemand",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\nDemand",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Fuel\nDemand",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Geopolitics",
    "target": "Legal\nFramework",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Land\nSubsidence",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Groundwater",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Hazardous\nWaste\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Heavy Metal\nContamination",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Heavy Metal\nContamination",
    "target": "Natural\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nBoundary\nand\nWater\nCommission",
    "target": "City/Municipalities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "International\nBoundary\nand\nWater\nCommission",
    "target": "Community\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Irrigation\nDistricts",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Irrigation\nDistricts",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (D)",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (D)",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (L/D)",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (L/D)",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (L/D)",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (P)",
    "target": "City/Municipalities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage (P)",
    "target": "Irrigation\nDistricts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Labor\nShortage\nS\n",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Lack of\nPublic\nAwareness",
    "target": "Water\nHabits",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Land\nUse",
    "target": "Climate\nChange",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Land\nUse",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "International\nBoundary\nand\nWater\nCommission",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Bureau\nof\nReclamation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Legal\nFramework",
    "target": "Wells",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Levee\nInfrastructure",
    "target": "Fluvial\nFlooding",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Migration",
    "target": "Geopolitics",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Migration",
    "target": "Population\nGrowth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Natural\nWater\nQuality",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Nitrates",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Oats",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organized\nCrime",
    "target": "Water\nAccess",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organized\nCrime",
    "target": "Migration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Organized\nCrime",
    "target": "Water\nOver-Exploitation\n/ Misuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Crime",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Migrant\nFarmworkers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Sustainability of\nNative\nCommunities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Other\nProduce",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Population\nGrowth",
    "target": "Fuel\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Population\nGrowth",
    "target": "Fiber\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Population\nGrowth",
    "target": "Water\nOver-Exploitation\n/ Misuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Population\nGrowth",
    "target": "Food\nDemand",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Precipitation",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waterborne\nDisease",
    "target": "Migration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waterborne\nDisease",
    "target": "Labor\nShortage (L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waterborne\nDisease",
    "target": "Labor\nShortage (P)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waterborne\nDisease",
    "target": "Labor\nShortage (S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waterborne\nDisease",
    "target": "Labor\nShortage (D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nTransportation",
    "target": "Labor\nShortage (L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nTransportation",
    "target": "Education",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nTransportation",
    "target": "Labor\nShortage (P)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nTransportation",
    "target": "Labor\nShortage (S)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Public\nTransportation",
    "target": "Labor\nShortage (D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Reservoirs",
    "target": "Electricity\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Rivers",
    "target": "Drinking\nWater Supply",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Scientific\nKnowledge",
    "target": "Geopolitics",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Scientific\nKnowledge",
    "target": "Legal\nFramework",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Scientific\nKnowledge",
    "target": "Technology /\nInnovation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Scientific\nKnowledge",
    "target": "Aquifer\nArtificial\nRecharge",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Solid\nWaste\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Fuel\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Sorghum",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Fuel\nProduction",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Soybeans",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Storm\nWater\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Urban\nSubsidence",
    "target": "Aqueducts\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Urban\nSubsidence",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Urban\nSubsidence",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Technology\n\nInnovation",
    "target": "Water\nOver-Exploitation\n/ Misuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Technology\n\nInnovation",
    "target": "Aquifer\nArtificial\nRecharge",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Drinking\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Waste\nWater\nInfrastructure",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Waterborne\nDisease",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Public\nTransportation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Volcanic\nEruption",
    "target": "Natural\nWater\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waste\nWater\nInfrastructure",
    "target": "Waterborne\nDisease",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Waste\nWater\nInfrastructure",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAccess",
    "target": "Migration",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAccess",
    "target": "Diversion\nCanals\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nAccess",
    "target": "Diversion\nCanals\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nHabits",
    "target": "Water\nOver-Exploitation\n/ Misuse",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\n\nMisuse",
    "target": "Water\nQuality",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\n\nMisuse",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\n\nMisuse",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\n\nMisuse",
    "target": "Urban\nSubsidence",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nOver\nExploitation\n\nMisuse",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "City/Municipalities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Irrigation\nDistricts",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Community\nIrrigation\nSystems",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(D)",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Public\nHealth",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPipe\nNetwork\n(L/D)",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nPrice",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nQuality",
    "target": "Reservoirs",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nQuality",
    "target": "Rivers",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Water\nQuality",
    "target": "Groundwater",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Water\nPipe\nNetwork\n(D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Water\nPipe\nNetwork\n(L/D)",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Oats",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Canola",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Barley",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Sorghum",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Soybeans",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Cotton",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Alfalfa\nHay",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Corn",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Other\nProduce",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wells",
    "target": "Wheat",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Food\nSecurity",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Employment",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "International\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Domestic\nConflict",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Profit",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Revenue",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Land\nConversion",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Exports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Imports",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Land\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Sustainability of\nNative\nCommunities",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Inflation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Water\nQuality\nDegradation",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Carbon\nFootprint",
    "lineStyle": {
      "curveness": 0.1
    }
  },
  {
    "source": "Wheat",
    "target": "Income",
    "lineStyle": {
      "curveness": 0.1
    }
  }
];

//End links
