<div class="map-bar map-bar-horizontal map-bar-timecontrol map-control">
  <a class="map-control-timecontrol timecontrol-backward" (click)="backward()" title="Backward"></a>
  <a class="map-control-timecontrol timecontrol-play" [ngClass]="status" (click)="play()" title="Play"></a>
  <a class="map-control-timecontrol timecontrol-forward" (click)="forward()" title="Forward"></a>
  <p class="map-control-timecontrol timecontrol-date" title="Date">{{now}}</p>
  <div class="map-control-timecontrol timecontrol-slider timecontrol-dateslider">
    <div class="map-range">
      <input #range type="range" class="form-range">
    </div>
  </div>
</div>
