<div *ngIf="this.isTri">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-point-template [data]="this.data" [AllowValueHover]="true" [haveFilters]="1" [filters]="this.filterInfoValues"
        (firstValue)="handleFilterUpdate($event)"></app-dynamic-point-template>
   </div>   
</div>
  
  <div *ngIf=" !this.isTri">
    <app-not-found></app-not-found>
  </div>
  