<div *ngIf="storyHydrated && currentSection" class="d-flex flex-column w-100 h-100">
  <div class="d-flex align-items-center">
    <h1>{{storyHydrated.name}}</h1>
    <div class="grow"></div>
    <div class="menu d-flex align-items-bottom ps-4">
      <span (click)="changePage(menu.sectionId)"
        style="font-weight: 600; color: #3E3E3E;"
        [class]="'mt-2 ps-4 pe-4 pt-2 pb-2 ' + (currentSection == menu.sectionId ? 'selected' : '')"
        *ngFor="let menu of storyHydrated.menu.reverse()">{{menu.label}}</span>
    </div>
  </div>
  <div class="grow component-container">
    <ng-container *ngFor="let children of storyHydrated.sections[currentSection].children">
      <ng-container *ngTemplateOutlet="templateSelector;context:{children}"></ng-container>
    </ng-container>
  </div>
</div>
<ng-template #templateSelector let-children="children" let-style="style" let-classes="classes">
  <ng-container *ngIf="storyHydrated && currentSection">
    <ng-container [ngSwitch]="storyHydrated.components[children].component">
      <ng-template [ngSwitchCase]="'brief'">
        <ng-container *ngTemplateOutlet="briefContainer;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'section'">
        <ng-container *ngTemplateOutlet="sectionContainer;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'columns'">
        <ng-container *ngTemplateOutlet="columnsContainer;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'rows'">
        <ng-container *ngTemplateOutlet="rowsContainer;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'rich-text'">
        <ng-container *ngTemplateOutlet="richText;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'map'">
        <ng-container *ngTemplateOutlet="map;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'line'">
        <ng-container *ngTemplateOutlet="line;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'line-category'">
        <ng-container *ngTemplateOutlet="lineCategory;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'simulator'">
        <ng-container *ngTemplateOutlet="simulator;context:storyHydrated.components[children]"></ng-container>
      </ng-template>
      <ng-template ngSwitchDefault>
        <div>{{storyHydrated.components[children].component}} not implemented</div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #briefContainer let-style="style" let-classes="classes" let-title="title" let-text="text" let-image="image"
  let-imageAlt="imageAlt">
  <div class="d-flex" [style]="style" [class]="classes">
    <div style="padding-left: 1rem; padding-right: 1rem; min-width: 300px;">
      <div [innerHTML]="title"></div>
      <div style="min-height: 0.35rem; background-color: #A7A7A7;
        width: calc(100% - 1.5rem); border-radius: 0.5rem; margin-left: 0.75rem;
        margin-right: 0.75rem;"></div>
      <div [innerHTML]="text"></div>
    </div>
    <img *ngIf="image" src="{{image}}" title="{{imageAlt || ''}}" alt="{{imageAlt || ''}}" style="height: fit-content; width: 721px; max-width: 50%;">
  </div>
</ng-template>
<ng-template #sectionContainer let-style="style" let-classes="classes" let-title="title" let-text="text" let-variables="variables" let-context="context">
  <div class="d-flex" [style]="style" [class]="classes">
    <div style="padding-left: 1rem; padding-right: 1rem; min-width: 300px; width: 50%; max-width: 600px;">
      <div [innerHTML]="title"></div>
      <div [innerHTML]="text"></div>
    </div>
    <div *ngIf="variables" class="d-flex flex-column pe-2" style="height: fit-content; flex-grow: 1;">
      <select name="" id="" [(ngModel)]="context.selected" *ngIf="variables.length > 1">
        <option value="{{i}}" *ngFor="let variable of variables; index as i">{{variable.metadata.name}}</option>
      </select>
      <ng-container [ngSwitch]="variables[context.selected].metadata.timeUnit">
        <ng-template [ngSwitchCase]="'month'">
          <app-line [data]="variables[context.selected].data" [timeUnit]="variables[context.selected].metadata.timeUnit"></app-line>
        </ng-template>
        <ng-template [ngSwitchCase]="'quarter'">
          <app-line-category [data]="variables[context.selected].data"></app-line-category>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #columnsContainer let-childrenComponents="children" let-style="style" let-classes="classes">
  <div class="d-flex ps-2 pr-2" [style]="style" [class]="classes">
    <ng-container class="" *ngFor="let children of childrenComponents">
      <ng-container *ngTemplateOutlet="templateSelector;context:{children}"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #rowsContainer let-childrenComponents="children" let-style="style" let-classes="classes">
  <div class="d-flex flex-column ps-2 pr-2" [style]="style" [class]="classes">
    <ng-container class="" *ngFor="let children of childrenComponents">
      <ng-container *ngTemplateOutlet="templateSelector;context:{children}"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #richText let-text="text" let-style="style" let-classes="classes">
  <div class="d-flex flex-column" [style]="style" [class]="classes" [innerHTML]="text"></div>
</ng-template>
<ng-template #map let-options="options" let-style="style" let-classes="classes" let-title="title" let-mapInfo="mapInfo"
  let-labelPopup="labelPopup" let-data="data">
  <div class="d-flex flex-column p-4" [style]="style" [class]="classes">
    <app-static-states-map class="map" [mapInfo]="mapInfo" [data]="data" [title]="title"
      [labelPopup]="labelPopup"></app-static-states-map>
  </div>
</ng-template>
<ng-template #line let-context="context" let-variables="variables" let-style="style"
  let-classes="classes">
  <div class="d-flex flex-column p-4" [style]="style" [class]="classes">
    <select name="" id="" [(ngModel)]="context.selected" *ngIf="variables.length > 1">
      <option value="{{i}}" *ngFor="let variable of variables; index as i">{{variable.metadata.name}}</option>
    </select>
    <app-line [data]="variables[context.selected].data"
      [timeUnit]="variables[context.selected].metadata.timeUnit"></app-line>
  </div>
</ng-template>
<ng-template #lineCategory let-options="options" let-style="style" let-classes="classes" let-barId="barId"
  let-data="data">
  <div class="d-flex flex-column p-4" [style]="style" [class]="classes">
    <app-line-category [data]="data"></app-line-category>
  </div>
</ng-template>
<ng-template #simulator let-variables="variables" let-clickHandler="clickHandler" let-style="style"
  let-classes="classes" let-data="data">
  <div class="d-flex flex-column w-100 p-4" [style]="style" [class]="classes">
    <h2>Risk analytics</h2>
    <div class="d-flex w-100">
      <div class="d-flex ps-4 pe-4 w-100 grow" style="height: 600px;">
        <div class="w-100 grow" EchartDirective [echartOptions]="data"
          (onClick)="clickHandler && clickHandler($event, variables, openSelectVariableValueModal.bind(this));"></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #varSelectionTemplate let-data>
  <div class="d-flex flex-column p-2">
    Select {{data.variableName}} value
    <div *ngFor="let option of data.options">
      <label for="{{data.variableName}}-{{option.label}}"><input id="{{data.variableName}}-{{option.label}}"
          value="{{option.value}}" type="radio" name="{{data.variableName}}">{{option.label}}</label>
    </div>
    <div class="d-flex w-100">
      <div class="grow"></div>
      <button>Apply</button>
      <button class="ms-2">Cancel</button>
    </div>
  </div>
</ng-template>
