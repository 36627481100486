import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { firstValueFrom, zip } from 'rxjs';
import { FormControl } from '@angular/forms';

// Services //
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeStore } from '../../../../core/stores/home-store';
import { MapService } from '../../../../core/services/mapService/map.service';
import { ApiServiceService } from 'src/app/core/services/apiService/api-service.service';
import { ApiRouting } from 'src/app/core/services/apiService/api.routing';


@Component({
  selector: 'app-residual-analysis',
  templateUrl: './residualAnalysis.component.html',
  styleUrls: ['./residualAnalysis.component.css'],
})
export class ResidualAnalysisComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tab: any;
  @Input() data: any;
  @Input() selectedTab: any;
  @Input() variable: any;
  @Input() firstValueSelected:any;
  @Input() secondValueSelected:any;
  @Input() thridValueSelected:any;
  @Input() fourthValueSelected:any;
  @Input() fifthValueSelected:any;
  @Input() yAxisValueSelected:any;
  
  private subscribe: any;
  public linearRegressionSurfaceMap: any = {};
  public linearRegressionResidualMap: any = {};
  public polynomialSmoothingSurfaceMap: any = {};
  public polynomialSmoothingResidualMap: any = {};
  public degrees: FormControl;
  public final_dict: any;
  private scroll: any;
  private readonly dashboard: any;
  showModal: boolean | undefined;

  public polynomial_smoothing_temp: any = {};

  public centroids_list:any={};
  public polynomial_smoothing_list: any = {};
  public linear_regresion_list:any={};
  public degreesList=[2,3,4,5]
  public url_filters_part: string = '';

  constructor(
    private apiService: ApiServiceService,
    private mapService: MapService,
    private homeStore: HomeStore,
    private spinner: NgxSpinnerService
  ) {
    this.dashboard = this.homeStore.selectedMainMenuOption.toLowerCase();
    
    this.degrees = new FormControl(this.degreesList[0]);
    // this.configGraph();
  }
  async ngOnChanges(changes: SimpleChanges) {
    setTimeout(async () => {
      await this.loadDataPolinomio(this.degrees.value);
      await this.loadDataLinearRegression();
      await this.initGraph();
      this.configGraph();
    }, 5000);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.filters_part();
    this.loadData();  
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    document.removeEventListener('scroll', this.scroll);
  }

  /**
   * Creation and configuration of maps and graphs.
   */
  configGraph() {
    this.linearRegressionSurface();
    this.linearRegressionResidual();
    this.polynomialSmoothingSurface();
    this.polynomialSmoothingResidual();
  }

  async initGraph() {
      //this.final_dict = final_dict;
      const data_states = this.data.data.data_States.map(
        (value: any) => value.values[0]
      );
      const centroids_x_and_y = this.centroids_list;//final_dict.centroids_x_and_y;

      const [
        xy_lr_response,
        z_coord_lr_response,
        z_pred_response,
        residuals_response,
      ] = this.linear_regresion_list;//this.final_dict.linear_regression;
      let [
        xy_ps_response,
        z_coord_ps_response,
        xy_ps_residuals_response,
        z_coord_ps_residuals_response,
        z_poly_pred_response,
        residuals_poly_response,
      ] =this.polynomial_smoothing_list;//this.final_dict.polynomial_smoothing;
      // Linear Regression surface
      let lrmCoords: any = this.mapService.serialized(
        xy_lr_response,
        z_coord_lr_response
      );
      let lrmsSurfaceCentroids: any = this.mapService.serialized(
        [centroids_x_and_y],
        z_pred_response
      );
      let lrmSurface = this.mapService.surface(
        lrmsSurfaceCentroids,
        this.dashboard
      );
      let lrmsResidualCentroids: any = this.mapService.serialized(
        [centroids_x_and_y],
        data_states
      );
      this.linearRegressionSurfaceMap['data'] = (value: any) => {
        if (!value || !value.length) return value;
        value[0]['x'] = lrmCoords.x;
        value[0]['y'] = lrmCoords.y;
        value[0]['z'] = lrmCoords.z;

        value[1]['x'] = lrmSurface.x;
        value[1]['y'] = lrmSurface.y;
        value[1]['z'] = lrmSurface.z;
        value[1]['intensity'] = lrmSurface.z;

        value[2]['x'] = lrmsResidualCentroids.x;
        value[2]['y'] = lrmsResidualCentroids.y;
        value[2]['z'] = lrmsResidualCentroids.z;

        return value;
      };

      let lrmrResidualCentroids: any;
      let lrmrLinerar: any;
      let psmsCoords: any;
      let psmsSurfaceCentroids: any;
      let prmsResidual: any;
      let psmsResidualCentroids: any;
      let psmrCoords: any;
      let psmrResidualCentroids: any;
      let prmrLinear: any;
      // Linear Regression residuals
      this.spinner.show();
      lrmrResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        residuals_response
      );
      lrmrLinerar = this.mapService.linear(
        lrmrResidualCentroids,
        lrmsSurfaceCentroids.z
      );
      this.linearRegressionResidualMap['data'] = (value: any) => {
        if (!value || !value.length) return value;
          value[0]['x'] = lrmCoords.x;
          value[0]['y'] = lrmCoords.y;
          value[0]['z'] = lrmCoords.z;

          value[1]['x'] = lrmSurface.x;
          value[1]['y'] = lrmSurface.y;
          value[1]['z'] = lrmSurface.z;
          value[1]['intensity'] = lrmSurface.z;

          value[2]['x'] = lrmrResidualCentroids.x;
          value[2]['y'] = lrmrResidualCentroids.y;
          value[2]['z'] = lrmrResidualCentroids.z;

          value[3]['x'] = lrmrLinerar.x;
          value[3]['y'] = lrmrLinerar.y;
          value[3]['z'] = lrmrLinerar.z;
                

        return value;
      };
    
      this.linearRegressionResidualMap.update();
      // Polynomial Smoothing surface
      psmsCoords = this.mapService.serialized(
        xy_ps_response,
        z_coord_ps_response
      );
      psmsSurfaceCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        z_poly_pred_response
      );
      prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
      psmsResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        data_states
      );

      this.polynomialSmoothingSurfaceMap['data'] = (value: any) => {
        if (!value || !value.length) return value;

        value[0]['x'] = psmsCoords.x;
        value[0]['y'] = psmsCoords.y;
        value[0]['z'] = psmsCoords.z;

        value[1]['x'] = prmsResidual.x;
        value[1]['y'] = prmsResidual.y;
        value[1]['z'] = prmsResidual.z;

        value[2]['x'] = psmsResidualCentroids.x;
        value[2]['y'] = psmsResidualCentroids.y;
        value[2]['z'] = psmsResidualCentroids.z;
        return value;
      };

      this.polynomialSmoothingSurfaceMap.update();

      // Polynomial Smoothing residuals
      psmrCoords = this.mapService.serialized(
        xy_ps_residuals_response,
        z_coord_ps_residuals_response
      );
      psmsSurfaceCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        z_poly_pred_response ?? z_poly_pred_response[0]
      );
      prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
      psmrResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        residuals_poly_response
      );
      prmrLinear = this.mapService.linear(
        psmrResidualCentroids,
        psmsSurfaceCentroids.z
      );

      this.polynomialSmoothingResidualMap['data'] = (value: any) => {
        if (!value || !value.length) return value;

        value[0]['x'] = psmrCoords.x;
        value[0]['y'] = psmrCoords.y;
        value[0]['z'] = psmrCoords.z;

        value[1]['x'] = prmsResidual.x;
        value[1]['y'] = prmsResidual.y;
        value[1]['z'] = prmsResidual.z;

        value[2]['x'] = psmrResidualCentroids.x;
        value[2]['y'] = psmrResidualCentroids.y;
        value[2]['z'] = psmrResidualCentroids.z;

        value[3]['x'] = prmrLinear.x;
        value[3]['y'] = prmrLinear.y;
        value[3]['z'] = prmrLinear.z;
        return value;
      };

      /*this.degrees.valueChanges.subscribe({
        next: (data) => {

         
          console.log("GRADO: ",data)
          this.spinner.show()
          setTimeout(async () => {
            await this.loadDataPolinomio(data);
            [
              xy_ps_response,
              z_coord_ps_response,
              xy_ps_residuals_response,
              z_coord_ps_residuals_response,
              z_poly_pred_response,
              residuals_poly_response,
            ] =this.polynomial_smoothing_list
            
          }, 5000);

        
          psmrCoords = this.mapService.serialized(
            xy_ps_residuals_response,
            z_coord_ps_residuals_response
          );
          psmsSurfaceCentroids = this.mapService.serialized(
            [centroids_x_and_y],
            z_poly_pred_response// z_poly_pred_response[data]
          );
          prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
          psmrResidualCentroids = this.mapService.serialized(
            [centroids_x_and_y],
            residuals_poly_response
          );
          prmrLinear = this.mapService.linear(
            psmrResidualCentroids,
            psmsSurfaceCentroids.z
          );

          this.polynomialSmoothingResidualMap['data'] = (value: any) => {
  
            if (!psmrCoords || !psmrCoords.length) return value;
            value[0]['x'] = psmrCoords.x;
            value[0]['y'] = psmrCoords.y;
            value[0]['z'] = psmrCoords.z;

            value[1]['x'] = prmsResidual.x;
            value[1]['y'] = prmsResidual.y;
            value[1]['z'] = prmsResidual.z;

            value[2]['x'] = psmrResidualCentroids.x;
            value[2]['y'] = psmrResidualCentroids.y;
            value[2]['z'] = psmrResidualCentroids.z;

            value[3]['x'] = prmrLinear.x;
            value[3]['y'] = prmrLinear.y;
            value[3]['z'] = prmrLinear.z;
            return value;
          };

          this.polynomialSmoothingResidualMap.update();
          this.spinner.hide();
        },
      });*/
      this.polynomialSmoothingResidualMap.update();
      if (this.linearRegressionSurfaceMap != null) {
        this.linearRegressionSurfaceMap.update();
      }
      this.spinner.hide();

   /* await firstValueFrom(
      zip(
        this.mapService.get(
          `/assets/resources/response_dict_225.json`
          //`/assets/resources/response_dict_${this.variable}.json`
        )
      )
    ).then(([final_dict]) => {
      console.log('inicio lectura archivo');
      this.final_dict = final_dict;
      const data_states = this.data.data.data_States.map(
        (value: any) => value.values[0]
      );
      const centroids_x_and_y = this.centroids_list;//final_dict.centroids_x_and_y;

      const [
        xy_lr_response,
        z_coord_lr_response,
        z_pred_response,
        residuals_response,
      ] = this.linear_regresion_list;//this.final_dict.linear_regression;
      let [
        xy_ps_response,
        z_coord_ps_response,
        xy_ps_residuals_response,
        z_coord_ps_residuals_response,
        z_poly_pred_response,
        residuals_poly_response,
      ] =this.polynomial_smoothing_list;//this.final_dict.polynomial_smoothing;
      // Linear Regression surface
      let lrmCoords: any = this.mapService.serialized(
        xy_lr_response,
        z_coord_lr_response
      );
      let lrmsSurfaceCentroids: any = this.mapService.serialized(
        [centroids_x_and_y],
        z_pred_response
      );
      let lrmSurface = this.mapService.surface(
        lrmsSurfaceCentroids,
        this.dashboard
      );
      let lrmsResidualCentroids: any = this.mapService.serialized(
        [centroids_x_and_y],
        data_states
      );
      this.linearRegressionSurfaceMap['data'] = (value: any) => {
        if (!value || !value.length) return value;
        value[0]['x'] = lrmCoords.x;
        value[0]['y'] = lrmCoords.y;
        value[0]['z'] = lrmCoords.z;

        value[1]['x'] = lrmSurface.x;
        value[1]['y'] = lrmSurface.y;
        value[1]['z'] = lrmSurface.z;
        value[1]['intensity'] = lrmSurface.z;

        value[2]['x'] = lrmsResidualCentroids.x;
        value[2]['y'] = lrmsResidualCentroids.y;
        value[2]['z'] = lrmsResidualCentroids.z;

        return value;
      };
      console.log('data: ', this.linearRegressionSurfaceMap['data']);
      let lrmrResidualCentroids: any;
      let lrmrLinerar: any;
      let psmsCoords: any;
      let psmsSurfaceCentroids: any;
      let prmsResidual: any;
      let psmsResidualCentroids: any;
      let psmrCoords: any;
      let psmrResidualCentroids: any;
      let prmrLinear: any;
      // Linear Regression residuals
      this.spinner.show();
      lrmrResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        residuals_response
      );
      lrmrLinerar = this.mapService.linear(
        lrmrResidualCentroids,
        lrmsSurfaceCentroids.z
      );
      this.linearRegressionResidualMap['data'] = (value: any) => {
        if (!value || !value.length) return value;
          value[0]['x'] = lrmCoords.x;
          value[0]['y'] = lrmCoords.y;
          value[0]['z'] = lrmCoords.z;

          value[1]['x'] = lrmSurface.x;
          value[1]['y'] = lrmSurface.y;
          value[1]['z'] = lrmSurface.z;
          value[1]['intensity'] = lrmSurface.z;

          value[2]['x'] = lrmrResidualCentroids.x;
          value[2]['y'] = lrmrResidualCentroids.y;
          value[2]['z'] = lrmrResidualCentroids.z;

          value[3]['x'] = lrmrLinerar.x;
          value[3]['y'] = lrmrLinerar.y;
          value[3]['z'] = lrmrLinerar.z;
                

        return value;
      };
      console.log('data_2: ', this.linearRegressionResidualMap['data']);
      this.linearRegressionResidualMap.update();
      // Polynomial Smoothing surface
      psmsCoords = this.mapService.serialized(
        xy_ps_response,
        z_coord_ps_response
      );
      psmsSurfaceCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        z_poly_pred_response
      );
      prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
      psmsResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        data_states
      );

      this.polynomialSmoothingSurfaceMap['data'] = (value: any) => {
        if (!value || !value.length) return value;

        value[0]['x'] = psmsCoords.x;
        value[0]['y'] = psmsCoords.y;
        value[0]['z'] = psmsCoords.z;

        value[1]['x'] = prmsResidual.x;
        value[1]['y'] = prmsResidual.y;
        value[1]['z'] = prmsResidual.z;

        value[2]['x'] = psmsResidualCentroids.x;
        value[2]['y'] = psmsResidualCentroids.y;
        value[2]['z'] = psmsResidualCentroids.z;
        return value;
      };

      this.polynomialSmoothingSurfaceMap.update();

      // Polynomial Smoothing residuals
      psmrCoords = this.mapService.serialized(
        xy_ps_residuals_response,
        z_coord_ps_residuals_response
      );
      psmsSurfaceCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        z_poly_pred_response ?? z_poly_pred_response[0]
      );
      prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
      psmrResidualCentroids = this.mapService.serialized(
        [centroids_x_and_y],
        residuals_poly_response
      );
      prmrLinear = this.mapService.linear(
        psmrResidualCentroids,
        psmsSurfaceCentroids.z
      );

      this.polynomialSmoothingResidualMap['data'] = (value: any) => {
        if (!value || !value.length) return value;

        value[0]['x'] = psmrCoords.x;
        value[0]['y'] = psmrCoords.y;
        value[0]['z'] = psmrCoords.z;

        value[1]['x'] = prmsResidual.x;
        value[1]['y'] = prmsResidual.y;
        value[1]['z'] = prmsResidual.z;

        value[2]['x'] = psmrResidualCentroids.x;
        value[2]['y'] = psmrResidualCentroids.y;
        value[2]['z'] = psmrResidualCentroids.z;

        value[3]['x'] = prmrLinear.x;
        value[3]['y'] = prmrLinear.y;
        value[3]['z'] = prmrLinear.z;
        return value;
      };

      this.degrees.valueChanges.subscribe({
        next: (data) => {
          let grado=data+1;
         

          this.spinner.show();
          psmrCoords = this.mapService.serialized(
            xy_ps_residuals_response,
            z_coord_ps_residuals_response
          );
          psmsSurfaceCentroids = this.mapService.serialized(
            [centroids_x_and_y],
            z_poly_pred_response// z_poly_pred_response[data]
          );
          prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
          psmrResidualCentroids = this.mapService.serialized(
            [centroids_x_and_y],
            residuals_poly_response
          );
          prmrLinear = this.mapService.linear(
            psmrResidualCentroids,
            psmsSurfaceCentroids.z
          );

          this.polynomialSmoothingResidualMap['data'] = (value: any) => {
  
            if (!psmrCoords || !psmrCoords.length) return value;
            value[0]['x'] = psmrCoords.x;
            value[0]['y'] = psmrCoords.y;
            value[0]['z'] = psmrCoords.z;

            value[1]['x'] = prmsResidual.x;
            value[1]['y'] = prmsResidual.y;
            value[1]['z'] = prmsResidual.z;

            value[2]['x'] = psmrResidualCentroids.x;
            value[2]['y'] = psmrResidualCentroids.y;
            value[2]['z'] = psmrResidualCentroids.z;

            value[3]['x'] = prmrLinear.x;
            value[3]['y'] = prmrLinear.y;
            value[3]['z'] = prmrLinear.z;
            return value;
          };

          this.polynomialSmoothingResidualMap.update();
          this.spinner.hide();
        },
      });
      this.polynomialSmoothingResidualMap.update();
      if (this.linearRegressionSurfaceMap != null) {
        this.linearRegressionSurfaceMap.update();
      }
      this.spinner.hide();
    });*/
  }

  linearRegressionSurface() {
    if (this.linearRegressionSurfaceMap.scatter3d != null) {
      this.linearRegressionSurfaceMap.scatter3d({
        mode: 'lines',
        name: 'Map',
      });
      this.linearRegressionSurfaceMap.mesh3d({
        name: 'Mesh3d',
        opacity: 0.6,
        intensity: [],
        colorscale: 'RdBu',
      });
      this.linearRegressionSurfaceMap.scatter3d({
        mode: 'markers',
        name: this.data.data.metadata.net,
        text: [],
        marker: {
          size: 4,
          color: 'rgb(62, 62, 62)',
        },
      });
      this.linearRegressionSurfaceMap['layout'] = (value: any) => {
        value['title']['text'] = 'Linear Regression Surface';
        value['scene']['zaxis']['title'] = `z - ${this.data.data.metadata.net}`;
        value['scene']['aspectratio']['x'] =
          this.dashboard == 'canada' ? 1 : 1.8;
        return value;
      };
      this.linearRegressionSurfaceMap.update();
    }
  }

  linearRegressionResidual() {
    if (
      this.linearRegressionResidualMap != null &&
      this.linearRegressionResidualMap.scatter3d != null
    ) {
      this.linearRegressionResidualMap.scatter3d({
        mode: 'lines',
        name: 'Map',
      });
      this.linearRegressionResidualMap.mesh3d({
        name: 'Mesh3d',
        opacity: 0.6,
        intensity: [],
        colorscale: 'RdBu',
      });
      this.linearRegressionResidualMap.scatter3d({
        mode: 'markers',
        name: 'Residuals',
        text: [],
        marker: {
          size: 4,
          color: 'rgb(62, 62, 62)',
        },
      });
      this.linearRegressionResidualMap.scatter3d({
        mode: 'lines',
        name: 'Lines',
        text: [],
        line: {
          color: 'rgb(62, 62, 62)',
        },
      });
      this.linearRegressionResidualMap['layout'] = (value: any) => {
        value['title']['text'] = 'Linear Regression Residual';
        value['scene']['aspectratio']['x'] =
          this.dashboard == 'canada' ? 1 : 1.8;
        return value;
      };
      this.linearRegressionResidualMap.update();
    }
  }

  polynomialSmoothingSurface() {
    if (
      this.polynomialSmoothingSurfaceMap != null &&
      this.polynomialSmoothingSurfaceMap.scatter3d
    ) {
      this.polynomialSmoothingSurfaceMap.scatter3d({
        mode: 'lines',
        name: 'Map',
      });
      this.polynomialSmoothingSurfaceMap.surface({
        mode: 'markers',
        name: 'Surface',
        opacity: 0.6,
        showscale: true,
        text: [],
      });
      this.polynomialSmoothingSurfaceMap.scatter3d({
        mode: 'markers',
        name: this.data.data.metadata.net,
        text: [],
        marker: {
          size: 4,
          color: 'rgb(62, 62, 62)',
        },
      });
      this.polynomialSmoothingSurfaceMap['layout'] = (value: any) => {
        value['title']['text'] = 'Polynomial Smoothing Surface';
        value['scene']['zaxis']['title'] = `z - ${this.data.data.metadata.net}`;
        value['scene']['aspectratio']['x'] =
          this.dashboard == 'canada' ? 1 : 1.8;
        return value;
      };
      this.polynomialSmoothingSurfaceMap.update();
    }
  }

  polynomialSmoothingResidual() {

    if (
      this.polynomialSmoothingResidualMap != null &&
      this.polynomialSmoothingResidualMap.scatter3d
    ) {
      this.polynomialSmoothingResidualMap.scatter3d({
        mode: 'lines',
        name: 'Map',
      });
      this.polynomialSmoothingResidualMap.surface({
        mode: 'markers',
        name: 'Surface',
        opacity: 0.6,
        showscale: true,
      });
      this.polynomialSmoothingResidualMap.scatter3d({
        mode: 'markers',
        name: 'Residuals',
        text: [],
        marker: {
          size: 4,
          color: 'rgb(62, 62, 62)',
        },
      });
      this.polynomialSmoothingResidualMap.scatter3d({
        mode: 'lines',
        name: 'Lines',
        text: [],
        line: {
          color: 'rgb(62, 62, 62)',
        },
      });
      this.polynomialSmoothingResidualMap['layout'] = (value: any) => {
        value['title']['text'] = 'Polynomial Smoothing Residual';
        value['scene']['aspectratio']['x'] =
          this.dashboard == 'canada' ? 1 : 1.8;
        return value;
      };
      this.polynomialSmoothingResidualMap.update();
    }
  }

  timeUpdate(event: any) {
    //console.log(event);
  }

  compareSize(param: any, compare: any) {
    if (param != null) {
      return (
        param.top + param.height <= window.innerHeight &&
        param.bottom >= 0 &&
        !compare
      );
    }
    return 0;
  }

  openNoDataModal() {
    this.showModal = true;
  }

  public closeModal() {
    this.showModal = false;
  }

  filters_part(){
    if(this.firstValueSelected != null){
      if(this.firstValueSelected.includes("/")) this.firstValueSelected = this.firstValueSelected.replace(/\//g, "%2F");    
      this.url_filters_part = this.url_filters_part + "¬f1:"+this.firstValueSelected;
    }
    if(this.secondValueSelected != null){
      if(this.secondValueSelected.includes("/"))  this.secondValueSelected = this.secondValueSelected.replace(/\//g, "%2F");
      this.url_filters_part = this.url_filters_part + "¬f2:"+this.secondValueSelected;
    }
    if(this.thridValueSelected != null){
      if(this.thridValueSelected.includes("/")) this.thridValueSelected = this.thridValueSelected.replace(/\//g, "%2F");
      this.url_filters_part = this.url_filters_part + "¬f3:"+this.thridValueSelected;
    }
    if(this.fourthValueSelected != null){
      if(this.fourthValueSelected.includes("/")) this.fourthValueSelected = this.fourthValueSelected.replace(/\//g, "%2F");
      this.url_filters_part = this.url_filters_part + "¬f4:"+this.fourthValueSelected;
    }
    if(this.fifthValueSelected != null){
      if(this.fifthValueSelected.includes("/")) this.fifthValueSelected = this.fifthValueSelected.replace(/\//g, "%2F");
      this.url_filters_part = this.url_filters_part + "¬f5:"+this.fifthValueSelected;
    }
    if(this.yAxisValueSelected != null){
      if(this.yAxisValueSelected.includes("/"))  this.yAxisValueSelected = this.yAxisValueSelected.replace(/\//g, "%2F");
      this.url_filters_part = this.url_filters_part + "¬yaxis:"+this.yAxisValueSelected;
    }
  }

  loadData(){
    let url="TridimentionalVariable/data/var:"+this.variable+"¬page:1"+this.url_filters_part+"/centroids_x_and_y"
    this.apiService.get(url).subscribe((val:any)=>{
      this.centroids_list=val.value;
     
    });
  }

  async loadDataPolinomio(g:any){
    let urls=[];
    for (let index=1; index<7; index++) {
      //statements
      let url=ApiRouting.ApiUrl+"TridimentionalVariable/data/var:"+this.variable+"¬page:"+index+"¬g"+g+this.url_filters_part+"/polynomial_smoothing";
      urls.push(fetch(url).then(r=>r.json())); 
   }
    
    
  this.polynomial_smoothing_list = await Promise.all(urls).then((data:any)=>{
    data[0]=data[0].value;
    data[1]=data[1].value;
    data[2]=data[2].value;
    data[3]=data[3].value;
    data[4]=data[4].value;
    data[5]=data[5].value;
    return data;
    })
   
  }


  async loadDataLinearRegression(){
    let urls=[];
    for (let index=1; index<5; index++) {
      //statements
      let url =ApiRouting.ApiUrl+"TridimentionalVariable/data/var:"+this.variable+"¬page:"+index+this.url_filters_part+"/linear_regression";
      urls.push(fetch(url).then(r=>r.json())); 
   }
    
    
  this.linear_regresion_list = await Promise.all(urls).then((data:any)=>{
    data[0]=data[0].value;
    data[1]=data[1].value;
    data[2]=data[2].value;
    data[3]=data[3].value;
    return data;
    })
   
  }
  select(value:any){
      this.spinner.show()
      let lrmrResidualCentroids: any;
      let lrmrLinerar: any;
      let psmsCoords: any;
      let psmsSurfaceCentroids: any;
      let prmsResidual: any;
      let psmsResidualCentroids: any;
      let psmrCoords: any;
      let psmrResidualCentroids: any;
      let prmrLinear: any;

          setTimeout(async () => {
            await this.loadDataPolinomio(value);
            let [
              xy_ps_response,
              z_coord_ps_response,
              xy_ps_residuals_response,
              z_coord_ps_residuals_response,
              z_poly_pred_response,
              residuals_poly_response,
            ] =this.polynomial_smoothing_list
    
             // Polynomial Smoothing residuals
            psmrCoords = this.mapService.serialized(
              xy_ps_residuals_response,
              z_coord_ps_residuals_response
            );
            psmsSurfaceCentroids = this.mapService.serialized(
              [this.centroids_list],
              z_poly_pred_response ?? z_poly_pred_response[0]
            );
            prmsResidual = this.mapService.residual(psmsSurfaceCentroids);
            psmrResidualCentroids = this.mapService.serialized(
              [this.centroids_list],
              residuals_poly_response
            );
            prmrLinear = this.mapService.linear(
              psmrResidualCentroids,
              psmsSurfaceCentroids.z
            );

            this.polynomialSmoothingResidualMap['data'] = (value: any) => {
    
              value[0]['x'] = psmrCoords.x;
              value[0]['y'] = psmrCoords.y;
              value[0]['z'] = psmrCoords.z;
    
              value[1]['x'] = prmsResidual.x;
              value[1]['y'] = prmsResidual.y;
              value[1]['z'] = prmsResidual.z;
    
              value[2]['x'] = psmrResidualCentroids.x;
              value[2]['y'] = psmrResidualCentroids.y;
              value[2]['z'] = psmrResidualCentroids.z;
    
              value[3]['x'] = prmrLinear.x;
              value[3]['y'] = prmrLinear.y;
              value[3]['z'] = prmrLinear.z;
              return value;
            };
    
    
            this.polynomialSmoothingResidualMap.update();
            this.spinner.hide();

        }, 5000);

        
  }
}


