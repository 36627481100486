
<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
        <app-dynamic-geotiff-template [data]="this.data" ></app-dynamic-geotiff-template>
    </div>

      
  </div>
  

  
  <div *ngIf=" !this.isUSA">
    <app-not-found></app-not-found>
  </div>
  
