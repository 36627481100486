import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { TimeVisualizationTemplateService } from 'src/app/core/services/shared/time-visualization/time-visualization-template.service';
import { STORY_EXAMPLE } from './story-example';
import { MatDialog } from '@angular/material/dialog';

type Story = {
  name: string;
  initialSection: string;
  sections: Record<
    string,
    Record<string, unknown> & { title: string; children: string[] }
  >;
  components: Record<
    string,
    Record<string, unknown> & { component: string; children?: string[] }
  >;
  menu: { label: string; sectionId: string }[];
};

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css'],
})
export class StoryComponent implements OnInit, OnChanges {
  @Input() story: Story | undefined;
  @ViewChild('varSelectionTemplate') varSelectionTemplate!: TemplateRef<any>;
  currentSection: string | undefined;
  storyHydrated: Story | undefined;
  constructor(
    private spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer,
    private timeVisualizationService: TimeVisualizationTemplateService,
    public dialog: MatDialog
  ) {}

  hydrateStory(story: Record<string, any>) {
    if (story) {
      return Object.keys(story).reduce((dict, key) => {
        if (key == 'components') {
          const hydrated = Object.keys(story[key]).reduce(
            (components, compId) => {
              components[compId] = story[key][compId];
              if (components[compId].component == 'brief') {
                components[compId].title =
                  this._sanitizer.bypassSecurityTrustHtml(
                    components[compId].title
                  );
                components[compId].text =
                  this._sanitizer.bypassSecurityTrustHtml(
                    components[compId].text
                  );
              }
              if (components[compId].component == 'section') {
                components[compId] = {
                  ...story[key][compId],
                  title: this._sanitizer.bypassSecurityTrustHtml(
                    components[compId].title
                  ),
                  text: this._sanitizer.bypassSecurityTrustHtml(
                    components[compId].text
                  ),
                  context: {
                    selected: 0,
                  },
                  variables: story[key][compId].variables.map(
                    (varData: any) => {
                      if (varData.metadata.timeUnit == 'month') {
                        const data = this.timeVisualizationService.getXY(
                          varData,
                          varData.metadata.isDate,
                          'value'
                        );
                        return {
                          metadata: varData.metadata,
                          data: {
                            data,
                            xyChart: {
                              title: '',
                              data,
                            },
                            histogram: {
                              title: '',
                            },
                          },
                        };
                      } else if (varData.metadata.timeUnit == 'quarter') {
                        const data =
                          this.timeVisualizationService.getXYCategory(
                            varData
                          );
                        return {
                          metadata: varData.metadata,
                          data: {
                            data,
                            xyChart: {
                              title: '',
                              data,
                            },
                            histogram: {
                              title: '',
                            },
                          },
                        };
                      } else {
                        return varData;
                      }
                    }
                  ),
                };
              }
              if (components[compId].component == 'rich-text') {
                components[compId].text =
                  this._sanitizer.bypassSecurityTrustHtml(
                    components[compId].text
                  );
              }
              if (components[compId].component == 'simulator') {
                // components[compId].data = ;
              }
              if (components[compId].component == 'line') {
                components[compId] = {
                  ...story[key][compId],
                  context: {
                    selected: 0,
                  },
                  variables: story[key][compId].variables.map(
                    (varData: any) => {
                      const parsed = this.timeVisualizationService.getXY(
                        varData,
                        varData.metadata.isDate,
                        'value'
                      );
                      return {
                        metadata: varData.metadata,
                        data: {
                          data: parsed,
                          xyChart: {
                            title: '',
                            data: parsed,
                          },
                          histogram: {
                            title: '',
                          },
                        },
                      };
                    }
                  ),
                };
              }
              if (components[compId].component == 'line-category') {
                if (components[compId].data?.data)
                  components[compId].data.data =
                    this.timeVisualizationService.getXYCategory(
                      components[compId].data.data
                    );
                if (components[compId].data?.xyChart?.data)
                  components[compId].data.xyChart.data =
                    this.timeVisualizationService.getXYCategory(
                      components[compId].data.xyChart.data
                    );
              }
              return components;
            },
            {} as Record<string, any>
          );
          dict[key] = hydrated;
        } else {
          dict[key] = story[key];
        }
        return dict;
      }, {} as Record<string, unknown>) as Story;
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currVal = changes['story']?.currentValue;
    this.storyHydrated = this.hydrateStory(currVal);
    this.currentSection = currVal?.initialSection as string;
  }
  changePage(selectedSection: string) {
    this.currentSection = selectedSection;
  }
  ngOnInit(): void {
    this.storyHydrated = this.hydrateStory(STORY_EXAMPLE);
    this.currentSection = 'overview';
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }

  openSelectVariableValueModal(
    componentId: string,
    variableName: string,
    variables: any[]
  ) {
    const variable = variables.find(
      (variable) => variable.name == variableName
    );
    
    const dialogRef = this.dialog.open(this.varSelectionTemplate, {
      data: {
        componentId,
        variableName,
        options: variable?.options || [],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // run simulation and update components
    });
  }
}
