import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { GeneralService } from 'src/app/core/services/generalService/generalService.service';
declare const Plotly: any;

@Component({
  selector: 'app-probability-graph',
  templateUrl: './probability-graph.component.html',
  styleUrls: ['./probability-graph.component.css']
})
export class ProbabilityGraphComponent implements OnInit, OnChanges {
  @Input() idScenario!: any;
  @Input() selection!: any;
  public datadb: any;
  @ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;

  public years: any;
  public yExpectedResponse: any;
  public yLower: any;
  public yUpper: any;

  constructor(private generalservice: GeneralService, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    debugger
    if (changes['selection'] || changes['idScenario']) {
      this.fetchDataAndPlot();
      this.cdr.detectChanges(); 
    }
  }

  ngOnInit(): void {
    this.selection = this.selection.split('¬')[0];
    this.fetchDataAndPlot();
  }

  fetchDataAndPlot(): void {
    this.generalservice.GetProbabilisticCalibrationLinearModelCommun(this.idScenario, this.selection).subscribe({
      next: (response: any) => {
        this.years = response.x;
        this.yExpectedResponse = response.yExpectedResponse;
        this.yLower = response.yLower;
        this.yUpper = response.yUpper;
        this.plotChart();
      },
      error: (err) => {
        console.error('Error al obtener los datos del servicio', err);
      }
    });
  }

  plotChart(): void {
    const trace1 = {
      x: this.years,
      y: this.yExpectedResponse,
      mode: 'lines+markers',
      name: 'Expected Response',
      line: { color: '#ff00ff' },
      marker: { size: 6, color: '#ff00ff' }
    };

    const trace2 = {
      x: this.years,
      y: this.yUpper,
      mode: 'lines',
      name: '1 Std Dev Up',
      line: { color: 'black', dash: 'dash' }
    };

    const trace3 = {
      x: this.years,
      y: this.yLower,
      mode: 'lines',
      name: '1 Std Dev Down',
      line: { color: 'black', dash: 'dash' }
    };

    const layout = {
      title: {
        text: this.selection + ' Probabilistic Calibration of Linear Model',
        font: {
          family: 'Arial, sans-serif',
          size: 18,
          color: '#000000',
          weight: 'bold' 
        }
      },
      xaxis: { title: 'Year' },
      yaxis: { title: this.selection },
      showlegend: true
    };

    const data = [trace1, trace2, trace3];
    Plotly.newPlot(this.chartContainer.nativeElement, data, layout);
  }
}
