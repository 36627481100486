import { Component, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-ecdf-chart',
  templateUrl: './ecdf.component.html',
  styleUrls: ['./ecdf.component.css'],
})
export class ECDFComponent implements OnChanges, AfterViewInit {
  @Input() Id: string = 'MyChart';
  @Input() data: any = null;
  @Input() title: string = '';
  @Input() um: any = undefined;
  @Input() yLabel: string = '';
  @Input() xLabel: string = '';
  @Input() hasMinMax: boolean = false;
  @Input() datasetPosition: string = 'right';
  @Input() datasetAlign: string = 'start';
  xLabelOrigin: string = '';
  @Input() height: string = '200px';
  @Input() callbackFunction!: (value: any) => string | number;
  private initialCallback!: (value: any) => string | number;
  options: any = {};
  public chart: any;
  private min!: number;
  private max!: number;

  constructor() {
    Chart.register(...registerables);
  }

  ngAfterViewInit(): void {
    this.initCallback();
    this.initOptions();
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasMinMax) {
      this.min = parseInt(this.data.labels.at(0)) - 1;
      this.max = parseInt(this.data.labels.at(-1)) + 1;
    } else {
      this.min = parseInt(this.data.labels.at(0));
      this.max = parseInt(this.data.labels.at(-1)) + 1;
    }
    this.xLabelOrigin = '';
    const maxLineLength = 70;
    if (this.um) {
      this.xLabelOrigin = this.formatXAxisLabel(this.xLabel + ' (' + this.um + ')', maxLineLength);
    } else {
      this.xLabelOrigin = this.formatXAxisLabel(this.xLabel, maxLineLength);
    }
    this.initOptions();
    
    
  }

  private abbreviateNumber(value: number): string {
    if (value >= 1_000_000_000) {
      return (value / 1_000_000_000).toFixed(2).replace(/\.?0+$/, '') + 'B';
    } else if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(2).replace(/\.?0+$/, '') + 'M';
    } else {
      return value.toString();
    }
  }

  private initCallback(): void {
    if (this.callbackFunction === this.initialCallback) {
      this.callbackFunction = (value: number): string | number => {
        if (value >= 1000000000 || value <= -1000000000) return value / 1e9 + 'MM';
        else if (value >= 1000000 || value <= -1000000) return value / 1e6 + 'M';
        else if (value >= 1000 || value <= -1000) return value / 1e3 + 'K';
        return value;
      };
    }
  }

  private initOptions(): void {
    this.options = {
      type: 'scatter',
      data: this.data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        showLine: true,
        scales: {
          x: {
            min: this.min,
            max: this.max,
            ticks: {
              callback: (value: any) => {
                return this.abbreviateNumber(value);
              },
            },
            title: {
              display: true,
              text: this.xLabelOrigin.split('\n'), // Ensure this is split into an array
              color: this.data.datasets[0].borderColor,
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          y: {
            beginAtZero: true,
            max: 1,
            ticks: {
              callback: this.callbackFunction,
            },
            title: {
              display: true,
              text: this.yLabel, // Ensure this is split into an array
              color: this.data.datasets[0].borderColor,
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.title,
            color: this.data.datasets[0].borderColor,
            font: {
              size: 18,
              weight: 'bold',
            },
          },
          legend: {
            position: this.datasetPosition,
            align: this.datasetAlign,
          },
          tooltip: {
            mode: 'point',
            intersect: true,
            callbacks: {
              label: (value: any) => {
                return `${value.parsed.x}:  ${value.parsed.y}`;
              },
            },
          },
        },
      },
    };
  }

  private formatXAxisLabel(text: string, maxLineLength: number): string {
    const words = text.split(' ');
    let formattedText = '';
    let line = '';

    words.forEach((word) => {
      if ((line + word).length > maxLineLength) {
        formattedText += line.trim() + '\n';
        line = '';
      }
      line += word + ' ';
    });

    formattedText += line.trim();
    return formattedText;
  }


}
