import { Injectable } from '@angular/core';
import { PopulationUsCountyModel } from 'src/app/core/models/TransboundaryWater/PopulationGrowth/PopulationGrowth.model';
import { DefaultBoxPlotGraphModel } from 'src/app/core/models/chartjs.model';
import { HousingPriceFuncModel, HousingPriceModel } from 'src/app/core/models/sawmillsProduction/housingPrice/housingPriceModel';

@Injectable({
  providedIn: 'root'
})
export class StaticTemplateService {

  constructor() { }

  getBoxplot(dataMapCopy: any, title: string): DefaultBoxPlotGraphModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const boxplot: DefaultBoxPlotGraphModel = {
      labels: ['data'],
      datasets: [{
        label: title,
        outLierColor: '#999999',
        meanBackgroundColor: '#461e7d',
        data: [],
        padding: 0,
        itemRadiius: 0,
        backgroundColor: 'rgba(255, 26, 104, 0.2)',
        borderColor: 'rgba(255, 26, 104, 1)',
        borderWidth: 1,
        type: 'boxplot'
      }]
    };
    let values: number[] = []
    
    dataCopy.data.forEach((value: any) => {
      values.push(value.values[0]);
    });
    values = values.filter(x => x !== 0);
    boxplot.datasets[0].data.push(values);

    return boxplot;
  }


  getHistogram(dataMapCopy: any, title: string):HousingPriceModel {
    const dataCopy = JSON.parse(JSON.stringify(dataMapCopy)) as PopulationUsCountyModel;
    const histogram_: HousingPriceModel = {
        series: [{
          color: '#ff1a6a',
          propertyName: 'value',
          longName:  title
        }
      ]};

    const index = 0;
    const values: number[] = []
    dataCopy.data.forEach((value: any) => {
      values.push(Math.trunc(value.values[index]));
    });

    const labels = [...new Set(values)].sort( (a, b) =>{
      return a - b;
    }).filter(x => x !== 0);
    const labelsString = labels.map((value: number) => { return value.toString() });

    const data_: HousingPriceFuncModel[] = [];
    labelsString.forEach((label: string) => {
      const count = values.filter(x => x === parseFloat(label)).length;
      data_.push({site: label, value: count});
    });
    histogram_.data = data_;
    return histogram_;
  }
}
