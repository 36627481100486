import {Component} from '@angular/core';
import {MainMenuService} from '../../services/menu/main-menu-service';
import {firstValueFrom} from 'rxjs';
import {MapMenuService} from '../../services/menu/map-menu-service';
import {trigger, transition, query, animate, stagger, style, animation} from '@angular/animations';
import { environment } from 'src/environments/environment';

const LIST_ANIMATION = trigger('listAnimation', [
  transition('* => *', [ // each time the binding value changes
    query(':leave', [
      stagger(100, [
        animate('0.5s', style({opacity: 0}))
      ])
    ], {
      optional: true,
    }),
    query(':enter', [
      style({opacity: 0}),
      stagger(100, [
        animate('0.5s', style({opacity: 1}))
      ])
    ], {
      optional: true,
    })
  ])
])
const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('0.5s {{delay}}ms ease-in', style({opacity: 1})),
  ], {
    params: {
      delay: 0
    }
  })
]);

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
  animations: [LIST_ANIMATION, fadeIn]
})
export class MainPageComponent {
  modelList: Array<any> = [];
  country: string;

  baseAssets = environment.BaseAssets;

  // todo: replace this values in the database instead
  public IMAGES_TEMP: Record<string, string> = {
    'corn-farming': this.baseAssets + '/main/Corn Farming.png',
    'sawmills-production': this.baseAssets + '/main/Sawmills and Wood Production.png',
    'transboundary-water': this.baseAssets + '/main/Water Security Transboundary.png',
    'inorganic-chemical': this.baseAssets + '/main/Inorganic Chemical.png',
    'medical-device': this.baseAssets + '/main/Medical Device Manufacturing.png',
    'public-health-info': this.baseAssets + '/main/Public Healt Information.png',
    'semiconductor-selectronic': this.baseAssets + '/main/Semiconductor Electronic Components.png',
    'automobile-engine': this.baseAssets + '/main/Automobile Engine & Parts Manufacturing.png',
    'power': this.baseAssets + '/main/Power.png',
  };

  constructor(private menuService: MainMenuService, private mapMenuService: MapMenuService) {
    firstValueFrom(
      this.menuService.getMenu({
        level: 1,
      })
    ).then((res) => (this.modelList = res || []));
    this.country = localStorage['COUNTRY'] || 'USA';
  }
}
