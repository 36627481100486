import { GraphItemModel, GraphLinkModel } from "../models/graph.model";

/* Categories */
export const PublicHealthGraphCategory: any[] = [
  { id: 0,name: 'Anthropogenic', itemStyle: { color: '#99ccff' } },
    { id: 1,name: 'Natural', itemStyle: { color: '#ccffff' } },
    { id: 2,name: 'Raw Material/Sources', itemStyle: { color: '#c17a2f' } },
    { id: 3,name: 'Supplier', itemStyle: { color: '#2d2b76' } },
    { id: 4,name: 'Transportation/Logistics', itemStyle: { color: '#500101' } },
    { id: 5,name: 'Manufacturer', itemStyle: { color: '#707070' } },
    { id: 6,name: 'Wholesaler/Retailer', itemStyle: { color: '#5a6237' } },
    { id: 7,name: 'Customers', itemStyle: { color: '#ffffff' } },
    { id: 8,name: 'Social', itemStyle: { color: '#ffc000' } },
    { id: 9,name: 'Economic', itemStyle: { color: '#ffc000' } },
    { id: 10,name: 'Environmental', itemStyle: { color: '#ad9d68' }, },
    { id: 11,name: '', itemStyle: { color: '#e376a7' }, },
    { id: 12,name: 'Logistic', itemStyle: { color: '#993365' } },
    { id: 13,name: 'Critical Infrastructure', itemStyle: { color: '#3366ff' } },
];

export const PublicHealthGraphCategoryLink: any[] = [
  { name: 'Threats', links: [PublicHealthGraphCategory[0], PublicHealthGraphCategory[1]] },
  {
    name: 'Systems', links: [PublicHealthGraphCategory[2], PublicHealthGraphCategory[3],
    PublicHealthGraphCategory[4], PublicHealthGraphCategory[5], PublicHealthGraphCategory[6]
    ]
  },
  {
    name: 'State of Risk', links: [PublicHealthGraphCategory[7], PublicHealthGraphCategory[8],
    PublicHealthGraphCategory[9]
    ]
  }
]
/*End categories */

/**
 * -----------------------------------------------------
 * Coordenadas raíz de los nodos del grupo Threats
 * Para considerarlo como si fueran las coordenadas 0,0 y a partir de éstos mover las categorías en grupo
 * TODO: Paulatinamente modificar los otros grupos para moverlos también en grupo
 * -----------------------------------------------------
 */

const threatsX = -20;
const threatsY = -19;

// -----------------------------------------------------
// Descripción de items repetidos
// Se les agrega un diferenciador (espacio, etc)
// -----------------------------------------------------

// Retailers/Broker Agents
const rbaTechnologyPlatforms = 'Technology\nPlatforms';
const rbaMediaServices = 'Media\nServices';
const rbaTraditionalMedia = 'Traditional\nMedia';
const rbaEmergencyAgencies = 'Emergency\nAgencies'

// Transportation/Logistics
const tlTechnologyPlatforms = 'Technology\nPlatforms ';
const tlTraditionalMedia = 'Traditional\nMedia ';

// Suppliers/Scanning Agents
const ssaOpinionLeaders = 'Opinion\nLeaders';

// Manufacturers/Manufacturing Agents
const mmaOpinionLeaders = 'Opinion\nLeaders ';
const mmaMediaServices = 'Media\nServices ';

// Customers/Users
const cuEmergencyAgencies = 'Emergency\nAgencies '

/*NODES*/
export const PublicHealthGraphItems: GraphItemModel[] = [
  { "fullname": "Algorithm Bias", "name": "Algorithm\nBias", "symbol": "circle", "x": -8, "y": 8, "category": 0, "isActive": false },
  { "fullname": "Bias Information Search", "name": "Bias\nInformation\nSearch", "x": 1, "y": -11, "category": 0, "isActive": false },
  { "fullname": "Change Management", "name": "Change\nManagement", "x": -3, "y": 21, "category": 0, "isActive": false },
  { "fullname": "Citation Misdirection", "name": "Citation\nMisdirection", "x": 0, "y": -17, "category": 0, "isActive": false },
  { "fullname": "Communication Inequalities", "name": "Communication\nInequalities", "x": -8, "y": -16, "category": 0, "isActive": false },
  { "fullname": "Community Engagement", "name": "Community\nEngagement", "x": -8, "y": 13, "category": 0, "isActive": false },
  { "fullname": "Complex Multisectorial Partnerships", "name": "Complex\nMultisectorial\nPartnerships", "x": -14, "y": -2, "category": 0, "isActive": false },
  { "fullname": "Content Monopoly", "name": "Content\nMonopoly", "x": -12, "y": 3, "category": 0, "isActive": false },
  { "fullname": "Cyber Threat", "name": "Cyber\nThreat", "x": -8, "y": -2, "category": 0, "isActive": false },
  { "fullname": "Data Quality", "name": "Data Quality", "x": -20, "y": -11, "category": 0, "isActive": false },
  { "fullname": "Data and Information Management Capabilities", "name": "Data and\nInformation\nManagement\nCapabilities", "x": -20, "y": -2, "category": 0, "isActive": false },
  { "fullname": "Data Dredge/P-hack", "name": "Data\nDredge/P-hack", "x": -3, "y": -14, "category": 0, "isActive": false },
  { "fullname": "Demand for PH Information", "name": "Demand for\nPH Information", "x": -2, "y": -3, "category": 0, "isActive": false },
  { "fullname": "Economic Incentives", "name": "Economic\nIncentives", "x": -14, "y": -11, "category": 0, "isActive": false },
  { "fullname": "Emergency Risk Communication Capability", "name": "Emergency Risk\nCommunication\nCapability", "x": -20, "y": 3, "category": 0, "isActive": false },
  { "fullname": "Foreign Dependencies", "name": "Foreign\nDependencies", "x": -14, "y": 18, "category": 0, "isActive": false },
  { "fullname": "Health Disparity", "name": "Health\nDisparity", "x": -12, "y": 23, "category": 0, "isActive": false },
  { "fullname": "Health Emergency Preparedness", "name": "Health\nEmergency\nPreparedness", "x": -8, "y": -11, "category": 0, "isActive": false },
  { "fullname": "Health Workforce Skills and Knowledge", "name": "Health\nWorkforce\nSkills\nand Knowledge", "x": -14, "y": 8, "category": 0, "isActive": false },
  { "fullname": "Ideological Divide", "name": "Ideological\nDivide", "x": -20, "y": 23, "category": 0, "isActive": false },
  { "fullname": "Inadequate Health Information Infrastructure", "name": "Inadequate Health\nInformation\nInfrastructure", "x": -20, "y": -7, "category": 0, "isActive": false },
  { "fullname": "Infodemic", "name": "Infodemic", "x": -12, "y": -16, "category": 0, "isActive": false },
  { "fullname": "Innovation", "name": "Innovation", "x": -8, "y": 18, "category": 0, "isActive": false },
  { "fullname": "Levels of PH Action", "name": "Levels of\nPH Action", "x": -20, "y": 8, "category": 0, "isActive": false },
  { "fullname": "Life Cycle of Crisis", "name": "Life\nCycle of\nCrisis", "x": -8, "y": 3, "category": 0, "isActive": false },
  { "fullname": "Political Power", "name": "Political\nPower", "x": -20, "y": 13, "category": 0, "isActive": false },
  { "fullname": "Population Mobility", "name": "Population\nMobility", "x": -14, "y": 13, "category": 0, "isActive": false },
  { "fullname": "Public Health Governance", "name": "Public\nHealth\nGovernance", "x": -8, "y": -7, "category": 0, "isActive": false },
  { "fullname": "Rapid Growth of Publication Outlets", "name": "Rapid Growth\nof Publication\nOutlets", "x": -2, "y": -7, "category": 0, "isActive": false },
  { "fullname": "Researchers Incentives", "name": "Researchers\nIncentives", "x": -4, "y": -19, "category": 0, "isActive": false },
  { "fullname": "Social Determinants of Health", "name": "Social\nDeterminants\nof Health", "x": -14, "y": -7, "category": 0, "isActive": false },
  { "fullname": "Technology Platforms Policies", "name": "Technology\nPlatforms\nPolicies", "x": -7, "y": 25, "category": 0, "isActive": false },
  { "fullname": "Untrusted Communication Channels", "name": "Untrusted\nCommunication\nChannels", "x": -20, "y": 18, "category": 0, "isActive": false },

  { "fullname": "Marginal Importance Sources", "name": "Marginal\nImportance\nSources", "x": 7, "y": 6, "category": 1, "isActive": false },
  { "fullname": "Primary Importance Sources", "name": "Primary\nImportance\nSources", "x": 7, "y": -4, "category": 1, "isActive": false },
  { "fullname": "Secondary Importance Sources", "name": "Secondary\nImportance\nSources", "x": 7, "y": 1, "category": 1, "isActive": false },

  { "fullname": "Administrative Information Aggregators", "name": "Administrative\nInformation\nAggregators", "x": 12, "y": -7, "category": 2, "isActive": false },
  { "fullname": "Educational Institutions", "name": "Educational\nInstitutions", "x": 17, "y": -15, "category": 2, "isActive": false },
  { "fullname": "Emergency Agencies Aggregators", "name": "Emergency\nAgencies\nAggregators", "x": 12, "y": 8, "category": 2, "isActive": false },
  { "fullname": "Fact Checkers", "name": "Fact\nCheckers", "x": 17, "y": 18, "category": 2, "isActive": false },
  { "fullname": "For-Profit Aggregators", "name": "For-Profit\nAggregators", "x": 17, "y": -4, "category": 2, "isActive": false },
  { "fullname": "Government Aggregators", "name": "Government\nAggregators", "x": 12, "y": -12, "category": 2, "isActive": false },
  { "fullname": "Medical Information Aggregators", "name": "Medical\nInformation\nAggregators", "x": 12, "y": -2, "category": 2, "isActive": false },
  { "fullname": "Non-Government Aggregators", "name": "Non-Government\nAggregators", "x": 17, "y": 1, "category": 2, "isActive": false },
  { "fullname": "Sentinel Surveillance Networks", "name": "Sentinel\nSurveillance\nNetworks", "x": 12, "y": 3, "category": 2, "isActive": false },
  { "fullname": "Health Industry", "name": "Health\nIndustry", "x": 17, "y": 11, "category": 2, "isActive": false },
  { "fullname": "Opinion Leaders", "name": "Opinion\nLeaders", "x": 17, "y": 6, "category": 2, "isActive": false },

  { "fullname": "Dis/misinformation Creation", "name": "Dis/misinformation\nCreation", "x": 22, "y": 16.5, "category": 3, "isActive": false },
  { "fullname": "Evidence Based Organizations", "name": "Evidence\nBased\nOrganizations", "x": 23, "y": -4, "category": 3, "isActive": false },
  { "fullname": "Media Services", "name": "Media\nServices", "x": 22, "y": 5, "category": 3, "isActive": false },
  { "fullname": "Opinion Leaders ", "name": "Opinion\nLeaders ", "x": 22, "y": 11, "category": 3, "isActive": false },
  { "fullname": "Quality of Research", "name": "Quality of \nResearch", "x": 22, "y": -8, "category": 3, "isActive": false },
  { "fullname": "Selective Outcome Reporting", "name": "Selective\nOutcome\nReporting", "x": 19, "y": -11, "category": 3, "isActive": false },

  { "fullname": "Emergency Agencies", "name": "Emergency\nAgencies", "x": 27, "y": 13, "category": 4, "isActive": false },
  { "fullname": "Fact Checker", "name": "Fact Checker", "x": 27, "y": 18, "category": 4, "isActive": false },
  { "fullname": "Government Agencies", "name": "Government\nAgencies", "x": 27, "y": -2, "category": 4, "isActive": false },
  { "fullname": "Health Digital Services", "name": "Health\nDigital\nServices", "x": 27, "y": -11, "category": 4, "isActive": false },
  { "fullname": "Media Services ", "name": "Media\nServices ", "x": 27, "y": -7, "category": 4, "isActive": false },
  { "fullname": "Non-Government Organizations", "name": "Non-Government\nOrganizations", "x": 27, "y": 3, "category": 4, "isActive": false },
  { "fullname": "Peer Review Quality", "name": "Peer Review\nQuality", "x": 31, "y": -19, "category": 4, "isActive": false },
  { "fullname": "Quality of Published Research", "name": "Quality of\nPublished\nResearch", "x": 35, "y": -16, "category": 4, "isActive": false },
  { "fullname": "Quantity of Published Research", "name": "Quantity of\nPublished\nResearch", "x": 33, "y": -12, "category": 4, "isActive": false },
  { "fullname": "Religious Leaders", "name": "Religious\nLeaders", "x": 27, "y": 8, "category": 4, "isActive": false },
  { "fullname": "Technology Platforms", "name": "Technology\nPlatforms", "x": 27, "y": -16, "category": 4, "isActive": false },
  { "fullname": "Traditional Media", "name": "Traditional\nMedia", "x": 27, "y": 24, "category": 4, "isActive": false },
  { "fullname": "Virtual Communities", "name": "Virtual\nCommunities", "x": 27, "y": -22, "category": 4, "isActive": false },

  { "fullname": "Broadband (Customers)", "name": "Broadband\n(Customers)", "x": 36, "y": 12, "category": 6, "isActive": false },
  { "fullname": "Broadband (Manufacturing)", "name": "Broadband\n(Manufacturing)", "x": 22, "y": 21, "category": 6, "isActive": false },
  { "fullname": "Broadband (Raw Materials)", "name": "Broadband\n(Raw Materials)", "x": 5, "y": 9, "category": 6, "isActive": false },
  { "fullname": "Broadband (Retailer)", "name": "Broadband\n(Retailer)", "x": 32, "y": 21, "category": 6, "isActive": false },
  { "fullname": "Broadband (Suppliers)", "name": "Broadband\n(Suppliers)", "x": 12, "y": 13, "category": 6, "isActive": false },
  { "fullname": "Broadband Manufacturers - Retailers", "name": "Broadband\nManufacturers -\nRetailers", "x": 32, "y": 26, "category": 6, "isActive": false },
  { "fullname": "Broadband Raw Materials - Suppliers", "name": "Broadband\nRaw Materials\n- Suppliers", "x": 9, "y": 22, "category": 6, "isActive": false },
  { "fullname": "Broadband Retailers - Customers", "name": "Broadband\nRetailers -\nCustomers", "x": 32, "y": 10, "category": 6, "isActive": false },
  { "fullname": "Broadband Suppliers - Manufacturers", "name": "Broadband\nSuppliers -\nManufacturers", "x": 16, "y": 24, "category": 6, "isActive": false },
  { "fullname": "Electricity (Transportation)", "name": "Electricity\n(Transportation)", "x": 32, "y": 17, "category": 6, "isActive": false },

  { "fullname": "Electricity (Raw Materials)", "name": "Electricity\n(Raw Materials)", "x": 5, "y": 13, "category": 6, "isActive": false },
  { "fullname": "Electricity (Suppliers)", "name": "Electricity\n(Suppliers)", "x": 12, "y": 18, "category": 6, "isActive": false },
  { "fullname": "Electricity (Customers)", "name": "Electricity\n(Customers)", "x": 36, "y": 15, "category": 6, "isActive": false },
  { "fullname": "Electricity (Manufacturing)", "name": "Electricity\n(Manufacturing)", "x": 21, "y": 26, "category": 6, "isActive": false },
  { "fullname": "Electricity (Retailer)", "name": "Electricity\n(Retailer)", "x": 36, "y": 22, "category": 6, "isActive": false },
  { "fullname": "Hype Findings", "name": "Hype\nFindings", "x": 36, "y": -9, "category": 6, "isActive": false },
  { "fullname": "Medical Consultations", "name": "Medical Consultations", "x": 35, "y": -22, "category": 6, "isActive": true },
  { "fullname": "Published Research", "name": "Published\nResearch", "x": 36, "y": -1, "category": 6, "isActive": false },
  { "fullname": "Radio", "name": "Radio", "x": 32, "y": 0, "category": 6, "isActive": false },
  { "fullname": "Technology Platforms ", "name": "Technology\nPlatforms ", "x": 32, "y": 5, "category": 6, "isActive": false },

  { "fullname": "Traditional Media ", "name": "Traditional\nMedia ", "x": 32, "y": -4, "category": 6, "isActive": false },
  { "fullname": "Word of Mouth", "name": "Word of\nMouth", "x": 32, "y": -8, "category": 6, "isActive": false },

  { "fullname": "Business", "name": "Business", "x": 42, "y": 14, "category": 5, "isActive": false },
  { "fullname": "Decisionmakers", "name": "Decisionmakers", "x": 42, "y": -6, "category": 5, "isActive": false },
  { "fullname": "Educators", "name": "Educators", "x": 42, "y": 10, "category": 5, "isActive": false },
  { "fullname": "Emergency Agencies ", "name": "Emergency\nAgencies ", "x": 42, "y": 22, "category": 5, "isActive": false },
  { "fullname": "Health Behavior", "name": "Health\nBehavior", "x": 47, "y": -2, "category": 5, "isActive": true },
  { "fullname": "Health Industry ", "name": "Health\nIndustry ", "x": 42, "y": 18, "category": 5, "isActive": false },
  { "fullname": "Health Professionals", "name": "Health\nProfessionals", "x": 42, "y": 6, "category": 5, "isActive": false },
  { "fullname": "Journalists", "name": "Journalists", "x": 42, "y": -14, "category": 5, "isActive": false },
  { "fullname": "Policymakers", "name": "Policymakers", "x": 42, "y": -10, "category": 5, "isActive": false },
  { "fullname": "Politicians", "name": "Politicians", "x": 42, "y": -22, "category": 5, "isActive": false },
  { "fullname": "Population", "name": "Population", "x": 42, "y": -2, "category": 5, "isActive": true },
  { "fullname": "Public Officials", "name": "Public Officials", "x": 42, "y": -18, "category": 5, "isActive": false },
  { "fullname": "Researchers", "name": "Researchers", "x": 42, "y": 2, "category": 5, "isActive": false },

  { "fullname": "Demand of Mental Health services", "name": "Demand of\nMental Health\nServices", "symbol": "rect", "x": 55, "y": -15, "category": 7, "isActive": false },
  { "fullname": "Disinformation", "name": "Disinformation", "symbol": "rect", "x": 50, "y": -12, "category": 7, "isActive": false },
  { "fullname": "Echo Chamber Effect", "name": "Echo\nChamber\nEffect", "symbol": "rect", "x": 60, "y": -6, "category": 7, "isActive": false },
  { "fullname": "Encounters/Consultations", "name": "Encounters/Consultations", "symbol": "rect", "x": 65, "y": -15, "category": 7, "isActive": false },
  { "fullname": "Exposure to Disease", "name": "Exposure\nto Disease", "symbol": "rect", "x": 55, "y": -4, "category": 7, "isActive": false },
  { "fullname": "False Facts", "name": "False Facts", "symbol": "rect", "x": 60, "y": -20, "category": 7, "isActive": false },
  { "fullname": "Hospitalizations ", "name": "Hospitalizations ", "symbol": "rect", "x": 58, "y": -12, "category": 7, "isActive": false },
  { "fullname": "Ideological Divide ", "name": "Ideological\nDivide ", "symbol": "rect", "x": 77, "y": -18, "category": 7, "isActive": false },
  { "fullname": "Incidence", "name": "Incidence", "symbol": "rect", "x": 75, "y": -12, "category": 7, "isActive": false },
  { "fullname": "Information Avoidance", "name": "Information\nAvoidance", "symbol": "rect", "x": 73, "y": -15, "category": 7, "isActive": false },
  { "fullname": "Life Expectancy", "name": "Life\nExpectancy", "symbol": "rect", "x": 68, "y": -9, "category": 7, "isActive": false },
  { "fullname": "Misinformation", "name": "Misinformation", "symbol": "rect", "x": 65, "y": -4, "category": 7, "isActive": false },
  { "fullname": "Morbidity", "name": "Morbidity", "symbol": "rect", "x": 63, "y": -9, "category": 7, "isActive": false },
  { "fullname": "Mortality", "name": "Mortality", "symbol": "rect", "x": 65, "y": -12, "category": 7, "isActive": true },
  { "fullname": "PH Services population coverage", "name": "PH Services\npopulation coverage", "symbol": "rect", "x": 75, "y": -6, "category": 7, "isActive": true },
  { "fullname": "Prevalence", "name": "Prevalence", "symbol": "rect", "x": 79, "y": -10, "category": 7, "isActive": false },
  { "fullname": "Public Trust", "name": "Public\nTrust", "symbol": "rect", "x": 70, "y": -4, "category": 7, "isActive": true },
  { "fullname": "Recovered", "name": "Recovered", "symbol": "rect", "x": 70, "y": -12, "category": 7, "isActive": false },
  { "fullname": "Replication Crisis", "name": "Replication\nCrisis", "symbol": "rect", "x": 55, "y": -20, "category": 7, "isActive": false },
  { "fullname": "Social Conflict", "name": "Social\nConflict", "symbol": "rect", "x": 80, "y": -4, "category": 7, "isActive": false },
  { "fullname": "Time frame", "name": "Time\nframe", "symbol": "rect", "x": 65, "y": -20, "category": 7, "isActive": false },
  { "fullname": "Types of Outbreaks", "name": "Types of\nOutbreaks", "symbol": "rect", "x": 70, "y": -20, "category": 7, "isActive": false },
  { "fullname": "Unemployment", "name": "Unemployment", "symbol": "rect", "x": 83, "y": -12, "category": 7, "isActive": false },

  { "fullname": "Allocated Resources", "name": "Allocated\nResources", "symbol": "rect", "x": 65, "y": 6, "category": 8, "isActive": false },
  { "fullname": "Cost of Illness", "name": "Cost of\nIllness", "symbol": "rect", "x": 75, "y": 6, "category": 8, "isActive": false },
  { "fullname": "Efficiency", "name": "Efficiency", "symbol": "rect", "x": 68, "y": 0, "category": 8, "isActive": false },
  { "fullname": "Health Workforce", "name": "Health\nWorkforce", "symbol": "rect", "x": 55, "y": 0, "category": 8, "isActive": true },
  { "fullname": "Income", "name": "Income", "symbol": "rect", "x": 72, "y": 0, "category": 8, "isActive": false },
  { "fullname": "Innovation ", "name": "Innovation ", "symbol": "rect", "x": 78, "y": 0, "category": 8, "isActive": false },
  { "fullname": "Population Satisfaction", "name": "Population\nSatisfaction", "symbol": "rect", "x": 62, "y": 0, "category": 8, "isActive": false },
  { "fullname": "Revenue for Technology Platforms", "name": "Revenue for\nTechnology\nPlatforms", "symbol": "rect", "x": 50, "y": 6, "category": 8, "isActive": true },
  { "fullname": "SC Synchronization", "name": "SC Synchronization", "symbol": "rect", "x": 58, "y": 8, "category": 8, "isActive": false },
  { "fullname": "Supply Chain Reliability", "name": "Supply\nChain\nReliability", "symbol": "rect", "x": 70, "y": 6, "category": 8, "isActive": false },

  { "fullname": "Energy Consumption", "name": "Energy\nConsumption", "symbol": "rect", "x": 55, "y": 12, "category": 9, "isActive": false }
];


/* Links */
export const PublicHealthGraphLinks: GraphLinkModel[] = [

  // ---------------------------------------------------
  // #region Anthropogenic Threat

  { source: 'Algorithm\nBias', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Algorithm\nBias', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Bias\nInformation\nSearch', target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Change\nManagement', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Data Quality', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Health\nWorkforce\nSkills\nand Knowledge', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Innovation', lineStyle: { curveness: 0.1 } },
  { source: 'Change\nManagement', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Citation\nMisdirection', target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Communication\nInequalities', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Educators', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Media\nServices ', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: 'Researchers', lineStyle: { curveness: 0.1 } },
  { source: 'Communication\nInequalities', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Community\nEngagement', target: 'Infodemic', lineStyle: { curveness: 0.1 } },
  { source: 'Community\nEngagement', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Community\nEngagement', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Community\nEngagement', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Complex\nMultisectorial\nPartnerships', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Complex\nMultisectorial\nPartnerships', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Complex\nMultisectorial\nPartnerships', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Complex\nMultisectorial\nPartnerships', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Complex\nMultisectorial\nPartnerships', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },

  { source: 'Content\nMonopoly', target: 'Internet Services', lineStyle: { curveness: 0.1 } },
  { source: 'Content\nMonopoly', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Cyber\nThreat', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Data and\nInformation\nManagement\nCapabilities', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: cuEmergencyAgencies, lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Inadequate Health\nInformation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Marginal\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Cyber\nThreat', target: 'Researchers', lineStyle: { curveness: 0.1 } },

  { source: 'Data Quality', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Marginal\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Secondary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },
  { source: 'Data Quality', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Data and\nInformation\nManagement\nCapabilities', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Data and\nInformation\nManagement\nCapabilities', target: 'Inadequate Health\nInformation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Data and\nInformation\nManagement\nCapabilities', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },

  { source: 'Data\nDredge/P-hack', target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Demand for\nPH Information', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Demand for\nPH Information', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Demand for\nPH Information', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Demand for\nPH Information', target: 'Population', lineStyle: { curveness: 0.1 } },

  { source: 'Economic\nIncentives', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nIncentives', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Economic\nIncentives', target: 'Policymakers', lineStyle: { curveness: 0.1 } },

  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Traditional\nMedia', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency Risk\nCommunication\nCapability', target: 'Word of\nMouth', lineStyle: { curveness: 0.1 } },

  { source: 'Foreign\nDependencies', target: 'Data Quality', lineStyle: { curveness: 0.1 } },
  { source: 'Foreign\nDependencies', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Foreign\nDependencies', target: 'Politicians', lineStyle: { curveness: 0.1 } },

  { source: 'Health\nDisparity', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nDisparity', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },

  { source: 'Health\nEmergency\nPreparedness', target: 'Data Quality', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Emergency\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Emergency Risk\nCommunication\nCapability', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nEmergency\nPreparedness', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Health\nWorkforce\nSkills\nand Knowledge', target: 'Data Quality ', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nWorkforce\nSkills\nand Knowledge', target: 'Health\nEmergency\nPreparedness', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nWorkforce\nSkills\nand Knowledge', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nWorkforce\nSkills\nand Knowledge', target: 'Medical\nConsultations', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nWorkforce\nSkills\nand Knowledge', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Ideological\nDivide', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Economic\nIncentives', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Ideological\nDivide', target: 'Traditional\nMedia', lineStyle: { curveness: 0.1 } },

  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Data Quality', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Inadequate Health\nInformation\nInfrastructure', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Infodemic', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Data Quality', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Fact\nCheckers', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Infodemic', target: 'Social\nDeterminants\nof Health', lineStyle: { curveness: 0.1 } },

  { source: 'Innovation', target: 'Data and\nInformation\nManagement\nCapabilities', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Inadequate Health\nInformation\nInfrastructure', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },
  { source: 'Innovation', target: 'Researchers\nIncentives', lineStyle: { curveness: 0.1 } },

  { source: 'Levels of\nPH Action', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Levels of\nPH Action', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Levels of\nPH Action', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Levels of\nPH Action', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Life\nCycle of\nCrisis', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Life\nCycle of\nCrisis', target: 'Secondary\nImportance\nSources', lineStyle: { curveness: 0.1 } },

  { source: 'Political\nPower', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Levels of\nPH Action', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },
  { source: 'Political\nPower', target: 'Public Officials', lineStyle: { curveness: 0.1 } },

  { source: 'Population\nMobility', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Population\nMobility', target: 'Political\nPower', lineStyle: { curveness: 0.1 } },
  { source: 'Population\nMobility', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Population\nMobility', target: 'Social\nDeterminants\nof Health', lineStyle: { curveness: 0.1 } },

  { source: 'Public\nHealth\nGovernance', target: 'Economic\nIncentives', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Foreign\nDependencies', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Health\nEmergency\nPreparedness', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Public\nHealth\nGovernance', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Rapid Growth\nof Publication\nOutlets', target: 'Peer Review\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Rapid Growth\nof Publication\nOutlets', target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
  { source: 'Rapid Growth\nof Publication\nOutlets', target: 'Quantity of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Researchers\nIncentives', target: 'Data\nDredge/P-hack', lineStyle: { curveness: 0.1 } },
  { source: 'Researchers\nIncentives', target: 'Selective\nOutcome\nReporting', lineStyle: { curveness: 0.1 } },

  { source: 'Social\nDeterminants\nof Health', target: 'Complex multisectional partnerships', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Health\nDisparity', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Social\nDeterminants\nof Health', target: 'Public\nHealth\nGovernance', lineStyle: { curveness: 0.1 } },

  { source: 'Technology\nPlatforms\nPolicies', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Infodemic', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Traditional\nMedia ', lineStyle: { curveness: 0.1 } },
  { source: 'Technology\nPlatforms\nPolicies', target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: 'Untrusted\nCommunication\nChannels', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Untrusted\nCommunication\nChannels', target: 'Researchers', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region Customers/Users

  { source: 'Business', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Business', target: 'Income ', lineStyle: { curveness: 0.1 } },
  { source: 'Business', target: 'Supply\nChain\nReliability', lineStyle: { curveness: 0.1 } },
  { source: 'Business', target: 'Unemployment', lineStyle: { curveness: 0.1 } },

  { source: 'Decisionmakers', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Decisionmakers', target: 'Efficiency', lineStyle: { curveness: 0.1 } },
  { source: 'Decisionmakers', target: 'Health\nWorkforce', lineStyle: { curveness: 0.1 } },
  { source: 'Decisionmakers', target: 'Income', lineStyle: { curveness: 0.1 } },
  { source: 'Decisionmakers', target: 'Innovation ', lineStyle: { curveness: 0.1 } },
  { source: 'Decisionmakers', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Educators', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Educators', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Electricity\n(Customers)', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Educators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Emergency\nAgencies ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Customers)', target: 'Researchers', lineStyle: { curveness: 0.1 } },

  { source: 'Emergency\nAgencies', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies', target: 'Health\nWorkforce', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Health\nBehavior', target: 'Exposure\nto Disease', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Incidence', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Information\nAvoidance', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Life\nExpectancy', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Morbidity', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Mortality', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Prevalence', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nBehavior', target: 'Recovered', lineStyle: { curveness: 0.1 } },

  { source: 'Health\nIndustry', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nIndustry', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nIndustry', target: 'Efficiency', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nIndustry', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nIndustry', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },

  { source: 'Health\nProfessionals', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Cost of\nIllness', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Demand of\nMental Health\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Efficiency', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Health\nWorkforce', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Misinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nProfessionals', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Journalists', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Journalists', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Journalists', target: 'Misinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Journalists', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Policymakers', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Policymakers', target: 'Health\nWorkforce', lineStyle: { curveness: 0.1 } },
  { source: 'Policymakers', target: 'Income', lineStyle: { curveness: 0.1 } },
  { source: 'Policymakers', target: 'Population\nSatisfaction', lineStyle: { curveness: 0.1 } },
  { source: 'Policymakers', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Politicians', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Politicians', target: 'Disinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Politicians', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Politicians', target: 'Ideological\nDivide ', lineStyle: { curveness: 0.1 } },
  { source: 'Politicians', target: 'Income', lineStyle: { curveness: 0.1 } },
  { source: 'Politicians', target: 'PH Service Population Coverage', lineStyle: { curveness: 0.1 } },

  { source: 'Population', target: 'Cost of\nIllness', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Information\nAvoidance', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Revenue for\nTechnology\nPlatforms (Companies)', lineStyle: { curveness: 0.1 } },
  { source: 'Population', target: 'Unemployment', lineStyle: { curveness: 0.1 } },

  { source: 'Public Officials', target: 'Allocated\nResources', lineStyle: { curveness: 0.1 } },
  { source: 'Public Officials', target: 'Efficiency', lineStyle: { curveness: 0.1 } },
  { source: 'Public Officials', target: 'Innovation ', lineStyle: { curveness: 0.1 } },

  { source: 'Researchers', target: 'Health\nWorkforce', lineStyle: { curveness: 0.1 } },
  { source: 'Researchers', target: 'Misinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Researchers', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region Manufacturers/Manufacturing Agents

  { source: 'Dis/misinformation\nCreation', target: 'Broadband\nManufacturers -\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Dis/misinformation\nCreation', target: 'Disinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Dis/misinformation\nCreation', target: 'Misinformation', lineStyle: { curveness: 0.1 } },
  { source: 'Dis/misinformation\nCreation', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Dis/misinformation\nCreation', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Dis/misinformation\nCreation', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Electricity\n(Manufacturing)', target: 'Broadband\n(Manufacturing)', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Manufacturing)', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Manufacturing)', target: 'Energy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Manufacturing)', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Manufacturing)', target: 'Media\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Manufacturing)', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },

  { source: 'Evidence\nBased\nOrganizations', target: 'Broadband\nManufacturers -\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Fact Checker', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Non-Government\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Selective\nOutcome\nReporting', lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },
  { source: 'Evidence\nBased\nOrganizations', target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: mmaOpinionLeaders, target: 'Broadband\nManufacturers -\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: 'Non-Government\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: mmaOpinionLeaders, target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: 'Quality of \nResearch', target: 'Quality of \nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Selective\nOutcome\nReporting', target: 'Peer Review\nQuality', lineStyle: { curveness: 0.1 } },
  { source: 'Selective\nOutcome\nReporting', target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
  { source: 'Selective\nOutcome\nReporting', target: 'Quantity of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Electricity\n(Raw Materials)', target: 'Broadband\n(Raw Materials)', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Raw Materials)', target: 'Energy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Raw Materials)', target: 'Marginal\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Raw Materials)', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Raw Materials)', target: 'Secondary\nImportance\nSources', lineStyle: { curveness: 0.1 } },

  { source: 'Marginal\nImportance\nSources', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Marginal\nImportance\nSources', target: 'Broadband\nRaw Materials\n- Suppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Marginal\nImportance\nSources', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Marginal\nImportance\nSources', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Marginal\nImportance\nSources', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Marginal\nImportance\nSources', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },

  { source: 'Primary\nImportance\nSources', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Broadband\nRaw Materials\n- Suppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Emergency\nAgencies Aggregator', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Fact\nCheckers', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Primary\nImportance\nSources', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Secondary\nImportance\nSources', target: 'Broadband\nRaw Materials\n- Suppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Secondary\nImportance\nSources', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Secondary\nImportance\nSources', target: 'Emergency\nAgencies Aggregator', lineStyle: { curveness: 0.1 } },
  { source: 'Secondary\nImportance\nSources', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Secondary\nImportance\nSources', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Secondary\nImportance\nSources', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region Retailers/Broker Agents

  { source: 'Electricity\n(Retailer)', target: 'Emergency\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Energy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Fact\nChecker', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Non-Government\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Retailer)', target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: 'Fact Checker', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Fact Checker', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Government\nAgencies', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAgencies', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAgencies', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAgencies', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Health\nDigital\nServices', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Health\nDigital\nServices', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Media\nServices', target: 'Broadband\nManufacturers -\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Fact Checker', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Media\nServices ', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: 'Telecommunication Network', lineStyle: { curveness: 0.1 } },
  { source: 'Media\nServices', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Non-Government\nOrganizations', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nOrganizations', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nOrganizations', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nOrganizations', target: 'Word of\nMouth', lineStyle: { curveness: 0.1 } },

  { source: 'Peer Review\nQuality', target: 'Quality of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },
  { source: 'Peer Review\nQuality', target: 'Quantity of\nPublished\nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Quality of\nPublished\nResearch', target: 'Published\nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Quantity of\nPublished\nResearch', target: 'Published\nResearch', lineStyle: { curveness: 0.1 } },

  { source: 'Religious\nLeaders', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Religious\nLeaders', target: 'Health\nBehavior', lineStyle: { curveness: 0.1 } },
  { source: 'Religious\nLeaders', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Religious\nLeaders', target: 'Word of\nMouth', lineStyle: { curveness: 0.1 } },

  { source: rbaTechnologyPlatforms, target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Echo\nChamber\nEffect', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Ideological\nDivide', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Innovation ', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Revenue for\nTechnology\nPlatforms (Companies)', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: 'Social\nConflict', lineStyle: { curveness: 0.1 } },
  { source: rbaTechnologyPlatforms, target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: rbaTraditionalMedia, target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: rbaTraditionalMedia, target: 'Echo\nChamber\nEffect', lineStyle: { curveness: 0.1 } },
  { source: rbaTraditionalMedia, target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: rbaTraditionalMedia, target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: rbaTraditionalMedia, target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: rbaTraditionalMedia, target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  // TODO: La relación es hacia sí misma. De ésta manera se tiene en el excel
  { source: rbaTraditionalMedia, target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Virtual\nCommunities', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Virtual\nCommunities', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Virtual\nCommunities', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Virtual\nCommunities', target: 'Word of\nMouth', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region State of Risk

  { source: 'Time\nframe', target: 'Exposure\nto Disease', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Incidence', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Life\nExpectancy', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Morbidity', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Mortality', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Prevalence', lineStyle: { curveness: 0.1 } },
  { source: 'Time\nframe', target: 'Recovered', lineStyle: { curveness: 0.1 } },

  { source: 'Types of\nOutbreaks', target: 'Exposure\nto Disease', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Hospitalizations', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Incidence', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Life\nExpectancy', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Morbidity', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Mortality', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Prevalence', lineStyle: { curveness: 0.1 } },
  { source: 'Types of\nOutbreaks', target: 'Recovered', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region Suppliers/Scanning Agents

  { source: 'Administrative\nInformation\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Administrative\nInformation\nAggregators', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Administrative\nInformation\nAggregators', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Administrative\nInformation\nAggregators', target: 'SC Synchronization', lineStyle: { curveness: 0.1 } },

  { source: 'Educational\nInstitutions', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Educational\nInstitutions', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Educational\nInstitutions', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },

  { source: 'Electricity\n(Suppliers)', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Broadband\n(Suppliers)', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Emergency\nAgencies\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Energy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Fact\nCheckers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Suppliers)', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Emergency\nAgencies\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies\nAggregators', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies\nAggregators', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Emergency\nAgencies\nAggregators', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },

  { source: 'Fact\nCheckers', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Fact\nCheckers', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Fact\nCheckers', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Fact\nCheckers', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },

  { source: 'For-Profit\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'For-Profit\nAggregators', target: 'Revenue for\nTechnology\nPlatforms (Companies)', lineStyle: { curveness: 0.1 } },
  { source: 'For-Profit\nAggregators', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  { source: 'Government\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAggregators', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAggregators', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Government\nAggregators', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Medical\nInformation\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Medical\nInformation\nAggregators', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Medical\nInformation\nAggregators', target: 'Health\nIndustry', lineStyle: { curveness: 0.1 } },
  { source: 'Medical\nInformation\nAggregators', target: 'SC Synchronization', lineStyle: { curveness: 0.1 } },

  { source: 'Non-Government\nAggregators', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nAggregators', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nAggregators', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Non-Government\nAggregators', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },

  { source: 'Sentinel\nSurveillance\nNetworks', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Sentinel\nSurveillance\nNetworks', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Sentinel\nSurveillance\nNetworks', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },
  { source: 'Sentinel\nSurveillance\nNetworks', target: 'SC Synchronization', lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------

  // ---------------------------------------------------
  // #region Transportation/Logistics

  { source: 'Broadband\n(Customers)', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Educators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: cuEmergencyAgencies, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Customers)', target: 'Researchers', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\n(Manufacturing)', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Manufacturing)', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Manufacturing)', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Manufacturing)', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\n(Raw Materials)', target: 'Marginal\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Raw Materials)', target: 'Primary\nImportance\nSources', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Raw Materials)', target: 'Secondary\nImportance\nSources', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\n(Retailer)', target: cuEmergencyAgencies, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Fact\nChecker', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Non-Government\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Retailer)', target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\n(Suppliers)', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Emergency\nAgencies\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Fact\nCheckers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\n(Suppliers)', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\nManufacturers -\nRetailers', target: cuEmergencyAgencies, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Fact\nChecker', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Government\nAgencies', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Health\nDigital\nServices', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Non-Government\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Religious\nLeaders', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nManufacturers -\nRetailers', target: 'Virtual\nCommunities', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Administrative\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Educational\nInstitutions', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Emergency\nAgencies\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Fact\nCheckers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'For-Profit\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Medical\nInformation\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Non-Government\nAggregators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRaw Materials\n- Suppliers', target: 'Sentinel\nSurveillance\nNetworks', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\nRetailers -\nCustomers', target: 'Business', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Echo\nChamber\nEffect', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Educators', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: cuEmergencyAgencies, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Health\nIndustry ', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Health\nProfessionals', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Journalists', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Policymakers', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nRetailers -\nCustomers', target: 'Researchers', lineStyle: { curveness: 0.1 } },

  { source: 'Broadband\nSuppliers -\nManufacturers', target: 'Dis/misinformation\nCreation', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nSuppliers -\nManufacturers', target: 'Evidence\nBased\nOrganizations', lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nSuppliers -\nManufacturers', target: mmaMediaServices, lineStyle: { curveness: 0.1 } },
  { source: 'Broadband\nSuppliers -\nManufacturers', target: mmaOpinionLeaders, lineStyle: { curveness: 0.1 } },

  { source: 'Electricity\n(Transportation)', target: 'Broadband\nManufacturers -\nRetailers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: 'Broadband\nRaw Materials\n- Suppliers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: 'Broadband\nSuppliers -\nManufacturers', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: 'Energy\nConsumption', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: 'Radio', lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },
  { source: 'Electricity\n(Transportation)', target: rbaTraditionalMedia, lineStyle: { curveness: 0.1 } },

  { source: 'Hype\nFindings', target: 'False Facts', lineStyle: { curveness: 0.1 } },

  { source: 'Medical Consultations', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Published\nResearch', target: 'False Facts', lineStyle: { curveness: 0.1 } },
  { source: 'Published\nResearch', target: 'Hype\nFindings', lineStyle: { curveness: 0.1 } },
  { source: 'Published\nResearch', target: 'Replication\nCrisis', lineStyle: { curveness: 0.1 } },

  { source: 'Radio', target: 'Ideological\nDivide ', lineStyle: { curveness: 0.1 } },
  { source: 'Radio', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Radio', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },

  { source: 'Word of\nMouth', target: 'Broadband\nRetailers -\nCustomers', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Decisionmakers', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Echo\nChamber\nEffect', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Ideological\nDivide', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Politicians', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Population', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Public Officials', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: 'Public\nTrust', lineStyle: { curveness: 0.1 } },
  { source: 'Word of\nMouth', target: tlTechnologyPlatforms, lineStyle: { curveness: 0.1 } },

  // #endregion
  // ---------------------------------------------------
];
