import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {ApiServiceService} from "../../../services/apiService/api-service.service";

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.css'],
})
export class ArticlesListComponent implements OnInit, OnDestroy {
  private _destroyed = new Subject()

  @Input() dateRange: any = []
  @Input() content: any;
  @Input() topic: any;

  articles: any[] = []
  hidden = false;
  totalCount: any = 0;

  chartDefinition = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: '#F1F1FA'
        },
        itemStyle: {
          color: '#610000'
        }
      }
    ]
  }

  constructor(private route: ActivatedRoute, private api: ApiServiceService) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        const {start, end, content, topic, id} = params;
        if (id) {
          this.hidden = true;
        } else {
          if (start && end && content) {
            this.api.get(`event/events?startDate=${start}1&endDate=${end}&contentId=${content}${topic ? `&topicId=${topic}` : ''}&limit=10&offset=0`)
              .subscribe(response => {
                //console.log('RESPONSE: ', response)
                this.articles = response.events;
                this.totalCount = response.totalCount
              })
          } else {
            this.articles = []
          }
          this.hidden = false;
        }
      })
  }

  ngOnDestroy() {
    this._destroyed.next(undefined);
  }
}
