import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HomeStore } from '../../stores/home-store';
import { MainMenuService } from '../../services/menu/main-menu-service';
import { CaseModel } from '../../models/main-page/case-model';

@Component({
  selector: 'app-second-page',
  templateUrl: './second-page.component.html',
  styleUrls: ['./second-page.component.css']
})
export class SecondPageComponent {  
  case?:CaseModel;

  @Output()
  eventoSecondPage = new EventEmitter<string>();


  constructor(private homeStore: HomeStore, private menuService:MainMenuService){
   this.case =this.menuService.caseModel;
   let second= this.case?.url!=null?this.case?.url?.toString():"";
   this.homeStore.setSelectedSecondMenuOption(second);
  }
  ngOnInit() {
    let second= this.case?.url!=null?this.case?.url?.toString():"";
    let name= this.case?.name!=null?this.case?.name?.toString():"";
    this.eventoSecondPage.emit(this.homeStore.getSelectedMainMenuOption()+"|"+second+"|"+name)
  } 
}
