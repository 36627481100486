import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-selector-years',
  templateUrl: './selector-years.component.html',
  styleUrls: ['./selector-years.component.css']
})
export class SelectorYearsComponent implements OnChanges, OnInit {
  @Input() listYears!: any;
  @Output() selectoryearValue = new EventEmitter<string[]>();
  selectoryearFilter = new FormControl<string[]>([]);

  selectedyears!: any;

  estado: boolean | undefined;
  constructor() {
    this.selectoryearFilter.valueChanges.subscribe((selectedYears: any) => {
      if (selectedYears.length > 5) {
        this.selectoryearFilter.setValue(selectedYears.slice(0, 5), { emitEvent: false });
        alert('You can select a maximum of 5 years.');
      } else {
        this.selectedyears = selectedYears;
      }
    });
  }

  submitSelection() {
    this.selectoryearValue.emit(this.selectedyears);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listYears'] && this.listYears) {
      let currentSelection = this.selectedyears != null ? this.selectedyears : this.listYears.slice(0, 5);
      this.selectoryearFilter.setValue(currentSelection, { emitEvent: false });
    }
  }

  ngOnInit() {
    if (this.listYears && this.listYears.length >= 5) {
      this.selectoryearFilter.setValue(this.listYears.slice(0, 5));
      this.selectedyears = null;
    }
  }

}
