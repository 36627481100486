
<div *ngIf="this.isUSA">
    <div *ngIf="this.data !== undefined">
      <app-static-template [dataRes]="this.data"></app-static-template>
    </div>
  
</div>
<div *ngIf="this.isTri">
  <div *ngIf="this.data !== undefined">
    <app-hurricane-trinational [data]="this.data"></app-hurricane-trinational>
  </div>

</div>

<div *ngIf="!this.isUSA && !this.isTri">
  <app-not-found></app-not-found>
</div>
    

