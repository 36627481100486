import { Component, ElementRef, Inject, Input, NgZone } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID } from '@angular/core';
import { ViewChild } from "@angular/core";

declare const am5: any;
declare const am5xy: any;
declare const am5themes_Animated: any;


@Component({
  selector: 'app-graph-bar-line',
  templateUrl: './graph-bar-line.component.html',
  styleUrls: ['./graph-bar-line.component.css']
})
export class GraphBarLineComponent {
  @Input() barId!: string;
  @Input() data!: any;

  @ViewChild('barchart', {read: ElementRef}) private barchart!: ElementRef<HTMLElement>;
  private root:any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  ngAfterViewInit() {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  initChart(){
    // Create root element
    this.root = am5.Root.new(this.barchart.nativeElement);

    // Set themes
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    // Create chart
    var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
      paddingLeft:0,
      paddingRight:1
    }));

    // Add cursor
    var cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set("visible", false);


    // Create axes
    var yRenderer = am5xy.AxisRendererY.new(this.root, { 
      minGridDistance: 30, 
      minorGridEnabled: true
    });

    yRenderer.labels.template.setAll({
      rotation: 0, //changes the rotation for labels to be horizontal 
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });

    yRenderer.grid.template.setAll({
      location: 1
    })

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
      maxDeviation: 0.3,
      categoryField: "category",
      renderer: yRenderer,
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    var xRenderer = am5xy.AxisRendererX.new(this.root, {
      strokeOpacity: 0.1
    })

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
      maxDeviation: 0.3,
      renderer: xRenderer
    }));

    // Create series
    var series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "value",
      sequencedInterpolation: true,
      categoryYField: "category",
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "{valueX}"
      })
    }));

    series.columns.template.setAll({ cornerRadiusBL: 5, cornerRadiusBR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add("fill", function (fill: any, target: any) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke: any, target: any) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });


    // Set data
    yAxis.data.setAll(this.data.data);
    series.data.setAll(this.data.data);


    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
  }

  
}